import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const WarningRedIcon: FunctionComponent<SvgProps> = ({ onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M12.1244 4C12.8942 2.66667 14.8187 2.66667 15.5885 4L25.9808 22C26.7506 23.3333 25.7884 25 24.2488 25H3.46414C1.92454 25 0.96229 23.3333 1.73209 22L12.1244 4Z"
                    fill={'#D7382D'}
                />
                <circle cx="14" cy="20.5" r="1" fill="white" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14 9C14.5523 9 15 9.44772 15 10V17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5V10C13 9.44772 13.4477 9 14 9Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default WarningRedIcon;
