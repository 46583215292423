import { Col, Input, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { GetPolicyBundlesRequestDto } from 'Api/Features/Policies/Dtos/GetPolicyBundlesRequestDto';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { CreateTeamRequestDto } from 'Api/Features/Teams/Dtos/CreateTeamRequestDto';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import Button from 'Components/button';
import LanguageCapitalizedText from 'Components/language-capitalized-text';
import { MagnifyingGlassIcon } from 'Components/icons';
import Modal from 'Components/modal';
import OnelinePolicySummary, {
    getOneLinePolicySummaryStringSections,
} from 'Components/oneline-policy-summary';
import { singleSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import AsyncSingleSelect, {
    SelectFetchFunctionPromise,
} from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useAsyncSingleSelectProps, useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { DROPDOWN_PAGE_SIZE, FORM_GUTTER } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTeamSchema } from 'Schemas/CreateTeamSchema';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import { TeamService } from 'Services/TeamService';
import { UserService } from 'Services/UserService';
import SelectedUsersComponent from './components/selected-users-component';
import './create-team-modal.less';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import SubmitButton from 'Components/submit-button/submit-button';

interface CreateTeamModalProps {
    visible: boolean;
    onComplete: (success: boolean, addPeople: boolean, id?: string) => void;
    team?: TeamDto;
}

const CreateTeamModal: React.FunctionComponent<CreateTeamModalProps> = observer(
    ({ visible, onComplete, team }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const { toastStore, globalLoadingStore, userStore, policyStore, languageStore } =
            useStores();
        const userService = useService(UserService);
        const teamService = useService(TeamService);
        const policyService = useService(PolicyBundleService);
        const [errors, validateForm, resetErrors] = useFormValidation(CreateTeamSchema, form);

        const { asyncSingleSelectProps: managerSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetUsersRequestDto) => userService.getUsers(request),
                fetchFunctionExtraParams: {
                    managementRoles: [ManagementRoleDto.Manager, ManagementRoleDto.Administrator],
                },
            },
            entityToSingleSelectCustomOption: (manager: UserDetailsDto) =>
                ({
                    value: manager.id,
                    label: `${manager.firstName ?? ''} ${manager.lastName ?? ''}`,
                    extraData: manager,
                } as SingleSelectCustomOption),
        });
        const [selectedManagers, setSelectedManagers] = useState<UserDto[]>([]);

        const [policySearchResults, setPolicySearchResults] = useState<PolicyBundleDto[]>([]);
        const [policyOptions, setPolicyOptions] = useState<SingleSelectCustomOption[]>([]);
        const [selectedPolicy, setSelectedPolicy] = useState<PolicyBundleDto>();
        const [selectedPolicyOption, setSelectedPolicyOption] =
            useState<SingleSelectCustomOption>();

        const fetchSpecificBundle = async (id: string) => {
            try {
                globalLoadingStore.addLoading();
                const bundle = await policyService.getPolicyBundle(id);
                if (bundle) {
                    setSelectedPolicy(bundle);
                    const policyString = getOneLinePolicySummaryStringSections(
                        bundle,
                        t,
                        languageStore.currentLanguage
                    );
                    setSelectedPolicyOption({
                        value: bundle.id,
                        label: bundle.name,
                        content: {
                            node: `${policyString.days} - ${policyString.time} - ${policyString.objectives}`,
                            className: 'ml-15',
                        },
                        extraData: bundle,
                    } as SingleSelectCustomOption);
                }
            } catch (e: any) {
                if (!e.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        useEffect(() => {
            if (team) {
                form.setFieldsValue({
                    name: team.name,
                });
                if (team.policyBundle?.id) {
                    //we need to fetch this specific policy for more info. Might not come out of top search results
                    fetchSpecificBundle(team.policyBundle?.id);
                }
            }
        }, [team]);

        const handleRemoveClick = (user: UserDto) => {
            setSelectedManagers((prev) => [...prev.filter((x) => x.id !== user.id)]);
        };

        //Policy search
        const searchPolicies = async (
            page: number,
            searchTerm: string
        ): Promise<SelectFetchFunctionPromise> => {
            const args: GetPolicyBundlesRequestDto = {
                pageSize: DROPDOWN_PAGE_SIZE,
                page: page,
                searchTerm: searchTerm,
            };
            const [results, totalItemCount] = await policyService.getPolicyBundles(args);

            setPolicySearchResults((prev) => [...prev, ...results]);

            return {
                maxResultHit: results.length + DROPDOWN_PAGE_SIZE * page >= totalItemCount,
            };
        };

        useEffect(() => {
            const searchResults = policySearchResults?.map((x: PolicyBundleDto) => {
                const policyString = getOneLinePolicySummaryStringSections(
                    x,
                    t,
                    languageStore.currentLanguage
                );
                return {
                    value: x?.id,
                    label: x?.name,
                    content: {
                        node: `${policyString.days} - ${policyString.time} - ${policyString.objectives}`,
                        className: 'ml-15',
                    },
                    selectedItemOnlyShowLabel: true,
                    extraData: x,
                } as SingleSelectCustomOption;
            });

            const merged = singleSelectMergeSelectedOptionsWithSearchResults(searchResults, [
                selectedPolicyOption,
            ]);

            setPolicyOptions(merged);
        }, [policySearchResults, selectedPolicyOption, languageStore.currentLanguage]);

        //#region Submit / Exit
        const dismiss = (success = false, addPeople = false, id?: string): void => {
            form.resetFields();
            resetErrors();
            onComplete(success, addPeople, id);
        };

        const submit = async (addPeople: boolean): Promise<void> => {
            const formData = form.getFieldsValue();

            if (!(await validateForm(formData))) return;

            try {
                globalLoadingStore.addLoading();
                const request: CreateTeamRequestDto = {
                    name: formData.name,
                    managerIds: selectedManagers
                        .filter((user) => user.id !== null && user.id !== undefined)
                        .map((user) => user.id!)
                        .concat(
                            team?.managers
                                ?.filter((x) => x?.id !== null && x?.id !== undefined)
                                .map((x) => x!.id!) ?? []
                        ),
                    policyBundleId: selectedPolicy?.id ? selectedPolicy?.id : undefined,
                };

                let createdTeam;

                if (team && team.id) {
                    await teamService.updateTeam(team.id, request);
                } else {
                    createdTeam = await teamService.createTeam(request);
                }

                if (
                    request.managerIds?.some((x) => x === userStore.userInfo?.id) ||
                    team?.id === userStore.userInfo?.team?.id
                ) {
                    policyStore.clearPolicyInfo();
                    await userStore.setUserInfo(userStore.userInfo?.id ?? '');
                    await policyStore.setPolicyRestrictionsAndObjectivesStats();
                }

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('team'),
                    }),
                });

                dismiss(true, addPeople, createdTeam?.id);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#region Submit / Exit

        return (
            <Modal
                className="CreateTeamModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t(`${team ? 'edit' : 'create'}_entity`, {
                    param1: t('team'),
                    param2: 'une',
                    param3: t('Entity.lowercase_team'),
                })}
                fixedHeight
                oustideOverflow={{
                    height: 300,
                    element: (
                        <Form layout="vertical" onFinish={submit} form={form}>
                            <Row gutter={FORM_GUTTER}>
                                <Col span={8}>
                                    <div className="text-callout-bold mb-15">
                                        <LanguageCapitalizedText>
                                            {t('basic_information')}
                                        </LanguageCapitalizedText>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={FORM_GUTTER}>
                                <Col span={12}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="name"
                                        label={t('name')}
                                        required
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={12}>
                                    <ValidatedFormItem label={t('policy_bundle')}>
                                        <AsyncSingleSelect
                                            fetchFunction={searchPolicies}
                                            resetSearchResults={() => setPolicySearchResults([])}
                                            options={policyOptions}
                                            selected={selectedPolicy?.id}
                                            onChange={(value?: SingleSelectCustomOption) => {
                                                setSelectedPolicy(
                                                    value ? value.extraData : undefined
                                                );
                                                setSelectedPolicyOption(value);
                                            }}
                                            hideSelectedOptions={false}
                                            isClearable
                                        />
                                        {selectedPolicy && (
                                            <div className="policy-summary">
                                                <OnelinePolicySummary policy={selectedPolicy} />
                                            </div>
                                        )}
                                    </ValidatedFormItem>
                                </Col>
                            </Row>

                            <div className="text-caption-1 text-mid-contrast mb-10 t-align-center">
                                {t('Team.search_managers_add_team')}
                            </div>

                            <Row gutter={FORM_GUTTER}>
                                <Col span={8} offset={8}>
                                    <AsyncSingleSelect
                                        {...managerSelectProps}
                                        placeholder={t('search')}
                                        selected={undefined}
                                        dropdownIndicatorIcon={<MagnifyingGlassIcon />}
                                        onChange={(value?: SingleSelectCustomOption) => {
                                            setSelectedManagers((prev) => [
                                                ...prev.filter((x) => x.id !== value?.extraData.id),
                                                value?.extraData,
                                            ]);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    ),
                }}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                        <div className="positive-btns">
                            {!team && (
                                <SubmitButton
                                    className="add-people"
                                    text={t('Team.create_and_add_coworkers')}
                                    type="link"
                                    width="hugged"
                                    onClick={() => submit(true)}
                                />
                            )}

                            <SubmitButton
                                text={
                                    team
                                        ? t('save_changes')
                                        : t('create_entity', { param1: t('team') })
                                }
                                type="primary"
                                onClick={() => submit(false)}
                            />
                        </div>
                    </>
                }
            >
                <SelectedUsersComponent
                    users={selectedManagers}
                    handleRemoveClick={handleRemoveClick}
                />
            </Modal>
        );
    }
);

export default CreateTeamModal;
