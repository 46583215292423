import React, { FunctionComponent } from 'react';
import { Checkbox, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { AdvancedFilterItem } from 'Models/Filters/AdvancedFilterItem';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import './advanced-filter-dropdown.less';
import { ChevronDownIcon } from 'Components/icons';
import { theme } from 'Style/theme';

interface AdvancedFilterDropdownProps {
    filter: AdvancedFilter;
    onItemChange: (key: string, parentKey: string) => void;
}

const AdvancedFilterDropdown: FunctionComponent<AdvancedFilterDropdownProps> = observer(
    ({ filter, onItemChange }) => {
        const { t } = useTranslation();

        const getMenu = (filterItems: AdvancedFilterItem[]) => (
            <Menu className="menu">
                {filterItems.map((filterItem) => (
                    <Menu.Item
                        key={filterItem.key}
                        onClick={() => onItemChange(filterItem.key, filter.key)}
                    >
                        <div className="filter-name">{t(filterItem.displayNameKey)}</div>
                        <Checkbox checked={filterItem.checked} />
                    </Menu.Item>
                ))}
            </Menu>
        );

        return (
            <div className="AdvancedFilterDropdown">
                <Dropdown
                    overlay={getMenu(filter.items)}
                    trigger={['click']}
                    placement="bottomRight"
                    overlayClassName="advanced-filter-overlay"
                >
                    <div>
                        <div>{filter.nameKey}</div>
                        <div className="check-count text-caption-1">
                            {filter.items.filter((filter) => filter.checked).length}
                        </div>
                        <ChevronDownIcon fill={theme['primary-mid-contrast']} />
                    </div>
                </Dropdown>
            </div>
        );
    }
);

export default AdvancedFilterDropdown;
