import { observer } from 'mobx-react';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './hours-preferences-tab.less';
import Button from 'Components/button';
import { useService, useStores } from 'Hooks';
import WeeklyHourPrefCalendar, { CalendarForType } from 'Components/weekly-hour-pref-calendar';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { ScheduleService } from 'Services/ScheduleService';
import { GetUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserDefaultScheduleRequestDto';
import moment from 'moment';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import { PolicyWrapperBoxInformations } from 'Components/policy-wrapper-box-informations';
import EditHourPrefModal from '../edit-hour-pref-modal';
import Divider from 'Components/divider';
import { OfficeService } from 'Services/OfficeService';
import {
    DefaultScheduleEventWithCapacity,
    fetchDefaultScheduleAttendence,
} from 'Utils/CalendarUtils';

const HoursPreferencesTab: FunctionComponent = observer(() => {
    const { userStore, toastStore, policyStore } = useStores();
    const scheduleService = useService(ScheduleService);
    const officeService = useService(OfficeService);
    const { t } = useTranslation();

    const [events, setEvents] = useState<ScheduleEventDto[]>([]);
    const [eventsWithCapacity, setEventsWithCapacity] = useState<
        DefaultScheduleEventWithCapacity[]
    >([]);

    const [editHoursPrefModalVisible, setEditHoursPrefModalVisible] = useState(false);

    const fetchSchedule = useCallback(async () => {
        try {
            const request: GetUserDefaultScheduleRequestDto = {
                week: moment().format(FORMAT_YEAR_MONTH_DAY),
            };

            const response = await scheduleService.getUserDefaultSchedule(
                userStore.userInfo!.id!,
                request
            );

            if (response) {
                const events =
                    response.events
                        ?.filter((event) => event !== null && event !== undefined)
                        .map((event) => event!) ?? [];
                setEvents(events);
                setEventsWithCapacity(events); //we can set events to render so that we dont have to wait on capacity to be able to show periods in calendar
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, [scheduleService]);

    useEffect(() => {
        if (userStore.userInfo?.id) fetchSchedule();
    }, [userStore.userInfo?.id]);

    const fetchScheduleAttendence = async (events: ScheduleEventDto[]) => {
        setEventsWithCapacity(
            await fetchDefaultScheduleAttendence(
                events,
                officeService,
                userStore.userInfo?.timeZone ?? '',
                toastStore
            )
        );
    };

    useEffect(() => {
        if (events) {
            fetchScheduleAttendence(events);
        }
    }, [events]);

    return (
        <div className="HoursPreferencesTab">
            <div className="white-container">
                <div className="x-padding">
                    <div className="top">
                        <span className="text-body-bold">{t('default_schedule')}</span>
                        <span className="text-body">{t('your_default_schedule')}</span>
                    </div>
                </div>

                <Divider marginTop={20} marginBottom={30} />

                <div className="x-padding bottom-padding">
                    <div className="calendar-top-container mb-30">
                        <PolicyWrapperBoxInformations
                            events={events}
                            objectives={policyStore.policyInfo?.policyBundleObjectivesDto}
                        />

                        <Button
                            className="edit-btn"
                            text={t('edit')}
                            type="primary"
                            width="hugged"
                            leftIcon="PencilIcon"
                            onClick={() => setEditHoursPrefModalVisible(true)}
                        />
                    </div>

                    <WeeklyHourPrefCalendar
                        events={eventsWithCapacity}
                        isInteractable={false}
                        calendarForType={CalendarForType.UserProfile}
                        restrictions={policyStore.policyInfo?.restrictions ?? undefined}
                        roundedBorders={true}
                    />
                </div>
            </div>

            {editHoursPrefModalVisible && (
                <EditHourPrefModal
                    visible={editHoursPrefModalVisible}
                    onComplete={(success) => {
                        setEditHoursPrefModalVisible(false);
                        if (success) {
                            fetchSchedule();
                            policyStore.setPolicyRestrictions();
                        }
                    }}
                    schedule={eventsWithCapacity}
                    calendarForType={CalendarForType.UserProfile}
                />
            )}
        </div>
    );
});

export default HoursPreferencesTab;
