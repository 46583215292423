import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FloorPlanIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M19.8703 16.4587L21.5152 17.2817C21.7262 17.3872 21.7262 17.6883 21.5152 17.7939L14.2564 21.4242C14.1768 21.4639 14.0892 21.4846 14.0003 21.4846C13.9114 21.4846 13.8237 21.4639 13.7442 21.4242L6.48532 17.7939C6.4379 17.77 6.39805 17.7334 6.3702 17.6881C6.34236 17.6429 6.32762 17.5909 6.32762 17.5378C6.32762 17.4847 6.34236 17.4326 6.3702 17.3874C6.39805 17.3422 6.4379 17.3056 6.48532 17.2817L8.13122 16.4587L13.231 19.0091C13.7154 19.2508 14.2861 19.2508 14.7695 19.0091L19.8693 16.4578L19.8703 16.4587ZM13.7442 6.77128C13.8237 6.7316 13.9114 6.71094 14.0003 6.71094C14.0892 6.71094 14.1768 6.7316 14.2564 6.77128L21.5152 10.4007C21.5626 10.4248 21.6024 10.4615 21.6302 10.5068C21.658 10.552 21.6727 10.6041 21.6727 10.6573C21.6727 10.7104 21.658 10.7625 21.6302 10.8078C21.6024 10.8531 21.5626 10.8898 21.5152 10.9139L14.2564 14.5423C14.1768 14.582 14.0892 14.6027 14.0003 14.6027C13.9114 14.6027 13.8237 14.582 13.7442 14.5423L6.48532 10.9139C6.43765 10.8901 6.39755 10.8534 6.36953 10.8081C6.3415 10.7628 6.32666 10.7106 6.32666 10.6573C6.32666 10.604 6.3415 10.5518 6.36953 10.5065C6.39755 10.4611 6.43765 10.4245 6.48532 10.4007L13.7442 6.77128Z"
                    fill={fill}
                />
                <path
                    d="M19.8703 13.0181L21.5152 13.841C21.7263 13.9465 21.7263 14.2477 21.5152 14.3532L14.2564 17.9826C14.1768 18.0223 14.0892 18.043 14.0003 18.043C13.9114 18.043 13.8237 18.0223 13.7442 17.9826L6.48534 14.3532C6.43792 14.3293 6.39807 14.2927 6.37022 14.2475C6.34238 14.2023 6.32764 14.1502 6.32764 14.0971C6.32764 14.044 6.34238 13.9919 6.37022 13.9467C6.39807 13.9015 6.43792 13.8649 6.48534 13.841L8.13124 13.0181L13.2311 15.5684C13.7154 15.8102 14.2861 15.8102 14.7695 15.5684L19.8703 13.0181Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default FloorPlanIcon;
