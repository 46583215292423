import React from 'react';
import './index.less';

const NUMBER_DAYS_IN_WEEK = 7;

export const hoursAndObjectivesSkeletonShape = (
    <div className="hoursAndObjectivesSkeletonShape">
        <div className="wrapper-top">
            <div className="rect-1" />
            <div className="rect-2" />
        </div>

        <div className="wrapper-2">
            <div className="wrapper-2-top">
                <div className="rect" />
                <div className="separator" />
                <div className="rect" />
            </div>

            <div className="wrapper-2-bottom">
                <div className="rect" />
            </div>
        </div>
    </div>
);

export const planScheduleCoworkersAtOfficeSkeletonShape = (
    <div className="planScheduleCoworkersAtOfficeSkeletonShape">
        <div className="rect-title" />

        <div className="container-days">
            {[...Array(NUMBER_DAYS_IN_WEEK)].map((el, key) => {
                return (
                    <div key={key} className="container-one-day">
                        <div className="rect" />
                        <div className="circle" />
                    </div>
                );
            })}
        </div>
    </div>
);
