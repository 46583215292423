import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { CoworkerFilterGroups, GetDailySchedulesResponseItem } from '.';
import { HorizontalRadioOption } from 'Components/horizontal-radio';

export const initStateCoworkers = {
    currentPageCoworkers: 0,
    handleDrawerVisible: false,
    allCoworkers: [],
    selectedCoworker: null,
    filteredCoworkers: [],
    totalAllCoworkersApi: 0,
    drawerAnimationEnded: false,
    totalCoworkersAlreadyLoad: 0,
    selectedOffice: 'all',
    selectedWorkTypeFilter: 'all',
    coworkerWeeklyHourPrefModalOpen: false,
    searchTerm: '',
    noScroll: false,
    workTypeFilterOptions: [],
    selectedTeams: '',
    optionsOffices: [],
    coworkersIsLoading: false,
    coworkersNeedToReload: false,
};

export enum ActionReducerCoworkers {
    'handle',
    'resetCoworkersCall',
    'resetCoworkerSelection',
    'fetchCoworkersSuccess',
    'selectCoworkers',
    'nextPageCoworkers',
    'prevPageCoworkers',
    'closeCoworkerWeelkyHourPrefModal',
    'setCoworkers',
}
export interface CoworkersState {
    currentPageCoworkers: number;
    handleDrawerVisible: boolean;
    allCoworkers: GetDailySchedulesResponseItem[];
    selectedCoworker: GetDailySchedulesResponseItem | null;
    coworkerFilteredGroups: CoworkerFilterGroups;
    totalAllCoworkersApi: number;
    drawerAnimationEnded: boolean;
    totalCoworkersAlreadyLoad: number;
    selectedOffice: string;
    selectedWorkTypeFilter: WorkTypeDto | string;
    coworkerWeeklyHourPrefModalOpen: boolean;
    searchTerm: string;
    noScroll: boolean;
    workTypeFilterOptions: HorizontalRadioOption[];
    selectedTeams: string;
    optionsOffices: SingleSelectCustomOption[];
    coworkersIsLoading: boolean;
    coworkersNeedToReload: boolean;
}

export interface CowokrersAction {
    type: ActionReducerCoworkers;
    value?: any;
    field?: string;
}

export const reducerCoworkers = (state: CoworkersState, action: CowokrersAction): any => {
    switch (action.type) {
        // handle generic
        case ActionReducerCoworkers.handle:
            if (!action.field || !action.value) break;
            return {
                ...state,
                [action.field]: action.value,
            };
        //apres 2h-3h je comprend toujours pas pourquoi utiliser le handler generic pour close le modal crash..
        /*Si tu veux tenter le coup, remet 
            {
                type: ActionReducerCoworkers.handle,
                field: 'coworkerWeeklyHourPrefModalOpen',
                value: false,
            }
            dans le onComplete du CoworkerScheduleModal
        */
        case ActionReducerCoworkers.closeCoworkerWeelkyHourPrefModal:
            return {
                ...state,
                coworkerWeeklyHourPrefModalOpen: false,
            };
        case ActionReducerCoworkers.fetchCoworkersSuccess:
            return {
                ...state,
                totalCoworkersAlreadyLoad:
                    state.totalCoworkersAlreadyLoad + action.value.response[0].length,
                totalAllCoworkersApi: action.value.response[1],
                allCoworkers: [...state.allCoworkers, ...action.value.coworkers],
                coworkersIsLoading: true,
                coworkersNeedToReload: false,
            };
        case ActionReducerCoworkers.selectCoworkers:
            return {
                ...state,
                drawerVisible: true,
                selectedCoworker: action.value,
            };
        case ActionReducerCoworkers.setCoworkers:
            return {
                ...state,
                allCoworkers: action.value,
            };

        // page coworkers
        case ActionReducerCoworkers.nextPageCoworkers:
            return {
                ...state,
                currentPageCoworkers: state.currentPageCoworkers + 1,
            };
        case ActionReducerCoworkers.prevPageCoworkers:
            return {
                ...state,
                currentPageCoworkers: state.currentPageCoworkers - 1,
            };

        // reset
        case ActionReducerCoworkers.resetCoworkersCall:
            return {
                ...state,
                currentPageCoworkers: 0,
                allCoworkers: [],
                totalCoworkersAlreadyLoad: 0,
                filteredCoworkers: [],
            };
        case ActionReducerCoworkers.resetCoworkerSelection:
            return {
                ...state,
                drawerVisible: false,
                selectedCoworker: null,
            };

        default:
            return state;
    }
};
