import React, { memo } from 'react';
import './index.less';

export interface OfficeOccupancyCardDateProps {
    isToday: boolean;
    day: string;
    date: string;
}

const OfficeOccupancyCardDate: React.FunctionComponent<OfficeOccupancyCardDateProps> = ({
    isToday,
    day,
    date,
}) => {
    return (
        <div className={`OfficeOccupancyCardDate isToday-${isToday}`}>
            <div className="day">{day}</div>
            <div className="month">{date}</div>
        </div>
    );
};

export default memo(OfficeOccupancyCardDate);
