import { useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { generateKey } from 'Utils/ArrayUtils';
import './index.less';

interface SkeletonProps {
    className?: string;
    //TODO changer pour isLoading pour moins de gymnastique mentale
    isLoaded?: boolean;
    placeholder: ReactElement;
    imgsSrc?: string[];
    children?: ReactElement | ReactElement[];
    animation?: boolean;
    defaultPadding?: boolean;
}

const Skeleton: React.FunctionComponent<SkeletonProps> = observer(
    ({
        className,
        isLoaded = false,
        placeholder,
        imgsSrc,
        children,
        animation = true,
        defaultPadding = true,
    }) => {
        const [imgs, setImgs] = useState<ReactNode[]>([]);
        const [imgsToLoad, setImgsToLoad] = useState<boolean[]>([]);
        const [imgsAreLoaded, setImgsAreLoaded] = useState<boolean[]>(imgsToLoad);
        const [isAllLoaded, setIsAllLoaded] = useState(false);
        const { userStore } = useStores();

        const placeholderWithClassName = React.cloneElement(placeholder, {
            className: `Skeleton ${defaultPadding ? 'p-20' : ''} ${className ?? ''} ${
                placeholder.props.className
            } ${animation ? 'animation' : ''}`,
        });
        const nonNullChildren = children ? (
            Array.isArray(children) ? (
                <>{children}</>
            ) : (
                children
            )
        ) : (
            <></>
        );

        useEffect(() => {
            const newImgsToLoad = imgsSrc && imgsSrc?.length > 0 ? imgsSrc.map(() => false) : [];
            newImgsToLoad.length > 0 && setImgsToLoad(newImgsToLoad);
        }, []);

        useEffect(() => {
            if (imgsToLoad.length === imgsSrc?.length) {
                const newImgs = imgsSrc
                    ? imgsSrc.map((src, i) =>
                          React.createElement('img', {
                              src: src.startsWith('http') ? src : window.location.origin + src,
                              key: generateKey(`preloaded-img-${i}`),
                              onLoad: () => {
                                  setImgsAreLoaded((prev) => [...prev, (prev[i] = true)]);
                              },
                              onError: () => {
                                  setImgsAreLoaded((prev) => [...prev, (prev[i] = true)]);
                              },
                          })
                      )
                    : [];

                if (newImgs.length > 0) setImgs(newImgs);
            }
        }, [imgsToLoad]);

        useEffect(() => {
            if (userStore?.userInfo?.id && userStore?.userInfo?.id !== undefined)
                setIsAllLoaded(isLoaded && !imgsAreLoaded.includes(false));
            else setIsAllLoaded(false);
        }, [isLoaded, imgsAreLoaded, userStore.userInfo?.id]);

        return isAllLoaded ? (
            nonNullChildren
        ) : (
            <>
                {placeholderWithClassName}
                <div>{imgs.map((img) => img)}</div>
            </>
        );
    }
);
export default Skeleton;
