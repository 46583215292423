import { useState } from 'react';
import { useStores } from './use-stores';

export const useFetch = () => {
    const { toastStore, globalLoadingStore } = useStores();
    //multiple calls could be loading at same time for same hook since we call apiRequest from outside the hook
    const [loadingStateKeys, setLoadingStateKeys] = useState<Map<string, string>>(new Map());

    const apiRequest = async (props: ApiRequestProps): Promise<any | [any[], number]> => {
        try {
            if (props.loadingStateKey)
                setLoadingStateKeys((prev) => new Map(prev).set(props.loadingStateKey!, ''));

            if (props.useGlobalLoading) globalLoadingStore.addLoading();

            return await props.requestFunction(props.requestParameters);
        } catch (e: any) {
            if (props.customErrorHandler) {
                props.customErrorHandler(e);
                e.treated = true;
            }
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            if (props.loadingStateKey) {
                setLoadingStateKeys((prev) => {
                    prev.delete(props.loadingStateKey!);
                    return prev;
                });
            }

            if (props.useGlobalLoading) globalLoadingStore.removeLoading();
        }
    };

    return { apiRequest, loadingStateKeys };
};

export interface ApiRequestProps {
    /**Service function */
    requestFunction: (requestParameters) => Promise<any | [any[], number]>;

    /**RequestDto for the service function and such */
    requestParameters: any;

    /**If loading state of the request needs to be used for loading skeleton, pass in a key to keep track of loading and render accordingly.
     *  Use loadingStates.has(loadingStateKey) in the skeleton; if key is present, the call is loading*/
    loadingStateKey?: string;

    /**Override the default generic toast error */
    customErrorHandler?: (error: any) => void;

    /**Fetching should trigger the global loading spinner */
    useGlobalLoading?: boolean;
}
