import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Button from 'Components/button';
import { useTranslation } from 'react-i18next';
import { useStores, useFormNavigationBlocker } from 'Hooks';
import { observer } from 'mobx-react';
import './profile-details-tab.less';
import Modal, { ModalHeaderColor } from 'Components/modal';
import ProfileImagePicker from 'Components/profile-image-picker/profile-image-picker';
import TitledWhitecardSection from '../titled-whitecard-section';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { Input } from 'antd';
import { ProfileContext, ProfileTabs } from 'Routes/profile';
import GooglePlacesAutoComplete from 'Components/google-places-autocomplete';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import Skeleton from 'Components/skeleton';
import { InputSkeletonShape } from 'Components/skeleton/generic-component-shapes/inputs';
import { mergeStrings } from 'Utils/TextUtils';
import { languageOptions, timeZonesOptions } from 'Utils/IntlUtils';
import BadgeCreatorInput from 'Components/badge-creator-input';
import StaticMultiSelect from 'Components/select-custom/multi-select/static-multi-select';
import { CertificationDto } from 'Api/Features/Users/Dtos/CertificationDto';
import EditCertificationModal from '../edit-certification-modal';
import CertificationItem from '../certification-item';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';
import { v4 as uuidv4 } from 'uuid';
import Divider from 'Components/divider';
import SubmitButton from 'Components/submit-button/submit-button';

const ProfileDetailsTab: FunctionComponent = observer(() => {
    const profileContext = useContext(ProfileContext);
    const [setNavBlocked] = useFormNavigationBlocker();
    const { t } = useTranslation();
    const { userStore, languageStore, confirmationModalStore } = useStores();
    const [changePictureModalVisible, setChangePictureModalVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | undefined>(
        userStore.userInfo?.imageUrl ?? undefined
    );
    const [selectedtimeZone, setSelectedTimeZone] = useState<string>();

    const [selectedLanguages, setSelectedLanguages] = useState<string[]>();
    const [base64, setBase64] = useState<string>();
    const [imageDeleted, setImageDeleted] = useState(false);

    const [resetGooglePlaceValue, setResetGooglePlaceValue] = useState(false);
    const [skillBadges, setSkillBadges] = useState<MultiSelectCustomOption[]>([]);
    const [certifications, setCertifications] = useState<CertificationDtoWithId[]>([]);
    const [certificationModalState, setCertificationModalState] = useState<{
        visible: boolean;
        certification: CertificationDtoWithId | undefined;
    }>({
        visible: false,
        certification: undefined,
    });

    useEffect(() => {
        if (userStore.userInfo) {
            setResetGooglePlaceValue(true);
            profileContext?.formInstance.setFieldsValue({
                firstName: userStore.userInfo.firstName,
                lastName: userStore.userInfo.lastName,
                jobTitle: userStore.userInfo.jobTitle ?? undefined,
                department: userStore.userInfo.department ?? undefined,
                timeZone: userStore.userInfo.timeZone,
                email: userStore.userInfo.contactInfo?.email,
                locationGooglePlaceId: userStore.userInfo.location?.googlePlaceId,
            });

            setCertifications(
                userStore.userInfo.certifications?.map((cert) => ({
                    ...cert,
                    id: uuidv4(),
                })) ?? []
            );

            setSelectedLanguages(
                userStore.userInfo.languageCodes
                    ?.filter((lang) => lang !== null)
                    .map((lang) => lang!) ?? []
            );
            setSelectedTimeZone(userStore.userInfo.timeZone ?? undefined);
            setImageUrl(userStore.userInfo.imageUrl ?? undefined);
            setSkillBadges(
                userStore.userInfo.skills?.map((skill) => ({
                    label: skill ?? '',
                    value: skill ?? '',
                })) ?? []
            );
        }
    }, [userStore.userInfo]);

    useEffect(() => {
        //After hours this is the only way I found of putting back the default value in the googleplace select; by forcing the component to re-render.
        //Use case is having a google place in userstore, clearing the value in input and changing tabs without saving which should reverting all values to userstore values.
        if (resetGooglePlaceValue) {
            setResetGooglePlaceValue(false);
        }
    }, [resetGooglePlaceValue]);

    const onSubmit = async () => {
        if (profileContext) {
            const imgRequest = base64
                ? ({
                      uploadBase64: base64,
                  } as UpdateFileRequestDto)
                : imageDeleted
                ? ({ delete: true } as UpdateFileRequestDto)
                : null;
            profileContext?.formInstance.setFieldsValue({
                skills: skillBadges.map((skill) => skill.value),
                certifications: certifications,
            });

            await profileContext?.submit(ProfileTabs.ProfileDetails, imgRequest);
        }
    };

    const onDeleteCertification = useCallback(async (certification: CertificationDtoWithId) => {
        if (
            !(await confirmationModalStore.confirm({
                icon: (
                    <Icon
                        iconName="WarningIcon"
                        width={64}
                        height={64}
                        fill={theme['warning-mid-contrast']}
                    />
                ),
                title: t('Cta.delete_confirm_title', {
                    param1: t('Entity.lowercase_certification'),
                    param2: 'cette',
                }),
                message: t('Cta.delete_confirm_message', {
                    param1: t('Entity.lowercase_certification'),
                    param2: 'Cette',
                }),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            }))
        )
            return;

        setNavBlocked(true);
        setCertifications((prev) => {
            const index = prev.findIndex((cert) => cert.id === certification.id);
            if (index > -1) {
                prev.splice(index, 1);
            }
            return [...prev];
        });
    }, []);

    return (
        <div className="ProfileDetailsTab">
            <TitledWhitecardSection title={t('photo')}>
                <div className="PhotoSectionUi">
                    <div
                        className="image"
                        style={{
                            backgroundImage: `url(${imageUrl ?? NoProfilePicture})`,
                        }}
                    />

                    <div className="image-desc">
                        <div className="title">{t('ImagePicker.profile_picture')}</div>
                        <div className="text">{t('Profile.profile_picture_desc')}</div>
                    </div>

                    <Button
                        text={t('edit')}
                        type="tertiary"
                        width="hugged"
                        leftIcon="PencilIcon"
                        onClick={() => setChangePictureModalVisible(true)}
                    />
                </div>
            </TitledWhitecardSection>

            <TitledWhitecardSection title={t('profile')}>
                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('first_name'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="firstName"
                        label={t('first_name')}
                        required
                    >
                        <Input />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('last_name'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="lastName"
                        label={t('last_name')}
                        required
                    >
                        <Input />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('location'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="locationGooglePlaceId"
                        label={t('location')}
                    >
                        {resetGooglePlaceValue ? (
                            <div></div>
                        ) : (
                            <GooglePlacesAutoComplete
                                onChange={(value?: string) => {
                                    profileContext?.formInstance.setFieldsValue({
                                        locationGooglePlaceId: value ?? 'deleted',
                                    });
                                }}
                                defaultValue={
                                    userStore.userInfo?.location?.googlePlaceId &&
                                    userStore.userInfo?.location?.description
                                        ? {
                                              label: userStore.userInfo.location.description,
                                              value: userStore.userInfo.location.googlePlaceId,
                                          }
                                        : undefined
                                }
                            />
                        )}
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('time_zone'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="timeZone"
                        label={t('time_zone')}
                        required
                    >
                        <StaticSingleSelect
                            options={timeZonesOptions(languageStore.currentLanguage)}
                            onChange={(value?: SingleSelectCustomOption): void => {
                                setSelectedTimeZone(value?.value);
                                profileContext?.formInstance.setFieldsValue({
                                    timeZone: value?.value,
                                });
                            }}
                            selected={selectedtimeZone}
                            placeholder={t('SelectCustom.default_select_placeholder')}
                        />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('languages'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="languageCodes"
                        label={t('languages')}
                    >
                        <StaticMultiSelect
                            options={languageOptions(languageStore.currentLanguage)}
                            onChange={(values: MultiSelectCustomOption[]): void => {
                                setSelectedLanguages(values?.map((x: any) => x.value));
                            }}
                            selected={selectedLanguages}
                            placeholder={t('SelectCustom.default_select_placeholder')}
                        />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('email'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="email"
                        label={t('email')}
                    >
                        <Input disabled />
                    </ValidatedFormItem>
                </Skeleton>
            </TitledWhitecardSection>

            <TitledWhitecardSection title={t('account_details')}>
                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(
                        t('Profile.job_title'),
                        languageStore.currentLanguage
                    )}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="jobTitle"
                        label={t('Profile.job_title')}
                    >
                        <Input />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('department'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="department"
                        label={t('department')}
                    >
                        <Input />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('skills'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="skills"
                        label={t('skills')}
                    >
                        <BadgeCreatorInput
                            options={skillBadges}
                            onCreateBadge={(badge: string) => {
                                setNavBlocked(true);
                                setSkillBadges((prev) => [
                                    ...prev,
                                    { label: badge ?? '', value: badge ?? '' },
                                ]);
                            }}
                            onRemoveBadge={(values) => {
                                setNavBlocked(true);
                                setSkillBadges(values);
                            }}
                        />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('role'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem errors={profileContext?.formErrors} label={t('role')}>
                        <Input
                            defaultValue={userStore.userInfo?.managementRoles?.[0]?.name}
                            disabled
                        />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(t('team'), languageStore.currentLanguage)}
                    defaultPadding={false}
                >
                    <ValidatedFormItem
                        errors={profileContext?.formErrors}
                        name="team"
                        label={t('team')}
                    >
                        <Input
                            defaultValue={userStore.userInfo?.team?.name ?? undefined}
                            disabled
                        />
                    </ValidatedFormItem>
                </Skeleton>

                <Skeleton
                    isLoaded={userStore.userInfo !== null}
                    placeholder={InputSkeletonShape(
                        t('Coworker.badges'),
                        languageStore.currentLanguage
                    )}
                    defaultPadding={false}
                >
                    <ValidatedFormItem name="badges" label={t('Coworker.badges')}>
                        <Input
                            defaultValue={mergeStrings(
                                userStore.userInfo?.badges?.map((badge) => badge) ?? [],
                                ', '
                            )}
                            disabled
                        />
                    </ValidatedFormItem>
                </Skeleton>
            </TitledWhitecardSection>

            <TitledWhitecardSection title={t('certifications')}>
                <ValidatedFormItem className="d-none" label="" name={'certifications'} />
                {certifications.map((cert) => (
                    <>
                        <CertificationItem
                            certification={cert}
                            key={cert.id}
                            onEdit={(certification: CertificationDtoWithId) =>
                                setCertificationModalState({
                                    visible: true,
                                    certification: certification,
                                })
                            }
                            onDelete={(certification) => {
                                onDeleteCertification(certification);
                            }}
                        />
                        <Divider marginBottom={20} marginTop={20} />
                    </>
                ))}

                <div
                    className="add-certification cursor-pointer"
                    onClick={() => {
                        setCertificationModalState({
                            visible: true,
                            certification: undefined,
                        });
                    }}
                >
                    <Button
                        text={t('add_certification')}
                        type="link"
                        width="hugged"
                        leftCircleIcon="AddIcon"
                        onClick={() => {
                            setCertificationModalState({
                                visible: true,
                                certification: undefined,
                            });
                        }}
                    />
                </div>
            </TitledWhitecardSection>

            <div className="d-flex">
                <SubmitButton
                    className="submit-btn"
                    text={t('save')}
                    type="tertiary"
                    onClick={() => onSubmit()}
                />
            </div>

            {changePictureModalVisible && (
                <Modal
                    className="EditImageModal"
                    visible={changePictureModalVisible}
                    onCancel={() => setChangePictureModalVisible(false)}
                    width={396}
                    headerColor={ModalHeaderColor.white}
                    maxHeight="418"
                >
                    <div className="image-picker-container">
                        <ProfileImagePicker
                            onFileChange={(imageUrl, base64, deleted) => {
                                setImageUrl(imageUrl);
                                setBase64(base64);
                                setImageDeleted(deleted ?? false);
                                setNavBlocked(true);
                            }}
                            imageUrl={imageUrl}
                            closeModal={() => setChangePictureModalVisible(false)}
                        />
                    </div>
                </Modal>
            )}

            {certificationModalState.visible && (
                <EditCertificationModal
                    visible={certificationModalState.visible}
                    onComplete={(success: boolean, certification?: CertificationDtoWithId) => {
                        setCertificationModalState({
                            visible: false,
                            certification: undefined,
                        });
                        if (success && certification) {
                            setNavBlocked(true);
                            setCertifications((prev) => {
                                const index = prev.findIndex(
                                    (cert) => cert.id === certification.id
                                );
                                if (index > -1) {
                                    prev.splice(index, 1, certification);
                                } else {
                                    prev.push(certification);
                                }
                                return [...prev];
                            });
                        }
                    }}
                    certification={certificationModalState.certification}
                />
            )}
        </div>
    );
});

export default ProfileDetailsTab;

export interface CertificationDtoWithId extends CertificationDto {
    id: string;
}
