import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubTechIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M7.71429 21C7.24286 21 6.83943 20.8399 6.504 20.5197C6.168 20.199 6 19.8136 6 19.3636V4.63636C6 4.18636 6.168 3.801 6.504 3.48027C6.83943 3.16009 7.24286 3 7.71429 3H16.2857C16.7571 3 17.1609 3.16009 17.4969 3.48027C17.8323 3.801 18 4.18636 18 4.63636V19.3636C18 19.8136 17.8323 20.199 17.4969 20.5197C17.1609 20.8399 16.7571 21 16.2857 21H7.71429ZM7.71429 18.5455V19.3636H16.2857V18.5455H7.71429ZM7.71429 16.9091H16.2857V7.09091H7.71429V16.9091ZM7.71429 5.45455H16.2857V4.63636H7.71429V5.45455Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubTechIcon;
