import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import FlexyLogo from 'Assets/Images/logo-color.svg';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { DASHBOARD_URL } from 'Models/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AuthenticationService } from 'Services/AuthenticationService';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import { UserService } from 'Services/UserService';
import { theme } from 'Style/theme';
import { displayTimeZone } from 'Utils/TimeUtils';
import OnboardingAmazingFeatures from './components/amazing-features';
import OnboardingFinishStep from './components/finish-step';
import OnboardingOtherFeatures from './components/other-features';
import PersonalInfo, { OnboardingPersonalInfoFormValues } from './components/personal-info';
import OnboardingProfileConfirm, {
    OnboardingProfileConfirmModel,
} from './components/profile-confirm';
import ProfilePicture, { OnboardingProfilePictureFormValues } from './components/profile-picture';
import Schedule from './components/schedule';
import OnboardingWelcomePage from './components/welcome-page';
import Workplaces, { OnboardingWorkplaceFormValues } from './components/workplaces';
import './index.less';
import { renderDefaultOfficeColumn } from 'Utils/WorkplaceUtils';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';
import { displayLanguages } from 'Utils/IntlUtils';
import SubmitButton from 'Components/submit-button/submit-button';

export interface OnboardingBaseProps {
    onPreviousClick: () => void;
    onNextClick: (formValues?: any) => void;
}

enum OnboardingStep {
    welcome,
    amazingFeatures,
    otherFeatures,
    personalInfo,
    profilePicture,
    workplaces,
    profileConfirm,
    schedule,
    finishStep,
}

const Onboarding: React.FunctionComponent = observer(() => {
    const numberOfSteps = Object.keys(OnboardingStep).length / 2;
    const { t } = useTranslation();
    const history = useHistory();
    const userService = useService(UserService);
    const authenticationService = useService(AuthenticationService);
    const azureAuthService = useService(AzureAdAuthenticationService);
    const {
        globalLoadingStore,
        toastStore,
        userStore,
        authenticationStore,
        policyStore,
        languageStore,
    } = useStores();
    const [currentStep, setCurrentStep] = useState<OnboardingStep>(OnboardingStep.welcome);

    const [personalInfoFormValues, setPersonalInfoFormValues] =
        useState<OnboardingPersonalInfoFormValues>();

    const [profilePictureFormValues, setProfilePictureFormValues] =
        useState<OnboardingProfilePictureFormValues>();

    const [workplacesFormValues, setWorkplacesFormValues] =
        useState<OnboardingWorkplaceFormValues>();

    const [scheduleFormValues, setScheduleFormValues] = useState<ScheduleEventDto[]>([]);

    const [confirmProfileStepInfo, setConfirmProfileStepInfo] =
        useState<OnboardingProfileConfirmModel>();

    useEffect(() => {
        //set profile confirm info
        if (userStore.userInfo && currentStep === OnboardingStep.profileConfirm) {
            setConfirmProfileStepInfo({
                id: userStore.userInfo.id ?? '',
                location: personalInfoFormValues?.locationLabel || '',
                timeZone:
                    displayTimeZone(
                        languageStore.currentLanguage,
                        personalInfoFormValues?.timeZone
                    ) || '',
                languages:
                    displayLanguages(
                        personalInfoFormValues?.languageCodes,
                        languageStore.currentLanguage
                    ) || '',
                department: personalInfoFormValues?.department || '',
                team: userStore.userInfo.team?.name || '',
                mainWorkplace: workplacesFormValues?.preferredWorkType,
                defaultOffice: workplacesFormValues?.defaultOffice
                    ? renderDefaultOfficeColumn({
                          defaultOffice: workplacesFormValues.defaultOffice,
                          defaultOfficeSpace: workplacesFormValues.defaultOfficeSpace,
                          defaultDesk: workplacesFormValues.defaultDesk,
                          defaultFloor: workplacesFormValues.defaultFloor,
                      })
                    : undefined,
                email: userStore.userInfo.contactInfo?.email || '',
                imageUrl: profilePictureFormValues?.image?.delete
                    ? undefined
                    : profilePictureFormValues?.imageUrl ?? userStore.userInfo.imageUrl,
                firstName: personalInfoFormValues?.firstName,
                lastName: personalInfoFormValues?.lastName,
                jobTitle: personalInfoFormValues?.jobTitle,
                role: t(
                    `ManagementRoleDto.ManagementRoleDto_${
                        userStore.userInfo.managementRoles?.[0]?.name ?? ManagementRoleDto.None
                    }`
                ),
            });
        }
    }, [currentStep, personalInfoFormValues, profilePictureFormValues, workplacesFormValues]);

    const fetchUserInfo = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            if (authenticationStore.userId) {
                const user = await userStore.setUserInfo(authenticationStore.userId);
                if (user?.team?.id) {
                    await policyStore.setPolicyRestrictions();
                    await policyStore.setPolicyObjectivesStats();
                }
            } else {
                if (!authenticationService.runSignInPromise) {
                    authenticationStore.setReturnUrl(
                        window.location.pathname + window.location.search
                    );
                    authenticationService.runSignInPromise = azureAuthService.runSignInFlow(
                        languageStore.currentLanguage
                    );
                }
                await authenticationService.runSignInPromise;
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, []);

    useEffect(() => {
        //if user refresh page, we lose store info
        if (userStore.userInfo === null) fetchUserInfo();
    }, [userStore.userInfo]);

    useEffect(() => {
        if (userStore.userInfo?.hasSeenOnboarding) history.push(DASHBOARD_URL);
    }, [userStore.userInfo?.hasSeenOnboarding]);

    const onSkipOnboardingClick = async () => {
        try {
            if (userStore.userInfo?.id) {
                globalLoadingStore.addLoading();

                const request: UpdateUserRequestDto = {
                    ...userStore.userInfo,
                    teamId: userStore.userInfo.team?.id,
                    defaultOfficeId: userStore.userInfo.defaultOffice?.id,
                    defaultOfficeSpaceId: userStore.userInfo.defaultOfficeSpace?.id,
                    defaultFloor: userStore.userInfo.defaultFloor,
                    locationGooglePlaceId: userStore.userInfo.location?.googlePlaceId,
                    hasSeenOnboarding: true,
                };

                await userService.updateUser(userStore.userInfo.id, request);

                await userStore.setUserInfo(userStore.userInfo.id);

                history.push(DASHBOARD_URL);
            }
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onFinishOnboarding = () => {
        onSkipOnboardingClick();
    };

    const submitProfileInformation = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            if (userStore.userInfo?.id) {
                const request: UpdateUserRequestDto = {
                    contactInfo: personalInfoFormValues?.contactInfo,
                    jobTitle: personalInfoFormValues?.jobTitle,
                    department: personalInfoFormValues?.department,
                    firstName: personalInfoFormValues?.firstName,
                    lastName: personalInfoFormValues?.lastName,
                    hasSeenOnboarding: false,
                    teamId: userStore.userInfo?.team?.id,
                    timeZone: personalInfoFormValues?.timeZone,
                    languageCodes: personalInfoFormValues?.languageCodes,
                    locationGooglePlaceId: personalInfoFormValues?.locationGooglePlaceId,
                    image: profilePictureFormValues?.image,
                    defaultOfficeId: workplacesFormValues?.defaultOffice?.id,
                    defaultOfficeSpaceId: workplacesFormValues?.defaultOfficeSpace?.id,
                    defaultDesk: workplacesFormValues?.defaultDesk,
                    defaultFloor: workplacesFormValues?.defaultFloor,
                    preferredWorkType: workplacesFormValues?.preferredWorkType
                        ? workplacesFormValues.preferredWorkType
                        : UserPreferredWorkTypeDto.Office,
                    badges: userStore.userInfo.badges,
                    managementRoles: userStore.userInfo.managementRoles,
                    preferredLanguageCode: userStore.userInfo.preferredLanguageCode,
                };

                await userService.updateUser(userStore.userInfo!.id!, request);

                await userStore.setUserInfo(userStore.userInfo.id);

                setCurrentStep((old) => old + 1);
            }
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="Onboarding">
            <div className="header">
                <div className="fake-container" />

                <div className="logo-container">
                    <img className="logo" src={FlexyLogo} alt="logo" />
                </div>

                <SubmitButton
                    className="skip-onboarding"
                    text={t('Onboarding.skip_onboarding')}
                    type="link"
                    width="hugged"
                    rightIcon="DoorIcon"
                    onClick={onSkipOnboardingClick}
                />
            </div>

            <div
                className="progression Shadow-900"
                style={{
                    backgroundColor:
                        currentStep === numberOfSteps - 1
                            ? theme['success-mid-contrast']
                            : theme['primary-mid-contrast'],
                    width: `${(currentStep + 1) * (100 / numberOfSteps)}%`,
                }}
            />

            {currentStep === OnboardingStep.welcome && (
                <OnboardingWelcomePage
                    onSkipClick={onSkipOnboardingClick}
                    onStartClick={() => setCurrentStep((old) => old + 1)}
                />
            )}

            {currentStep === OnboardingStep.amazingFeatures && (
                <OnboardingAmazingFeatures
                    onNextClick={() => {
                        setCurrentStep((old) => old + 1);
                    }}
                    onPreviousClick={() => setCurrentStep((old) => old - 1)}
                />
            )}

            {currentStep === OnboardingStep.otherFeatures && (
                <OnboardingOtherFeatures
                    onNextClick={() => {
                        setCurrentStep((old) => old + 1);
                    }}
                    onPreviousClick={() => setCurrentStep((old) => old - 1)}
                />
            )}

            {currentStep === OnboardingStep.personalInfo && (
                <PersonalInfo
                    onNextClick={(formValues: OnboardingPersonalInfoFormValues) => {
                        setPersonalInfoFormValues(formValues);
                        setCurrentStep((old) => old + 1);
                    }}
                    onPreviousClick={() => setCurrentStep((old) => old - 1)}
                    previousFormValues={personalInfoFormValues}
                />
            )}

            {currentStep === OnboardingStep.profilePicture && (
                <ProfilePicture
                    onNextClick={(formValues: OnboardingProfilePictureFormValues) => {
                        setProfilePictureFormValues(formValues);
                        setCurrentStep((old) => old + 1);
                    }}
                    onPreviousClick={() => setCurrentStep((old) => old - 1)}
                    previousFormValue={profilePictureFormValues}
                />
            )}

            {currentStep === OnboardingStep.workplaces && (
                <Workplaces
                    onNextClick={(formValues: OnboardingWorkplaceFormValues) => {
                        setCurrentStep((old) => old + 1);
                        setWorkplacesFormValues(formValues);
                    }}
                    onPreviousClick={() => setCurrentStep((old) => old - 1)}
                    previousFormValues={workplacesFormValues}
                />
            )}

            {currentStep === OnboardingStep.profileConfirm && confirmProfileStepInfo && (
                <OnboardingProfileConfirm
                    onNextClick={() => {
                        submitProfileInformation();
                    }}
                    onPreviousClick={() => {
                        setCurrentStep((old) => old - 1);
                    }}
                    userInfo={confirmProfileStepInfo}
                />
            )}

            {currentStep === OnboardingStep.schedule && (
                <Schedule
                    onNextClick={(formValues: ScheduleEventDto[]) => {
                        setScheduleFormValues(formValues);
                        setCurrentStep((old) => old + 1);
                    }}
                    onPreviousClick={() => {
                        /*no previous*/
                    }}
                    schedule={scheduleFormValues}
                />
            )}

            {currentStep === OnboardingStep.finishStep && (
                <OnboardingFinishStep
                    onNextClick={onFinishOnboarding}
                    onPreviousClick={() => setCurrentStep((old) => old - 1)}
                />
            )}
        </div>
    );
});

export default Onboarding;
