import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';
import logo from './slack.png';

const SlackIcon: FunctionComponent<SvgProps> = () => {
    return (
        <span role="img" className="anticon">
            <img src={logo} width="28" height="28" />
        </span>
    );
};

export default SlackIcon;
