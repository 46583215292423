import OnboardingCalendar from 'Assets/Images/onboarding-calendar.svg';
import OnboardingCoworker from 'Assets/Images/onboarding-coworkers.png';
import OnboardingClubs from 'Assets/Images/onboarding-clubs.svg';
import Button from 'Components/button';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingBaseProps } from '..';
import './amazing-features.less';
import './base-styles.less';

const OnboardingAmazingFeatures: React.FunctionComponent<OnboardingBaseProps> = observer(
    ({ onNextClick, onPreviousClick }) => {
        const { t } = useTranslation();
        const handleNextClick = () => {
            onNextClick();
        };
        return (
            <div className="AmazingFeatures OnboardingBase">
                <div className="text-title-1-bold mb-20 centered pt-40">
                    {t('Onboarding.amazing_features')}
                </div>

                <div className="text-callout text-mid-contrast mb-20 centered subtitle">
                    {t('Onboarding.amazing_features_subtitle')}
                </div>

                <div className="content">
                    <div className="cell restrictions">
                        <img src={OnboardingCalendar} />

                        <div className="title text-headline text-high-contrast">
                            {t('restrictions_and_objectives')}
                        </div>

                        <div className="text-regular-caption text-mid-contrast subtitle">
                            {t('Onboarding.amazing_feature_subtitle_left')}
                        </div>
                    </div>

                    <div className="cell clubs">
                        <img src={OnboardingClubs} />
                        <div className="title text-headline text-high-contrast">
                            {t('events_and_clubs')}
                        </div>

                        <div className="text-regular-caption text-mid-contrast subtitle">
                            {t('Onboarding.amazing_feature_subtitle_middle')}
                        </div>
                    </div>

                    <div className="cell coworkers">
                        <img src={OnboardingCoworker} />
                        <div className="title text-headline text-high-contrast">
                            {t('coworkers')}
                        </div>
                        <div className="text-regular-caption text-mid-contrast subtitle">
                            {t('Onboarding.amazing_feature_subtitle_right')}
                        </div>
                    </div>
                </div>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button text={t('previous')} type="tertiary" onClick={onPreviousClick} />
                        <Button text={t('next')} type="primary" onClick={() => handleNextClick()} />
                    </div>
                </div>
            </div>
        );
    }
);

export default OnboardingAmazingFeatures;
