import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { TagColor } from 'Components/tag/tag';

export const getWorkTypeTagColor = (type: WorkTypeDto): TagColor => {
    switch (type) {
        case WorkTypeDto.Away:
            return TagColor.grey;
        case WorkTypeDto.Office:
            return TagColor.purple;
        case WorkTypeDto.Remote:
            return TagColor.blue;
        case WorkTypeDto.Unavailable:
            return TagColor.red;
    }
};

export const getEventTypeTagColor = (type: EventTypeDto): TagColor => {
    switch (type) {
        case EventTypeDto.Away:
            return TagColor.grey;
        case EventTypeDto.OffHourAvailability:
            return TagColor.yellow;
        case EventTypeDto.Work:
            return TagColor.green;
    }
};
