import React from 'react';
import './side-skeleton-shapes.less';

// LARGE SIZE

export const sideNavigationShape = (
    <div className="sideNavigationShape">
        <div className="one-row">
            <div className="circle" />
            <div className="rect" />
        </div>

        <div className="one-row">
            <div className="circle" />
            <div className="rect" />
        </div>

        <div className="one-row">
            <div className="circle" />
            <div className="rect" />
        </div>
    </div>
);

export const sideNavigationLogoContainer = (
    <div className="sideNavigationLogoContainer">
        <div className="buttonRect" />
    </div>
);

// SMALL SIZE

export const sideNavigationShapeSmall = (
    <div className="sideNavigationShapeSmall">
        <div className="one-row">
            <div className="circle" />
        </div>

        <div className="one-row">
            <div className="circle" />
        </div>

        <div className="one-row">
            <div className="circle" />
        </div>

        <div className="one-row">
            <div className="circle" />
        </div>
    </div>
);

export const sideNavigationLogoContainerSmall = (
    <div className="sideNavigationLogoContainerSmall">
        <div className="buttonRect" />
    </div>
);
