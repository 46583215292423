import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { GetOfficesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficesSortColumnDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetDayUserCountsRequestDto } from 'Api/Features/Schedules/Dtos/GetDayUserCountsRequestDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserTeamFilterModeDto } from 'Api/Features/Users/Dtos/UserTeamFilterModeDto';
import HelloGlanceDropdown, { HelloGlanceDropdownData } from 'Components/hello-glance-dropdown';
import ModalWeeklyObjectives from 'Components/modal-weekly-objectives';
import { singleSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import AsyncSingleSelect, {
    SelectFetchFunctionPromise,
} from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import Skeleton from 'Components/skeleton';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { DROPDOWN_PAGE_SIZE, PLAN_SCHEDULE_URL, FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { OfficeService } from 'Services/OfficeService';
import { ScheduleService } from 'Services/ScheduleService';
import { getTotalHoursInEvents, getTotalHoursInEventsType } from 'Utils/CalendarUtils';
import { createTimeMomentInTimezoneWithoutConverting } from 'Utils/TimeUtils';
import DashboardMobileNextDays from './components/dashboard-mobile-next-days';
import './index.less';
import { dashboardMobileHelloGlanceDropdownShape } from './skeleton-shapes';
import WorkingCoworkersModal, {
    WorkingCoworkersModalMode,
} from 'Components/coworkers-at-office-modal/working-coworkers-modal';

const DashboardMobile: React.FunctionComponent = observer(() => {
    const officeService = useService(OfficeService);
    const scheduleService = useService(ScheduleService);

    const { userStore, policyStore, toastStore } = useStores();
    const [helloGlanceDropdownData, setHelloGlanceDropdownData] =
        useState<HelloGlanceDropdownData | null>(null);
    const [officeSearchResults, setOfficeSearchResults] = useState<OfficeDto[]>([]);
    const [officeOptions, setOfficeOptions] = useState<SingleSelectCustomOption[]>([]);
    const [selectedOfficeId, setSelectedOfficeFilterId] = useState<string | undefined>('all');
    const [selectedOfficeOption, setSelectedOfficeFilterOption] = useState<
        SingleSelectCustomOption | undefined
    >();
    const [modalWeeklyObjectivesOpen, setModalWeeklyObjectivesOpen] = useState(false);
    const [workingCoworkerModalState, setWorkingCoworkerModalState] = useState<{
        visible: boolean;
        startingDate?: string;
        workType?: WorkTypeDto;
        mode: WorkingCoworkersModalMode;
    }>({
        visible: false,
        mode: WorkingCoworkersModalMode.CoworkerList,
    });

    const { t } = useTranslation();
    const history = useHistory();

    const fetchHelloGlanceDropdownData = async () => {
        try {
            if (userStore?.userInfo?.id) {
                const dayUserCountsRequest: GetDayUserCountsRequestDto = {
                    teamIds: userStore.userInfo.team?.id ? [userStore.userInfo.team.id] : undefined,
                    startTime: moment().format(),
                    endTime: moment().format(),
                    excludedUserIds: [userStore.userInfo.id],
                    favoritesForUserId: userStore.userInfo.id,
                    teamFilterMode: UserTeamFilterModeDto.MembersAndFavorites,
                };

                const reponse = await scheduleService.getDayUserCounts(dayUserCountsRequest);

                const userScheduleRequest: GetUserScheduleRequestDto = {
                    timeZone: userStore.userInfo.timeZone,
                    startDate: moment().startOf('week').format('YYYY-MM-DD'),
                    endDate: moment().endOf('week').format('YYYY-MM-DD'),
                };
                const schedule = await scheduleService.getUserSchedule(
                    userStore.userInfo.id,
                    userScheduleRequest
                );

                const totalHoursWorks = getTotalHoursInEvents(
                    schedule?.events?.filter((event) => event !== null).map((event) => event!) ??
                        [],
                    getTotalHoursInEventsType.workHoursPerWeek
                );
                const totalAvailabilityHours = getTotalHoursInEvents(
                    schedule?.events?.filter((event) => event !== null).map((event) => event!) ??
                        [],
                    getTotalHoursInEventsType.availabilityHoursPerWeek
                );

                const data = {
                    name: `${t('hello')}, ${userStore.userInfo?.firstName ?? ''}`,
                    currentlyWorking: {
                        remote: 0,
                        office: 0,
                        openRemoteModal: () =>
                            setWorkingCoworkerModalState({
                                visible: true,
                                startingDate: moment().format(FORMAT_YEAR_MONTH_DAY),
                                workType: WorkTypeDto.Remote,
                                mode: WorkingCoworkersModalMode.CurrentlyWorking,
                            }),
                        openOfficeModal: () =>
                            setWorkingCoworkerModalState({
                                visible: true,
                                startingDate: moment().format(FORMAT_YEAR_MONTH_DAY),
                                workType: WorkTypeDto.Office,
                                mode: WorkingCoworkersModalMode.CurrentlyWorking,
                            }),
                    },
                    objectives: {
                        completedObjectives:
                            policyStore.policyInfo?.policyObjectiveStats?.totalScore.completed ?? 0,
                        totalObjectives:
                            policyStore.policyInfo?.policyObjectiveStats?.totalScore.total ?? 0,
                        openModal: () => setModalWeeklyObjectivesOpen(true),
                    },
                    hoursOfWork: {
                        workHours: totalHoursWorks,
                        availabilityHours: totalAvailabilityHours,
                    },
                };

                setHelloGlanceDropdownData({
                    ...data,
                    currentlyWorking: {
                        ...data.currentlyWorking,
                        remote: reponse?.remote ?? 0,
                        office: reponse?.office ?? 0,
                    },
                });
            }
        } catch (err: any) {
            if (!err.treated) {
                toastStore.genericError();
            }
        }
    };

    const searchOffices = async (
        page: number,
        searchTerm: string
    ): Promise<SelectFetchFunctionPromise> => {
        const args: GetOfficesRequestDto = {
            sortColumn: GetOfficesSortColumnDto.IsUserDefaultOffice,
            page: page,
            pageSize: DROPDOWN_PAGE_SIZE,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await officeService.getOffices(args);
        setOfficeSearchResults((prev) => [...prev, ...results]);

        return {
            maxResultHit: results.length + DROPDOWN_PAGE_SIZE * page >= totalItemCount,
        };
    };

    const onChangeSelectedOfficeFilter = (value?: SingleSelectCustomOption) => {
        if (value?.value !== selectedOfficeId) {
            setSelectedOfficeFilterId(value?.value);
            setSelectedOfficeFilterOption(value);
        }
    };

    useEffect(() => {
        if (userStore.userInfo?.id) policyStore.setPolicyObjectivesStats();
    }, [userStore.userInfo?.id]);

    useEffect(() => {
        if (userStore?.userInfo?.id) fetchHelloGlanceDropdownData();
    }, [userStore?.userInfo?.id]);

    useEffect(() => {
        const searchResults = officeSearchResults?.map(
            (office: OfficeDto) =>
                ({
                    value: office.id || '',
                    label: office.name || '',
                } as SingleSelectCustomOption)
        );

        searchResults.unshift({
            value: 'all',
            label: t('all_offices'),
        });

        const merged = singleSelectMergeSelectedOptionsWithSearchResults(searchResults, [
            selectedOfficeOption,
        ]);

        setOfficeOptions(merged);
    }, [officeSearchResults, selectedOfficeOption]);

    return (
        <div className="DashboardMobile">
            <Skeleton
                isLoaded={helloGlanceDropdownData !== null}
                placeholder={dashboardMobileHelloGlanceDropdownShape}
            >
                <HelloGlanceDropdown data={helloGlanceDropdownData!} />
            </Skeleton>

            <div className="dashboard-content">
                <AsyncSingleSelect
                    fetchFunction={searchOffices}
                    resetSearchResults={() => setOfficeOptions([])}
                    options={officeOptions}
                    selected={selectedOfficeId}
                    onChange={onChangeSelectedOfficeFilter}
                    isSearchable={false}
                    placeholder={t('SelectCustom.default_select_placeholder')}
                />

                <DashboardMobileNextDays
                    selectedOfficeFilterName={selectedOfficeOption?.label ?? t('all_offices')}
                    selectedOfficeFilterId={selectedOfficeOption?.value || undefined}
                    setWorkingCoworkerModalState={setWorkingCoworkerModalState}
                />
            </div>

            {workingCoworkerModalState.visible && (
                <WorkingCoworkersModal
                    onComplete={(copyEvent?: ScheduleEventDto) => {
                        setWorkingCoworkerModalState({
                            visible: false,
                            mode: WorkingCoworkersModalMode.CoworkerList,
                        });
                        if (copyEvent) {
                            history.push(
                                PLAN_SCHEDULE_URL +
                                    `?date=${createTimeMomentInTimezoneWithoutConverting(
                                        copyEvent.startTime ?? ''
                                    ).format(FORMAT_YEAR_MONTH_DAY)}`,
                                { copyEvent }
                            );
                        }
                    }}
                    visible={workingCoworkerModalState.visible}
                    startingDate={workingCoworkerModalState.startingDate ?? ''}
                    teamId={userStore.userInfo?.team?.id ?? ''}
                    workType={workingCoworkerModalState.workType ?? WorkTypeDto.Office}
                    mode={workingCoworkerModalState.mode}
                    selectedOfficeFilter={
                        selectedOfficeOption && selectedOfficeOption.value !== 'all'
                            ? selectedOfficeOption
                            : undefined
                    }
                    isMobile
                />
            )}

            {modalWeeklyObjectivesOpen && (
                <ModalWeeklyObjectives
                    isVisible={modalWeeklyObjectivesOpen}
                    onCancel={() => setModalWeeklyObjectivesOpen(false)}
                    isConstantWeek
                    displayEditButton={false}
                    mobilefullScreen
                />
            )}
        </div>
    );
});

export default DashboardMobile;
