import { Input } from 'antd';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { GetOfficesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficesSortColumnDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetDaySchedulesRequestDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesRequestDto';
import { GetDayUserCountsRequestDto } from 'Api/Features/Schedules/Dtos/GetDayUserCountsRequestDto';
import { ScheduleDayDto } from 'Api/Features/Schedules/Dtos/ScheduleDayDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { ScheduleUserDto } from 'Api/Features/Schedules/Dtos/ScheduleUserDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { UserTeamFilterModeDto } from 'Api/Features/Users/Dtos/UserTeamFilterModeDto';
import Button from 'Components/button';
import HorizontalRadio from 'Components/horizontal-radio';
import { MagnifyingGlassIcon } from 'Components/icons';
import AsyncSingleSelect, {
    SelectFetchFunctionPromise,
} from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { useAsyncSingleSelectProps, useService, useStores, useDebounce } from 'Hooks';
import { observer } from 'mobx-react-lite';
import {
    COWORKERS_URL,
    COWORKER_TIMESHEET_URL,
    FORMAT_DAY_WRITTEN,
    DROPDOWN_PAGE_SIZE,
    EMPTY_GUID,
    FORMAT_MONTH_WRITTEN_DATE_YEAR,
    PAGE_SIZE_INFINITE_LOADING,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { OfficeService } from 'Services/OfficeService';
import { ScheduleService } from 'Services/ScheduleService';
import { TeamService } from 'Services/TeamService';
import { theme } from 'Style/theme';
import { getCoworkerWorkingHours } from 'Utils/CoworkerUtils';
import CoworkerScheduleModal from './components/coworker-schedule-modal';
import PageContentCoworkers from './components/page-content';
import './index.less';
import { ActionReducerCoworkers, initStateCoworkers, reducerCoworkers } from './reducer';

export interface GetDailySchedulesResponseItem {
    user: ScheduleUserDto;
    events: ScheduleEventDto[] | null;
    day?: ScheduleDayDto | null;
}

export interface CoworkerFilterGroups {
    all: GetDailySchedulesResponseItem[];
    away: GetDailySchedulesResponseItem[];
    office: GetDailySchedulesResponseItem[];
    remote: GetDailySchedulesResponseItem[];
    byOffice: Map<string, GetDailySchedulesResponseItem[]>;
}

export enum ActionPagination {
    'next',
    'prev',
}

const Coworkers: React.FunctionComponent = observer(() => {
    const [
        {
            coworkersNeedToReload,
            coworkersIsLoading,
            currentPageCoworkers,
            drawerVisible,
            selectedCoworker,
            allCoworkers,
            totalAllCoworkersApi,
            drawerAnimationEnded,
            totalCoworkersAlreadyLoad,
            selectedOffice,
            selectedWorkTypeFilter,
            coworkerWeeklyHourPrefModalOpen,
            searchTerm,
            noScroll,
            workTypeFilterOptions,
            selectedTeams,
            optionsOffices,
        },
        dispatch,
    ] = useReducer(reducerCoworkers, initStateCoworkers);

    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const debounceSearch = useDebounce(searchTerm || '');

    const { toastStore, userStore, languageStore } = useStores();

    const scheduleService = useService(ScheduleService);
    const teamService = useService(TeamService);
    const officeService = useService(OfficeService);
    const [coworkerDisplay, setCoworkerDisplay] = useState([]);

    const coworkerCardRef = useRef<(null | HTMLDivElement)[]>([]);

    const { asyncSingleSelectProps: teamSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetTeamsRequestDto) =>
                await teamService.getTeams(request),
        },
        entityToSingleSelectCustomOption: (team: TeamDto) =>
            ({
                label:
                    team.id === userStore.userInfo?.team?.id
                        ? `${team.name} ${t('parenthesis_my_team')}`
                        : team.name,
                value: team.id,
            } as SingleSelectCustomOption),
        extraMandatoryOptions: {
            unshift: [
                {
                    value: 'favorites',
                    label: t('my_favorites'),
                },
                { value: 'all', label: t('all_coworkers') },
            ],
            push: [
                {
                    value: 'no',
                    label: t('no_teams'),
                },
            ],
        },
    });

    const scrollToSelectedCoworkerCard = (id: string) =>
        coworkerCardRef.current[id]?.scrollIntoView({ block: 'start', behavior: 'smooth' });

    const resetCoworkerSelection = () => {
        dispatch({ type: ActionReducerCoworkers.resetCoworkerSelection });
        history.push({ pathname: COWORKERS_URL });
    };

    const resetCallApiCoworker = () =>
        dispatch({ type: ActionReducerCoworkers.resetCoworkersCall });

    const selectCoworker = (coworker: GetDailySchedulesResponseItem) =>
        dispatch({ type: ActionReducerCoworkers.selectCoworkers, value: coworker });

    const handlePageCoworkers = (action: ActionPagination) => {
        if (action === ActionPagination.prev && currentPageCoworkers === 0) return;

        if (action === ActionPagination.next)
            return dispatch({ type: ActionReducerCoworkers.nextPageCoworkers });
        dispatch({ type: ActionReducerCoworkers.prevPageCoworkers });
    };

    const fetchCoworkers = useCallback(
        async (searchTerm?: string) => {
            const paginationTotalIsFetch =
                currentPageCoworkers && totalCoworkersAlreadyLoad >= totalAllCoworkersApi;
            // Si aucune aucune team ou tous les items de la pagination sont atteinte.
            if (!selectedTeams || paginationTotalIsFetch) return;

            // Optimisation - Si le tab sur lequel on est à 0, alors aucun appel api.
            // TODO Gènère des blugs autres. (Plusieurs call API)
            // https://appcom.atlassian.net/browse/FLEXY-2272
            // if (selectedWorkTypeFilter && workTypeFilterOptions) {
            //     const currentCount = workTypeFilterOptions.find(
            //         (el) => el.value == selectedWorkTypeFilter
            //     )?.count;

            //     if (currentCount != undefined && currentCount === 0) {
            //         return dispatch({
            //             type: ActionReducerCoworkers.fetchCoworkersSuccess,
            //             value: {
            //                 response: [[], 0],
            //                 coworkers: [],
            //             },
            //         });
            //     }
            // }

            try {
                const getTeamsId = () => {
                    if (selectedTeams) {
                        if (selectedTeams == 'all') return undefined;
                        if (selectedTeams == 'favorites') return undefined;
                        if (selectedTeams == 'no') return [EMPTY_GUID];
                        return [selectedTeams];
                    }
                    return undefined;
                };

                const teamId = getTeamsId();
                let teamRequest = {};
                if (teamId)
                    teamRequest = {
                        teamIds: teamId,
                        teamFilterMode: UserTeamFilterModeDto.MembersAndManagers,
                    };

                const dayWorkTypes =
                    selectedWorkTypeFilter === 'all'
                        ? undefined
                        : WorkTypeDto[selectedWorkTypeFilter];

                const request: GetDaySchedulesRequestDto = {
                    ...teamRequest,
                    date: moment().format(FORMAT_YEAR_MONTH_DAY),
                    timeZone: userStore.userInfo?.timeZone,
                    searchTerm: searchTerm == 'undefined' ? undefined : searchTerm,
                    pageSize: PAGE_SIZE_INFINITE_LOADING,
                    page: currentPageCoworkers,
                    dayWorkTypes: dayWorkTypes === WorkTypeDto.Away ? undefined : dayWorkTypes,
                    dayOfficeIds:
                        selectedOffice && selectedOffice != 'all' ? [selectedOffice] : undefined,
                    dayExcludedWorkTypes:
                        dayWorkTypes === WorkTypeDto.Away
                            ? [WorkTypeDto.Office, WorkTypeDto.Remote]
                            : undefined,
                    favoritesForUserId: userStore.userInfo?.id,
                    favoritesOnly: selectedTeams == 'favorites' ? true : undefined,
                };
                const [responseCoworkers, responseTotalCoworkers] =
                    await scheduleService.getDailySchedules(request);

                if (responseCoworkers !== undefined && responseTotalCoworkers !== undefined) {
                    const coworkers = responseCoworkers
                        .filter((x) => x.user !== null)
                        .filter((x) => x.user?.id !== userStore.userInfo?.id)
                        .map(
                            (x) =>
                                ({
                                    events:
                                        x.events?.filter((x) => x !== null).map((x) => x!) ?? null,
                                    user: x.user!,
                                    day: x.day,
                                } as GetDailySchedulesResponseItem)
                        );

                    dispatch({
                        type: ActionReducerCoworkers.fetchCoworkersSuccess,
                        value: {
                            response: [responseCoworkers, responseTotalCoworkers],
                            coworkers,
                        },
                    });
                }
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            }
        },
        [
            toastStore,
            scheduleService,
            selectedTeams,
            currentPageCoworkers,
            selectedWorkTypeFilter,
            selectedOffice,
        ]
    );

    // OFFICES
    const fetchOffices = useCallback(
        async (page: number, searchTerm: string): Promise<SelectFetchFunctionPromise> => {
            const createOptionsOffices = (listOffices: OfficeDto[]) => {
                const options: SingleSelectCustomOption[] = [];

                listOffices.forEach((office) => {
                    const asyncSelectCustomOption: SingleSelectCustomOption = {
                        value: office.id || '',
                        label: office.name || '',
                    };

                    options.push(asyncSelectCustomOption);
                });

                const allAlreadyExist =
                    optionsOffices.filter((office) => office.value == 'all').length > 0;
                if (allAlreadyExist) return options;

                const optionsAll: SingleSelectCustomOption = {
                    value: 'all',
                    label: t('all_offices'),
                };

                options.unshift(optionsAll);
                return options;
            };

            try {
                const request: GetOfficesRequestDto = {
                    sortColumn: GetOfficesSortColumnDto.IsUserDefaultOffice,
                    searchTerm: searchTerm,
                    page: page,
                    pageSize: DROPDOWN_PAGE_SIZE,
                };

                const response = await officeService.getOffices(request);
                const optionsOffices = createOptionsOffices(response[0]);

                dispatch({
                    type: ActionReducerCoworkers.handle,
                    value: optionsOffices,
                    field: 'optionsOffices',
                });
                return {
                    maxResultHit: response[0].length + DROPDOWN_PAGE_SIZE * page >= response[1],
                };
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            }
            return { maxResultHit: true };
        },
        [userStore.userInfo?.id]
    );

    const resetOfficesSearch = () => {
        dispatch({
            type: ActionReducerCoworkers.handle,
            value: [],
            field: 'optionsOffices',
        });
    };

    useEffect(() => {
        if (userStore.userInfo?.id) {
            fetchCoworkers(searchTerm);
        }
    }, [
        fetchCoworkers,
        selectedTeams,
        currentPageCoworkers,
        selectedOffice,
        userStore.userInfo?.id,
    ]);

    useEffect(() => {
        resetCallApiCoworker();
        fetchCoworkers(searchTerm);
        fetchCountCoworkers(searchTerm);
    }, [debounceSearch]);

    const fetchCountCoworkers = useCallback(
        async (searchTerm: string) => {
            if (!selectedTeams) return;
            try {
                resetCoworkerCounts();
                const teamIds =
                    selectedTeams &&
                    selectedTeams != 'all' &&
                    selectedTeams != 'no' &&
                    selectedTeams != 'favorites'
                        ? [selectedTeams]
                        : selectedTeams == 'no'
                        ? [EMPTY_GUID]
                        : undefined;

                let teamRequest = {};
                if (teamIds)
                    teamRequest = {
                        teamIds: teamIds,
                        teamFilterMode: UserTeamFilterModeDto.MembersAndManagers,
                    };

                const request: GetDayUserCountsRequestDto = {
                    ...teamRequest,
                    date: moment().format(FORMAT_YEAR_MONTH_DAY),
                    searchTerm: searchTerm == 'undefined' ? undefined : searchTerm,
                    excludedUserIds: [userStore.userInfo?.id || ''],
                    favoritesForUserId: userStore.userInfo?.id,
                    favoritesOnly: selectedTeams == 'favorites' ? true : undefined,
                };
                const response = await scheduleService.getDayUserCounts(request);

                const OfficeUser = response?.office || 0;
                const RemoteUser = response?.remote || 0;
                const AwayUser = response?.notWorking || 0;

                dispatch({
                    type: ActionReducerCoworkers.handle,
                    value: [
                        { label: t('all'), value: 'all', countIsLoading: false },
                        {
                            label: t('Coworker.work_week_type_office'),
                            value: WorkTypeDto.Office,
                            count: OfficeUser,
                            countIsLoading: false,
                        },
                        {
                            label: t('Coworker.work_week_type_remote'),
                            value: WorkTypeDto.Remote,
                            count: RemoteUser,
                            countIsLoading: false,
                        },
                        {
                            label: t('Coworker.work_week_type_away'),
                            value: WorkTypeDto.Away,
                            count: AwayUser,
                            countIsLoading: false,
                        },
                    ],
                    field: 'workTypeFilterOptions',
                });
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            }
        },
        [selectedTeams, userStore.userInfo?.id]
    );

    const resetCoworkerCounts = () => {
        dispatch({
            type: ActionReducerCoworkers.handle,
            value: [
                { label: t('all'), value: 'all', countIsLoading: false },
                {
                    label: t('Coworker.work_week_type_office'),
                    value: WorkTypeDto.Office,
                    count: 0,
                    countIsLoading: true,
                },
                {
                    label: t('Coworker.work_week_type_remote'),
                    value: WorkTypeDto.Remote,
                    count: 0,
                    countIsLoading: true,
                },
                {
                    label: t('Coworker.work_week_type_away'),
                    value: WorkTypeDto.Away,
                    count: 0,
                    countIsLoading: true,
                },
            ],
            field: 'workTypeFilterOptions',
        });
    };

    useEffect(() => {
        //set the tab filters on render, the count will come after
        dispatch({
            type: ActionReducerCoworkers.handle,
            value: [
                { label: t('all'), value: 'all', countIsLoading: false },
                {
                    label: t('Coworker.work_week_type_office'),
                    value: WorkTypeDto.Office,
                    countIsLoading: true,
                    count: undefined,
                },
                {
                    label: t('Coworker.work_week_type_remote'),
                    value: WorkTypeDto.Remote,
                    countIsLoading: true,
                    count: undefined,
                },
                {
                    label: t('Coworker.work_week_type_away'),
                    value: WorkTypeDto.Away,
                    countIsLoading: true,
                    count: undefined,
                },
            ],
            field: 'workTypeFilterOptions',
        });
    }, []);

    useEffect(() => {
        if (userStore.userInfo?.id) fetchCountCoworkers('');
    }, [selectedTeams, userStore.userInfo?.id]);

    useEffect(() => {
        if (!(id && coworkerDisplay)) return;

        const coworker = coworkerDisplay.find((item: any) => item.user.id === id);
        coworker && selectCoworker(coworker);
    }, [id, coworkerDisplay]);

    useEffect(() => {
        if (drawerAnimationEnded && !!selectedCoworker?.user.id && !noScroll)
            scrollToSelectedCoworkerCard(selectedCoworker?.user.id);
    }, [drawerAnimationEnded, selectedCoworker?.user.id]);

    // TEAMS
    useEffect(() => {
        //once info from user is available, select his team (or all coworkers if teamless) in the team dropdown
        if (userStore.userInfo?.id) {
            if (userStore.userInfo.team) {
                teamSelectProps.onChange({
                    value: userStore.userInfo.team.id ?? '',
                    label: userStore.userInfo.team.name
                        ? `${userStore.userInfo.team.name} ${t('parenthesis_my_team')}`
                        : '',
                });
            } else {
                teamSelectProps.onChange({ value: 'all', label: t('all_coworkers') });
            }
        }
    }, [userStore.userInfo?.id, userStore.userInfo?.team]);

    useEffect(() => {
        //only dispatch team selection when team selected. Dropdown is not clearable
        if (teamSelectProps.selectedOption) {
            dispatch({
                type: ActionReducerCoworkers.handle,
                value: teamSelectProps.selectedOption?.value,
                field: 'selectedTeams',
            });
            dispatch({
                type: ActionReducerCoworkers.handle,
                value: true,
                field: 'coworkersNeedToReload',
            });

            resetCallApiCoworker();
        }
    }, [teamSelectProps.selectedOption]);

    const onChangeOffice = (value: any) => {
        dispatch({
            type: ActionReducerCoworkers.handle,
            value: value.value,
            field: 'selectedOffice',
        });
        resetCallApiCoworker();
    };

    const onChangeWorkTypeFilter = (value: string) => {
        dispatch({
            type: ActionReducerCoworkers.handle,
            value: value === 'all' ? value : WorkTypeDto[value],
            field: 'selectedWorkTypeFilter',
        });

        if (value !== WorkTypeDto.Office)
            dispatch({
                type: ActionReducerCoworkers.handle,
                value: 'all',
                field: 'selectedOffice',
            });

        dispatch({
            type: ActionReducerCoworkers.handle,
            value: true,
            field: 'coworkersNeedToReload',
        });
        resetCoworkerSelection();
        resetCallApiCoworker();
    };

    const onChangeSearchTerm = (event: any) => {
        dispatch({
            type: ActionReducerCoworkers.handle,
            value: event.target.value || 'undefined',
            field: 'searchTerm',
        });
    };

    useEffect(() => {
        setCoworkerDisplay(allCoworkers);
    }, [allCoworkers]);

    return (
        <div className="Coworkers">
            <div className="filters-container">
                <div className="date text-title-3">
                    <div className="headline-mixin capitalize">
                        {moment().format(FORMAT_DAY_WRITTEN)}
                    </div>
                    <div className="body-mixin">
                        {moment().format(
                            FORMAT_MONTH_WRITTEN_DATE_YEAR[languageStore.currentLanguage]
                        )}
                    </div>
                </div>

                <div className="right">
                    <AsyncSingleSelect
                        {...teamSelectProps}
                        placeholder={t('SelectCustom.default_select_placeholder')}
                    />

                    <Input
                        className="search"
                        placeholder={t('search')}
                        onChange={onChangeSearchTerm}
                        prefix={<MagnifyingGlassIcon fill={theme['primary-mid-contrast']} />}
                    />

                    <Button
                        text={t('schedules')}
                        type="secondary"
                        width="hugged"
                        leftIcon={'CalendarIcon'}
                        onClick={() => history.push(COWORKERS_URL + COWORKER_TIMESHEET_URL)}
                    />
                </div>

                <HorizontalRadio
                    name={'workTypeFilter'}
                    defaultValue={'all'}
                    options={workTypeFilterOptions}
                    onChange={onChangeWorkTypeFilter}
                />
            </div>

            <PageContentCoworkers
                showManagerTag={selectedTeams != 'all' && selectedTeams != 'no'}
                selectedWorkTypeFilter={selectedWorkTypeFilter}
                drawerVisible={drawerVisible}
                searchTerm={searchTerm}
                setDrawerAnimationEnded={(value: boolean) =>
                    dispatch({
                        type: ActionReducerCoworkers.handle,
                        field: 'drawerAnimationEnded',
                        value: value,
                    })
                }
                coworkers={coworkerDisplay}
                coworkerCardRef={coworkerCardRef}
                getCoworkerWorkingHours={getCoworkerWorkingHours}
                selectedCoworker={selectedCoworker}
                resetCoworkerSelection={resetCoworkerSelection}
                setCoworkerWeeklyHourPrefModalOpen={(value: boolean) =>
                    dispatch({
                        type: ActionReducerCoworkers.handle,
                        field: 'coworkerWeeklyHourPrefModalOpen',
                        value: value,
                    })
                }
                setNoScroll={(value: boolean) =>
                    dispatch({
                        field: 'setNoScroll',
                        type: ActionReducerCoworkers.handle,
                        value: value,
                    })
                }
                optionsOffices={optionsOffices}
                onChangeOffice={onChangeOffice}
                selectedOffice={selectedOffice}
                totalAllCoworkersApi={totalAllCoworkersApi}
                handlePageCoworkers={handlePageCoworkers}
                totalCoworkersAlreadyLoad={totalCoworkersAlreadyLoad}
                coworkersIsLoading={coworkersIsLoading && coworkersNeedToReload == false}
                setCoworkers={setCoworkerDisplay}
                fetchOffices={fetchOffices}
                resetOfficesSearch={resetOfficesSearch}
            />

            {coworkerWeeklyHourPrefModalOpen && selectedCoworker?.user?.id && (
                <CoworkerScheduleModal
                    visible={coworkerWeeklyHourPrefModalOpen}
                    onComplete={() =>
                        dispatch({
                            type: ActionReducerCoworkers.closeCoworkerWeelkyHourPrefModal,
                        })
                    }
                    coworker={selectedCoworker.user!}
                />
            )}
        </div>
    );
});

export default Coworkers;
