import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FunnelIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            {...props}
        >
            <path
                d="M4.11302 6.53819C4.28522 6.20746 4.62713 6 5 6H23C23.3729 6 23.7148 6.20746 23.887 6.53819C24.0592 6.86892 24.0331 7.26799 23.8192 7.57346L17 17.3152V21.5C17 21.8788 16.786 22.225 16.4472 22.3944L12.4472 24.3944C12.1372 24.5494 11.7691 24.5329 11.4743 24.3507C11.1795 24.1684 11 23.8466 11 23.5V17.3152L4.18077 7.57346C3.96694 7.26799 3.94083 6.86892 4.11302 6.53819ZM6.92066 8L12.8192 16.4265C12.9369 16.5946 13 16.7948 13 17V21.882L15 20.882V17C15 16.7948 15.0631 16.5946 15.1808 16.4265L21.0793 8H6.92066Z"
                fill={fill}
            />
        </svg>
    );
};

export default FunnelIcon;
