import React from 'react';
import './index.less';

const NUMBER_ROW_FAKE_TABLE = 10;

export const settingsSlackShape = (
    <div className="settingsSlackShape">
        <div className="wrapper-1">
            <div className="rect-1" />
            <div className="rect-2" />
            <div className="rect-1" />
        </div>
    </div>
);

export const settingsSlackIntegrationShape = (
    <div className="settingsSlackIntegrationShape">
        <div className="wrapper-1">
            <div className="rect-1" />
            <div className="rect-2" />
            <div className="rect-1" />
        </div>
        <div className="rect-3" />
        <div className="wrapper-1">
            <div className="rect-1" />
            <div className="rect-2" />
            <div className="rect-1" />
        </div>
        <div className="rect-3" />
        <div className="wrapper-1">
            <div className="rect-1" />
            <div className="rect-2" />
            <div className="rect-1" />
        </div>
    </div>
);

export const tableSkeletonShape = (
    <div className="tableSkeletonShape">
        <div className="pagination-container mb-10">
            <div className="large" />
            <div className="little" />
            <div className="little" />
        </div>

        {[...Array(NUMBER_ROW_FAKE_TABLE)].map((el, key) => (
            <div key={key} className="one-row" />
        ))}

        <div className="pagination-container mt-10">
            <div className="large" />
            <div className="little" />
            <div className="little" />
        </div>
    </div>
);
