import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { UpdateHappeningAttendanceRequestDto } from 'Api/Features/Happenings/Dtos/UpdateHappeningAttendanceRequestDto';
import Button from 'Components/button';
import { useService, useStores } from 'Hooks';
import React, { useEffect, useState } from 'react';
import { HappeningService } from 'Services/HappeningService';
import './index.less';
import ButtonDropdownAttending, {
    ButtonDropdownAttendingStyle,
} from './components/button-dropdown-attending';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

interface RSVPToggleProps {
    happening: HappeningDto;
    onPressToggleExtra?: (isActive: boolean, isAttendingCustom: boolean) => void;
    dropdownBtnStyle?: ButtonDropdownAttendingStyle;
    /**When user changes attendence, need to pass funtion that will append or remove current user image to locally update RSVPInfoDetail attending image circles */
    onAttendenceChange: (isAttending: boolean) => void;
    isPast?: boolean;
}

export enum RSVPToggleState {
    attending = 'attending',
    notAttending = 'notAttending',
    attendingPlusOne = 'attendingPlusOne',
}

const RSVPToggle: React.FunctionComponent<RSVPToggleProps> = observer(
    ({ happening, onPressToggleExtra, dropdownBtnStyle, onAttendenceChange, isPast }) => {
        const [canAttend, setCanAttend] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [canAttendAccompanied, setCanAttendAccompanied] = useState(false);
        const [attendenceState, setAttendenceState] = useState<RSVPToggleState>(
            RSVPToggleState.notAttending
        );

        const happeningService = useService(HappeningService);
        const { userStore, toastStore } = useStores();

        const { t } = useTranslation();

        useEffect(() => {
            //settings states for attendence, if event is capicity full, if it would be possible to attend with companion
            if (happening && userStore.userInfo?.id) {
                //currently only possible to have +1 company
                setCanAttendAccompanied(
                    happening.maxCompanions !== null &&
                        (happening.capacity === null ||
                            happening.capacity === 0 ||
                            (happening.attendeesCount ?? 0) + 2 <= (happening.capacity ?? 0))
                );

                if (happening.myAttendance) {
                    setAttendenceState(
                        (happening.myAttendance.numberOfCompanions ?? 0) > 0
                            ? RSVPToggleState.attendingPlusOne
                            : RSVPToggleState.attending
                    );
                } else setAttendenceState(RSVPToggleState.notAttending);

                setCanAttend(
                    happening.myAttendance
                        ? true
                        : (happening.attendeesEnabled && happening.capacity === null) ||
                              (happening.attendeesCount ?? 0) < (happening.capacity ?? 0)
                );
            }
        }, [happening, userStore.userInfo?.id]);

        // Change les états des différents state pour simuler un refresh
        const updateLocalChangement = (isAttending: boolean, companionAmount: number) => {
            if (isAttending) {
                if (companionAmount > 0) setAttendenceState(RSVPToggleState.attendingPlusOne);
                else setAttendenceState(RSVPToggleState.attending);
            } else {
                setAttendenceState(RSVPToggleState.notAttending);
            }
            if (onPressToggleExtra) {
                onPressToggleExtra(isAttending, (companionAmount || 0) > 0);
            }
        };

        const changeAttendence = async (isAttending: boolean, companionAmount: number) => {
            if (isLoading) return;
            if (!happening.id || !userStore?.userInfo?.id) return;

            setIsLoading(true);

            try {
                const request: UpdateHappeningAttendanceRequestDto = {
                    userId: userStore.userInfo.id,
                    isAttending: isAttending,
                    numberOfCompanions: companionAmount ? companionAmount : undefined,
                };

                await happeningService.updateHappeningAttendance(happening.id, request);
                onAttendenceChange(isAttending);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                updateLocalChangement(isAttending, companionAmount);
                setIsLoading(false);
            }
        };

        return (
            <div className="RSVPToggle">
                {canAttend && !isPast ? (
                    <ButtonDropdownAttending
                        attendenceState={attendenceState}
                        onChangeAttendence={changeAttendence}
                        canBringCompanion={canAttendAccompanied}
                        style={dropdownBtnStyle}
                    />
                ) : null}

                {!canAttend && !isPast ? (
                    <Button
                        text="Full Capacity"
                        type="light"
                        width="hugged"
                        onClick={() => {
                            //
                        }}
                        disabled
                    />
                ) : null}

                {isPast && attendenceState === RSVPToggleState.attending ? (
                    <Button
                        text={t('Happening.you_attending')}
                        type="light"
                        width="hugged"
                        disabled
                    />
                ) : null}
            </div>
        );
    }
);

export default RSVPToggle;
