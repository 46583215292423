import Button from 'Components/button';
import ObjectivesSummary from 'Components/objectives-summary';
import 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

interface ObjectivesDetailsDropdownModalProps {
    forCloseDropdownModal: () => void;
    openExternalModal: () => void;
    policyBundleObjectivesDetails: any;
}

const ObjectivesDetailsDropdownModal: React.FunctionComponent<
    ObjectivesDetailsDropdownModalProps
> = ({ forCloseDropdownModal, openExternalModal, policyBundleObjectivesDetails }) => {
    const { t } = useTranslation();

    return (
        <div className="ObjectivesDetailsDropdownModal">
            <ObjectivesSummary policyBundleObjectivesDetails={policyBundleObjectivesDetails} />

            <div className="separator" />

            <Button
                text={t('view_details')}
                type="secondary"
                width="full"
                height="small"
                onClick={() => {
                    forCloseDropdownModal();
                    openExternalModal();
                }}
            />
        </div>
    );
};
export default ObjectivesDetailsDropdownModal;
