import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const TrophyIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M15.18 18.2606C15.18 16.8412 16.28 16.0557 18.074 14.8936C20.268 13.4693 23 11.6997 23 7.49834C22.9995 7.40787 22.9807 7.31839 22.9448 7.23502C22.9088 7.15165 22.8564 7.07605 22.7906 7.01253C22.7247 6.94901 22.6467 6.89882 22.5609 6.86486C22.4752 6.83089 22.3835 6.8138 22.291 6.81458H18.861C18.377 5.9381 16.932 5 14 5C11.066 5 9.623 5.9381 9.138 6.81458H5.707C5.61468 6.81406 5.52316 6.83134 5.43767 6.86542C5.35217 6.89951 5.27438 6.94973 5.20873 7.01322C5.14308 7.07672 5.09085 7.15224 5.05504 7.23547C5.01923 7.31871 5.00052 7.40803 5 7.49834C5 11.6997 7.73 13.4693 9.926 14.8926C11.72 16.0547 12.82 16.8402 12.82 18.2596V19.542C10.936 19.7465 9.551 20.4283 9.551 21.2402C9.551 22.2125 11.543 23 14 23C16.457 23 18.449 22.2125 18.449 21.2402C18.449 20.4283 17.064 19.7465 15.18 19.542V18.2606ZM17.957 13.3148C18.523 12.1419 18.973 10.5504 19.045 8.18309H21.555C21.315 10.8252 19.693 12.1585 17.957 13.3148ZM14 6.19928C16.732 6.19733 17.799 7.28998 17.798 7.69496C17.798 8.10385 16.732 9.19455 14 9.19651C11.268 9.19553 10.201 8.10483 10.201 7.69594C10.2 7.29096 11.268 6.19733 14 6.19928ZM6.445 8.18309H8.954C9.027 10.5504 9.475 12.1419 10.043 13.3148C8.307 12.1585 6.684 10.8252 6.445 8.18309Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default TrophyIcon;
