import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import { useHistory } from 'react-router-dom';
import { useApiRequestHistory, useService, useStores } from 'Hooks';
import { TeamService } from 'Services/TeamService';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { FilterStore } from 'Stores';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { GetTeamsSortColumnDto } from 'Api/Features/Teams/Dtos/GetTeamsSortColumnDto';
import { ColumnType } from 'antd/es/table';
import { TeamManagerDto } from 'Api/Features/Teams/Dtos/TeamManagerDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import {
    SETTINGS_TEAMS_URL_REGEX,
    DEBOUNCE_DELAY_400,
    SETTINGS_URL,
    TEAMS_URL,
    TEAMS_URL_REGEX,
} from 'Models/Constants';
import { Empty } from 'Components/empty';
import TableFilters from 'Components/table-filters/table-filters';
import { ConfigProvider } from 'antd';
import Skeleton from 'Components/skeleton';
import { tableSkeletonShape } from 'Routes/settings/skeleton-shapes';

export enum TeamTableContext {
    Settings = 'Settings',
    MyTeams = 'MyTeams',
}

export interface TeamDetailsProps {
    context: TeamTableContext;
    onChangeIsLoaded?: any;
}

const TeamsTable: FunctionComponent<TeamDetailsProps> = ({ context, onChangeIsLoaded }) => {
    const history = useHistory();
    const teamService = useService(TeamService);
    const paginationRef = useRef(initialPaginationState);
    const { t } = useTranslation();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const { toastStore, userStore } = useStores();
    const [teamList, setTeamList] = useState<TeamDto[]>([]);
    const [apiNoTeams, setApiNoTeams] = useState(false);
    const [isLoading, setIsLoading] = useState({
        teams: false,
    });
    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex:
                context === TeamTableContext.Settings ? SETTINGS_TEAMS_URL_REGEX : TEAMS_URL_REGEX,
            requestType: RequestType.Teams,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            const filterStore = filterStoreRef.current;
            const request: GetTeamsRequestDto = history.request;
            filterStore.searchTerm = request.searchTerm ?? '';

            paginationRef.current = {
                ...paginationRef.current,
                current: request.page ? request.page + 1 : 1,
                pageSize: request.pageSize ?? initialPaginationState.pageSize,
            };
        },
    });

    const columns: ColumnType<TeamDto>[] = [
        {
            title: t('name'),
            render: (team: TeamDto) => team.name,
            key: GetTeamsSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            sorter: true,
            width: '28%',
        },
        {
            title: t('Team.nbr_of_coworkers'),
            render: (team: TeamDto) => team.numberOfMembers ?? 'N/A',
            key: GetTeamsSortColumnDto.NumberOfMembers,
            sorter: true,
            width: '27ch',
        },
        {
            title: t('managers'),
            sorter: true,
            render: (team: TeamDto) =>
                team.managers && team.managers.length > 0
                    ? team.managers
                          .filter((manager) => manager !== null)
                          .map((manager) => manager!)
                          .map(
                              (manager: TeamManagerDto) =>
                                  `${manager.firstName} ${manager.lastName}${
                                      context === TeamTableContext.MyTeams &&
                                      manager.id === userStore.userInfo?.id
                                          ? ` ${t('parenthesis_me')}`
                                          : ''
                                  }`
                          )
                          .join(', ')
                    : t('n/a'),
        },
    ];

    const fetchTeams = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: any | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetTeamsRequestDto = setRequestHistory({
                    request: {
                        pageSize: params.pagination.pageSize || 0,
                        page: (params.pagination.current || 1) - 1,
                        searchTerm: params.searchTerm,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                        managerIds:
                            context === TeamTableContext.MyTeams
                                ? [userStore.userInfo?.id]
                                : undefined,
                    },
                    requestType: RequestType.Teams,
                });

                const [items, totalItemsCount] = await teamService.getTeams(request);
                setTeamList(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
                // gestion des wireframes
                if (onChangeIsLoaded) onChangeIsLoaded(true);
                setIsLoading((prev) => {
                    return { ...prev, teams: true };
                });

                if (items.length > 0) setApiNoTeams(false);
                else setApiNoTeams(true);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [teamService]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm } = filterStoreRef.current;
        await fetchTeams({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string }) => {
            fetchTeams({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const onRowClick = (team: TeamDto): void => {
        if (context === TeamTableContext.Settings)
            history.push(SETTINGS_URL + TEAMS_URL + `/${team.id}`);
        if (context === TeamTableContext.MyTeams) history.push(TEAMS_URL + `/${team.id}`);
    };

    return (
        <div className="TeamsTable">
            <>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    isLoaded={isLoading.teams}
                />

                {apiNoTeams ? (
                    <div className="table-empty-yet">
                        <Empty />
                    </div>
                ) : (
                    <Skeleton placeholder={tableSkeletonShape} isLoaded={isLoading.teams}>
                        <ConfigProvider renderEmpty={() => <Empty />}>
                            <Table
                                className="table-action-rows table-striped-rows"
                                dataSource={teamList}
                                columns={columns}
                                loading={loading}
                                rowKey={(team): string => team.id ?? ''}
                                pagination={pagination}
                                onChange={handleTableChange}
                                onRow={(row: TeamDto) => ({
                                    onClick: (): void => {
                                        onRowClick(row);
                                    },
                                })}
                            />
                        </ConfigProvider>
                    </Skeleton>
                )}
            </>
        </div>
    );
};

export default TeamsTable;
