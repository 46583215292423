import { Checkbox } from 'antd';
import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';
import Divider from 'Components/divider';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    FORMAT_MONTH_DAY_YEAR_DASH_TWELVE_HOUR,
    FORMAT_TWELVE_HOUR_NO_MINUTE_AM_PM_CAPS,
} from 'Models/Constants';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XAxis, YAxis, CartesianGrid, Area, ComposedChart, Tooltip, Line } from 'recharts';
import { theme } from 'Style/theme';
import { RealEstateChartBaseProps } from '../..';
import './index.less';

export interface RealEstateChartOfficeOccupationProps extends RealEstateChartBaseProps {
    lastPeriodChartData?: GetOfficeUtilizationStatsResponseDto;
}

const OfficeOccupation: React.FunctionComponent<RealEstateChartOfficeOccupationProps> = observer(
    ({ chartData, lastPeriodChartData }) => {
        const { languageStore } = useStores();
        const { t } = useTranslation();
        const [averageFromLast4WeekisActive, setAverageFromLast4WeekisActive] = useState(true);

        const renderCustomLabel = ({ x, y, payload }) => {
            return (
                <text fontSize={13} x={x - 20} y={y + 15} color={theme['text-mid-contrast']}>
                    {payload.value}
                </text>
            );
        };

        const onChangeAverageFromLast4Week = () =>
            setAverageFromLast4WeekisActive((prev: boolean) => !prev);

        const CustomTooltip = (props) => {
            if (props.active && props.payload && props.payload.length) {
                const { timeUnit, totalUsers, average } = props.payload[0].payload;

                return (
                    <div className="custom-tooltip">
                        <div className="text-caption-3">
                            {moment
                                .utc(timeUnit)
                                .format(
                                    FORMAT_MONTH_DAY_YEAR_DASH_TWELVE_HOUR[
                                        languageStore.currentLanguage
                                    ]
                                )}
                        </div>

                        <div className="column">
                            <div className="row">
                                <div className="circle office" />
                                <div className="text-caption-2">{t('office_occupation')}</div>
                                <div className="text-caption-2-bold">
                                    {totalUsers} {t('users')}
                                </div>
                            </div>

                            {averageFromLast4WeekisActive && (
                                <div className="row">
                                    <div className="circle average" />
                                    <div className="text-caption-2">
                                        {t('last_four_week_average')}
                                    </div>
                                    <div className="text-caption-2-bold">
                                        {average.toFixed(2)} {t('users')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }

            return null;
        };

        const calculateAverageFromLast4Week = useMemo(() => {
            return chartData?.groups?.map((chartDataGroup) => {
                const lastPeriodChartDataFilter = lastPeriodChartData?.groups?.filter(
                    (lastPeriodGroup) =>
                        moment.utc(lastPeriodGroup?.info?.timeUnit).hour() ===
                        moment.utc(chartDataGroup?.info?.timeUnit).hour()
                );

                if (!lastPeriodChartDataFilter)
                    return {
                        label: moment
                            .utc(chartDataGroup?.info?.timeUnit)
                            .format(
                                FORMAT_TWELVE_HOUR_NO_MINUTE_AM_PM_CAPS[
                                    languageStore.currentLanguage
                                ]
                            ),
                        timeUnit: chartDataGroup?.info?.timeUnit,
                        totalUsers: chartDataGroup?.totalUsers,
                        average: 0,
                    };

                const calculateAverage = lastPeriodChartDataFilter
                    .map((el) => el?.totalUsers)
                    .reduce((a, b) => (a || 0) + (b || 0), 0);

                return {
                    label: moment
                        .utc(chartDataGroup?.info?.timeUnit)
                        .format(
                            FORMAT_TWELVE_HOUR_NO_MINUTE_AM_PM_CAPS[languageStore.currentLanguage]
                        ),
                    timeUnit: chartDataGroup?.info?.timeUnit,
                    totalUsers: chartDataGroup?.totalUsers,
                    average: (calculateAverage || 1) / lastPeriodChartDataFilter.length,
                };
            });
        }, [chartData?.totalUsers, chartData?.totalHours]);

        const yAxisMaxDomain = useMemo((): number => {
            let result = 0;
            chartData?.groups?.map((group) => {
                if ((group?.totalUsers ?? 0) > result) result = group?.totalUsers ?? 0;
            });
            if (averageFromLast4WeekisActive) {
                lastPeriodChartData?.groups?.map((group) => {
                    if ((group?.totalUsers ?? 0) > result) result = group?.totalUsers ?? 0;
                });
            }
            return result;
        }, [averageFromLast4WeekisActive, chartData, lastPeriodChartData]);

        return (
            <div className="OfficeOccupation">
                <div className="header-container">
                    <Checkbox
                        onChange={onChangeAverageFromLast4Week}
                        checked={averageFromLast4WeekisActive}
                    >
                        <div className="text-body">{t('show_last_four_week_average')}</div>
                    </Checkbox>
                </div>

                <Divider marginTop={40} marginBottom={10} />

                <ComposedChart
                    width={780}
                    height={320}
                    data={chartData?.groups?.map((item) => ({
                        label: moment
                            .utc(item?.info?.timeUnit)
                            .format(
                                FORMAT_TWELVE_HOUR_NO_MINUTE_AM_PM_CAPS[
                                    languageStore.currentLanguage
                                ]
                            ),
                        timeUnit: item?.info?.timeUnit,
                        totalUsers: item?.totalUsers,
                    }))}
                >
                    <defs>
                        <linearGradient id="gradiant" x1="0" y1="0" x2="0" y2="3">
                            <stop
                                offset="15%"
                                stopColor={theme['layout-mid-accent']}
                                stopOpacity={1}
                            />
                            <stop
                                offset="70%"
                                stopColor={theme['layout-mid-accent']}
                                stopOpacity={0.1}
                            />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="0" vertical={false} />
                    <XAxis dataKey="label" tick={renderCustomLabel} minTickGap={2} interval={2} />
                    <YAxis dataKey="totalUsers" domain={[0, Math.round(yAxisMaxDomain * 1.25)]} />

                    <Area
                        type="monotone"
                        dataKey="totalUsers"
                        fill="url(#gradiant)"
                        stroke={'transparent'}
                    />

                    <Tooltip content={<CustomTooltip />} />

                    {averageFromLast4WeekisActive && (
                        <Line
                            data={calculateAverageFromLast4Week}
                            type="monotone"
                            dataKey="average"
                            stroke="#A9CDF9"
                            strokeWidth={3}
                            dot={<div />}
                        />
                    )}
                </ComposedChart>
            </div>
        );
    }
);

export default OfficeOccupation;
