import Content from 'Components/Content';
import TeamsTable, { TeamTableContext } from 'Components/teams-table/teams-table';
import React from 'react';
const TeamManagement: React.FunctionComponent = () => {
    return (
        <Content className="TeamManagement">
            <TeamsTable context={TeamTableContext.MyTeams} />
        </Content>
    );
};

export default TeamManagement;
