import NoProfilePicture from 'Assets/Images/no-profile.svg';
import DropdownModal from 'Components/dropdown-modal';
import Icon from 'Components/icons/Icon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ObjectivesDetailsDropdownModal from 'Routes/dashboard/modals/objectives-details-dropdown-modal';
import { PolicyBundleObjectivesDetails } from 'Stores/PolicyStore';
import { img, ImgSize } from 'Utils/ImgUtils';
import WorkHoursGlanceDropdownModal from '../../modals/work-hours-glance-dropdown-modal';
import DashboardObjectives from '../dashboard-objectives';
import './index.less';

interface DashboardTodayProfileData {
    imgUrl?: string;
    name: string;
}

interface CurrentlyWorkingData {
    office: number;
    remote: number;
    onClick: { office: () => void; remote: () => void };
}

interface ObjectivesData {
    completedObjectives: number;
    totalObjectives: number;
    openObjectivesModal: () => void;
    policyBundleObjectivesDetails: PolicyBundleObjectivesDetails[];
}

export interface DashboardTodayData {
    profile: DashboardTodayProfileData;
    currentlyWorking: CurrentlyWorkingData;
    objectives: ObjectivesData;
    hoursOfWork: { workHours: string; availabilityHours: string };
}

interface DashboardTodayProps {
    data?: DashboardTodayData;
}

const DashboardToday: React.FunctionComponent<DashboardTodayProps> = ({ data }) => {
    const [forceCloseDropDownModal, setForceCloseDropDownModal] = useState(false);
    const { t } = useTranslation();

    return (
        <div className="DashboardToday">
            <div className="waves-container">
                <div className="waves" />
            </div>

            <div className="profile">
                <div
                    className="img-container"
                    style={{
                        backgroundImage: `url(${
                            data?.profile.imgUrl
                                ? img(data.profile.imgUrl, ImgSize.m)
                                : NoProfilePicture
                        })`,
                    }}
                />

                <div className="text">
                    <span>{t('hello')}, </span>
                    <span>{data?.profile.name}</span>
                </div>
            </div>

            <div className="info-container">
                <div className="title">{t('Dashboard.currently_working_team')}</div>

                <div className="content">
                    <div className="info-bloc" onClick={data?.currentlyWorking.onClick.remote}>
                        <Icon iconName="SimpleHouseIcon" />
                        <div>
                            {data?.currentlyWorking.remote} {t('coworkers')}
                        </div>
                    </div>

                    <div className="info-bloc" onClick={data?.currentlyWorking.onClick.office}>
                        <Icon iconName="AppartementIcon" />
                        <div>
                            {data?.currentlyWorking.office} {t('coworkers')}
                        </div>
                    </div>
                </div>

                <div className="title">{t('Dashboard.guardrails_this_week')}</div>

                <div className="content">
                    {data && data.objectives.totalObjectives > 0 && (
                        <DropdownModal
                            className="info-bloc"
                            parentElement={
                                <DashboardObjectives
                                    completedObjectives={data?.objectives.completedObjectives ?? 0}
                                    totalObjectives={data?.objectives.totalObjectives ?? 0}
                                />
                            }
                            forceClose={{
                                close: forceCloseDropDownModal,
                                resetForceClose: () => setForceCloseDropDownModal(false),
                            }}
                        >
                            <ObjectivesDetailsDropdownModal
                                forCloseDropdownModal={() => setForceCloseDropDownModal(true)}
                                openExternalModal={() => data?.objectives.openObjectivesModal()}
                                policyBundleObjectivesDetails={
                                    data.objectives.policyBundleObjectivesDetails
                                }
                            />
                        </DropdownModal>
                    )}

                    <DropdownModal
                        className="info-bloc"
                        parentElement={
                            <div className="work-hours-container">
                                <Icon iconName="ClockIcon" />
                                {data?.hoursOfWork.workHours} {t('hours_of_availabilities')}
                            </div>
                        }
                        forceClose={{
                            close: forceCloseDropDownModal,
                            resetForceClose: () => setForceCloseDropDownModal(false),
                        }}
                    >
                        <WorkHoursGlanceDropdownModal
                            offHours={data?.hoursOfWork.availabilityHours ?? '0'}
                            workHours={data?.hoursOfWork.workHours ?? '0'}
                        />
                    </DropdownModal>
                </div>
            </div>
        </div>
    );
};

export default DashboardToday;
