// GENERATED FILE - DO NOT MODIFY
import { GetDefaultLanguageResponseDto } from 'Api/Features/System/Dtos/GetDefaultLanguageResponseDto';
import { UpdateDefaultLanguageRequestDto } from 'Api/Features/System/Dtos/UpdateDefaultLanguageRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SystemProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getDefaultLanguage(): Promise<GetDefaultLanguageResponseDto | null> {
        const uri = this.buildUri(
            "/default-language",
            null,
            null
        );

        const data: GetDefaultLanguageResponseDto | null = await this.httpClient.get<GetDefaultLanguageResponseDto | null>(uri);
        return data;
    }

    public async updateDefaultLanguage(request: UpdateDefaultLanguageRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/default-language",
            null,
            null
        );

        await this.httpClient.put<UpdateDefaultLanguageRequestDto | null, void>(uri, request);
    }
}