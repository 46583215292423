import { FloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanFloorDto';
import Button from 'Components/button';
import Modal from 'Components/modal';
import SubmitButton from 'Components/submit-button/submit-button';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useStores } from 'Hooks';
import { FORM_GUTTER } from 'Models/Constants';
import { CreateFloorSchema } from 'Schemas/FloorPlanSchema';
import { FloorPlanManager } from 'Components/floor-plan/FloorPlanManager';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './floor-modal.less';
import { UploadBoxIcon, WarningIcon } from 'Components/icons';
import { v4 as uuidv4 } from 'uuid';
import { theme } from 'Style/theme';

interface FloorModalProps {
    visible: boolean;
    onComplete: (
        success: boolean,
        deleteFloor: boolean,
        editing: boolean,
        floor?: FloorPlanFloorDto
    ) => void;
    floorPlanManager: FloorPlanManager;
    editingFloor?: FloorPlanFloorDto;
}

interface FloorPlanFile {
    fileName?: string;
    svg: string | ArrayBuffer | null | undefined;
    size?: number;
}

const FloorModal: FunctionComponent<FloorModalProps> = observer(
    ({ visible, onComplete, floorPlanManager, editingFloor }) => {
        const [form] = useForm();
        const [errors, validateForm, resetErrors] = useFormValidation(CreateFloorSchema, form);
        const { t } = useTranslation();
        const { toastStore, confirmationModalStore } = useStores();
        const [floorFile, setFloorFile] = useState<FloorPlanFile | null>(null);
        const hiddenref = useRef<any>(null);

        useEffect(() => {
            if (editingFloor) {
                form.setFieldsValue({
                    name: editingFloor.name,
                });
            }
        }, [editingFloor]);

        const dismiss = (success = false, floor?: FloorPlanFloorDto): void => {
            form.resetFields();
            resetErrors();
            onComplete(success, false, editingFloor != undefined, floor);
        };

        const submit = async (): Promise<void> => {
            const formValues = form.getFieldsValue();
            const floor: FloorPlanFloorDto = {
                name: formValues.name,
                svg:
                    editingFloor && floorFile == null
                        ? editingFloor.svg
                        : floorPlanManager?.svgToBase64(floorFile?.svg),
            };

            if (!(await validateForm(floor))) return;

            dismiss(true, {
                name: floor.name,
                svg: floor.svg,
                id: editingFloor?.id ?? uuidv4(),
                officeSpaceAreas: [],
            });
        };

        const handleFileChange = (e: any) => {
            if (floorPlanManager) {
                const file = e?.target?.files[0];

                if (file) {
                    if (file?.size > 1024 * 1024 * 3) {
                        toastStore.toast({
                            type: 'error',
                            messageKey: 'The plan SVG file must be under 3Mb',
                        });
                        return;
                    }

                    const reader = new FileReader();
                    reader.readAsText(file, 'UTF-8');

                    reader.onload = (readerEvent) => {
                        if (!floorPlanManager.isSvg(readerEvent?.target?.result as any)) {
                            toastStore.toast({
                                type: 'error',
                                messageKey:
                                    'The selected file does not appear to be a valid SVG file.',
                            });
                        } else {
                            setFloorFile({
                                fileName: file.name,
                                svg: readerEvent.target?.result as any,
                                size: file.size,
                            });
                        }
                    };
                }
            }
        };

        const onDeleteFloor = async (): Promise<void> => {
            if (
                await confirmationModalStore.confirm({
                    icon: <WarningIcon fill={theme['warning-mid-contrast']} />,
                    title: t('Cta.delete_confirm_title', {
                        param1: t('Entity.lowercase_floor'),
                        param2: 'cet',
                    }),
                    message: t('Cta.delete_confirm_message', {
                        param1: t('Entity.lowercase_floor'),
                        param2: "L'",
                    }),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                })
            ) {
                onComplete(true, true, false, { id: editingFloor?.id });
            }
        };

        return (
            <Modal
                className="FloorModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={editingFloor ? t('FloorPlan.edit_floor') : t('FloorPlan.create_floor')}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />

                        {editingFloor && (
                            <div
                                className="remove text-callout-bold text-error-mid-contrast cursor-pointer"
                                onClick={() => onDeleteFloor()}
                            >
                                {t('FloorPlan.delete_floor')}
                            </div>
                        )}

                        <SubmitButton text={t('confirm')} type="primary" onClick={() => submit()} />
                    </>
                }
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={4} />
                        <Col span={16}>
                            <ValidatedFormItem label={t('name')} name={'name'} errors={errors}>
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={4} />
                    </Row>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={4} />
                        <Col span={16}>
                            <ValidatedFormItem
                                label={t('FloorPlan.floor_file_svg')}
                                name={'svg'}
                                errors={errors}
                            >
                                <div
                                    className="visible-input cursor-pointer"
                                    onClick={() => {
                                        if (hiddenref.current) {
                                            hiddenref.current.click();
                                        }
                                    }}
                                >
                                    <div className="d-flex-col">
                                        <UploadBoxIcon width={48} height={48} />
                                        <span className="text-body-bold">
                                            {t('FloorPlan.upload_svg')}
                                        </span>
                                    </div>
                                </div>
                            </ValidatedFormItem>

                            {floorFile && (
                                <span>
                                    {t('filename')}: {floorFile.fileName}
                                </span>
                            )}

                            <input
                                ref={hiddenref}
                                className="hidden-input"
                                type="file"
                                accept={'.svg'}
                                onChange={(e) => handleFileChange(e)}
                            />
                        </Col>
                        <Col span={4} />
                    </Row>
                </Form>
            </Modal>
        );
    }
);

export default FloorModal;
