import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const HouseBuildingIcon: FunctionComponent<SvgProps> = ({ fill, onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <mask
                    id="mask0_2853_21852"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                >
                    <rect width="40" height="40" fill={fill ? fill : '#D9D9D9'} />
                </mask>
                <g mask="url(#mask0_2853_21852)">
                    <path
                        d="M27.25 35V18.708C27.25 18.236 27.146 17.7777 26.938 17.333C26.7293 16.889 26.4443 16.542 26.083 16.292L16.792 9.708V7.75C16.792 7 17.0627 6.354 17.604 5.812C18.146 5.27067 18.8057 5 19.583 5H35.542C36.3193 5 36.979 5.27067 37.521 5.812C38.0623 6.354 38.333 7.014 38.333 7.792V32.208C38.333 32.986 38.0623 33.646 37.521 34.188C36.979 34.7293 36.3193 35 35.542 35H27.25ZM28.792 28.292H31.583V25.5H28.792V28.292ZM28.792 21.333H31.583V18.542H28.792V21.333ZM28.792 14.375H31.583V11.583H28.792V14.375ZM1.66699 33.625V20.042C1.66699 19.5973 1.77099 19.1737 1.97899 18.771C2.18766 18.3683 2.47233 18.042 2.83299 17.792L11.458 11.583C11.958 11.2497 12.4997 11.083 13.083 11.083C13.6663 11.083 14.208 11.2497 14.708 11.583L23.292 17.792C23.6807 18.042 23.9723 18.3683 24.167 18.771C24.361 19.1737 24.458 19.5973 24.458 20.042V33.625C24.458 34.0137 24.326 34.34 24.062 34.604C23.7987 34.868 23.4723 35 23.083 35H16.417V25.542H9.74999V35H3.04199C2.65266 35 2.32599 34.868 2.06199 34.604C1.79866 34.34 1.66699 34.0137 1.66699 33.625Z"
                        fill="white"
                    />
                    <path
                        d="M27.25 35V18.708C27.25 18.236 27.146 17.7777 26.938 17.333C26.7293 16.889 26.4443 16.542 26.083 16.292L16.792 9.708V7.75C16.792 7 17.0627 6.354 17.604 5.812C18.146 5.27067 18.8057 5 19.583 5H35.542C36.3193 5 36.979 5.27067 37.521 5.812C38.0623 6.354 38.333 7.014 38.333 7.792V32.208C38.333 32.986 38.0623 33.646 37.521 34.188C36.979 34.7293 36.3193 35 35.542 35H27.25ZM28.792 28.292H31.583V25.5H28.792V28.292ZM28.792 21.333H31.583V18.542H28.792V21.333ZM28.792 14.375H31.583V11.583H28.792V14.375ZM1.66699 33.625V20.042C1.66699 19.5973 1.77099 19.1737 1.97899 18.771C2.18766 18.3683 2.47233 18.042 2.83299 17.792L11.458 11.583C11.958 11.2497 12.4997 11.083 13.083 11.083C13.6663 11.083 14.208 11.2497 14.708 11.583L23.292 17.792C23.6807 18.042 23.9723 18.3683 24.167 18.771C24.361 19.1737 24.458 19.5973 24.458 20.042V33.625C24.458 34.0137 24.326 34.34 24.062 34.604C23.7987 34.868 23.4723 35 23.083 35H16.417V25.542H9.74999V35H3.04199C2.65266 35 2.32599 34.868 2.06199 34.604C1.79866 34.34 1.66699 34.0137 1.66699 33.625Z"
                        fill={fill ? fill : 'F9F9F9'}
                    />
                    {!fill && (
                        <path
                            d="M27.25 35V18.708C27.25 18.236 27.146 17.7777 26.938 17.333C26.7293 16.889 26.4443 16.542 26.083 16.292L16.792 9.708V7.75C16.792 7 17.0627 6.354 17.604 5.812C18.146 5.27067 18.8057 5 19.583 5H35.542C36.3193 5 36.979 5.27067 37.521 5.812C38.0623 6.354 38.333 7.014 38.333 7.792V32.208C38.333 32.986 38.0623 33.646 37.521 34.188C36.979 34.7293 36.3193 35 35.542 35H27.25ZM28.792 28.292H31.583V25.5H28.792V28.292ZM28.792 21.333H31.583V18.542H28.792V21.333ZM28.792 14.375H31.583V11.583H28.792V14.375ZM1.66699 33.625V20.042C1.66699 19.5973 1.77099 19.1737 1.97899 18.771C2.18766 18.3683 2.47233 18.042 2.83299 17.792L11.458 11.583C11.958 11.2497 12.4997 11.083 13.083 11.083C13.6663 11.083 14.208 11.2497 14.708 11.583L23.292 17.792C23.6807 18.042 23.9723 18.3683 24.167 18.771C24.361 19.1737 24.458 19.5973 24.458 20.042V33.625C24.458 34.0137 24.326 34.34 24.062 34.604C23.7987 34.868 23.4723 35 23.083 35H16.417V25.542H9.74999V35H3.04199C2.65266 35 2.32599 34.868 2.06199 34.604C1.79866 34.34 1.66699 34.0137 1.66699 33.625Z"
                            fill="url(#paint0_radial_2853_21852)"
                            fillOpacity="0.9"
                            style={{ mixBlendMode: 'multiply' }}
                        />
                    )}
                </g>
                <defs>
                    <radialGradient
                        id="paint0_radial_2853_21852"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(5.01476 9.58333) rotate(37.7926) scale(37.3208 77.1867)"
                    >
                        <stop offset="0.0260417" stopColor="#702DED" />
                        <stop offset="1" stopColor="#2F84EE" />
                    </radialGradient>
                </defs>
            </svg>
        </span>
    );
};

export default HouseBuildingIcon;
