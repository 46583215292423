import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { ClubProxy } from 'Api/Features/Clubs/ClubProxy';
import { UpdateClubRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubRequestDto';
import { CreateClubResponseDto } from 'Api/Features/Clubs/Dtos/CreateClubResponseDto';
import { CreateClubRequestDto } from 'Api/Features/Clubs/Dtos/CreateClubRequestDto';
import { GetClubMembersResponseDto } from 'Api/Features/Clubs/Dtos/GetClubMembersResponseDto';
import { GetClubMembersRequestDto } from 'Api/Features/Clubs/Dtos/GetClubMembersRequestDto';
import { GetClubsRequestDto } from 'Api/Features/Clubs/Dtos/GetClubsRequestDto';
import { ClubDto } from 'Api/Features/Clubs/Dtos/ClubDto';
import { GetClubsResponseDto } from 'Api/Features/Clubs/Dtos/GetClubsResponseDto';
import { ClubDetailsDto } from 'Api/Features/Clubs/Dtos/ClubDetailsDto';
import { GetClubRequestDto } from 'Api/Features/Clubs/Dtos/GetClubRequestDto';
import { UpdateClubMembersRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubMembersRequestDto';
import { UpdateClubInvitationsRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubInvitationsRequestDto';

@inject(ClubProxy)
export class ClubService extends ApiService {
    constructor(private readonly clubProxy: ClubProxy) {
        super();
    }

    public async getClub(
        clubId: string,
        request: GetClubRequestDto | null
    ): Promise<ClubDetailsDto | null> {
        const data: ClubDetailsDto | null = await this.clubProxy.getClub(clubId, request);
        return data;
    }

    public async getClubs(request: GetClubsRequestDto | null): Promise<[ClubDto[], number]> {
        const data: GetClubsResponseDto | null = await this.clubProxy.getClubs(request);
        return [
            data?.items?.filter((x) => x != null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async getClubMembers(
        clubId: string,
        request: GetClubMembersRequestDto | null
    ): Promise<GetClubMembersResponseDto | null> {
        const data: GetClubMembersResponseDto | null = await this.clubProxy.getClubMembers(
            clubId,
            request
        );
        return data;
    }

    public async createClub(
        request: CreateClubRequestDto | null
    ): Promise<CreateClubResponseDto | null> {
        const data: CreateClubResponseDto | null = await this.clubProxy.createClub(request);
        return data;
    }

    public async updateClub(clubId: string, request: UpdateClubRequestDto | null): Promise<void> {
        await this.clubProxy.updateClub(clubId, request);
    }

    public async deleteClub(clubId: string): Promise<void> {
        await this.clubProxy.deleteClub(clubId);
    }

    public async updateClubMembers(
        clubId: string,
        request: UpdateClubMembersRequestDto
    ): Promise<void> {
        await this.clubProxy.updateClubMembers(clubId, request);
    }

    public async updateClubInvitations(
        clubId: string,
        request: UpdateClubInvitationsRequestDto
    ): Promise<void> {
        await this.clubProxy.updateClubInvitations(clubId, request);
    }
}
