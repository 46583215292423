import { AuthorizationProxy } from "Api/Features/Authorization/AuthorizationProxy";
import { inject } from "aurelia-dependency-injection";
import { Environment } from "Misc/Environment";
import { AuthorizationConfigStore } from "Stores";

/**
 * Parameters necessary for OAuth user flows with Azure AD B2C (sign-in, password change, etc.).
 */
export class AuthConfig {
    constructor(   
        public readonly authorizeEndpoint: string,
        public readonly tokenEndpoint: string,
        public readonly logoutEndpoint: string,
        public readonly resetPasswordEndpoint: string,
        public readonly changePasswordEndpoint: string,
        public readonly confirmAccountEndpoint: string,
        public readonly linkAccountEndpoint: string,
    
        public readonly clientId: string,
        public readonly scopes: string
    ) {
    }
}

@inject(AuthorizationProxy, AuthorizationConfigStore, Environment)
export class AuthorizationConfigService {
    constructor(
        private readonly authorizationProxy: AuthorizationProxy,
        private readonly authorizationConfigStore: AuthorizationConfigStore,
        private readonly environment: Environment
    ) {        
    }

    /**
     * Logic to retrieve and store the Azure AD B2C OAuth parameters.
     * Parameters are fetched from the API once and stored in the local session storage.
     * 
     * @returns The OAuth parameters.
     */
    public async getAuthConfig(): Promise<AuthConfig> {
        if (this.authorizationConfigStore.authConfig && this.authorizationConfigStore.authConfig.linkAccountEndpoint) {
            return this.authorizationConfigStore.authConfig;
        }

        const dto = await this.authorizationProxy.getAuthConfig();

        const clientId = this.environment.REACT_APP_AZUREADB2C_CLIENT_ID || '';

        const config: AuthConfig = {
            authorizeEndpoint: dto?.flows?.signIn?.authorizationEndpoint ?? "",
            tokenEndpoint: dto?.flows?.signIn?.tokenEndpoint ?? "",
            logoutEndpoint: dto?.flows?.signIn?.logoutEndpoint ?? "",
            resetPasswordEndpoint: dto?.flows?.resetPassword?.authorizationEndpoint ?? "",
            changePasswordEndpoint: dto?.flows?.changePassword?.authorizationEndpoint ?? "",
            confirmAccountEndpoint: dto?.flows?.confirmAccount?.authorizationEndpoint ?? "",
            linkAccountEndpoint: dto?.flows?.linkAccount?.authorizationEndpoint ?? "",

            clientId: clientId,
            scopes: `${dto?.scopes?.accessAsUser?.name ?? ""} ${dto?.scopes?.openId?.name ?? ""} ${dto?.scopes?.offlineAccess?.name ?? ""}`
        };

        this.authorizationConfigStore.setConfig(config);

        return config;
    }
}
