import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import { SupportedLanguage } from 'Stores/LanguageStore';

/**
 * ConfirmAccount
 * This page is normally accessed by clicking a confirmation link in an email sent to the user when we create their account.
 */
const ConfirmAccount: FunctionComponent = observer(() => {
    const { languageStore } = useStores();
    const [errorString, setErrorString] = useState<string | undefined>(undefined);
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    const urlParams = new URLSearchParams(window.location.search);
    const { t } = useTranslation();

    useEffect(() => {
        const confirmAccount = async () => {
            // Get email and token from query string.
            const email = urlParams.get('email');
            const token = urlParams.get('token');
            let lang = urlParams.get('lang');

            if (lang === 'en') lang = SupportedLanguage.EN;
            if (lang === 'fr') lang = SupportedLanguage.FR;

            if (!email || !token) {
                setErrorString(t('ConfirmAccount.acc_conf_link_inv'));
                return;
            }

            //set the language in the store so the callback page can be localised
            languageStore.setCurrentLanguage(
                lang ? (lang as SupportedLanguage) : SupportedLanguage.EN
            );
            await azureAdAuthenticationService.runConfirmAccountFlow(
                email,
                token,
                lang ? (lang as SupportedLanguage) : SupportedLanguage.EN
            );
        };

        confirmAccount();
    }, []);

    return <div className="ConfirmAccount">{errorString && <div>{errorString}</div>}</div>;
});

export default ConfirmAccount;
