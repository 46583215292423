import React from 'react';
import './index.less';

export const TeamDetailsPolicySkeletonShapeSection = (
    <div className="TeamDetailsPolicySkeletonShapeSection">
        <div className="wrapper-left">
            <div className="rect-1" />
            <div className="rect-2" />
            <div className="rect-2" />
        </div>

        <div className="wrapper-right">
            <div className="rect-1" />
            <div className="rect-2" />
            <div className="rect-3" />
        </div>
    </div>
);

export const TeamDetailsHeaderSkeletonShape = (
    <div className="TeamDetailsHeaderSkeletonShape">
        <div className="rect" />
        <div className="rect-right" />
    </div>
);
