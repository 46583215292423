import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubSportsIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M6.24 16.32C6.915 15.795 7.4439 15.1575 7.8267 14.4075C8.2089 13.6575 8.4 12.855 8.4 12C8.4 11.145 8.2089 10.3425 7.8267 9.5925C7.4439 8.8425 6.915 8.205 6.24 7.68C5.775 8.31 5.4186 8.9925 5.1708 9.7275C4.9236 10.4625 4.8 11.22 4.8 12C4.8 12.78 4.9236 13.5375 5.1708 14.2725C5.4186 15.0075 5.775 15.69 6.24 16.32ZM12 19.2C12.825 19.2 13.6239 19.0686 14.3967 18.8058C15.1689 18.5436 15.87 18.15 16.5 17.625C15.645 16.935 14.9814 16.0989 14.5092 15.1167C14.0364 14.1339 13.8 13.095 13.8 12C13.8 10.905 14.0364 9.8661 14.5092 8.8833C14.9814 7.9011 15.645 7.065 16.5 6.375C15.87 5.85 15.1689 5.4564 14.3967 5.1942C13.6239 4.9314 12.825 4.8 12 4.8C11.175 4.8 10.3764 4.9314 9.6042 5.1942C8.8314 5.4564 8.13 5.85 7.5 6.375C8.355 7.065 9.0189 7.9011 9.4917 8.8833C9.9639 9.8661 10.2 10.905 10.2 12C10.2 13.095 9.9639 14.1339 9.4917 15.1167C9.0189 16.0989 8.355 16.935 7.5 17.625C8.13 18.15 8.8314 18.5436 9.6042 18.8058C10.3764 19.0686 11.175 19.2 12 19.2ZM17.76 16.32C18.225 15.69 18.5811 15.0075 18.8283 14.2725C19.0761 13.5375 19.2 12.78 19.2 12C19.2 11.22 19.0761 10.4625 18.8283 9.7275C18.5811 8.9925 18.225 8.31 17.76 7.68C17.085 8.205 16.5564 8.8425 16.1742 9.5925C15.7914 10.3425 15.6 11.145 15.6 12C15.6 12.855 15.7914 13.6575 16.1742 14.4075C16.5564 15.1575 17.085 15.795 17.76 16.32ZM12 21C10.755 21 9.585 20.7636 8.49 20.2908C7.395 19.8186 6.4425 19.1775 5.6325 18.3675C4.8225 17.5575 4.1814 16.605 3.7092 15.51C3.2364 14.415 3 13.245 3 12C3 10.755 3.2364 9.585 3.7092 8.49C4.1814 7.395 4.8225 6.4425 5.6325 5.6325C6.4425 4.8225 7.395 4.1811 8.49 3.7083C9.585 3.2361 10.755 3 12 3C13.245 3 14.415 3.2361 15.51 3.7083C16.605 4.1811 17.5575 4.8225 18.3675 5.6325C19.1775 6.4425 19.8186 7.395 20.2908 8.49C20.7636 9.585 21 10.755 21 12C21 13.245 20.7636 14.415 20.2908 15.51C19.8186 16.605 19.1775 17.5575 18.3675 18.3675C17.5575 19.1775 16.605 19.8186 15.51 20.2908C14.415 20.7636 13.245 21 12 21Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubSportsIcon;
