import { ClubCategoryDto } from 'Api/Features/Clubs/Dtos/ClubCategoryDto';
import Button from 'Components/button';
import ClubIconColorLabel from 'Components/club-icon-color-label';
import VerticalThreeDotMenuButton, {
    ThreeDotMenuOption,
} from 'Components/vertical-three-dot-menu-button';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

export interface ClubDetailsHeaderProps {
    isJoined: boolean;
    joinOnClick: () => void;
    clubCategory: ClubCategoryDto;
    menuOptions: ThreeDotMenuOption[];
}

const ClubDetailsHeader: React.FunctionComponent<ClubDetailsHeaderProps> = ({
    isJoined,
    joinOnClick,
    clubCategory,
    menuOptions,
}) => {
    const { t } = useTranslation();

    return (
        <ClubIconColorLabel className="ClubDetailsHeader" clubCategory={clubCategory} background>
            <div className="club-details-header-content">
                <ClubIconColorLabel clubCategory={clubCategory} type="icon-color" />

                {isJoined ? (
                    <VerticalThreeDotMenuButton menuOptions={menuOptions} />
                ) : (
                    <Button type="floating" text={t('join')} onClick={joinOnClick} />
                )}
            </div>
        </ClubIconColorLabel>
    );
};

export default memo(ClubDetailsHeader);
