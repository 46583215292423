import { Checkbox, Dropdown, Menu } from 'antd';
import { PolicyBundleRestrictionsDto } from 'Api/Features/Policies/Dtos/PolicyBundleRestrictionsDto';
import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { ScheduleEventSourceDto } from 'Api/Features/Schedules/Dtos/ScheduleEventSourceDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import Button from 'Components/button';
import TimelineTooltip, { TimelineTooltipType } from 'Components/hour-timeline/timeline-tooltip';
import {
    ArrowClockWiseIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    WarningRedIcon,
} from 'Components/icons';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import Skeleton from 'Components/skeleton';
import TooltipWrapper from 'Components/tooltip-wrapper';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    FORMAT_DAY,
    FORMAT_DAY_WRITTEN_ABBR,
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    TWENTY_FOUR_HOUR_MINUTE,
    FORMAT_YEAR_MONTH_DAY,
    FORMAT_MONTH_DATE_COMMA_YEAR,
    FORMAT_MONTH_DAY,
    FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE,
} from 'Models/Constants';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, Culture, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import { DividedScheduleEventDto } from 'Routes/plan-schedule/plan-schedule';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { theme } from 'Style/theme';
import {
    calendarEventOverlapsRestriction,
    coreHoursToCalendarEventTime,
    isTimeRestricted,
} from 'Utils/CalendarUtils';
import {
    createMomentFromUtcWithoutChangingTime,
    createTimeMomentInTimezoneWithoutConverting,
    getUtcOffsetString,
    momentIsLastPossibleTimePeriod,
} from 'Utils/TimeUtils';
import TimePeriodModal, {
    CreateUserScheduleEventRequestDtoExtended,
    RemoveEvent,
    TimePeriodType,
} from './components/time-period-modal';
import './index.less';
import { WeeklyScheduleCalendarButtonCreateSkeletonShapes } from './skeleton-shapes';

export interface CalendarEvent {
    start: Date;
    end: Date;
    eventType: CalendarEventType;
    workAreaType: WorkAreaType;
    officeId?: string;
    officeName?: string;
    spaceId?: string;
    spaceName?: string;
    capacity?: string;
    id?: string;
    source?: ScheduleEventSourceDto | null;
    eventSpansMultipleDays: boolean;
    originalEventStartTime?: string;
    originalEventEndTime?: string;
    desk?: string;
    floor?: string;
}

enum CalendarEventType {
    Office = 'Office',
    Remote = 'Remote',
    OffHour = 'OffHour',
    Away = 'Away',
    CoreHour = 'CoreHour',
}

enum WorkAreaType {
    Office = 'Office',
    Remote = 'Remote',
}

interface TimePeriodModalState {
    visible: boolean;
    startTime?: string;
    endTime?: string;
    weekRange: string[];
    office?: SingleSelectCustomOption;
    space?: SingleSelectCustomOption;
    isOffHour?: boolean;
    workType?: WorkTypeDto;
    isEditing?: boolean;
    isAllDay?: boolean;
    id?: string;
    source?: ScheduleEventSourceDto;
    apiEventStartString?: string;
    apiEventEndString?: string;
    startDate?: string;
    endDate?: string;
    timePeriodType: TimePeriodType;
    desk?: string;
    floor?: string;
}

export enum EventChangeCommandType {
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete',
    OverrideDefaultSchedule = 'OverrideDefaultSchedule',
    DeleteDefaultSchedule = 'DeleteDefaultSchedule',
}

interface EventChangeCommand {
    event: CreateUserScheduleEventRequestDtoExtended;
    commandType: EventChangeCommandType;
}

export interface EventChangeCommands {
    commands: EventChangeCommand[];
}

enum VisualFilterEnum {
    CoreHours = 'CoreHours',
}

export enum WeeklyScheduleCalendarType {
    Personal = 'Personal',
    Coworker = 'Coworker',
}

interface WeeklyScheduleCalendarProps {
    events: DividedScheduleEventDto[];
    onEventsChange: (eventCommands: EventChangeCommands) => void;
    onDateRangeChange: ({ start, end }) => void;
    timezone: string;
    currentWeek: { start: string; end: string };
    editedDates: string[];
    onRevertToDefaultClick: (date: string) => void;
    copyEvent?: ScheduleEventDto;
    restrictions?: PolicyBundleRestrictionsDto;
    isLoaded?: {
        policyStore?: boolean;
        attendee?: boolean;
    };
    weeklyScheduleCalendarType: WeeklyScheduleCalendarType;
}

const WeeklyScheduleCalendar: React.FunctionComponent<WeeklyScheduleCalendarProps> = observer(
    ({
        events,
        onEventsChange,
        onDateRangeChange,
        timezone,
        currentWeek,
        editedDates,
        onRevertToDefaultClick,
        copyEvent,
        restrictions,
        isLoaded,
        weeklyScheduleCalendarType,
    }) => {
        const localizer = momentLocalizer(moment);

        const { t } = useTranslation();
        const { languageStore } = useStores();

        const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
        const [timePeriodModalState, setTimePeriodModalState] = useState<TimePeriodModalState>({
            visible: false,
            weekRange: [],
            timePeriodType: TimePeriodType.WorkPeriod,
        });
        const { policyStore } = useStores();

        const [showCoreHoursCheckboxChecked, setShowCoreHoursCheckboxChecked] = useState(true);
        const [selectedVisualfilters, setSelectedVisualFilters] = useState<VisualFilterEnum[]>([
            VisualFilterEnum.CoreHours,
        ]);

        const getTimezonedMomentObject = (dateString?: string): moment.Moment => {
            return moment.tz(dateString, timezone);
        };

        const parseEventType = (
            eventType?: EventTypeDto,
            workType?: WorkTypeDto | null
        ): CalendarEventType => {
            if (eventType && eventType === EventTypeDto.OffHourAvailability)
                return CalendarEventType.OffHour;
            if (eventType && eventType === EventTypeDto.Work) {
                if (workType && workType === WorkTypeDto.Office) return CalendarEventType.Office;
                if (workType && workType === WorkTypeDto.Remote) return CalendarEventType.Remote;
            }
            if (eventType && eventType === EventTypeDto.Away) return CalendarEventType.Away;

            return CalendarEventType.OffHour;
        };

        const parseWorkAreaType = (workType?: WorkTypeDto | null): WorkAreaType => {
            if (workType === WorkTypeDto.Office) return WorkAreaType.Office;
            else return WorkAreaType.Remote;
        };

        useEffect(() => {
            const parsedEvents: CalendarEvent[] = events.map((event: DividedScheduleEventDto) => {
                const start = getTimezonedMomentObject(event.startTime);
                const end = getTimezonedMomentObject(event.endTime);
                const eventEndsAtMidnight = end.hour() === 0 && end.minute() === 0;

                const calendarEvent: CalendarEvent = {
                    start: new Date(
                        start.year(),
                        start.month(),
                        start.date(),
                        start.hour(),
                        start.minute()
                    ),
                    end: new Date(
                        //should always be same as start. Disregarding what api sends because of overlaps
                        //if converting timezone makes an event overlap into an other day, we seperated it into two events.
                        //the first event end is 00:00 but we must put it as 23:59 so that it can be displayed correctly
                        start.year(),
                        start.month(),
                        start.date(),
                        eventEndsAtMidnight ? 23 : end.hour(),
                        eventEndsAtMidnight ? 59 : end.minute()
                    ),
                    eventType: parseEventType(event.type, event.workType),
                    workAreaType: parseWorkAreaType(event.workType),
                    id: event.id,
                    capacity: event.capacity,
                    source: event.source,
                    eventSpansMultipleDays: event.eventSpansMultipleDays,
                    originalEventEndTime: event.originalEventEndTime,
                    originalEventStartTime: event.originalEventStartTime,
                    officeId: event.office?.id,
                    officeName: event.office?.name ?? undefined,
                    spaceId: event.officeSpace?.id,
                    spaceName: event.officeSpace?.name ?? undefined,
                    desk: event.desk ?? undefined,
                    floor: event.floor ?? undefined,
                };

                return calendarEvent;
            });

            //create core hours fake events. Only for personal calendar
            if (
                weeklyScheduleCalendarType === WeeklyScheduleCalendarType.Personal &&
                selectedVisualfilters.includes(VisualFilterEnum.CoreHours) &&
                policyStore.policyInfo?.policyBundleObjectivesDto?.coreHoursGroups
            ) {
                const coreHourDates = coreHoursToCalendarEventTime(
                    policyStore.policyInfo?.policyBundleObjectivesDto?.coreHoursGroups,
                    currentWeek,
                    languageStore.currentLanguage
                );

                coreHourDates.forEach((coreHour) => {
                    parsedEvents.push({
                        start: coreHour.start,
                        end: coreHour.end,
                        eventType: CalendarEventType.CoreHour,
                        workAreaType: WorkAreaType.Office,
                        eventSpansMultipleDays: false,
                    });
                });
            }

            setCalendarEvents(parsedEvents);
        }, [
            events,
            selectedVisualfilters,
            policyStore.policyInfo?.policyBundleObjectivesDto,
            currentWeek,
        ]);

        //Scroll to Earliest event
        const getTimeToScrollTo = useCallback(() => {
            if (events.length === 0) return new Date();

            let timeToScroll;
            let comparerDate;
            events.forEach((event, i) => {
                if (i === 0) {
                    comparerDate = getTimezonedMomentObject(event.startTime).day(1).month(1);
                    timeToScroll = event.startTime;
                } else if (
                    getTimezonedMomentObject(event.startTime).day(1).month(1).isBefore(comparerDate)
                ) {
                    comparerDate = getTimezonedMomentObject(event.startTime).day(1).month(1);
                    timeToScroll = event.startTime;
                }
            });
            return createMomentFromUtcWithoutChangingTime(timeToScroll).toDate();
        }, [events]);

        const formats = {
            timeGutterFormat: (start: Date, culture?: Culture, localizer?: DateLocalizer) =>
                localizer?.format(
                    start,
                    FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE[languageStore.currentLanguage],
                    languageStore.currentLanguage
                ) ?? '',
            dayRangeHeaderFormat: (
                range: { start: Date; end: Date },
                culture?: Culture,
                localizer?: DateLocalizer
            ): string =>
                localizer
                    ? localizer.format(
                          range.start,
                          FORMAT_MONTH_DAY[languageStore.currentLanguage],
                          languageStore.currentLanguage
                      ) +
                      ' - ' +
                      localizer?.format(
                          range.end,
                          FORMAT_MONTH_DATE_COMMA_YEAR[languageStore.currentLanguage],
                          languageStore.currentLanguage
                      )
                    : '',
        };

        const SlotPropGetter = useCallback(
            (date) => {
                return {
                    className: isTimeRestricted(date, languageStore.currentLanguage, restrictions)
                        ? 'restricted'
                        : '',
                };
            },
            [restrictions]
        );

        const handleTimeSlotClick = (e) => {
            if (weeklyScheduleCalendarType !== WeeklyScheduleCalendarType.Personal) return;
            if (isTimeRestricted(e.start, languageStore.currentLanguage, restrictions)) return;

            const startTimeString = e.start.toString();
            const momentStartTime =
                startTimeString.includes('15:00') || startTimeString.includes('45:00')
                    ? moment(e.start).add(-15, 'minutes')
                    : moment(e.start);

            setTimePeriodModalState({
                visible: true,
                startTime: momentStartTime.format(TWENTY_FOUR_HOUR_MINUTE),
                weekRange: [currentWeek.start, currentWeek.end],
                source: ScheduleEventSourceDto.PersistedSchedule,
                startDate: momentStartTime.format(),
                endDate: momentStartTime.format(),
                timePeriodType: TimePeriodType.WorkPeriod,
            });
        };

        const handleEventClick = (event: CalendarEvent) => {
            if (
                weeklyScheduleCalendarType !== WeeklyScheduleCalendarType.Personal ||
                event.eventType === CalendarEventType.CoreHour
            )
                return;
            const start = createTimeMomentInTimezoneWithoutConverting(
                event.originalEventStartTime ?? ''
            );
            const end = createTimeMomentInTimezoneWithoutConverting(
                event.originalEventEndTime ?? ''
            );

            setTimePeriodModalState({
                visible: true,
                isEditing: true,
                startTime: start.format(TWENTY_FOUR_HOUR_MINUTE),
                endTime: end.format(TWENTY_FOUR_HOUR_MINUTE),
                office: event.officeId
                    ? { value: event.officeId, label: event.officeName ?? '' }
                    : undefined,
                space: event.spaceId
                    ? {
                          value: event.spaceId,
                          label: event.spaceName ?? '',
                          content: {
                              node: (
                                  <div>
                                      {t('TimePeriodModal.occupancy_period')}: {event.capacity}
                                  </div>
                              ),
                              className: 'text-caption-1',
                              showSelected: true,
                          },
                      }
                    : undefined,
                isOffHour: event.eventType === CalendarEventType.OffHour,
                workType:
                    event.workAreaType === WorkAreaType.Office
                        ? WorkTypeDto.Office
                        : WorkTypeDto.Remote,
                id: event.id,
                weekRange: [currentWeek.start, currentWeek.end],
                source: event.source ?? ScheduleEventSourceDto.DefaultSchedule,
                apiEventStartString: event.originalEventStartTime,
                apiEventEndString: event.originalEventEndTime,
                startDate: event.originalEventStartTime,
                endDate: event.originalEventEndTime,
                timePeriodType:
                    event.eventType === CalendarEventType.Away
                        ? TimePeriodType.AwayPeriod
                        : TimePeriodType.WorkPeriod,
                isAllDay:
                    start.hour() === 0 &&
                    start.minute() === 0 &&
                    end.hour() === 23 &&
                    end.minute() === 59,
                desk: event.desk,
                floor: event.floor,
            });
        };

        const CustomEventComponentCoreHour = (event: CalendarEvent): JSX.Element => {
            return (
                <div className="custom-event-inner-container" title={undefined}>
                    <TooltipWrapper
                        backgroundColor={theme['layout-high-contrast']}
                        textColor={theme['text-low-contrast']}
                        borderRadius={5}
                        padding={[10]}
                        title={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className="text-caption-2-bold text-white"
                                    style={{ paddingBottom: '5px' }}
                                >
                                    {t('Policy.core_hours_title')}
                                </div>
                                <div className="text-caption-3 text-white">
                                    {moment
                                        .tz(event.start, timezone)
                                        .format(
                                            FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                                languageStore.currentLanguage
                                            ]
                                        )}
                                    {' - '}
                                    {moment
                                        .tz(event.end, timezone)
                                        .format(
                                            FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                                languageStore.currentLanguage
                                            ]
                                        )}
                                </div>
                            </div>
                        }
                        placement="left"
                    >
                        <div className="core-hour-blueline-indicator"></div>
                    </TooltipWrapper>
                </div>
            );
        };

        const CustomEventComponent = useCallback(
            (calendarObject: any): JSX.Element => {
                const event: CalendarEvent = calendarObject.event;
                let fill, title, hoverTitle, offHourOfficeText;
                //Core hour events are fake events we create to have the left blue bar
                if (event.eventType === CalendarEventType.CoreHour) {
                    return CustomEventComponentCoreHour(event);
                }

                const isInvalid = calendarEventOverlapsRestriction(
                    event.originalEventStartTime ?? '',
                    event.originalEventEndTime ?? '',
                    languageStore.currentLanguage,
                    restrictions,
                    false,
                    timezone
                );

                switch (event.eventType) {
                    case CalendarEventType.OffHour:
                        {
                            fill = theme['warning-high-contrast'];
                            title = t('PrefCalendar.off_hour_availability');
                            hoverTitle =
                                t('PrefCalendar.off_hour_availability') +
                                (event.officeName
                                    ? ' - ' +
                                      event.officeName +
                                      (event.spaceName ? ' - ' + event.spaceName : '')
                                    : '');
                            offHourOfficeText =
                                event.workAreaType === WorkAreaType.Office
                                    ? event.officeName ?? t('at_office')
                                    : t('remote');
                        }
                        break;
                    case CalendarEventType.Office:
                        {
                            fill = theme['accent-high-contrast'];
                            title = event.officeName ?? t('PrefCalendar.working_office');
                            hoverTitle = event.officeName
                                ? event.officeName +
                                  (event.spaceName ? ' - ' + event.spaceName : '')
                                : t('PrefCalendar.working_office');
                        }
                        break;
                    case CalendarEventType.Remote:
                        {
                            fill = theme['primary-high-contrast'];
                            title = t('PrefCalendar.working_remote');
                            hoverTitle = t('PrefCalendar.working_remote');
                        }
                        break;
                    case CalendarEventType.Away:
                        {
                            fill = theme['text-high-contrast'];
                            title = t('away_period');
                            hoverTitle = t('away_period');
                        }
                        break;
                }

                const timeString = `${moment
                    .tz(event.originalEventStartTime, timezone)
                    .format(
                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
                    )} - ${
                    momentIsLastPossibleTimePeriod(event.originalEventEndTime ?? '', false)
                        ? '12:00 AM'
                        : moment
                              .tz(event.originalEventEndTime, timezone)
                              .format(
                                  FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                      languageStore.currentLanguage
                                  ]
                              )
                }`;

                if (moment(event.start).add(30, 'minute').isSameOrAfter(moment(event.end))) {
                    title = title + '...';
                }

                return (
                    <div
                        className="custom-event-inner-container"
                        title={`${hoverTitle}: ${timeString}`}
                    >
                        <div>
                            <div className="title text-caption-1-bold" style={{ color: fill }}>
                                {isInvalid && (
                                    <TooltipWrapper
                                        backgroundColor={theme['layout-high-contrast']}
                                        textColor={theme['text-low-contrast']}
                                        width={
                                            languageStore.currentLanguage === SupportedLanguage.EN
                                                ? 290
                                                : 310
                                        }
                                        title={t('PrefCalendar.event_time_restriction')}
                                        placement="top"
                                    >
                                        <span className="warning">
                                            <WarningRedIcon width={16} height={16} />
                                        </span>
                                    </TooltipWrapper>
                                )}

                                {title}

                                {isLoaded?.attendee ? (
                                    <div className="capacity">{event.capacity}</div>
                                ) : null}
                            </div>
                            <div
                                className="off-hour-office text-caption-3-bold"
                                style={{ color: fill }}
                            >
                                {offHourOfficeText}
                            </div>
                            <span className="uppercase">
                                <ClockIcon width={12} height={12} fill={fill} />
                                <span
                                    className="time-string text-caption-3-bold"
                                    style={{ color: fill }}
                                >
                                    {timeString}
                                </span>
                            </span>
                        </div>
                        <div className="office grospe text-caption-3-bold" style={{ color: fill }}>
                            {event.spaceName} {event.floor} {event.desk}
                        </div>
                    </div>
                );
            },
            [timezone, isLoaded?.attendee]
        );

        const CustomToolbar = useCallback(
            (toolbar: any): JSX.Element => {
                const canMoveBack = moment().startOf('week').isBefore(moment(currentWeek.start));
                const moveBack = (): void => canMoveBack && toolbar.onNavigate('PREV');
                const moveForward = (): void => toolbar.onNavigate('NEXT');
                const goToCurrent = (): void => toolbar.onNavigate('TODAY');

                return (
                    <div className="customToolbar">
                        <div className="customToolbar-bottom">
                            <div className="flex-wrap-container">
                                <div className="date text-headline">{toolbar.label}</div>
                                <div className="date-change-buttons-container">
                                    <div onClick={moveBack} className="back">
                                        <ChevronLeftIcon
                                            fill={
                                                theme[
                                                    canMoveBack
                                                        ? 'primary-mid-contrast'
                                                        : 'text-disabled'
                                                ]
                                            }
                                            width={23}
                                            height={23}
                                        />
                                    </div>
                                    <Button
                                        text={t('today')}
                                        type="link"
                                        width="hugged"
                                        onClick={goToCurrent}
                                    />
                                    <div onClick={moveForward}>
                                        <ChevronRightIcon
                                            fill={theme['primary-mid-contrast']}
                                            width={23}
                                            height={23}
                                        />
                                    </div>
                                </div>

                                {weeklyScheduleCalendarType ===
                                    WeeklyScheduleCalendarType.Personal && (
                                    <div className="visual-filter-container">
                                        <Checkbox
                                            checked={showCoreHoursCheckboxChecked}
                                            onChange={(e) => {
                                                setShowCoreHoursCheckboxChecked(e.target.checked);
                                                if (e.target.checked)
                                                    setSelectedVisualFilters([
                                                        VisualFilterEnum.CoreHours,
                                                    ]);
                                                else setSelectedVisualFilters([]);
                                            }}
                                        >
                                            {t('show_core_hours')}
                                        </Checkbox>
                                    </div>
                                )}
                            </div>

                            {weeklyScheduleCalendarType === WeeklyScheduleCalendarType.Personal && (
                                <Skeleton
                                    placeholder={WeeklyScheduleCalendarButtonCreateSkeletonShapes}
                                    isLoaded={isLoaded?.policyStore}
                                >
                                    <Dropdown
                                        overlayClassName="create-period-ant-dropdown-overlay-class"
                                        overlay={
                                            <Menu>
                                                <Menu.Item
                                                    onClick={() => {
                                                        setTimePeriodModalState({
                                                            visible: true,
                                                            weekRange: [
                                                                currentWeek.start,
                                                                currentWeek.end,
                                                            ],
                                                            timePeriodType:
                                                                TimePeriodType.WorkPeriod,
                                                        });
                                                    }}
                                                    key={1}
                                                >
                                                    <div className="title">{t('work_period')}</div>
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={() => {
                                                        setTimePeriodModalState({
                                                            visible: true,
                                                            weekRange: [
                                                                currentWeek.start,
                                                                currentWeek.end,
                                                            ],
                                                            timePeriodType:
                                                                TimePeriodType.AwayPeriod,
                                                        });
                                                    }}
                                                    key={0}
                                                >
                                                    <div className="title">{t('away_period')}</div>
                                                </Menu.Item>
                                            </Menu>
                                        }
                                        trigger={['click']}
                                        placement="bottomRight"
                                    >
                                        <Button
                                            text={t('add_new')}
                                            type="floating"
                                            width="hugged"
                                            leftCircleIcon="AddIcon"
                                            rightIcon="ChevronDownIcon"
                                        />
                                    </Dropdown>
                                </Skeleton>
                            )}
                        </div>
                    </div>
                );
            },
            [
                currentWeek,
                weeklyScheduleCalendarType,
                policyStore.policyInfoForSpecificWeek?.policyObjectiveStats,
                selectedVisualfilters,
            ]
        );

        const CustomDayHeaderComponent = useCallback(
            (headerProps) => {
                return (
                    <div className="customDateHeader">
                        <div className="date-container">
                            <span className="text-caption-2">
                                {moment(headerProps.date).format(FORMAT_DAY_WRITTEN_ABBR)}
                            </span>
                            <div
                                className={`date-number text-body-bold ${
                                    moment.utc(headerProps.date).isSame(moment(), 'day')
                                        ? 'current-date text-caption-2'
                                        : ''
                                }`}
                            >
                                {moment(headerProps.date).format(FORMAT_DAY)}
                            </div>
                            {editedDates &&
                                editedDates.some(
                                    (date) =>
                                        date ===
                                        moment.utc(headerProps.date).format(FORMAT_YEAR_MONTH_DAY)
                                ) && (
                                    <TimelineTooltip
                                        type={TimelineTooltipType.resetDefaultSchedule}
                                        title={t('ScheduleCalendar.reset_to_default_schedule')}
                                        lowercase={true}
                                    >
                                        <div
                                            className="d-flex"
                                            onClick={() =>
                                                onRevertToDefaultClick(
                                                    moment(headerProps.date).format(
                                                        FORMAT_YEAR_MONTH_DAY
                                                    )
                                                )
                                            }
                                        >
                                            <ArrowClockWiseIcon
                                                fill={theme['primary-mid-contrast']}
                                            />
                                        </div>
                                    </TimelineTooltip>
                                )}
                        </div>
                    </div>
                );
            },
            [editedDates]
        );

        const CustomTimeColumnHeader = useCallback(
            () => (
                <div className="customGutterHeader">
                    <div className="utc-container text-caption-3 uppercase">
                        <ClockIcon width={16} height={16} fill={theme['primary-mid-contrast']} />
                        {'utc '}
                        {getUtcOffsetString(timezone)}
                    </div>
                </div>
            ),
            [timezone]
        );

        //https://jquense.github.io/react-big-calendar/examples/iframe.html?id=props--event-prop-getter&viewMode=docs&args=
        const eventPropGetter = useCallback(
            (event: CalendarEvent /*, start, end, isSelected*/) => {
                let quarterHourEvent = '';
                if (
                    getTimezonedMomentObject(event.start.toString())
                        .add(15, 'minute')
                        .isSameOrAfter(getTimezonedMomentObject(event.end.toString()))
                ) {
                    quarterHourEvent = 'quarterHourEvent';
                }

                const coreHoursDisplayed = selectedVisualfilters.includes(
                    VisualFilterEnum.CoreHours
                )
                    ? 'core-hour-displayed'
                    : '';

                return {
                    ...(event.eventType === CalendarEventType.Office && {
                        className: `pref-type-office text-caption-1-bold ${quarterHourEvent} ${coreHoursDisplayed}`,
                    }),
                    ...(event.eventType === CalendarEventType.OffHour && {
                        className: `pref-type-offhour text-caption-1-bold ${quarterHourEvent} ${coreHoursDisplayed}`,
                    }),
                    ...(event.eventType === CalendarEventType.Remote && {
                        className: `pref-type-remote text-caption-1-bold ${quarterHourEvent} ${coreHoursDisplayed}`,
                    }),
                    ...(event.eventType === CalendarEventType.Away && {
                        className: `pref-type-away text-caption-1-bold ${quarterHourEvent} ${coreHoursDisplayed}`,
                    }),
                    ...(event.eventType === CalendarEventType.CoreHour && {
                        className: `pref-type-coreHour ${quarterHourEvent}`,
                    }),
                };
            },
            [selectedVisualfilters]
        );
        // -- End Region Calendar props

        const handleTimePeriodModalSubmit = (
            newEvent?: CreateUserScheduleEventRequestDtoExtended,
            removeEvents?: RemoveEvent[]
        ) => {
            setTimePeriodModalState({
                visible: false,
                weekRange: [],
                timePeriodType: TimePeriodType.WorkPeriod,
            });

            if (!onEventsChange || (!newEvent && !removeEvents)) return;

            const commands: EventChangeCommand[] = [];
            if (removeEvents && removeEvents.length > 0) {
                //A seperate call needs to be sent to updateUserScheduleTimeline to remove the old default event when editing it
                removeEvents
                    .filter((event) => event.source === ScheduleEventSourceDto.DefaultSchedule)
                    ?.map((event) => {
                        commands.push({
                            commandType: event.isDefaultScheduleEventDelete
                                ? EventChangeCommandType.DeleteDefaultSchedule
                                : EventChangeCommandType.OverrideDefaultSchedule,
                            event: {
                                startTime: event.eventStartString,
                                endTime: event.eventEndString,
                            },
                        });
                    });

                //removing normal events
                removeEvents
                    .filter((event) => event.source !== ScheduleEventSourceDto.DefaultSchedule)
                    ?.map((event) => {
                        commands.push({
                            commandType: EventChangeCommandType.Delete,
                            event: {
                                startTime: event.eventStartString,
                                endTime: event.eventEndString,
                                id: event.id,
                            },
                        });
                    });
            }

            if (newEvent) {
                //editing default events equals creating a new event.
                if (newEvent.source === ScheduleEventSourceDto.DefaultSchedule) {
                    commands.push({
                        commandType: EventChangeCommandType.Create,
                        event: newEvent,
                    });
                } else {
                    if (newEvent.id) {
                        commands.push({
                            commandType: EventChangeCommandType.Update,
                            event: newEvent,
                        });
                    } else {
                        commands.push({
                            commandType: EventChangeCommandType.Create,
                            event: newEvent,
                        });
                    }
                }
            }

            onEventsChange({
                commands,
            } as EventChangeCommands);
        };

        useEffect(() => {
            //it is possible for other components to pass an event to copy. Open up corresponding away or timeperiod modal and set info.
            if (copyEvent) {
                const start = createTimeMomentInTimezoneWithoutConverting(
                    copyEvent.startTime ?? ''
                );
                const end = createTimeMomentInTimezoneWithoutConverting(copyEvent.endTime ?? '');

                setTimePeriodModalState({
                    visible: true,
                    startTime: start.format(TWENTY_FOUR_HOUR_MINUTE),
                    endTime: end.format(TWENTY_FOUR_HOUR_MINUTE),
                    isAllDay:
                        start.hour() === 0 &&
                        start.minute() === 0 &&
                        end.hour() === 23 &&
                        end.minute() === 59,
                    weekRange: [
                        start.clone().startOf('week').format(),
                        start.clone().endOf('week').format(),
                    ],
                    apiEventStartString: copyEvent.startTime,
                    apiEventEndString: copyEvent.endTime,
                    startDate: copyEvent.startTime,
                    endDate: copyEvent.endTime,
                    isOffHour: copyEvent.type === EventTypeDto.OffHourAvailability,
                    workType: copyEvent.workType ?? undefined,
                    timePeriodType:
                        copyEvent.workType === WorkTypeDto.Away
                            ? TimePeriodType.AwayPeriod
                            : TimePeriodType.WorkPeriod,
                });
            }
        }, [copyEvent]);

        return (
            <div className="WeeklyScheduleCalendar">
                <Calendar
                    selectable
                    localizer={localizer}
                    events={calendarEvents}
                    //https://jquense.github.io/react-big-calendar/examples/index.html?path=/story/props-full-prop-list--page#date
                    date={moment(currentWeek.start).toDate()}
                    defaultView="week"
                    views={['week']}
                    step={15}
                    timeslots={4}
                    formats={formats}
                    eventPropGetter={eventPropGetter}
                    slotPropGetter={SlotPropGetter}
                    components={{
                        toolbar: CustomToolbar,
                        timeGutterHeader: CustomTimeColumnHeader,
                        event: CustomEventComponent,
                        header: CustomDayHeaderComponent,
                    }}
                    scrollToTime={getTimeToScrollTo()}
                    onSelectSlot={handleTimeSlotClick}
                    onSelectEvent={handleEventClick}
                    onSelecting={() => false} //disable the dragging create event
                    onRangeChange={(range) => {
                        if (!onDateRangeChange) return;
                        if (Array.isArray(range)) {
                            const dateRange = range as Date[];
                            const dateRangeObject = {
                                start: dateRange[0],
                                end: range[dateRange.length - 1],
                            };
                            onDateRangeChange(dateRangeObject);
                        }
                    }}
                    tooltipAccessor={undefined}
                />

                {timePeriodModalState.visible && (
                    <TimePeriodModal
                        visible={timePeriodModalState.visible}
                        startTime={timePeriodModalState.startTime}
                        endTime={timePeriodModalState.endTime}
                        isEditing={timePeriodModalState.isEditing}
                        isOffHour={timePeriodModalState.isOffHour}
                        office={timePeriodModalState.office}
                        space={timePeriodModalState.space}
                        desk={timePeriodModalState.desk}
                        floor={timePeriodModalState.floor}
                        workType={timePeriodModalState.workType}
                        onComplete={handleTimePeriodModalSubmit}
                        calendarEvents={events}
                        weekRange={timePeriodModalState.weekRange}
                        id={timePeriodModalState.id}
                        source={timePeriodModalState.source}
                        apiEventEndString={timePeriodModalState.apiEventEndString}
                        apiEventStartString={timePeriodModalState.apiEventStartString}
                        startDate={timePeriodModalState.startDate}
                        endDate={timePeriodModalState.endDate}
                        timePeriodType={timePeriodModalState.timePeriodType}
                    />
                )}
            </div>
        );
    }
);
export default WeeklyScheduleCalendar;
