import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import React from 'react';
import './happenings-collapse.less';
import { Collapse } from 'antd';
import Icon from 'Components/icons/Icon';
import { CalendarStarIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import moment from 'moment';
import { EVENTS_URL, FORMAT_SHORT_MONTH_DATE_TIME } from 'Models/Constants';
import { getHappeningAttendanceTypeIcon, getHappeningDefaultImg } from 'Utils/HappeningUtils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { img, ImgSize } from 'Utils/ImgUtils';
import { RichTextDisplay } from 'Components/rich-text-display/rich-text-display';
import RSVPInfo from 'Components/rsvp-info';
import Button from 'Components/button';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';

interface HappeningsCollapseProps {
    happenings: HappeningDto[];
    hideViewButton?: boolean;
    hideImages?: boolean;
    hideViewAll?: boolean;
}

const HappeningsCollapse: React.FunctionComponent<HappeningsCollapseProps> = observer(
    ({ happenings, hideViewButton, hideImages, hideViewAll }) => {
        const { languageStore } = useStores();
        const { Panel } = Collapse;
        const { t } = useTranslation();
        const history = useHistory();

        const OnPressViewDetail = (happening: HappeningDto) => {
            if (!happening) return;
            const { id } = happening;

            history.push(EVENTS_URL + '/' + id);
        };

        const getHeader = (happening: HappeningDto) => {
            return (
                <div className="header-container">
                    <div className="icon-left">
                        <CalendarStarIcon fill={theme['white']} width={21} height={21} />
                    </div>
                    <div className="text-container">
                        <div className="text-body-bold">{happening.name}</div>
                        <div className="text-caption-1 capitalize">{`${moment(
                            happening?.startTime
                        ).format(
                            FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage]
                        )} - ${moment(happening?.endTime).format(
                            FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage]
                        )}`}</div>
                    </div>
                </div>
            );
        };

        return (
            <div className="HappeningsCollapse">
                <Collapse
                    accordion
                    className="CoworkersScheduleCollapse"
                    expandIconPosition={'right'}
                    style={{ border: 0 }}
                    expandIcon={({ isActive }) => (
                        <span className={`ChevronDownIcon-open-${isActive}`}>
                            <Icon iconName="ChevronDownIcon" />
                        </span>
                    )}
                >
                    <div className="header">
                        <div className="text-body-bold mb-10">{t('events')}</div>
                        {hideViewAll && (
                            <div
                                className="view-all text-callout-bold"
                                onClick={() => history.push(EVENTS_URL)}
                            >
                                {t('view_all')}
                            </div>
                        )}
                    </div>
                    {happenings.map((happening: HappeningDto) => {
                        return (
                            <Panel
                                style={{ border: 0 }}
                                className="Panel"
                                key={`${happening.id}`}
                                header={getHeader(happening)}
                            >
                                <div className="happening-open-container">
                                    <div className="left">
                                        <img
                                            src={
                                                happening.imageUrl
                                                    ? img(happening.imageUrl, ImgSize.l, false)
                                                    : getHappeningDefaultImg(
                                                          happening.attendanceType
                                                      )
                                            }
                                        />
                                    </div>
                                    <div className="right">
                                        <div className="description-icon-container">
                                            <div className="wrapper-icon-text-bottom">
                                                {getHappeningAttendanceTypeIcon(
                                                    happening.attendanceType
                                                )}

                                                <div className="text-caption-1">
                                                    {t(
                                                        `HappeningAttendanceTypeDto.HappeningAttendanceTypeDto_${happening?.attendanceType}`
                                                    )}
                                                </div>
                                            </div>
                                            <div className="wrapper-icon-text-bottom">
                                                <Icon iconName="MapPinWithCircleIcon" />
                                                <div className="text-caption-1">
                                                    {happening?.office?.name}
                                                </div>
                                            </div>
                                        </div>

                                        <RichTextDisplay html={happening.description} />

                                        <div className="bottom">
                                            <RSVPInfo
                                                happening={happening}
                                                hideImages={hideImages}
                                                addToCalendarButtonProps={{
                                                    color: theme['accent-mid-contrast'],
                                                    width: 24,
                                                }}
                                            />
                                            {!hideViewButton && (
                                                <Button
                                                    text={t('view')}
                                                    type="secondary"
                                                    onClick={() => OnPressViewDetail(happening)}
                                                    width="hugged"
                                                    className="button-view"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        );
                    })}
                </Collapse>
            </div>
        );
    }
);

export default HappeningsCollapse;
