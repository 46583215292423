export const denominatorSafeValue = (value?: number): number => {
    return !value ? 1 : value;
};

//@ts-ignore
Number.prototype.toFixedIfNecessary = function (decimalPlaces?: number) {
    if (this === 0) {
        return '0';
    }

    if ((this as any) % 1 !== 0) {
        return this.toFixed(decimalPlaces ? decimalPlaces : 2);
    }

    return this.toString();
};
