import Button from 'Components/button';
import Icon from 'Components/icons/Icon';
import ImagesCoworkers from 'Components/images-coworkers';
import {
    WorkingCoworkersOfficeData,
    WorkingCoworkersTeamData,
} from 'Components/office-occupancy-card';
import StarNumber from 'Components/star-number';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import OccupancyProgressBar from '../occupancy-progress-bar';
import './index.less';

export interface OfficeAndTeamProps {
    dataOffice: WorkingCoworkersOfficeData;
    dataTeam: WorkingCoworkersTeamData;
}

const OfficeAndTeam: React.FunctionComponent<OfficeAndTeamProps> = ({ dataOffice, dataTeam }) => {
    const { t } = useTranslation();

    return (
        <div className="OfficeAndTeam">
            <div className="office-name">
                <Icon iconName="AppartementIcon" /> {dataOffice.officeName}
            </div>

            <div className="label-value-row border-bottom">
                <div className="label">{t('total_coworkers')}</div>
                <div className="value">
                    {dataTeam.favCoworkers > 0 && <StarNumber number={dataTeam.favCoworkers} />}
                    {dataOffice.totalCoworkers}
                </div>
            </div>
            <div className="label-value-row">
                {dataOffice.myTeam ? (
                    <>
                        <div className="label">{t('your_team')}</div>
                        <div className="value">
                            {dataOffice.myTeam.atOffice}/
                            {
                                dataOffice.myTeam.total <= 1
                                    ? 0
                                    : dataOffice.myTeam.total - 1 /*exclude current user */
                            }
                        </div>
                    </>
                ) : (
                    <>
                        <div className="label">{t('no_team')}</div>
                        <div className="value">0</div>
                    </>
                )}
            </div>

            <OccupancyProgressBar
                occupancy={{
                    atOffice: dataOffice.myTeam?.atOffice ?? 0, //if teamless  0/1 will put empty line
                    max: dataOffice.myTeam?.total ?? 1,
                }}
            />

            <div className="coworkers-images">
                {dataOffice.myTeam && (
                    <ImagesCoworkers
                        imageUrls={dataTeam.imgUrls}
                        usersCount={dataTeam.atOffice}
                        maxDisplayCoworkers={5}
                    />
                )}
                <div className="ml-auto">
                    <Button
                        text={t('view_all')}
                        type="link"
                        width="hugged"
                        onClick={dataTeam.onOpenCoworkersAtOfficeModal}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(OfficeAndTeam);
