import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { Empty } from 'Components/empty';
import { CloseIcon } from 'Components/icons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import { observer } from 'mobx-react-lite';
import { default as React } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './selected-users-component.less';
import { renderDefaultOfficeColumn } from 'Utils/WorkplaceUtils';

interface SelectedUsersComponentProps {
    users: UserDto[];
    handleRemoveClick: (user: UserDto) => void;
    height?: number;
}

const SelectedUsersComponent: React.FunctionComponent<SelectedUsersComponentProps> = observer(
    ({ users, handleRemoveClick, height }) => {
        const { t } = useTranslation();
        return (
            <div className="SelectedUsersComponent" style={{ height }}>
                <div className="header">
                    <div className="text-footnote-bold text-primary-high-contrast">{t('name')}</div>
                    <div className="text-footnote-bold text-primary-high-contrast">
                        {t('email')}
                    </div>
                    <div className="text-footnote-bold text-primary-high-contrast">
                        {t('Profile.job_title')}
                    </div>
                    <div className="text-footnote-bold text-primary-high-contrast">
                        {t('default_office')}
                    </div>
                </div>

                <div
                    className="selected-users-container"
                    style={{
                        maxHeight: height ?? undefined,
                        overflowY: users.length < 1 ? undefined : 'auto',
                    }}
                >
                    {users.length < 1 ? (
                        <Empty />
                    ) : (
                        users.map((user) => (
                            <div key={user.id} className="selected-user">
                                <div className="img-name">
                                    <ImageWithPlaceholder
                                        imgSrc={user.imageUrl}
                                        width={32}
                                        height={32}
                                    />
                                    <span className="text-body name">{`${user.firstName ?? ''} ${
                                        user.lastName ?? ''
                                    }`}</span>
                                </div>

                                <div className="text-body">{user.contactInfo?.email}</div>

                                <div className="text-body">{user.jobTitle}</div>

                                <div className="text-body">
                                    {renderDefaultOfficeColumn({
                                        defaultOffice: user.defaultOffice,
                                        defaultOfficeSpace: user.defaultOfficeSpace,
                                        defaultDesk: user.defaultDesk,
                                        defaultFloor: user.defaultFloor,
                                    })}
                                </div>

                                <div className="remove" onClick={() => handleRemoveClick(user)}>
                                    <CloseIcon
                                        width={24}
                                        height={24}
                                        fill={theme['text-mid-contrast']}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        );
    }
);

export default SelectedUsersComponent;
