import React from 'react';
import './index.less';

const NUMBER_SECTION = 6;

export const officesDetailsSkeletonShape = (
    <div className="officesDetailsSkeletonShape">
        {[...Array(NUMBER_SECTION)].map((item, key) => {
            return (
                <div key={key} className="wrapper-one-section">
                    <div className="rect-top" />
                    <div className="rect-bottom" />
                </div>
            );
        })}
    </div>
);

export const floorPlanDropdownShape = (
    <div className="floorPlanDropdownShape">
        <div className="rect"></div>
        <div className="buttonRect ml-20"></div>
        <div className="buttonRect ml-auto"></div>
    </div>
);

export const floorPlanRoundButton = (
    <div className="floorPlanRoundButton">
        <div className="buttonRect"></div>
    </div>
);

export const floorPlanShape = (
    <div className="floorPlanShape">
        <div className="rect info"></div>
        <div className="rect plan"></div>
    </div>
);

export const floorPlanSpacesShape = (
    <div className="floorPlanSpacesShape">
        <div className="rect"></div>
        <div className="rect"></div>
        <div className="rect"></div>
    </div>
);
