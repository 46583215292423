import Icon from 'Components/icons/Icon';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import './image-with-placeholder.less';

export interface ImageWithPlaceholderProps {
    className?: string;
    imgSrc?: string | null;
    img?: ReactNode;
    defaultImgSrc?: string;
    width?: string | number;
    height?: string | number;
    title?: string;
    defaultImg?: ReactNode;
}

const ImageWithPlaceholder: FunctionComponent<ImageWithPlaceholderProps> = ({
    className,
    imgSrc,
    img,
    defaultImgSrc,
    defaultImg = <Icon iconName="PersonIcon" />,
    width,
    height,
    title,
}) => {
    const [imgErrored, setImgErrored] = useState(false);
    const onImgError = (): void => setImgErrored(true);

    const spanStyle = {
        width: width || undefined,
        height: height || undefined,
    };

    return (
        <>
            {imgErrored || (!img && !imgSrc)
                ? (
                      <span
                          style={spanStyle}
                          className={`ImageWithPlaceholder default ${className || ''}`}
                      >
                          {defaultImg || ''}
                      </span>
                  ) || (
                      <img
                          className={`ImageWithPlaceholder ${className || ''}`}
                          src={defaultImgSrc}
                          alt={title}
                          width={width || undefined}
                          height={height || undefined}
                      />
                  )
                : img || (
                      <img
                          className={`ImageWithPlaceholder ${className || ''}`}
                          src={imgSrc!}
                          onError={onImgError}
                          alt={title}
                          width={width || undefined}
                          height={height || undefined}
                      />
                  )}
        </>
    );
};

export default React.memo(ImageWithPlaceholder);
