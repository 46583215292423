// GENERATED FILE - DO NOT MODIFY
import { ConnectSlackWorkspaceRequestDto } from 'Api/Features/Slack/Dtos/ConnectSlackWorkspaceRequestDto';
import { GetSlackChannelsRequestDto } from 'Api/Features/Slack/Dtos/GetSlackChannelsRequestDto';
import { GetSlackChannelsResponseDto } from 'Api/Features/Slack/Dtos/GetSlackChannelsResponseDto';
import { GetSlackConfigResponseDto } from 'Api/Features/Slack/Dtos/GetSlackConfigResponseDto';
import { UpdateSlackConfigRequestDto } from 'Api/Features/Slack/Dtos/UpdateSlackConfigRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class SlackProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getSlackConfig(): Promise<GetSlackConfigResponseDto | null> {
        const uri = this.buildUri(
            "/slack-config",
            null,
            null
        );

        const data: GetSlackConfigResponseDto | null = await this.httpClient.get<GetSlackConfigResponseDto | null>(uri);
        return data;
    }

    public async updateSlackConfig(request: UpdateSlackConfigRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/slack-config",
            null,
            null
        );

        await this.httpClient.put<UpdateSlackConfigRequestDto | null, void>(uri, request);
    }

    public async connectSlackWorkspace(request: ConnectSlackWorkspaceRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/connect-slack-workspace",
            null,
            null
        );

        await this.httpClient.post<ConnectSlackWorkspaceRequestDto | null, void>(uri, request);
    }

    public async disconnectSlackWorkspace(): Promise<void> {
        const uri = this.buildUri(
            "/disconnect-slack-workspace",
            null,
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async isSlackWorkspaceConnected(): Promise<boolean> {
        const uri = this.buildUri(
            "/is-slack-workspace-connected",
            null,
            null
        );

        const data: boolean = await this.httpClient.get<boolean>(uri);
        return data;
    }

    public async getSlackChannels(request: GetSlackChannelsRequestDto | null): Promise<GetSlackChannelsResponseDto | null> {
        const uri = this.buildUri(
            "/slack-channels",
            null,
            { ...request || undefined }
        );

        const data: GetSlackChannelsResponseDto | null = await this.httpClient.get<GetSlackChannelsResponseDto | null>(uri);
        return data;
    }
}