import Button from 'Components/button';
import ImagesCoworkers from 'Components/images-coworkers';
import StarNumber from 'Components/star-number';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

export interface ClubDetailsInfosMembersProps {
    total: number;
    favTotal: number;
    imageUrls: string[];
    viewAllOnClick: () => void;
}

const ClubDetailsInfosMembers: React.FunctionComponent<ClubDetailsInfosMembersProps> = ({
    total,
    favTotal,
    imageUrls,
    viewAllOnClick,
}) => {
    const { t } = useTranslation();

    return (
        <div className="ClubDetailsInfosMembers">
            <div className="members-title">
                {t('Clubs.club_total_members')}: <span>{total}</span>
            </div>

            <div className="members-images">
                <ImagesCoworkers
                    imageUrls={imageUrls}
                    usersCount={total}
                    maxDisplayCoworkers={10}
                />

                <div>{favTotal > 0 && <StarNumber number={favTotal} />}</div>

                <Button text={t('view_all')} type="link" width="hugged" onClick={viewAllOnClick} />
            </div>
        </div>
    );
};

export default memo(ClubDetailsInfosMembers);
