import FlexyLogo from 'Assets/Images/logo-color.svg';
import Button from 'Components/button';
import Icon from 'Components/icons/Icon';
import WhiteCard from 'Components/white-card';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './confirm-account-callback.less';

/**
 * ConfirmAccountCallback
 * Azure AD will redirect here once the "confirm account" flow is finished
 */
const ConfirmAccountCallback: React.FunctionComponent = () => {
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const [errorString, setErrorString] = useState<string | undefined>(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        const confirmAccountCallback = () => {
            // Check if success or error.
            const error = urlParams.get('error');
            const errorDescription = urlParams.get('error_description');

            if (error) {
                // Errors: invalid token, expired token, other unknown problem with Azure...

                if (errorDescription?.startsWith('E003007')) {
                    setErrorString(t('E003007'));
                    // Ex: "E003007: This user account is already confirmed.\r\nCorrelation ID: 37392ab1-04f4-4dcf-b5ad-d46606f22daa\r\nTimestamp: 2022-04-26 16:51:28Z\r\n"
                } else if (errorDescription?.startsWith('E003008')) {
                    setErrorString(t('E003008'));
                    // Ex: "E003008: The account confirmation link is invalid.\r\nCorrelation ID: 37392ab1-04f4-4dcf-b5ad-d46606f22daa\r\nTimestamp: 2022-04-26 16:51:28Z\r\n"
                } else if (errorDescription?.startsWith('E003009')) {
                    setErrorString(t('E003009'));
                    // Ex: "E003009: The account confirmation link is expired.\r\nCorrelation ID: 37392ab1-04f4-4dcf-b5ad-d46606f22daa\r\nTimestamp: 2022-04-26 16:51:28Z\r\n"
                } else if (errorDescription?.startsWith('AADB2C90091')) {
                    setErrorString(t('AADB2C90091'));
                }

                // Other error, show error page?
                else {
                    setErrorString(t('generic_error'));
                }
            }
        };

        confirmAccountCallback();
    }, []);

    return (
        <div className="ConfirmAccountCallback">
            <svg
                className="svg-bottom-left"
                width="932"
                height="488"
                viewBox="0 0 932 488"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_2022_15688)">
                    <path
                        opacity="0.4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M622.922 768.884L563.26 663.432C503.598 557.981 384.274 347.078 230.638 226.732C77.0021 106.386 -110.945 76.5973 -204.919 61.7027L-298.892 46.8082L-443 427.147L-355.889 460.153C-268.777 493.159 -94.5549 559.17 79.6677 625.182C253.89 691.194 428.113 757.206 515.224 790.212L602.336 823.218L622.922 768.884Z"
                        fill="#A4CAF9"
                    />
                    <path
                        opacity="0.4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M922.696 625.493L818.301 564.001C713.907 502.508 505.117 379.523 311.892 352.119C118.666 324.714 -58.9952 392.89 -147.826 426.977L-236.657 461.065L-171.289 862.502L-79.345 847.53C12.5986 832.558 196.486 802.615 380.373 772.672C564.26 742.728 748.147 712.785 840.091 697.813L932.034 682.841L922.696 625.493Z"
                        fill="#A4CAF9"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2022_15688">
                        <rect width="932" height="488" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <svg
                className="svg-top-right"
                width="930"
                height="470"
                viewBox="0 0 930 470"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_2022_15691)">
                    <path
                        opacity="0.4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M302.007 -228.596L369.452 -127.944C436.896 -27.2926 571.785 174.011 734.059 282.428C896.333 390.846 1085.99 406.377 1180.82 414.142L1275.65 421.907L1390.67 31.7843L1301.31 5.44164C1211.96 -20.9011 1033.26 -73.5865 854.552 -126.272C675.847 -178.957 497.143 -231.643 407.79 -257.985L318.438 -284.328L302.007 -228.596Z"
                        fill="#A4CAF9"
                    />
                    <path
                        opacity="0.4"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.9014 -63.0074L122.636 -9.56262C231.371 43.8821 448.84 150.772 643.582 163.527C838.324 176.282 1010.34 94.903 1096.35 54.2134L1182.35 13.5238L1086.9 -381.84L996.345 -359.978C905.792 -338.115 724.687 -294.39 543.581 -250.664C362.476 -206.939 181.37 -163.213 90.8177 -141.351L0.264947 -119.488L13.9014 -63.0074Z"
                        fill="#A4CAF9"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2022_15691">
                        <rect width="930" height="470" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <WhiteCard className={`errorString-${errorString}`}>
                {errorString ? (
                    <div>{errorString}</div>
                ) : (
                    <>
                        <img className="logo" src={FlexyLogo} alt="logo" />
                        <div className="text-title-1-bold">{t('Login.login_welcome_title')}</div>
                        <Icon iconName="CircleCheckmarkIcon" />
                        <div className="welcome-text text-callout text-mid-contrast">
                            {t('Login.login_welcome_text')}
                        </div>
                        <Button
                            text={t('Login.login_cta')}
                            type="primary"
                            onClick={() => history.push('/')}
                        />
                    </>
                )}
            </WhiteCard>
        </div>
    );
};

export default ConfirmAccountCallback;
