import Icon from 'Components/icons/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import { TimeRangeFilter } from '../../graph-card';
import LineChart from './line-chart';
import './one-row-office.less';

interface OneRowOfficeProps {
    numero: number;
    label: string;
    data: any;
    percentage: number;
    totalOfficeHours: number;
    currentTimeFilter?: TimeRangeFilter;
}

const OneRowOffice: React.FunctionComponent<OneRowOfficeProps> = ({
    numero,
    label,
    data,
    percentage,
    totalOfficeHours,
    currentTimeFilter,
}) => {
    const { t } = useTranslation();
    return (
        <div className="OneRowOffice">
            <div className="left">
                <div className="text-headline">{numero}</div>
                <div className="text-footnote">{label}</div>
            </div>

            <LineChart data={data} percentage={percentage} />

            <div className="poucentage">
                <div className="text-title-3-bold">
                    {percentage >= 0 && '+'}
                    {percentage}%
                </div>
                <div className="text-caption-3 interest-last-month">
                    {t('interest_over')}{' '}
                    <span>{t(`TimeRangeKey.TimeRangeKey_${currentTimeFilter?.key}`)}</span>
                </div>
            </div>

            <div className="total-office-hours">
                <div className="top">
                    <Icon fill={theme['layout-mid-main']} iconName="Statistics" />
                    <div className="text-title-3-bold">{totalOfficeHours}</div>
                </div>
                <div className="text-caption-3 bottom">{t('total_office_hours')}</div>
            </div>
        </div>
    );
};

export default OneRowOffice;
