import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import Content from 'Components/Content';
import LabelValueComponent from 'Components/label-value-component';
import { RichTextDisplay } from 'Components/rich-text-display/rich-text-display';
import Skeleton from 'Components/skeleton';
import WhiteCard from 'Components/white-card';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FORMAT_SHORT_MONTH_DATE_TIME } from 'Models/Constants';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getHappeningDefaultImg } from 'Utils/HappeningUtils';
import { img, ImgSize } from 'Utils/ImgUtils';
import {
    happeningDetailsSkeletonShape,
    happeningImageSkeletonShape,
} from '../../id/skeleton-shapes';
import './index.less';

interface TabHappeningDetailsProps {
    loading: boolean;
    happeningDetails?: HappeningDto;
}

const TabHappeningDetails: React.FunctionComponent<TabHappeningDetailsProps> = observer(
    ({ loading, happeningDetails }) => {
        const { languageStore } = useStores();
        const { t } = useTranslation();

        return (
            <Content className="TabHappeningDetails">
                <Skeleton
                    placeholder={happeningImageSkeletonShape}
                    isLoaded={!loading}
                    imgsSrc={[
                        happeningDetails?.imageUrl
                            ? img(happeningDetails?.imageUrl, ImgSize.xl, false)
                            : getHappeningDefaultImg(happeningDetails?.attendanceType),
                    ]}
                >
                    <div
                        className="image-container"
                        style={{
                            backgroundImage: `url(${
                                happeningDetails?.imageUrl
                                    ? img(happeningDetails?.imageUrl, ImgSize.xl, false)
                                    : getHappeningDefaultImg(happeningDetails?.attendanceType)
                            })`,
                        }}
                    />
                </Skeleton>

                <WhiteCard padding={40} className="details-container">
                    <Skeleton placeholder={happeningDetailsSkeletonShape} isLoaded={!loading}>
                        <div>
                            <div className="text-title-3-bold title">{t('details')}</div>
                            <div className="details">
                                <LabelValueComponent
                                    label={t('date_time')}
                                    value={`${moment
                                        .tz(
                                            happeningDetails?.startTime,
                                            happeningDetails?.office?.timeZone ?? ''
                                        )
                                        .format(
                                            FORMAT_SHORT_MONTH_DATE_TIME[
                                                languageStore.currentLanguage
                                            ]
                                        )} - ${moment
                                        .tz(
                                            happeningDetails?.endTime,
                                            happeningDetails?.office?.timeZone ?? ''
                                        )
                                        .format(
                                            FORMAT_SHORT_MONTH_DATE_TIME[
                                                languageStore.currentLanguage
                                            ]
                                        )}`}
                                />

                                <LabelValueComponent
                                    label={t('attendance_type')}
                                    value={t(
                                        `HappeningAttendanceTypeDto.HappeningAttendanceTypeDto_${happeningDetails?.attendanceType}`
                                    )}
                                />

                                <LabelValueComponent
                                    label={t('office')}
                                    value={happeningDetails?.office?.name}
                                />

                                {happeningDetails?.capacity && (
                                    <LabelValueComponent
                                        label={t('capacity')}
                                        value={`${happeningDetails.capacity}`}
                                    />
                                )}
                            </div>

                            {happeningDetails?.description && (
                                <>
                                    <div className="text-title-3-bold title">
                                        {t('description')}
                                    </div>

                                    <RichTextDisplay
                                        className="text-body"
                                        html={happeningDetails?.description}
                                    />
                                </>
                            )}
                        </div>
                    </Skeleton>
                </WhiteCard>
            </Content>
        );
    }
);

export default TabHappeningDetails;
