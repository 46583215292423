import Icon from 'Components/icons/Icon';
import React from 'react';
import './index.less';

interface DashboardPopularBlocProps {
    title: string;
    text: string;
    iconName: string;
}

const DashboardPopularBloc: React.FunctionComponent<DashboardPopularBlocProps> = ({
    title,
    text,
    iconName,
}) => {
    return (
        <div className="DashboardPopularBloc">
            <div className="info">
                <div className="title text-caption-1-bold">{title}</div>
                <div className="text text-body-bold capitalize">{text}</div>
            </div>

            <Icon iconName={iconName} />
        </div>
    );
};

export default DashboardPopularBloc;
