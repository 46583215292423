import React from 'react';
import './index.less';

export const sectionHeaderShapes = (
    <div className="sectionHeaderShapes">
        <div className="circle" />

        <div className="one-column">
            <div className="rect" />
            <div className="rect" />
        </div>

        <div className="rect" />
    </div>
);
