import { QUARTER_HOUR_DECIMAL_VALUES } from 'Models/Constants';
import * as yup from 'yup';

const decimalQuarterHourTest = (value: number | null | undefined): boolean => {
    //decimal value must be quarters
    if (value) {
        const floatSplit = value.toString()?.split('.');
        if (floatSplit.length > 1) {
            return QUARTER_HOUR_DECIMAL_VALUES.some((x) => x === Number.parseInt(floatSplit[1]));
        }
        return true;
    }
    return true;
};

export const CreatePolicySchema = yup.object({
    name: yup
        .string()
        .required('Errors.error_required')
        .min(2, 'Errors.error_min_2')
        .max(40, 'Errors.error_max_40'),
    workHoursPerWeekEnabled: yup.boolean(),
    workHoursPerWeek: yup.number().when('workHoursPerWeekEnabled', {
        is: true,
        then: yup
            .number()
            .required('Errors.error_required')
            .typeError('Errors.error_type_number')
            .min(0.25, 'Errors.error_policy_hour')
            .max(168, 'Errors.error_policy_hour')
            .test(
                'workHoursPerWeekEnabled',
                'Errors.error_policy_hour_float',
                decimalQuarterHourTest
            ),
    }),
    hoursAvailabilityEnabled: yup.boolean(),
    hoursAvailability: yup.number().when('hoursAvailabilityEnabled', {
        is: true,
        then: yup
            .number()
            .required('Errors.error_required')
            .typeError('Errors.error_type_number')
            .min(0.25, 'Errors.error_policy_hour')
            .max(168, 'Errors.error_policy_hour')
            .test(
                'hoursAvailabilityEnabled',
                'Errors.error_policy_hour_float',
                decimalQuarterHourTest
            ),
    }),
    numberDaysWeekEnabled: yup.boolean(),
    numberDaysWeek: yup.number().when('numberDaysWeekEnabled', {
        is: true,
        then: yup
            .number()
            .required('Errors.error_required')
            .typeError('Errors.error_type_number')
            .min(1, 'Errors.error_policy_day')
            .max(7, 'Errors.error_policy_day'),
    }),
    numberDaysOfficeEnabled: yup.boolean(),
    numberDaysOffice: yup.number().when('numberDaysOfficeEnabled', {
        is: true,
        then: yup
            .number()
            .required('Errors.error_required')
            .typeError('Errors.error_type_number')
            .min(1, 'Errors.error_policy_day')
            .max(7, 'Errors.error_policy_day'),
    }),
});
