import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';
import logo from './google-calendar.png';

const GoogleCalendarIcon: FunctionComponent<SvgProps> = () => {
    return (
        <span role="img" className="anticon">
            <img src={logo} />
        </span>
    );
};

export default GoogleCalendarIcon;
