import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import moment from 'moment';
import { createEvent, EventAttributes } from 'ics';

export const generateOutlookLinkForHappening = (happeningDto: HappeningDto): void => {
    const baseUrl = 'https://outlook.live.com/calendar/0/action/compose';
    const searchParams = new URLSearchParams();

    searchParams.append('allday', 'false');
    searchParams.append('rru', 'addevent');
    searchParams.append('subject', happeningDto.name ?? 'Flexy happening');
    searchParams.append('body', happeningDto.description ?? 'Flexy happening');
    searchParams.append('startdt', happeningDto.startTime ?? '');
    searchParams.append('enddt', happeningDto.endTime ?? '');

    window.open(`${baseUrl}?${searchParams}`, '_blank');
};

export const generateGoogleCalendarLinkForHappening = (happeningDto: HappeningDto): void => {
    const baseUrl = 'https://calendar.google.com/calendar/render';
    const searchParams = new URLSearchParams();
    const googleCalendarTimeFormat = 'YYYYMMDDTHHmmss';

    const start = moment.utc(happeningDto.startTime).format(googleCalendarTimeFormat) + 'Z';
    const end = moment.utc(happeningDto.endTime).format(googleCalendarTimeFormat) + 'Z';

    searchParams.append('action', 'TEMPLATE');
    searchParams.append('text', happeningDto.name ?? 'Flexy happening');
    searchParams.append('details', happeningDto.description ?? 'Flexy happening');
    searchParams.append('dates', `${start}/${end}`);

    window.open(`${baseUrl}?${searchParams}`, '_blank');
};

export const generateCalendarICSFileForHappening = (
    happeningDto: HappeningDto,
    userTimezone: string
) => {
    const start = moment.tz(happeningDto.startTime, userTimezone);
    const end = moment.tz(happeningDto.endTime, userTimezone);

    const eventAttributes: EventAttributes = {
        start: [
            start.year(),
            start.month() + 1 /*moment month is 0 based*/,
            start.date(),
            start.hour(),
            start.minute(),
        ],
        end: [
            end.year(),
            end.month() + 1 /*moment month is 0 based*/,
            end.date(),
            end.hour(),
            end.minute(),
        ],
        title: happeningDto.name ?? 'Flexy happening',
        description: happeningDto.description ?? 'Flexy happening',
        status: 'CONFIRMED',
    };

    const { error, value } = createEvent(eventAttributes);

    if (!error) {
        const blob = new Blob([value ?? ''], { type: 'text/calendar' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        window.URL.revokeObjectURL(url);
    }
};
