import Button, { ButtonIcons, ButtonProps } from 'Components/button';
import { FormSubmittingContextProvider } from 'Contexts/FormSubmittingContext';
import React, { FunctionComponent, HTMLProps, useContext } from 'react';

interface SubmitButtonProps extends ButtonProps {
    onClick: () => Promise<void>;
}

type SubmitButtonPropsWithBase = SubmitButtonProps & ButtonIcons & HTMLProps<HTMLButtonElement>;

/**Button wrapper specific for form submit buttons. When focus is on button pressing enter multiple times can submit form even if globalloading prevents user from clicking submit */
const SubmitButton: FunctionComponent<SubmitButtonPropsWithBase> = (props) => {
    const formSubmittingContext = useContext(FormSubmittingContextProvider);

    const handleOnClick = async () => {
        if (formSubmittingContext?.formSubmitting) return;

        formSubmittingContext?.setFormSubmitting(true);
        await props.onClick();
        formSubmittingContext?.setFormSubmitting(false);
    };

    return <Button {...props} onClick={() => handleOnClick()} />;
};

export default SubmitButton;
