import CircleToggle, { DayCircle } from 'Components/circle-toggle';
import React from 'react';
import { generateKey } from 'Utils/ArrayUtils';
import './index.less';

interface CircleToggleListProps {
    list: DayCircle[];
    onClick?: (position: number) => void;
}

const CircleToggleList: React.FunctionComponent<CircleToggleListProps> = ({ list, onClick }) => {
    const listRendered = list.map((item, i) => (
        <CircleToggle
            key={generateKey(item.id + '-' + i)}
            data={item}
            onClick={onClick ? () => onClick(i) : undefined}
        />
    ));

    return <div className="CircleToggleList">{listRendered}</div>;
};

export default CircleToggleList;
