import { Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import './timeline-tooltip.less';

interface TimelineTooltipProps {
    title?: string;
    type: TimelineTooltipType;
    lowercase?: boolean;
}

export enum TimelineTooltipType {
    work = 'work',
    unavailable = 'unavailable',
    offhour = 'offhour',
    away = 'away',
    resetDefaultSchedule = 'resetDefaultSchedule'
}

const TimelineTooltip: FunctionComponent<TimelineTooltipProps> = ({ title, type, lowercase, children }) => {
    return (
        <Tooltip
            overlayClassName={`timeline-tooltip-overlay ${type} ${lowercase ? 'lowercase' : ''}`}
            title={title}
            placement={'top'}
        >
            {children}
        </Tooltip>
    );
};

export default TimelineTooltip;
