import Button from 'Components/button';
import { observer } from 'mobx-react';
import React, { FunctionComponent, /*useCallback, useEffect,*/ useState } from 'react';
import TitledWhitecardSection from '../titled-whitecard-section';
import { useTranslation } from 'react-i18next';
import { useService, useStores } from 'Hooks';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import ChangeLangModal from 'Routes/profile/components/change-lang-modal';
import './manage-account-tab.less';
import ProfileIntegrationSection from '../profile-integration-section/profile-integration-section';
import { useHistory } from 'react-router';
import { PROFILE_URL } from 'Models/Constants';
import { ProfileTabs } from 'Routes/profile';
// import { UserService } from 'Services/UserService';
// import { GetUserExternalIdentitiesResponseDto } from 'Api/Features/Users/Dtos/GetUserExternalIdentitiesResponseDto';
// import { UserExternalIdentityIssuerDto } from 'Api/Features/Users/Dtos/UserExternalIdentityIssuerDto';
// import Skeleton from 'Components/skeleton';
// import { profileExternalIdentityShape } from 'Routes/profile/skeleton-shapes';

const ManageAccountTab: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { languageStore, globalLoadingStore, userStore /*toastStore*/ } = useStores();
    const history = useHistory();
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    // const userService = useService(UserService);

    // const [googleLinkIsLoading, setGoogleLinkIsLoading] = useState(true);
    // const [googleLinkStatus, setGoogleLinkStatus] = useState(false);
    // const [oktaAppcomLinkIsLoading, setOktaAppcomLinkIsLoading] = useState(true);
    // const [oktaAppcomLinkStatus, setOktaAppcomLinkStatus] = useState(false);

    const [changeLangModalVisible, setChangeLangModalVisible] = useState(false);

    const changePassword = async (): Promise<void> => {
        if (userStore.userInfo?.contactInfo?.email) {
            await azureAdAuthenticationService.runChangePasswordFlow(
                languageStore.currentLanguage,
                userStore.userInfo.contactInfo.email
            );
        }
    };

    // const linkAccount = async (issuer: UserExternalIdentityIssuerDto): Promise<void> => {
    //     if (userStore.userInfo?.contactInfo?.email) {
    //         await azureAdAuthenticationService.runLinkAccountFlow(
    //             languageStore.currentLanguage,
    //             userStore.userInfo.contactInfo.email,
    //             issuer
    //         );
    //     }
    // };

    // const fetchExternalIdentities = useCallback(async () => {
    //     try {
    //         setGoogleLinkIsLoading(true);
    //         setOktaAppcomLinkIsLoading(true);
    //         const response: GetUserExternalIdentitiesResponseDto | null =
    //             await userService.getUserExternalIdentities(userStore?.userInfo?.id || '');

    //         if (response) {
    //             setGoogleLinkStatus(
    //                 response.items?.find(
    //                     (identity) => identity?.issuer === UserExternalIdentityIssuerDto.Google
    //                 )?.issuerAssignedId !== undefined
    //             );
    //             setOktaAppcomLinkStatus(
    //                 response.items?.find(
    //                     (identity) => identity?.issuer === UserExternalIdentityIssuerDto.OktaAppcom
    //                 )?.issuerAssignedId !== undefined
    //             );
    //         }
    //     } catch (err: any) {
    //         if (!err.treated) toastStore.genericError();
    //     } finally {
    //         setGoogleLinkIsLoading(false);
    //         setOktaAppcomLinkIsLoading(false);
    //     }
    // }, [userStore?.userInfo?.id]);

    // useEffect(() => {
    //     if (userStore?.userInfo?.id) {
    //         fetchExternalIdentities();
    //     }
    // }, [userStore?.userInfo?.id]);

    // const handleLinkUnlinkGoogle = async () => {
    //     try {
    //         setGoogleLinkIsLoading(true);
    //         if (googleLinkStatus) {
    //             await userService.removeUserExternalIdentity(userStore.userInfo?.id ?? '', {
    //                 issuer: UserExternalIdentityIssuerDto.Google,
    //             });
    //             setGoogleLinkStatus(false);
    //         } else {
    //             await linkAccount(UserExternalIdentityIssuerDto.Google);
    //         }
    //     } catch (err: any) {
    //         if (!err.treated) toastStore.genericError();
    //     } finally {
    //         setGoogleLinkIsLoading(false);
    //     }
    // };

    // const handleLinkUnlinkOktaAppcom = async () => {
    //     try {
    //         setOktaAppcomLinkIsLoading(true);
    //         if (oktaAppcomLinkStatus) {
    //             await userService.removeUserExternalIdentity(userStore.userInfo?.id ?? '', {
    //                 issuer: UserExternalIdentityIssuerDto.OktaAppcom,
    //             });
    //             setOktaAppcomLinkStatus(false);
    //         } else {
    //             await linkAccount(UserExternalIdentityIssuerDto.OktaAppcom);
    //         }
    //     } catch (err: any) {
    //         if (!err.treated) toastStore.genericError();
    //     } finally {
    //         setOktaAppcomLinkIsLoading(false);
    //     }
    // };

    return (
        <div className="ManageAccountTab">
            <TitledWhitecardSection title={t('manage_account')} padding={40}>
                <div className="manage-container">
                    <div>
                        <div className="text-callout-bold mb-5">
                            {t('Profile.password_subtitle')}
                        </div>
                        <div className="text-caption-1 text-mid-contrast">
                            {t('Profile.change_password_sentence')}
                        </div>
                    </div>
                    <Button
                        text={t('change')}
                        type="tertiary"
                        width="hugged"
                        leftIcon="PencilIcon"
                        onClick={changePassword}
                    />
                </div>

                <div className="manage-container">
                    <div>
                        <div className="text-callout-bold mb-5">
                            {t('SupportedLanguage.language_preferences')}
                        </div>
                        <div className="text-caption-1 text-mid-contrast">
                            {t('SupportedLanguage.language_preferences_exp')}
                        </div>
                    </div>
                    <Button
                        text={t('change')}
                        type="tertiary"
                        width="hugged"
                        leftIcon="PencilIcon"
                        onClick={() => setChangeLangModalVisible(true)}
                    />
                </div>

                {/* <div className="manage-container">
                    <div>
                        <div className="text-callout-bold mb-5">{'Google'}</div>
                        <div className="text-caption-1 text-mid-contrast">
                            {'Link an external account that can be used to sign in to Flexy.'}
                        </div>
                    </div>
                    <Skeleton
                        placeholder={profileExternalIdentityShape}
                        isLoaded={!googleLinkIsLoading}
                    >
                        <Button
                            text={googleLinkStatus ? t('unlink') : t('link')}
                            type="tertiary"
                            width="hugged"
                            leftIcon="PencilIcon"
                            onClick={handleLinkUnlinkGoogle}
                        />
                    </Skeleton>
                </div>

                <div className="manage-container">
                    <div>
                        <div className="text-callout-bold mb-5">{'Okta Appcom'}</div>
                        <div className="text-caption-1 text-mid-contrast">
                            {'Link an external account that can be used to sign in to Flexy.'}
                        </div>
                    </div>
                    <Skeleton
                        placeholder={profileExternalIdentityShape}
                        isLoaded={!oktaAppcomLinkIsLoading}
                    >
                        <Button
                            text={oktaAppcomLinkStatus ? t('unlink') : t('link')}
                            type="tertiary"
                            width="hugged"
                            leftIcon="PencilIcon"
                            onClick={handleLinkUnlinkOktaAppcom}
                        />
                    </Skeleton>
                </div> */}
            </TitledWhitecardSection>

            <ProfileIntegrationSection />

            {changeLangModalVisible && (
                <ChangeLangModal
                    visible={changeLangModalVisible}
                    onComplete={(success?: boolean) => {
                        setChangeLangModalVisible(false);
                        if (success) {
                            globalLoadingStore.addLoading();
                            //reload must be performed because google places script needs to be refetch on current language. Setting active tab in url to come back here after reload
                            history.push(PROFILE_URL + `?activeTab=${ProfileTabs.ManageAccount}`);
                            window.location.reload();
                        }
                    }}
                />
            )}
        </div>
    );
});

export default ManageAccountTab;
