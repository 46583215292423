import { Col, FormInstance, Input, Row, Switch } from 'antd';
import { GetOfficeSpaceOccupancyRequestPeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestPeriodDto';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';
import Divider from 'Components/divider';
import { ClockIcon, SpaceViewerIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import { singleSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import Skeleton from 'Components/skeleton';
import TimePickerDropdown from 'Components/time-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import CustomRadioButton from 'Components/weekly-hour-pref-calendar/components/custom-radio-button';
import DayReplicator, {
    DayReplicatorStateEnum,
} from 'Components/weekly-hour-pref-calendar/components/day-replicator';
import { useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FORM_GUTTER } from 'Models/Constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfficeService } from 'Services/OfficeService';
import { theme } from 'Style/theme';
import { getAllDatesOfThisWeek, validateCanFetchOccupancy } from 'Utils/CalendarUtils';
import { TimePeriodModalDayReplicatorSkeletonShapes } from '../skeleton-shapes';
import { mergeStrings } from 'Utils/TextUtils';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';

export interface SpaceWithCapacityExtended extends OfficeSpaceDto {
    currentCapacity?: { occupancy: number; capacity: number };
    isAvailableForUser?: boolean;
}

interface WorkTabProps {
    errors: Map<string, string[]>;
    offHourIsChecked?: boolean;
    handleOffHourChange: () => void;
    startDayOptions: SingleSelectCustomOption[];
    selectedStartDayWritten?: string;
    onStartDayChange: (writtenDay: string) => void;
    onStartTimeChange: (value: string) => void;
    startTime?: string;
    selectedStartDate?: moment.Moment;
    endTime?: string;
    endDayOptions: SingleSelectCustomOption[];
    selectedEndDayWritten?: string;
    onEndDayChange: (writtenDay: string) => void;
    onEndTimeChange: (value: string) => void;
    selectedEndDate?: moment.Moment;
    getMinimumEndTimeAvailable: () => string;
    getMaximumEndTimeAvailable: () => string | undefined;
    selectedWorkType: UserPreferredWorkTypeDto;
    onWorkTypeChange: (type: UserPreferredWorkTypeDto) => void;
    selectedOffice?: SingleSelectCustomOption;
    onOfficeChange: (option?: SingleSelectCustomOption) => void;
    selectedSpace?: SingleSelectCustomOption;
    onSpaceChange: (option?: SingleSelectCustomOption) => void;
    replicateIsChecked: boolean;
    onReplicateChange: () => void;
    replicateDaysState: DayReplicatorStateEnum[];
    onReplicateDaysStateChange: (dayIndex: number, customState?: DayReplicatorStateEnum) => void;
    setSpaceViewerVisible: (value: boolean) => void;
    form: FormInstance<any>;
    setErrors: (errors: Map<string, string[]>) => void;
    resetErrors: () => void;
    setCapacityBlocksSubmit: (value: boolean) => void;
}

const WorkTab: React.FunctionComponent<WorkTabProps> = observer(
    ({
        errors,
        offHourIsChecked,
        handleOffHourChange,
        startDayOptions,
        selectedStartDayWritten,
        onStartDayChange,
        onStartTimeChange,
        startTime,
        selectedStartDate,
        endTime,
        endDayOptions,
        selectedEndDayWritten,
        onEndDayChange,
        onEndTimeChange,
        selectedEndDate,
        getMinimumEndTimeAvailable,
        getMaximumEndTimeAvailable,
        selectedWorkType,
        onWorkTypeChange,
        selectedOffice,
        onOfficeChange,
        selectedSpace,
        onSpaceChange,
        replicateIsChecked,
        onReplicateChange,
        replicateDaysState,
        onReplicateDaysStateChange,
        setSpaceViewerVisible,
        form,
        setErrors,
        resetErrors,
        setCapacityBlocksSubmit,
    }) => {
        const officeService = useService(OfficeService);
        const { policyStore, userStore, toastStore } = useStores();
        const { t } = useTranslation();

        const [spacesWithCapacity, setSpacesWithCapacity] = useState<SpaceWithCapacityExtended[]>(
            []
        );
        const [spacesWithCapacityOptions, setSpacesWithCapacityOptions] = useState<
            SingleSelectCustomOption[]
        >([]);
        const [spaceViewerBtnEnabled, setSpaceViewerBtnEnabled] = useState(false);
        const [isLoaded, setIsLoaded] = useState({
            checkReplicatePeriodIsAvailable: false,
        });

        const [defaultOfficeIsDisabled, setDefaultOfficeIsDisabled] = useState(true); //disabled by default in case slow network and trying to click faster
        const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficesRequestDto) =>
                    await officeService.getOffices(request),
            },
            entityToSingleSelectCustomOption: (office: OfficeDto) =>
                ({ value: office.id, label: office.name } as SingleSelectCustomOption),
            defaultSelectedOption: selectedOffice,
            extraMandatoryOptions: userStore.userInfo?.defaultOffice?.id
                ? {
                      unshift: [
                          {
                              value: t('OfficeOccupancyCard.use_default_office'),
                              label: t('OfficeOccupancyCard.use_default_office'),
                              content: {
                                  node: `${mergeStrings(
                                      [
                                          userStore.userInfo.defaultOfficeSpace?.name,
                                          userStore.userInfo.defaultFloor,
                                          userStore.userInfo.defaultDesk,
                                      ],
                                      ' - '
                                  )}`,
                              },
                              isDisabled: defaultOfficeIsDisabled,
                          },
                      ],
                  }
                : undefined,
        });

        const { asyncSingleSelectProps: spaceSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficeSpacesRequestDto) =>
                    await officeService.getOfficeSpaces(request),
                fetchFunctionExtraParams: {
                    officeIds:
                        selectedOffice?.value === t('OfficeOccupancyCard.use_default_office')
                            ? userStore.userInfo?.defaultOffice?.id
                            : selectedOffice?.value,
                },
            },
            entityToSingleSelectCustomOption: (space: OfficeSpaceDto) =>
                ({
                    value: space.id,
                    label: space.name,
                } as SingleSelectCustomOption),
        });

        useEffect(() => {
            //when fetch from space select is done, fetch the capacity for those spaces
            if (spaceSelectProps.options) {
                fetchDropdownSpaceCapacities(
                    spaceSelectProps.options.map(
                        (option) =>
                            ({
                                id: option.value,
                                name: option.label,
                            } as OfficeSpaceDto)
                    ),
                    selectedStartDate,
                    startTime,
                    selectedEndDate,
                    endTime
                );
            }
        }, [spaceSelectProps.options]);

        const fetchDropdownSpaceCapacities = async (
            spaces: OfficeSpaceDto[],
            selectedStartDate: moment.Moment | undefined,
            selectedStartTime: string | undefined,
            selectedEndDate: moment.Moment | undefined,
            selectedEndTime: string | undefined
        ): Promise<void> => {
            //without these informations, we cannot get space capacities. We must still go through with creating options for the space dropdown
            if (!selectedEndDate || !selectedStartDate || !selectedStartTime || !selectedEndTime) {
                setSpacesWithCapacity(spaces);
                return;
            }

            const getOfficeSpaceOccupancyRequest: GetOfficeSpaceOccupancyRequestPeriodDto[] =
                officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                    spaces.map((space) => ({
                        spaceId: space.id!,
                        startTime: selectedStartTime!,
                        endTime: selectedEndTime!,
                        momentSelectedStartDate: moment(selectedStartDate),
                        momentSelectedEndDate: moment(selectedEndDate),
                    })),
                    userStore.userInfo?.timeZone ?? ''
                );

            ('fetchDropdownSpaceCapacities');
            const getOfficeSpaceOccupancyResponse = await getOfficeSpaceOccupancies(
                getOfficeSpaceOccupancyRequest
            );

            if (getOfficeSpaceOccupancyResponse) {
                const newWorkplacesWithCapacity = spaces.map((space) => {
                    if (space.id) {
                        const period = getOfficeSpaceOccupancyResponse.find(
                            (period) => period.officeSpace?.id === space?.id
                        );

                        return {
                            ...space,
                            currentCapacity: {
                                occupancy: period?.occupancy ?? 0,
                                capacity: period?.officeSpace?.capacity ?? 0,
                            },
                            isAvailableForUser: period?.isAvailableForUser,
                        };
                    } else {
                        return { ...space };
                    }
                });

                setSpacesWithCapacity([...newWorkplacesWithCapacity]);
            }
        };

        useEffect(() => {
            //once the capacities for the spaces have been fetched make new options for the space select
            const searchResults = spacesWithCapacity.map((space) => {
                return {
                    label: space.name,
                    value: space.id,
                    content: space.currentCapacity
                        ? {
                              node: (
                                  <div>
                                      {t('TimePeriodModal.occupancy_period')}:{' '}
                                      {space.currentCapacity?.occupancy}/
                                      {space.currentCapacity?.capacity}
                                  </div>
                              ),
                              className: 'text-caption-1',
                              showSelected: true,
                          }
                        : undefined,
                    isDisabled: !space.isAvailableForUser,
                } as SingleSelectCustomOption;
            });
            const merged = singleSelectMergeSelectedOptionsWithSearchResults(searchResults, [
                selectedSpace,
            ]);
            setSpacesWithCapacityOptions(merged);
        }, [spacesWithCapacity, selectedSpace]);

        const getOfficeSpaceOccupancies = async (
            periods: GetOfficeSpaceOccupancyRequestPeriodDto[]
        ): Promise<GetOfficeSpaceOccupancyResponsePeriodDto[] | null> => {
            try {
                const getOfficeSpaceOccupancyResponse = await officeService.getOfficeSpaceOccupancy(
                    {
                        periods: periods,
                        timeZone: userStore?.userInfo?.timeZone,
                    }
                );

                return getOfficeSpaceOccupancyResponse;
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
                return null;
            }
        };

        useEffect(() => {
            setSpaceViewerBtnEnabled(
                selectedStartDate !== undefined &&
                    selectedEndDate !== undefined &&
                    startTime !== undefined &&
                    endTime !== undefined
            );
        }, [selectedStartDate, selectedEndDate, startTime, endTime]);

        const handleUseDefaultOffice = () => {
            onOfficeChange({
                value: userStore.userInfo?.defaultOffice?.id ?? '',
                label: userStore.userInfo?.defaultOffice?.name ?? '',
            });
            if (userStore.userInfo?.defaultOfficeSpace?.id) {
                onSpaceChange({
                    value: userStore.userInfo.defaultOfficeSpace.id,
                    label: userStore.userInfo?.defaultOfficeSpace.name ?? '',
                });
            } else {
                spaceSelectProps.onChange(undefined);
            }
            resetErrors();
            setCapacityBlocksSubmit(false);
            form.setFieldsValue({
                desk: userStore.userInfo?.defaultDesk,
                floor: userStore.userInfo?.defaultFloor,
            });
        };

        const updateReplicatorAvailability = async (
            selectedSpace: SingleSelectCustomOption | undefined,
            selectedStartDate: moment.Moment,
            selectedStartTime: string,
            selectedEndDate: moment.Moment,
            selectedEndTime: string
        ) => {
            if (selectedSpace === undefined) {
                setIsLoaded((prev) => ({ ...prev, checkReplicatePeriodIsAvailable: true }));
                return;
            }
            setIsLoaded((prev) => ({ ...prev, checkReplicatePeriodIsAvailable: false }));

            const endDateIsSameAsStart = selectedStartDate.isSame(selectedEndDate, 'day');
            const allDates = getAllDatesOfThisWeek(selectedStartDate);
            const draftPeriods = allDates.map((date) => {
                return {
                    spaceId: selectedSpace?.value,
                    startTime: selectedStartTime,
                    endTime: selectedEndTime,
                    momentSelectedStartDate: date,
                    momentSelectedEndDate: endDateIsSameAsStart ? date : date.clone().add(1, 'day'),
                };
            });

            const periods = officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                draftPeriods,
                userStore.userInfo?.timeZone ?? ''
            );

            const spaceOccupancyResponse: GetOfficeSpaceOccupancyResponsePeriodDto[] | null =
                await getOfficeSpaceOccupancies(periods);

            if (spaceOccupancyResponse) {
                spaceOccupancyResponse.forEach((period, index) => {
                    const isNotAvailable = !period.isAvailableForUser;
                    const isMandatoryDate =
                        moment.tz(period.startTime, userStore.userInfo?.timeZone ?? '').day() ===
                        selectedStartDate.day();
                    if (isNotAvailable) {
                        onReplicateDaysStateChange(
                            index,
                            DayReplicatorStateEnum.Disabled_full_capacity
                        );
                    } else if (isMandatoryDate) {
                        onReplicateDaysStateChange(index, DayReplicatorStateEnum.Mandatory);
                    } else {
                        onReplicateDaysStateChange(index, DayReplicatorStateEnum.Normal);
                    }
                });
            }
            setIsLoaded((prev) => ({ ...prev, checkReplicatePeriodIsAvailable: true }));
        };

        const handleSelectedSpaceAvailabilityOnDateChanges = async (
            selectedSpace: SingleSelectCustomOption | undefined,
            selectedStartDate: moment.Moment,
            selectedStartTime: string,
            selectedEndDate: moment.Moment,
            selectedEndTime: string
        ) => {
            if (!selectedSpace) return;

            const getOfficeSpaceOccupancyRequest: GetOfficeSpaceOccupancyRequestPeriodDto[] =
                officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                    [
                        {
                            spaceId: selectedSpace.value,
                            startTime: selectedStartTime,
                            endTime: selectedEndTime,
                            momentSelectedStartDate: moment(selectedStartDate),
                            momentSelectedEndDate: moment(selectedEndDate),
                        },
                    ],
                    userStore.userInfo?.timeZone ?? ''
                );

            const getOfficeSpaceOccupancyResponse = await getOfficeSpaceOccupancies(
                getOfficeSpaceOccupancyRequest
            );
            const isFull = !getOfficeSpaceOccupancyResponse?.[0].isAvailableForUser;
            if (isFull) {
                setErrors(new Map([['space', [t('space_capacity_full')]]]));
                setCapacityBlocksSubmit(true);
            } else {
                resetErrors();
                setCapacityBlocksSubmit(false);
            }
        };

        useEffect(() => {
            if (
                validateCanFetchOccupancy(
                    selectedStartDate,
                    startTime,
                    selectedEndDate,
                    endTime,
                    userStore.userInfo?.timeZone
                )
            ) {
                handleSelectedSpaceAvailabilityOnDateChanges(
                    selectedSpace,
                    selectedStartDate!,
                    startTime!,
                    selectedEndDate!,
                    endTime!
                );
                updateReplicatorAvailability(
                    selectedSpace,
                    selectedStartDate!,
                    startTime!,
                    selectedEndDate!,
                    endTime!
                );
            }
        }, [selectedSpace, selectedEndDate, selectedStartDate, startTime, endTime]);

        const getDefaultOfficeDisabledState = async (
            selectedStartDate: moment.Moment,
            selectedStartTime: string,
            selectedEndDate: moment.Moment,
            selectedEndTime: string
        ): Promise<void> => {
            if (userStore.userInfo?.defaultOfficeSpace?.id) {
                const getOfficeSpaceOccupancyRequest: GetOfficeSpaceOccupancyRequestPeriodDto[] =
                    officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                        [
                            {
                                spaceId: userStore.userInfo?.defaultOfficeSpace?.id,
                                startTime: selectedStartTime,
                                endTime: selectedEndTime,
                                momentSelectedStartDate: moment(selectedStartDate),
                                momentSelectedEndDate: moment(selectedEndDate),
                            },
                        ],
                        userStore.userInfo?.timeZone ?? ''
                    );

                const getOfficeSpaceOccupancyResponse = await getOfficeSpaceOccupancies(
                    getOfficeSpaceOccupancyRequest
                );
                setDefaultOfficeIsDisabled(
                    !getOfficeSpaceOccupancyResponse?.[0]?.isAvailableForUser ?? false
                );
            } else {
                setDefaultOfficeIsDisabled(false);
            }
        };

        useEffect(() => {
            if (
                validateCanFetchOccupancy(
                    selectedStartDate,
                    startTime,
                    selectedEndDate,
                    endTime,
                    userStore.userInfo?.timeZone
                )
            ) {
                getDefaultOfficeDisabledState(
                    selectedStartDate!,
                    startTime!,
                    selectedEndDate!,
                    endTime!
                );
            }
        }, [
            userStore.userInfo?.defaultOfficeSpace,
            selectedStartDate,
            selectedEndDate,
            startTime,
            endTime,
        ]);

        return (
            <div className="WorkTab">
                <div className="px-20">
                    <div className="off-hour-container px-20">
                        <span
                            className="text-body text cursor-pointer"
                            onClick={() => handleOffHourChange()}
                        >
                            {t('PrefCalendar.off_hour_availability')}
                        </span>
                        <Switch
                            onChange={() => handleOffHourChange()}
                            checked={offHourIsChecked}
                            size="small"
                        />
                    </div>
                </div>

                <Divider marginTop={20} marginBottom={40} />

                <div className="px-20">
                    <div className="date-time-container">
                        <div className="section-title-container">
                            <div className="blue-icon-container">
                                <ClockIcon
                                    width={16}
                                    height={16}
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>

                            <span className="title">{t('date_time')}</span>
                        </div>
                        <div className="controls-container pl-40">
                            <div className="control">
                                <ValidatedFormItem
                                    label={t('from')}
                                    errors={errors}
                                    name="startDate"
                                >
                                    <StaticSingleSelect
                                        className="day"
                                        options={startDayOptions}
                                        selected={selectedStartDayWritten}
                                        onChange={(option?: SingleSelectCustomOption) => {
                                            onStartDayChange(option?.value ?? '');
                                            onEndDayChange(option?.value ?? '');
                                        }}
                                        dropdownIndicatorIcon={<div></div>}
                                        hideSelectedOptions={false}
                                        placeholder={t('SelectCustom.default_select_placeholder')}
                                    />
                                </ValidatedFormItem>

                                <ValidatedFormItem label="" errors={errors} name="startTime">
                                    <TimePickerDropdown
                                        onChange={onStartTimeChange}
                                        selected={startTime}
                                        restrictions={
                                            policyStore.policyInfo?.restrictions ?? undefined
                                        }
                                        forSpecificDate={selectedStartDate?.format()}
                                    />
                                </ValidatedFormItem>
                            </div>

                            <div className="control">
                                <ValidatedFormItem label={t('to')} name="EndDate">
                                    <StaticSingleSelect
                                        className="day"
                                        options={endDayOptions}
                                        selected={selectedEndDayWritten}
                                        onChange={(option?: SingleSelectCustomOption) =>
                                            onEndDayChange(option?.value ?? '')
                                        }
                                        dropdownIndicatorIcon={<div></div>}
                                        hideSelectedOptions={false}
                                        placeholder={t('SelectCustom.default_select_placeholder')}
                                    />
                                </ValidatedFormItem>

                                <ValidatedFormItem label="" errors={errors} name="endTime">
                                    <TimePickerDropdown
                                        onChange={(value: string) => {
                                            onEndTimeChange(value);
                                        }}
                                        selected={endTime}
                                        minimumAvailableTime={getMinimumEndTimeAvailable()}
                                        maximumAvailableTime={getMaximumEndTimeAvailable()}
                                        restrictions={
                                            policyStore.policyInfo?.restrictions ?? undefined
                                        }
                                        forSpecificDate={selectedEndDate?.format()}
                                        endTimeMidnightRestrictionOverride
                                    />
                                </ValidatedFormItem>
                            </div>
                        </div>
                    </div>
                </div>

                <Divider marginTop={16} marginBottom={40} />

                <div className="px-20">
                    <div className="section-title-container">
                        <div className="blue-icon-container">
                            <Icon
                                width={16}
                                height={16}
                                iconName="MapPinWithCircleIcon"
                                fill={theme['primary-mid-contrast']}
                            />
                        </div>
                        <span className="title">{t('workplace')}</span>
                    </div>

                    <div className="pl-40">
                        <div className="work-type-container">
                            <CustomRadioButton
                                label={t('WorkTypeDto.WorkTypeDto_Office')}
                                onSelect={(value: string) => {
                                    onWorkTypeChange(value as UserPreferredWorkTypeDto);
                                }}
                                selected={selectedWorkType === UserPreferredWorkTypeDto.Office}
                                value={WorkTypeDto.Office}
                            />
                            <CustomRadioButton
                                label={t('WorkTypeDto.WorkTypeDto_Remote')}
                                onSelect={(value: string) => {
                                    onWorkTypeChange(value as UserPreferredWorkTypeDto);
                                    onOfficeChange(undefined);
                                }}
                                selected={selectedWorkType === UserPreferredWorkTypeDto.Remote}
                                value={WorkTypeDto.Remote}
                            />
                        </div>

                        {selectedWorkType === UserPreferredWorkTypeDto.Office && (
                            <>
                                <Row gutter={FORM_GUTTER}>
                                    <Col span={24}>
                                        <ValidatedFormItem
                                            label={t('choose_an_office') + ' ' + t('optional')}
                                            errors={errors}
                                            name="office"
                                        >
                                            <AsyncSingleSelect
                                                {...officeSelectProps}
                                                selected={selectedOffice?.value}
                                                onChange={(option?: SingleSelectCustomOption) => {
                                                    spaceSelectProps.resetSearchResults();
                                                    if (
                                                        option?.value ===
                                                        t('OfficeOccupancyCard.use_default_office')
                                                    ) {
                                                        handleUseDefaultOffice();
                                                    } else {
                                                        onOfficeChange(option);
                                                        spaceSelectProps.onChange(undefined);
                                                        resetErrors();
                                                        setCapacityBlocksSubmit(false);
                                                    }
                                                }}
                                                placeholder={t(
                                                    'SelectCustom.default_select_placeholder'
                                                )}
                                                isClearable
                                                disabled={
                                                    endTime === undefined ||
                                                    startTime === undefined ||
                                                    selectedStartDate === undefined ||
                                                    selectedEndDate === undefined
                                                }
                                            />
                                        </ValidatedFormItem>
                                    </Col>
                                </Row>

                                {selectedOffice && (
                                    <Row gutter={FORM_GUTTER}>
                                        <Col span={24} className="space-column">
                                            <ValidatedFormItem
                                                label={t('choose_a_space') + ' ' + t('optional')}
                                                errors={errors}
                                                name="space"
                                            >
                                                <div className="dropdown-space-viewer">
                                                    <AsyncSingleSelect
                                                        {...spaceSelectProps}
                                                        options={spacesWithCapacityOptions}
                                                        selected={selectedSpace?.value}
                                                        onChange={(
                                                            option?: SingleSelectCustomOption
                                                        ) => {
                                                            onSpaceChange(option);
                                                            setCapacityBlocksSubmit(false);
                                                            resetErrors();
                                                        }}
                                                        isClearable
                                                        disabled={
                                                            endTime === undefined ||
                                                            startTime === undefined ||
                                                            selectedStartDate === undefined ||
                                                            selectedEndDate === undefined
                                                        }
                                                    />
                                                    <div
                                                        className={`space-viewer-btn ${
                                                            spaceViewerBtnEnabled
                                                                ? 'enabled'
                                                                : 'disabled'
                                                        }`}
                                                        onClick={
                                                            spaceViewerBtnEnabled
                                                                ? () => setSpaceViewerVisible(true)
                                                                : undefined
                                                        }
                                                    >
                                                        <SpaceViewerIcon
                                                            width={24}
                                                            height={24}
                                                            fill={theme['accent-mid-contrast']}
                                                        />
                                                    </div>
                                                </div>
                                            </ValidatedFormItem>
                                        </Col>
                                    </Row>
                                )}

                                {selectedOffice && (
                                    <>
                                        <Row gutter={FORM_GUTTER}>
                                            <Col span={12}>
                                                <ValidatedFormItem
                                                    label={t('floor') + ' ' + t('optional')}
                                                    errors={errors}
                                                    name="floor"
                                                >
                                                    <Input />
                                                </ValidatedFormItem>
                                            </Col>

                                            <Col span={12}>
                                                <ValidatedFormItem
                                                    label={
                                                        t('Office.desk_number') +
                                                        ' ' +
                                                        t('optional')
                                                    }
                                                    errors={errors}
                                                    name="desk"
                                                >
                                                    <Input />
                                                </ValidatedFormItem>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>

                <Divider marginBottom={40} marginTop={40} />

                <div className="px-20">
                    <div className="section-title-container">
                        <div className="blue-icon-container">
                            <Icon
                                width={13}
                                height={13}
                                iconName="DuplicateIcon"
                                fill={theme['primary-mid-contrast']}
                            />
                        </div>
                        <span
                            className={`title replicate cursor-pointer ${
                                selectedStartDate && startTime && endTime ? '' : 'disabled'
                            }`}
                            onClick={() => {
                                if (selectedStartDate && startTime && endTime) onReplicateChange();
                            }}
                        >
                            {t('replicate_period')}
                        </span>
                        <Switch
                            size="small"
                            checked={replicateIsChecked}
                            disabled={
                                selectedStartDate === undefined ||
                                startTime === undefined ||
                                startTime === '' ||
                                endTime === undefined ||
                                endTime === ''
                            }
                            onChange={() => {
                                if (selectedStartDate && startTime && endTime) onReplicateChange();
                            }}
                        />
                    </div>

                    {replicateIsChecked && replicateDaysState && (
                        <div className="replicate-container pl-40">
                            <Divider marginBottom={20} marginTop={20} />
                            <div className="text-caption-1 mb-10">{t('replicate_on')}</div>

                            <Skeleton
                                placeholder={TimePeriodModalDayReplicatorSkeletonShapes}
                                isLoaded={isLoaded.checkReplicatePeriodIsAvailable}
                            >
                                <DayReplicator
                                    onDayChange={(dayIndex: number) => {
                                        onReplicateDaysStateChange(dayIndex);
                                    }}
                                    dayStates={replicateDaysState}
                                />
                            </Skeleton>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default WorkTab;
