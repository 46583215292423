import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { GetDaySchedulesResponseItemDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesResponseItemDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import HourTimeline from 'Components/hour-timeline';
import { CalendarPlusIcon, ChevronDownIcon } from 'Components/icons';
import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import Tag from 'Components/tag/tag';
import { FavToggle } from 'Components/toggle';
import TooltipWrapper from 'Components/tooltip-wrapper';
import WorkTypeStatus from 'Components/worktype-status';
import { Fetches } from 'Contexts/TriggerFetchContext';
import { useStores, useWindowDimensions } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { MOBILE_MAX, FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE } from 'Models/Constants';
import moment from 'moment';
import { default as React, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { theme } from 'Style/theme';
import { img, ImgSize } from 'Utils/ImgUtils';
import { getEventTypeTagColor } from 'Utils/WeeklyHoursPreferenceUtils';
import './coworker-working-line.less';
import Divider from 'Components/divider';

interface CoworkerWorkingLineProps {
    coworker: GetDaySchedulesResponseItemDto;
    date: string;
    onChangeFavorites: (state: 'add' | 'remove', coworkerId: string, relationId: string) => void;
    onCopyEventClick: (event: ScheduleEventDto) => void;
    isMobile?: boolean;
}

//This component replicates ANTD Collape and Collapse.Panel to be able to use ScrollInfinite. Copied and modified from deprecated component coworker-schedule-collapse.tsx
const CoworkerWorkingLine: React.FunctionComponent<CoworkerWorkingLineProps> = React.memo(
    observer(({ coworker, date, onChangeFavorites, onCopyEventClick, isMobile = false }) => {
        const { userStore, languageStore } = useStores();
        const { t } = useTranslation();
        const { windowWidth } = useWindowDimensions();

        const [events, setEvents] = useState<ScheduleEventDto[]>([]);

        const [isOpen, setIsOpen] = useState(false);
        const [favToggleHasTooltip, setFavToggleHasTooltip] = useState(true);

        useEffect(() => {
            if (coworker.events) {
                setEvents(
                    coworker.events?.filter((event) => event !== null).map((event) => event!)
                );
            }
        }, [coworker.events]);

        const getWorkToday = ({ events, coworker }) => {
            const start = events ? events.startTime : coworker.day?.workStartTime;
            const end = events ? events.endTime : coworker.day?.workEndTime;

            const formatMoment = (time: any): string =>
                moment(time)
                    .tz(userStore.userInfo?.timeZone || '')
                    .format(
                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
                    );
            return (
                <div>
                    {formatMoment(start)} - {formatMoment(end)}
                </div>
            );
        };

        const getHeader = useCallback(
            (coworker: GetDaySchedulesResponseItemDto): ReactNode => {
                const { user } = coworker;
                const SIZE_IMAGE = 32;
                const workToday = getWorkToday({ events: undefined, coworker: coworker });
                const userImgSrc = user?.imageUrl ? img(user.imageUrl, ImgSize.s) : undefined;

                return (
                    <div
                        className="header-container cursor-pointer"
                        onClick={() => {
                            setIsOpen((prev) => !prev);
                        }}
                    >
                        <FavToggle
                            relationId={user?.favoriteInfo?.id}
                            coworkerId={user?.id}
                            fetch={[
                                Fetches.dashboardFetchSchedule,
                                Fetches.planScheduleFetchOfficeWorkingCoworkers,
                                Fetches.scheduleFetchOfficeWorkingCoworkers,
                            ]}
                            onChange={onChangeFavorites}
                            hasTooltipOnHover={favToggleHasTooltip}
                        />

                        <div className="logo-name">
                            <ImageWithPlaceholder
                                imgSrc={userImgSrc}
                                width={SIZE_IMAGE}
                                height={SIZE_IMAGE}
                            />
                            <div className={`text-body${isMobile ? '-bold' : ''}`}>
                                {user?.firstName} {user?.lastName}
                            </div>
                        </div>

                        <div className="workingFrom text-body">
                            <div className="text-body workToday">{workToday}</div>
                        </div>

                        <div className={`chevron ${isOpen ? 'open' : ''}`}>
                            <ChevronDownIcon width={24} />
                        </div>
                    </div>
                );
            },
            [coworker, isOpen]
        );

        const getMobileHeader = useCallback(
            (coworker: GetDaySchedulesResponseItemDto): ReactNode => {
                const { user } = coworker;
                const SIZE_IMAGE = 32;
                const workToday = getWorkToday({ events: undefined, coworker: coworker });
                const userImgSrc = user?.imageUrl ? img(user.imageUrl, ImgSize.s) : undefined;

                return (
                    <div
                        className="header-container header-container-mobile cursor-pointer"
                        onClick={() => {
                            setIsOpen((prev) => !prev);
                        }}
                    >
                        <div className="top-half">
                            <div className="logo-name">
                                <ImageWithPlaceholder
                                    imgSrc={userImgSrc}
                                    width={SIZE_IMAGE}
                                    height={SIZE_IMAGE}
                                />
                                <div className={`text-body${isMobile ? '-bold' : ''}`}>
                                    {user?.firstName} {user?.lastName}
                                </div>
                            </div>

                            <FavToggle
                                relationId={user?.favoriteInfo?.id}
                                coworkerId={user?.id}
                                fetch={[
                                    Fetches.dashboardFetchSchedule,
                                    Fetches.planScheduleFetchOfficeWorkingCoworkers,
                                    Fetches.scheduleFetchOfficeWorkingCoworkers,
                                ]}
                                onChange={onChangeFavorites}
                                hasTooltipOnHover={favToggleHasTooltip}
                            />
                        </div>

                        <Divider marginBottom={10} marginTop={10} />

                        <div className="bottom-half">
                            <div className="workingFrom text-body">
                                <div className="text-body workToday">{workToday}</div>
                            </div>

                            <div className={`chevron ${isOpen ? 'open' : ''}`}>
                                <ChevronDownIcon width={24} />
                            </div>
                        </div>
                    </div>
                );
            },
            [coworker, isOpen]
        );

        useEffect(() => {
            setFavToggleHasTooltip(windowWidth > MOBILE_MAX);
        }, [windowWidth]);

        return (
            <div className="CoworkerWorkingLine">
                {isMobile ? getMobileHeader(coworker) : getHeader(coworker)}

                <div
                    className={`inner-container ${isOpen ? 'open' : ''} ${
                        isMobile ? 'inner-container-mobile' : ''
                    }`}
                    style={{
                        height: !isOpen
                            ? 0
                            : events.length * 40 /* height of every event line*/ +
                              67 /*height of the hour timeline */,
                    }}
                >
                    {/* important to only render if needed. This kills performance in the infinite scroll with lots of results */}
                    {isOpen && (
                        <div className="content">
                            {events.map((event, key) => (
                                <div key={event?.id ?? ''} className="copy-schedule">
                                    <div
                                        className="one-line-event"
                                        style={{
                                            backgroundColor:
                                                key % 2 ? theme['layout-super-light'] : 'white',
                                        }}
                                    >
                                        <div
                                            className={` ${
                                                isMobile ? 'text-footnote' : 'text-body'
                                            }`}
                                        >
                                            {getWorkToday({
                                                events: event,
                                                coworker: coworker,
                                            })}
                                        </div>
                                        <Tag
                                            text={t(`EventTypeDto.EventTypeDto_${event.type}`)}
                                            color={getEventTypeTagColor(
                                                event.type || EventTypeDto.Away
                                            )}
                                        />
                                        <WorkTypeStatus
                                            workTypes={[event.workType || WorkTypeDto.Away]}
                                            tooltip={{
                                                active: true,
                                                title: event?.office?.name || '',
                                            }}
                                            type="very-small"
                                        />
                                    </div>
                                    {!isMobile && (
                                        <TooltipWrapper
                                            placement="top"
                                            title={t('Schedule.add_schedule_to_yours')}
                                            width={
                                                languageStore.currentLanguage ===
                                                SupportedLanguage.EN
                                                    ? 240
                                                    : 260
                                            }
                                        >
                                            <div
                                                className="copy-schedule cursor-pointer"
                                                onClick={() => onCopyEventClick(event)}
                                            >
                                                <CalendarPlusIcon
                                                    fill={theme['primary-mid-contrast']}
                                                    width={18}
                                                    height={18}
                                                />
                                            </div>
                                        </TooltipWrapper>
                                    )}
                                </div>
                            ))}

                            {!isMobile && (
                                <HourTimeline
                                    date={date}
                                    events={
                                        coworker.events
                                            ?.filter((event) => event !== null)
                                            .map((x) => x!) ?? []
                                    }
                                    timezoneConvert={userStore.userInfo?.timeZone ?? ''}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    })
);

export default CoworkerWorkingLine;
