import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const UploadBoxIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M14 14.1113C14.2109 14.1113 14.4043 14.041 14.6064 13.8389L17.4629 11.0879C17.6035 10.9473 17.6826 10.7891 17.6826 10.5781C17.6826 10.1826 17.3662 9.89258 16.9795 9.89258C16.7773 9.89258 16.584 9.97168 16.4434 10.1299L15.2656 11.3779L14.7383 12.0107L14.8262 10.6924V4.10059C14.8262 3.66992 14.457 3.29199 14 3.29199C13.5518 3.29199 13.1826 3.66992 13.1826 4.10059V10.6924L13.2705 12.0195L12.7344 11.3779L11.5654 10.1299C11.4248 9.97168 11.2227 9.89258 11.0205 9.89258C10.6338 9.89258 10.3174 10.1826 10.3174 10.5781C10.3174 10.7891 10.3965 10.9473 10.5459 11.0879L13.4023 13.8389C13.6045 14.041 13.7979 14.1113 14 14.1113ZM6.47656 22.9883H21.5146C23.4219 22.9883 24.415 22.0039 24.415 20.123V14.9639C24.415 14.0322 24.3008 13.5312 23.8789 12.9863L20.9785 9.25977C19.9238 7.89746 19.2559 7.20312 17.6475 7.20312H16.4521V8.64453H17.7266C18.3418 8.64453 18.9482 9.1543 19.3525 9.67285L22.3584 13.6279C22.6572 14.0234 22.543 14.1904 22.0947 14.1904H16.584C16.127 14.1904 15.916 14.5156 15.916 14.8584V14.8936C15.916 15.8428 15.1777 16.8535 14 16.8535C12.8135 16.8535 12.0752 15.8428 12.0752 14.8936V14.8584C12.0752 14.5156 11.873 14.1904 11.4072 14.1904H5.90527C5.44824 14.1904 5.35156 13.9971 5.6416 13.6279L8.64746 9.67285C9.05176 9.1543 9.6582 8.64453 10.2734 8.64453H11.5479V7.20312H10.3525C8.74414 7.20312 8.07617 7.88867 7.02148 9.25977L4.1123 12.9863C3.69922 13.54 3.58496 14.0322 3.58496 14.9639V20.123C3.58496 22.0039 4.57812 22.9883 6.47656 22.9883ZM6.56445 21.2305C5.75586 21.2305 5.31641 20.8086 5.31641 19.9648V15.7373H10.5811C10.8623 17.3018 12.2246 18.4619 14 18.4619C15.7754 18.4619 17.1377 17.3018 17.4189 15.7373H22.6836V19.9648C22.6836 20.8086 22.2354 21.2305 21.4268 21.2305H6.56445Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default UploadBoxIcon;
