import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UserBadgeDto } from 'Api/Features/Users/Dtos/UserBadgeDto';
import Button from 'Components/button';
import BaseModalMobile from 'Components/modal/base-modal-mobile';
import Tag, { TagColor } from 'Components/tag/tag';
import React from 'react';
import './profile-modal-mobile.less';
import Badges from 'Components/badges';

export interface VisibleData {
    visible: boolean;
    onClose: () => void;
}

export interface ProfileModalMobileData {
    imgUrl: string;
    managementRoleText?: ManagementRoleDto;
    name: string;
    jobTitle: string;
    badges?: UserBadgeDto[];
    teamName: string;
    department: string;
}

interface ProfileModalMobileProps {
    visibleData: VisibleData;
    data: ProfileModalMobileData;
    onClickLogout: () => void;
}

const ProfileModalMobile: React.FunctionComponent<ProfileModalMobileProps> = ({
    visibleData,
    data,
    onClickLogout,
}) => {
    return (
        <BaseModalMobile
            className="ProfileModalMobile"
            visible={visibleData.visible}
            onCancel={visibleData.onClose}
            maskClosable
        >
            <div className="img-status-container">
                <div
                    className="img-container"
                    style={{
                        backgroundImage: `url(${data.imgUrl})`,
                    }}
                >
                    {data.managementRoleText && (
                        <div className="tag-container">
                            <Tag text={data.managementRoleText} color={TagColor.blue} />
                        </div>
                    )}
                </div>
            </div>

            <div className="text-container">
                <div className="text-title-3-bold">{data.name}</div>
                <div className="text-body">{data.jobTitle}</div>
            </div>

            {data.badges && <Badges types={data.badges} />}

            <div className="text-container bottom-text-container">
                <div className="text-body">{data.teamName}</div>
                <div className="text-body text-departement">{data.department}</div>
            </div>

            <Button text="Logout" type="light" onClick={onClickLogout} />
        </BaseModalMobile>
    );
};

export default ProfileModalMobile;
