import Icon from 'Components/icons/Icon';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, {
    Children,
    cloneElement,
    isValidElement,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'Stores/LanguageStore';
import './index.less';

enum Direction {
    left = 'left',
    right = 'right',
    today = 'today',
}

export interface ColsPosition {
    leftNb: number;
    displayNb: number;
    rightNb: number;
}

interface HorizontalGridSlideProps {
    className?: string;
    colSize: number;
    gap?: number;
    pageSlide?: boolean;
    onPositionChanges?: (colsPosition: ColsPosition) => void;
    canMoveForward: boolean;
}

const HorizontalGridSlide: React.FunctionComponent<HorizontalGridSlideProps> = observer(
    ({
        className,
        colSize,
        gap = 10,
        pageSlide = false,
        children,
        onPositionChanges,
        canMoveForward,
    }) => {
        const { languageStore } = useStores();
        const [colsPosition, setColsPosition] = useState<ColsPosition>({
            leftNb: 0,
            displayNb: 0,
            rightNb: 0,
        });
        const horizontalGridSlideRef = useRef<HTMLDivElement>(null);
        const { t } = useTranslation();

        const colsNb = Children.count(children);
        const step = colSize + gap;
        const defaultStyle = {
            gridTemplateColumns: `repeat(${colsNb}, ${colSize}px)`,
            gap: `${gap}px`,
        };

        const move = (direction: Direction) => {
            let amountToMove = 0;

            if (direction === Direction.today) {
                setColsPosition(
                    (previousCols) =>
                        previousCols && {
                            leftNb: 0,
                            displayNb: previousCols.displayNb,
                            rightNb: previousCols.rightNb + previousCols.leftNb,
                        }
                );
            } else {
                if (pageSlide) {
                    amountToMove = colsPosition.displayNb;

                    if (direction === Direction.left) {
                        if (colsPosition.leftNb < colsPosition.displayNb) {
                            amountToMove = colsPosition.leftNb;
                        }
                    } else if (canMoveForward) {
                        if (colsPosition.rightNb < colsPosition.displayNb) {
                            amountToMove = colsPosition.rightNb;
                        }
                    }
                } else {
                    if (direction === Direction.left) {
                        if (colsPosition.leftNb > 0) {
                            amountToMove = -1;
                        }
                    } else if (canMoveForward) {
                        if (colsPosition.rightNb > 0) {
                            amountToMove = 1;
                        }
                    }
                }

                setColsPosition(
                    (previousCols) =>
                        previousCols && {
                            leftNb: previousCols.leftNb + amountToMove,
                            displayNb: previousCols.displayNb,
                            rightNb: previousCols.rightNb - amountToMove,
                        }
                );
            }
        };

        useEffect(() => {
            function handleResize() {
                setColsPosition((previousCols) => {
                    if (horizontalGridSlideRef.current) {
                        const leftNb = previousCols.leftNb;
                        const displayNb = Math.trunc(
                            (horizontalGridSlideRef.current.offsetWidth + gap) / step
                        );
                        const rightNb = colsNb - displayNb - leftNb;

                        return { leftNb, displayNb, rightNb };
                    } else {
                        return previousCols;
                    }
                });
            }

            window.addEventListener('resize', handleResize);
            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        }, [horizontalGridSlideRef?.current?.offsetWidth, colsNb]);

        useEffect(() => {
            if (onPositionChanges) onPositionChanges(colsPosition);
        }, [colsPosition]);

        return (
            <div className={`HorizontalGridSlide ${className ?? ''}`}>
                {!(colsPosition.leftNb <= 0 && colsPosition.rightNb <= 0) && (
                    <div className="HorizontalGridSlide-buttonWrapper">
                        <button
                            className={`HorizontalGridSlide-button-left active-${
                                colsPosition.leftNb > 0
                            }`}
                            onClick={() => move(Direction.left)}
                        >
                            <Icon iconName="ChevronLeftIcon" />
                        </button>

                        <button
                            className="HorizontalGridSlide-button-today"
                            onClick={() => move(Direction.today)}
                            style={{
                                width:
                                    languageStore.currentLanguage === SupportedLanguage.EN
                                        ? 75
                                        : 120,
                            }}
                        >
                            {t('today')}
                        </button>

                        <button
                            className={`HorizontalGridSlide-button-right active-${
                                colsPosition.rightNb > 0
                            }`}
                            onClick={() => move(Direction.right)}
                        >
                            <Icon iconName="ChevronLeftIcon" />
                        </button>
                    </div>
                )}

                <div className="HorizontalGridSlide-overflow">
                    <div className="HorizontalGridSlide-wrapper" ref={horizontalGridSlideRef}>
                        <div
                            className="HorizontalGridSlide-slider"
                            style={{ ...defaultStyle, left: `${-colsPosition.leftNb * step}px` }}
                        >
                            {Children.map(
                                children,
                                (child, i) =>
                                    isValidElement(child) &&
                                    cloneElement(child as ReactElement, {
                                        className: `visible-${
                                            i + 1 > colsPosition.leftNb &&
                                            i + 1 <= colsPosition.leftNb + colsPosition.displayNb
                                        }`,
                                    })
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default HorizontalGridSlide;
