import axios, {
    AxiosResponse,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosError,
    InternalAxiosRequestConfig,
} from 'axios';
import { ApiHttpClient } from '../Api/ApiHttpClient';
import { inject } from 'aurelia-dependency-injection';
import { AuthorizationStore, ToastStore } from 'Stores';
import { Environment } from '../Misc/Environment';
import { ABORT_CONTROLLER_ABORTED_ERROR } from 'Models/Constants';

export interface HttpError extends AxiosError {
    treated: boolean;
}

@inject(AuthorizationStore, ToastStore, Environment)
export class HttpClient implements ApiHttpClient {
    private readonly axiosInstance: AxiosInstance;

    constructor(
        private readonly authorizationStore: AuthorizationStore,
        private readonly toastStore: ToastStore,
        private readonly environment: Environment
    ) {
        const requestConfig: AxiosRequestConfig = {
            baseURL: environment.REACT_APP_API_DOMAIN || undefined,
            headers: {
                'App-Info': `Web;ca.appcom.flexy.webapp;${process.env.REACT_APP_VERSION}`,
            },
        };

        // Send a header to specify the current tenant
        if (environment.REACT_APP_TENANT_ID && requestConfig.headers) {
            requestConfig.headers['Tenant-Id'] = environment.REACT_APP_TENANT_ID;
        }

        this.axiosInstance = axios.create(requestConfig);
        this.handleRejection = this.handleRejection.bind(this);
        this.axiosInstance.interceptors.response.use((response) => response, this.handleRejection);
    }

    public addAbortSignal(abortSignal: AbortSignal) {
        this.axiosInstance.defaults.signal = abortSignal;
    }

    public async get<TR>(uri: string): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.get(uri);
        return response?.data;
    }

    public async getAsBuffer(uri: string): Promise<ArrayBuffer> {
        const response: AxiosResponse<ArrayBuffer> = await this.axiosInstance.get(uri, {
            responseType: 'arraybuffer',
        });
        return response?.data;
    }

    public async delete<TD, TR>(uri: string, data?: TD): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.delete(uri, { data: data });
        return response?.data;
    }

    public async post<TD, TR>(uri: string, data?: TD): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.post(uri, data);
        return response?.data;
    }

    public async postAsBuffer<TD>(uri: string, data?: TD): Promise<ArrayBuffer> {
        const response: AxiosResponse<ArrayBuffer> = await this.axiosInstance.post(uri, data, {
            responseType: 'arraybuffer',
        });
        return response?.data;
    }

    public async put<TD, TR>(uri: string, data?: TD | undefined): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.put(uri, data);
        return response?.data;
    }

    public async patch<TD, TR>(uri: string, data?: TD | undefined): Promise<TR> {
        const response: AxiosResponse<TR> = await this.axiosInstance.patch(uri, data);
        return response?.data;
    }

    public addRequestInterceptor(
        onFulfilled?: (
            value: InternalAxiosRequestConfig
        ) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>,
        onRejected?: (error: any) => any
    ): void {
        this.axiosInstance.interceptors.request.clear();
        this.axiosInstance.interceptors.request.use(onFulfilled, onRejected);
    }

    public handleRejection(error: AxiosError): void {
        const httpError = error as HttpError;
        if (httpError.code === ABORT_CONTROLLER_ABORTED_ERROR) {
            //abort controller canceled request, should not have to toast in this case
            httpError.treated = true;
        } else if (httpError.response) {
            if (httpError.response.status >= 500) {
                this.toastStore.serverError();
                httpError.treated = true;
            } else if (httpError.response.status === 404 || httpError.response.status === 403) {
                this.authorizationStore.handleErrorCode(httpError.response.status);
                //404 or 403 page will be shown.
                httpError.treated = true;
            } else if (httpError.response.status === 401) {
                //handling of running azure sign-in flow is triggered elsewhere. For now handle as treated silently so no error toast
                httpError.treated = true;
            }
        }
        throw httpError;
    }
}
