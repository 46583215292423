import { useService } from 'Hooks';
import React, { useEffect } from 'react';
import { GoogleCalendarService } from 'Services/GoogleCalendarService';

const GoogleCalendarCallback: React.FunctionComponent = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const googleCalendarService = useService(GoogleCalendarService);

    const googleCalendarFlow = () => {
        const code = urlParams.get('code') || '';
        const state = urlParams.get('state') || '';

        googleCalendarService.runPromiseCallbackGoogleCalendarFlow(code, state);
    };

    useEffect(() => {
        googleCalendarFlow();
    }, []);

    return <div className="GoogleCalendarCallback" />;
};

export default GoogleCalendarCallback;
