import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect } from 'react';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';

export const Login: FunctionComponent = observer(() => {
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    const { languageStore } = useStores();

    useEffect(() => {
        const signIn = async () => {
            await azureAdAuthenticationService.runSignInFlow(languageStore.currentLanguage);
        };

        signIn();
    }, [languageStore.currentLanguage]);

    return <div></div>;
});

export default Login;
