import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const HouseIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M3 13.8835C3 14.3406 3.35156 14.7361 3.91406 14.7361C4.18652 14.7361 4.43262 14.5867 4.65234 14.4109L5.6543 13.5671V21.741C5.6543 23.0417 6.43652 23.8152 7.78125 23.8152H19.6729C21.0088 23.8152 21.7998 23.0417 21.7998 21.741V13.5232L22.8545 14.4109C23.0654 14.5867 23.3115 14.7361 23.584 14.7361C24.1025 14.7361 24.498 14.4109 24.498 13.9011C24.498 13.6023 24.3838 13.365 24.1553 13.1716L21.7998 11.1853V7.44116C21.7998 7.04565 21.5449 6.79956 21.1494 6.79956H19.9365C19.5498 6.79956 19.2861 7.04565 19.2861 7.44116V9.07593L15.0059 5.4812C14.2412 4.8396 13.2744 4.8396 12.5098 5.4812L3.35156 13.1716C3.11426 13.365 3 13.6287 3 13.8835ZM16.2803 16.3093C16.2803 15.8962 16.0166 15.6326 15.6035 15.6326H11.9121C11.499 15.6326 11.2266 15.8962 11.2266 16.3093V22.0662H8.24707C7.70215 22.0662 7.40332 21.7585 7.40332 21.2048V12.0994L13.3711 7.0896C13.6172 6.87866 13.8984 6.87866 14.1445 7.0896L20.042 12.0466V21.2048C20.042 21.7585 19.7432 22.0662 19.1982 22.0662H16.2803V16.3093Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default HouseIcon;
