import { Collapse, Divider, Form } from 'antd';
import { HappeningFilterModeDto } from 'Api/Features/Happenings/Dtos/HappeningFilterModeDto';
import { CreateUserScheduleEventRequestDto } from 'Api/Features/Schedules/Dtos/CreateUserScheduleEventRequestDto';
import { GetDailyWorkStatsRequestDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsRequestDto';
import { GetDailyWorkStatsResponseDayDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsResponseDayDto';
import { GetDailyWorkStatsResponseDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsResponseDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { ScheduleDto } from 'Api/Features/Schedules/Dtos/ScheduleDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { UpdateUserScheduleEventRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserScheduleEventRequestDto';
import { UpdateUserScheduleTimelineOperationDto } from 'Api/Features/Schedules/Dtos/UpdateUserScheduleTimelineOperationDto';
import { UpdateUserScheduleTimelineRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserScheduleTimelineRequestDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserTeamFilterModeDto } from 'Api/Features/Users/Dtos/UserTeamFilterModeDto';
import Icon from 'Components/icons/Icon';
import ObjectivesSummary from 'Components/objectives-summary';
import PolicyObjectiveCircleIcon from 'Components/policyObjectiveCircleIcon';
import Skeleton from 'Components/skeleton';
import Tag, { TagColor } from 'Components/tag/tag';
import WeeklyScheduleCalendar, {
    EventChangeCommands,
    EventChangeCommandType,
    WeeklyScheduleCalendarType,
} from 'Components/weekly-schedule-calendar';
import { Fetches, TriggerFetchContext } from 'Contexts/TriggerFetchContext';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import {
    E004006,
    E006002,
    FORMAT_DAY_WRITTEN_ABBR,
    FORMAT_MONTH_WRITTEN_DATE,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import moment from 'moment';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OfficeService } from 'Services/OfficeService';
import { ScheduleService } from 'Services/ScheduleService';
import { PolicyInfoStoreObjectiveStats } from 'Stores/PolicyStore';
import { getTotalHoursInEvents, getTotalHoursInEventsType } from 'Utils/CalendarUtils';
import {
    createTimeMomentInTimezoneWithoutConverting,
    createTimezonedMomentFromDateAndTime,
    momentIsLastPossibleTimePeriod,
} from 'Utils/TimeUtils';
import './plan-schedule.less';
import {
    hoursAndObjectivesSkeletonShape,
    planScheduleCoworkersAtOfficeSkeletonShape,
} from './skeleton-shapes';
import CardCoworkersAtOffice from 'Routes/plan-schedule/card-coworkers-at-office';
import WorkingCoworkersModal, {
    WorkingCoworkersModalMode,
} from 'Components/coworkers-at-office-modal/working-coworkers-modal';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';

export interface DividedScheduleEventDto extends ScheduleEventDto {
    eventSpansMultipleDays: boolean;
    originalEventStartTime?: string;
    originalEventEndTime?: string;
    capacity?: string;
}

const PlanSchedule: React.FunctionComponent = observer(() => {
    //#region Hooks
    const triggerFetchContext = useContext(TriggerFetchContext);
    const history = useHistory();
    const [form] = Form.useForm();
    const { globalLoadingStore, toastStore, userStore, policyStore, languageStore } = useStores();
    const scheduleService = useService(ScheduleService);
    const [workingCoworkerModalState, setWorkingCoworkerModalState] = useState({
        visible: false,
        startingDate: '',
    });

    const { t } = useTranslation();

    const [events, setEvents] = useState<DividedScheduleEventDto[]>([]);
    const [dayOfWeek, setDayOfWeek] = useState<string>(
        history.location?.search?.split('?date=')?.[1]
            ? moment(history.location?.search?.split('?date=')[1]).format(FORMAT_YEAR_MONTH_DAY)
            : moment().format(FORMAT_YEAR_MONTH_DAY)
    );

    const [editedDatesForCalendarHeader, setEditedDatesForCalendarHeader] = useState<string[]>([]);
    const [copyEvent, setCopyEvent] = useState<ScheduleEventDto>();
    const [daysCoworker, setDaysCoworker] = useState<GetDailyWorkStatsResponseDayDto[]>([]);
    const [isLoaded, setIsLoaded] = useState({
        calendar: false,
        coworkers: false,
        policyStore: false,
        attendee: false,
    });
    const [objectiveStats, setObjectiveStats] = useState<PolicyInfoStoreObjectiveStats>();
    const officeService = useService(OfficeService);

    const policyStoreSetPolicyObjectivesStatsForSpecificWeek = async (argDayOfWeek) => {
        setIsLoaded((prev) => {
            return { ...prev, policyStore: false };
        });

        const response = await policyStore.setPolicyObjectivesStatsForSpecificWeek(argDayOfWeek);

        setIsLoaded((prev) => {
            return { ...prev, policyStore: true };
        });
        return response;
    };

    const handleDateOverrides = (schedule: ScheduleDto) => {
        //find days that override default schedule
        const editedDates: string[] = [];
        const firstWeekDay = moment
            .tz(dayOfWeek, userStore.userInfo?.timeZone ?? '')
            .startOf('week');
        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
            if (schedule.days?.[dayIndex]?.canBeRevertedToDefaultSchedule) {
                editedDates.push(firstWeekDay.format(FORMAT_YEAR_MONTH_DAY));
            }
            firstWeekDay.add(1, 'day');
        }
        setEditedDatesForCalendarHeader(editedDates);
    };

    const fetchAttendeeSchedule = async (parsedEvents: DividedScheduleEventDto[]) => {
        setIsLoaded((prev) => {
            return {
                ...prev,
                attendee: false,
            };
        });

        const periods: any = [];
        const parsedEventWithCapacity: any = [];

        const getCapacity = (
            reponse: GetOfficeSpaceOccupancyResponsePeriodDto[],
            index: number
        ) => {
            const capacityIsEqualTo0 = reponse[index].officeSpace?.capacity === 0;
            if (capacityIsEqualTo0) return undefined;

            return `${reponse[index].occupancy} / ${reponse[index].officeSpace?.capacity}`;
        };

        /*
            Pour optimiser l'appel API:
                -> Tous les objets periods sont construits en amont, avant d'être envoyé en une seule requêtes API.
                -> Les resultats restent dans le même ordres dans le tableau. Les valeurs sont donc update avec leurs index.
        */

        // Créer le periods array.
        for (const event of parsedEvents) {
            if (event?.officeSpace?.id && event?.officeSpace?.id.length > 0 && event.startTime) {
                periods.push({
                    officeSpaceId: event?.officeSpace?.id || '',
                    startTime: event.startTime,
                    endTime: event.endTime,
                });
            }
        }

        // Appel API.
        try {
            const reponse = await officeService.getOfficeSpaceOccupancy({
                periods: periods,
                timeZone: userStore.userInfo?.timeZone,
            });

            // Réutilise l'index du retour API pour savoir le quel correspond au quel.
            let idx = 0;
            for (const event of parsedEvents) {
                if (
                    event?.officeSpace?.id &&
                    event?.officeSpace?.id.length > 0 &&
                    event.startTime
                ) {
                    periods.push({
                        officeSpaceId: event?.officeSpace?.id || '',
                        startTime: event.startTime,
                        endTime: moment
                            .tz(event.startTime, userStore.userInfo?.timeZone ?? '')
                            .add(30, 'minutes')
                            .format(),
                    });

                    parsedEventWithCapacity.push({ ...event, capacity: getCapacity(reponse, idx) });
                    idx = idx + 1;
                } else {
                    parsedEventWithCapacity.push(event);
                }
            }

            setEvents(parsedEventWithCapacity);
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
            setEvents(parsedEvents);
        } finally {
            setIsLoaded((prev) => {
                return {
                    ...prev,
                    attendee: true,
                };
            });
        }
    };

    const fetchSchedule = useCallback(async () => {
        const request: GetUserScheduleRequestDto = {
            week: dayOfWeek,
        };

        try {
            const schedule: ScheduleDto | null = await scheduleService.getUserSchedule(
                userStore.userInfo!.id!,
                request
            );

            if (schedule) {
                //Api might send events that split between two days depending on the timezone we are requesting. We need to create two events with it.
                const parsedEvents: DividedScheduleEventDto[] = [];
                schedule.events
                    ?.filter((event) => event !== null)
                    .map((event) => event!)
                    .map(async (event) => {
                        if (
                            //event start and end are different days
                            moment
                                .tz(event.startTime, userStore.userInfo?.timeZone ?? '')
                                .date() !==
                                moment
                                    .tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                    .date() &&
                            //the end is not midnight
                            !moment
                                .tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                .startOf('day')
                                .isSame(
                                    moment.tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                )
                        ) {
                            //create first event from starttime to end of day.
                            parsedEvents.push({
                                ...event,
                                startTime: event.startTime,
                                endTime: moment
                                    .tz(event.startTime, userStore.userInfo?.timeZone ?? '')
                                    .endOf('day')
                                    .add(1, 'second')
                                    .format(),
                                originalEventStartTime: event.startTime,
                                originalEventEndTime: event.endTime,
                                eventSpansMultipleDays: true,
                                capacity: '',
                            });
                            //create second event from start of endtime day to endtime
                            parsedEvents.push({
                                ...event,
                                startTime: moment
                                    .tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                    .startOf('day')
                                    .format(),
                                endTime: event.endTime,
                                originalEventStartTime: event.startTime,
                                originalEventEndTime: event.endTime,
                                eventSpansMultipleDays: true,
                                capacity: '',
                            });
                        } else {
                            parsedEvents.push({
                                ...event,
                                eventSpansMultipleDays: false,
                                originalEventEndTime: event.endTime,
                                originalEventStartTime: event.startTime,
                                capacity: '',
                            });
                        }
                    });

                fetchAttendeeSchedule(parsedEvents);
                setEvents(parsedEvents);

                handleDateOverrides(schedule);
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setIsLoaded((prev) => {
                return { ...prev, calendar: true };
            });
        }
    }, [toastStore, scheduleService, dayOfWeek, languageStore.currentLanguage]);

    //Navigated from my schedule page and trying to copy an event
    useEffect(() => {
        if ((history.location.state as any)?.copyEvent) {
            const event: ScheduleEventDto = (history.location.state as any).copyEvent;
            setCopyEvent(event);
        }
    }, [history.location.state]);

    useEffect(() => {
        if (userStore.userInfo?.id && dayOfWeek) fetchSchedule();
    }, [userStore.userInfo?.id, dayOfWeek]);

    const fetchOfficeWorkingCoworkers = async () => {
        try {
            const request: GetDailyWorkStatsRequestDto = {
                week: moment(dayOfWeek).format(FORMAT_YEAR_MONTH_DAY),
                workType: WorkTypeDto.Office,
                teamIds: [userStore.userInfo?.team?.id ?? ''],
                favoritesForUserId: userStore.userInfo?.id,
                excludedUserIds: [userStore.userInfo?.id || ''],
                teamFilterMode: UserTeamFilterModeDto.MembersAndFavorites,
                happeningOfficeIds: undefined, // https://appcom.atlassian.net/browse/FLEXY-3373?focusedCommentId=51000
                happeningFilterMode: HappeningFilterModeDto.GeneralAndUserClubs,
                clubsForUserId: userStore.userInfo?.id,
            };
            const response: GetDailyWorkStatsResponseDto = await scheduleService.getDailyWorkStats(
                request
            );

            if (response.days) {
                const listDays: GetDailyWorkStatsResponseDayDto[] | any[] = response.days;
                setDaysCoworker(listDays);
                setIsLoaded((prev) => {
                    return { ...prev, coworkers: true };
                });
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    };

    useEffect(() => {
        if (userStore.userInfo?.id && userStore.userInfo.team?.id && dayOfWeek)
            fetchOfficeWorkingCoworkers();
        else if (userStore.userInfo?.id && !userStore.userInfo?.team?.id) {
            setIsLoaded((prev) => {
                return { ...prev, coworkers: true };
            });
        }
    }, [userStore.userInfo?.id, userStore.userInfo?.team?.id, dayOfWeek]);

    const renderCardCoworkersAtOffice = useCallback((): ReactNode[] => {
        // Génére les journées pour un utilisateur sans team.
        const createFakeDay = () => {
            let day: any = new Date();
            const list: GetDailyWorkStatsResponseDayDto[] = [];
            for (let i = 0; i < 7; i++) {
                list.push({ date: day, users: [], usersCount: 0, favoriteUsersCount: 0 });
                day = moment(day).add(1, 'day');
            }
            return list;
        };

        const DOMToReturn: ReactNode[] = [];
        const daysCoworkerToDisplay: GetDailyWorkStatsResponseDayDto[] =
            daysCoworker && daysCoworker.length > 0 ? daysCoworker : createFakeDay();

        daysCoworkerToDisplay.forEach((item: GetDailyWorkStatsResponseDayDto, index) => {
            return DOMToReturn.push(
                <div
                    key={index}
                    onClick={() => {
                        if ((item.usersCount || 0) <= 0 && (item.happenings?.length || 0) <= 0)
                            return;
                        setWorkingCoworkerModalState({
                            visible: true,
                            startingDate: moment(item.date)
                                .add(1, 'day')
                                .format(FORMAT_YEAR_MONTH_DAY),
                        });
                    }}
                    className={`${
                        (item?.usersCount ?? 0) > 0 || (item.happenings?.length || 0) > 0
                            ? 'cursor-pointer'
                            : ''
                    }`}
                >
                    <CardCoworkersAtOffice
                        day={moment(item.date).add(1, 'day').format(FORMAT_DAY_WRITTEN_ABBR)}
                        listUrlImagesTeams={
                            item.users
                                ?.slice(0, 5) //only need top 5 max displayed
                                ?.filter((el) => el?.id != userStore.userInfo?.id)
                                .map((el) => el?.imageUrl) as string[]
                        }
                        usersCount={item.usersCount ?? 0}
                        favCoworkers={item.favoriteUsersCount ?? 0}
                        hasHappening={(item.happenings?.length ?? 0) > 0}
                    />
                </div>
            );
        });
        return DOMToReturn;
    }, [dayOfWeek, daysCoworker]);

    //#region Submit / Exit

    const submit = async (eventCommands: EventChangeCommands): Promise<void> => {
        try {
            if (userStore.userInfo?.id) {
                globalLoadingStore.addLoading();

                const overrideDefaultEvent = eventCommands.commands.find(
                    (command) =>
                        command.commandType === EventChangeCommandType.OverrideDefaultSchedule
                );

                const createEvents = eventCommands.commands.filter(
                    (command) => command.commandType === EventChangeCommandType.Create
                );

                if (createEvents.length > 0) {
                    //must be (for of)
                    for (const eventCommand of createEvents) {
                        const event = eventCommand.event;
                        const request: CreateUserScheduleEventRequestDto = {
                            ...event,
                            endTime: momentIsLastPossibleTimePeriod(event?.endTime ?? '', false)
                                ? createTimezonedMomentFromDateAndTime(
                                      moment
                                          .tz(
                                              event?.endTime?.split('T')[0] ?? '',
                                              userStore.userInfo?.timeZone ?? ''
                                          )
                                          .add(1, 'day')
                                          .format(FORMAT_YEAR_MONTH_DAY),
                                      '00:00',
                                      userStore.userInfo?.timeZone ?? ''
                                  ).format()
                                : event?.endTime,
                            //if editing an event that comes from default schedule and it completly changes in time, we need to tell api to delete the original event
                            defaultScheduleEventStartTime: overrideDefaultEvent?.event.startTime,
                            defaultScheduleEventEndTime: overrideDefaultEvent?.event.endTime,
                        };
                        await scheduleService.createUserScheduleEvent(
                            userStore.userInfo!.id!,
                            request
                        );
                    }
                }

                const editEvent = eventCommands.commands.find(
                    (command) => command.commandType === EventChangeCommandType.Update
                )?.event;

                if (editEvent && editEvent.id) {
                    const request: UpdateUserScheduleEventRequestDto = {
                        ...editEvent,
                        endTime: momentIsLastPossibleTimePeriod(editEvent?.endTime ?? '', false)
                            ? createTimezonedMomentFromDateAndTime(
                                  moment
                                      .tz(
                                          editEvent?.endTime?.split('T')[0] ?? '',
                                          userStore.userInfo?.timeZone ?? ''
                                      )
                                      .add(1, 'day')
                                      .format(FORMAT_YEAR_MONTH_DAY),
                                  '00:00',
                                  userStore.userInfo?.timeZone ?? ''
                              ).format()
                            : editEvent?.endTime,
                    };
                    await scheduleService.updateUserScheduleEvent(
                        userStore.userInfo?.id,
                        editEvent.id,
                        request
                    );
                }

                const deleteEvent = eventCommands.commands.find(
                    (command) => command.commandType === EventChangeCommandType.Delete
                );

                if (deleteEvent?.event.id) {
                    await scheduleService.deleteUserScheduleEvent(
                        userStore.userInfo.id,
                        deleteEvent.event.id
                    );
                }

                const deleteDefaultScheduleEvent = eventCommands.commands.find(
                    (command) =>
                        command.commandType === EventChangeCommandType.DeleteDefaultSchedule
                );

                if (deleteDefaultScheduleEvent) {
                    const request: UpdateUserScheduleTimelineRequestDto = {
                        operation:
                            UpdateUserScheduleTimelineOperationDto.CreateEmptyPersistedPeriod,
                        startTime: deleteDefaultScheduleEvent.event.startTime,
                        endTime: deleteDefaultScheduleEvent.event.endTime,
                    };
                    await scheduleService.updateUserScheduleTimeline(
                        userStore.userInfo.id,
                        request
                    );
                }

                await fetchSchedule();
                await policyStoreSetPolicyObjectivesStatsForSpecificWeek(dayOfWeek);

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('schedule'),
                    }),
                });
            }
        } catch (e: any) {
            if (e.response?.data?.errorCode === E004006) {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.errorDescription,
                });
            } else if (e.response?.data?.errorCode === E006002) {
                toastStore.toast({
                    type: 'error',
                    message: e.response.data.errorDescription,
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#endregion

    const getCurrentWeek = useCallback(() => {
        return {
            start: moment(dayOfWeek).startOf('week').format(),
            end: moment(dayOfWeek).endOf('week').format(),
        };
    }, [dayOfWeek]);

    const handleEventsChange = (eventCommands: EventChangeCommands) => {
        submit(eventCommands);
    };

    const getCurrentWeekInString = useCallback(() => {
        const { start, end } = getCurrentWeek();
        const format = FORMAT_MONTH_WRITTEN_DATE[languageStore.currentLanguage];
        const formatYear = 'YYYY';

        const startDate = moment(start).format(format);
        const endDate = moment(end).format(format);
        const year = moment(start).format(formatYear);

        return `${startDate} - ${endDate}, ${year}`;
    }, [getCurrentWeek]);

    const handleRevertToDefaultClick = async (revertingDate: string) => {
        if (userStore.userInfo?.id) {
            const request: UpdateUserScheduleTimelineRequestDto = {
                operation: UpdateUserScheduleTimelineOperationDto.RevertPeriodToDefaultSchedule,
                startTime: moment
                    .tz(revertingDate, userStore.userInfo.timeZone ?? '')
                    .startOf('day')
                    .format(),
                endTime: moment
                    .tz(revertingDate, userStore.userInfo.timeZone ?? '')
                    .endOf('day')
                    .add(1, 'second')
                    .format(),
            };
            try {
                globalLoadingStore.addLoading();
                await scheduleService.updateUserScheduleTimeline(userStore.userInfo!.id!, request);

                await fetchSchedule();
                await policyStoreSetPolicyObjectivesStatsForSpecificWeek(dayOfWeek);

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('schedule'),
                    }),
                });
            } catch (e: any) {
                if (e.response?.data?.errorCode === E004006) {
                    toastStore.toast({
                        type: 'error',
                        message: t('Errors.revert_default_schedule_error'),
                    });
                } else if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    useEffect(() => {
        const reloadPolicyStore = async () => {
            await policyStoreSetPolicyObjectivesStatsForSpecificWeek(dayOfWeek);
        };
        if (userStore.userInfo?.id) reloadPolicyStore();
    }, [dayOfWeek, userStore.userInfo?.id]);

    const getHoursWorks = useMemo(() => {
        let totalHoursWorks = '';
        const policyStoreObjectivesValues =
            policyStore?.policyInfoForSpecificWeek?.policyObjectiveStats?.objectives?.values;

        if (policyStoreObjectivesValues !== null && policyStoreObjectivesValues?.workHours !== null)
            totalHoursWorks = String(policyStoreObjectivesValues?.workHours || 0);

        totalHoursWorks = getTotalHoursInEvents(events, getTotalHoursInEventsType.workHoursPerWeek);
        return totalHoursWorks;
    }, [
        policyStore?.policyInfoForSpecificWeek?.policyObjectiveStats?.objectives?.values?.workHours,
        events.length,
    ]);

    const getOffhoursWorks = useMemo(() => {
        let totalOffHoursWork = '';
        const policyStoreObjectivesValues =
            policyStore?.policyInfoForSpecificWeek?.policyObjectiveStats?.objectives?.values;

        if (
            policyStoreObjectivesValues !== null &&
            policyStoreObjectivesValues?.availabilityHours !== null
        )
            totalOffHoursWork = String(policyStoreObjectivesValues?.availabilityHours || 0);

        totalOffHoursWork = getTotalHoursInEvents(
            events,
            getTotalHoursInEventsType.availabilityHoursPerWeek
        );
        return totalOffHoursWork;
    }, [
        policyStore?.policyInfoForSpecificWeek?.policyObjectiveStats?.objectives?.values
            ?.availabilityHours,
        events.length,
    ]);

    useEffect(() => {
        triggerFetchContext?.run(Fetches.coworkersAtOfficeModal, fetchOfficeWorkingCoworkers);
    }, [triggerFetchContext]);

    useEffect(() => {
        if (policyStore.policyInfoForSpecificWeek?.policyObjectiveStats)
            setObjectiveStats({
                ...policyStore.policyInfoForSpecificWeek?.policyObjectiveStats,
            });
    }, [policyStore.policyInfoForSpecificWeek?.policyObjectiveStats]);

    const HeaderObjectives = () => {
        return (
            <div className="header-objectives">
                <PolicyObjectiveCircleIcon
                    policyGoal={objectiveStats?.totalScore?.total}
                    policyCurrentValue={objectiveStats?.totalScore?.completed}
                    minWidth={30}
                />
                <div className="text-caption-2-bold number-progress">
                    {objectiveStats?.totalScore?.completed} / {objectiveStats?.totalScore?.total}
                </div>
                <div
                    className="text-caption-2 text-week-objectives"
                    dangerouslySetInnerHTML={{ __html: t('PlanSchedule.this_weeks_objectives') }}
                />
            </div>
        );
    };

    //#region Render
    return (
        <div className="PlanSchedule">
            <div className="calendar-container">
                <Form layout="vertical" form={form}>
                    <WeeklyScheduleCalendar
                        events={isLoaded.calendar ? events : []}
                        onEventsChange={handleEventsChange}
                        timezone={userStore.userInfo?.timeZone ?? ''}
                        onDateRangeChange={({ start }) =>
                            setDayOfWeek(moment(start).format(FORMAT_YEAR_MONTH_DAY))
                        }
                        currentWeek={getCurrentWeek()}
                        editedDates={editedDatesForCalendarHeader}
                        onRevertToDefaultClick={handleRevertToDefaultClick}
                        copyEvent={copyEvent}
                        weeklyScheduleCalendarType={WeeklyScheduleCalendarType.Personal}
                        restrictions={
                            isLoaded.calendar
                                ? policyStore.policyInfo?.restrictions ?? undefined
                                : undefined
                        }
                        isLoaded={{
                            policyStore: isLoaded.policyStore,
                            attendee: isLoaded.attendee,
                        }}
                    />
                </Form>
            </div>

            <div className="widgets-container">
                <Skeleton
                    isLoaded={dayOfWeek !== undefined}
                    placeholder={hoursAndObjectivesSkeletonShape}
                >
                    <>
                        <div className="title">
                            <div className="text-title-3">{t('this_week')}</div>
                            <div className="text-caption-2 text-mid-contrast">
                                {getCurrentWeekInString()}
                            </div>
                        </div>

                        <div className="hours-container">
                            <div className="hour-container">
                                <div className="numberHours text-footnote">
                                    <span className="text-title-3-bold">{getHoursWorks}</span>{' '}
                                    {t('hours')}
                                </div>
                                <Tag color={TagColor.green} text={t('PlanSchedule.of_work')} />
                            </div>

                            <div className="line" />

                            <div className="hour-container">
                                <div className="numberHours text-footnote">
                                    <span className="text-title-3-bold">{getOffhoursWorks}</span>{' '}
                                    {t('hours')}
                                </div>
                                <Tag color={TagColor.yellow} text={t('PlanSchedule.off_hours')} />
                            </div>
                        </div>

                        {objectiveStats?.totalScore?.total ? (
                            <>
                                <Divider style={{ marginTop: 25, marginBottom: 0 }} />
                                <Collapse
                                    className="Collapse collapse-animated-arrow"
                                    expandIconPosition={'right'}
                                    style={{ border: 0 }}
                                    expandIcon={({ isActive }) => (
                                        <span className={`ChevronDownIcon-open-${isActive}`}>
                                            <Icon
                                                iconName="ChevronDownIcon"
                                                width={24}
                                                height={24}
                                            />
                                        </span>
                                    )}
                                >
                                    <Collapse.Panel
                                        className="Panel"
                                        key={1}
                                        header={<HeaderObjectives />}
                                    >
                                        <ObjectivesSummary
                                            policyBundleObjectivesDetails={
                                                objectiveStats.policyBundleObjectivesDetails
                                            }
                                        />
                                    </Collapse.Panel>
                                </Collapse>
                            </>
                        ) : null}
                    </>
                </Skeleton>

                <Divider />

                <Skeleton
                    isLoaded={dayOfWeek != undefined && isLoaded.coworkers}
                    placeholder={planScheduleCoworkersAtOfficeSkeletonShape}
                >
                    <div className="title-at-the-office text-headline">
                        {t('PlanSchedule.work_at_office')}
                    </div>
                    <div className="card-coworkers-at-offices-container">
                        {renderCardCoworkersAtOffice()}
                    </div>
                </Skeleton>
            </div>

            {workingCoworkerModalState.visible && (
                <WorkingCoworkersModal
                    onComplete={(copyEvent?: ScheduleEventDto) => {
                        setWorkingCoworkerModalState({ visible: false, startingDate: '' });
                        if (copyEvent) {
                            setCopyEvent(copyEvent);
                            setDayOfWeek(
                                createTimeMomentInTimezoneWithoutConverting(
                                    copyEvent.startTime ?? ''
                                ).format(FORMAT_YEAR_MONTH_DAY)
                            );
                        }
                    }}
                    visible={workingCoworkerModalState.visible}
                    startingDate={workingCoworkerModalState.startingDate}
                    teamId={userStore.userInfo?.team?.id ?? ''}
                    mode={WorkingCoworkersModalMode.CoworkerList}
                    workType={WorkTypeDto.Office}
                />
            )}
        </div>
    );
    //#endregion
});

export default React.memo(PlanSchedule);
