import { GetTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetTeamDefaultScheduleRequestDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import WeeklyHourPrefCalendar, { CalendarForType } from 'Components/weekly-hour-pref-calendar';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { ScheduleService } from 'Services/ScheduleService';
import { PolicyWrapperBoxInformations } from 'Components/policy-wrapper-box-informations';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import { TeamService } from 'Services/TeamService';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import './hours-preferences-tab.less';
import { OfficeService } from 'Services/OfficeService';
import {
    DefaultScheduleEventWithCapacity,
    fetchDefaultScheduleAttendence,
} from 'Utils/CalendarUtils';

interface HoursPreferencesTabProps {
    data?: UserDetailsDto;
}

const HoursPreferencesTab: React.FunctionComponent<HoursPreferencesTabProps> = observer(
    ({ data: userData }) => {
        const scheduleService = useService(ScheduleService);
        const policyService = useService(PolicyBundleService);
        const officeService = useService(OfficeService);
        const teamService = useService(TeamService);
        const { toastStore, userStore } = useStores();

        const [team, setTeam] = useState<TeamDto>();
        const [teamPolicy, setTeamPolicy] = useState<PolicyBundleDto>();

        const [events, setEvents] = useState<ScheduleEventDto[]>([]);
        const [eventsWithCapacity, setEventsWithCapacity] = useState<
            DefaultScheduleEventWithCapacity[]
        >([]);

        const fetchDefaultSchedule = useCallback(
            async (userId: string) => {
                try {
                    const request: GetTeamDefaultScheduleRequestDto = {
                        week: moment().format(FORMAT_YEAR_MONTH_DAY),
                    };

                    const response = await scheduleService.getUserDefaultSchedule(userId, request);

                    if (response) {
                        const events =
                            response.events
                                ?.filter((event) => event !== null && event !== undefined)
                                .map((event) => event!) ?? [];
                        setEvents(events);
                        setEventsWithCapacity(events); //we can set events to render so that we dont have to wait on capacity to be able to show periods in calendar
                    }
                } catch (e: any) {
                    if (!e.treated) {
                        toastStore.genericError();
                    }
                }
            },
            [scheduleService]
        );

        useEffect(() => {
            if (userData?.id) fetchDefaultSchedule(userData.id);
        }, [userData?.id]);

        const fetchScheduleAttendence = async (events: ScheduleEventDto[]) => {
            setEventsWithCapacity(
                await fetchDefaultScheduleAttendence(
                    events,
                    officeService,
                    userStore.userInfo?.timeZone ?? '',
                    toastStore
                )
            );
        };

        useEffect(() => {
            if (events) {
                fetchScheduleAttendence(events);
            }
        }, [events]);

        const fetchTeam = useCallback(
            async (teamdId: string) => {
                try {
                    const response = await teamService.getTeam(teamdId);

                    if (response) setTeam(response);
                } catch (e: any) {
                    if (!e.treated) {
                        toastStore.genericError();
                    }
                }
            },
            [teamService]
        );

        useEffect(() => {
            if (userData?.team?.id) fetchTeam(userData?.team?.id);
        }, [userData?.team?.id]);

        const fetchTeamPolicy = useCallback(async (policyId: string) => {
            try {
                const response = await policyService.getPolicyBundle(policyId);
                if (response) setTeamPolicy(response);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        }, []);

        useEffect(() => {
            if (team?.policyBundle?.id) fetchTeamPolicy(team.policyBundle.id);
        }, [team?.policyBundle?.id]);

        return (
            <div className="HoursPreferencesTab">
                <div className="white-container">
                    <div className="x-padding bottom-padding">
                        <div className="mb-30 policy-container">
                            <PolicyWrapperBoxInformations
                                events={events}
                                objectives={teamPolicy?.objectives}
                                forSpecificTeamSchedule={true}
                            />
                        </div>

                        <WeeklyHourPrefCalendar
                            events={eventsWithCapacity}
                            isInteractable={false}
                            calendarForType={CalendarForType.TeamSchedule}
                            teamScheduleCoreHoursGroups={teamPolicy?.objectives?.coreHoursGroups}
                            restrictions={teamPolicy?.restrictions ?? undefined}
                            roundedBorders={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default HoursPreferencesTab;
