import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { TeamProxy } from 'Api/Features/Teams/TeamProxy';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { GetTeamsResponseDto } from 'Api/Features/Teams/Dtos/GetTeamsResponseDto';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { CreateTeamRequestDto } from 'Api/Features/Teams/Dtos/CreateTeamRequestDto';
import { CreateTeamResponseDto } from 'Api/Features/Teams/Dtos/CreateTeamResponseDto';
import { UpdateTeamRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamRequestDto';
import { UpdateTeamMembersRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersRequestDto';

@inject(TeamProxy)
export class TeamService extends ApiService {
    constructor(
        private readonly teamProxy: TeamProxy
    ) {
        super();
    }

    public async getTeam(teamId: string): Promise<TeamDto | null> {
        const data: TeamDto | null = await this.teamProxy.getTeam(teamId);
        return data;
    }

    public async getTeams(request: GetTeamsRequestDto | null): Promise<[TeamDto[], number]> {
        const response: GetTeamsResponseDto | null = await this.teamProxy.getTeams(request);
        return [
            response?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            response?.totalItemCount ?? 0,
        ];
    }

    public async createTeam(request: CreateTeamRequestDto | null): Promise<CreateTeamResponseDto | null> {
        const data: CreateTeamResponseDto | null = await this.teamProxy.createTeam(request);
        return data;
    }

    public async updateTeam(teamId: string, request: UpdateTeamRequestDto | null): Promise<void> {
        await this.teamProxy.updateTeam(teamId, request);
    }

    public async updateTeamMembers(teamId: string, request: UpdateTeamMembersRequestDto | null): Promise<void> {
        await this.teamProxy.updateTeamMembers(teamId, request);
    }

    public async deleteTeam(teamId: string): Promise<void> {
        await this.teamProxy.deleteTeam(teamId);
    }
}
