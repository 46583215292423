import { Col, Divider, Form, Input, Radio, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Button from 'Components/button';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingBaseProps } from '..';
import './base-styles.less';
import './workplaces.less';
import Icon from 'Components/icons/Icon';
import { FORM_GUTTER } from 'Models/Constants';
import { ValidatedFormItem } from 'Components/validated-form-item';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { useAsyncSingleSelectProps, useFormValidation, useService, useStores } from 'Hooks';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { OfficeService } from 'Services/OfficeService';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { displayAddress } from 'Utils/TextUtils';
import { OnboardingWorkplaceSchema } from 'Schemas/EditProfileSchema';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';

export interface OnboardingWorkplaceFormValues {
    defaultOffice?: { id?: string; name?: string; address?: any };
    defaultOfficeEnabled?: boolean;
    defaultOfficeSpace?: { id?: string; name?: string };
    defaultDesk?: string;
    defaultFloor?: string;
    preferredWorkType: UserPreferredWorkTypeDto;
}

interface OnboardingWorkplacesProps extends OnboardingBaseProps {
    previousFormValues?: OnboardingWorkplaceFormValues;
}

const OnboardingWorkplaces: React.FunctionComponent<OnboardingWorkplacesProps> = observer(
    ({ onNextClick, onPreviousClick, previousFormValues }) => {
        const { t } = useTranslation();
        const [form] = useForm();
        const officeService = useService(OfficeService);
        const { userStore } = useStores();
        const [errors, validateForm, resetErrors] = useFormValidation(
            OnboardingWorkplaceSchema,
            form
        );

        const [showDefaultOfficeForm, setShowDefaultOfficeForm] = useState(false);
        const [selectedPreferredWorkplace, setSelectedPreferredWorkplace] =
            useState<UserPreferredWorkTypeDto>();

        const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficesRequestDto) =>
                    await officeService.getOffices(request),
            },
            entityToSingleSelectCustomOption: (office: OfficeDto) =>
                ({
                    value: office.id,
                    label: office.name,
                    extraData: office.address,
                } as SingleSelectCustomOption),
            defaultSelectedOption: previousFormValues?.defaultOffice
                ? {
                      value: previousFormValues.defaultOffice?.id ?? '',
                      label: previousFormValues.defaultOffice?.name ?? '',
                      extraData: previousFormValues.defaultOffice?.address,
                  }
                : userStore.userInfo?.defaultOffice
                ? {
                      value: userStore.userInfo.defaultOffice?.id ?? '',
                      label: userStore.userInfo.defaultOffice?.name ?? '',
                      extraData: userStore.userInfo.defaultOffice?.address,
                  }
                : undefined,
        });

        const { asyncSingleSelectProps: spaceSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficeSpacesRequestDto) =>
                    await officeService.getOfficeSpaces(request),
                fetchFunctionExtraParams: {
                    officeIds: officeSelectProps.selected
                        ? [officeSelectProps.selected]
                        : undefined,
                },
            },
            entityToSingleSelectCustomOption: (space: OfficeSpaceDto) =>
                ({
                    value: space.id,
                    label: space.name,
                } as SingleSelectCustomOption),
            defaultSelectedOption: previousFormValues?.defaultOfficeSpace
                ? {
                      value: previousFormValues.defaultOfficeSpace?.id ?? '',
                      label: previousFormValues.defaultOfficeSpace?.name ?? '',
                  }
                : userStore.userInfo?.defaultOfficeSpace
                ? {
                      value: userStore.userInfo.defaultOfficeSpace?.id ?? '',
                      label: userStore.userInfo.defaultOfficeSpace?.name ?? '',
                  }
                : undefined,
        });

        useEffect(() => {
            //set info from previous form values. if not present take what's in store
            const setFormValues = (values) => {
                form.setFieldsValue({
                    preferredWorkType: values.preferredWorkType,
                    defaultFloor: values.defaultFloor,
                    defaultDesk: values.defaultDesk,
                    defaultOfficeId: values.defaultOffice
                        ? {
                              value: values.defaultOffice.id,
                              label: values.defaultOffice.name,
                              extraData: values.defaultOffice.address,
                          }
                        : undefined,
                    defaultOfficeSpaceId: values.defaultOfficeSpace
                        ? {
                              value: values.defaultOfficeSpace.id,
                              label: values.defaultOfficeSpace.name,
                          }
                        : undefined,
                    defaultOfficeEnabled:
                        values.defaultOffice !== null && values.defaultOffice !== undefined,
                });
            };

            if (previousFormValues) {
                setFormValues(previousFormValues);
                setShowDefaultOfficeForm(
                    previousFormValues.defaultOffice !== null &&
                        previousFormValues.defaultOffice !== undefined
                );
                setSelectedPreferredWorkplace(previousFormValues.preferredWorkType);
            } else if (userStore.userInfo) {
                setFormValues(userStore.userInfo);
                setShowDefaultOfficeForm(
                    userStore.userInfo.defaultOffice !== null &&
                        userStore.userInfo.defaultOffice !== undefined
                );
                setSelectedPreferredWorkplace(
                    userStore.userInfo.preferredWorkType ?? UserPreferredWorkTypeDto.Office
                );
            }
        }, [previousFormValues, userStore.userInfo]);

        useEffect(() => {
            //set the address in the field when selecting a new office
            form.setFieldsValue({
                address: displayAddress(officeSelectProps.selectedOption?.extraData),
            });
        }, [officeSelectProps.selectedOption]);

        const handleNextClick = async () => {
            const formData = form.getFieldsValue();
            const request: OnboardingWorkplaceFormValues = {
                defaultOffice: formData.defaultOfficeId
                    ? {
                          id: formData.defaultOfficeId.value,
                          name: formData.defaultOfficeId.label,
                          address: formData.defaultOfficeId.extraData,
                      }
                    : undefined,
                defaultOfficeEnabled: formData.defaultOfficeEnabled,
                defaultDesk: formData.defaultDesk,
                defaultFloor: formData.defaultFloor,
                defaultOfficeSpace: formData.defaultOfficeSpaceId
                    ? {
                          id: formData.defaultOfficeSpaceId.value,
                          name: formData.defaultOfficeSpaceId.label,
                      }
                    : undefined,
                preferredWorkType: selectedPreferredWorkplace ?? UserPreferredWorkTypeDto.Office,
            };

            if (!(await validateForm(request))) return;

            resetErrors();
            onNextClick(request);
        };

        const onRemoveDefaultOffice = () => {
            setShowDefaultOfficeForm(false);
            form.setFieldsValue({
                defaultOfficeEnabled: false,
                defaultFloor: undefined,
                defaultDesk: undefined,
                defaultOfficeId: undefined,
                defaultOfficeSpaceId: undefined,
            });
        };

        return (
            <div className="OnboardingWorkplaces OnboardingBase">
                <div className="form-wrapper">
                    <Form
                        layout="vertical"
                        className="padding-scroll container-scroll-actions-fixed"
                        form={form}
                    >
                        <div className="text-title-1-bold mb-20 centered pt-40">
                            {t('Onboarding.what_main_workplace')}
                        </div>

                        <div className="text-callout text-mid-contrast mb-20 centered subtitle">
                            {t('Onboarding.main_workplace_subtitle')}
                        </div>

                        <div className="preferred-workplace-container">
                            <div className="text-headline-bold mb-20">
                                {t('preferred_workplace')}
                            </div>
                            <div className="text-callout text-mid-contrast">
                                {t('Onboarding.preferred_workplace_explanation')}
                            </div>

                            <div className="workplace-toggle-container">
                                <div
                                    className={`workplace-type office selected-${
                                        selectedPreferredWorkplace ===
                                        UserPreferredWorkTypeDto.Office
                                    }`}
                                    onClick={() =>
                                        setSelectedPreferredWorkplace(
                                            UserPreferredWorkTypeDto.Office
                                        )
                                    }
                                >
                                    <Icon iconName="AppartementIcon" width={90} height={90} />
                                    <div className="radio-container office">
                                        <span>{t('office')}</span>
                                        <Radio
                                            checked={
                                                selectedPreferredWorkplace ===
                                                UserPreferredWorkTypeDto.Office
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`workplace-type remote selected-${
                                        selectedPreferredWorkplace ===
                                        UserPreferredWorkTypeDto.Remote
                                    }`}
                                    onClick={() =>
                                        setSelectedPreferredWorkplace(
                                            UserPreferredWorkTypeDto.Remote
                                        )
                                    }
                                >
                                    <Icon iconName="SimpleHouseIcon" width={90} height={90} />
                                    <div className="radio-container remote">
                                        <span>{t('remote')}</span>
                                        <Radio
                                            checked={
                                                selectedPreferredWorkplace ===
                                                UserPreferredWorkTypeDto.Remote
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <ValidatedFormItem
                                className="d-none"
                                label=""
                                name={'preferredWorkType'}
                            />
                        </div>

                        <div className="text-headline-bold mt-40 mb-20">{t('default_office')}</div>
                        <div className="text-callout text-mid-contrast">
                            {t('Onboarding.default_office_explanation')}
                        </div>

                        <div className="default-office-container mt-20">
                            {showDefaultOfficeForm ? (
                                <div className="default-office-form">
                                    <Row gutter={FORM_GUTTER}>
                                        <Col span={12}>
                                            <ValidatedFormItem
                                                errors={errors}
                                                name="defaultOfficeId"
                                                label={t('name')}
                                                required
                                            >
                                                <AsyncSingleSelect
                                                    {...officeSelectProps}
                                                    onChange={(
                                                        option?: SingleSelectCustomOption
                                                    ) => {
                                                        officeSelectProps.onChange(option);
                                                        spaceSelectProps.resetSearchResults();
                                                        spaceSelectProps.onChange(undefined);
                                                    }}
                                                    placeholder={t('choose_an_office')}
                                                />
                                            </ValidatedFormItem>
                                        </Col>

                                        <Col span={12}>
                                            <ValidatedFormItem
                                                name="address"
                                                label={t('building_address')}
                                            >
                                                <Input disabled />
                                            </ValidatedFormItem>
                                        </Col>
                                    </Row>

                                    {officeSelectProps.selected && (
                                        <>
                                            <Row gutter={FORM_GUTTER}>
                                                <Col span={24}>
                                                    <ValidatedFormItem
                                                        errors={errors}
                                                        name="defaultOfficeSpaceId"
                                                        label={t('Office.space')}
                                                    >
                                                        <AsyncSingleSelect
                                                            {...spaceSelectProps}
                                                            isClearable
                                                        />
                                                    </ValidatedFormItem>
                                                </Col>
                                            </Row>

                                            <Row gutter={FORM_GUTTER}>
                                                <Col span={12}>
                                                    <ValidatedFormItem
                                                        errors={errors}
                                                        name="defaultFloor"
                                                        label={t('floor')}
                                                    >
                                                        <Input />
                                                    </ValidatedFormItem>
                                                </Col>
                                                <Col span={12}>
                                                    <ValidatedFormItem
                                                        errors={errors}
                                                        name="defaultDesk"
                                                        label={t('Office.desk_number')}
                                                    >
                                                        <Input />
                                                    </ValidatedFormItem>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                    <Divider />
                                    <div className="remove-default-container">
                                        <Button
                                            className="remove-default-btn"
                                            text={t('remove')}
                                            type="light"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onRemoveDefaultOffice();
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="no-default-office cursor-pointer"
                                    onClick={() => {
                                        form.setFieldsValue({ defaultOfficeEnabled: true });
                                        setShowDefaultOfficeForm(true);
                                    }}
                                >
                                    <Button
                                        text={t('set_default_office')}
                                        type="link"
                                        width="hugged"
                                        leftCircleIcon="AddIcon"
                                        onClick={() => {
                                            form.setFieldsValue({ defaultOfficeEnabled: true });
                                            setShowDefaultOfficeForm(true);
                                        }}
                                    />
                                </div>
                            )}

                            <ValidatedFormItem
                                className="d-none"
                                label=""
                                name={'defaultOfficeEnabled'}
                            />
                        </div>
                    </Form>
                </div>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button text={t('previous')} type="tertiary" onClick={onPreviousClick} />
                        <Button text={t('next')} type="primary" onClick={() => handleNextClick()} />
                    </div>
                </div>
            </div>
        );
    }
);

export default OnboardingWorkplaces;
