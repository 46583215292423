import TargetArrowIcon from 'Components/icons/target-arrow-icon';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import React from 'react';
import AlarmClockIcon from 'Components/icons/alarm-clock-icon';
import CalendarIcon from 'Components/icons/calendar-icon';
import BriefcaseIcon from 'Components/icons/briefcase-icon';
import {
    ObjectivesScore,
    PolicyBundleObjectivesDetails,
    PolicyInfoStoreObjectiveStats,
} from 'Stores/PolicyStore';
import { theme } from 'Style/theme';
import CircleCheckmarkIcon from 'Components/icons/circle-checkmark-icon';
import { PolicyBundleObjectivesDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesDto';
import { PolicyBundleObjectivesCoreHoursPeriodDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesCoreHoursPeriodDto';
import { PolicyBundleObjectivesCoreHoursGroupDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesCoreHoursGroupDto';
import { UserObjectivesStatusDetailsDto } from 'Api/Features/Users/Dtos/UserObjectivesStatusDetailsDto';
import { GetUserObjectivesStatusWeekValuesDto } from 'Api/Features/Users/Dtos/GetUserObjectivesStatusWeekValuesDto';
import {
    getTotalEventOverlappingCoreHours,
    getTotalHoursInEvents,
    getTotalHoursInEventsType,
} from './CalendarUtils';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import moment from 'moment';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';

export const objectiveLabeltoTitle = (
    label: keyof GetUserObjectivesStatusWeekValuesDto,
    t: TFunction
): string => {
    if (label === 'workHours') return t('Policy.work_hours_per_week');
    if (label === 'availabilityHours') return t('Policy.off_hours_availability_per_week');
    if (label === 'days') return t('Policy.number_days_week');
    if (label === 'officeDays') return t('Policy.days_at_the_office');
    if (label === 'coreHours') return t('Policy.corehours_per_week');
    return '';
};

export const objectiveLabelToUnit = (
    label: keyof GetUserObjectivesStatusWeekValuesDto,
    t: TFunction
): string => {
    if (label === 'workHours') return t('hours');
    if (label === 'availabilityHours') return t('hours');
    if (label === 'coreHours') return t('hours');
    if (label === 'days') return t('days');
    if (label === 'officeDays') return t('days');
    if (label === 'coreHours') return t('hours');
    return '';
};

export const objectiveLabelToDescription = (
    label: keyof GetUserObjectivesStatusWeekValuesDto,
    t: TFunction
): string => {
    if (label === 'workHours') return t('Policy.weekly_objectives_work_hours_week_details');
    if (label === 'availabilityHours') return t('Policy.weekly_objectives_off_hours_week_details');
    if (label === 'days') return t('Policy.weekly_objectives_days_week_details');
    if (label === 'officeDays') return t('Policy.weekly_objectives_days_office_details');
    if (label === 'coreHours') return t('Policy.weekly_objectives_days_corehours');
    return '';
};

export const getObjectiveIcon = (
    policyKey: keyof GetUserObjectivesStatusWeekValuesDto | undefined,
    size: number,
    fill: string,
    isSuccess?: boolean
): ReactNode => {
    if (!policyKey) return <TargetArrowIcon width={size} height={size} fill={fill} />;
    if (isSuccess) return <CircleCheckmarkIcon width={size} height={size} fill={fill} />;

    if (policyKey == 'workHours' || policyKey == 'coreHours')
        return <AlarmClockIcon width={size} height={size} fill={fill} />;
    if (policyKey == 'days') return <CalendarIcon width={size} height={size} fill={fill} />;
    if (policyKey == 'officeDays') return <CalendarIcon width={size} height={size} fill={fill} />;
    if (policyKey == 'availabilityHours')
        return <AlarmClockIcon width={size} height={size} fill={fill} />;
    if (policyKey == 'coreHours') return <AlarmClockIcon width={size} height={size} fill={fill} />;
    return <BriefcaseIcon width={size} height={size} fill={fill} />;
};

export const getPolicyCoreHoursTotalHours = (
    bundleObjectives: PolicyBundleObjectivesDto | null | undefined
): number => {
    const getTotalCoreHoursInPeriods = (periods: PolicyBundleObjectivesCoreHoursPeriodDto[]) => {
        const MULTIPL = 10000; // '093000' (avant replace '09:30:00') -> transformé en : 09.3
        const hourFractionMap = { '15': '25', '30': '50', '45': '75', '00': '00' }; //les fractions d'heure sont .25 .5 .75

        return periods
            .map((period: PolicyBundleObjectivesCoreHoursPeriodDto) => {
                if (period.endTime && period.startTime) {
                    const endSplit = period.endTime.split(':');
                    const startSplit = period.startTime.split(':');

                    const endTime =
                        Number(`${endSplit[0]}${hourFractionMap[endSplit[1]]}${endSplit[2]}`) /
                        MULTIPL;
                    const startTime =
                        Number(
                            `${startSplit[0]}${hourFractionMap[startSplit[1]]}${startSplit[2]}`
                        ) / MULTIPL;

                    return endTime - startTime;
                }
                return 0;
            })
            .reduce((partialSum: any, a: any) => partialSum + a, 0);
    };

    if (!bundleObjectives?.coreHoursGroups) return 0;
    const arrayTimeRange = bundleObjectives?.coreHoursGroups.map(
        (coreHourGroup: PolicyBundleObjectivesCoreHoursGroupDto | null) => {
            return (
                getTotalCoreHoursInPeriods(
                    coreHourGroup?.periods?.filter((x) => x !== null).map((x) => x!) ?? []
                ) * (coreHourGroup?.daysOfWeek?.length ?? 1)
            );
        }
    );

    return arrayTimeRange.reduce((partialSum: any, a: any) => partialSum + a, 0);
};

export const getPolicyBundleObjectivesDetails = (
    responseApi: UserObjectivesStatusDetailsDto
): PolicyBundleObjectivesDetails[] => {
    const arr: PolicyBundleObjectivesDetails[] = [];
    const bundleObjectives = responseApi.policyBundleObjectives;
    const bundleObjectivesCompleted = responseApi.values;

    if (bundleObjectives?.availabilityHoursPerWeek)
        arr.push({
            completed: bundleObjectivesCompleted?.availabilityHours || 0,
            total: bundleObjectives.availabilityHoursPerWeek,
            label: 'availabilityHours',
        });
    if (bundleObjectives?.daysPerWeek)
        arr.push({
            completed: bundleObjectivesCompleted?.days || 0,
            total: bundleObjectives.daysPerWeek,
            label: 'days',
        });
    if (bundleObjectives?.officeDaysPerWeek)
        arr.push({
            completed: bundleObjectivesCompleted?.officeDays || 0,
            total: bundleObjectives.officeDaysPerWeek,
            label: 'officeDays',
        });
    if (bundleObjectives?.workHoursPerWeek)
        arr.push({
            completed: bundleObjectivesCompleted?.workHours || 0,
            total: bundleObjectives.workHoursPerWeek,
            label: 'workHours',
        });
    if (bundleObjectives?.coreHoursGroups)
        arr.push({
            completed: bundleObjectivesCompleted?.coreHours || 0,
            total: getPolicyCoreHoursTotalHours(bundleObjectives),
            label: 'coreHours',
        });
    return arr;
};

export const getObjectivesScore = (
    responseApi: UserObjectivesStatusDetailsDto
): ObjectivesScore => {
    let completed = 0;
    let totalActiveObjectives = 0;

    const calculateCompleted = (value: number, objective: number) => {
        return value >= objective ? completed++ : undefined;
    };

    if (responseApi.policyBundleObjectives && responseApi.values) {
        if (responseApi.policyBundleObjectives.availabilityHoursPerWeek) {
            calculateCompleted(
                responseApi.values.availabilityHours ?? 0,
                responseApi.policyBundleObjectives.availabilityHoursPerWeek
            );
        }

        if (responseApi.policyBundleObjectives.daysPerWeek) {
            calculateCompleted(
                responseApi.values.days ?? 0,
                responseApi.policyBundleObjectives.daysPerWeek
            );
        }

        if (responseApi.policyBundleObjectives.officeDaysPerWeek) {
            calculateCompleted(
                responseApi.values.officeDays ?? 0,
                responseApi.policyBundleObjectives.officeDaysPerWeek
            );
        }

        if (responseApi.policyBundleObjectives.workHoursPerWeek) {
            calculateCompleted(
                responseApi.values.workHours ?? 0,
                responseApi.policyBundleObjectives.workHoursPerWeek
            );
        }

        if (responseApi.policyBundleObjectives.coreHoursGroups) {
            calculateCompleted(
                responseApi.values.coreHours ?? 0,
                getPolicyCoreHoursTotalHours(responseApi.policyBundleObjectives)
            );
        }

        totalActiveObjectives = Object.values(responseApi.policyBundleObjectives).filter(
            (objective) => objective !== null
        ).length;
    }

    return {
        completed,
        total: totalActiveObjectives,
    };
};

export const objectivePopoverContent = (
    objective: PolicyBundleObjectivesDetails,
    t: TFunction
): ReactNode => {
    return (
        <div className="CustomToolBarTop-tooltip-inner">
            <div className="title text-mid-contrast text-caption-1-bold">
                {objectiveLabeltoTitle(objective.label, t)}
            </div>

            <div className="description text-high-contrast text-caption-1">
                {objectiveLabelToDescription(objective.label, t)}
            </div>

            <div className="recommended">
                <span className="text-caption-3 text-mid-contrast">{t('Policy.recommended')}</span>

                {getObjectiveIcon(objective.label, 22, theme['primary-mid-contrast'])}

                <span className="text-title-2-bold value">{objective.total}</span>

                <span className="text-footnote text-mid-contrast lowercase">
                    {objectiveLabelToUnit(objective.label, t)}/{t('week')}
                </span>
            </div>
        </div>
    );
};

export interface HoursAndObjectivesFromEvents {
    workHours: number;
    offHours: number;
    daysPerWeek: number;
    officeDaysPerWeek: number;
    objectivesWorkHoursPerWeek: number;
    objectivesAvailabilityHoursPerWeek: number;
    objectivesDaysPerWeek: number;
    objectivesOfficeDaysPerWeek: number;
}

export const getHoursAndObjectivesFromEvents = (
    events: ScheduleEventDto[],
    objectives: PolicyBundleObjectivesDto
): HoursAndObjectivesFromEvents => {
    const totalWorkHours = parseFloat(
        getTotalHoursInEvents(events, getTotalHoursInEventsType.workHoursPerWeek)
    );
    const totalOffHours = parseFloat(
        getTotalHoursInEvents(events, getTotalHoursInEventsType.availabilityHoursPerWeek)
    );
    const totalDaysPerWeek = parseFloat(
        getTotalHoursInEvents(events, getTotalHoursInEventsType.daysPerWeek)
    );
    const totalOfficeDaysPerWeek = parseFloat(
        getTotalHoursInEvents(events, getTotalHoursInEventsType.officeDaysPerWeek)
    );
    const objectivesWorkHoursPerWeek = objectives.workHoursPerWeek ?? 0;
    const objectivesAvailabilityHoursPerWeek = objectives.availabilityHoursPerWeek ?? 0;
    const objectivesDaysPerWeek = objectives.daysPerWeek ?? 0;
    const objectivesOfficeDaysPerWeek = objectives.officeDaysPerWeek ?? 0;

    return {
        workHours: totalWorkHours,
        offHours: totalOffHours,
        daysPerWeek: totalDaysPerWeek,
        officeDaysPerWeek: totalOfficeDaysPerWeek,
        objectivesWorkHoursPerWeek: objectivesWorkHoursPerWeek,
        objectivesAvailabilityHoursPerWeek: objectivesAvailabilityHoursPerWeek,
        objectivesDaysPerWeek: objectivesDaysPerWeek,
        objectivesOfficeDaysPerWeek: objectivesOfficeDaysPerWeek,
    };
};

/**
 * For instances where we need to calculate objective stats from events, frontend side.
 * Events should be in UTC
 */
export const calculateObjectiveProgressFromDefaultScheduleEvents = (
    events: ScheduleEventDto[],
    policyBundle?: PolicyBundleObjectivesDto | null
): PolicyInfoStoreObjectiveStats | undefined => {
    if (policyBundle && Object.values(policyBundle ?? {}).some((x) => x !== null)) {
        const eventStats = getEventsPolicyObjectiveValues(events, policyBundle);

        const objectiveStatus: UserObjectivesStatusDetailsDto = {
            policyBundleObjectives: policyBundle,
            values: {
                availabilityHours: eventStats.availabilityHours,
                days: eventStats.days,
                officeDays: eventStats.officeDays,
                workHours: eventStats.workHours,
                coreHours: eventStats.coreHours,
            },
        };

        const objectiveDetails = getPolicyBundleObjectivesDetails(objectiveStatus);

        return {
            totalScore: getObjectivesScore(objectiveStatus),
            objectives: objectiveStatus,
            policyBundleObjectivesDetails: objectiveDetails,
        };
    }
    return undefined;
};

/**
 * Used for calculateObjectiveProgress
 */
const getEventsPolicyObjectiveValues = (
    events: ScheduleEventDto[],
    policy: PolicyBundleObjectivesDto
): {
    availabilityHours: number;
    days: number;
    officeDays: number;
    workHours: number;
    coreHours: number;
} => {
    let availabilityHours = 0;
    let workHours = 0;

    let days = 0;
    let officeDays = 0;

    let coreHours = 0;

    workHours = parseFloat(
        getTotalHoursInEvents(events, getTotalHoursInEventsType.workHoursPerWeek)
    );
    availabilityHours = parseFloat(
        getTotalHoursInEvents(events, getTotalHoursInEventsType.availabilityHoursPerWeek)
    );

    coreHours = getTotalEventOverlappingCoreHours(events, policy?.coreHoursGroups);

    if (policy?.daysPerWeek || policy?.officeDaysPerWeek) {
        const workEvents = events.filter((event) => event.type === EventTypeDto.Work);
        let lastWorkDay = 0;

        workEvents.forEach((event) => {
            const workDay = moment.utc(event.startTime).day();
            if (lastWorkDay !== workDay) {
                lastWorkDay = workDay;
                days++;
                if (event.workType === WorkTypeDto.Office) officeDays++;
            }
        });
    }
    return {
        availabilityHours,
        days,
        officeDays,
        workHours,
        coreHours,
    };
};
