import React, { ReactNode, useEffect, useState } from 'react';
import './space-viewer-desk.less';
import { PeopleFillIcon, PersonFillIcon, Star } from 'Components/icons';
import { theme } from 'Style/theme';
import { ImgSize, img } from 'Utils/ImgUtils';
import { Tooltip } from 'react-tooltip';
import Skeleton from 'Components/skeleton';
import { deskTooltipSkeletonShape } from './space-viewer-skeleton-shapes';
import { useService } from 'Hooks';
import { ScheduleService } from 'Services/ScheduleService';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE, FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import { OfficeSpaceOccupantDto } from 'Api/Features/Offices/Dtos/OfficeSpaceOccupantDto';
import { mergeStrings } from 'Utils/TextUtils';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { useTranslation } from 'react-i18next';
import { SpaceViewerScheduleType } from './space-viewer-modal-content';
import { GetUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserDefaultScheduleRequestDto';

export enum SpaceViewerDeskType {
    empty = 'empty',
    occupied = 'occupied',
    teamMember = 'teamMember',
    favourite = 'favourite',
    currentUser = 'currentUser',
}

export enum ImgPosition {
    top = 'top',
    bottom = 'bottom',
}

interface SpaceViewerDeskProps {
    type: SpaceViewerDeskType;
    imgPosition: ImgPosition;
    imgUrl?: string | null;
    tooltipId: string;
    occupant?: OfficeSpaceOccupantDto;
    timeRange: {
        startTime: string;
        endTime: string;
    };
    timezone?: string | null;
    spaceId?: string;
    language: SupportedLanguage;
    scheduleType: SpaceViewerScheduleType;
}

interface tooltipInfo {
    name: string;
    relationship: string;
    timePeriods: string;
    error?: boolean;
}

const SpaceViewerDesk: React.FC<SpaceViewerDeskProps> = ({
    type,
    imgPosition,
    imgUrl,
    tooltipId,
    occupant,
    timeRange,
    timezone,
    spaceId,
    language,
    scheduleType,
}) => {
    const { t } = useTranslation();
    const [icon, setIcon] = useState<ReactNode | undefined>(undefined);
    const [tooltipInfo, setTooltipInfo] = useState<tooltipInfo | undefined>(undefined);
    const scheduleService = useService(ScheduleService);

    useEffect(() => {
        switch (type) {
            case SpaceViewerDeskType.currentUser:
                {
                    setIcon(
                        <PersonFillIcon
                            width={16}
                            height={16}
                            fill={theme['primary-mid-contrast']}
                        />
                    );
                }
                break;
            case SpaceViewerDeskType.teamMember:
                {
                    setIcon(
                        <PeopleFillIcon width={16} height={16} fill={theme['layout-mid-accent']} />
                    );
                }
                break;
            case SpaceViewerDeskType.favourite:
                {
                    setIcon(<Star width={16} height={16} fill={theme['warning-mid-contrast']} />);
                }
                break;
        }
    }, [type]);

    const fetchOccupantPeriods = async () => {
        try {
            let response;

            if (scheduleType === SpaceViewerScheduleType.schedule) {
                const request: GetUserScheduleRequestDto = {
                    startDate: moment(timeRange.startTime).format(FORMAT_YEAR_MONTH_DAY),
                    endDate: moment(timeRange.startTime).format(FORMAT_YEAR_MONTH_DAY),
                    timeZone: timezone,
                };
                response = await scheduleService.getUserSchedule(occupant?.id ?? '', request);
            } else {
                const request: GetUserDefaultScheduleRequestDto = {
                    week: moment(timeRange.startTime).format(FORMAT_YEAR_MONTH_DAY),
                };
                response = await scheduleService.getUserDefaultSchedule(
                    occupant?.id ?? '',
                    request
                );
            }
            const events = response?.events
                ?.filter((event) => event?.officeSpace?.id === spaceId)
                .map((event) => {
                    const start =
                        scheduleType === SpaceViewerScheduleType.schedule
                            ? moment(event?.startTime)
                            : moment.utc(event?.startTime);
                    const end =
                        scheduleType === SpaceViewerScheduleType.schedule
                            ? moment(event?.endTime)
                            : moment.utc(event?.endTime);

                    return `${start.format(
                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[language]
                    )} - ${end.format(FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[language])}`;
                });

            if (events) {
                setTooltipInfo({
                    name: occupant?.firstName + ' ' + occupant?.lastName,
                    relationship:
                        type === SpaceViewerDeskType.currentUser
                            ? t('me')
                            : type === SpaceViewerDeskType.favourite
                            ? t('favorite')
                            : type === SpaceViewerDeskType.teamMember
                            ? t('team_member')
                            : t('coworker'),
                    timePeriods: mergeStrings(events, ' • ') ?? '',
                });
            }
        } catch (e: any) {
            setTooltipInfo({
                error: true,
                name: '',
                relationship: '',
                timePeriods: '',
            });
        }
    };

    return (
        <div
            className={`SpaceViewerDesk ${type} img-${imgPosition}`}
            data-tooltip-id={occupant ? tooltipId : undefined}
        >
            <div className={`img-container ${imgUrl ? '' : 'no-img'}`}>
                {type !== SpaceViewerDeskType.empty ? (
                    imgUrl ? (
                        <img className="img" src={img(imgUrl, ImgSize.s)} />
                    ) : (
                        <PersonFillIcon width={16} height={16} fill={theme.white} />
                    )
                ) : undefined}
            </div>
            <div className="icon">{icon}</div>

            <Tooltip
                id={tooltipId}
                delayShow={500}
                afterShow={() => fetchOccupantPeriods()}
                offset={25}
                place={imgPosition}
            >
                <Skeleton
                    isLoaded={tooltipInfo !== undefined}
                    placeholder={deskTooltipSkeletonShape}
                    defaultPadding={false}
                >
                    <div className="tooltip-content">
                        {tooltipInfo?.error ? (
                            <>{t('generic_error')}</>
                        ) : (
                            <>
                                <div className="top">
                                    <div className="name">{tooltipInfo?.name}</div>
                                    <div className="dot">•</div>
                                    <div className="relationship">{tooltipInfo?.relationship}</div>
                                </div>

                                <div className="timePeriods">{tooltipInfo?.timePeriods}</div>
                            </>
                        )}
                    </div>
                </Skeleton>
            </Tooltip>
        </div>
    );
};

export default SpaceViewerDesk;
