import React, { FunctionComponent } from 'react';
import './custom-radio-button.less';
import { observer } from 'mobx-react';
import { Radio } from 'antd';

interface CustomRadioButtonProps {
    label: string;
    value: string;
    selected: boolean;
    onSelect: (value: string) => void;
}
const CustomRadioButton: FunctionComponent<CustomRadioButtonProps> = observer(
    ({ label, value, selected, onSelect }) => {
        return (
            <div
                className={`CustomRadioButton ${selected ? 'selected' : ''}`}
                onClick={() => onSelect(value)}
            >
                <span>{label}</span>
                <Radio checked={selected} />
            </div>
        );
    }
);
export default CustomRadioButton;
