import { inject } from 'aurelia-dependency-injection';
import { observable, action } from 'mobx';
import LanguageStore, { SupportedLanguage } from './LanguageStore';

interface ToastConfig {
    type: 'info' | 'success' | 'error' | 'warning';
    message?: string;
    messageKey?: string;
}

@inject(LanguageStore)
class ToastStore {
    constructor(private readonly languageStore: LanguageStore) {}

    @observable currentToast: ToastConfig | null = null;

    @action
    genericError(): void {
        this.currentToast = {
            type: 'error',
            messageKey:
                this.languageStore.currentLanguage === SupportedLanguage.EN
                    ? 'An unexpected error occurred.'
                    : 'Une erreur inattendue est survenue.',
        };
    }

    @action
    serverError(): void {
        this.currentToast = {
            type: 'error',
            messageKey:
                this.languageStore.currentLanguage === SupportedLanguage.EN
                    ? 'An unexpected server error occurred.'
                    : 'Une erreur de serveur inattendue est survenue.',
        };
    }

    @action
    toast(config: ToastConfig): void {
        this.currentToast = config;
    }
}

export default ToastStore;
