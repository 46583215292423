import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PersonFillIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M14.0088 13.5752C15.9248 13.5752 17.5508 11.8701 17.5508 9.66406C17.5508 7.51074 15.916 5.8584 14.0088 5.8584C12.0928 5.8584 10.4492 7.53711 10.458 9.68164C10.458 11.8701 12.084 13.5752 14.0088 13.5752ZM8.52441 21.749H19.4756C20.9258 21.749 21.4268 21.3096 21.4268 20.501C21.4268 18.2422 18.5615 15.1309 14 15.1309C9.44727 15.1309 6.57324 18.2422 6.57324 20.501C6.57324 21.3096 7.07422 21.749 8.52441 21.749Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PersonFillIcon;
