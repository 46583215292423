import { inject } from 'aurelia-dependency-injection';
import { Environment } from 'Misc/Environment';
import {
    DASHBOARD_URL,
    KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER,
    KEY_SESSION_STORAGE_AZURE_TOKEN_ENDPOINT,
} from 'Models/Constants';
import { AuthenticationStore } from 'Stores';
import LanguageStore, { SupportedLanguage } from 'Stores/LanguageStore';
import { AuthConfig, AuthorizationConfigService } from './AuthorizationConfigService';
import { UserExternalIdentityIssuerDto } from 'Api/Features/Users/Dtos/UserExternalIdentityIssuerDto';

@inject(AuthenticationStore, AuthorizationConfigService, Environment, LanguageStore)
export class AzureAdAuthenticationService {
    constructor(
        private readonly authenticationStore: AuthenticationStore,
        private readonly authorizationConfigService: AuthorizationConfigService,
        private readonly environment: Environment,
        private readonly languageStore: LanguageStore
    ) {}

    /**
     * Runs our "Sign-in" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     */
    public async runSignInFlow(lang: SupportedLanguage): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem(KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER, codeVerifier);
        window.sessionStorage.setItem(
            KEY_SESSION_STORAGE_AZURE_TOKEN_ENDPOINT,
            parameters.tokenEndpoint
        );

        const azureADStateId = this.generateRandomString(10);
        this.authenticationStore.setAzureADStateId(azureADStateId);

        const redirectUri = `${window.location.protocol}//${window.location.host}/actions/signin-callback`;
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            state: azureADStateId,
            ui_locales: lang,
        });

        // Pass the current tenant ID in the query string for the Azure page. This way if Azure calls our API, we can determine from which tenant the operation was initiated.
        if (this.environment.REACT_APP_TENANT_ID) {
            args.append('flexyTenantId', this.environment.REACT_APP_TENANT_ID);
        }

        // Go to Azure URL.
        window.location = (parameters.authorizeEndpoint + '/?' + args) as any;
    }

    /**
     * Get a new token with the refresh token
     */
    public async refreshToken(token: string): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                const response = xhr.response;
                if (xhr.status == 200) {
                    this.authenticationStore.setSession(
                        response.access_token,
                        response.refresh_token
                    );
                    resolve();
                } else {
                    reject();
                    this.authenticationStore.setReturnUrl(
                        window.location.pathname + window.location.search
                    );
                    this.runSignInFlow(this.languageStore.currentLanguage);
                }
            };
            xhr.responseType = 'json';
            xhr.open('POST', parameters.tokenEndpoint, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send(
                new URLSearchParams({
                    client_id: parameters.clientId,
                    grant_type: 'refresh_token',
                    redirect_uri: window.location.protocol + '//' + window.location.host,
                    scope: parameters.scopes,
                    refresh_token: token,
                })
            );
        });
    }

    /**
     * Asks Azure to logout the current user, if necessary.
     */
    public async signOut(): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        const args = new URLSearchParams({
            // After logout with Azure, redirect to web app root.
            post_logout_redirect_uri: window.location.protocol + '//' + window.location.host,
        });

        // Go to Azure URL.
        window.location = (parameters.logoutEndpoint + '/?' + args) as any;
    }

    /**
     * Runs our "Reset password" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     */
    public async runResetPasswordFlow(lang: SupportedLanguage): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem(KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER, codeVerifier);

        const redirectUri = `${window.location.protocol}//${window.location.host}/actions/reset-password-callback`;
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            ui_locales: lang,
        });

        // Pass the current tenant ID in the query string for the Azure page. This way if Azure calls our API, we can determine from which tenant the operation was initiated.
        if (this.environment.REACT_APP_TENANT_ID) {
            args.append('flexyTenantId', this.environment.REACT_APP_TENANT_ID);
        }

        // Go to Azure URL.
        window.location = (parameters.resetPasswordEndpoint + '/?' + args) as any;
    }

    /**
     * Runs our "Change password" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     */
    public async runChangePasswordFlow(lang: SupportedLanguage, userEmail: string): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem(KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER, codeVerifier);

        const redirectUri = `${window.location.protocol}//${window.location.host}/actions/change-password-callback`;
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            ui_locales: lang,
            login_hint: userEmail,
        });

        // Go to Azure URL.
        window.location = (parameters.changePasswordEndpoint + '/?' + args) as any;
    }

    public async runLinkAccountFlow(
        lang: SupportedLanguage,
        userEmail: string,
        issuer: UserExternalIdentityIssuerDto
    ): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem(KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER, codeVerifier);

        // TODO: Do not hardcode
        let option = '';
        if (issuer === UserExternalIdentityIssuerDto.Google) {
            option = 'google';
        } else if (issuer === UserExternalIdentityIssuerDto.OktaAppcom) {
            option = 'okta-appcom';
        }

        // TODO: add specific callback logic for link account
        const redirectUri = `${window.location.protocol}//${window.location.host}/actions/change-password-callback`;
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            ui_locales: lang,
            login_hint: userEmail,
            option: option,
        });

        // Go to Azure URL.
        window.location = (parameters.linkAccountEndpoint + '/?' + args) as any;
    }

    /**
     * Runs our "Confirm account" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     *
     * @param email Email address of the user account to confirm.
     * @param token Account confirmation token (usually sent in an email).
     */
    public async runConfirmAccountFlow(
        email: string,
        token: string,
        lang: SupportedLanguage
    ): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem(KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER, codeVerifier);

        const redirectUri = `${window.location.protocol}//${window.location.host}/actions/confirm-account-callback`;
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            email: email,
            token: token,
            ui_locales: lang,
        });

        // Pass the current tenant ID in the query string for the Azure page. This way if Azure calls our API, we can determine from which tenant the operation was initiated.
        if (this.environment.REACT_APP_TENANT_ID) {
            args.append('flexyTenantId', this.environment.REACT_APP_TENANT_ID);
        }

        // Go to Azure URL.
        window.location = (parameters.confirmAccountEndpoint + '/?' + args) as any;
    }

    // Check if we were just redirected from the AD server after authorize.
    public async handleAuthorizeCallback(): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        if (window.location.search) {
            const args = new URLSearchParams(window.location.search);
            const code = args.get('code');
            const state = args.get('state');
            if (code) {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'json';
                xhr.open('POST', parameters.tokenEndpoint, true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.send(
                    new URLSearchParams({
                        client_id: parameters.clientId,
                        code_verifier:
                            window.sessionStorage.getItem(
                                KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER
                            ) ?? '',
                        grant_type: 'authorization_code',
                        redirect_uri: `${window.location.protocol}//${window.location.host}`,
                        code: code,
                        scope: parameters.scopes,
                        state: state ?? '',
                    })
                );

                xhr.onload = () => {
                    const response = xhr.response;
                    if (xhr.status == 200) {
                        this.authenticationStore.setSession(
                            response.access_token,
                            response.refresh_token
                        );
                        if (state && state === this.authenticationStore.azureADStateId) {
                            this.authenticationStore.clearAzureADStateId();
                            //consume returnUrl
                            const returnUrl =
                                this.authenticationStore.returnUrl ?? (DASHBOARD_URL as any);
                            this.authenticationStore.clearReturnUrl();

                            window.location = returnUrl;
                        }
                    } else {
                        //something went wrong getting the token. We are now connected on azure side, but cannot get a token on our side.
                        window.location =
                            `${window.location.protocol}//${window.location.host}/actions/signin-failure` as any;
                    }
                };
            }
        }
        return;
    }

    private async generateCodeChallenge(codeVerifier): Promise<string> {
        const digest = await crypto.subtle.digest(
            'SHA-256',
            new TextEncoder().encode(codeVerifier)
        );
        return btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
    }

    private generateRandomString(length): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
}
