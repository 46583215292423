import Button from 'Components/button';
import { useTranslation } from 'react-i18next';
import './index.less';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { OfficeService } from 'Services/OfficeService';
import { useEffect, useState } from 'react';
import { MainWorkplaceIcon, MapPinWithCircleIcon, SpaceViewerIcon } from 'Components/icons';
import { observer } from 'mobx-react';
import { theme } from 'Style/theme';
import { GetOfficeSpaceOccupancyRequestPeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestPeriodDto';
import { mergeStrings } from 'Utils/TextUtils';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { EMPTY_GUID } from 'Models/Constants';
import SpaceViewerModal from 'Components/space-viewer-modal/space-viewer-modal';

interface SpaceWithCapacityExtended extends OfficeSpaceDto {
    currentCapacity?: { occupancy: number; capacity: number };
}

export interface OfficeDropdownModalContentProps {
    makeHybridButton?: string;
    onSubmit: (
        office?: { name?: string; id?: string },
        officeSpace?: { name?: string; id?: string; occupancy?: number; capacity?: number }
    ) => void;
    minWidth?: number;
    capacityFetchParams: {
        startTime: string;
        endTime: string;
    };
    hasSpaceViewer?: boolean;
    defaultSelectedOffice?: SingleSelectCustomOption;
    defaultSelectedSpace?: SingleSelectCustomOption;
}

const OfficeDropdownModalContent: React.FunctionComponent<OfficeDropdownModalContentProps> =
    observer(
        ({
            makeHybridButton,
            onSubmit,
            minWidth = 0,
            capacityFetchParams,
            hasSpaceViewer,
            defaultSelectedOffice,
            defaultSelectedSpace,
        }) => {
            const { t } = useTranslation();
            const { userStore, toastStore } = useStores();
            const style = { minWidth: `${minWidth}px` };
            const officeService = useService(OfficeService);

            const [spaceViewerModalOpen, setSpaceViewerModalOpen] = useState(false);

            const [selectedOffice, setSelectedOffice] = useState<
                SingleSelectCustomOption | undefined
            >(defaultSelectedOffice);
            const [defaultOfficeIsDisabled, setDefaultOfficeIsDisabled] = useState(true); //disabled by default in case slow network and trying to click faster

            const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
                fetchProps: {
                    fetchFunction: async (request: GetOfficesRequestDto) =>
                        await officeService.getOffices(request),
                },
                entityToSingleSelectCustomOption: (office: OfficeDto) =>
                    ({
                        value: office.id,
                        label: office.name,
                        showLeftIconSelected: true,
                        leftIcon:
                            userStore.userInfo?.defaultOffice?.id === office.id ? (
                                <MainWorkplaceIcon
                                    width={24}
                                    height={24}
                                    fill={theme['accent-mid-contrast']}
                                />
                            ) : (
                                <MapPinWithCircleIcon width={24} height={24} />
                            ),
                    } as SingleSelectCustomOption),
                extraMandatoryOptions: userStore.userInfo?.defaultOffice
                    ? {
                          unshift: [
                              {
                                  label: t('OfficeOccupancyCard.use_default_office'),
                                  value: 'useDefaultOffice',
                                  content: {
                                      node: userStore.userInfo.defaultOfficeSpace
                                          ? `${mergeStrings(
                                                [
                                                    userStore.userInfo.defaultOfficeSpace?.name,
                                                    userStore.userInfo.defaultFloor,
                                                    userStore.userInfo.defaultDesk,
                                                ],
                                                ' - '
                                            )}`
                                          : '',
                                  },
                                  isDisabled: defaultOfficeIsDisabled,
                              } as SingleSelectCustomOption,
                              {
                                  label: t('OfficeOccupancyCard.no_office'),
                                  value: 'noOffice',
                              } as SingleSelectCustomOption,
                          ],
                      }
                    : undefined,
                defaultSelectedOption: defaultSelectedOffice,
            });

            const [spacesWithCapacity, setSpacesWithCapacity] = useState<
                SpaceWithCapacityExtended[]
            >([]);
            const [spacesWithCapacityOptions, setSpacesWithCapacityOptions] = useState<
                SingleSelectCustomOption[]
            >([]);

            const { asyncSingleSelectProps: spaceSelectProps } = useAsyncSingleSelectProps({
                fetchProps: {
                    fetchFunction: async (request: GetOfficeSpacesRequestDto) =>
                        await officeService.getOfficeSpaces(request),
                    fetchFunctionExtraParams: { officeIds: selectedOffice?.value },
                },
                entityToSingleSelectCustomOption: (space: OfficeSpaceDto) =>
                    ({
                        value: space.id,
                        label: space.name,
                    } as SingleSelectCustomOption),
                extraMandatoryOptions: {
                    unshift: [
                        {
                            label: t('OfficeOccupancyCard.no_space'),
                            value: 'noSpace',
                        } as SingleSelectCustomOption,
                    ],
                },
                defaultSelectedOption: defaultSelectedSpace,
            });

            useEffect(() => {
                //when fetch from space select is done, fetch the capacity for those spaces
                if (spaceSelectProps.options) {
                    fetchSpaceCapacities(
                        spaceSelectProps.options.map(
                            (option) =>
                                ({
                                    id: option.value,
                                    name: option.label,
                                } as OfficeSpaceDto)
                        )
                    );
                }
            }, [spaceSelectProps.options]);

            const fetchSpaceCapacities = async (spaces: OfficeSpaceDto[]): Promise<void> => {
                try {
                    const getOfficeSpaceOccupancyRequest: GetOfficeSpaceOccupancyRequestPeriodDto[] =
                        [];

                    spaces
                        .filter((space) => space.id !== 'noSpace')
                        .forEach((space) => {
                            if (space?.id) {
                                getOfficeSpaceOccupancyRequest.push({
                                    officeSpaceId: space.id,
                                    startTime: capacityFetchParams.startTime,
                                    endTime: capacityFetchParams.endTime,
                                });
                            }
                        });
                    const getOfficeSpaceOccupancyResponse =
                        await officeService.getOfficeSpaceOccupancy({
                            periods: getOfficeSpaceOccupancyRequest,
                            timeZone: userStore?.userInfo?.timeZone,
                        });
                    const newWorkplacesWithCapacity = spaces.map((space) => {
                        if (space.id) {
                            const period = getOfficeSpaceOccupancyResponse.find(
                                (period) => period.officeSpace?.id === space?.id
                            );
                            return {
                                ...space,
                                currentCapacity: {
                                    occupancy: period?.occupancy ?? 0,
                                    capacity: period?.officeSpace?.capacity ?? 0,
                                },
                            };
                        } else {
                            return { ...space };
                        }
                    });
                    setSpacesWithCapacity([...newWorkplacesWithCapacity]);
                } catch (e: any) {
                    if (!e.treated) {
                        toastStore.genericError();
                    }
                }
            };

            useEffect(() => {
                //once the capacities for the spaces have been fetched make new options for the space select
                setSpacesWithCapacityOptions(
                    spacesWithCapacity.map((space) => {
                        return {
                            label: space.name,
                            value: space.id,
                            content:
                                space.id !== 'noSpace' && space.currentCapacity
                                    ? {
                                          node: (
                                              <div>
                                                  {t('TimePeriodModal.occupancy_period')}:{' '}
                                                  {space.currentCapacity?.occupancy}/
                                                  {space.currentCapacity?.capacity}
                                              </div>
                                          ),
                                          className: 'text-caption-1',
                                          showSelected: true,
                                      }
                                    : undefined,
                            extraData: space,
                        } as SingleSelectCustomOption;
                    })
                );
            }, [spacesWithCapacity]);

            const handleUseDefaultOffice = async () => {
                if (userStore.userInfo?.defaultOffice) {
                    let officeSpaceOccupancyResponse:
                        | GetOfficeSpaceOccupancyResponsePeriodDto[]
                        | undefined = undefined;

                    //when selecting default office, we dont necessarily have the capacity info for that office so we need to fetch it before submitting
                    if (userStore.userInfo.defaultOfficeSpace) {
                        officeSpaceOccupancyResponse = await officeService.getOfficeSpaceOccupancy({
                            periods: [
                                {
                                    officeSpaceId: userStore.userInfo.defaultOfficeSpace.id,
                                    startTime: capacityFetchParams.startTime,
                                    endTime: capacityFetchParams.endTime,
                                },
                            ],
                            timeZone: userStore?.userInfo?.timeZone,
                        });
                    }

                    onSubmit(
                        {
                            id: userStore.userInfo.defaultOffice.id,
                            name: userStore.userInfo.defaultOffice.name ?? '',
                        },
                        {
                            id: userStore.userInfo.defaultOfficeSpace?.id,
                            name: userStore.userInfo.defaultOfficeSpace?.name ?? '',
                            capacity: officeSpaceOccupancyResponse?.[0]?.officeSpace?.capacity,
                            occupancy: officeSpaceOccupancyResponse
                                ? (officeSpaceOccupancyResponse?.[0]?.occupancy ?? 0) + 1 //add ourself in the attendence
                                : undefined,
                        }
                    );
                }
            };

            const getDefaultOfficeDisabledState = async (): Promise<void> => {
                if (userStore.userInfo?.defaultOfficeSpace?.id) {
                    try {
                        const getOfficeSpaceOccupancyResponse: GetOfficeSpaceOccupancyResponsePeriodDto[] =
                            await officeService.getOfficeSpaceOccupancy({
                                periods: [
                                    {
                                        officeSpaceId: userStore.userInfo.defaultOfficeSpace.id,
                                        startTime: capacityFetchParams.startTime,
                                        endTime: capacityFetchParams.endTime,
                                    },
                                ],
                                timeZone: userStore?.userInfo?.timeZone,
                            });

                        if (
                            (getOfficeSpaceOccupancyResponse[0].occupancy ?? 0) >=
                                (getOfficeSpaceOccupancyResponse[0].officeSpace?.capacity ?? 0) &&
                            //if user is already in the space, it is not disabled
                            defaultSelectedSpace?.value !==
                                userStore.userInfo?.defaultOfficeSpace?.id
                        )
                            setDefaultOfficeIsDisabled(true);
                        else {
                            setDefaultOfficeIsDisabled(false);
                        }
                    } catch (e: any) {
                        if (!e.treated) {
                            toastStore.genericError();
                        }
                    }
                } else {
                    setDefaultOfficeIsDisabled(false);
                }
            };

            useEffect(() => {
                getDefaultOfficeDisabledState();
            }, [userStore.userInfo?.defaultOfficeSpace]);

            return (
                <div className="OfficeDropdownModalContent" style={style}>
                    <div
                        className="title"
                        dangerouslySetInnerHTML={{
                            __html: t('OfficeDropdownModal.change_all_day_location'),
                        }}
                    />

                    <AsyncSingleSelect
                        {...officeSelectProps}
                        selected={selectedOffice?.value}
                        onChange={(option?: SingleSelectCustomOption) => {
                            if (
                                option?.value === 'useDefaultOffice' &&
                                userStore.userInfo?.defaultOffice?.id
                            ) {
                                handleUseDefaultOffice();
                                return;
                            } else if (option?.value === 'noOffice') {
                                onSubmit({ id: EMPTY_GUID }, undefined);
                                return;
                            }

                            setSelectedOffice(option);
                            spaceSelectProps.resetSearchResults();
                            spaceSelectProps.onChange(undefined);
                        }}
                        placeholder={t('SelectCustom.choose_an_entity', {
                            param1: t('Entity.lowercase_office'),
                            param2: 'un',
                            param3: t('Entity.lowercase_office'),
                        })}
                    />

                    {selectedOffice && (
                        <div className={`${hasSpaceViewer ? 'dropdown-space-viewer' : ''}`}>
                            <AsyncSingleSelect
                                {...spaceSelectProps}
                                className="space-select"
                                options={spacesWithCapacityOptions.map((item) => {
                                    if (item.value === 'noSpace') return item;
                                    if (item.value === spaceSelectProps.selected) return item;

                                    const isFull =
                                        item.extraData.currentCapacity.occupancy >=
                                        item.extraData.currentCapacity.capacity;

                                    if (isFull) {
                                        return {
                                            ...item,
                                            isDisabled: true,
                                        };
                                    }

                                    return item;
                                })}
                                onChange={(option?: SingleSelectCustomOption) => {
                                    onSubmit(
                                        { id: selectedOffice.value, name: selectedOffice.label },
                                        option?.value === 'noSpace'
                                            ? { id: EMPTY_GUID }
                                            : {
                                                  id: option?.value,
                                                  name: option?.label,
                                                  capacity:
                                                      option?.extraData.currentCapacity?.capacity,
                                                  occupancy:
                                                      option?.extraData.currentCapacity?.occupancy +
                                                      1,
                                              }
                                    );
                                }}
                                placeholder={t('SelectCustom.select_a_entity', {
                                    param1: t('Entity.lowercase_space'),
                                    param2: 'une',
                                    param3: t('Entity.lowercase_space'),
                                })}
                            />
                            {hasSpaceViewer && (
                                <div
                                    className="space-viewer-btn"
                                    onClick={() => setSpaceViewerModalOpen(true)}
                                >
                                    <SpaceViewerIcon
                                        width={24}
                                        height={24}
                                        fill={theme['accent-mid-contrast']}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {makeHybridButton && (
                        <>
                            <div className="separator" />

                            <Button
                                text={t('Dashboard.go_to_schedule')}
                                type="secondary"
                                width="full"
                                height="small"
                                link={makeHybridButton}
                            />
                        </>
                    )}

                    {selectedOffice && spaceViewerModalOpen && (
                        <SpaceViewerModal
                            visible={spaceViewerModalOpen}
                            selectedOffice={selectedOffice}
                            timeRange={{
                                startTime: capacityFetchParams.startTime,
                                endTime: capacityFetchParams.endTime,
                            }}
                            onComplete={(success: boolean, space?: SingleSelectCustomOption) => {
                                setSpaceViewerModalOpen(false);
                                if (success) {
                                    onSubmit(
                                        { id: selectedOffice.value, name: selectedOffice.label },
                                        { id: space?.value, name: space?.label }
                                    );
                                }
                            }}
                            selectedSpaceProp={defaultSelectedSpace}
                        />
                    )}
                </div>
            );
        }
    );

export default OfficeDropdownModalContent;
