import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClockFilledIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M13.136 21.6177C17.7635 21.6177 21.5829 17.7983 21.5829 13.179C21.5829 8.55971 17.7553 4.74023 13.1279 4.74023C8.50858 4.74023 4.69727 8.55971 4.69727 13.179C4.69727 17.7983 8.51674 21.6177 13.136 21.6177ZM9.02274 14.2073C8.65548 14.2073 8.36984 13.9217 8.36984 13.5544C8.36984 13.1872 8.65548 12.9015 9.02274 12.9015H12.4831V8.21694C12.4831 7.84968 12.7688 7.56403 13.1279 7.56403C13.5033 7.56403 13.7889 7.84968 13.7889 8.21694V13.5544C13.7889 13.9217 13.5033 14.2073 13.1279 14.2073H9.02274Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClockFilledIcon;
