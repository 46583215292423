import { ImageWithPlaceholder } from 'Components/image-with-placeholder';
import React from 'react';
import { img, ImgSize } from 'Utils/ImgUtils';
import './index.less';

export interface ImagesCoworkersProps {
    imageUrls: string[];
    usersCount: number;
    maxDisplayCoworkers?: number;
}

const ImagesCoworkers: React.FunctionComponent<ImagesCoworkersProps> = ({
    imageUrls,
    usersCount,
    maxDisplayCoworkers = 6,
}) => {
    const nbrTotalUser = usersCount ? usersCount : imageUrls.length;

    return (
        <div className="ImagesCoworkers">
            {imageUrls.slice(0, maxDisplayCoworkers).map((url, index) => {
                return (
                    <ImageWithPlaceholder
                        imgSrc={url ? img(url, ImgSize.s) : undefined}
                        key={index}
                    />
                );
            })}

            {nbrTotalUser > maxDisplayCoworkers && (
                <div className="ImageWithPlaceholder img overflow text-footnote-bold">{`+${
                    nbrTotalUser - maxDisplayCoworkers
                }`}</div>
            )}

            {nbrTotalUser <= 0 && (
                <div className="ImageWithPlaceholder img overflow text-footnote-bold">0</div>
            )}
        </div>
    );
};

export default ImagesCoworkers;
