import Button from 'Components/button';
import LayoutErrorsPage from 'Layout/errors_page';
import { DASHBOARD_URL } from 'Models/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

export const NotFoundPage: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <LayoutErrorsPage className="NotFoundPage">
            <div className="text-titleXL">{t('NotFoundPage.page_not_found_title')}</div>
            <div className="text-callout">{t('NotFoundPage.this_page_not_exist')}</div>
            <Button text={t('ForbiddenPage.go_to_dashboard')} type="primary" link={DASHBOARD_URL} />
        </LayoutErrorsPage>
    );
};

export default NotFoundPage;
