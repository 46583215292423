import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Button from 'Components/button';
import Divider from 'Components/divider';
import Tag, { TagColor } from 'Components/tag/tag';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingBaseProps } from '..';
import './base-styles.less';
import './profile-confirm.less';
import LabelValueComponent from 'Components/label-value-component';
import { DisplayContactPreferencesData } from 'Components/display-contact-preferences';

interface OnboardingProfileConfirmProps extends OnboardingBaseProps {
    userInfo: OnboardingProfileConfirmModel;
}

export interface UserInfo {
    id: string;
    location: string;
    timeZone: string;
    languages: string;
    department: string;
    team: string;
    email: string;
    role: string;
    badges?: string;
    contactPreferences?: DisplayContactPreferencesData;
    mainWorkplace?: string;
    defaultOffice?: string;
}

export interface OnboardingProfileConfirmModel extends UserInfo {
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    jobTitle?: string | null;
}

const OnboardingProfileConfirm: React.FunctionComponent<OnboardingProfileConfirmProps> = observer(
    ({ onNextClick, onPreviousClick, userInfo }) => {
        const { t } = useTranslation();

        const handleNextClick = () => {
            onNextClick();
        };
        return (
            <div className="OnboardingProfileConfirm OnboardingBase">
                <div className="text-title-1-bold mb-20 centered pt-40">
                    {t('Onboarding.profil_confirmation_title')}
                </div>

                <div className="text-callout text-mid-contrast mb-20 centered subtitle">
                    {t('Onboarding.profil_confirmation_subtitle')}
                </div>

                <div className="content">
                    <div className="profile-summary-container">
                        <div
                            className="img-container"
                            style={{
                                backgroundImage: `url(${userInfo?.imageUrl ?? NoProfilePicture})`,
                            }}
                        />

                        {userInfo.role === ManagementRoleDto.Manager && (
                            <Tag text="Manager" color={TagColor.blue} />
                        )}

                        <div className="text-container">
                            <div className="name text-title-3-bold">
                                {userInfo.firstName} {userInfo.lastName}
                            </div>
                            <div className="jobTitle text-body">{userInfo?.jobTitle}</div>

                            <div className="divider">
                                <Divider marginTop={10} marginBottom={16} />
                            </div>

                            <div className="team text-body">{userInfo.team}</div>

                            <div className="department text-body text-mid-contrast">
                                {userInfo.department}
                            </div>
                        </div>
                    </div>

                    <div className="full-info-container">
                        <>
                            <div className="ProfileInfo">
                                <div className="text-title-3-bold mb-20">{t('profile')}</div>
                                <div className="section">
                                    <LabelValueComponent
                                        label={t('location')}
                                        value={userInfo?.location}
                                    />
                                    <LabelValueComponent
                                        label={t('time_zone')}
                                        value={userInfo?.timeZone}
                                    />
                                    <LabelValueComponent
                                        label={t('languages')}
                                        value={userInfo?.languages}
                                    />
                                    <LabelValueComponent
                                        label={t('email')}
                                        value={userInfo?.email}
                                    />
                                </div>

                                <div className="text-title-3-bold mb-20">
                                    {t('account_details')}
                                </div>
                                <div className="section">
                                    <LabelValueComponent
                                        label={t('department')}
                                        value={userInfo?.department}
                                    />
                                    <LabelValueComponent label={t('team')} value={userInfo?.team} />
                                    <LabelValueComponent label={t('role')} value={userInfo?.role} />

                                    <LabelValueComponent
                                        label={t('Coworker.badges')}
                                        value={userInfo?.badges}
                                    />
                                </div>

                                <div className="text-title-3-bold mb-20">{t('workplaces')}</div>
                                <div className="section">
                                    <LabelValueComponent
                                        label={t('main_workplace')}
                                        value={userInfo?.mainWorkplace}
                                    />
                                    <div className="default-office">
                                        <LabelValueComponent
                                            label={t('default_office')}
                                            value={userInfo?.defaultOffice}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button text={t('previous')} type="tertiary" onClick={onPreviousClick} />
                        <Button
                            text={t('confirm')}
                            type="primary"
                            onClick={() => handleNextClick()}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default OnboardingProfileConfirm;
