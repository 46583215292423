import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import TitledWhitecardSection from '../titled-whitecard-section';
import { useTranslation } from 'react-i18next';
import { ProfileContext, ProfileTabs } from 'Routes/profile';
import './contact-preferences-tab.less';
import { Col, Input, Row, Switch } from 'antd';
import { FORM_GUTTER } from 'Models/Constants';
import { ValidatedFormItem } from 'Components/validated-form-item';
import PhoneInput from 'Components/phone-input';
import { ContactMethodTypeDto } from 'Api/Features/General/Dtos/ContactMethodTypeDto';
import {
    getContactPreferencesMethodArray,
    getEachContactPreferencesMethod,
} from 'Utils/ContactPreferencesUtils';
import { ContactPreferencesMethodDto } from 'Api/Features/General/Dtos/ContactPreferencesMethodDto';
import { fromE164, toE164 } from 'Utils/PhoneNumberUtils';
import { EnvelopeIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { GetUserSlackInfoResponseDto } from 'Api/Features/Users/Dtos/GetUserSlackInfoResponseDto';
import { useService, useStores } from 'Hooks';
import { UserService } from 'Services/UserService';
import { observer } from 'mobx-react';
import SubmitButton from 'Components/submit-button/submit-button';

//this section taken from old edit profile modal
const ContactPreferencesTab: FunctionComponent = observer(() => {
    const profileContext = useContext(ProfileContext);
    const { userStore } = useStores();
    const { t } = useTranslation();
    const userService = useService(UserService);

    const [slackInfo, setSlackInfo] = useState<GetUserSlackInfoResponseDto>();

    const [contactPreferences, setContactPreferences] = useState<
        (ContactPreferencesMethodDto | null)[]
    >([
        { type: ContactMethodTypeDto.PhoneNumber, value: undefined, enabled: false },
        { type: ContactMethodTypeDto.Email, value: undefined, enabled: false },
        { type: ContactMethodTypeDto.Slack, value: undefined, enabled: false },
        { type: ContactMethodTypeDto.Calendly, value: undefined, enabled: false },
    ]);

    const eachContactPreferences = getEachContactPreferencesMethod(contactPreferences);

    const handleContactPreferencesChanges = (
        type?: ContactMethodTypeDto,
        enabled?: boolean,
        value?: string,
        phoneNumberisValid?: boolean
    ) => {
        setContactPreferences((prev: (ContactPreferencesMethodDto | null)[]) => {
            const index = prev.findIndex((item) => item?.type === type);
            const newVal: (ContactPreferencesMethodDto | null)[] = [...prev];
            const nonNullNewVal = newVal.filter(
                (item): item is ContactPreferencesMethodDto => item !== null
            );

            if (enabled !== undefined) nonNullNewVal[index].enabled = enabled;

            if (value !== undefined) {
                if (value === '' || value === null) nonNullNewVal[index].value = undefined;
                else nonNullNewVal[index].value = value;
            }

            if (
                phoneNumberisValid !== undefined &&
                profileContext?.phoneNumberIsValid !== undefined
            )
                profileContext.phoneNumberIsValid = phoneNumberisValid;

            return nonNullNewVal;
        });
    };

    const fetchSlackInfo = async (userId: string): Promise<void> => {
        const value = await userService.getUserSlackInfo(userId);
        setSlackInfo(value ?? undefined);
    };

    useEffect(() => {
        if (userStore.userInfo) {
            const eachContactPreferences = getEachContactPreferencesMethod(
                userStore.userInfo.contactPreferences?.methods
            );
            const slackUrl =
                eachContactPreferences.slack.value ?? slackInfo?.slackProfileUrl ?? undefined;
            const contactPreferencesEmail =
                eachContactPreferences.email.value ?? userStore.userInfo.contactInfo?.email;

            setContactPreferences(
                getContactPreferencesMethodArray({
                    ...eachContactPreferences,
                    slack: { ...eachContactPreferences.slack, value: slackUrl },
                    email: { ...eachContactPreferences.email, value: contactPreferencesEmail },
                })
            );

            profileContext?.formInstance.setFieldsValue({
                contactPreferencesEmailEnabled: eachContactPreferences.email.enabled ?? false,
                contactPreferencesEmail: contactPreferencesEmail,
                contactPreferencesSlackEnabled: eachContactPreferences.slack.enabled ?? false,
                contactPreferencesSlack: slackUrl,
                contactPreferencesCalendlyEnabled: eachContactPreferences.calendly.enabled ?? false,
                contactPreferencesCalendly: eachContactPreferences.calendly.value,
                contactPreferencesPhoneNumberEnabled:
                    eachContactPreferences.phoneNumber.enabled ?? false,
            });
        }
    }, [userStore.userInfo]);

    useEffect(() => {
        if (
            slackInfo &&
            (profileContext?.formInstance.getFieldValue('contactPreferencesSlack') === undefined ||
                profileContext?.formInstance.getFieldValue('contactPreferencesSlack').length === 0)
        ) {
            profileContext?.formInstance.setFieldsValue({
                contactPreferencesSlack: slackInfo?.slackProfileUrl,
            });
            setContactPreferences(
                getContactPreferencesMethodArray({
                    ...eachContactPreferences,
                    slack: {
                        ...eachContactPreferences.slack,
                        value: slackInfo?.slackProfileUrl ?? undefined,
                        enabled: true,
                    },
                })
            );

            handleContactPreferencesChanges(
                ContactMethodTypeDto.Slack,
                true,
                slackInfo.slackProfileUrl ?? undefined
            );
        }
    }, [slackInfo, profileContext?.formInstance]);

    return (
        <div className="ContactPreferencesTab">
            <TitledWhitecardSection
                title={t('contact_preferences')}
                subtitle={t('Profile.contact_preferences_explanation')}
            >
                <Col span={24} className="text-callout-bold mb-20">
                    {t('contact_preferences')}
                </Col>

                <Row className="contact-preferences-row" gutter={FORM_GUTTER}>
                    <Col className="toggle-wrapper" span={14}>
                        <ValidatedFormItem
                            className="ValidatedFormItem-toggle"
                            errors={profileContext?.formErrors}
                            name="contactPreferencesPhoneNumberEnabled"
                            label=""
                        >
                            <Switch
                                checked={eachContactPreferences.phoneNumber.enabled}
                                onChange={(e) =>
                                    handleContactPreferencesChanges(
                                        ContactMethodTypeDto.PhoneNumber,
                                        e
                                    )
                                }
                            />
                        </ValidatedFormItem>
                        {t(
                            'ContactMethodTypeDto.ContactMethodTypeDto_' +
                                ContactMethodTypeDto.PhoneNumber
                        )}
                    </Col>

                    <Col className="input-wrapper" span={10}>
                        <ValidatedFormItem
                            errors={profileContext?.formErrors}
                            name="contactPreferencesPhoneNumber"
                            label=""
                        >
                            <PhoneInput
                                formErrors={profileContext?.formErrors ?? new Map()}
                                onChange={(
                                    isValid: boolean,
                                    fullNumber?: string,
                                    extension?: string
                                ) => {
                                    handleContactPreferencesChanges(
                                        ContactMethodTypeDto.PhoneNumber,
                                        undefined,
                                        toE164(fullNumber, extension),
                                        isValid || fullNumber === ''
                                    );
                                    profileContext?.formInstance.setFieldsValue({
                                        contactPreferencesPhoneNumber: toE164(
                                            fullNumber,
                                            extension
                                        ),
                                    });
                                }}
                                startingValue={
                                    eachContactPreferences.phoneNumber.value
                                        ? fromE164(eachContactPreferences.phoneNumber.value).number
                                        : undefined
                                }
                                extension={
                                    eachContactPreferences.phoneNumber.value
                                        ? fromE164(eachContactPreferences.phoneNumber.value).ext
                                        : undefined
                                }
                                formInputName="contactPreferencesPhoneNumber"
                                onFocus={() => {
                                    if (profileContext) {
                                        profileContext.phoneNumberInputWasFocused = true;
                                    }
                                }}
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row className="contact-preferences-row" gutter={FORM_GUTTER}>
                    <Col className="toggle-wrapper" span={14}>
                        <ValidatedFormItem
                            className="ValidatedFormItem-toggle"
                            errors={profileContext?.formErrors}
                            name="contactPreferencesEmailEnabled"
                            label=""
                        >
                            <Switch
                                checked={eachContactPreferences.email.enabled}
                                onChange={(e) =>
                                    handleContactPreferencesChanges(ContactMethodTypeDto.Email, e)
                                }
                            />
                        </ValidatedFormItem>

                        {t(
                            'ContactMethodTypeDto.ContactMethodTypeDto_' +
                                ContactMethodTypeDto.Email
                        )}
                    </Col>

                    <Col className="input-wrapper" span={10}>
                        <ValidatedFormItem
                            errors={profileContext?.formErrors}
                            name="contactPreferencesEmail"
                            label={''}
                        >
                            <Input
                                type={'email'}
                                prefix={<EnvelopeIcon fill={theme['primary-mid-contrast']} />}
                                onChange={(val) =>
                                    handleContactPreferencesChanges(
                                        ContactMethodTypeDto.Email,
                                        undefined,
                                        val.target.value
                                    )
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row className="contact-preferences-row" gutter={FORM_GUTTER}>
                    <Col className="toggle-wrapper" span={14}>
                        <ValidatedFormItem
                            className="ValidatedFormItem-toggle"
                            errors={profileContext?.formErrors}
                            name="contactPreferencesSlackEnabled"
                            label=""
                        >
                            <Switch
                                checked={eachContactPreferences.slack.enabled}
                                onChange={(e) => {
                                    if (e && userStore.userInfo?.id)
                                        fetchSlackInfo(userStore.userInfo.id);

                                    handleContactPreferencesChanges(ContactMethodTypeDto.Slack, e);
                                }}
                            />
                        </ValidatedFormItem>

                        {t(
                            'ContactMethodTypeDto.ContactMethodTypeDto_input_label_' +
                                ContactMethodTypeDto.Slack
                        )}
                    </Col>

                    <Col className="input-wrapper" span={10}>
                        <ValidatedFormItem
                            errors={profileContext?.formErrors}
                            name="contactPreferencesSlack"
                            label={''}
                        >
                            <Input
                                onChange={(val) =>
                                    handleContactPreferencesChanges(
                                        ContactMethodTypeDto.Slack,
                                        undefined,
                                        val.target.value
                                    )
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Row className="contact-preferences-row" gutter={FORM_GUTTER}>
                    <Col className="toggle-wrapper" span={14}>
                        <ValidatedFormItem
                            className="ValidatedFormItem-toggle"
                            errors={profileContext?.formErrors}
                            name="contactPreferencesCalendlyEnabled"
                            label=""
                        >
                            <Switch
                                checked={eachContactPreferences.calendly.enabled}
                                onChange={(e) =>
                                    handleContactPreferencesChanges(
                                        ContactMethodTypeDto.Calendly,
                                        e
                                    )
                                }
                            />
                        </ValidatedFormItem>

                        {t(
                            'ContactMethodTypeDto.ContactMethodTypeDto_input_label_' +
                                ContactMethodTypeDto.Calendly
                        )}
                    </Col>

                    <Col className="input-wrapper" span={10}>
                        <ValidatedFormItem
                            errors={profileContext?.formErrors}
                            name="contactPreferencesCalendly"
                            label={''}
                        >
                            <Input
                                onChange={(val) =>
                                    handleContactPreferencesChanges(
                                        ContactMethodTypeDto.Calendly,
                                        undefined,
                                        val.target.value
                                    )
                                }
                            />
                        </ValidatedFormItem>
                    </Col>
                </Row>
            </TitledWhitecardSection>

            <div className="d-flex">
                <SubmitButton
                    className="submit-btn"
                    text={t('save')}
                    type="tertiary"
                    onClick={async () =>
                        await profileContext?.submit(ProfileTabs.ContactPreferences)
                    }
                />
            </div>
        </div>
    );
});

export default ContactPreferencesTab;
