import { Tooltip } from 'antd';
import Icon from 'Components/icons/Icon';
import React, { ReactNode, useEffect, useState } from 'react';
import './index.less';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface DropdownModalProps {
    className?: string;
    parentElement: ReactNode;
    forceClose?: { close: boolean; resetForceClose: () => void };
    disabled?: boolean;
    placement?: TooltipPlacement;
}

const DropdownModal: React.FunctionComponent<DropdownModalProps> = ({
    className,
    parentElement,
    forceClose,
    disabled,
    placement,
    children,
}) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (forceClose?.close === true) {
            forceClose.resetForceClose();
            setOpen(false);
        }
    }, [forceClose?.close]);

    return (
        <Tooltip
            className={`DropdownModal ${className ?? ''} disabled-${disabled}`}
            overlayClassName={`DropdownModal-overlay ${className ?? ''}`}
            trigger="click"
            placement={placement ?? 'bottom'}
            arrowContent={undefined}
            visible={disabled ? false : open}
            title={
                <>
                    <div className="close-button" onClick={() => setOpen(false)}>
                        <Icon iconName="CloseIcon" />
                    </div>
                    {children}
                </>
            }
            onVisibleChange={(visible) => !visible && setOpen(false)}
        >
            <div onClick={() => setOpen(true)}>{parentElement}</div>
        </Tooltip>
    );
};

export default DropdownModal;
