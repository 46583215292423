import { Col, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import Button from 'Components/button';
import Modal from 'Components/modal';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useAsyncSingleSelectProps, useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORM_GUTTER } from 'Models/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostHappeningToSlackSchema } from 'Schemas/CreateHappeningSchema';
import { HappeningService } from 'Services/HappeningService';
import { SlackService } from 'Services/SlackService';
import { GetSlackChannelsRequestDto } from 'Api/Features/Slack/Dtos/GetSlackChannelsRequestDto';
import { SlackChannel } from 'Api/Features/Slack/Dtos/SlackChannel';
import { HappeningSlackChannel } from 'Api/Features/Happenings/Dtos/HappeningSlackChannel';
import SubmitButton from 'Components/submit-button/submit-button';

interface PostOnSlackModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    happeningId: string;
    channel?: HappeningSlackChannel | null;
}

const PostOnSlackModal: React.FunctionComponent<PostOnSlackModalProps> = observer(
    ({ visible, onComplete, happeningId, channel }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const { toastStore, globalLoadingStore } = useStores();
        const [errors, validateForm, resetErrors] = useFormValidation(
            PostHappeningToSlackSchema,
            form
        );
        const happeningService = useService(HappeningService);
        const slackService = useService(SlackService);
        const [slackGeneralChannel, setSlackGeneralChannel] = useState<
            SingleSelectCustomOption | undefined
        >();

        const { asyncSingleSelectProps: slackSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetSlackChannelsRequestDto) =>
                    await slackService.getSlackChannels(request),
            },
            entityToSingleSelectCustomOption: (channel: SlackChannel) =>
                ({
                    label: channel.name,
                    value: channel.id,
                } as SingleSelectCustomOption),
        });

        useEffect(() => {
            if (slackGeneralChannel && slackSelectProps.selected === undefined) {
                slackSelectProps.onChange(slackGeneralChannel);
            }
        }, [slackGeneralChannel]);

        const fetchSlackGeneralChannel = useCallback(async () => {
            //general is ALWAYS first result
            const [response] = await slackService.getSlackChannels({ page: 0, pageSize: 1 });
            setSlackGeneralChannel({ value: response[0].id ?? '', label: response[0].name ?? '' });
        }, []);

        useEffect(() => {
            fetchSlackGeneralChannel();
        }, []);

        useEffect(() => {
            if (channel?.id && channel.name) {
                slackSelectProps.onChange({ label: channel.name, value: channel.id });
            }
        }, [channel]);

        //#region Submit / Exit
        const dismiss = (success = false): void => {
            form.resetFields();
            resetErrors();
            onComplete(success);
        };

        const submit = async (): Promise<void> => {
            const validationObject = { slackChannelId: slackSelectProps.selected };

            if (!(await validateForm(validationObject))) return;

            try {
                globalLoadingStore.addLoading();

                await happeningService.postHappeningToSlack(happeningId, {
                    slackChannelId: slackSelectProps.selected,
                });

                toastStore.toast({
                    type: 'success',
                    message: t('Happening.post_to_slack_success'),
                });

                dismiss(true);
            } catch (e: any) {
                if (e.response?.data?.errors.slackChannelId?.[0].description !== undefined) {
                    toastStore.toast({
                        type: 'error',
                        message: e.response?.data?.errors.slackChannelId?.[0].description,
                    });
                    e.treated = true;
                }
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#region Submit / Exit

        return (
            <Modal
                className="PostOnSlackModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t('Happening.post_message_select_slack_channel')}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />

                        <SubmitButton
                            text={t('save_changes')}
                            type="primary"
                            onClick={() => submit()}
                        />
                    </>
                }
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <div className="text-callout-bold mb-15">
                                {t('Happening.happening_post_on_slack')}
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <ValidatedFormItem
                                label={t('Happening.post_message_select_slack_channel_subtitle')}
                                name={'slackChannelId'}
                                errors={errors}
                                capitalizeLabel={false}
                            >
                                <AsyncSingleSelect
                                    {...slackSelectProps}
                                    placeholder={t('SelectCustom.choose_a_entity', {
                                        param1: t('Entity.lowercase_channel'),
                                    })}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
);

export default PostOnSlackModal;
