import React, {
    Children,
    cloneElement,
    Dispatch,
    isValidElement,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { generateKey } from 'Utils/ArrayUtils';
import './index.less';

const onSlideButtonclick = (
    buttonIndex: number,
    setCurrentSlide: Dispatch<SetStateAction<number>>
) => {
    setCurrentSlide(buttonIndex);
};

const renderButtons = (
    buttonsNb: number,
    currentSlide: number,
    setCurrentSlide: Dispatch<SetStateAction<number>>
): ReactElement[] => {
    const buttons: ReactElement[] = [];

    for (let index = 0; index < buttonsNb; index++) {
        const active = index === currentSlide;

        buttons.push(
            <div
                key={generateKey(`slide-button-${index}`)}
                className={`slide-button active-${active}`}
                onClick={active ? undefined : () => onSlideButtonclick(index, setCurrentSlide)}
            />
        );
    }

    return buttons;
};

interface VerticalCarouselProps {
    className?: string;
    activeSlide?: number;
    height: string;
    onChange?: (currentSlide: number) => void;
}

export const VerticalCarousel: React.FunctionComponent<VerticalCarouselProps> = ({
    className,
    activeSlide,
    height,
    children,
    onChange,
}) => {
    const [currentSlide, setCurrentSlide] = useState(activeSlide ?? 0);

    const nonNullchildren = React.Children.toArray(children).filter(Boolean);
    const rowsNb = Children.count(nonNullchildren);
    const step = 100 / rowsNb;
    const verticalCarouselstyle = { height };
    const sliderStyle = {
        height: `${rowsNb * 100}%`,
        transform: `translateY(${-currentSlide * step}%)`,
        gridAutoRows: `${100 / rowsNb}%`,
    };

    useEffect(() => {
        if (onChange) onChange(currentSlide);
    }, [currentSlide]);

    return (
        <div className={`VerticalCarousel ${className ?? ''}`} style={verticalCarouselstyle}>
            <div className="slider-container">
                <div className="slider" style={sliderStyle}>
                    {Children.map(
                        nonNullchildren,
                        (child, i) =>
                            isValidElement(child) &&
                            cloneElement(child as ReactElement, {
                                className: `visible-${i === currentSlide}`,
                            })
                    )}
                </div>
            </div>

            <div className="button-container">
                {rowsNb > 1 && renderButtons(rowsNb, currentSlide, setCurrentSlide)}
            </div>
        </div>
    );
};
