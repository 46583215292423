import React from 'react';
import './index.less';

export const userDetailsHeaderSkeletonShape = (
    <div className="userDetailsHeaderSkeletonShape">
        <div className="circle" />
        <div className="wrapper-center">
            <div className="rect-large" />
            <div className="rect-large" />
        </div>
        <div className="rect" />
    </div>
);
