import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerticalThreeDotMenuButton, {
    ThreeDotMenuOption,
} from 'Components/vertical-three-dot-menu-button';
import './index.less';

interface ActionsButtons {
    onEdit: () => void;
    onAddCoworkers: () => void;
    onDeleteTeam: () => void;
}

export interface TeamDetailsHeaderProps {
    name?: string | null;
    actionButtons?: ActionsButtons;
}

const TeamDetailsHeader: FunctionComponent<TeamDetailsHeaderProps> = ({ name, actionButtons }) => {
    const { t } = useTranslation();
    const [ctaOptions, setCtaOptions] = useState<ThreeDotMenuOption[]>([]);

    useEffect(() => {
        if (actionButtons) {
            setCtaOptions([
                {
                    title: t('edit'),
                    onClick: () => actionButtons.onEdit(),
                },
                {
                    title: t('add_entity', { param1: t('coworkers') }),
                    onClick: () => actionButtons.onAddCoworkers(),
                },
                {
                    title: t('delete'),
                    onClick: () => actionButtons.onDeleteTeam(),
                    className: 'secondary',
                },
            ]);
        }
    }, [actionButtons]);

    return (
        <div className="TeamDetailsHeader content-header">
            <div className="text-title-3">{name}</div>
            <VerticalThreeDotMenuButton menuOptions={ctaOptions} />
        </div>
    );
};

export default TeamDetailsHeader;
