import Button from 'Components/button';
import { ChevronLeftIcon, ChevronRightIcon } from 'Components/icons';
import { FORMAT_MONTH_YEAR } from 'Models/Constants';
import moment from 'moment';
import React, { forwardRef } from 'react';

// package : https://www.npmjs.com/package/react-datepicker
// Best documentation : https://reactdatepicker.com/
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './custom-date-picker.less';

interface CustomDatePickerProps {
    onChange: (date: Date) => void;
    value: Date | null;
    maxDate?: Date;
    minDate?: Date;
    error?: boolean;
    hasTodayBtn?: boolean;
}

const CustomDatePicker: React.FunctionComponent<CustomDatePickerProps> = ({
    onChange,
    value = null,
    maxDate,
    minDate,
    error,
    hasTodayBtn,
}) => {
    const { t } = useTranslation();

    const HeaderCustomDatePicker = forwardRef(
        (object: { value: string; onClick: () => void }, ref) => {
            return (
                <button
                    className={`custom-date-picker-button ${error ? 'error' : ''}`}
                    type="button"
                    onClick={object.onClick}
                    // @ts-ignore
                    ref={ref}
                >
                    {value == null || object.value == null || object.value === ''
                        ? ''
                        : object.value === moment(new Date()).format('MM/DD/YYYY')
                        ? t('today')
                        : moment(value).format('YYYY/MM/DD')}
                </button>
            );
        }
    );

    const handleOnChange = (date: Date) => {
        onChange(date);
    };

    // CONSTANT
    const COLOR_HEADER_CHEVRON = theme['layout-mid-main'];

    return (
        <div className="CustomDatePicker">
            <DatePicker
                selected={value == null ? new Date() : value}
                value={value}
                onChange={handleOnChange}
                calendarClassName="CustomDatePickerCalendar"
                className="CustomDatePickerContainer"
                maxDate={maxDate}
                minDate={minDate}
                // @ts-ignore
                customInput={<HeaderCustomDatePicker />}
                renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
                    return (
                        <div className="custom-date-picker-header">
                            <ChevronLeftIcon fill={COLOR_HEADER_CHEVRON} onClick={decreaseMonth} />
                            <div className="text-callout-bold">
                                {moment(date).format(FORMAT_MONTH_YEAR)}
                            </div>
                            <ChevronRightIcon fill={COLOR_HEADER_CHEVRON} onClick={increaseMonth} />
                        </div>
                    );
                }}
                showDisabledMonthNavigation
            >
                {hasTodayBtn && (
                    <Button
                        className="today-btn"
                        text="Today"
                        type="secondary"
                        onClick={() => handleOnChange(new Date())}
                    />
                )}
            </DatePicker>
        </div>
    );
};

export default CustomDatePicker;
