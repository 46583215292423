import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PadlockIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M9.7373 22.1182H18.2627C19.4404 22.1182 20.0205 21.5381 20.0205 20.2549V13.6719C20.0205 12.5117 19.5459 11.9229 18.5615 11.8262V9.65527C18.5615 6.31543 16.3379 4.70703 14 4.70703C11.6621 4.70703 9.43848 6.31543 9.43848 9.65527V11.8525C8.52441 11.9932 7.97949 12.5732 7.97949 13.6719V20.2549C7.97949 21.5381 8.55957 22.1182 9.7373 22.1182ZM11.126 9.49707C11.126 7.44922 12.4268 6.32422 14 6.32422C15.5645 6.32422 16.874 7.44922 16.874 9.49707V11.8086L11.126 11.8174V9.49707Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PadlockIcon;
