export const moveArrayElement = (array: any[], fromIndex: number, toIndex: number): any[] => {
    const arr = [...array];
    const element = array[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
};

export const generateKey = (pre: string | number): string => {
    return `${pre}-${new Date().getTime()}`;
};
