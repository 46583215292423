import { default as React, FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import './objective-info.less';
import { InformationCircleIcon, TargetArrowIcon } from 'Components/icons';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import PolicyObjectiveCircleIcon from 'Components/policyObjectiveCircleIcon';
import { theme } from 'Style/theme';
import ModalWeeklyObjectives from 'Components/modal-weekly-objectives';
import { PolicyInfoStoreObjectiveStats } from 'Stores/PolicyStore';
import Skeleton from 'Components/skeleton';
import { wrapperBoxInformationsShape } from './skeleton-shapes';

interface ObjectiveInfoProps {
    objectiveStats: PolicyInfoStoreObjectiveStats | undefined;
    displayEditButton?: boolean;
    isLoaded?: boolean;
}

const ObjectiveInfo: FunctionComponent<ObjectiveInfoProps> = observer(
    ({ objectiveStats, displayEditButton, isLoaded = true }) => {
        const { t } = useTranslation();
        const [modalWeeklyObjectivesIsVisible, setModalWeeklyObjectivesIsVisible] = useState(false);

        const onPressInformation = () => setModalWeeklyObjectivesIsVisible(true);
        const onCloseModalWeeklyObjectives = () => setModalWeeklyObjectivesIsVisible(false);

        return (
            <div className="ObjectiveInfo">
                <Skeleton isLoaded={isLoaded} placeholder={wrapperBoxInformationsShape}>
                    {!objectiveStats ||
                    objectiveStats.totalScore == undefined ||
                    objectiveStats?.totalScore?.total == 0 ? (
                        <></>
                    ) : (
                        <div className="valide-container">
                            {/* <- Indisepensable pour le border changant dans './wrapper-box-informations.less' */}
                            <TargetArrowIcon fill={theme['primary-high-contrast']} />
                            <div className="weekly-objectives text-caption-2-bold">
                                {t('PlanSchedule.weekly_objectives')}
                            </div>
                            <Divider type="vertical" className="divider" />

                            <PolicyObjectiveCircleIcon
                                policyGoal={objectiveStats?.totalScore?.total}
                                policyCurrentValue={objectiveStats?.totalScore?.completed}
                                minWidth={30}
                            />

                            <div className="text-caption-2 number-completed">
                                <div className="text-caption-2-bold number-progress">
                                    {objectiveStats?.totalScore?.completed} /{' '}
                                    {objectiveStats?.totalScore?.total}
                                </div>
                                {t('PlanSchedule.completed_objectives')}
                            </div>
                            <div
                                className="container-exclamationMarkCircle"
                                onClick={onPressInformation}
                            >
                                <InformationCircleIcon
                                    fill={theme['primary-mid-contrast']}
                                    width={21}
                                    height={21}
                                />
                            </div>
                        </div>
                    )}
                </Skeleton>

                {modalWeeklyObjectivesIsVisible && (
                    <ModalWeeklyObjectives
                        isVisible={modalWeeklyObjectivesIsVisible}
                        onCancel={onCloseModalWeeklyObjectives}
                        objectiveStats={objectiveStats}
                        displayEditButton={displayEditButton}
                    />
                )}
            </div>
        );
    }
);

export default ObjectiveInfo;
