import React from 'react';
import './index.less';

export const profileSummaryShape = (
    <div className="profileSummaryShape">
        <div className="circle-1" />
        <div className="rect-2" />
        <div className="rect-3" />
        <div className="rect-4" />
        <div className="rect-5" />
    </div>
);

export const profileInfoShape = (
    <div className="profileInfoShape">
        <div className="wrapper-1">
            <div className="rect-1" />
            <div className="buttonRect-2" />
        </div>
        <div className="rect-3" />
        <div className="wrapper-2">
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
            <div className="wrapper-3">
                <div className="rect-4" />
                <div className="rect-5" />
            </div>
        </div>
        <div className="rect-6" />
        <div className="rect-6" />
        <div className="rect-7" />
        <div className="rect-8" />
        <div className="roundRect-9" />
    </div>
);

export const profileIntegrationGoogleCalendarShape = (
    <div className="profileIntegrationGoogleCalendarShape">
        <div className="rect" />
        <div className="rect-big" />
        <div className="rect-btn" />
    </div>
);

export const profileExternalIdentityShape = (
    <div className="profileExternalIdentityShape">
        <div className="rect-btn" />
    </div>
);
