import { FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE } from 'Models/Constants';
import moment from 'moment';
import { GetDailySchedulesResponseItem } from 'Routes/coworkers';
import { SupportedLanguage } from 'Stores/LanguageStore';

export interface WorkingHours {
    start: string;
    end: string;
}

export const getCoworkerWorkingHours = (
    coworker: GetDailySchedulesResponseItem,
    currentUserTimezone: string,
    language: SupportedLanguage
): WorkingHours | undefined => {
    if (!coworker.day?.workStartTime || !coworker.day?.workEndTime) return undefined;
    return {
        start: moment
            .tz(coworker.day?.workStartTime, currentUserTimezone)
            .format(FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[language]),
        end: moment
            .tz(coworker.day?.workEndTime, currentUserTimezone)
            .format(FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[language]),
    };
};
