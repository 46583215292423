import { Col, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { UpdatePolicyBundleTeamsActionDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleTeamsActionDto';
import { UpdatePolicyBundleTeamsRequestDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleTeamsRequestDto';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import Button from 'Components/button';
import { MagnifyingGlassIcon } from 'Components/icons';
import Modal from 'Components/modal';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import { TeamService } from 'Services/TeamService';
import { theme } from 'Style/theme';
import SelectedTeamsComponent from '../components/selected-teams/selected-teams-component';
import './policy-add-teams-modal.less';
import SubmitButton from 'Components/submit-button/submit-button';

interface PolicyAddTeamsModalProps {
    visible: boolean;
    policy: PolicyBundleDto;
    onComplete: (success: boolean) => void;
}

const PolicyAddTeamsModal: React.FunctionComponent<PolicyAddTeamsModalProps> = ({
    visible,
    policy,
    onComplete,
}) => {
    const [form] = useForm();
    const { t } = useTranslation();
    const { toastStore, globalLoadingStore, policyStore } = useStores();

    const teamService = useService(TeamService);
    const policyBundleService = useService(PolicyBundleService);

    const { asyncSingleSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetTeamsRequestDto) =>
                await teamService.getTeams(request),
        },
        entityToSingleSelectCustomOption: (team: TeamDto) =>
            ({
                label: team.name,
                value: team.id,
                extraData: team,
            } as SingleSelectCustomOption),
    });

    const [selectedTeams, setSelectedTeams] = useState<TeamDto[]>([]);

    const handleRemoveClick = (team: TeamDto) => {
        setSelectedTeams((prev) => [...prev.filter((x) => x.id !== team.id)]);
    };

    //#region Submit / Exit
    const dismiss = (success = false): void => {
        form.resetFields();
        onComplete(success);
    };

    const submit = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            const request: UpdatePolicyBundleTeamsRequestDto = {
                entries: selectedTeams.map((team) => ({
                    action: UpdatePolicyBundleTeamsActionDto.Add,
                    teamId: team.id,
                })),
            };

            await policyBundleService.updatePolicyBundleTeams(policy.id!, request);

            if (policy.id === policyStore.policyInfo?.policyBundleId)
                await policyStore.setPolicyRestrictionsAndObjectivesStats();

            toastStore.toast({
                type: 'success',
                message: t('Toast.success_message', {
                    param1: t('bundle'),
                }),
            });

            dismiss(true);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#region Submit / Exit

    return (
        <Modal
            className="PolicyAddTeamsModal"
            visible={visible}
            onCancel={() => dismiss()}
            headerText={`${t('add_entity', { param1: t('teams') })} - ${policy.name}`}
            fixedHeight
            oustideOverflow={{
                element: (
                    <Form
                        layout="vertical"
                        onFinish={submit}
                        form={form}
                        className="container-scroll-actions-fixed"
                    >
                        <div className="text-caption-1 text-mid-contrast mb-10 t-align-center">
                            {t('Team.search_team_add_bundle')}
                        </div>

                        <Row>
                            <Col span={10} offset={7}>
                                <AsyncSingleSelect
                                    {...asyncSingleSelectProps}
                                    placeholder={t('search')}
                                    selected={undefined}
                                    onChange={(value?: SingleSelectCustomOption) => {
                                        setSelectedTeams((prev) => [
                                            ...prev.filter((x) => x.id !== value?.extraData.id),
                                            value?.extraData,
                                        ]);
                                    }}
                                    dropdownIndicatorIcon={
                                        <MagnifyingGlassIcon fill={theme['primary-mid-contrast']} />
                                    }
                                />
                            </Col>
                        </Row>
                    </Form>
                ),
                height: 120,
            }}
            footer={
                <>
                    <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />

                    <div className="positive-btns">
                        {selectedTeams.some((team) => team.policyBundle?.id) && (
                            <div className="warning-container text-callout text-mid-contrast">
                                <span className="mr-5">{t('warning')}:</span>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('PolicyAddTeamsModal.team_already_associated', {
                                            param1: selectedTeams.filter(
                                                (team) => team.policyBundle?.id
                                            ).length,
                                            param2:
                                                selectedTeams.filter(
                                                    (team) => team.policyBundle?.id
                                                ).length > 1
                                                    ? 'are'
                                                    : 'is',
                                            param3:
                                                selectedTeams.filter(
                                                    (team) => team.policyBundle?.id
                                                ).length > 1
                                                    ? 'sont'
                                                    : 'est',
                                        }),
                                    }}
                                />
                                {t('Policy.warning_team_in_policy')}
                            </div>
                        )}

                        <SubmitButton
                            text={t('save_changes')}
                            type="primary"
                            onClick={() => submit()}
                        />
                    </div>
                </>
            }
        >
            <SelectedTeamsComponent teams={selectedTeams} handleRemoveClick={handleRemoveClick} />
        </Modal>
    );
};

export default PolicyAddTeamsModal;
