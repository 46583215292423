import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const GrowingChartIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M6.19991 19.7801L11.4999 14.4801L14.7499 17.7301C15.1599 18.1401 15.8199 18.1201 16.1999 17.6901L23.3699 9.62006C23.7199 9.23006 23.6999 8.63006 23.3299 8.25006C23.2338 8.15425 23.1192 8.079 22.9931 8.0289C22.867 7.9788 22.732 7.95489 22.5963 7.95863C22.4607 7.96237 22.3272 7.99369 22.204 8.05067C22.0809 8.10764 21.9706 8.18909 21.8799 8.29006L15.4899 15.4701L12.1999 12.1801C12.1074 12.0874 11.9975 12.0138 11.8765 11.9636C11.7556 11.9134 11.6259 11.8876 11.4949 11.8876C11.3639 11.8876 11.2343 11.9134 11.1133 11.9636C10.9923 12.0138 10.8824 12.0874 10.7899 12.1801L4.69991 18.2801C4.60721 18.3726 4.53366 18.4825 4.48348 18.6034C4.4333 18.7244 4.40747 18.8541 4.40747 18.9851C4.40747 19.116 4.4333 19.2457 4.48348 19.3667C4.53366 19.4877 4.60721 19.5975 4.69991 19.6901L4.78991 19.7801C5.17991 20.1701 5.81991 20.1701 6.19991 19.7801Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default GrowingChartIcon;
