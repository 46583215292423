import React, { FunctionComponent } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface DragDropContainerProps {
    /**The data source of draggable elements. This will be returned onDragEnd*/
    sourceArray: any[];
    onDragEnd: (mutatedList: any[], unparsedReturn: any) => void;
    draggableItems: { id: string; element: JSX.Element }[];
    onDragStart?: (unparsedReturn: any) => void;
}

//https://github.com/atlassian/react-beautiful-dnd
//https://codesandbox.io/embed/github/eggheadio-projects/react-beautiful-dnd-task-app/tree/lesson-3/?hidenavigation=1

const DragDropContainer: FunctionComponent<DragDropContainerProps> = ({
    sourceArray,
    onDragEnd,
    draggableItems,
    onDragStart,
}) => {
    const OnDragEndHelper = (array, fromIndex, toIndex) => {
        if (fromIndex === toIndex) {
            return array;
        }

        const newArray = [...array];

        const element = newArray.splice(fromIndex, 1)[0]; // Remove the element from the array
        newArray.splice(toIndex, 0, element); // Insert the element at the desired index

        return newArray;
    };

    return (
        <DragDropContext
            onDragStart={(e) => (onDragStart ? onDragStart(e) : undefined)}
            onDragEnd={(e) => {
                const mutatedList = OnDragEndHelper(
                    sourceArray,
                    e.source.index,
                    e.destination.index
                );
                onDragEnd(mutatedList, e);
            }}
        >
            <Droppable droppableId={'singular-drop-section'}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {draggableItems.map((item, i) => (
                            <Draggable draggableId={item.id} index={i} key={item.id}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps} //spread provided.dragHandleProps on element that triggers dragging.
                                        // Ex if we need a specific drag handle this spread would be moved on the drag handle element
                                    >
                                        {item.element}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragDropContainer;
