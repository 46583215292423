import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'Stores/LanguageStore';
import './index.less';

interface LabelValueComponentProps {
    label: string;
    value?: string | null;
}

const LabelValueComponent: FunctionComponent<LabelValueComponentProps> = observer(
    ({ label, value }) => {
        const { languageStore } = useStores();
        const { t } = useTranslation();
        return (
            <div className="LabelValueComponent">
                <div
                    className={`label text-caption-1 text-mid-contrast ${
                        languageStore.currentLanguage === SupportedLanguage.EN ? 'capitalize' : ''
                    }`}
                >
                    {label}
                </div>
                {value ? (
                    <div className="text-body text-high-contrast">{value}</div>
                ) : (
                    <div className="text-body text-disabled">{t('n/a')}</div>
                )}
            </div>
        );
    }
);

export default LabelValueComponent;
