import { ClubCategoryDto } from 'Api/Features/Clubs/Dtos/ClubCategoryDto';
import Icon from 'Components/icons/Icon';
import CSS from 'csstype';
import React, { memo } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import './index.less';

interface ClubIconColorLabelProps {
    className?: string;
    clubCategory: ClubCategoryDto;
    type?: 'icon-label' | 'icon-color';
    background?: boolean;
    children?: React.ReactNode;
}

const ClubIconColorLabel: React.FunctionComponent<ClubIconColorLabelProps> = ({
    className,
    clubCategory,
    type,
    background = false,
    children,
}) => {
    const { t } = useTranslation();
    const isBackground = children !== undefined || background;
    const clubData = ClubCategoryIconMap.has(clubCategory)
        ? ClubCategoryIconMap.get(clubCategory)!
        : { iconName: 'WarningIcon', color: 'red' };

    const icon = <Icon iconName={clubData.iconName} />;
    const iconColorStyle = { color: clubData.color };
    let backgroundStyle: CSS.Properties | undefined = undefined;

    if (isBackground) {
        const backgroundIconColor = 'rgba(0, 0, 0, 0.06)';
        const textIcon = ReactDOMServer.renderToString(icon);
        const textSvg = textIcon
            .substring(textIcon.indexOf('<svg'), textIcon.indexOf('</span>'))
            .replace('currentColor', backgroundIconColor);

        backgroundStyle = {
            backgroundColor: clubData.color,
            backgroundImage: `url('data:image/svg+xml;utf8,${textSvg}')`,
        };
    }

    return (
        <div
            className={`ClubIconColorLabel ${className ? className : ''} ${type ? type : ''} ${
                isBackground ? 'background' : ''
            }`}
            style={backgroundStyle}
        >
            {type === 'icon-label' && (
                <>
                    {icon}
                    {t('Clubs.ClubCategory_' + clubCategory)}
                </>
            )}

            {type === 'icon-color' && <div style={iconColorStyle}>{icon}</div>}

            {isBackground && children}
        </div>
    );
};

export default memo(ClubIconColorLabel);
