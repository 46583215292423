import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { SupportedLanguage } from 'Stores/LanguageStore';

const LanguageCapitalizedText: FunctionComponent = observer(({ children }) => {
    const { languageStore } = useStores();

    return (
        <div
            className={`${
                languageStore.currentLanguage === SupportedLanguage.EN ? 'capitalize' : ''
            }`}
        >
            {children}
        </div>
    );
});

export default LanguageCapitalizedText;
