import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Horizontal3DotsIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <circle cx="6.23424" cy="14.0001" r="2.60533" fill={fill} />
                <circle cx="14.4449" cy="14.0001" r="2.60533" fill={fill} />
                <circle cx="22.6556" cy="14.0001" r="2.60533" fill={fill} />
            </svg>
        </span>
    );
};

export default Horizontal3DotsIcon;
