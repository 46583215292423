import { Col, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { MagnifyingGlassIcon } from 'Components/icons';
import Modal from 'Components/modal';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { default as React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from 'Services/UserService';
import { theme } from 'Style/theme';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import Button from 'Components/button';
import SelectedUsersComponent from 'Routes/settings/teams/components/selected-users-component';
import { ClubService } from 'Services/ClubService';
import { UpdateClubInvitationsRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubInvitationsRequestDto';
import { UpdateClubInvitationsEntryRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubInvitationsEntryRequestDto';
import { UpdateClubInvitationsActionDto } from 'Api/Features/Clubs/Dtos/UpdateClubInvitationsActionDto';
import './index.less';
import SubmitButton from 'Components/submit-button/submit-button';

interface ClubInviteModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    clubId: string;
}

const ClubInviteModal: React.FunctionComponent<ClubInviteModalProps> = observer(
    ({ visible, onComplete, clubId }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const { toastStore, globalLoadingStore } = useStores();
        const userService = useService(UserService);
        const clubService = useService(ClubService);

        const { asyncSingleSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetUsersRequestDto) =>
                    await userService.getUsers(request),
            },
            entityToSingleSelectCustomOption: (user: UserDto) =>
                ({
                    value: user.id,
                    label: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
                    extraData: user,
                } as SingleSelectCustomOption),
        });

        const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);

        const handleRemoveClick = (user: UserDto) => {
            setSelectedUsers((prev) => [...prev.filter((x) => x.id !== user.id)]);
        };

        //#region Submit / Exit
        const dismiss = (success = false): void => {
            form.resetFields();
            onComplete(success);
        };

        const submit = async (): Promise<void> => {
            try {
                globalLoadingStore.addLoading();
                const request: UpdateClubInvitationsRequestDto = {
                    entries: [
                        ...selectedUsers.map(
                            (x) =>
                                ({
                                    userId: x.id,
                                    action: UpdateClubInvitationsActionDto.Add,
                                } as UpdateClubInvitationsEntryRequestDto)
                        ),
                    ],
                };

                await clubService.updateClubInvitations(clubId, request);

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('club'),
                    }),
                });

                dismiss(true);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#region Submit / Exit

        return (
            <Modal
                className="ClubInviteModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t('Clubs.club_invite_coworkers')}
                oustideOverflow={{
                    element: (
                        <Form
                            layout="vertical"
                            className="padding-scroll container-scroll-actions-fixed"
                            onFinish={submit}
                            form={form}
                        >
                            <div className="text-caption-1 text-mid-contrast mb-10 t-align-center">
                                {t('Clubs.search_coworkers_add_club')}
                            </div>

                            <Row>
                                <Col span={10} offset={7}>
                                    <AsyncSingleSelect
                                        {...asyncSingleSelectProps}
                                        placeholder={t('search_for_entity', {
                                            param1: t('coworker'),
                                        })}
                                        selected={undefined}
                                        onChange={(value: any) => {
                                            setSelectedUsers((prev) => [
                                                ...prev.filter((x) => x.id !== value.extraData.id),
                                                value.extraData,
                                            ]);
                                        }}
                                        dropdownIndicatorIcon={
                                            <MagnifyingGlassIcon
                                                fill={theme['primary-mid-contrast']}
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form>
                    ),
                    height: 120,
                }}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                        <SubmitButton text={t('invite')} type="primary" onClick={submit} />
                    </>
                }
                fixedHeight
            >
                <SelectedUsersComponent
                    users={selectedUsers}
                    handleRemoveClick={handleRemoveClick}
                />
            </Modal>
        );
    }
);

export default ClubInviteModal;
