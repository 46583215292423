import { observer } from 'mobx-react';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { SlackService } from 'Services/SlackService';
import { ConnectSlackWorkspaceRequestDto } from 'Api/Features/Slack/Dtos/ConnectSlackWorkspaceRequestDto';
import Content from 'Components/Content';
import WhiteCard from 'Components/white-card';
import Skeleton from 'Components/skeleton';
import { settingsSlackIntegrationShape } from '../skeleton-shapes';
import { BellIcon, SlackIcon } from 'Components/icons';
import Button from 'Components/button';
import Divider from 'Components/divider';
import { Spin, Switch } from 'antd';
import './index.less';
import { SETTINGS_URL, SLACK_INTEGRATION_URL } from 'Models/Constants';
import { UpdateSlackConfigRequestDto } from 'Api/Features/Slack/Dtos/UpdateSlackConfigRequestDto';
import { LoadingOutlined } from '@ant-design/icons';
import { theme } from 'Style/theme';

const SlackIntegration: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const { globalLoadingStore, toastStore, slackParametersStore } = useStores();
    const slackService = useService(SlackService);
    const [connected, setConnected] = useState<boolean | undefined>(undefined);
    const [notificationState, setNotificationState] = useState(false);
    const [notificationIsLoading, setNotificationIsLoading] = useState(false);
    const [hasSlackConnectCode, setHasSlackConnectCode] = useState(false);

    useEffect(() => {
        setConnected(
            slackParametersStore.slackParameters?.slackWorkspace !== null &&
                slackParametersStore.slackParameters?.slackWorkspace !== undefined
        );
        setNotificationState(slackParametersStore.slackParameters?.notificationsEnabled ?? false);
    }, [slackParametersStore.slackParameters]);

    const fetchSlackParameters = useCallback(async (): Promise<void> => {
        try {
            await slackService.getSlackConfig();
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, slackService]);

    useEffect(() => {
        fetchSlackParameters();
    }, []);

    const onConnectSlackClick = useCallback(async () => {
        try {
            await slackService.runConnectSlackFlow();
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, []);

    const onDisconnectSlackClick = useCallback(async () => {
        try {
            globalLoadingStore.addLoading();
            await slackService.disconnectSlackWorkspace();
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, []);

    const onConnectSlackCallback = useCallback(async (code: string) => {
        try {
            globalLoadingStore.addLoading();
            const request: ConnectSlackWorkspaceRequestDto = {
                code,
            };
            await slackService.connectSlackWorkspace(request);
            await fetchSlackParameters();
            history.replace(SETTINGS_URL + SLACK_INTEGRATION_URL);
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, []);

    useEffect(() => {
        //must be done this way otherwise we get double call
        if (hasSlackConnectCode) {
            const code = urlParams.get('code');
            if (code) {
                onConnectSlackCallback(code);
            }
        }
    }, [hasSlackConnectCode]);

    useEffect(() => {
        if (urlParams) {
            const code = urlParams.get('code');
            if (code) {
                setHasSlackConnectCode(true);
            }
        }
    }, []);

    const changeNotificationSetting = async (notificationsEnabled: boolean) => {
        try {
            setNotificationIsLoading(true);
            const request: UpdateSlackConfigRequestDto = {
                notificationsEnabled,
            };
            await slackService.updateSlackConfig(request);
            const currentParams = slackParametersStore.slackParameters;
            if (currentParams)
                slackParametersStore.setParameters({
                    ...currentParams,
                    notificationsEnabled: notificationsEnabled,
                });
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        } finally {
            setNotificationIsLoading(false);
        }
    };

    return (
        <Content className="SlackIntegration">
            <div className="text-headline-bold mb-20">{t('Settings.menu_integration_title')}</div>

            <WhiteCard padding={40}>
                <div className={`white-card-inner-container text-body connected-${connected}`}>
                    <Skeleton
                        isLoaded={connected === true || connected === false}
                        placeholder={settingsSlackIntegrationShape}
                    >
                        <div className="connected-status-container">
                            <div className="slack-icon">
                                <SlackIcon />
                            </div>

                            <div className="text-body-bold">{t('slack_integration')}</div>

                            <div className="connected-label">
                                {connected ? t('connected') : t('not_connected')}
                            </div>
                        </div>

                        <Divider marginTop={20} marginBottom={40} />

                        {connected ? (
                            <>
                                <div className="notifications-container">
                                    <BellIcon />
                                    <div className="text-container">
                                        <div className="text-body-bold">{t('notifications')}</div>
                                        <div className="text-caption-2">
                                            {t('activate_deactivate_automatic_notif')}
                                        </div>
                                    </div>
                                    <div className="switch">
                                        {notificationIsLoading ? (
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: 20,
                                                            color: theme['secondary-color'],
                                                        }}
                                                        spin
                                                    />
                                                }
                                                size="large"
                                            />
                                        ) : (
                                            <Switch
                                                checked={notificationState}
                                                onChange={(e) => changeNotificationSetting(e)}
                                            />
                                        )}
                                    </div>
                                </div>

                                <Divider marginTop={40} marginBottom={40} />

                                <div className="connected-workplace-container">
                                    <div className="SlackConnectedItem">
                                        {slackParametersStore.slackParameters?.slackWorkspace
                                            ?.iconUrl && (
                                            <div
                                                className="image"
                                                style={{
                                                    backgroundImage: `url('${slackParametersStore.slackParameters?.slackWorkspace?.iconUrl}')`,
                                                }}
                                            />
                                        )}
                                        <div className="workspace-container">
                                            <div className="title text-caption-3">
                                                {t('workspace')}
                                            </div>
                                            <div className="company text-footnote-bold">
                                                {
                                                    slackParametersStore.slackParameters
                                                        ?.slackWorkspace?.name
                                                }
                                            </div>
                                            {slackParametersStore.slackParameters?.slackWorkspace
                                                ?.url && (
                                                <div className="slack-link text-caption-3-bold">
                                                    {
                                                        slackParametersStore.slackParameters
                                                            ?.slackWorkspace?.url
                                                    }
                                                </div>
                                            )}
                                        </div>

                                        <div className="enterprise-container">
                                            <div className="title text-caption-3">
                                                {t('enterprise')}
                                            </div>
                                            <div className="company text-footnote-bold">
                                                {
                                                    slackParametersStore.slackParameters
                                                        ?.slackEnterprise?.name
                                                }
                                            </div>
                                        </div>

                                        <div className="connected-btn">
                                            <Button
                                                className="action-btn"
                                                text={t('disconnect')}
                                                type="light"
                                                width="full"
                                                onClick={onDisconnectSlackClick}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="not-connected-btn">
                                {!connected && (
                                    <Button
                                        className="action-btn"
                                        text={t('connect')}
                                        type="primary"
                                        width="full"
                                        onClick={onConnectSlackClick}
                                    />
                                )}
                            </div>
                        )}
                    </Skeleton>
                </div>
            </WhiteCard>
        </Content>
    );
});

export default SlackIntegration;
