// GENERATED FILE - DO NOT MODIFY
import { UpdateUserGoogleCalendarAccountConfigRequestDto } from 'Api/Features/GoogleCalendar/Dtos/UpdateUserGoogleCalendarAccountConfigRequestDto';
import { UserGoogleCalendarAccountConfigDto } from 'Api/Features/GoogleCalendar/Dtos/UserGoogleCalendarAccountConfigDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class GoogleCalendarProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUserGoogleCalendarAccountConfig(userId: string): Promise<UserGoogleCalendarAccountConfigDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/google-calendar-account-config",
            { userId: userId },
            null
        );

        const data: UserGoogleCalendarAccountConfigDto | null = await this.httpClient.get<UserGoogleCalendarAccountConfigDto | null>(uri);
        return data;
    }

    public async updateUserGoogleCalendarAccountConfig(userId: string, request: UpdateUserGoogleCalendarAccountConfigRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/google-calendar-account-config",
            { userId: userId },
            null
        );

        await this.httpClient.put<UpdateUserGoogleCalendarAccountConfigRequestDto | null, void>(uri, request);
    }
}