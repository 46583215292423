import { Form, Tabs } from 'antd';
import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import { CreateUserScheduleEventRequestDto } from 'Api/Features/Schedules/Dtos/CreateUserScheduleEventRequestDto';
import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { ScheduleDto } from 'Api/Features/Schedules/Dtos/ScheduleDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { ScheduleEventSourceDto } from 'Api/Features/Schedules/Dtos/ScheduleEventSourceDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import Button from 'Components/button';
import { DeleteIcon, WarningIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { DayReplicatorStateEnum } from 'Components/weekly-hour-pref-calendar/components/day-replicator';
import { useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import {
    FORMAT_DAY_WRITTEN,
    MINIMUM_TIME_PERIOD_MINUTES,
    TWENTY_FOUR_HOUR_MINUTE,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimePeriodSchema } from 'Schemas/SchedulePreferenceSchema';
import { ScheduleService } from 'Services/ScheduleService';
import { theme } from 'Style/theme';
import { eventOverlapsEvent } from 'Utils/CalendarUtils';
import {
    createTimeMomentInTimezoneWithoutConverting,
    createTimezonedMomentFromDateAndTime,
    dayIndexToDayOfWeekDto,
    dayOfWeekLangMap,
    getWrittendDayIndex,
} from 'Utils/TimeUtils';
import AwayTab from './away-tab';
import './time-period-modal.less';
import WorkTab from './work-tab';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';
import Modal, { ModalHeaderColor } from 'Components/modal';
import SpaceViewerModalContent, {
    SpaceViewerScheduleType,
} from 'Components/space-viewer-modal/components/space-viewer-modal-content';
import SubmitButton from 'Components/submit-button/submit-button';
import ViewTypeToggle, {
    SpaceViewerViewType,
} from 'Components/space-viewer-modal/components/view-type-toggle';
import { FloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanFloorDto';
import { useFetch } from 'Hooks/use-fetch';
import { FloorPlanDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanDto';
import { FloorPlanService } from 'Services/FloorPlanService';
import FloorPlanViewer from 'Components/space-viewer-modal/components/floor-plan-viewer/floor-plan-viewer';
import Skeleton from 'Components/skeleton';

interface TimePeriodModalProps {
    visible: boolean;
    onComplete: (
        newEvents?: CreateUserScheduleEventRequestDtoExtended,
        removeEvent?: RemoveEvent[]
    ) => void;
    isEditing?: boolean;
    startTime?: string;
    endTime?: string;
    startDate?: string;
    endDate?: string;
    weekRange: string[];
    office?: SingleSelectCustomOption;
    space?: SingleSelectCustomOption;
    isOffHour?: boolean;
    workType?: WorkTypeDto;
    apiEventStartString?: string;
    apiEventEndString?: string;
    calendarEvents: ScheduleEventDto[];
    id?: string;
    source?: ScheduleEventSourceDto;
    timePeriodType: TimePeriodType;
    desk?: string;
    floor?: string;
}

export enum TimePeriodType {
    WorkPeriod = 'WorkPeriod',
    AwayPeriod = 'AwayPeriod',
}

export interface RemoveEvent {
    id?: string;
    eventStartString?: string;
    eventEndString?: string;
    source?: ScheduleEventSourceDto;
    isDefaultScheduleEventDelete: boolean;
}

export interface CreateUserScheduleEventRequestDtoExtended
    extends CreateUserScheduleEventRequestDto {
    source?: string;
    id?: string;
}

const TimePeriodModal: React.FunctionComponent<TimePeriodModalProps> = observer(
    ({
        visible,
        onComplete,
        isEditing,
        startTime: startTimeProp,
        endTime: endTimeProp,
        startDate: startDateProp,
        endDate: endDateProp,
        weekRange,
        office,
        space,
        isOffHour,
        workType,
        calendarEvents,
        apiEventStartString: eventStartString,
        apiEventEndString: eventEndString,
        id,
        source,
        timePeriodType,
        desk,
        floor,
    }) => {
        const { t } = useTranslation();
        const {
            userStore,
            confirmationModalStore,
            globalLoadingStore,
            toastStore,
            policyStore,
            languageStore,
        } = useStores();
        const scheduleService = useService(ScheduleService);
        const floorPlanService = useService(FloorPlanService);
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
            TimePeriodSchema,
            form
        );
        const [capacityBlocksSubmit, setCapacityBlocksSubmit] = useState(false);

        const [userTimeZone, setUserTimezone] = useState<string | null>();
        const [selectedOffice, setSelectedOffice] = useState<SingleSelectCustomOption | undefined>(
            office ? office : undefined
        );
        const [selectedSpace, setSelectedSpace] = useState<SingleSelectCustomOption | undefined>(
            space
        );
        const [spaceViewerSelectedSpace, setSpaceViewerSelectedSpace] = useState<
            SingleSelectCustomOption | undefined
        >(space); //user can cancel when in space viewer so only set selectedSpace when confirmed
        const [selectedWorkType, setSelectedWorkType] = useState<UserPreferredWorkTypeDto>(
            workType
                ? UserPreferredWorkTypeDto[workType]
                : userStore.userInfo?.preferredWorkType ?? UserPreferredWorkTypeDto.Office
        );
        const [offHourIsChecked, setOffHourIsChecked] = useState(isOffHour);
        const [selectedStartDayWritten, setSelectedStartDayWritten] = useState<string>();
        const [selectedEndDayWritten, setSelectedEndDayWritten] = useState<string>();
        const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment>();
        const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment>();
        const [startTime, setStartTime] = useState<string | undefined>(startTimeProp);
        const [endTime, setEndTime] = useState<string | undefined>(endTimeProp);

        const [replicateIsChecked, setReplicateIsChecked] = useState(false);
        const [replicateDaysState, setReplicateDaysState] = useState<DayReplicatorStateEnum[]>([]);

        const [selectedTimePeriodType, setSelectedTimePeriodType] =
            useState<TimePeriodType>(timePeriodType);
        const [allDayChecked, setAllDayChecked] = useState(false);

        const [spaceViewerVisible, setSpaceViewerVisible] = useState(false);
        const [spaceViewerType, setSpaceViewerType] = useState<SpaceViewerViewType>(
            SpaceViewerViewType.SpaceViewer
        );

        const [floorPlanFloors, setFloorPlanFloors] = useState<FloorPlanFloorDto[]>([]);
        const { apiRequest, loadingStateKeys } = useFetch();

        //floor plan must be fetched in order to determine if the view switcher is active
        const fetchFloorPlan = useCallback(
            async (officeId: string) => {
                const floorPlan: FloorPlanDto = await apiRequest({
                    requestFunction: () => floorPlanService.getFloorPlan(officeId),
                    requestParameters: undefined,
                    loadingStateKey: 'floorplan',
                });
                if (floorPlan) {
                    setFloorPlanFloors(
                        floorPlan.floors?.filter((x) => x !== null).map((x) => x!) ?? []
                    );
                }
            },
            [floorPlanService]
        );

        useEffect(() => {
            if (selectedOffice?.value && spaceViewerVisible) fetchFloorPlan(selectedOffice.value);
        }, [selectedOffice, spaceViewerVisible]);

        useEffect(() => {
            setUserTimezone(userStore.userInfo?.timeZone);
        }, [userStore.userInfo?.timeZone]);

        useEffect(() => {
            form.setFieldsValue({
                desk: desk,
                floor: floor,
            });
        }, [desk, floor]);

        useEffect(() => {
            setReplicateIsChecked(false);
        }, [selectedEndDate, selectedStartDate]);

        useEffect(() => {
            if (startDateProp) {
                const dayWritten =
                    createTimeMomentInTimezoneWithoutConverting(startDateProp).format(
                        FORMAT_DAY_WRITTEN
                    );
                setSelectedStartDayWritten(dayWritten);
                setSelectedStartDate(createTimeMomentInTimezoneWithoutConverting(startDateProp));
                form.setFieldsValue({
                    startDate: dayWritten,
                });
            }
        }, [startDateProp]);

        useEffect(() => {
            if (endDateProp) {
                const dayWritten =
                    createTimeMomentInTimezoneWithoutConverting(endDateProp).format(
                        FORMAT_DAY_WRITTEN
                    );
                setSelectedEndDayWritten(dayWritten);
                setSelectedEndDate(createTimeMomentInTimezoneWithoutConverting(endDateProp));
                form.setFieldsValue({
                    endDate: dayWritten,
                });
            }
        }, [endDateProp]);

        useEffect(() => {
            //replication days enabling state
            if (selectedStartDate) {
                const dayArray: DayReplicatorStateEnum[] = [];
                const selectedDayIndex = selectedStartDate.day();

                Object.keys(DayOfWeekDto).forEach((day, dayIndex) => {
                    //event day is mandatory
                    if (dayIndex === selectedDayIndex) {
                        dayArray[dayIndex] = DayReplicatorStateEnum.Mandatory;
                    }
                    //if no restrictions, all days are available
                    else if (!policyStore.policyInfo?.restrictions) {
                        dayArray[dayIndex] = DayReplicatorStateEnum.Normal;
                    } else {
                        if (
                            //if restrictions, day is available if in daysOfWeek list
                            policyStore.policyInfo.restrictions.daysOfWeek?.some(
                                (daysOfWeek: DayOfWeekDto) => day === daysOfWeek
                            ) && //but is restricted if the event end spans into a restricted day
                            (selectedEndDate?.date() === selectedStartDate.date() ||
                                policyStore.policyInfo.restrictions.daysOfWeek?.some(
                                    (daysOfWeek: DayOfWeekDto) =>
                                        dayIndexToDayOfWeekDto(dayIndex + 1) === daysOfWeek
                                ))
                        ) {
                            dayArray[dayIndex] = DayReplicatorStateEnum.Normal;
                        }
                        //day is restricted
                        else dayArray[dayIndex] = DayReplicatorStateEnum.Disabled;
                    }
                });

                setReplicateDaysState(dayArray);
            }
        }, [
            policyStore.policyInfo?.restrictions,
            selectedStartDate,
            selectedEndDate,
            selectedSpace?.value,
        ]);

        const startDayOptions = useCallback((): SingleSelectCustomOption[] => {
            const date = moment().startOf('week');
            const options: SingleSelectCustomOption[] = [];
            for (let index = 0; index < 7; index++) {
                options.push({
                    label:
                        date.format(FORMAT_DAY_WRITTEN).charAt(0).toUpperCase() +
                        date.format(FORMAT_DAY_WRITTEN).slice(1), //capitalise
                    value: date.format(FORMAT_DAY_WRITTEN),
                    isDisabled:
                        selectedTimePeriodType !== TimePeriodType.AwayPeriod && // no restrictions on away periods
                        policyStore.policyInfo?.restrictions !== null &&
                        policyStore.policyInfo?.restrictions !== undefined &&
                        !policyStore?.policyInfo?.restrictions?.daysOfWeek?.some(
                            (day) =>
                                dayOfWeekLangMap(day, languageStore.currentLanguage) ===
                                date.format(FORMAT_DAY_WRITTEN)
                        ),
                });
                date.add(1, 'day');
            }
            return options;
        }, [policyStore.policyInfo?.restrictions]);

        useEffect(() => {
            policyStore.cachedSetPolicyRestrictions();
        }, []);

        const endDayOptions = useCallback((): SingleSelectCustomOption[] => {
            const date = moment().day(
                getWrittendDayIndex(selectedStartDayWritten ?? '', languageStore.currentLanguage)
            );
            const options: SingleSelectCustomOption[] = [];
            for (let index = 0; selectedStartDayWritten ? index < 2 : index < 7; index++) {
                options.push({
                    label:
                        date.format(FORMAT_DAY_WRITTEN).charAt(0).toUpperCase() +
                        date.format(FORMAT_DAY_WRITTEN).slice(1), //capitalise,
                    value: date.format(FORMAT_DAY_WRITTEN),
                });
                date.add(1, 'day');
            }
            return options;
        }, [selectedStartDayWritten, policyStore.policyInfo?.restrictions]);

        useEffect(() => {
            //Clear value of endTime if it is not valid
            if (selectedStartDayWritten && selectedEndDayWritten && startTime && endTime) {
                if (startTime >= endTime && selectedStartDayWritten === selectedEndDayWritten)
                    setEndTime(undefined);
                if (selectedStartDayWritten !== selectedEndDayWritten && endTime > startTime)
                    setEndTime(undefined);
            }
        }, [selectedStartDayWritten, selectedEndDayWritten, startTime, endTime]);

        useEffect(() => {
            //Clear end date value if not valid
            if (selectedStartDayWritten && selectedEndDayWritten) {
                const startIndex = getWrittendDayIndex(
                    selectedStartDayWritten,
                    languageStore.currentLanguage
                );
                const endIndex = getWrittendDayIndex(
                    selectedEndDayWritten,
                    languageStore.currentLanguage
                );

                /*sunday is valid if sat is start because we will consider sunday of next week*/
                if (selectedStartDayWritten === 'Saturday' && selectedEndDayWritten === 'Sunday')
                    return;
                if (startIndex > endIndex || endIndex - startIndex > 2) {
                    setSelectedEndDate(undefined);
                    setSelectedEndDayWritten(undefined);
                }
            }
        }, [selectedStartDayWritten, selectedEndDayWritten]);

        const onStartDayChange = (writtenDay: string) => {
            const dayIndex = getWrittendDayIndex(writtenDay, languageStore.currentLanguage);
            setSelectedStartDayWritten(writtenDay);
            setSelectedStartDate(moment(weekRange[0]).day(dayIndex));
            if (allDayChecked && selectedTimePeriodType === TimePeriodType.AwayPeriod) {
                onEndDayChange(
                    moment(weekRange[0])
                        .day(
                            getWrittendDayIndex(writtenDay ?? '', languageStore.currentLanguage) + 1
                        )
                        .format(FORMAT_DAY_WRITTEN)
                );
            }
        };

        const onEndDayChange = (writtenDay: string) => {
            const dayIndex = getWrittendDayIndex(writtenDay, languageStore.currentLanguage);
            setSelectedEndDayWritten(writtenDay);
            //When saturday is selected as start day sunday means sunday of next week
            if (
                (selectedStartDayWritten === 'Saturday' && writtenDay === 'Sunday') ||
                (allDayChecked && writtenDay === 'Sunday')
            )
                setSelectedEndDate(moment(weekRange[0]).day(dayIndex).add(1, 'week'));
            else setSelectedEndDate(moment(weekRange[0]).day(dayIndex));
        };

        const getMinimumEndTimeAvailable = useCallback((): string => {
            if (allDayChecked && selectedTimePeriodType === TimePeriodType.AwayPeriod)
                return moment().startOf('day').format(TWENTY_FOUR_HOUR_MINUTE);

            if (selectedStartDayWritten === selectedEndDayWritten && startTime === '23:45')
                return '23:45';

            return selectedStartDayWritten === selectedEndDayWritten
                ? moment()
                      .startOf('day')
                      .add(startTime)
                      .add(MINIMUM_TIME_PERIOD_MINUTES, 'minute')
                      .format(TWENTY_FOUR_HOUR_MINUTE)
                : moment().startOf('day').format(TWENTY_FOUR_HOUR_MINUTE);
        }, [selectedStartDayWritten, selectedEndDayWritten, startTime, allDayChecked]);

        const getMaximumEndTimeAvailable = useCallback((): string | undefined => {
            if (allDayChecked && selectedTimePeriodType === TimePeriodType.AwayPeriod)
                return undefined;

            if (selectedEndDayWritten === selectedStartDayWritten) return undefined;

            return startTime;
        }, [selectedStartDayWritten, selectedEndDayWritten, startTime, allDayChecked]);

        const handleAllDayChange = (checked: boolean) => {
            setAllDayChecked(checked);
            if (checked) {
                setStartTime('00:00');
                setEndTime('00:00');
                if (selectedStartDayWritten) {
                    const endMoment = moment(weekRange[0]).day(
                        getWrittendDayIndex(
                            selectedStartDayWritten ?? '',
                            languageStore.currentLanguage
                        ) + 1
                    );
                    setSelectedEndDayWritten(endMoment.format(FORMAT_DAY_WRITTEN));
                    setSelectedEndDate(endMoment);
                }
            }
        };

        //#region Submit / Exit
        const dismiss = (
            newEvent?: CreateUserScheduleEventRequestDtoExtended,
            removeEvent?: RemoveEvent[]
        ): void => {
            form.resetFields();
            onComplete(newEvent, removeEvent);
        };

        const getOverlappingEvents = async (
            newEvent: CreateUserScheduleEventRequestDtoExtended
        ): Promise<ScheduleEventDto[]> => {
            let events = calendarEvents;
            //if new event spills into next week, we must get events for next week to check overlaps
            if (
                moment.tz(newEvent.startTime, userTimeZone ?? '').week() !==
                moment.tz(newEvent.endTime, userTimeZone ?? '').week()
            ) {
                const nextWeekEvents = await fetchScheduleEventsForNextWeekDay();
                if (nextWeekEvents) events = [...events, ...nextWeekEvents];
            }
            const overlappingEvents = events
                //if editing an existing event, filter out that event
                .filter((event) => (isEditing ? event.id !== newEvent.id : true))
                //check for overlaps
                .filter((event) =>
                    eventOverlapsEvent(newEvent as ScheduleEventDto, event, userTimeZone ?? '')
                );

            return overlappingEvents;
        };

        const fetchScheduleEventsForNextWeekDay = useCallback(async (): Promise<
            ScheduleEventDto[]
        > => {
            globalLoadingStore.addLoading();

            const request: GetUserScheduleRequestDto = {
                week: moment(weekRange[0]).add(1, 'week').format(FORMAT_YEAR_MONTH_DAY),
                timeZone: userStore.userInfo?.timeZone,
            };
            try {
                const schedule: ScheduleDto | null = await scheduleService.getUserSchedule(
                    userStore.userInfo!.id!,
                    request
                );

                return schedule?.events?.filter((x) => x !== null).map((x) => x!) ?? [];
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
                throw e;
            } finally {
                globalLoadingStore.removeLoading();
            }
        }, [globalLoadingStore, toastStore, scheduleService]);

        const confirmOverlapOverwrite = async (): Promise<boolean> => {
            return await confirmationModalStore.confirm({
                icon: <WarningIcon fill={theme['warning-mid-contrast']} />,
                title: t('PrefCalendar.overwrite_confirm_title'),
                message: t('PrefCalendar.overwrite_confirm_message'),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            });
        };

        const submit = async (): Promise<void> => {
            if (capacityBlocksSubmit) return;
            try {
                const formData = form.getFieldsValue();
                const validationObject = {
                    startTime: startTime,
                    endTime: endTime,
                    startDate: selectedStartDayWritten,
                    endDate: selectedEndDayWritten,
                };

                if (!(await validateForm(validationObject))) return;

                const isAwayPeriod = selectedTimePeriodType === TimePeriodType.AwayPeriod;

                const replicatedEvents: CreateUserScheduleEventRequestDtoExtended[] = [];
                const replicateDaysOfWeek: DayOfWeekDto[] = [];
                if (replicateIsChecked) {
                    replicateDaysState.forEach((replicateDayState, index) => {
                        if (replicateDayState === DayReplicatorStateEnum.Active) {
                            replicateDaysOfWeek.push(Object.values(DayOfWeekDto)[index]);

                            //create event object to check overlaps
                            replicatedEvents.push({
                                startTime: createTimezonedMomentFromDateAndTime(
                                    moment(selectedStartDate)
                                        .day(index)
                                        .format(FORMAT_YEAR_MONTH_DAY),
                                    startTime!,
                                    userTimeZone ?? ''
                                ).format(),
                                endTime: createTimezonedMomentFromDateAndTime(
                                    moment(selectedEndDate)
                                        .day(
                                            index +
                                                (selectedStartDate?.day() !== selectedEndDate?.day()
                                                    ? 1
                                                    : 0)
                                        )
                                        .format(FORMAT_YEAR_MONTH_DAY),
                                    endTime!,
                                    userTimeZone ?? ''
                                ).format(),
                            });
                        }
                    });
                }

                const newEvent: CreateUserScheduleEventRequestDtoExtended = {
                    workType: isAwayPeriod ? WorkTypeDto.Away : WorkTypeDto[selectedWorkType],
                    type: isAwayPeriod
                        ? EventTypeDto.Away
                        : offHourIsChecked
                        ? EventTypeDto.OffHourAvailability
                        : EventTypeDto.Work,
                    startTime: createTimezonedMomentFromDateAndTime(
                        moment(selectedStartDate).format(FORMAT_YEAR_MONTH_DAY),
                        startTime!,
                        userTimeZone ?? ''
                    ).format(),
                    endTime: createTimezonedMomentFromDateAndTime(
                        moment(selectedEndDate).format(FORMAT_YEAR_MONTH_DAY),
                        endTime!,
                        userTimeZone ?? ''
                    ).format(),
                    officeId: isAwayPeriod ? undefined : selectedOffice?.value,
                    officeSpaceId: isAwayPeriod ? undefined : selectedSpace?.value,
                    floor: isAwayPeriod ? undefined : formData.floor,
                    desk: isAwayPeriod ? undefined : formData.desk,
                    source: source,
                    id: id,
                    replicateDaysOfWeek: replicateDaysOfWeek,
                };

                const newEvents: CreateUserScheduleEventRequestDtoExtended[] = [
                    newEvent,
                    ...replicatedEvents,
                ];

                const overlappingEvents = (
                    await Promise.all(newEvents.map((event) => getOverlappingEvents(event)))
                ).flatMap((x) => x);

                if (overlappingEvents.length < 1 || (await confirmOverlapOverwrite())) {
                    let removeEvents: RemoveEvent[] = [];
                    removeEvents.push(
                        ...overlappingEvents.map(
                            (event) =>
                                ({
                                    id: undefined,
                                    eventStartString: event.startTime ?? '',
                                    eventEndString: event.endTime ?? '',
                                } as RemoveEvent)
                        )
                    );
                    //when editing a period that comes from defaultschedule in plan schedule mode, that event needs to be overrided
                    if (newEvent.source === ScheduleEventSourceDto.DefaultSchedule) {
                        removeEvents = [];
                        removeEvents.push({
                            eventStartString: eventStartString!,
                            eventEndString: eventEndString!,
                            id: undefined,
                            source: ScheduleEventSourceDto.DefaultSchedule,
                            isDefaultScheduleEventDelete: false,
                        });
                    }

                    dismiss(newEvent, removeEvents);
                }
            } catch (e) {
                //error fetching next week schedule. Just do not dismiss
            }
        };

        const onDeleteEvent = async (removeEvent: RemoveEvent): Promise<void> => {
            dismiss(undefined, [removeEvent]);
        };
        //#endregion

        //#region Render
        return (
            <Modal
                centered
                footer={
                    spaceViewerVisible ? (
                        <>
                            <Button
                                text={t('cancel')}
                                type="tertiary"
                                onClick={() => {
                                    setSpaceViewerVisible(false);
                                    setSpaceViewerType(SpaceViewerViewType.SpaceViewer);
                                    setSpaceViewerSelectedSpace(selectedSpace);
                                }}
                            />
                            <Button
                                text={t('confirm')}
                                type="primary"
                                onClick={() => {
                                    setSpaceViewerVisible(false);
                                    setSpaceViewerType(SpaceViewerViewType.SpaceViewer);
                                    setSelectedSpace(spaceViewerSelectedSpace);
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Button text={t('cancel')} type="tertiary" onClick={() => dismiss()} />
                            <SubmitButton
                                text={isEditing ? t('save') : t('add')}
                                type="primary"
                                onClick={() => submit()}
                            />
                        </>
                    )
                }
                width={
                    !spaceViewerVisible
                        ? 640
                        : spaceViewerType == SpaceViewerViewType.SpaceViewer
                        ? 640
                        : 1200
                }
                onCancel={() => dismiss()}
                visible={visible}
                closeIcon={<Icon iconName="CloseIcon" fill={theme['text-mid-contrast']} />}
                wrapClassName="TimePeriodModalWrapper"
                headerColor={ModalHeaderColor.white}
                headerText={t('PrefCalendar.time_period')}
                headerActionIcon={
                    isEditing ? (
                        <DeleteIcon
                            onClick={() =>
                                onDeleteEvent({
                                    id,
                                    eventStartString,
                                    eventEndString,
                                    source: source,
                                    isDefaultScheduleEventDelete:
                                        source === ScheduleEventSourceDto.DefaultSchedule,
                                })
                            }
                            fill={theme['text-mid-contrast']}
                            width={25}
                            height={25}
                        />
                    ) : null
                }
                noPadding
            >
                <Form
                    layout="vertical"
                    form={form}
                    className={`TimePeriodModal-form ${spaceViewerVisible && 'grey'}`}
                >
                    <div
                        className={`TimePeriodModal ${
                            spaceViewerVisible &&
                            spaceViewerType == SpaceViewerViewType.FloorPlan &&
                            'floor-plan-visible'
                        }`}
                    >
                        <div
                            className={`time-period-container ${spaceViewerVisible && 'fade-out'}`}
                        >
                            <Tabs
                                activeKey={
                                    selectedTimePeriodType === TimePeriodType.WorkPeriod ? '1' : '2'
                                }
                                onChange={(e) => {
                                    setSelectedTimePeriodType(() => {
                                        if (e === '1') return TimePeriodType.WorkPeriod;
                                        else return TimePeriodType.AwayPeriod;
                                    });
                                }}
                            >
                                <Tabs.TabPane tab={t('work_period')} key="1">
                                    <WorkTab
                                        errors={errors}
                                        offHourIsChecked={offHourIsChecked}
                                        handleOffHourChange={() =>
                                            setOffHourIsChecked((old) => !old)
                                        }
                                        startDayOptions={startDayOptions()}
                                        selectedStartDayWritten={selectedStartDayWritten}
                                        onStartDayChange={(writtenDay) =>
                                            onStartDayChange(writtenDay)
                                        }
                                        onStartTimeChange={(value: string) => setStartTime(value)}
                                        startTime={startTime}
                                        selectedStartDate={selectedStartDate}
                                        endTime={endTime}
                                        endDayOptions={endDayOptions()}
                                        selectedEndDayWritten={selectedEndDayWritten}
                                        onEndDayChange={(writtenDay: string) =>
                                            onEndDayChange(writtenDay)
                                        }
                                        onEndTimeChange={(value: string) => setEndTime(value)}
                                        selectedEndDate={selectedEndDate}
                                        getMinimumEndTimeAvailable={getMinimumEndTimeAvailable}
                                        getMaximumEndTimeAvailable={getMaximumEndTimeAvailable}
                                        selectedWorkType={selectedWorkType}
                                        onWorkTypeChange={(type: UserPreferredWorkTypeDto) =>
                                            setSelectedWorkType(type)
                                        }
                                        selectedOffice={selectedOffice}
                                        onOfficeChange={(option?: SingleSelectCustomOption) => {
                                            setSelectedOffice(option);
                                            setSelectedSpace(undefined);
                                        }}
                                        selectedSpace={selectedSpace}
                                        onSpaceChange={(option?: SingleSelectCustomOption) => {
                                            setSelectedSpace(option);
                                            setSpaceViewerSelectedSpace(option);
                                        }}
                                        replicateIsChecked={replicateIsChecked}
                                        onReplicateChange={() =>
                                            setReplicateIsChecked((old) => !old)
                                        }
                                        replicateDaysState={replicateDaysState}
                                        onReplicateDaysStateChange={(
                                            dayIndex: number,
                                            customState?: DayReplicatorStateEnum
                                        ) => {
                                            setReplicateDaysState((old) => {
                                                if (customState) {
                                                    old[dayIndex] = customState;
                                                    return [...old];
                                                }

                                                old[dayIndex] =
                                                    old[dayIndex] === DayReplicatorStateEnum.Active
                                                        ? DayReplicatorStateEnum.Normal
                                                        : DayReplicatorStateEnum.Active;
                                                return [...old];
                                            });
                                        }}
                                        setSpaceViewerVisible={(value: boolean) =>
                                            setSpaceViewerVisible(value)
                                        }
                                        form={form}
                                        setErrors={setErrors}
                                        resetErrors={resetErrors}
                                        setCapacityBlocksSubmit={(value: boolean) =>
                                            setCapacityBlocksSubmit(value)
                                        }
                                    />
                                </Tabs.TabPane>

                                <Tabs.TabPane tab={t('away_period')} key="2">
                                    <AwayTab
                                        errors={errors}
                                        startDayOptions={startDayOptions()}
                                        selectedStartDayWritten={selectedStartDayWritten}
                                        onStartDayChange={(writtenDay) =>
                                            onStartDayChange(writtenDay)
                                        }
                                        onStartTimeChange={(value: string) => setStartTime(value)}
                                        startTime={startTime}
                                        selectedStartDate={selectedStartDate}
                                        endTime={endTime}
                                        endDayOptions={endDayOptions()}
                                        selectedEndDayWritten={selectedEndDayWritten}
                                        onEndDayChange={(writtenDay: string) =>
                                            onEndDayChange(writtenDay)
                                        }
                                        onEndTimeChange={(value: string) => setEndTime(value)}
                                        selectedEndDate={selectedEndDate}
                                        getMinimumEndTimeAvailable={getMinimumEndTimeAvailable}
                                        getMaximumEndTimeAvailable={getMaximumEndTimeAvailable}
                                        replicateIsChecked={replicateIsChecked}
                                        onReplicateChange={() =>
                                            setReplicateIsChecked((old) => !old)
                                        }
                                        replicateDaysState={replicateDaysState}
                                        onReplicateDaysStateChange={(
                                            dayIndex: number,
                                            customState?: DayReplicatorStateEnum
                                        ) => {
                                            setReplicateDaysState((old) => {
                                                if (customState) {
                                                    old[dayIndex] = customState;
                                                    return [...old];
                                                }

                                                old[dayIndex] =
                                                    old[dayIndex] === DayReplicatorStateEnum.Active
                                                        ? DayReplicatorStateEnum.Normal
                                                        : DayReplicatorStateEnum.Active;
                                                return [...old];
                                            });
                                        }}
                                        allDayChecked={allDayChecked}
                                        onAllDayChange={(checked: boolean) =>
                                            handleAllDayChange(checked)
                                        }
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>

                        {spaceViewerVisible ? (
                            <div className="space-viewer-time-period-container">
                                <div className="viewer-type-container">
                                    <div className="text-callout">{t('choose_a_space')}</div>
                                    <Skeleton
                                        isLoaded={!loadingStateKeys.has('floorplan')}
                                        defaultPadding={false}
                                        placeholder={
                                            <div
                                                style={{
                                                    width: 'auto',
                                                    marginLeft: 'auto',
                                                }}
                                            >
                                                <div
                                                    className="roundRect"
                                                    style={{ width: 82, height: 47 }}
                                                />
                                            </div>
                                        }
                                    >
                                        <ViewTypeToggle
                                            selected={spaceViewerType}
                                            onChange={(selected: SpaceViewerViewType) => {
                                                setSpaceViewerType(selected);
                                            }}
                                            floorPlanAvailable={floorPlanFloors.length > 0}
                                        />
                                    </Skeleton>
                                </div>
                                {spaceViewerType == SpaceViewerViewType.SpaceViewer ? (
                                    <SpaceViewerModalContent
                                        officeId={selectedOffice?.value ?? ''}
                                        selectedSpace={spaceViewerSelectedSpace}
                                        setSelectedSpace={setSpaceViewerSelectedSpace}
                                        timeRange={{
                                            startTime: createTimezonedMomentFromDateAndTime(
                                                moment(selectedStartDate).format(
                                                    FORMAT_YEAR_MONTH_DAY
                                                ),
                                                startTime ?? '',
                                                userTimeZone ?? ''
                                            ).format(),
                                            endTime: createTimezonedMomentFromDateAndTime(
                                                moment(selectedEndDate).format(
                                                    FORMAT_YEAR_MONTH_DAY
                                                ),
                                                endTime ?? '',
                                                userTimeZone ?? ''
                                            ).format(),
                                        }}
                                        scheduleType={SpaceViewerScheduleType.schedule}
                                    />
                                ) : (
                                    <FloorPlanViewer
                                        floorPlanFloors={floorPlanFloors}
                                        timeRange={{
                                            startTime: createTimezonedMomentFromDateAndTime(
                                                moment(selectedStartDate).format(
                                                    FORMAT_YEAR_MONTH_DAY
                                                ),
                                                startTime ?? '',
                                                userTimeZone ?? ''
                                            ).format(),
                                            endTime: createTimezonedMomentFromDateAndTime(
                                                moment(selectedEndDate).format(
                                                    FORMAT_YEAR_MONTH_DAY
                                                ),
                                                endTime ?? '',
                                                userTimeZone ?? ''
                                            ).format(),
                                        }}
                                        selectedSpace={spaceViewerSelectedSpace}
                                        setSelectedSpace={setSpaceViewerSelectedSpace}
                                        scheduleType={SpaceViewerScheduleType.schedule}
                                    />
                                )}
                            </div>
                        ) : (
                            <div></div> //always render an element this helps with animation
                        )}
                    </div>
                </Form>
            </Modal>
        );
        //#endregion
    }
);

export default React.memo(TimePeriodModal);
