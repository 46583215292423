import { FloorPlanDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanDto';
import { FloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanFloorDto';
import { UpdateFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/UpdateFloorPlanRequestDto';
import Button from 'Components/button';
import { useService, useStores } from 'Hooks';
import { useFetch } from 'Hooks/use-fetch';
import FloorModal from 'Components/floor-plan/floor-modal/floor-modal';
import { FloorPlanService } from 'Services/FloorPlanService';
import {
    FloorPlanManager,
    FloorPlanOfficeSpaceAreaDtoWithAvailability,
    FloorPlanType,
} from 'Components/floor-plan/FloorPlanManager';
import React, { useCallback, useEffect, useState } from 'react';
import WhiteCard from 'Components/white-card';
import {
    EmptyBoxIcon,
    InformationCircleIcon,
    MagnifyingLessIcon,
    MagnifyingPlusIcon,
} from 'Components/icons';
import './floor-plan-tab.less';
import { useTranslation } from 'react-i18next';
import SpaceAreaCard from 'Components/floor-plan/space-area-card/space-area-card';
import AssignSpaceModal from 'Components/floor-plan/assign-space-modal/assign-space-modal';
import { theme } from 'Style/theme';
import { FloorPlanOfficeSpaceAreaDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanOfficeSpaceAreaDto';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import SubmitButton from 'Components/submit-button/submit-button';
import Skeleton from 'Components/skeleton';
import {
    floorPlanDropdownShape,
    floorPlanRoundButton,
    floorPlanShape,
    floorPlanSpacesShape,
} from '../skeleton-shapes';

interface FloorPlanTabProps {
    officeId: string;
    setNavigationBlocked: (blocked: boolean) => void;
}

const FloorPlanTab: React.FC<FloorPlanTabProps> = ({ officeId, setNavigationBlocked }) => {
    const { t } = useTranslation();
    const { toastStore } = useStores();
    const floorPlanService = useService(FloorPlanService);
    const { apiRequest, loadingStateKeys } = useFetch();
    const [floorPlanManager, setFloorPlanManager] = useState<FloorPlanManager>();
    const [floors, setFloors] = useState<FloorPlanFloorDto[]>([]);
    const [selectedFloorId, setSelectedFloorId] = useState<string>();
    const [currentSelectedSpaceId, setCurrentSelectedSpaceId] = useState<string | undefined>(
        undefined
    );
    const [floorModalState, setFloorModalState] = useState<{
        visible: boolean;
        floor?: FloorPlanFloorDto;
    }>({ visible: false });
    const [assignSpaceModalState, setAssignSpaceModalState] = useState<{
        visible: boolean;
        points: string;
    }>({ visible: false, points: '' });
    const [displayNoFloorPlan, setDisplayNoFloorPlan] = useState(false);
    const [displayNoSpaceAssigned, setDisplayNoSpaceAssigned] = useState(false);

    //Create floor manager object
    useEffect(() => {
        setFloorPlanManager(
            new FloorPlanManager(
                (officeSpace: FloorPlanOfficeSpaceAreaDtoWithAvailability) =>
                    setCurrentSelectedSpaceId(officeSpace.officeSpaceId),
                () => setCurrentSelectedSpaceId(undefined),
                FloorPlanType.editor,
                true,
                (spaceArea: FloorPlanOfficeSpaceAreaDto) =>
                    setAssignSpaceModalState({ visible: true, points: spaceArea.points ?? '' }),
                () => setNavigationBlocked(true)
            )
        );
    }, []);

    //Call the init on floor manager
    useEffect(() => {
        if (floorPlanManager) floorPlanManager.init();
    }, [floorPlanManager]);

    useEffect(() => {
        //initialise the floor in the manager
        if (selectedFloorId) {
            const floor = floors.find((floor) => floor.id === selectedFloorId);
            floorPlanManager?.initFloor(floor ?? null);
        } else {
            floorPlanManager?.initFloor(null);
        }
    }, [selectedFloorId, floorPlanManager]);

    useEffect(() => {
        fetchFloorPlan();
    }, []);

    useEffect(() => {
        //when to show no spaces assigned in right section
        if (
            !loadingStateKeys.has('floorplan') &&
            floors.length > 0 &&
            selectedFloorId &&
            floors.find((x) => x.id === selectedFloorId)?.officeSpaceAreas?.length === 0
        )
            setDisplayNoSpaceAssigned(true);
        else setDisplayNoSpaceAssigned(false);
    }, [floors, loadingStateKeys, selectedFloorId]);

    useEffect(() => {
        //when to show no floor plan in left section
        if (!loadingStateKeys.has('floorplan') && floors.length < 1) setDisplayNoFloorPlan(true);
        else setDisplayNoFloorPlan(false);
    }, [floors, loadingStateKeys]);

    const fetchFloorPlan = useCallback(async () => {
        const floorPlan: FloorPlanDto = await apiRequest({
            requestFunction: () => floorPlanService.getFloorPlan(officeId),
            requestParameters: undefined,
            loadingStateKey: 'floorplan',
        });
        if (floorPlan) {
            setFloors(floorPlan.floors?.filter((x) => x !== null).map((x) => x!) ?? []);
            setSelectedFloorId(floorPlan.floors?.[0]?.id);
        }
    }, [floorPlanService]);

    const submit = async () => {
        if (floorPlanManager) {
            const request: UpdateFloorPlanRequestDto = {
                floors: floors,
            };

            await apiRequest({
                requestFunction: (request) => floorPlanService.updateFloorPlan(officeId, request),
                requestParameters: request,
                useGlobalLoading: true,
            });

            setNavigationBlocked(false);

            toastStore.toast({
                type: 'success',
                message: t('Toast.success_message', {
                    param1: t('floor_plan'),
                }),
            });
        }
    };

    const onDeleteSpaceArea = async (spaceId: string): Promise<void> => {
        floorPlanManager?.removePolygonBySpaceId(spaceId);
        setFloors((prev) => {
            const floor = prev.find((x) => x.id == selectedFloorId);
            floor!.officeSpaceAreas = floor!.officeSpaceAreas?.filter(
                (x) => x?.officeSpaceId !== spaceId
            );
            return [...prev];
        });
        setNavigationBlocked(true);
    };

    const handleFloorModalSubmit = (
        success: boolean,
        deleteFloor: boolean,
        editing: boolean,
        newFloor?: FloorPlanFloorDto
    ) => {
        if (deleteFloor) {
            if (floors.length == 1) {
                floorPlanManager?.initFloor(null);
            } else {
                setSelectedFloorId(floors.find((x) => x.id !== newFloor?.id)?.id);
            }
            setFloors((prev) => {
                return [...prev.filter((x) => x.id !== floorModalState.floor?.id)];
            });
            setNavigationBlocked(true);
        } else if (success && newFloor) {
            if (editing) {
                setFloors((prev) => {
                    const floor = prev.find((x) => x.id == selectedFloorId);
                    floor!.svg = newFloor?.svg;
                    floor!.name = newFloor?.name;
                    floor!.officeSpaceAreas = [];
                    return [...prev];
                });
                floorPlanManager?.initFloor(floors.find((x) => x.id === newFloor.id) ?? null);
            } else {
                setFloors((prev) => [...prev, newFloor]);
                setSelectedFloorId(newFloor.id);
            }
            setNavigationBlocked(true);
        }
        setFloorModalState({ visible: false });
    };

    return (
        <div className="FloorPlanTab">
            <div className="left-container">
                <div className="floors-container">
                    <Skeleton
                        isLoaded={!loadingStateKeys.has('floorplan')}
                        defaultPadding={false}
                        placeholder={floorPlanDropdownShape}
                    >
                        <StaticSingleSelect
                            placeholder={''}
                            selected={selectedFloorId}
                            options={floors.map((x) => ({
                                label: x.name ?? '',
                                value: x.id ?? '',
                            }))}
                            onChange={(option?: SingleSelectCustomOption) => {
                                setSelectedFloorId(option?.value);
                            }}
                            disabled={displayNoFloorPlan}
                        />
                        {displayNoFloorPlan ? (
                            <div />
                        ) : (
                            <Button
                                className="edit-floor"
                                text={t('FloorPlan.edit_current_floor')}
                                type="floating"
                                width="hugged"
                                rightIcon="PencilIcon"
                                onClick={() =>
                                    setFloorModalState({
                                        visible: true,
                                        floor: floors.find((x) => x.id == selectedFloorId),
                                    })
                                }
                            />
                        )}

                        <Button
                            className="add-floor"
                            text={t('FloorPlan.add_a_floor')}
                            type="floating"
                            width="hugged"
                            leftCircleIcon="AddIcon"
                            onClick={() => setFloorModalState({ visible: true })}
                        />
                    </Skeleton>
                </div>

                <WhiteCard padding={40} paddingBottom={20}>
                    <Skeleton
                        isLoaded={!loadingStateKeys.has('floorplan')}
                        defaultPadding={false}
                        placeholder={floorPlanShape}
                    >
                        {displayNoFloorPlan ? (
                            <div className="no-floor-plan">
                                <EmptyBoxIcon
                                    width={64}
                                    height={64}
                                    fill={theme['text-disabled']}
                                />
                                <div className="text-title-3 text-disabled">
                                    {t('FloorPlan.no_floor_plan')}
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex-align">
                                <InformationCircleIcon
                                    width={16}
                                    height={16}
                                    fill={theme['primary-mid-contrast']}
                                />
                                <div
                                    className="ml-10"
                                    dangerouslySetInnerHTML={{
                                        __html: t('FloorPlan.edit_floor_plan_explanation'),
                                    }}
                                />
                            </div>
                        )}
                    </Skeleton>
                    <div
                        //this must always render for floorplanmanager. just play with height
                        id="svg-container-div"
                        style={{
                            height:
                                loadingStateKeys.has('floorplan') || displayNoFloorPlan ? 0 : 400,
                        }}
                    />

                    {!displayNoFloorPlan && !loadingStateKeys.has('floorplan') && (
                        <div className="zoom-btn-container">
                            <div
                                className="zoom-btn"
                                onClick={() => floorPlanManager?.panZoom?.zoomOut()}
                            >
                                <MagnifyingLessIcon
                                    width={24}
                                    height={24}
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>
                            <div
                                className="zoom-btn"
                                onClick={() => floorPlanManager?.panZoom?.zoomIn()}
                            >
                                <MagnifyingPlusIcon
                                    width={24}
                                    height={24}
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>
                        </div>
                    )}
                </WhiteCard>

                <Skeleton
                    isLoaded={!loadingStateKeys.has('floorplan')}
                    defaultPadding={false}
                    placeholder={floorPlanRoundButton}
                >
                    <SubmitButton
                        className="save-btn"
                        text={t('save')}
                        type="primary"
                        onClick={() => submit()}
                    />
                </Skeleton>
            </div>

            <div className="right-container">
                {!displayNoFloorPlan && (
                    <div className="text-headline-bold mb-20">{t('office_spaces')}</div>
                )}
                <Skeleton
                    isLoaded={!loadingStateKeys.has('floorplan')}
                    defaultPadding={false}
                    placeholder={floorPlanSpacesShape}
                >
                    {displayNoSpaceAssigned ? (
                        <WhiteCard className="no-spaces">
                            <div className="d-flex-col">
                                <EmptyBoxIcon
                                    width={64}
                                    height={64}
                                    fill={theme['text-disabled']}
                                />
                                <div className="text-title-3 text-disabled">
                                    {t('FloorPlan.no_space_assigned')}
                                </div>
                            </div>
                        </WhiteCard>
                    ) : (
                        <div className="space-area-cards">
                            {floors
                                .find((x) => x.id == selectedFloorId)
                                ?.officeSpaceAreas?.map((x) => (
                                    <SpaceAreaCard
                                        key={x?.officeSpaceId ?? ''}
                                        officeSpace={{
                                            id: x?.officeSpaceId,
                                            capacity: x?.capacity,
                                            name: x?.officeSpaceName,
                                        }}
                                        selected={currentSelectedSpaceId == x?.officeSpaceId}
                                        onClick={(spaceId: string) => {
                                            setCurrentSelectedSpaceId(spaceId);
                                            floorPlanManager?.onSideControlSpaceClicked(spaceId);
                                        }}
                                        onDelete={(spaceId) => onDeleteSpaceArea(spaceId)}
                                    />
                                ))}
                        </div>
                    )}
                </Skeleton>
            </div>

            {floorModalState.visible && floorPlanManager && (
                <FloorModal
                    visible={floorModalState.visible}
                    onComplete={handleFloorModalSubmit}
                    floorPlanManager={floorPlanManager}
                    editingFloor={floorModalState.floor}
                />
            )}

            {assignSpaceModalState.visible && (
                <AssignSpaceModal
                    visible={assignSpaceModalState.visible}
                    officeId={officeId}
                    points={assignSpaceModalState.points}
                    unavailableSpaceIds={
                        floors.flatMap((x) => x.officeSpaceAreas?.map((x) => x?.officeSpaceId)) ??
                        []
                    }
                    onComplete={(success?: boolean, space?: FloorPlanOfficeSpaceAreaDto) => {
                        setAssignSpaceModalState({ visible: false, points: '' });
                        if (success && space) {
                            floorPlanManager?.assignPolygonSpaceCallback(space);
                            setFloors((prev) => {
                                const floor = prev.find((x) => x.id == selectedFloorId);
                                floor!.officeSpaceAreas = floor!.officeSpaceAreas?.concat(space);
                                return [...prev];
                            });
                            setCurrentSelectedSpaceId(space.officeSpaceId);
                            setNavigationBlocked(true);
                        } else floorPlanManager?.removeSelectedPolygon();
                    }}
                />
            )}
        </div>
    );
};

export default FloorPlanTab;
