import React, { FunctionComponent, ReactNode } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import './index.less';
import { useStores } from 'Hooks';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { observer } from 'mobx-react';

interface ValidatedFormItemProps extends FormItemProps {
    errors?: Map<string, string[]>;
    label: string;
    // disabled?: boolean;
    capitalizeLabel?: boolean;
}

const ValidatedFormItem: FunctionComponent<ValidatedFormItemProps> = observer(
    ({ children, name, label, errors, capitalizeLabel, /*disabled,*/ ...props }) => {
        const { languageStore } = useStores();

        const fieldErrors = errors?.get(name?.toString() || '');
        const errorProps: FormItemProps = {};
        if (fieldErrors) {
            errorProps.hasFeedback = true;
            errorProps.help = fieldErrors.map((errorKey) => `${errorKey}`);
            errorProps.validateStatus = 'error';
        }

        const formattedLabel = (): ReactNode => {
            return (
                // ${disabled ? 'disabled' : ''} for re-activate disabled label
                <div
                    className={`${
                        languageStore.currentLanguage === SupportedLanguage.FR ||
                        capitalizeLabel === false
                            ? 'french-label'
                            : ''
                    }`}
                >
                    {label}
                    {props.required && <span>{'*'}</span>}
                </div>
            );
        };

        return (
            <div className={`ValidatedFormItem ${props.className ?? ''}`}>
                <Form.Item {...props} name={name} label={formattedLabel()} {...errorProps}>
                    {children}
                </Form.Item>
            </div>
        );
    }
);

export default ValidatedFormItem;
