import { FORMAT_DAY_WRITTEN_ABBR } from 'Models/Constants';
import moment from 'moment';
import React, { ReactNode } from 'react';
import './day-replicator.less';
import { WarningIcon } from 'Components/icons';
import FactoryDayReplicator from './factory-day-replicator';
import { theme } from 'Style/theme';

export enum DayReplicatorStateEnum {
    Mandatory = 'Mandatory',
    Active = 'Active',
    Disabled = 'Disabled',
    Disabled_full_capacity = 'Disabled_full_capacity',
    Normal = 'Normal',
}

interface DayReplicatorProps {
    onDayChange: (dayIndex: number) => void;
    dayStates: DayReplicatorStateEnum[];
}

const DayReplicator: React.FunctionComponent<DayReplicatorProps> = ({ onDayChange, dayStates }) => {
    const DayReplicatorComponent = (i: number, isDisabled: boolean): ReactNode => (
        <div
            key={i}
            className={`day ${dayStates[i]} text-body-bold cursor-pointer`}
            onClick={() =>
                isDisabled || dayStates[i] === DayReplicatorStateEnum.Mandatory
                    ? undefined
                    : onDayChange(i)
            }
        >
            {moment().day(i).format(FORMAT_DAY_WRITTEN_ABBR)}
        </div>
    );

    return (
        <div className="DayReplicator">
            {[...Array(7)].map((_, i) => {
                return (
                    <div key={i} className="column-center">
                        <FactoryDayReplicator
                            DayReplicatorComponent={DayReplicatorComponent}
                            dayStates={dayStates}
                            i={i}
                        />

                        {dayStates[i] === DayReplicatorStateEnum.Disabled_full_capacity && (
                            <WarningIcon
                                width={18}
                                height={18}
                                fill={theme['layout-high-contrast']}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};
export default DayReplicator;
