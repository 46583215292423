// GENERATED FILE - DO NOT MODIFY
import { CreateUserFavoriteUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserFavoriteUserRequestDto';
import { CreateUserFavoriteUserResponseDto } from 'Api/Features/Users/Dtos/CreateUserFavoriteUserResponseDto';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { CreateUserResponseDto } from 'Api/Features/Users/Dtos/CreateUserResponseDto';
import { GetUserExternalIdentitiesResponseDto } from 'Api/Features/Users/Dtos/GetUserExternalIdentitiesResponseDto';
import { GetUserObjectivesStatusRequestDto } from 'Api/Features/Users/Dtos/GetUserObjectivesStatusRequestDto';
import { GetUserSlackInfoResponseDto } from 'Api/Features/Users/Dtos/GetUserSlackInfoResponseDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersResponseDto } from 'Api/Features/Users/Dtos/GetUsersResponseDto';
import { RemoveUserExternalIdentityRequestDto } from 'Api/Features/Users/Dtos/RemoveUserExternalIdentityRequestDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UserObjectivesStatusDetailsDto } from 'Api/Features/Users/Dtos/UserObjectivesStatusDetailsDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class UserProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUser(userId: string): Promise<UserDetailsDto | null> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            null
        );

        const data: UserDetailsDto | null = await this.httpClient.get<UserDetailsDto | null>(uri);
        return data;
    }

    public async getUsers(request: GetUsersRequestDto | null): Promise<GetUsersResponseDto | null> {
        const uri = this.buildUri(
            "/users",
            null,
            { ...request || undefined }
        );

        const data: GetUsersResponseDto | null = await this.httpClient.get<GetUsersResponseDto | null>(uri);
        return data;
    }

    public async getUserObjectivesStatus(userId: string, request: GetUserObjectivesStatusRequestDto | null): Promise<UserObjectivesStatusDetailsDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/objectives-status",
            { userId: userId },
            { ...request || undefined }
        );

        const data: UserObjectivesStatusDetailsDto | null = await this.httpClient.get<UserObjectivesStatusDetailsDto | null>(uri);
        return data;
    }

    public async createUser(request: CreateUserRequestDto | null): Promise<CreateUserResponseDto | null> {
        const uri = this.buildUri(
            "/users",
            null,
            null
        );

        const data: CreateUserResponseDto | null = await this.httpClient.post<CreateUserRequestDto | null, CreateUserResponseDto | null>(uri, request);
        return data;
    }

    public async updateUser(userId: string, request: UpdateUserRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            null
        );

        await this.httpClient.patch<UpdateUserRequestDto | null, void>(uri, request);
    }

    public async deactivateUser(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/deactivate",
            { userId: userId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async reactivateUser(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/reactivate",
            { userId: userId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async resendConfirmationToken(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/resend-invitation",
            { userId: userId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async createUserFavoriteUser(request: CreateUserFavoriteUserRequestDto | null): Promise<CreateUserFavoriteUserResponseDto | null> {
        const uri = this.buildUri(
            "/user-favorite-users",
            null,
            null
        );

        const data: CreateUserFavoriteUserResponseDto | null = await this.httpClient.post<CreateUserFavoriteUserRequestDto | null, CreateUserFavoriteUserResponseDto | null>(uri, request);
        return data;
    }

    public async deleteUserFavoriteUser(id: string): Promise<void> {
        const uri = this.buildUri(
            "/user-favorite-users/{id}",
            { id: id },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getUserSlackInfo(userId: string): Promise<GetUserSlackInfoResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/slack-info",
            { userId: userId },
            null
        );

        const data: GetUserSlackInfoResponseDto | null = await this.httpClient.get<GetUserSlackInfoResponseDto | null>(uri);
        return data;
    }

    public async getUserExternalIdentities(userId: string): Promise<GetUserExternalIdentitiesResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/external-identities",
            { userId: userId },
            null
        );

        const data: GetUserExternalIdentitiesResponseDto | null = await this.httpClient.get<GetUserExternalIdentitiesResponseDto | null>(uri);
        return data;
    }

    public async removeUserExternalIdentity(userId: string, request: RemoveUserExternalIdentityRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/remove-external-identity",
            { userId: userId },
            null
        );

        await this.httpClient.post<RemoveUserExternalIdentityRequestDto | null, void>(uri, request);
    }
}