import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const SimpleHouseIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <mask
                    id="mask0_2853_21865"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                >
                    <rect width="40" height="40" fill={fill != 'currentColor' ? fill : '#D9D9D9'} />
                </mask>
                <g mask="url(#mask0_2853_21865)">
                    <path
                        d="M9.45799 35C8.68066 35 8.02099 34.7293 7.47899 34.188C6.93766 33.646 6.66699 32.986 6.66699 32.208V16.375C6.66699 15.9303 6.76399 15.5137 6.95799 15.125C7.15266 14.7363 7.43066 14.417 7.79199 14.167L18.333 6.25001C18.583 6.05534 18.847 5.91634 19.125 5.83301C19.403 5.74967 19.6947 5.70801 20 5.70801C20.3053 5.70801 20.597 5.74967 20.875 5.83301C21.153 5.91634 21.417 6.05534 21.667 6.25001L32.208 14.167C32.5693 14.417 32.8473 14.7363 33.042 15.125C33.236 15.5137 33.333 15.9303 33.333 16.375V32.208C33.333 32.986 33.0623 33.646 32.521 34.188C31.979 34.7293 31.3193 35 30.542 35H23.333V23.333H16.667V35H9.45799Z"
                        fill={fill != 'currentColor' ? fill : '#2F84EE'}
                    />
                </g>
            </svg>
        </span>
    );
};

export default SimpleHouseIcon;
