import Skeleton from 'Components/skeleton';
import WhiteCard from 'Components/white-card';
import React from 'react';
import { officesDetailsSkeletonShape } from '../skeleton-shapes';
import { useTranslation } from 'react-i18next';
import UserValueDisplay from 'Routes/settings/users/id/components/user-value-display/user-value-display';

export interface LabelAndValue {
    label: string;
    value: string;
}

interface DetailsTabProps {
    officeDetails: LabelAndValue[];
    isLoading: boolean;
}

const DetailsTab: React.FC<DetailsTabProps> = ({ officeDetails, isLoading }) => {
    const { t } = useTranslation();

    return (
        <div className="DetailsTab">
            <div className="text-headline-bold mb-20">{t('Office.office_details')}</div>
            <WhiteCard padding={40}>
                <Skeleton placeholder={officesDetailsSkeletonShape} isLoaded={!isLoading}>
                    <div className="information-offices">
                        {officeDetails?.map((item, key) => {
                            return (
                                <UserValueDisplay key={key} label={item.label} value={item.value} />
                            );
                        })}
                    </div>
                </Skeleton>
            </WhiteCard>
        </div>
    );
};

export default DetailsTab;
