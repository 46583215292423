import { Checkbox, Col, Input, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import { CreatePolicyBundleObjectivesDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleObjectivesDto';
import { CreatePolicyBundleRequestDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleRequestDto';
import { CreatePolicyBundleResponseDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleResponseDto';
import { CreatePolicyBundleRestrictionsDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleRestrictionsDto';
import { CreatePolicyBundleSuggestionsDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleSuggestionsDto';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { PolicyBundleObjectivesCoreHoursGroupDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesCoreHoursGroupDto';
import Button from 'Components/button';
import { DayCircle } from 'Components/circle-toggle';
import CircleToggleDays from 'Components/circle-toggle-days';
import { CreateCoreHoursRow } from 'Components/display-core-hours';
import Divider from 'Components/divider';
import { AddIcon, MinusIcon } from 'Components/icons';
import Modal from 'Components/modal';
import ToggleableRow from 'Components/toggleable-row';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import {
    FORM_GUTTER,
    MINIMUM_TIME_PERIOD_MINUTES,
    TWENTY_FOUR_HOUR_MINUTE,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreatePolicySchema } from 'Schemas/CreatePolicySchema';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import { theme } from 'Style/theme';
import CoreHoursForm, { getTotalCoreHours } from './components/core-hours-form';
import TimeRange from './components/time-range';
import './create-policy-modal.less';
import SubmitButton from 'Components/submit-button/submit-button';

interface CreatePolicyModalProps {
    visible: boolean;
    onComplete: (success: boolean, addPeople: boolean, id?: string) => void;
    policy?: PolicyBundleDto;
}

enum InputAction {
    add = 'add',
    substract = 'substract',
}

const CreatePolicyModal: React.FunctionComponent<CreatePolicyModalProps> = ({
    visible,
    onComplete,
    policy,
}) => {
    const [form] = useForm();
    const { t } = useTranslation();
    const { toastStore, globalLoadingStore, policyStore } = useStores();
    const policyService = useService(PolicyBundleService);
    const [errors, validateForm, resetErrors] = useFormValidation(CreatePolicySchema, form);

    //Restrictions
    const [workPeriodStartTime, setWorkPeriodStartTime] = useState<string>('00:00');
    const [workPeriodEndTime, setWorkPeriodEndTime] = useState<string>('23:59');
    const [workPeriodAllDayChecked, setWorkPeriodAllDayChecked] = useState(true);
    const [daysOfWorkAllDayChecked, setDaysOfWorkAllDayChecked] = useState(true);
    const [daysOfWorkStateArray, setDaysOfWorkStateArray] = useState<DayOfWeekDto[]>([
        DayOfWeekDto.Sunday,
        DayOfWeekDto.Monday,
        DayOfWeekDto.Tuesday,
        DayOfWeekDto.Wednesday,
        DayOfWeekDto.Thursday,
        DayOfWeekDto.Friday,
        DayOfWeekDto.Saturday,
    ]);

    //Suggestions
    const [daysOfSuggestionOfficeDayStateArray, setDaysOfSuggestionOfficeDayStateArray] = useState<
        DayOfWeekDto[]
    >([]);
    const [suggestionOfficeDaysEnabled, setSuggestionOfficeDaysEnabled] = useState(false);

    //objectives
    const [workHoursPerWeekEnabled, setWorkHoursPerWeekEnabled] = useState(false);
    const [hoursAvailabilityEnabled, setHoursAvailabilityEnabled] = useState(false);
    const [numberDaysWeekEnabled, setNumberDaysWeekEnabled] = useState(false);
    const [numberDaysOfficeEnabled, setNumberDaysOfficeEnabled] = useState(false);
    const [coreHoursEnabled, setCoreHoursEnabled] = useState(false);
    const [coreHours, setCoreHours] = useState<CreateCoreHoursRow[]>();

    useEffect(() => {
        //set form info with policy to edit
        if (policy) {
            form.setFieldsValue({
                name: policy.name,
                workHoursPerWeek: policy.objectives?.workHoursPerWeek ?? undefined,
                hoursAvailability: policy.objectives?.availabilityHoursPerWeek ?? undefined,
                numberDaysWeek: policy.objectives?.daysPerWeek ?? undefined,
                numberDaysOffice: policy.objectives?.officeDaysPerWeek ?? undefined,
            });

            //restrictions state
            const workPeriodStart = policy.restrictions?.workPeriodStartTime
                ? moment('2020-01-01' + 'T' + policy.restrictions?.workPeriodStartTime).format(
                      TWENTY_FOUR_HOUR_MINUTE
                  )
                : undefined;
            const workPeriodEnd = policy.restrictions?.workPeriodEndTime
                ? moment('2020-01-01' + 'T' + policy.restrictions?.workPeriodEndTime).format(
                      TWENTY_FOUR_HOUR_MINUTE
                  )
                : undefined;
            setWorkPeriodStartTime(workPeriodStart ?? '00:00');
            setWorkPeriodEndTime(workPeriodEnd ?? '23:59');
            setWorkPeriodAllDayChecked(
                workPeriodStart === undefined && workPeriodEnd === undefined
            );
            setDaysOfWorkAllDayChecked(policy.restrictions?.daysOfWeek?.length === 7);
            setDaysOfWorkStateArray(policy.restrictions?.daysOfWeek ?? []);

            //Suggestions state
            if (policy?.suggestions?.officeDays && policy?.suggestions?.officeDays?.length > 0) {
                setSuggestionOfficeDaysEnabled(true);
                setDaysOfSuggestionOfficeDayStateArray(policy?.suggestions?.officeDays);
            }

            //objectives state
            setWorkHoursPerWeekEnabled(
                (policy.objectives?.workHoursPerWeek ?? undefined) !== undefined
            );
            setHoursAvailabilityEnabled(
                (policy.objectives?.availabilityHoursPerWeek ?? undefined) !== undefined
            );
            setNumberDaysWeekEnabled((policy.objectives?.daysPerWeek ?? undefined) !== undefined);
            setNumberDaysOfficeEnabled(
                (policy.objectives?.officeDaysPerWeek ?? undefined) !== undefined
            );
            setCoreHoursEnabled((policy.objectives?.coreHoursGroups ?? undefined) !== undefined);
            if (policy.objectives?.coreHoursGroups)
                setCoreHours(
                    policy.objectives?.coreHoursGroups.map(
                        (coreHours) =>
                            ({
                                activeDays: coreHours?.daysOfWeek,
                                timeRange: coreHours?.periods?.map((period) => ({
                                    startTime: period?.startTime?.slice(0, -3),
                                    endTime: period?.endTime?.slice(0, -3),
                                })),
                            } as CreateCoreHoursRow)
                    )
                );
        }
    }, [policy]);

    //#region Submit / Exit
    const dismiss = (success = false, addPeople = false, id?: string): void => {
        form.resetFields();
        resetErrors();
        onComplete(success, addPeople, id);
    };

    const submit = async (addPeople: boolean): Promise<void> => {
        const formData = form.getFieldsValue();

        const validationObject = {
            ...formData,
            workHoursPerWeekEnabled,
            hoursAvailabilityEnabled,
            numberDaysWeekEnabled,
            numberDaysOfficeEnabled,
        };

        if (!(await validateForm(validationObject))) return;

        try {
            globalLoadingStore.addLoading();

            const restrictions: CreatePolicyBundleRestrictionsDto = {
                daysOfWeek: daysOfWorkStateArray,
                workPeriodEndTime: workPeriodEndTime === '23:59' ? '00:00' : workPeriodEndTime,
                workPeriodStartTime: workPeriodStartTime,
            };

            const objectives: CreatePolicyBundleObjectivesDto = {
                availabilityHoursPerWeek: formData.hoursAvailability,
                daysPerWeek: formData.numberDaysWeek,
                officeDaysPerWeek: formData.numberDaysOffice,
                workHoursPerWeek: formData.workHoursPerWeek,
                coreHoursGroups: coreHoursEnabled
                    ? coreHours
                          ?.filter(
                              (coreHour) =>
                                  coreHour.activeDays.length > 0 &&
                                  coreHour.timeRange[coreHour.timeRange.length - 1].startTime !==
                                      undefined &&
                                  coreHour.timeRange[coreHour.timeRange.length - 1].endTime !==
                                      undefined
                          )
                          .map(
                              (coreHours) =>
                                  ({
                                      daysOfWeek: coreHours.activeDays,
                                      periods: coreHours.timeRange.filter(
                                          (timeRange) => timeRange.startTime && timeRange.endTime
                                      ),
                                  } as PolicyBundleObjectivesCoreHoursGroupDto)
                          )
                    : undefined,
            };

            const suggestions: CreatePolicyBundleSuggestionsDto = {
                officeDays: daysOfSuggestionOfficeDayStateArray,
            };

            const request: CreatePolicyBundleRequestDto = {
                name: formData.name,
                restrictions,
                objectives,
                suggestions,
            };

            let createResponse: CreatePolicyBundleResponseDto | null = null;

            if (policy) {
                await policyService.updatePolicyBundle(policy.id!, request);
                if (policy.id === policyStore.policyInfo?.policyBundleId) {
                    await policyStore.setPolicyRestrictionsAndObjectivesStats();
                }
            } else createResponse = await policyService.createPolicyBundle(request);

            toastStore.toast({
                type: 'success',
                message: t('Toast.success_message', {
                    param1: t('bundle'),
                }),
            });

            dismiss(true, addPeople, createResponse?.id ?? undefined);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };
    //#region Submit / Exit

    const getMinimumWorkPeriodEndTimeAvailable = useCallback((): string | undefined => {
        if (workPeriodStartTime)
            return moment()
                .startOf('day')
                .add(workPeriodStartTime)
                .add(MINIMUM_TIME_PERIOD_MINUTES, 'minute')
                .format(TWENTY_FOUR_HOUR_MINUTE);
        else return undefined;
    }, [workPeriodStartTime]);

    const handleWorkPeriodAllDayChange = (value: boolean) => {
        setWorkPeriodAllDayChecked(value);
        if (value) {
            setWorkPeriodStartTime('00:00');
            setWorkPeriodEndTime('23:59');
        }
    };

    const handleDaysOfWorkClick = (days: DayCircle[]) => {
        const activeDays = days.filter((day) => day.isActive);
        if (activeDays.length > 6) setDaysOfWorkAllDayChecked(true);
        else setDaysOfWorkAllDayChecked(false);

        setDaysOfWorkStateArray(activeDays.map((day) => day.id as DayOfWeekDto));
    };

    const handleClickSuggestOfficeDays = (days: DayCircle[]) => {
        const activeDays = days.filter((day) => day.isActive);
        setDaysOfSuggestionOfficeDayStateArray(activeDays.map((day) => day.id as DayOfWeekDto));
    };

    const handleNumberDayWeekClick = (action: InputAction) => {
        const formValue = form.getFieldValue(['numberDaysWeek']);

        if (action === InputAction.substract && (!formValue || formValue <= 1)) return;
        if (action === InputAction.add && formValue >= 7) return;

        form.setFieldsValue({
            numberDaysWeek: parseInt((formValue ?? 0) + (action === InputAction.add ? 1 : -1)),
        });
    };

    const handleNumberDayOfficeClick = (action: InputAction) => {
        const formValue = form.getFieldValue(['numberDaysOffice']);

        if (action === InputAction.substract && (!formValue || formValue <= 1)) return;
        if (action === InputAction.add && formValue >= 7) return;

        form.setFieldsValue({
            numberDaysOffice: parseInt((formValue ?? 0) + (action === InputAction.add ? 1 : -1)),
        });
    };

    const handleCoreHoursOnChange = (coreHours: CreateCoreHoursRow[]) => setCoreHours(coreHours);

    return (
        <Modal
            className="CreatePolicyModal"
            visible={visible}
            onCancel={() => dismiss()}
            headerText={t(`${policy ? 'edit_entity' : 'create_entity'}`, {
                param1: t('policy_bundle'),
                param2: 'un',
                param3: t('Entity.lowercase_policy_bundle'),
            })}
            footer={
                <>
                    <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                    <div className="positive-btns">
                        {!policy && (
                            <SubmitButton
                                className="add-people"
                                text={t('Policy.create_policy_and_add_teams')}
                                type="link"
                                width="hugged"
                                onClick={() => submit(true)}
                            />
                        )}

                        <SubmitButton
                            text={
                                policy
                                    ? t('save_changes')
                                    : t('create_entity', { param1: t('bundle') })
                            }
                            type="primary"
                            onClick={() => submit(false)}
                        />
                    </div>
                </>
            }
        >
            <Form
                layout="vertical"
                onFinish={submit}
                form={form}
                className="container-scroll-actions-fixed"
            >
                <div className="text-callout-bold mb-20">{t('detail')}</div>
                <Row gutter={FORM_GUTTER}>
                    <Col span={12}>
                        <ValidatedFormItem label={t('name')} name={'name'} errors={errors} required>
                            <Input />
                        </ValidatedFormItem>
                    </Col>
                </Row>

                <Divider marginTop={20} marginBottom={40} />

                <div className="text-callout-bold mb-20">{t('restrictions')}</div>

                <ToggleableRow
                    title={t('work_period')}
                    subTitle={
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('Policy.work_period_explanation'),
                            }}
                        />
                    }
                >
                    <Checkbox
                        checked={workPeriodAllDayChecked}
                        onChange={(e) => handleWorkPeriodAllDayChange(e.target.checked)}
                    >
                        {t('all_day')}
                    </Checkbox>

                    <TimeRange
                        start={{
                            onChange: (value) => {
                                setWorkPeriodStartTime(value);
                            },
                            selected: workPeriodStartTime,
                            disabled: workPeriodAllDayChecked,
                        }}
                        end={{
                            onChange: (value) => {
                                setWorkPeriodEndTime(value);
                            },
                            selected: workPeriodEndTime,
                            minimumAvailableTime: getMinimumWorkPeriodEndTimeAvailable(),
                            midnightAvailable: true,
                            disabled: workPeriodAllDayChecked,
                        }}
                    />
                </ToggleableRow>

                <ToggleableRow
                    title={t('Policy.days_of_work')}
                    subTitle={t('Policy.days_of_work_explanation')}
                >
                    <Checkbox
                        checked={daysOfWorkAllDayChecked}
                        onChange={(e) => {
                            setDaysOfWorkAllDayChecked(e.target.checked);

                            if (e.target.checked)
                                setDaysOfWorkStateArray(
                                    (
                                        Object.keys(DayOfWeekDto) as Array<
                                            keyof typeof DayOfWeekDto
                                        >
                                    ).map((day) => DayOfWeekDto[day])
                                );
                        }}
                    >
                        {t('all_days')}
                    </Checkbox>

                    <CircleToggleDays
                        activeDays={daysOfWorkStateArray}
                        onClick={handleDaysOfWorkClick}
                        tooltips
                    />
                </ToggleableRow>

                <Divider marginTop={20} marginBottom={40} />

                <div className="text-callout-bold mb-20">{t('suggestions')}</div>

                <ToggleableRow
                    title={t('Policy.suggested_office_day')}
                    subTitle={t('Policy.suggested_office_day_explanation')}
                    toggle={{
                        checked: suggestionOfficeDaysEnabled,
                        onChange: (checked) => {
                            setSuggestionOfficeDaysEnabled(checked);
                            if (!checked) {
                                setDaysOfSuggestionOfficeDayStateArray([]);
                            }
                        },
                    }}
                >
                    <CircleToggleDays
                        activeDays={daysOfSuggestionOfficeDayStateArray}
                        onClick={handleClickSuggestOfficeDays}
                        disabledDays={
                            suggestionOfficeDaysEnabled
                                ? []
                                : [
                                      DayOfWeekDto.Sunday,
                                      DayOfWeekDto.Monday,
                                      DayOfWeekDto.Tuesday,
                                      DayOfWeekDto.Wednesday,
                                      DayOfWeekDto.Thursday,
                                      DayOfWeekDto.Friday,
                                      DayOfWeekDto.Saturday,
                                  ]
                        }
                        tooltips
                    />
                </ToggleableRow>

                <Divider marginTop={20} marginBottom={40} />

                <div className="text-callout-bold mb-20">{t('objectives')}</div>

                <ToggleableRow
                    title={t('Policy.work_hours_per_week')}
                    subTitle={t('Policy.work_hours_per_week_explanation')}
                    toggle={{
                        checked: workHoursPerWeekEnabled,
                        onChange: (e) => {
                            setWorkHoursPerWeekEnabled(e);
                            if (!e) {
                                form.resetFields(['workHoursPerWeek']);
                            }
                        },
                    }}
                >
                    <ValidatedFormItem
                        label={`${t('hours')}/${t('week')}`}
                        name={'workHoursPerWeek'}
                        errors={errors}
                    >
                        <Input
                            value={form.getFieldsValue(['workHoursPerWeek'])}
                            suffix={t('hours')}
                            disabled={!workHoursPerWeekEnabled}
                        />
                    </ValidatedFormItem>
                </ToggleableRow>

                <ToggleableRow
                    title={t('Policy.hours_availability_per_week')}
                    subTitle={t('Policy.hours_availability_per_week_explanation')}
                    toggle={{
                        checked: hoursAvailabilityEnabled,
                        onChange: (e) => {
                            setHoursAvailabilityEnabled(e);
                            if (!e) {
                                form.resetFields(['hoursAvailability']);
                            }
                        },
                    }}
                >
                    <ValidatedFormItem
                        label={`${t('hours')}/${t('week')}`}
                        name={'hoursAvailability'}
                        errors={errors}
                    >
                        <Input
                            value={form.getFieldsValue(['hoursAvailability'])}
                            suffix={t('hours')}
                            disabled={!hoursAvailabilityEnabled}
                        />
                    </ValidatedFormItem>
                </ToggleableRow>

                <ToggleableRow
                    title={t('Policy.number_days_week')}
                    subTitle={t('Policy.number_days_week_explanation')}
                    toggle={{
                        checked: numberDaysWeekEnabled,
                        onChange: (e) => {
                            setNumberDaysWeekEnabled(e);
                            if (!e) {
                                form.resetFields(['numberDaysWeek']);
                            }
                        },
                    }}
                >
                    <div className="incremental-input">
                        <MinusIcon
                            fill={theme['primary-mid-contrast']}
                            onClick={() =>
                                numberDaysWeekEnabled
                                    ? handleNumberDayWeekClick(InputAction.substract)
                                    : undefined
                            }
                        />

                        <ValidatedFormItem label="" name="numberDaysWeek" errors={errors}>
                            <Input
                                value={form.getFieldsValue(['numberDaysWeek'])}
                                className="small-input"
                                suffix={t('days')}
                                disabled={!numberDaysWeekEnabled}
                            />
                        </ValidatedFormItem>

                        <AddIcon
                            fill={theme['primary-mid-contrast']}
                            onClick={() =>
                                numberDaysWeekEnabled
                                    ? handleNumberDayWeekClick(InputAction.add)
                                    : undefined
                            }
                        />
                    </div>
                </ToggleableRow>

                <ToggleableRow
                    title={t('Policy.number_day_at_office')}
                    subTitle={t('Policy.number_day_at_office_explanation')}
                    toggle={{
                        checked: numberDaysOfficeEnabled,
                        onChange: (e) => {
                            setNumberDaysOfficeEnabled(e);
                            if (!e) {
                                form.resetFields(['numberDaysOffice']);
                            }
                        },
                    }}
                >
                    <div className="incremental-input">
                        <MinusIcon
                            fill={theme['primary-mid-contrast']}
                            onClick={() =>
                                numberDaysOfficeEnabled
                                    ? handleNumberDayOfficeClick(InputAction.substract)
                                    : undefined
                            }
                        />

                        <ValidatedFormItem label="" name="numberDaysOffice" errors={errors}>
                            <Input
                                value={form.getFieldsValue(['numberDaysOffice'])}
                                className="small-input"
                                suffix={t('days')}
                                disabled={!numberDaysOfficeEnabled}
                            />
                        </ValidatedFormItem>

                        <AddIcon
                            fill={theme['primary-mid-contrast']}
                            onClick={() =>
                                numberDaysOfficeEnabled
                                    ? handleNumberDayOfficeClick(InputAction.add)
                                    : undefined
                            }
                        />
                    </div>
                </ToggleableRow>

                <ToggleableRow
                    title={t('Policy.core_hours_title')}
                    subTitle={t('Policy.core_hours_info')}
                    toggle={{
                        checked: coreHoursEnabled,
                        onChange: (e) => {
                            setCoreHoursEnabled(e);
                        },
                    }}
                    bottomChildren={
                        coreHoursEnabled ? (
                            <CoreHoursForm data={coreHours} onChange={handleCoreHoursOnChange} />
                        ) : undefined
                    }
                >
                    <ValidatedFormItem label={t('Policy.core_hours_per_week')}>
                        <Input
                            value={coreHours ? getTotalCoreHours(coreHours) : 0}
                            suffix={t('hours')}
                            disabled
                        />
                    </ValidatedFormItem>
                </ToggleableRow>
            </Form>
        </Modal>
    );
};

export default CreatePolicyModal;
