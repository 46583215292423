import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import CircleToggleDays from 'Components/circle-toggle-days';
import DisplayCoreHours, { CoreHoursRow } from 'Components/display-core-hours';
import { Empty } from 'Components/empty';
import Skeleton from 'Components/skeleton';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    TWENTY_FOUR_HOUR_MINUTE,
} from 'Models/Constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTotalCoreHours } from '../core-hours-form';
import PolicyDetailsWhiteCard, { PolicyDetailsWhiteCardData } from '../policy-details-white-card';
import TimeDisplay from '../time-display';
import './index.less';
import { policyDetailsCard } from './skeleton-shapes';

interface MappedData {
    restrictions: PolicyDetailsWhiteCardData[];
    objectives: PolicyDetailsWhiteCardData[];
    suggestions: PolicyDetailsWhiteCardData[];
}
interface TabDetailsProps {
    isLoading: boolean;
    data?: PolicyBundleDto;
    displayName?: boolean;
}

const mappedDataInit: MappedData = {
    restrictions: [],
    objectives: [],
    suggestions: [],
};

const TabPolicyDetails: React.FunctionComponent<TabDetailsProps> = observer(
    ({ isLoading, data, displayName = false }) => {
        const { languageStore } = useStores();
        const { t } = useTranslation();
        const [mappedData, setMappedData] = useState<MappedData>(mappedDataInit);
        const [isLoadingLocal, setIsLoadingLocal] = useState({
            policy: false,
        });

        useEffect(() => {
            const mappedData: MappedData = {
                restrictions: [],
                objectives: [],
                suggestions: [],
            };
            //api sends null if midnight is chosen.
            let workStart, workEnd;
            if (data?.restrictions?.workPeriodEndTime || data?.restrictions?.workPeriodStartTime) {
                workStart = data.restrictions.workPeriodStartTime ?? '00:00:00';
                workEnd = data.restrictions.workPeriodEndTime ?? '00:00:00';
            }

            if (workEnd && workStart) {
                const isNotWorkEnd12AM = workEnd != '00:00:00';
                const workEndMoment = moment(
                    isNotWorkEnd12AM ? workEnd : '24:00:00',
                    TWENTY_FOUR_HOUR_MINUTE
                );
                const workStartMoment = moment(workStart, TWENTY_FOUR_HOUR_MINUTE);

                const plageOfWorkDurantion = workEndMoment.diff(workStartMoment) / 1000 / 60 / 60;

                mappedData.restrictions.push({
                    title: t('work_period'),
                    text: t('Policy.restrictions_work_period_info'),
                    infos: {
                        title: t('Policy.possibility_of'),
                        icon: 'AlarmClockIcon',
                        value: plageOfWorkDurantion,
                        label: t('hours') + '/' + t('day'),
                        element: (
                            <TimeDisplay
                                start={moment(workStart, TWENTY_FOUR_HOUR_MINUTE).format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )}
                                end={moment(workEnd, TWENTY_FOUR_HOUR_MINUTE).format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )}
                            />
                        ),
                    },
                });
            }

            data?.restrictions?.daysOfWeek &&
                data.restrictions.daysOfWeek.length > 0 &&
                mappedData.restrictions.push({
                    title: t('Policy.days_of_work'),
                    text: t('Policy.restrictions_days_of_work_info'),
                    infos: {
                        title: t('Policy.possibility_of'),
                        icon: 'CalendarIcon',
                        value: data.restrictions.daysOfWeek.length,
                        label: t('days') + '/' + t('week'),
                        element: (
                            <CircleToggleDays activeDays={data?.restrictions?.daysOfWeek || []} />
                        ),
                    },
                });

            data?.objectives?.workHoursPerWeek &&
                mappedData.objectives.push({
                    title: t('Policy.work_hours_per_week'),
                    text: t('Policy.objectives_work_hours_week_info'),
                    infos: {
                        title: t('Policy.recommended'),
                        icon: 'AlarmClockIcon',
                        value: data.objectives.workHoursPerWeek,
                        label: t('hours') + '/' + t('week'),
                    },
                });

            data?.objectives?.availabilityHoursPerWeek &&
                mappedData.objectives.push({
                    title: t('Policy.hours_availability_per_week'),
                    text: t('Policy.objectives_hours_availability_week_info'),
                    infos: {
                        title: t('Policy.recommended'),
                        icon: 'AlarmClockIcon',
                        value: data.objectives.availabilityHoursPerWeek,
                        label: t('hours') + '/' + t('week'),
                    },
                });

            data?.objectives?.daysPerWeek &&
                mappedData.objectives.push({
                    title: t('Policy.number_days_week'),
                    text: t('Policy.objectives_days_week_info'),
                    infos: {
                        title: t('Policy.recommended'),
                        icon: 'CalendarIcon',
                        value: data.objectives.daysPerWeek,
                        label: t('days') + '/' + t('week'),
                    },
                });

            data?.objectives?.officeDaysPerWeek &&
                mappedData.objectives.push({
                    title: t('Policy.number_day_at_office'),
                    text: t('Policy.objectives_days_office_info'),
                    infos: {
                        title: t('Policy.recommended'),
                        icon: 'CalendarIcon',
                        value: data.objectives.officeDaysPerWeek,
                        label: t('days') + '/' + t('week'),
                    },
                });

            if (data?.objectives?.coreHoursGroups) {
                const mappedCoreHours = data.objectives.coreHoursGroups.map(
                    (coreHours) =>
                        ({
                            activeDays: coreHours?.daysOfWeek,
                            timeRange: coreHours?.periods?.map((period) => ({
                                startTime: period?.startTime?.slice(0, -3),
                                endTime: period?.endTime?.slice(0, -3),
                            })),
                        } as CoreHoursRow)
                );

                mappedData.objectives.push({
                    title: t('Policy.core_hours_title'),
                    text: t('Policy.core_hours_info'),
                    infos: {
                        title: t('Policy.recommended'),
                        icon: 'AlarmClockIcon',
                        value: getTotalCoreHours(mappedCoreHours),
                        label: t('hours') + '/' + t('week'),
                    },
                    element: <DisplayCoreHours data={mappedCoreHours} />,
                });
            }

            // suggestions
            if (data?.suggestions?.officeDays) {
                mappedData.suggestions.push({
                    title: t('Policy.suggested_office_day'),
                    text: t('Policy.suggested_office_day_explanation'),
                    infos: {
                        title: t('Policy.possibility_of'),
                        icon: 'CalendarIcon',
                        value: data?.suggestions?.officeDays.length,
                        label: t('days') + '/' + t('week'),
                        element: (
                            <CircleToggleDays activeDays={data?.suggestions?.officeDays || []} />
                        ),
                    },
                });
            }
            setIsLoadingLocal((prev) => {
                return { ...prev, policy: true };
            });
            setMappedData(mappedData);
        }, [data]);

        return (
            <div className="TabDetails">
                {displayName && data?.name && <div className="text-title-2-bold">{data.name}</div>}

                <div className="TabDetails-title text-headline-bold">{t('restrictions')}</div>
                {!isLoading && isLoadingLocal.policy ? (
                    mappedData.restrictions.length > 0 ? (
                        mappedData.restrictions.map((restriction, i) => (
                            <PolicyDetailsWhiteCard
                                key={restriction.title + i}
                                data={restriction}
                            />
                        ))
                    ) : (
                        <Empty stringKey="Policy.no_active_restrictions" />
                    )
                ) : (
                    <>
                        <Skeleton isLoaded={false} placeholder={policyDetailsCard} />
                        <Skeleton isLoaded={false} placeholder={policyDetailsCard} />
                    </>
                )}

                <div className="TabDetails-title text-headline-bold">{t('suggestions')}</div>
                {!isLoading && isLoadingLocal.policy ? (
                    mappedData?.suggestions?.length > 0 ? (
                        mappedData?.suggestions?.map((restriction, i) => (
                            <PolicyDetailsWhiteCard
                                key={restriction.title + i}
                                data={restriction}
                            />
                        ))
                    ) : (
                        <Empty stringKey="Policy.no_active_suggestions" />
                    )
                ) : (
                    <>
                        <Skeleton isLoaded={false} placeholder={policyDetailsCard} />
                    </>
                )}

                <div className="TabDetails-title text-headline-bold">{t('objectives')}</div>
                {!isLoading && isLoadingLocal.policy ? (
                    mappedData.objectives.length > 0 ? (
                        mappedData.objectives.map((objective, i) => (
                            <PolicyDetailsWhiteCard key={objective.title + i} data={objective} />
                        ))
                    ) : (
                        <Empty stringKey="Policy.no_active_objectives" />
                    )
                ) : (
                    <>
                        <Skeleton isLoaded={false} placeholder={policyDetailsCard} />
                        <Skeleton isLoaded={false} placeholder={policyDetailsCard} />
                        <Skeleton isLoaded={false} placeholder={policyDetailsCard} />
                        <Skeleton isLoaded={false} placeholder={policyDetailsCard} />
                    </>
                )}
            </div>
        );
    }
);

export default TabPolicyDetails;
