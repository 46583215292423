import { Input } from 'antd';
import { ClubCategoryDto } from 'Api/Features/Clubs/Dtos/ClubCategoryDto';
import { ClubDto } from 'Api/Features/Clubs/Dtos/ClubDto';
import { GetClubsRequestDto } from 'Api/Features/Clubs/Dtos/GetClubsRequestDto';
import Button from 'Components/button';
import Content from 'Components/Content';
import HorizontalRadio, { HorizontalRadioOption } from 'Components/horizontal-radio';
import { MagnifyingGlassIcon, PeopleFill3Icon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import ScrollInfinite from 'Components/scroll-infinite';
import { useService, useStores, useApiRequestHistory } from 'Hooks';
import { observer } from 'mobx-react';
import {
    CLUBS_URL,
    CLUBS_URL_REGEX,
    DEBOUNCE_DELAY_400,
    PAGE_SIZE_INFINITE_LOADING,
} from 'Models/Constants';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ClubService } from 'Services/ClubService';
import { theme } from 'Style/theme';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import ClubCard from './components/club-card/index';
import CreateClubModal from './modals/create-club-modal/index';
import Wave from 'Assets/Images/no-club-wave.svg';
import Skeleton from 'Components/skeleton';
import { UpdateClubMembersActionDto } from 'Api/Features/Clubs/Dtos/UpdateClubMembersActionDto';
import './index.less';
import { UpdateClubInvitationsActionDto } from 'Api/Features/Clubs/Dtos/UpdateClubInvitationsActionDto';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import { autorun } from 'mobx';
import debounce from 'lodash.debounce';
import { FilterStore } from 'Stores';
import { useFetch } from 'Hooks/use-fetch';

enum ClubTab {
    Joined = 'Joined',
    Discover = 'Discover',
    Invitations = 'Invitations',
}

interface ClubTypeFilter {
    icon?: string;
    id: string;
    displayName: string;
    selected: boolean;
}

const Clubs: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();
    const { toastStore, userStore } = useStores();
    const [createClubModalVisible, setCreateClubModalVisible] = useState(false);
    const clubService = useService(ClubService);
    const [clubTypeFilter, setClubTypeFilter] = useState<ClubTypeFilter[]>([
        { id: 'All', displayName: t('all'), selected: true },
        ...Object.keys(ClubCategoryDto).map((cat) => ({
            icon: ClubCategoryIconMap.get(cat as ClubCategoryDto)?.iconName,
            id: cat,
            displayName: t(`Clubs.ClubCategory_${cat}`),
            selected: false,
        })),
    ]);
    const { apiRequest, loadingStateKeys } = useFetch();

    const [clubs, setClubs] = useState<ClubDto[]>([]);
    const [clubsCurrentPage, setClubsCurrentPage] = useState(0);
    const [clubsTotalItemCount, setClubsTotalItemCount] = useState(0);
    const [displayNoClubs, setDisplayNoclubs] = useState(false);
    const [respondedInvitations, setRespondedInvitation] = useState<string[]>([]);

    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));

    const [selectedTab, setSelectedTab] = useState<ClubTab>(ClubTab.Joined);
    const [invitationAmount, setInvitationAmount] = useState(0);
    const [joinedAmount, setJoinedAmount] = useState(0);
    const [horizontalRadioOptions, setHorizontalRadioOptions] = useState<HorizontalRadioOption[]>([
        {
            label: t(`ClubTab.ClubTab_${ClubTab.Joined}`),
            value: ClubTab.Joined,
            count: joinedAmount,
            countIsLoading: true,
        },
        {
            label: t(`ClubTab.ClubTab_${ClubTab.Discover}`),
            value: ClubTab.Discover,
            countIsLoading: false,
        },
        {
            label: t(`ClubTab.ClubTab_${ClubTab.Invitations}`),
            value: ClubTab.Invitations,
            count: invitationAmount,
            countIsLoading: true,
        },
    ]);

    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex: CLUBS_URL_REGEX,
            requestType: RequestType.Clubs,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            handleTabChange(history.request.currentTab);
            const request: GetClubsRequestDto = history.request;
            const typeFilter = request.categories;
            setClubTypeFilter(() => {
                return [
                    { id: 'All', displayName: t('all'), selected: typeFilter === undefined },
                    ...Object.keys(ClubCategoryDto).map((category) => ({
                        icon: ClubCategoryIconMap.get(category as ClubCategoryDto)?.iconName,
                        id: category,
                        displayName: t(`Clubs.ClubCategory_${category}`),
                        selected: typeFilter?.some((type) => type === category) ?? false,
                    })),
                ];
            });
            filterStoreRef.current.updateSearchTerm(request.searchTerm ?? '');
        },
    });

    const handleFetchClubs = useCallback(
        async (
            currentPage: number,
            selectedTab: ClubTab,
            clubTypeFilter: ClubTypeFilter[],
            currentUserId?: string,
            searchTerm?: string
        ) => {
            if (!currentUserId) return;
            //never load more pages for invitations
            if (selectedTab === ClubTab.Invitations && currentPage > 0) return;

            const selectedCategory = clubTypeFilter.find((type) => type.selected)?.id;
            const request: GetClubsRequestDto = setRequestHistory({
                request: {
                    page: currentPage,
                    pageSize: PAGE_SIZE_INFINITE_LOADING,
                    searchTerm,
                    categories:
                        selectedCategory === 'All'
                            ? undefined
                            : [selectedCategory as ClubCategoryDto],
                    invitedUserIds:
                        selectedTab === ClubTab.Invitations ? [currentUserId] : undefined,
                    memberUserIds: selectedTab === ClubTab.Joined ? [currentUserId] : undefined,
                    favoritesForUserId: currentUserId,
                    excludeClubsForUserId:
                        selectedTab === ClubTab.Discover ? userStore.userInfo?.id : undefined,
                    currentTab: selectedTab, //api does not use this, this is for us in useHistoryCallback
                },
                requestType: RequestType.Clubs,
            });
            const [items, totalItemCount] = await apiRequest({
                requestFunction: (params) => clubService.getClubs(params),
                requestParameters: request,
                loadingStateKey: 'clubsLoading',
            });

            setClubsTotalItemCount(totalItemCount);

            setClubs((prev) => (currentPage > 0 ? [...prev, ...items] : [...items]));

            if (
                clubsCurrentPage === 0 &&
                totalItemCount === 0 &&
                searchTerm === undefined &&
                selectedCategory === 'All'
            ) {
                setDisplayNoclubs(true);
            } else {
                setDisplayNoclubs(false);
            }
        },
        [userStore?.userInfo?.id]
    );

    //Backend approves seperate calls for totals in tabs 16/02/23
    const handleFetchClubTabTotal = useCallback(
        async (tab: ClubTab) => {
            if (!userStore.userInfo?.id) return;
            const request: GetClubsRequestDto = {
                page: 0,
                pageSize: 0,
                invitedUserIds: tab === ClubTab.Invitations ? [userStore.userInfo.id] : undefined,
                memberUserIds: tab === ClubTab.Joined ? [userStore.userInfo.id] : undefined,
            };

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, totalItemCount] = await apiRequest({
                requestFunction: (params) => clubService.getClubs(params),
                requestParameters: request,
                loadingStateKey: 'tabCount',
            });

            if (tab === ClubTab.Invitations) {
                setInvitationAmount(totalItemCount);
                setHorizontalRadioOptions((prev) => {
                    prev.splice(2, 1, {
                        ...prev[2],
                        count: totalItemCount,
                        countIsLoading: false,
                    });
                    return [...prev];
                });
            } else {
                setJoinedAmount(totalItemCount);
                setHorizontalRadioOptions((prev) => {
                    prev.splice(0, 1, {
                        ...prev[0],
                        count: totalItemCount,
                        countIsLoading: false,
                    });
                    return [...prev];
                });
            }
        },
        [userStore?.userInfo?.id]
    );

    const handleScrollToBottom = () => {
        const maxIsAlreadyLoaded =
            clubsTotalItemCount > 0 ? clubs.length >= clubsTotalItemCount : false;
        if (maxIsAlreadyLoaded) return;

        setClubsCurrentPage((prev) => prev + 1);
    };

    const resetSearchResults = () => {
        setClubs([]);
        setClubsCurrentPage(0);
        setClubsTotalItemCount(0);
    };

    const resetFilters = () => {
        filterStoreRef.current.updateSearchTerm('');
        setClubTypeFilter((prev) => {
            prev = prev.map((type, i) => ({ ...type, selected: i === 0 }));
            return [...prev];
        });
    };

    const handleTabChange = (tab: ClubTab) => {
        setSelectedTab(tab);
        resetFilters();
        resetSearchResults();
        setRespondedInvitation([]);
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                currentPage: number;
                selectedTab: ClubTab;
                clubTypeFilter: ClubTypeFilter[];
                searchTerm?: string;
            }) => {
                handleFetchClubs(
                    params.currentPage,
                    params.selectedTab,
                    params.clubTypeFilter,
                    userStore.userInfo?.id,
                    params.searchTerm
                );
            },
            DEBOUNCE_DELAY_400
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                currentPage: clubsCurrentPage,
                selectedTab: selectedTab,
                clubTypeFilter: clubTypeFilter,
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch, selectedTab, clubTypeFilter, clubsCurrentPage]);

    useEffect(() => {
        if (userStore.userInfo?.id) {
            handleFetchClubTabTotal(ClubTab.Invitations);
            handleFetchClubTabTotal(ClubTab.Joined);
        }
    }, [userStore.userInfo?.id]);

    const handleInvitationAction = async (
        clubId: string | undefined,
        action: UpdateClubMembersActionDto
    ) => {
        if (userStore.userInfo?.id && clubId) {
            if (action === UpdateClubMembersActionDto.Add) {
                await apiRequest({
                    requestFunction: (params) => clubService.updateClubMembers(clubId, params),
                    requestParameters: {
                        entries: [
                            {
                                action: action as UpdateClubMembersActionDto,
                                userId: userStore.userInfo.id,
                            },
                        ],
                    },
                });
                //update clubs for user
                await userStore.setUserInfo(userStore.userInfo.id);
            } else {
                await apiRequest({
                    requestFunction: (params) => clubService.updateClubInvitations(clubId, params),
                    requestParameters: {
                        entries: [
                            {
                                action: UpdateClubInvitationsActionDto.Remove,
                                userId: userStore.userInfo.id,
                            },
                        ],
                    },
                });
            }

            //no re-fetching on invitation responding. keep a reference of which invitations not to display
            setRespondedInvitation((prev) => [...prev, clubId]);
        }
        toastStore.toast({
            type: 'success',
            message: t(`Clubs.club_invitation_${action}`),
        });

        handleFetchClubTabTotal(ClubTab.Invitations);
        handleFetchClubTabTotal(ClubTab.Joined);
    };

    return (
        <div className="Clubs">
            <div className="header">
                <HorizontalRadio
                    name={'clubTab'}
                    defaultValue={selectedTab}
                    options={horizontalRadioOptions}
                    onChange={(value) => {
                        handleTabChange(value as ClubTab);
                    }}
                />
                <Button
                    text={t('create_entity', {
                        param1: t('club'),
                        param2: 'un',
                        param3: t('Entity.lowercase_club'),
                    })}
                    type="link"
                    width="hugged"
                    rightCircleIcon="AddIcon"
                    onClick={() => setCreateClubModalVisible(true)}
                />
            </div>

            <Content>
                {displayNoClubs && clubs.length === 0 ? (
                    <div className="empty">
                        <div className="circle">
                            <PeopleFill3Icon width={74} height={74} fill={theme['white']} />
                        </div>
                        <div className="text-large-title text-mid-contrast">
                            {t(
                                `Clubs.no_${
                                    selectedTab === ClubTab.Invitations ? 'invitations' : 'clubs' //Dont t('') this is ok
                                }_at_this_time`
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="filter-section">
                            <div className="top">
                                <div className="text-title-2-bold">
                                    {t(`ClubTab.ClubTab_${selectedTab}`)}
                                    {selectedTab === ClubTab.Invitations &&
                                        ` (${invitationAmount})`}
                                    {selectedTab === ClubTab.Joined && ` (${joinedAmount})`}
                                </div>

                                <Input
                                    className="search"
                                    placeholder={t('search')}
                                    onChange={(value) => {
                                        filterStoreRef.current.updateSearchTerm(value.target.value);
                                        resetSearchResults();
                                    }}
                                    value={filterStoreRef.current.searchTerm}
                                    prefix={
                                        <MagnifyingGlassIcon fill={theme['primary-mid-contrast']} />
                                    }
                                />
                            </div>

                            <div className="type-filter-container">
                                {clubTypeFilter.map((type, i) => (
                                    <div
                                        className={`type-filter ${
                                            type.selected ? 'selected' : ''
                                        } Shadow-100 text-caption-1-bold cursor-pointer`}
                                        key={type.id}
                                        onClick={() => {
                                            resetSearchResults();
                                            setClubTypeFilter((prev) => [
                                                ...prev.map((type, i2) => ({
                                                    ...type,
                                                    selected: i2 === i,
                                                })),
                                            ]);
                                        }}
                                    >
                                        {type.icon && (
                                            <Icon
                                                iconName={type.icon}
                                                width={16}
                                                height={16}
                                                fill={
                                                    type.selected
                                                        ? theme.white
                                                        : theme['text-high-contrast']
                                                }
                                            />
                                        )}
                                        {type.displayName}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="container">
                            <div className="content">
                                <div className="body">
                                    {selectedTab !== ClubTab.Invitations ? (
                                        <ScrollInfinite
                                            items={clubs}
                                            RenderItem={({ item }: { item: ClubDto }) => (
                                                <ClubCard
                                                    club={item}
                                                    handleInvitationAction={handleInvitationAction}
                                                />
                                            )}
                                            RenderItemSkeleton={() => <ClubCard isSkeleton />}
                                            handlePagination={handleScrollToBottom}
                                            paginationOptions={{
                                                pageSize: PAGE_SIZE_INFINITE_LOADING,
                                                isLoading: loadingStateKeys.has('clubsLoading'),
                                            }}
                                            numberSkeletonLoading={4}
                                        />
                                    ) : (
                                        <Skeleton
                                            placeholder={
                                                <div className="invitations-container">
                                                    {[...Array(16)].map((k) => (
                                                        <ClubCard isSkeleton key={k} />
                                                    ))}
                                                </div>
                                            }
                                            isLoaded={!loadingStateKeys.has('clubsLoading')}
                                        >
                                            <div className="invitations-container">
                                                {clubs.map((club, i) => (
                                                    <ClubCard
                                                        key={i}
                                                        club={club}
                                                        isInvitation
                                                        handleInvitationAction={
                                                            handleInvitationAction
                                                        }
                                                        className={
                                                            respondedInvitations.some(
                                                                (id) => id === club.id
                                                            )
                                                                ? 'responded'
                                                                : ''
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </Skeleton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Content>

            {createClubModalVisible && (
                <CreateClubModal
                    onComplete={(success: boolean, id?: string, invitePeople?: boolean) => {
                        setCreateClubModalVisible(false);
                        if (success)
                            history.push(
                                CLUBS_URL + `/${id}${invitePeople ? '?invitePeople=true' : ''}`
                            );
                    }}
                    visible={createClubModalVisible}
                />
            )}

            {displayNoClubs && clubs.length === 0 && (
                <div className="wave">
                    <img width={'100%'} src={Wave} />
                </div>
            )}
        </div>
    );
});

export default Clubs;
