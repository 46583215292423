import { Tabs } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import './index.less';

interface DetailsPageNavigationTabsProps {
    defaultActiveKey: string;
    onChange?: (activeKey: string) => void;
    activeKey?: string;
}

const DetailsPageNavigationTabs: FunctionComponent<DetailsPageNavigationTabsProps> = ({
    defaultActiveKey,
    children,
    onChange,
    activeKey,
}) => {
    const [activeKeyState, setActiveKeyState] = useState<string | undefined>(defaultActiveKey);

    useEffect(() => {
        setActiveKeyState(activeKey);
    }, [activeKey]);

    return (
        <div className="DetailsPageNavigationTabs">
            <Tabs
                defaultActiveKey={defaultActiveKey}
                onChange={onChange}
                activeKey={activeKeyState}
            >
                {children}
            </Tabs>
        </div>
    );
};

export default DetailsPageNavigationTabs;
