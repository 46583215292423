import TimePickerDropdown, { TimePickerDropdownProps } from 'Components/time-picker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

interface TimeRangeProps {
    start: TimePickerDropdownProps;
    end: TimePickerDropdownProps;
}

const TimeRange: React.FunctionComponent<TimeRangeProps> = ({ start, end }) => {
    const { t } = useTranslation();

    return (
        <div className="TimeRange">
            <div className="time-wrapper">
                <span>{t('start')}</span>
                <TimePickerDropdown {...start} />
            </div>

            <div className="seperator" />

            <div className="time-wrapper">
                <span>{t('end')}</span>
                <TimePickerDropdown {...end} />
            </div>
        </div>
    );
};

export default TimeRange;
