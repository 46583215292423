import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import {
    HOUR_SCALE,
    MINIMUM_TIME_PERIOD_MINUTES,
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
} from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { theme } from 'Style/theme';
import './index.less';
import TimelineTooltip, { TimelineTooltipType } from './timeline-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { SupportedLanguage } from 'Stores/LanguageStore';

export interface HourTimelineProps {
    events: ScheduleEventDto[];
    timezoneConvert?: string | null;
    date: string;
}

const HourTimeline: FunctionComponent<HourTimelineProps> = observer(
    ({ events, timezoneConvert = '', date: dateProp }) => {
        const { languageStore } = useStores();
        const timeMarkers =
            languageStore.currentLanguage === SupportedLanguage.EN
                ? ['12 AM', '3', '6', '9', '12', '3', '6', '9', '12']
                : ['00h', '3', '6', '9', '12', '15', '17', '21', '00'];
        const slotsInHour = 60 /*(minutes)*/ / MINIMUM_TIME_PERIOD_MINUTES;
        const slotsInDay = 24 /*(hours)*/ * slotsInHour;

        const getAmountOfSlotsForDuration = (start: moment.Moment, end: moment.Moment): number => {
            const startTime = moment.tz(start, timezoneConvert ?? '');
            const endTime = moment.tz(end, timezoneConvert ?? '');
            let amount = 0;
            while (startTime.isBefore(endTime, 'minute')) {
                amount++;
                startTime.add(MINIMUM_TIME_PERIOD_MINUTES, 'minute');
            }
            return amount;
        };

        const getTimelineTooltipType = (
            periodsType: EventTypeDto | undefined
        ): TimelineTooltipType => {
            if (periodsType == EventTypeDto.OffHourAvailability) return TimelineTooltipType.offhour;
            if (periodsType == EventTypeDto.Work) return TimelineTooltipType.work;
            if (periodsType == EventTypeDto.Away) return TimelineTooltipType.away;
            return TimelineTooltipType.work;
        };

        const getBackgroundColor = (periodsType: EventTypeDto | undefined): string => {
            if (periodsType == EventTypeDto.OffHourAvailability)
                return theme['warning-mid-contrast'];
            if (periodsType == EventTypeDto.Away) return theme['layout-low-contrast'];
            if (periodsType == EventTypeDto.Work) return theme['success-mid-contrast'];
            return theme['error-mid-contrast'];
        };

        const getPeriods = useCallback(() => {
            const slots: ReactNode[] = [];

            const dateInTimezone = moment.tz(dateProp, timezoneConvert ?? '');
            const eventsForDateIntimezone: ScheduleEventDto[] = events.filter(
                (event) =>
                    moment
                        .tz(event.startTime, timezoneConvert ?? '')
                        .isBefore(dateInTimezone.clone().endOf('day')) &&
                    moment
                        .tz(event.endTime, timezoneConvert ?? '')
                        .isAfter(dateInTimezone.clone().startOf('day'))
            );

            if (eventsForDateIntimezone.length === 0) {
                slots.push(
                    <TimelineTooltip
                        key={uuidv4()}
                        type={TimelineTooltipType.unavailable}
                        title={`${moment()
                            .startOf('day')
                            .format(
                                FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                    languageStore.currentLanguage
                                ]
                            )} - ${moment()
                            .endOf('day')
                            .format(
                                FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                    languageStore.currentLanguage
                                ]
                            )}`}
                    >
                        <div
                            className="line-segment"
                            style={{
                                gridColumn: `span ${slotsInDay}`,
                                backgroundColor: theme['error-mid-contrast'],
                            }}
                        ></div>
                    </TimelineTooltip>
                );
            }

            let previousPeriodEndTime;

            for (let index = 0; index < eventsForDateIntimezone.length; index++) {
                const userTimezonedStartMoment = moment.tz(
                    eventsForDateIntimezone[index].startTime,
                    timezoneConvert ?? ''
                );
                const userTimezonedEndMoment = moment.tz(
                    eventsForDateIntimezone[index].endTime,
                    timezoneConvert ?? ''
                );

                //add an unavailable period until start of first period.
                if (index === 0) {
                    if (
                        userTimezonedStartMoment.isAfter(
                            moment.tz(dateInTimezone, timezoneConvert ?? '').startOf('day')
                        )
                    ) {
                        slots.push(
                            <TimelineTooltip
                                key={uuidv4()}
                                type={TimelineTooltipType.unavailable}
                                title={`${moment
                                    .tz(timezoneConvert ?? '')
                                    .startOf('day')
                                    .format(
                                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                            languageStore.currentLanguage
                                        ]
                                    )} - ${userTimezonedStartMoment.format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )}`}
                            >
                                <div
                                    className="line-segment"
                                    style={{
                                        gridColumn: `span ${getAmountOfSlotsForDuration(
                                            moment
                                                .tz(userTimezonedStartMoment, timezoneConvert ?? '')
                                                .startOf('day'),
                                            userTimezonedStartMoment
                                        )}`,
                                        backgroundColor: theme['error-mid-contrast'],
                                    }}
                                ></div>
                            </TimelineTooltip>
                        );
                    }
                    previousPeriodEndTime = userTimezonedStartMoment;
                }

                //add unavailable period between last period and current period
                if (previousPeriodEndTime.isBefore(userTimezonedStartMoment)) {
                    slots.push(
                        <TimelineTooltip
                            key={uuidv4()}
                            type={TimelineTooltipType.unavailable}
                            title={`${previousPeriodEndTime.format(
                                FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                    languageStore.currentLanguage
                                ]
                            )} - ${userTimezonedStartMoment.format(
                                FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                    languageStore.currentLanguage
                                ]
                            )}`}
                        >
                            <div
                                className="line-segment"
                                style={{
                                    gridColumn: `span ${getAmountOfSlotsForDuration(
                                        previousPeriodEndTime,
                                        userTimezonedStartMoment
                                    )}`,
                                    backgroundColor: theme['error-mid-contrast'],
                                }}
                            ></div>
                        </TimelineTooltip>
                    );
                }

                //if current work period spans into different day only take times for specified yearMonthDayString
                let currentEventStart = userTimezonedStartMoment;
                let currentEventEnd = userTimezonedEndMoment;
                if (userTimezonedStartMoment.isBefore(dateInTimezone.clone().startOf('day'))) {
                    currentEventStart = moment.tz(dateProp, timezoneConvert ?? '').startOf('day');
                }
                if (userTimezonedEndMoment.isAfter(dateInTimezone.clone().endOf('day'))) {
                    currentEventEnd = moment
                        .tz(dateProp, timezoneConvert ?? '')
                        .endOf('day')
                        .add(1, 'second');
                }

                //add current work period
                slots.push(
                    <TimelineTooltip
                        key={uuidv4()}
                        type={getTimelineTooltipType(eventsForDateIntimezone[index].type)}
                        title={`${currentEventStart.format(
                            FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
                        )} - ${currentEventEnd.format(
                            FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
                        )}`}
                    >
                        <div
                            className="line-segment"
                            style={{
                                gridColumn: `span ${getAmountOfSlotsForDuration(
                                    currentEventStart,
                                    currentEventEnd
                                )}`,
                                backgroundColor: getBackgroundColor(
                                    eventsForDateIntimezone[index].type
                                ),
                            }}
                        ></div>
                    </TimelineTooltip>
                );

                previousPeriodEndTime = moment.tz(currentEventEnd, timezoneConvert ?? '');

                //add an unavailable period from last period to end of day.
                if (index === eventsForDateIntimezone.length - 1) {
                    if (currentEventEnd.isBefore(currentEventStart.clone().endOf('day'))) {
                        slots.push(
                            <TimelineTooltip
                                key={uuidv4()}
                                type={TimelineTooltipType.unavailable}
                                title={`${currentEventEnd.format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )} - ${moment
                                    .tz(timezoneConvert ?? '')
                                    .endOf('day')
                                    .format(
                                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                            languageStore.currentLanguage
                                        ]
                                    )}`}
                            >
                                <div
                                    className="line-segment"
                                    style={{
                                        gridColumn: `span ${getAmountOfSlotsForDuration(
                                            currentEventEnd,
                                            moment
                                                .tz(currentEventEnd, timezoneConvert ?? '')
                                                .endOf('day')
                                        )}`,
                                        backgroundColor: theme['error-mid-contrast'],
                                    }}
                                ></div>
                            </TimelineTooltip>
                        );
                    }
                }
            }

            return slots;
        }, [events, timezoneConvert]);

        return (
            <div className="HourTimeline">
                <div
                    className="line"
                    style={{
                        gridTemplateColumns: `repeat(${slotsInDay},1fr)`,
                    }}
                >
                    {getPeriods()}
                </div>

                <div
                    className="marker-time-container"
                    style={{ gridTemplateColumns: `repeat(${slotsInDay},1fr)` }}
                >
                    {timeMarkers.map((timeMarker, i) => (
                        <div
                            key={i}
                            className="marker-time"
                            style={{ gridColumn: `span ${slotsInHour * HOUR_SCALE}` }}
                        >
                            <div className="marker-time-number">{timeMarker}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
);

export default HourTimeline;
