import React, { useState } from 'react';
import './button-dropdown-attending.less';
import { Popover } from 'antd';
import { ChevronDownIcon } from 'Components/icons';
import { useTranslation } from 'react-i18next';
import { RSVPToggleState } from '..';

export enum ButtonDropdownAttendingStyle {
    default = 'default',
    dashboard = 'dashboard',
}

interface ButtonDropdownAttendingProps {
    attendenceState: RSVPToggleState;
    canBringCompanion: boolean;
    onChangeAttendence: (isAttending: boolean, companionAmount: number) => void;
    style?: ButtonDropdownAttendingStyle;
}

const ButtonDropdownAttending: React.FunctionComponent<ButtonDropdownAttendingProps> = ({
    attendenceState,
    canBringCompanion,
    onChangeAttendence,
    style = ButtonDropdownAttendingStyle.default,
}) => {
    const { t } = useTranslation();
    const [popOverVisible, setPopOverVisible] = useState(false);

    const popOverContent = (
        <>
            {attendenceState !== RSVPToggleState.attending && (
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        onChangeAttendence(true, 0);
                        setPopOverVisible(false);
                    }}
                >
                    {t('Happening.attendence_attend_self')}
                </div>
            )}
            {canBringCompanion && attendenceState !== RSVPToggleState.attendingPlusOne && (
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        onChangeAttendence(true, 1);
                        setPopOverVisible(false);
                    }}
                >
                    {t('Happening.attendence_attend_someone')}
                </div>
            )}
            {attendenceState !== RSVPToggleState.notAttending && (
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        onChangeAttendence(false, 0);
                        setPopOverVisible(false);
                    }}
                >
                    {t('Happening.attendence_not_attend')}
                </div>
            )}
        </>
    );

    return (
        <div className="ButtonDropdownAttending">
            <Popover
                content={popOverContent}
                trigger="click"
                visible={popOverVisible}
                onVisibleChange={(visible: boolean) => setPopOverVisible(visible)}
                placement="bottomRight"
                overlayClassName="ButtonDropdownAttending-popover"
            >
                <div
                    className={`btn ${
                        style === ButtonDropdownAttendingStyle.dashboard ? 'dashboard-style' : ''
                    } ${attendenceState !== RSVPToggleState.notAttending ? 'attending' : ''}`}
                >
                    <div className="text-callout-bold">
                        {t(`RSVPToggleState.RSVPToggleState_${attendenceState}`)}
                    </div>
                    <ChevronDownIcon width={15} height={15} />
                </div>
            </Popover>
        </div>
    );
};

export default ButtonDropdownAttending;
