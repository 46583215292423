import WhiteCard from 'Components/white-card';
import React, { FunctionComponent } from 'react';
import './index.less';

interface TitledWhitecardSectionProps {
    title: string;
    subtitle?: string;
    className?: string;
    padding?: number;
}

const TitledWhitecardSection: FunctionComponent<TitledWhitecardSectionProps> = ({
    title,
    subtitle,
    className,
    padding,
    children,
}) => {
    return (
        <div className={`TitledWhitecardSection ${className ?? ''}`}>
            <div className={`text-headline-bold ${subtitle ? 'mb-5' : 'mb-20'}`}>{title}</div>
            {subtitle && <div className="text-caption-2 text-mid-contrast mb-20">{subtitle}</div>}
            <WhiteCard padding={padding ?? 40}>{children}</WhiteCard>
        </div>
    );
};

export default TitledWhitecardSection;
