import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PowerIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M13.9957 14.8079C14.5437 14.8079 14.9004 14.4152 14.9004 13.8262V5.99061C14.9004 5.4016 14.5437 5 13.9957 5C13.4563 5 13.0909 5.4016 13.0909 5.99061V13.8262C13.0909 14.4152 13.4563 14.8079 13.9957 14.8079ZM13.9957 24C18.9285 24 23 19.8234 23 14.7722C23 12.077 21.8342 9.721 20.2073 8.15031C19.3026 7.24002 18.0237 8.46266 18.9546 9.42649C20.3378 10.7651 21.1904 12.6571 21.1991 14.7722C21.2078 18.8685 17.9889 22.1616 13.9957 22.1616C10.0024 22.1616 6.80957 18.8685 6.80957 14.7722C6.80957 12.6482 7.66216 10.7562 9.04543 9.41757C9.97632 8.44481 8.69744 7.23109 7.79265 8.14138C6.15708 9.71207 5 12.077 5 14.7722C5 19.8234 9.07153 24 13.9957 24Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PowerIcon;
