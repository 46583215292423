import { Typography } from 'antd';
import Button from 'Components/button';
import Modal, { ModalHeaderColor } from 'Components/modal';
import React, { ReactNode } from 'react';
import './confirmation-modal.less';

const { Title } = Typography;

interface ModalAction {
    text: string;
    action: () => void;
}

export interface ConfirmationModalProps {
    id: string;
    icon?: ReactNode;
    title: string;
    message?: string | ReactNode;
    positive: ModalAction;
    positiveIsSecondary?: boolean;
    negative?: ModalAction;
    width?: number | null;
    centerButtons?: boolean;
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
    icon,
    title,
    message,
    positive,
    positiveIsSecondary = false,
    negative,
    width,
}) => {
    return (
        <Modal
            className="ConfirmationModal"
            visible
            onCancel={negative && negative.action}
            width={width ? width : 780}
            maxHeight="384"
            headerColor={ModalHeaderColor.white}
        >
            {icon && <div className="icon">{icon}</div>}
            <Title level={2} className="title text-title-2-bold">
                {title}
            </Title>
            {message && <div className="message text-body text-mid-contrast">{message}</div>}
            <div className="actions">
                {negative && negative.text && (
                    <Button text={negative.text} type="tertiary" onClick={negative.action} />
                )}

                <Button
                    text={positive.text}
                    type={positiveIsSecondary ? 'tertiary' : 'primary'}
                    onClick={positive.action}
                />
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
