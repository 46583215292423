import { action, observable } from 'mobx';

export interface HistoryRequest {
    /**Request dto object */
    request?: any;
    /**Type of request */
    requestType?: RequestType | null;
}

/**Helps differenciate requests and wether or not to use request from history */
export enum RequestType {
    Users = 'Users',
    Teams = 'Teams',
    TeamManagers = 'TeamManagers',
    Offices = 'Offices',
    Policies = 'Policies',
    TeamsPolicies = 'TeamsPolicies',
    Happenings = 'Happenings',
    Clubs = 'Clubs',
}

class RequestHistoryStore {
    @observable
    apiRequestHistory: HistoryRequest = {
        request: undefined,
        requestType: null,
    };

    @observable previousUrl = '';

    @action
    setApiRequestHistory(fetchRequest: HistoryRequest): any {
        this.apiRequestHistory = { ...fetchRequest };

        return fetchRequest.request;
    }

    @action
    setPreviousUrl(url: string): void {
        this.previousUrl = url;
    }
}

export default RequestHistoryStore;
