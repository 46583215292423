import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LocationIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M5.50969 12.2657C4.16496 12.881 4.44621 14.7618 5.9843 14.7706L12.5234 14.797C12.6552 14.797 12.6991 14.8497 12.6991 14.9815L12.7167 21.4767C12.7167 22.9884 14.6064 23.3311 15.2743 21.8722L21.9277 7.47568C22.6396 5.93759 21.4443 4.91806 19.9765 5.59482L5.50969 12.2657ZM7.60148 13.1886C7.53117 13.1886 7.50481 13.1095 7.58391 13.0655L19.9765 7.39658C20.082 7.35263 20.1435 7.41415 20.0907 7.51962L14.413 19.877C14.3779 19.9649 14.29 19.9474 14.29 19.8683L14.3251 13.9532C14.3251 13.4171 14.0702 13.1534 13.5165 13.1534L7.60148 13.1886Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LocationIcon;
