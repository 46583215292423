import { Tooltip } from 'antd';
import Icon from 'Components/icons/Icon';
import React, { memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './index.less';

export interface CtaRowProps {
    workType?: { iconName: string; label: string; onClick?: () => void };
    office?: {
        isDefaultOffice?: boolean;
        iconName: string;
        label: string;
        subLabel?: string;
        tooltip?: string;
        capacity?: string | ReactElement;
        onClick?: () => void;
    };
    workingFrom?: { value: string; url?: string };
    disabled?: boolean;
}

const CtaRow: React.FunctionComponent<CtaRowProps> = ({
    workType,
    office,
    workingFrom,
    disabled = false,
}) => {
    const { t } = useTranslation();

    let type = 'workType';
    if (office) type = 'office';
    if (workingFrom) type = 'workingFrom';

    const WorkingFromWrapper: React.FunctionComponent = ({ children }) => {
        return workingFrom?.url ? (
            <Link to={workingFrom.url}>{children}</Link>
        ) : (
            <div>{children}</div>
        );
    };

    const OfficeWrapper: React.FunctionComponent = ({ children }) => {
        return office?.tooltip ? (
            <Tooltip
                overlay={office.tooltip}
                className="office-tooltip"
                placement="top"
                arrowPointAtCenter={true}
            >
                {children}
            </Tooltip>
        ) : (
            <>{children}</>
        );
    };

    return (
        <div
            className={`CtaRow type-${type} isMainWorkplace-${
                office?.isDefaultOffice
            } disabled-${disabled} office-tooltip-${office?.tooltip !== undefined}`}
            onClick={(workType?.onClick || office?.onClick) ?? undefined}
        >
            {workType && (
                <>
                    <Icon iconName={workType.iconName} />
                    <div>{workType.label}</div>
                </>
            )}

            {office && (
                <OfficeWrapper>
                    <Icon iconName={office.iconName} />
                    <div>
                        <div className="office">{office.label}</div>
                        {office.subLabel && <div className="space">{office.subLabel}</div>}
                    </div>
                    <div className="capacity">{office.capacity}</div>
                </OfficeWrapper>
            )}

            {workingFrom && (
                <WorkingFromWrapper>
                    <div className="label">{t('working_from')}</div>
                    <div className="value">{workingFrom.value}</div>
                </WorkingFromWrapper>
            )}
        </div>
    );
};

export default memo(CtaRow);
