import React from 'react';
import { LineChart as ReLineChart, Line } from 'recharts';
import { theme } from 'Style/theme';

interface LineChartProps {
    data: any;
    percentage: number;
}

const LineChart: React.FunctionComponent<LineChartProps> = ({ data, percentage }) => {
    const THEME_SUCCESS = theme['success-mid-contrast'];
    const THEME_FAILED = theme['error-mid-contrast'];
    const THEME_OK = theme['layout-mid-main'];
    const STROKEWIDTH = 3;

    const strokeColor =
        percentage >= 3 ? THEME_SUCCESS : percentage <= -3 ? THEME_FAILED : THEME_OK;

    return (
        <div className="LineChart">
            <ReLineChart width={100} height={46} data={data}>
                <Line
                    type="monotone"
                    dataKey="pv"
                    stroke={strokeColor}
                    strokeWidth={STROKEWIDTH}
                    dot={<div />}
                />
            </ReLineChart>
        </div>
    );
};

export default LineChart;
