import React, { ReactElement, useCallback, useRef } from 'react';

interface ScrollInfiniteProps {
    handlePagination: (action: 'next' | 'prev') => void;
    items: any[];
    RenderItem: (item: any) => ReactElement;
    RenderItemSkeleton: () => ReactElement;
    paginationOptions: {
        pageSize: number;
        isLoading: boolean;
    };
    numberSkeletonLoading?: number;
}

const ScrollInfinite: React.FunctionComponent<ScrollInfiniteProps> = ({
    handlePagination,
    items,
    RenderItem,
    RenderItemSkeleton,
    paginationOptions,
    numberSkeletonLoading = Math.floor(paginationOptions.pageSize / 2),
}) => {
    const observer: any = useRef();

    const lastItemInList = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    handlePagination('next');
                    observer.current.disconnect();
                }
            });
            if (node) observer.current.observe(node);
        },
        [observer]
    );

    return (
        <div className="ScrollInfinite">
            <div className="wrapper-skeleton-scroll-infinite-items">
                {items.map((item, index) => {
                    const isLast = items.length - 1 === index;

                    return (
                        <div key={index} ref={isLast ? lastItemInList : undefined}>
                            <RenderItem item={item} />
                        </div>
                    );
                })}
            </div>

            {paginationOptions.isLoading && (
                <div className="wrapper-skeleton-scroll-infinite-loading-items">
                    {[...Array(numberSkeletonLoading)].map((item, key) => {
                        return (
                            <div key={`${key}+loading`}>
                                <RenderItemSkeleton />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ScrollInfinite;
