import * as yup from 'yup';

export const CreateFloorSchema = yup.object({
    name: yup
        .string()
        .required('Errors.error_required')
        .min(2, 'Errors.error_min_2')
        .max(40, 'Errors.error_max_40'),
    svg: yup.string().required('Errors.error_required'),
});

export const AssignSpaceSchema = yup.object({
    createNew: yup.boolean(),
    spaceId: yup.string().when('createNew', {
        is: false,
        then: yup.string().required('Errors.error_required'),
    }),
    name: yup.string().when('createNew', {
        is: true,
        then: yup
            .string()
            .required('Errors.error_required')
            .min(2, 'Errors.error_min_2')
            .max(40, 'Errors.error_max_40'),
    }),
    capacity: yup.number().when('createNew', {
        is: true,
        then: yup
            .number()
            .required('Errors.error_required')
            .min(1, 'Errors.error_min_number_1')
            .max(500, 'Errors.error_max_number_500'),
    }),
});
