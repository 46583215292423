import { Divider } from 'antd';
import { UpdateUserGoogleCalendarAccountConfigRequestDto } from 'Api/Features/GoogleCalendar/Dtos/UpdateUserGoogleCalendarAccountConfigRequestDto';
import { UserGoogleCalendarAccountConfigDto } from 'Api/Features/GoogleCalendar/Dtos/UserGoogleCalendarAccountConfigDto';
import Button from 'Components/button';
import { GoogleCalendarIcon } from 'Components/icons';
import Skeleton from 'Components/skeleton';
import { useService, useStores } from 'Hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleCalendarService } from 'Services/GoogleCalendarService';
import { profileIntegrationGoogleCalendarShape } from '../../skeleton-shapes';
import './profile-integration-section.less';
import TitledWhitecardSection from '../titled-whitecard-section';
import { observer } from 'mobx-react';

const ProfileIntegrationSection: React.FunctionComponent = observer(() => {
    const googleCalendarService = useService(GoogleCalendarService);
    const { userStore, toastStore, globalLoadingStore } = useStores();
    const { t } = useTranslation();

    const [userGoogleCalendarAccountConfig, setUserGoogleCalendarAccountConfig] =
        useState<UserGoogleCalendarAccountConfigDto>({});
    const [isLoaded, setIsLoaded] = useState({
        googleCalendar: {
            status: false,
        },
    });

    const fetchGoogleCalendarStatus = useCallback(async () => {
        try {
            setIsLoaded((prev) => {
                return {
                    ...prev,
                    googleCalendar: {
                        ...prev.googleCalendar,
                        status: false,
                    },
                };
            });

            const response = await googleCalendarService.getUserGoogleCalendarAccountConfig(
                userStore?.userInfo?.id || ''
            );
            if (response) setUserGoogleCalendarAccountConfig(response);
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        } finally {
            setIsLoaded((prev) => {
                return {
                    ...prev,
                    googleCalendar: {
                        ...prev.googleCalendar,
                        status: true,
                    },
                };
            });
        }
    }, [userStore?.userInfo?.id]);

    useEffect(() => {
        if (userStore?.userInfo?.id) {
            fetchGoogleCalendarStatus();
        }
    }, [userStore?.userInfo?.id]);

    const fetchUpdateGoogleStatus = async (newStatus: boolean, googleCode?: string) => {
        try {
            globalLoadingStore.addLoading();

            const request: UpdateUserGoogleCalendarAccountConfigRequestDto = {
                availabilitiesSyncEnabled: newStatus,
                code: googleCode ? googleCode : null,
            };
            await googleCalendarService.updateUserGoogleCalendarAccountConfig(
                userStore.userInfo?.id || '',
                request
            );
            fetchGoogleCalendarStatus();
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onClickConnect = async () => {
        await googleCalendarService.runConnectGoogleCalendarFlow(
            userGoogleCalendarAccountConfig.authorizeUrl || '',
            userStore?.userInfo?.id || ''
        );
    };

    const onClickDisconnect = async () => {
        await fetchUpdateGoogleStatus(false);
    };

    return (
        <TitledWhitecardSection
            title={t('Settings.menu_integration_title')}
            padding={40}
            className="ProfileIntegrationSection"
        >
            <div className="container-google-sync">
                <Skeleton
                    isLoaded={isLoaded.googleCalendar.status}
                    placeholder={profileIntegrationGoogleCalendarShape}
                >
                    <div className="container-logo">
                        <GoogleCalendarIcon />
                    </div>

                    <Divider type="vertical" />

                    <div className="container-center">
                        <div className="text-callout-bold">
                            {t('ProfileIntegrationSection.google_calendar_sync')}
                        </div>
                        <div className="text-caption-1 text-mid-contrast">
                            {t('ProfileIntegrationSection.allows_flexy_to_auto_create_events')}
                        </div>
                    </div>

                    <div className="container-right">
                        {userGoogleCalendarAccountConfig.googleUserInfo &&
                        userGoogleCalendarAccountConfig.googleUserInfo !== null ? (
                            <>
                                <div className="container-connected">
                                    <div className="top">
                                        <div className="circle-green" />
                                        <div className="text-body">{t('connected')}</div>
                                    </div>
                                    <div className="text-caption-1">
                                        {userGoogleCalendarAccountConfig.googleUserInfo?.email}
                                    </div>
                                </div>

                                <Button
                                    text={t('disconnect')}
                                    type="light"
                                    onClick={onClickDisconnect}
                                    width="hugged"
                                />
                            </>
                        ) : (
                            <div className="button-google-connect" onClick={onClickConnect}>
                                <GoogleCalendarIcon />
                                <div className="text-callout-bold">
                                    {t('ProfileIntegrationSection.sign_in_to _google_calendar')}
                                </div>
                            </div>
                        )}
                    </div>
                </Skeleton>
            </div>
        </TitledWhitecardSection>
    );
});

export default ProfileIntegrationSection;
