import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { SystemProxy } from 'Api/Features/System/SystemProxy';
import { GetDefaultLanguageResponseDto } from 'Api/Features/System/Dtos/GetDefaultLanguageResponseDto';
import { UpdateDefaultLanguageRequestDto } from 'Api/Features/System/Dtos/UpdateDefaultLanguageRequestDto';

@inject(SystemProxy)
export class SystemService extends ApiService {
    constructor(private readonly systemProxy: SystemProxy) {
        super();
    }

    public async getDefaultLanguage(): Promise<GetDefaultLanguageResponseDto | null> {
        const data: GetDefaultLanguageResponseDto | null =
            await this.systemProxy.getDefaultLanguage();
        return data;
    }

    public async updateDefaultLanguage(
        request: UpdateDefaultLanguageRequestDto | null
    ): Promise<void> {
        await this.systemProxy.updateDefaultLanguage(request);
    }
}
