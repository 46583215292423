import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MagnifyingLessIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M12.917 19.834C14.3145 19.834 15.624 19.4121 16.7139 18.6914L20.5635 22.5498C20.8184 22.7959 21.1436 22.9189 21.4951 22.9189C22.2246 22.9189 22.7607 22.3477 22.7607 21.627C22.7607 21.293 22.6465 20.9678 22.4004 20.7217L18.5771 16.8809C19.3682 15.7559 19.834 14.3936 19.834 12.917C19.834 9.11133 16.7227 6 12.917 6C9.12012 6 6 9.11133 6 12.917C6 16.7227 9.11133 19.834 12.917 19.834ZM12.917 17.9883C10.1309 17.9883 7.8457 15.7031 7.8457 12.917C7.8457 10.1309 10.1309 7.8457 12.917 7.8457C15.7031 7.8457 17.9883 10.1309 17.9883 12.917C17.9883 15.7031 15.7031 17.9883 12.917 17.9883ZM10.6494 13.6113H15.1846C15.5625 13.6113 15.8789 13.2949 15.8789 12.917C15.8789 12.5391 15.5625 12.2227 15.1846 12.2227H10.6494C10.2715 12.2227 9.95508 12.5391 9.95508 12.917C9.95508 13.2949 10.2715 13.6113 10.6494 13.6113Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default MagnifyingLessIcon;
