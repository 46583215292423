import React, { FunctionComponent, useCallback, useState } from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FilterStore } from 'Stores';
import {
    ArrowClockWiseIcon,
    FunnelIcon,
    FunnelIconFill,
    MagnifyingGlassIcon,
} from 'Components/icons';
import { theme } from 'Style/theme';
import './table-filters.less';
import TeamFilterSelect from './team-filter-select/team-filter-select';
// import { AdvancedFilters } from './advanced-filters';
import { AdvancedFiltersInline } from './advanced-filters-inline';
import AdvancedFilterDropdown from './advanced-filters/advanced-filter-dropdown';
import Tag, { TagColor } from 'Components/tag/tag';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import TagNumber from 'Components/tag-number/tag-number';
import OfficeFilterSelect from './office-filter-select';
import Skeleton from 'Components/skeleton';
import {
    advancedFiltersSkeletonShape,
    searchBarSkeletonShape,
    teamSelectSkeletonShape,
} from './skeleton-shapes';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import Button from 'Components/button';

interface TableFiltersProps {
    filterStore: FilterStore;
    includeSearch?: boolean;
    includeAdvancedFilters?: boolean;
    includeTeamSelect?: boolean;
    defaultSelectedTeamIds?: string[];
    includeUserStatusSelect?: boolean;
    advancedFiltersDefaultValue?: () => AdvancedFilter[];
    includeOfficeSelect?: boolean;
    defaultSelectedOfficeIds?: string[];
    isLoaded?: boolean;
    genericActionButton?: {
        text: string;
        genericActionOnClick: () => void;
    };
}

enum EModeDeleteFilter {
    teams = 'teams',
    offices = 'offices',
}

const TableFilters: FunctionComponent<TableFiltersProps> = observer(
    ({
        filterStore,
        includeSearch,
        includeAdvancedFilters,
        includeTeamSelect,
        defaultSelectedTeamIds,
        includeUserStatusSelect,
        advancedFiltersDefaultValue,
        includeOfficeSelect,
        defaultSelectedOfficeIds,
        isLoaded,
        genericActionButton,
    }) => {
        const { t } = useTranslation();
        const [showAdvanced, setShowAdvanced] = useState(false);
        const [selectedTeamOptions, setSelectedTeamOptions] = useState<MultiSelectCustomOption[]>(
            []
        );
        const [selectedOfficeOptions, setSelectedOfficeOptions] = useState<
            MultiSelectCustomOption[]
        >([]);

        const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            filterStore.updateSearchTerm(event.target.value);
        };

        const toggleAdvanced = (): void => {
            setShowAdvanced(!showAdvanced);
        };

        const onAdvancedFilterChange = (key: string, parentKey: string): void => {
            filterStore.toggleAdvancedFilter(key, parentKey);
        };

        const onTeamChange = (teams: MultiSelectCustomOption[]) => {
            const teamIds = teams?.map((x: any) => x.value);
            filterStore.updateTeamIds(teamIds);
            setSelectedTeamOptions(teams);
        };

        const onOfficeChange = (offices: MultiSelectCustomOption[]) => {
            const officeIds = offices?.map((x: any) => x.value);
            filterStore.updateOfficeIds(officeIds);
            setSelectedOfficeOptions(offices);
        };

        const getFilterActive = useCallback(() => {
            const arr: any = [];

            if (!includeAdvancedFilters) return arr;

            filterStore?.advancedFilters?.map((parentKey) => {
                parentKey.items.map((item) => {
                    if (item.checked) arr.push({ ...item, parentKey: parentKey.key });
                    return;
                });
            });
            return arr;
        }, [filterStore?.advancedFilters]);

        const onClickResetFilters = () => {
            if (advancedFiltersDefaultValue) {
                const defaultValue: AdvancedFilter[] = advancedFiltersDefaultValue();

                defaultValue.map((parentKey) => {
                    parentKey.items.map((childrenFilter) => {
                        const parent = filterStore?.advancedFilters?.find(
                            (filter) => filter.key === parentKey.key
                        );
                        const filterItem = parent?.items.find(
                            (item) => item.key === childrenFilter.key
                        );

                        if (filterItem?.checked != childrenFilter.checked)
                            onAdvancedFilterChange(childrenFilter.key, parentKey.key);
                    });
                });
            }

            if (includeTeamSelect) onTeamChange([]);

            if (
                includeUserStatusSelect &&
                filterStore?.advancedFilters &&
                !advancedFiltersDefaultValue
            ) {
                filterStore?.advancedFilters.map((parent) => {
                    parent.items.map((childrenFilter) => {
                        if (childrenFilter.checked) {
                            onAdvancedFilterChange(childrenFilter.key, parent.key);
                        }
                    });
                });
            }
        };

        const onClickDeleteFilter = (filter: any, mode?: EModeDeleteFilter) => {
            if (filter && filter.key && filter.parentKey) {
                onAdvancedFilterChange(filter.key, filter.parentKey);
            }
            if (filter && mode == EModeDeleteFilter.teams) {
                const newTeamsSelect = selectedTeamOptions.filter((team) => {
                    return team.value !== filter.value;
                });
                onTeamChange(newTeamsSelect);
            }
            if (filter && mode == EModeDeleteFilter.offices) {
                const newOfficesSelect = selectedOfficeOptions.filter((office) => {
                    return office.value !== filter.value;
                });
                onOfficeChange(newOfficesSelect);
            }
        };

        const SIZE_ICON = 24;

        return (
            <div className="TableFilters">
                <div className="filters-actions">
                    {genericActionButton && (
                        <Button
                            text={genericActionButton.text}
                            type="floating"
                            width="hugged"
                            onClick={genericActionButton.genericActionOnClick}
                        />
                    )}
                    {includeTeamSelect && (
                        <Skeleton placeholder={teamSelectSkeletonShape} isLoaded={isLoaded}>
                            <TeamFilterSelect
                                onChange={onTeamChange}
                                defaultSelectedIds={defaultSelectedTeamIds}
                            />
                        </Skeleton>
                    )}

                    {includeOfficeSelect && (
                        <OfficeFilterSelect
                            onChange={onOfficeChange}
                            defaultSelectedIds={defaultSelectedOfficeIds}
                        />
                    )}

                    {includeUserStatusSelect && (
                        <AdvancedFilterDropdown
                            filter={
                                filterStore.advancedFilters?.find(
                                    (filter) => filter.key === 'status'
                                ) ?? { key: 'status', nameKey: 'Status', items: [] }
                            }
                            onItemChange={onAdvancedFilterChange}
                        />
                    )}

                    {includeAdvancedFilters && filterStore.advancedFilters && (
                        <Skeleton placeholder={advancedFiltersSkeletonShape} isLoaded={isLoaded}>
                            <div
                                className={`advanced-filters-btn ${showAdvanced ? ' active' : ''}`}
                                onClick={toggleAdvanced}
                            >
                                {showAdvanced && (
                                    <TagNumber number={getFilterActive().length.toString()} />
                                )}
                                <div className="text text-body">{t('advanced_filters')}</div>
                                {!showAdvanced ? (
                                    <FunnelIcon
                                        height={SIZE_ICON}
                                        width={SIZE_ICON}
                                        fill={theme['layout-mid-main']}
                                    />
                                ) : (
                                    <FunnelIconFill
                                        height={SIZE_ICON}
                                        width={SIZE_ICON}
                                        fill={theme['layout-mid-main']}
                                    />
                                )}
                            </div>
                        </Skeleton>
                    )}

                    {includeSearch && (
                        <Skeleton placeholder={searchBarSkeletonShape} isLoaded={isLoaded}>
                            <Input
                                className="search-bar"
                                placeholder={t('search')}
                                onChange={onSearchChange}
                                prefix={
                                    <MagnifyingGlassIcon fill={theme['primary-mid-contrast']} />
                                }
                                value={filterStore.searchTerm}
                            />
                        </Skeleton>
                    )}
                </div>

                {showAdvanced && includeAdvancedFilters && filterStore.advancedFilters && (
                    <>
                        <AdvancedFiltersInline
                            filters={filterStore.advancedFilters}
                            onItemChange={onAdvancedFilterChange}
                        />
                        <div className="advanced-filters-inline-preview-container">
                            <div className="text-body">{t('Table.filters_by')}:</div>
                            {getFilterActive().map((filter) => {
                                return (
                                    <Tag
                                        onClick={() => onClickDeleteFilter(filter)}
                                        canDelete
                                        key={filter.key}
                                        text={filter.displayNameKey}
                                        color={TagColor.blue}
                                    />
                                );
                            })}
                            {includeTeamSelect &&
                                selectedTeamOptions.map((team) => {
                                    return team.label ? (
                                        <Tag
                                            onClick={() =>
                                                onClickDeleteFilter(team, EModeDeleteFilter.teams)
                                            }
                                            canDelete
                                            key={team.value}
                                            text={team.label}
                                            color={TagColor.blue}
                                        />
                                    ) : (
                                        ''
                                    );
                                })}

                            {includeOfficeSelect &&
                                selectedOfficeOptions.map((office) => {
                                    return office.label ? (
                                        <Tag
                                            onClick={() =>
                                                onClickDeleteFilter(
                                                    office,
                                                    EModeDeleteFilter.offices
                                                )
                                            }
                                            canDelete
                                            key={office.value}
                                            text={office.label}
                                            color={TagColor.blue}
                                        />
                                    ) : (
                                        ''
                                    );
                                })}
                            {advancedFiltersDefaultValue && (
                                <div
                                    onClick={onClickResetFilters}
                                    className="reset-filter-container"
                                >
                                    <ArrowClockWiseIcon />
                                    <div className="text-caption-1-bold reset-filter">
                                        {t('Table.reset_filters')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                    // <Row gutter={[20, 20]}>
                    //     <Col span={24}>
                    //         <AdvancedFilters
                    //             filters={filterStore.advancedFilters}
                    //             onItemChange={onAdvancedFilterChange}
                    //         />
                    //     </Col>
                    // </Row>
                )}
            </div>
        );
    }
);

export default TableFilters;
