import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MessageBubbleIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M7.79492 23.2168C9.0166 23.2168 11.4863 22.0303 13.3496 20.7031C19.5547 20.8174 24.1953 17.2314 24.1953 12.5205C24.1953 7.97656 19.6514 4.32031 13.9912 4.32031C8.33105 4.32031 3.7959 7.97656 3.7959 12.5205C3.7959 15.4121 5.58887 17.9873 8.33984 19.332C7.96191 20.0439 7.29395 21.0195 6.91602 21.5293C6.35352 22.2764 6.74023 23.2168 7.79492 23.2168ZM8.8584 21.3975C8.74414 21.4414 8.7002 21.3799 8.7793 21.2832C9.22754 20.7383 9.88672 19.8682 10.2119 19.2529C10.4756 18.7695 10.3877 18.3477 9.8252 18.084C7.14453 16.8271 5.58887 14.8145 5.58887 12.5205C5.58887 8.9873 9.31543 6.11328 13.9912 6.11328C18.667 6.11328 22.3936 8.9873 22.3936 12.5205C22.3936 16.0537 18.667 18.9365 13.9912 18.9365C13.833 18.9365 13.5869 18.9277 13.2881 18.9277C12.9102 18.9189 12.6289 19.0332 12.2773 19.3057C11.2139 20.0967 9.64062 21.0811 8.8584 21.3975Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default MessageBubbleIcon;
