import Button from 'Components/button';
import Modal, { ModalHeaderColor } from 'Components/modal';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpaceViewerModalContent, {
    SpaceViewerScheduleType,
} from './components/space-viewer-modal-content';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { EMPTY_GUID } from 'Models/Constants';
import SubmitButton from 'Components/submit-button/submit-button';
import FloorPlanViewer from './components/floor-plan-viewer/floor-plan-viewer';
import './space-viewer-modal.less';
import ViewTypeToggle, { SpaceViewerViewType } from './components/view-type-toggle';
import { FloorPlanDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanDto';
import { useFetch } from 'Hooks/use-fetch';
import { useService } from 'Hooks';
import { FloorPlanService } from 'Services/FloorPlanService';
import { FloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanFloorDto';
import Skeleton from 'Components/skeleton';

interface SpaceViewerModalProps {
    visible: boolean;
    onComplete: (success: boolean, space?: SingleSelectCustomOption) => void;
    selectedOffice: SingleSelectCustomOption;
    selectedSpaceProp?: SingleSelectCustomOption;
    timeRange: {
        startTime: string;
        endTime: string;
    };
}

const SpaceViewerModal: React.FC<SpaceViewerModalProps> = ({
    visible,
    onComplete,
    selectedOffice,
    selectedSpaceProp,
    timeRange,
}) => {
    const { t } = useTranslation();
    const floorPlanService = useService(FloorPlanService);
    const { apiRequest, loadingStateKeys } = useFetch();
    const [floorPlanFloors, setFloorPlanFloors] = useState<FloorPlanFloorDto[]>([]);

    const [selectedSpace, setSelectedSpace] = useState<SingleSelectCustomOption | undefined>(
        selectedSpaceProp ?? undefined
    );
    const [spaceViewerType, setSpaceViewerType] = useState<SpaceViewerViewType>(
        SpaceViewerViewType.SpaceViewer
    );

    const submit = async (): Promise<void> => {
        dismiss(true, selectedSpace ? selectedSpace : { value: EMPTY_GUID, label: '' }); //must put empty_guid thats how api removes space in the dashboard call
    };

    const dismiss = (success = false, space: SingleSelectCustomOption | undefined): void => {
        onComplete(success, space);
    };

    //floor plan must be fetched in order to determine if the view switcher is active
    const fetchFloorPlan = useCallback(async () => {
        const floorPlan: FloorPlanDto = await apiRequest({
            requestFunction: () => floorPlanService.getFloorPlan(selectedOffice.value),
            requestParameters: undefined,
            loadingStateKey: 'floorplan',
        });
        if (floorPlan) {
            setFloorPlanFloors(floorPlan.floors?.filter((x) => x !== null).map((x) => x!) ?? []);
        }
    }, [floorPlanService]);

    useEffect(() => {
        fetchFloorPlan();
    }, []);

    return (
        <Modal
            className="SpaceViewerModal"
            visible={visible}
            onCancel={() => dismiss(false, undefined)}
            headerText={selectedOffice.label}
            width={spaceViewerType == SpaceViewerViewType.SpaceViewer ? 640 : 1200}
            headerColor={ModalHeaderColor.white}
            noPadding
            footer={
                <>
                    <Button
                        text={t('cancel')}
                        type="tertiary"
                        onClick={() => dismiss(false, undefined)}
                    />

                    <SubmitButton text={t('confirm')} type="primary" onClick={() => submit()} />
                </>
            }
        >
            <div className="viewer-type-container">
                <div className="text-callout">{t('choose_a_space')}</div>
                <Skeleton
                    isLoaded={!loadingStateKeys.has('floorplan')}
                    defaultPadding={false}
                    placeholder={
                        <div style={{ width: 'auto', marginLeft: 'auto' }}>
                            <div className="roundRect" style={{ width: 82, height: 47 }}></div>
                        </div>
                    }
                >
                    <ViewTypeToggle
                        selected={spaceViewerType}
                        onChange={(selected: SpaceViewerViewType) => {
                            setSpaceViewerType(selected);
                        }}
                        floorPlanAvailable={floorPlanFloors.length > 0}
                    />
                </Skeleton>
            </div>
            {spaceViewerType == SpaceViewerViewType.SpaceViewer && (
                <SpaceViewerModalContent
                    officeId={selectedOffice.value}
                    timeRange={timeRange}
                    setSelectedSpace={setSelectedSpace}
                    selectedSpace={selectedSpace}
                    scheduleType={SpaceViewerScheduleType.schedule}
                />
            )}

            {spaceViewerType == SpaceViewerViewType.FloorPlan &&
                (floorPlanFloors?.length ?? 0) > 0 && (
                    <FloorPlanViewer
                        floorPlanFloors={floorPlanFloors}
                        timeRange={timeRange}
                        setSelectedSpace={setSelectedSpace}
                        selectedSpace={selectedSpace}
                        scheduleType={SpaceViewerScheduleType.schedule}
                    />
                )}
        </Modal>
    );
};

export default SpaceViewerModal;
