import OnboardingPreference from 'Assets/Images/onboarding-preferences.svg';
import OnboardingMySchedule from 'Assets/Images/onboarding-myschedule.svg';
import OnboardingWorkspaces from 'Assets/Images/onboarding-workspaces.svg';
import Button from 'Components/button';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingBaseProps } from '..';
import './base-styles.less';
import './other-features.less';

const OnboardingOtherFeatures: React.FunctionComponent<OnboardingBaseProps> = observer(
    ({ onNextClick, onPreviousClick }) => {
        const { t } = useTranslation();
        const handleNextClick = () => {
            onNextClick();
        };
        return (
            <div className="OnboardingOtherFeatures OnboardingBase">
                <div className="text-title-1-bold mb-20 centered pt-40">
                    {t('Onboarding.other_features_title')}
                </div>

                <div className="text-callout text-mid-contrast mb-20 centered subtitle">
                    {t('Onboarding.other_features_subtitle')}
                </div>

                <div className="content">
                    <div className="cell schedule">
                        <img src={OnboardingMySchedule} />

                        <div className="title text-headline text-high-contrast">
                            {t('Onboarding.other_feature_title_left')}
                        </div>

                        <div className="text-regular-caption text-mid-contrast subtitle">
                            {t('Onboarding.other_feature_subtitle_left')}
                        </div>
                    </div>

                    <div className="cell preferences">
                        <img src={OnboardingPreference} />

                        <div className="title text-headline text-high-contrast">
                            {t('Onboarding.other_feature_title_middle')}
                        </div>

                        <div className="text-regular-caption text-mid-contrast subtitle">
                            {t('Onboarding.other_feature_subtitle_middle')}
                        </div>
                    </div>

                    <div className="cell workspaces">
                        <img src={OnboardingWorkspaces} />
                        <div className="title text-headline text-high-contrast">
                            {t('Onboarding.other_feature_title_right')}
                        </div>
                        <div className="text-regular-caption text-mid-contrast subtitle">
                            {t('Onboarding.other_feature_subtitle_right')}
                        </div>
                    </div>
                </div>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button text={t('previous')} type="tertiary" onClick={onPreviousClick} />
                        <Button
                            text={t('Onboarding.set_up_profile')}
                            type="primary"
                            onClick={() => handleNextClick()}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default OnboardingOtherFeatures;
