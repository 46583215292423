import Icon from 'Components/icons/Icon';
import React, { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import './index.less';

const getIcon = (icons: ButtonIcons) => {
    if (icons.leftIcon)
        return (
            <span className="icon-container leftIcon">
                <Icon iconName={icons.leftIcon} />
            </span>
        );
    if (icons.leftCircleIcon)
        return (
            <span className="icon-container leftCircleIcon">
                <Icon iconName={icons.leftCircleIcon} />
            </span>
        );
    if (icons.rightIcon)
        return (
            <span className="icon-container rightIcon">
                <Icon iconName={icons.rightIcon} />
            </span>
        );
    if (icons.rightCircleIcon)
        return (
            <span className="icon-container rightCircleIcon">
                <Icon iconName={icons.rightCircleIcon} />
            </span>
        );

    return null;
};

export type Type =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'light'
    | 'small'
    | 'smallLight'
    | 'floating'
    | 'link'
    | 'greenchecked'
    | 'box';

export type Width = 'full' | 'auto' | 'hugged';

export type Height = 'small';

export interface ButtonIcons {
    leftIcon?: string;
    rightIcon?: string;
    leftCircleIcon?: string;
    rightCircleIcon?: string;
}

export interface ButtonProps {
    text: string;
    type: Type;
    link?: string;
    externalLink?: { url: string; newTab?: boolean };
    width?: Width;
    height?: Height;
}

type Button = ButtonProps & ButtonIcons & HTMLProps<HTMLButtonElement>;

const Button: React.FunctionComponent<Button> = ({
    text,
    type,
    link,
    externalLink,
    width,
    height,
    leftIcon,
    rightIcon,
    leftCircleIcon,
    rightCircleIcon,
    className,
    disabled = false,
    ...htmlProps
}) => {
    const classes = `Button ${className} type-${type} width-${width} height-${height} disabled-${disabled}`;
    const content = (
        <>
            {getIcon({ leftIcon, leftCircleIcon })}
            {text}
            {getIcon({ rightIcon, rightCircleIcon })}
        </>
    );

    return link ? (
        <Link className={classes} to={disabled ? '' : link}>
            {content}
        </Link>
    ) : externalLink ? (
        <a
            className={classes}
            href={externalLink.url}
            target={externalLink.newTab ? '_blank' : undefined}
            rel="noreferrer"
        >
            {content}
        </a>
    ) : (
        <button className={classes} disabled={disabled} {...htmlProps}>
            {content}
        </button>
    );
};

export default Button;
