import Button from 'Components/button';
import { useAsyncSingleSelectProps, useService, useStores, useFormNavigationBlocker } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PreferredWorkplaceToggle from '../preferred-workplace-toggle/preferred-workplace-toggle';
import TitledWhitecardSection from '../titled-whitecard-section';
import './workplaces-tab.less';
import { ProfileContext, ProfileTabs } from 'Routes/profile';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeService } from 'Services/OfficeService';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { Col, Input, Row } from 'antd';
import { FORM_GUTTER } from 'Models/Constants';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { displayAddress } from 'Utils/TextUtils';
import Divider from 'Components/divider';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';
import { InputSkeletonShape } from 'Components/skeleton/generic-component-shapes/inputs';
import Skeleton from 'Components/skeleton';
import SubmitButton from 'Components/submit-button/submit-button';

const WorkplacesTab: FunctionComponent = observer(() => {
    const [setNavBlocked] = useFormNavigationBlocker();
    const profileContext = useContext(ProfileContext);
    const { userStore, confirmationModalStore, languageStore } = useStores();
    const officeService = useService(OfficeService);
    const { t } = useTranslation();
    const [showDefaultOfficeForm, setShowDefaultOfficeForm] = useState(false);
    const [preferredWorkplace, setPreferredWorkplace] = useState<UserPreferredWorkTypeDto>();

    const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetOfficesRequestDto) =>
                await officeService.getOffices(request),
        },
        entityToSingleSelectCustomOption: (office: OfficeDto) =>
            ({
                value: office.id,
                label: office.name,
                extraData: office.address,
            } as SingleSelectCustomOption),
        defaultSelectedOption: userStore.userInfo?.defaultOffice
            ? {
                  value: userStore.userInfo?.defaultOffice?.id ?? '',
                  label: userStore.userInfo?.defaultOffice?.name ?? '',
                  extraData: userStore.userInfo?.defaultOffice?.address,
              }
            : undefined,
    });

    const { asyncSingleSelectProps: spaceSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetOfficeSpacesRequestDto) =>
                await officeService.getOfficeSpaces(request),
            fetchFunctionExtraParams: {
                officeIds: officeSelectProps.selected ? [officeSelectProps.selected] : undefined,
            },
        },
        entityToSingleSelectCustomOption: (space: OfficeSpaceDto) =>
            ({
                value: space.id,
                label: space.name,
            } as SingleSelectCustomOption),
        defaultSelectedOption: userStore.userInfo?.defaultOfficeSpace
            ? {
                  value: userStore.userInfo?.defaultOfficeSpace?.id ?? '',
                  label: userStore.userInfo?.defaultOfficeSpace?.name ?? '',
              }
            : undefined,
    });

    useEffect(() => {
        profileContext?.formInstance.setFieldsValue({
            preferredWorkType: userStore.userInfo?.preferredWorkType,
            defaultFloor: userStore.userInfo?.defaultFloor,
            defaultDesk: userStore.userInfo?.defaultDesk,
            defaultOfficeId: userStore.userInfo?.defaultOffice
                ? { value: userStore.userInfo?.defaultOffice.id }
                : undefined,
            defaultOfficeSpaceId: userStore.userInfo?.defaultOfficeSpace
                ? { value: userStore.userInfo?.defaultOfficeSpace.id }
                : undefined,
            defaultOfficeEnabled:
                userStore.userInfo?.defaultOffice !== null &&
                userStore.userInfo?.defaultOffice !== undefined,
        });
        setPreferredWorkplace(
            userStore.userInfo?.preferredWorkType
                ? userStore.userInfo.preferredWorkType
                : UserPreferredWorkTypeDto.Office
        );
        setShowDefaultOfficeForm(
            userStore.userInfo?.defaultOffice !== null &&
                userStore.userInfo?.defaultOffice !== undefined
        );
    }, [userStore.userInfo]);

    useEffect(() => {
        //set the address in the field when selecting a new office
        profileContext?.formInstance.setFieldsValue({
            address: displayAddress(officeSelectProps.selectedOption?.extraData),
        });
    }, [officeSelectProps.selectedOption]);

    const handlePreferenceClick = (type: UserPreferredWorkTypeDto) => {
        setPreferredWorkplace(type);
        profileContext?.formInstance.setFieldsValue({
            preferredWorkType: type,
        });
        setNavBlocked(true);
    };

    const onRemoveDefaultOffice = async () => {
        if (
            !(await confirmationModalStore.confirm({
                icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                title: t('Cta.delete_confirm_title', {
                    param1: t('Entity.lowercase_default_office'),
                    param2: 'ce',
                }),
                message: t('Cta.delete_confirm_message', {
                    param1: t('Entity.lowercase_default_office'),
                    param2: 'Le',
                }),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            }))
        )
            return;

        profileContext?.formInstance.setFieldsValue({
            defaultFloor: undefined,
            defaultDesk: undefined,
            defaultOfficeId: undefined,
            defaultOfficeSpaceId: undefined,
            defaultOfficeEnabled: false,
        });

        setNavBlocked(true);
        setShowDefaultOfficeForm(false);
        officeSelectProps.onChange(undefined);
        spaceSelectProps.onChange(undefined);
    };

    return (
        <div className="WorkplacesTab">
            <TitledWhitecardSection
                className="preferred-workplace"
                title={t('preferred_workplace')}
                subtitle={t('Onboarding.preferred_workplace_explanation')}
                padding={20}
            >
                <ValidatedFormItem
                    className="hidden-work-type-field"
                    label=""
                    name={'preferredWorkType'}
                />
                <PreferredWorkplaceToggle
                    type={UserPreferredWorkTypeDto.Office}
                    isSelected={preferredWorkplace === UserPreferredWorkTypeDto.Office}
                    onClick={handlePreferenceClick}
                />
                <PreferredWorkplaceToggle
                    type={UserPreferredWorkTypeDto.Remote}
                    isSelected={preferredWorkplace === UserPreferredWorkTypeDto.Remote}
                    onClick={handlePreferenceClick}
                />
            </TitledWhitecardSection>

            <TitledWhitecardSection
                title={t('default_office')}
                subtitle={t('Onboarding.default_office_explanation')}
                padding={20}
            >
                <ValidatedFormItem className="d-none" label="" name={'defaultOfficeEnabled'} />

                {showDefaultOfficeForm ? (
                    <div className="default-office-form">
                        <Row gutter={FORM_GUTTER}>
                            <Col span={12}>
                                <Skeleton
                                    isLoaded={userStore.userInfo !== null}
                                    placeholder={InputSkeletonShape(
                                        t('name'),
                                        languageStore.currentLanguage
                                    )}
                                    defaultPadding={false}
                                >
                                    <ValidatedFormItem
                                        errors={profileContext?.formErrors}
                                        name="defaultOfficeId"
                                        label={t('name')}
                                        required
                                    >
                                        <AsyncSingleSelect
                                            {...officeSelectProps}
                                            onChange={(option?: SingleSelectCustomOption) => {
                                                officeSelectProps.onChange(option);
                                                spaceSelectProps.resetSearchResults();
                                                spaceSelectProps.onChange(undefined);
                                            }}
                                            placeholder={t('choose_an_office')}
                                        />
                                    </ValidatedFormItem>
                                </Skeleton>
                            </Col>

                            <Col span={12}>
                                <Skeleton
                                    isLoaded={userStore.userInfo !== null}
                                    placeholder={InputSkeletonShape(
                                        t('building_address'),
                                        languageStore.currentLanguage
                                    )}
                                    defaultPadding={false}
                                >
                                    <ValidatedFormItem
                                        errors={profileContext?.formErrors}
                                        name="address"
                                        label={t('building_address')}
                                    >
                                        <Input disabled />
                                    </ValidatedFormItem>
                                </Skeleton>
                            </Col>
                        </Row>

                        {officeSelectProps.selected && (
                            <>
                                <Row gutter={FORM_GUTTER}>
                                    <Col span={24}>
                                        <Skeleton
                                            isLoaded={userStore.userInfo !== null}
                                            placeholder={InputSkeletonShape(
                                                t('Office.space'),
                                                languageStore.currentLanguage
                                            )}
                                            defaultPadding={false}
                                        >
                                            <ValidatedFormItem
                                                errors={profileContext?.formErrors}
                                                name="defaultOfficeSpaceId"
                                                label={t('Office.space')}
                                            >
                                                <AsyncSingleSelect
                                                    {...spaceSelectProps}
                                                    isClearable
                                                />
                                            </ValidatedFormItem>
                                        </Skeleton>
                                    </Col>
                                </Row>

                                <Row gutter={FORM_GUTTER}>
                                    <Col span={12}>
                                        <Skeleton
                                            isLoaded={userStore.userInfo !== null}
                                            placeholder={InputSkeletonShape(
                                                t('floor'),
                                                languageStore.currentLanguage
                                            )}
                                            defaultPadding={false}
                                        >
                                            <ValidatedFormItem
                                                errors={profileContext?.formErrors}
                                                name="defaultFloor"
                                                label={t('floor')}
                                            >
                                                <Input />
                                            </ValidatedFormItem>
                                        </Skeleton>
                                    </Col>
                                    <Col span={12}>
                                        <Skeleton
                                            isLoaded={userStore.userInfo !== null}
                                            placeholder={InputSkeletonShape(
                                                t('Office.desk_number'),
                                                languageStore.currentLanguage
                                            )}
                                            defaultPadding={false}
                                        >
                                            <ValidatedFormItem
                                                errors={profileContext?.formErrors}
                                                name="defaultDesk"
                                                label={t('Office.desk_number')}
                                            >
                                                <Input />
                                            </ValidatedFormItem>
                                        </Skeleton>
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Divider />
                        <div className="remove-default-container">
                            <Button
                                className="remove-default-btn"
                                text={t('remove')}
                                type="light"
                                onClick={() => onRemoveDefaultOffice()}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className="no-default-office cursor-pointer"
                        onClick={() => {
                            profileContext?.formInstance.setFieldsValue({
                                defaultOfficeEnabled: true,
                            });
                            setShowDefaultOfficeForm(true);
                        }}
                    >
                        <Button
                            text={t('set_default_office')}
                            type="link"
                            width="hugged"
                            leftCircleIcon="AddIcon"
                            onClick={() => {
                                profileContext?.formInstance.setFieldsValue({
                                    defaultOfficeEnabled: true,
                                });
                                setShowDefaultOfficeForm(true);
                            }}
                        />
                    </div>
                )}
            </TitledWhitecardSection>
            <div className="d-flex">
                <SubmitButton
                    className="submit-btn"
                    text={t('save')}
                    type="tertiary"
                    onClick={async () => await profileContext?.submit(ProfileTabs.Workplace)}
                />
            </div>
        </div>
    );
});

export default WorkplacesTab;
