import { Gutter } from 'antd/lib/grid/row';
import { SupportedLanguage } from 'Stores/LanguageStore';

export const DROPDOWN_PAGE_SIZE = 25;
export const PAGE_SIZE_INFINITE_LOADING = 30;
export const US_LANGUAGE_CODE = 'EN-US';
export const HOUR_SCALE = 3;
export const MINIMUM_TIME_PERIOD_MINUTES = 15;
export const FORM_GUTTER: [Gutter, Gutter] = [20, 0];
export const DEBOUNCE_DELAY_400 = 400;
export const SEARCH_DEBOUCE_DELAY = 500;
export const QUARTER_HOUR_DECIMAL_VALUES = [25, 50, 75, 5, 0];
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const DAYS_IN_WEEK = 7;
export const SIDENAV_WIDTH_OPEN = 260;
export const SIDENAV_WIDTH_COLLAPSED = 50;
export const SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD = 1560;

// TIME FORMATS.  FORMAT_ is usually defined for displaying moments as text in ui not for manipulation
export const MOMENT_DEFAULT = 'YYYY-MM-DDTHH:mm:SS';
export const TWENTY_FOUR_HOUR_MINUTE = 'HH:mm';
export const TWENTY_FOUR_HOUR_MINUTE_SECOND = 'HH:mm:ss';

export const FORMAT_DAY_WRITTEN = 'dddd';
export const FORMAT_DAY_WRITTEN_ABBR = 'ddd';
export const FORMAT_DAY_COMMA_YEAR = 'DD, YYYY';
export const FORMAT_DAY = 'DD';

export const FORMAT_MONTH = 'MMMM';
export const FORMAT_SHORT_MONTH = 'MMM';
export const FORMAT_MONTH_YEAR = 'MMMM YYYY';
export const FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';

export const FORMAT_TWELVE_HOUR_NO_MINUTE_AM_PM_CAPS = {
    [SupportedLanguage.EN]: 'hh A',
    [SupportedLanguage.FR]: 'H:mm',
};
export const FORMAT_MONTH_WRITTEN_DATE_YEAR = {
    [SupportedLanguage.EN]: 'MMMM DD YYYY',
    [SupportedLanguage.FR]: 'DD MMMM YYYY',
};
export const FORMAT_MONTH_WRITTEN_DATE = {
    [SupportedLanguage.EN]: 'MMMM DD',
    [SupportedLanguage.FR]: 'DD MMMM',
};
export const FORMAT_SHORT_MONTH_DATE = {
    [SupportedLanguage.EN]: 'MMM DD',
    [SupportedLanguage.FR]: 'DD MMM',
};
export const FORMAT_MONTH_DAY = {
    [SupportedLanguage.EN]: 'MMMM DD',
    [SupportedLanguage.FR]: 'DD MMMM',
};
export const FORMAT_MONTH_DATE_COMMA_YEAR = {
    [SupportedLanguage.EN]: 'MMMM DD, YYYY',
    [SupportedLanguage.FR]: 'DD MMMM, YYYY',
};
export const FORMAT_SHORT_MONTH_DATE_TIME = {
    [SupportedLanguage.EN]: 'MMM D, h:mma',
    [SupportedLanguage.FR]: 'D MMM, H:mm',
};

export const FORMAT_SINGLE_DIGIT_TWENTY_FOUR_HOUR_MINUTE = 'H:mm';
export const FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE = {
    [SupportedLanguage.EN]: 'ha',
    [SupportedLanguage.FR]: FORMAT_SINGLE_DIGIT_TWENTY_FOUR_HOUR_MINUTE,
};
export const FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE = {
    [SupportedLanguage.EN]: 'h:mma',
    [SupportedLanguage.FR]: FORMAT_SINGLE_DIGIT_TWENTY_FOUR_HOUR_MINUTE,
};
export const FORMAT_MONTH_DAY_YEAR_DASH_TWELVE_HOUR = {
    [SupportedLanguage.EN]: 'MMMM DD YYYY - h a',
    [SupportedLanguage.FR]: 'DD MMMM YYYY - H:mm',
};

// URLS
export const URL_HTTPS_MANDATORY =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/;
export const SLACK_URL = /^(https|http):\/\/[a-zA-Z0-9]+.slack.com\/team\/[a-zA-Z0-9._%+-]+$/;
export const CALENDLY_URL = /^(https|http):\/\/calendly.com/;
export const PROFILE_URL_REGEX = /^\/profile\/?.*/;
export const DASHBOARD_URL_REGEX = /^\/dashboard\/?.*/;
export const COWORKERS_URL_REGEX = /^\/coworkers\/?.*/;
export const PLAN_SCHEDULE_URL_REGEX = /^\/plan-schedule\/?.*/;
export const TEAMS_URL_REGEX = /^\/teams\/?.*/;
export const ONBOARDING_URL_REGEX = /^\/onboarding\/?.*/;
export const SETTINGS_URL_REGEX = /^\/settings\/?.*/;
export const SETTINGS_USERS_URL_REGEX = /^\/settings\/users\/?.*/;
export const SETTINGS_TEAMS_URL_REGEX = /^\/settings\/teams\/?.*/;
export const SETTINGS_OFFICES_URL_REGEX = /^\/settings\/offices\/?.*/;
export const SETTINGS_POLICIES_URL_REGEX = /^\/settings\/policies\/?.*/;
export const STATISTICS_REAL_ESTATE_URL_REGEX = /^\/real-estate-statistics\/?.*/;
export const EVENTS_URL_REGEX = /^\/events\/?.*/;
export const SETTINGS_HAPPENINGS_URL_REGEX = /^\/settings\/happenings\/?.*/;
export const SETTINGS_SLACK_INTEGRATION_URL_REGEX = /^\/settings\/slack-integration\/?.*/;
export const COWORKER_TIMESHEET_URL_REGEX = /^\/coworkers\/timesheet\/?.*/;
export const CLUBS_URL_REGEX = /^\/clubs\/?.*/;
export const SETTINGS_GLOBAL_LANGUAGE_URL_REGEX = /^\/settings\/global-language\/?.*/;

export const PROFILE_URL = '/profile';
export const DASHBOARD_URL = '/dashboard';
export const COWORKERS_URL = '/coworkers';
export const PLAN_SCHEDULE_URL = '/plan-schedule';
export const OFFICES_URL = '/offices';
export const TEAMS_URL = '/teams';
export const SETTINGS_URL = '/settings';
export const USERS_URL = '/users';
export const POLICIES_URL = '/policies';
export const STATISTICS_REAL_ESTATE_URL = '/real-estate-statistics';
export const ONBOARDING_URL = '/onboarding';
export const EVENTS_URL = '/events';
export const SLACK_INTEGRATION_URL = '/slack-integration';
export const GLOBAL_LANGUAGE_URL = '/global-language';
export const COWORKER_TIMESHEET_URL = '/timesheet';
export const CLUBS_URL = '/clubs';
export const HAPPENINGS_URL = '/happenings';

// BREAKPOINTS
export const MOBILE_MAX = 1100;
export const DESKTOP_MIN = MOBILE_MAX + 1;

// KEY LOCAL STORAGE
export const KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED = 'side-nav-collapsed';

// KEY SESSION STORAGE
export const KEY_SESSION_STORAGE_AZURE_CODE_VERIFIER = 'code_verifier';
export const KEY_SESSION_STORAGE_AZURE_TOKEN_ENDPOINT = 'tokenEndpoint';

export const ABORT_CONTROLLER_ABORTED_ERROR = 'ERR_CANCELED';
//API ErrorCodes
/**OfficeSpaceOccupancyReached*/
export const E004006 = 'E004006';
/**RestrictionsNotMet*/
export const E006002 = 'E006002';
