import React from 'react';
import './space-viewer-skeleton-shapes.less';
import { SpaceViewerCardWidth } from './space-viewer-card';

export const spaceViewerInitialSkeletonShape = (
    <div className="spaceViewerInitialSkeletonShape">
        <div className="rect" />
    </div>
);

export const spaceSkeletonShape = (width: SpaceViewerCardWidth, height: number) => (
    <div className={`spaceSkeletonShape ${width}`} style={{ height: height }}>
        <div className="buttonRect name" />
        <div className="buttonRect capacity" />
    </div>
);

export const deskTooltipSkeletonShape = (
    <div className="deskTooltipSkeletonShape dark-animation">
        <div className="top">
            <div className="roundRect" />
            <div className="dot">•</div>
            <div className="roundRect" />
        </div>
        <div className="bottom">
            <div className="roundRect" />
        </div>
    </div>
);
