import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Button from 'Components/button';
import Icon from 'Components/icons/Icon';
import Modal, { ModalHeaderColor } from 'Components/modal';
import ProfileImagePicker from 'Components/profile-image-picker/profile-image-picker';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingBaseProps } from '..';
import './base-styles.less';
import './profile-picture.less';

export interface OnboardingProfilePictureFormValues {
    image?: UpdateFileRequestDto | null;
    imageUrl?: string;
}

interface OnboardingProfilePictureProps extends OnboardingBaseProps {
    previousFormValue?: OnboardingProfilePictureFormValues;
}

const OnboardingProfilePicture: React.FunctionComponent<OnboardingProfilePictureProps> = observer(
    ({ onNextClick, onPreviousClick, previousFormValue }) => {
        const { t } = useTranslation();
        const { userStore } = useStores();
        const [imageUrl, setImageUrl] = useState<string | undefined>(
            previousFormValue
                ? previousFormValue.imageUrl
                : userStore.userInfo?.imageUrl ?? undefined
        );
        const [base64, setBase64] = useState<string | undefined>(
            previousFormValue?.image?.uploadBase64 ?? undefined
        );
        const [imageDeleted, setImageDeleted] = useState(previousFormValue?.image?.delete ?? false);
        const [changePictureModalVisible, setChangePictureModalVisible] = useState(false);

        const handleNextClick = () => {
            const request = {
                image: base64
                    ? ({
                          uploadBase64: base64,
                      } as UpdateFileRequestDto)
                    : imageDeleted
                    ? ({ delete: true } as UpdateFileRequestDto)
                    : null,
                imageUrl,
            };
            onNextClick(request);
        };

        return (
            <div className="OnboardingProfilePicture OnboardingBase">
                <div className="text-title-1-bold mb-20 centered pt-40">
                    {t('ImagePicker.profile_picture')}
                </div>

                <div
                    className="text-callout text-mid-contrast mb-20 centered subtitle"
                    dangerouslySetInnerHTML={{
                        __html: t('Onboarding.profile_pic_info'),
                    }}
                ></div>

                <div className="edit-image-container">
                    <div className="circle-1">
                        <div className="circle-2">
                            <div
                                className="img-container Shadow-900"
                                style={{
                                    backgroundImage: `url(${imageUrl ?? NoProfilePicture})`,
                                }}
                                onClick={() => setChangePictureModalVisible(true)}
                            >
                                <div className="edit-img-btn">
                                    <Icon iconName="AddIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button text={t('previous')} type="tertiary" onClick={onPreviousClick} />
                        <Button text={t('next')} type="primary" onClick={() => handleNextClick()} />
                    </div>
                </div>

                {changePictureModalVisible && (
                    <Modal
                        className="EditImageModal"
                        visible={changePictureModalVisible}
                        onCancel={() => setChangePictureModalVisible(false)}
                        width={396}
                        headerColor={ModalHeaderColor.white}
                        maxHeight="418"
                    >
                        <div className="image-picker-container">
                            <ProfileImagePicker
                                onFileChange={(imageUrl, base64, deleted) => {
                                    setImageUrl(imageUrl);
                                    setBase64(base64);
                                    setImageDeleted(deleted ?? false);
                                }}
                                imageUrl={imageUrl}
                                closeModal={() => setChangePictureModalVisible(false)}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
);

export default OnboardingProfilePicture;
