import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import CoworkerCard from 'Components/coworker-card';
import { Empty } from 'Components/empty';
import AsyncSingleSelect, {
    SelectFetchFunctionPromise,
} from 'Components/select-custom/single-select/async-single-select';
import Skeleton from 'Components/skeleton';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { COWORKERS_URL } from 'Models/Constants';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { WorkingHours } from 'Utils/CoworkerUtils';
import { ActionPagination, GetDailySchedulesResponseItem } from '..';
import DrawerContent from './drawer-content';
import './page-content.less';

interface PageContentCoworkersProps {
    selectedWorkTypeFilter: any;
    drawerVisible: any;
    searchTerm: any;
    setDrawerAnimationEnded: any;
    coworkers: GetDailySchedulesResponseItem[];
    coworkerCardRef: any;
    getCoworkerWorkingHours: (
        coworker: GetDailySchedulesResponseItem,
        currentUserTimezone: string,
        language: SupportedLanguage
    ) => WorkingHours | undefined;
    selectedCoworker: any;
    resetCoworkerSelection: any;
    setCoworkerWeeklyHourPrefModalOpen: any;
    setNoScroll: any;
    optionsOffices: any;
    onChangeOffice: any;
    selectedOffice: any;
    handlePageCoworkers: any;
    totalAllCoworkersApi: number;
    totalCoworkersAlreadyLoad: number;
    showManagerTag: boolean;
    coworkersIsLoading: boolean;
    setCoworkers: any;
    fetchOffices: (
        page: number,
        searchTerm: string,
        extraParams?: any
    ) => Promise<SelectFetchFunctionPromise>;
    resetOfficesSearch: () => void;
}

const PageContentCoworkers: React.FunctionComponent<PageContentCoworkersProps> = observer(
    ({
        selectedWorkTypeFilter,
        drawerVisible,
        searchTerm,
        setDrawerAnimationEnded,
        coworkers,
        getCoworkerWorkingHours,
        selectedCoworker,
        resetCoworkerSelection,
        setCoworkerWeeklyHourPrefModalOpen,
        setNoScroll,
        optionsOffices,
        onChangeOffice,
        selectedOffice,
        handlePageCoworkers,
        coworkerCardRef,
        showManagerTag,
        totalCoworkersAlreadyLoad,
        totalAllCoworkersApi,
        coworkersIsLoading,
        setCoworkers,
        fetchOffices,
        resetOfficesSearch,
    }) => {
        const { t } = useTranslation();
        const { userStore, languageStore } = useStores();
        const history = useHistory();
        const observer: any = useRef();

        const lastItemInList = useCallback(
            (node) => {
                if (observer.current) observer.current.disconnect();
                observer.current = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting) {
                        handlePageCoworkers(ActionPagination.next);
                        observer.current.disconnect();
                    }
                });
                if (node) observer.current.observe(node);
            },
            [observer]
        );

        const IsManagerUser = (ids: string[]) => {
            if (!ids || ids.length <= 0) return false;

            const idMyTeam = userStore.userInfo?.team?.id;
            if (!idMyTeam) return false;

            const isManagerOfMyTeam = ids.includes(idMyTeam);
            return isManagerOfMyTeam ? true : false;
        };

        const SkeletonCards = () => {
            if (totalCoworkersAlreadyLoad >= totalAllCoworkersApi) return [];
            const DOM: any = [];
            for (let i = 0; i < 10; i++) DOM.push(<CoworkerCard isSkeleton />);
            return DOM;
        };

        const onChangeFavorites = (
            state: 'add' | 'remove',
            coworkerId: string,
            relationId: string
        ) => {
            setCoworkers((prev) => {
                return prev.map((el) => {
                    if (el.user.id === coworkerId) {
                        return {
                            ...el,
                            user: {
                                ...el.user,
                                favoriteInfo: {
                                    ...el.user.favoriteInfo,
                                    id: state === 'add' ? relationId : '',
                                },
                            },
                        };
                    }

                    return el;
                });
            });
        };

        const oneCoworkerCard = (coworker: GetDailySchedulesResponseItem, isLast: boolean) => {
            return (
                <div
                    key={coworker.user?.id}
                    ref={(element) => {
                        if (coworker.user?.id) coworkerCardRef.current[coworker.user?.id] = element;
                    }}
                >
                    <div ref={isLast ? lastItemInList : undefined}>
                        <CoworkerCard
                            isManagerUser={
                                showManagerTag && IsManagerUser(coworker.user.managedTeamIds ?? [])
                            }
                            name={
                                coworker.user.firstName && coworker.user.lastName
                                    ? `${coworker.user.firstName} ${coworker.user.lastName}`
                                    : ''
                            }
                            relationId={coworker.user.favoriteInfo?.id || ''}
                            coworkerId={coworker.user.id || ''}
                            jobTitle={coworker.user.jobTitle}
                            imageUrl={coworker.user.imageUrl}
                            workingHours={getCoworkerWorkingHours(
                                coworker,
                                userStore.userInfo?.timeZone ?? '',
                                languageStore.currentLanguage
                            )}
                            selected={selectedCoworker?.user?.id === coworker.user.id}
                            day={coworker.day}
                            onClick={() => {
                                setNoScroll(true);

                                history.push({
                                    pathname: COWORKERS_URL + '/' + coworker.user.id,
                                });
                            }}
                            showFavToggle
                            onChangeFavorites={onChangeFavorites}
                            badges={coworker.user.badges ? coworker.user.badges : undefined}
                        />
                    </div>
                </div>
            );
        };

        // CONSTANTS
        const NUMBER_SKELETON_LAZY_LOADING = 10;

        return (
            <div className="PageContentCoworkers">
                <div
                    className={`page-content-container drawerVisible-${drawerVisible} office-select-visible-${
                        selectedWorkTypeFilter === WorkTypeDto.Office
                    }`}
                    onTransitionEnd={() => {
                        setDrawerAnimationEnded(true);
                    }}
                >
                    <div className="coworker-cards-container">
                        <Skeleton
                            isLoaded={coworkersIsLoading === true}
                            placeholder={
                                <div className="coworker-cards-container-content">
                                    {selectedWorkTypeFilter === WorkTypeDto.Office && (
                                        <div
                                            className="rect office-select-wrapper"
                                            style={{
                                                height: 52,
                                                width: 300,
                                            }}
                                        />
                                    )}
                                    {[...Array(NUMBER_SKELETON_LAZY_LOADING)].map((el, key) => (
                                        <CoworkerCard isSkeleton key={key} />
                                    ))}
                                </div>
                            }
                            defaultPadding={false}
                        >
                            <div className="coworker-cards-container-content">
                                {selectedWorkTypeFilter === WorkTypeDto.Office && (
                                    <div className="office-select-wrapper">
                                        <AsyncSingleSelect
                                            fetchFunction={fetchOffices}
                                            resetSearchResults={resetOfficesSearch}
                                            options={optionsOffices}
                                            selected={selectedOffice}
                                            onChange={onChangeOffice}
                                            hideSelectedOptions={false}
                                            placeholder={t(
                                                'SelectCustom.default_select_placeholder'
                                            )}
                                        />
                                    </div>
                                )}

                                {coworkers.length === 0 && !searchTerm ? (
                                    <Empty stringKey="Table.table_empty" />
                                ) : (
                                    <>
                                        {coworkers.map(
                                            (coworker: GetDailySchedulesResponseItem, index) => {
                                                const ArrayDomElement: any = [];

                                                ArrayDomElement.push(
                                                    oneCoworkerCard(
                                                        coworker,
                                                        index === coworkers.length - 1
                                                    )
                                                );
                                                return ArrayDomElement;
                                            }
                                        )}

                                        <SkeletonCards />
                                    </>
                                )}
                            </div>
                        </Skeleton>
                    </div>

                    <DrawerContent
                        setDrawerVisible={resetCoworkerSelection}
                        selectedCoworker={selectedCoworker}
                        onSeeWeekScheduleClick={() => setCoworkerWeeklyHourPrefModalOpen(true)}
                        onChangeFavorites={onChangeFavorites}
                    />
                </div>
            </div>
        );
    }
);

export default PageContentCoworkers;
