import DropdownModal from 'Components/dropdown-modal';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OfficeOccupancyCardDate from './components/office-occupancy-card-date';
import CtaRow from './components/cta-row';
import HappeningIndicator from './components/happening-indicator';
import OfficeAndTeam from './components/office-and-team';
import './index.less';
import OfficeDropdownModalContent from './modals/office-dropdown-modal-content';
import WorktypeDropdownModalContent from './modals/worktype-dropdown-modal-content';
import Skeleton from 'Components/skeleton';
import { dashboardNextDayContentTop } from 'Routes/dashboard/skeleton-shapes';
import { truncate } from 'Utils/TextUtils';

export enum OfficeOccupancyCardWorkType {
    Hybride = 'Hybride',
    Office = 'Office',
    Remote = 'Remote',
    Away = 'Away',
    Unavailable = 'Unavailable',
}

export interface WorkingCoworkersOfficeData {
    officeName: string;
    totalCoworkers: number;
    myTeam?: { atOffice: number; total: number };
}

export interface WorkingCoworkersTeamData {
    imgUrls: string[];
    atOffice: number;
    favCoworkers: number;
    onOpenCoworkersAtOfficeModal: () => void;
}

interface WorkTypeData {
    selected: OfficeOccupancyCardWorkType;
    onChangeAllDayWorkType: (worktype: OfficeOccupancyCardWorkType) => void;
    buttonLink: string;
}

interface OfficeAllDayChangerData {
    onChangeAllDayOffices: (
        office?: { name?: string; id?: string },
        officeSpace?: { name?: string; id?: string; occupancy?: number; capacity?: number }
    ) => void;
    makeHybridButtonLink: string;
    officeButtonData: {
        hasMultipleOffices: boolean;
        /**A flag to know if to consider selecteOffice.spaceName or displaying another label */
        hasMultipleOfficeSpaces: boolean;
        selectedOffice?: {
            isDefaultOffice?: boolean;
            capacity?: { value: number; total: number };
            officeName?: string;
            officeId?: string;
            spaceId?: string;
            spaceName?: string;
        };
        capacityFetchParams: {
            startTime: string;
            endTime: string;
        };
    };
}

export interface OfficeOccupancyCardData {
    apiDateId: string;
    onOpenWorkingCoworkersModal?: () => void;
    isToday: boolean;
    isSuggestedDay?: boolean;
    date: { day: string; month: string };
    workingFrom?: string;
    worktypeData: WorkTypeData;
    officeAllDayChangerData: OfficeAllDayChangerData;
    workingFromlink: string;
    office: WorkingCoworkersOfficeData;
    team: WorkingCoworkersTeamData;
    dayChangersAreLoading: boolean;
}

interface OfficeOccupancyCardProps {
    className?: string;
    officeOccupancyCardData: OfficeOccupancyCardData;
}

const OfficeOccupancyCard: React.FunctionComponent<OfficeOccupancyCardProps> = ({
    className,
    officeOccupancyCardData,
}) => {
    const [forceCloseDropDownModal, setForceCloseDropDownModal] = useState(false);
    const { t } = useTranslation();
    const hasHappening = officeOccupancyCardData.onOpenWorkingCoworkersModal !== undefined;
    const worktypeIcons = {
        Hybride: 'HouseBuildingIcon',
        Office: 'AppartementIcon',
        Remote: 'SimpleHouseIcon',
        Away: 'SunIcon',
        Unavailable: 'SunIcon',
    };
    const worktypeOptions = [
        {
            worktype: OfficeOccupancyCardWorkType.Office,
            iconName: worktypeIcons[OfficeOccupancyCardWorkType.Office],
        },
        {
            worktype: OfficeOccupancyCardWorkType.Remote,
            iconName: worktypeIcons[OfficeOccupancyCardWorkType.Remote],
        },
        {
            worktype: OfficeOccupancyCardWorkType.Away,
            iconName: worktypeIcons[OfficeOccupancyCardWorkType.Away],
        },
    ];
    const selectedWorktypeOption = {
        worktype: officeOccupancyCardData.worktypeData.selected,
        iconName: worktypeIcons[officeOccupancyCardData.worktypeData.selected],
    };
    const handleWorktypeOnClick = (worktype: OfficeOccupancyCardWorkType) => {
        setForceCloseDropDownModal(true);
        officeOccupancyCardData.worktypeData.onChangeAllDayWorkType(worktype);
    };

    const handleOfficeSubmit = (
        office?: { name?: string; id?: string },
        officeSpace?: { name?: string; id?: string; occupancy?: number; capacity?: number }
    ) => {
        setForceCloseDropDownModal(true);
        officeOccupancyCardData.officeAllDayChangerData.onChangeAllDayOffices(office, officeSpace);
    };

    const parseOfficeChangerLabel = useMemo(
        () =>
            truncate(
                officeOccupancyCardData.officeAllDayChangerData.officeButtonData.hasMultipleOffices
                    ? t('OfficeOccupancyCard.multi_office')
                    : officeOccupancyCardData.officeAllDayChangerData.officeButtonData
                          .selectedOffice?.officeName ?? t('OfficeOccupancyCard.select_an_office'),
                25
            ),
        [
            officeOccupancyCardData.officeAllDayChangerData.officeButtonData.hasMultipleOffices,
            officeOccupancyCardData.officeAllDayChangerData.officeButtonData.selectedOffice
                ?.officeName,
        ]
    );

    const parseOfficeChangerSubLabel = useMemo(() => {
        const multipleSpaces =
            officeOccupancyCardData.officeAllDayChangerData.officeButtonData.hasMultipleOffices ||
            officeOccupancyCardData.officeAllDayChangerData.officeButtonData
                .hasMultipleOfficeSpaces;
        if (multipleSpaces) return t('multiple_spaces');

        const noOfficeSelected =
            officeOccupancyCardData.officeAllDayChangerData.officeButtonData.selectedOffice
                ?.officeName === undefined;
        if (noOfficeSelected) return '';

        return truncate(
            officeOccupancyCardData.officeAllDayChangerData.officeButtonData.selectedOffice
                ?.spaceName ?? t('no_space_chosen'),
            30
        );
    }, [
        officeOccupancyCardData.officeAllDayChangerData.officeButtonData.hasMultipleOffices,
        officeOccupancyCardData.officeAllDayChangerData.officeButtonData.hasMultipleOfficeSpaces,
        officeOccupancyCardData.officeAllDayChangerData.officeButtonData.selectedOffice,
        officeOccupancyCardData.officeAllDayChangerData.officeButtonData.selectedOffice?.spaceName,
    ]);

    return (
        <div
            className={`OfficeOccupancyCard isToday-${officeOccupancyCardData.isToday} ${
                className ?? ''
            }`}
        >
            <div className="card">
                <HappeningIndicator
                    visible={hasHappening}
                    onClick={() =>
                        officeOccupancyCardData.onOpenWorkingCoworkersModal &&
                        officeOccupancyCardData.onOpenWorkingCoworkersModal()
                    }
                />

                <div className="content top">
                    <OfficeOccupancyCardDate
                        isToday={officeOccupancyCardData.isToday}
                        day={officeOccupancyCardData.date.day}
                        date={officeOccupancyCardData.date.month}
                    />

                    <Skeleton
                        isLoaded={!officeOccupancyCardData.dayChangersAreLoading}
                        placeholder={dashboardNextDayContentTop}
                        defaultPadding={false}
                    >
                        <>
                            <DropdownModal
                                parentElement={
                                    <CtaRow
                                        workType={{
                                            iconName: selectedWorktypeOption.iconName,
                                            label: t(
                                                'WorkTypeDto.WorkTypeDto_' +
                                                    selectedWorktypeOption.worktype
                                            ),
                                        }}
                                        disabled={
                                            selectedWorktypeOption.worktype ===
                                                OfficeOccupancyCardWorkType.Unavailable ||
                                            selectedWorktypeOption.worktype ===
                                                OfficeOccupancyCardWorkType.Away
                                        }
                                    />
                                }
                                forceClose={{
                                    close: forceCloseDropDownModal,
                                    resetForceClose: () => setForceCloseDropDownModal(false),
                                }}
                                disabled={
                                    selectedWorktypeOption.worktype ===
                                        OfficeOccupancyCardWorkType.Unavailable ||
                                    selectedWorktypeOption.worktype ===
                                        OfficeOccupancyCardWorkType.Away
                                }
                            >
                                <WorktypeDropdownModalContent
                                    options={worktypeOptions}
                                    buttonLink={officeOccupancyCardData.worktypeData.buttonLink}
                                    onClick={handleWorktypeOnClick}
                                />
                            </DropdownModal>

                            {(officeOccupancyCardData.worktypeData.selected ===
                                OfficeOccupancyCardWorkType.Office ||
                                officeOccupancyCardData.worktypeData.selected ===
                                    OfficeOccupancyCardWorkType.Hybride) && (
                                <DropdownModal
                                    parentElement={
                                        <CtaRow
                                            office={{
                                                isDefaultOffice:
                                                    officeOccupancyCardData.officeAllDayChangerData
                                                        .officeButtonData.selectedOffice
                                                        ?.isDefaultOffice,
                                                iconName: officeOccupancyCardData
                                                    .officeAllDayChangerData.officeButtonData
                                                    .selectedOffice?.isDefaultOffice
                                                    ? 'MainWorkplaceIcon'
                                                    : 'MapPinWithCircleIcon',
                                                label: parseOfficeChangerLabel,
                                                subLabel: parseOfficeChangerSubLabel,
                                                capacity:
                                                    officeOccupancyCardData.officeAllDayChangerData
                                                        .officeButtonData.hasMultipleOffices ||
                                                    officeOccupancyCardData.officeAllDayChangerData
                                                        .officeButtonData.selectedOffice
                                                        ?.officeName === undefined
                                                        ? undefined
                                                        : officeOccupancyCardData
                                                              .officeAllDayChangerData
                                                              .officeButtonData.selectedOffice
                                                              ?.capacity
                                                        ? `${officeOccupancyCardData.officeAllDayChangerData.officeButtonData.selectedOffice?.capacity.value}/${officeOccupancyCardData.officeAllDayChangerData.officeButtonData.selectedOffice?.capacity.total}`
                                                        : undefined,
                                            }}
                                        />
                                    }
                                    forceClose={{
                                        close: forceCloseDropDownModal,
                                        resetForceClose: () => setForceCloseDropDownModal(false),
                                    }}
                                    placement="top"
                                >
                                    <OfficeDropdownModalContent
                                        makeHybridButton={
                                            officeOccupancyCardData.officeAllDayChangerData
                                                .makeHybridButtonLink
                                        }
                                        onSubmit={handleOfficeSubmit}
                                        minWidth={316}
                                        capacityFetchParams={
                                            officeOccupancyCardData.officeAllDayChangerData
                                                .officeButtonData.capacityFetchParams
                                        }
                                        hasSpaceViewer
                                        defaultSelectedOffice={
                                            officeOccupancyCardData.officeAllDayChangerData
                                                .officeButtonData.selectedOffice?.officeId
                                                ? {
                                                      label:
                                                          officeOccupancyCardData
                                                              .officeAllDayChangerData
                                                              .officeButtonData.selectedOffice
                                                              ?.officeName ?? '',
                                                      value: officeOccupancyCardData
                                                          .officeAllDayChangerData.officeButtonData
                                                          .selectedOffice?.officeId,
                                                  }
                                                : undefined
                                        }
                                        defaultSelectedSpace={
                                            officeOccupancyCardData.officeAllDayChangerData
                                                .officeButtonData.selectedOffice?.spaceId
                                                ? {
                                                      label:
                                                          officeOccupancyCardData
                                                              .officeAllDayChangerData
                                                              .officeButtonData.selectedOffice
                                                              ?.spaceName ?? '',
                                                      value: officeOccupancyCardData
                                                          .officeAllDayChangerData.officeButtonData
                                                          .selectedOffice?.spaceId,
                                                  }
                                                : undefined
                                        }
                                    />
                                </DropdownModal>
                            )}

                            {officeOccupancyCardData.worktypeData.selected !==
                                OfficeOccupancyCardWorkType.Away &&
                                officeOccupancyCardData.worktypeData.selected !==
                                    OfficeOccupancyCardWorkType.Unavailable &&
                                officeOccupancyCardData.workingFrom && (
                                    <CtaRow
                                        workingFrom={{
                                            value: officeOccupancyCardData.workingFrom,
                                            url: officeOccupancyCardData.workingFromlink,
                                        }}
                                    />
                                )}
                        </>
                    </Skeleton>
                </div>

                <div className="content">
                    <OfficeAndTeam
                        dataOffice={officeOccupancyCardData.office}
                        dataTeam={officeOccupancyCardData.team}
                    />
                </div>

                {officeOccupancyCardData.isSuggestedDay && (
                    <div className="suggested-day-container">
                        <div className="text">{t('Policy.suggested_office_day')}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OfficeOccupancyCard;
