import { observable, action } from 'mobx';
import { SlackParameters } from 'Services/SlackService';

class SlackParametersStore {
    @observable slackParameters?: SlackParameters | null = null;

    constructor() {
        if (sessionStorage.slackParameters) {
            this.slackParameters = JSON.parse(sessionStorage.slackParameters);
        }
    }

    @action
    setParameters(parameters: SlackParameters): void {
        this.slackParameters = parameters;
        sessionStorage.slackParameters = JSON.stringify(parameters);
    }

    @action
    clearParameters(): void {
        this.slackParameters = null;
        sessionStorage.removeItem('slackParameters');
    }
}

export default SlackParametersStore;
