import { Table, TablePaginationConfig } from 'antd';
import { ColumnType } from 'antd/es/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { GetOfficesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficesSortColumnDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import Content from 'Components/Content';
import CreateOfficeModal from 'Components/create-office-modal';
import Skeleton from 'Components/skeleton';
import TableFilters from 'Components/table-filters/table-filters';
import TablePageSecondHeader from 'Components/table-page-second-header';
import { useApiRequestHistory, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    DEBOUNCE_DELAY_400,
    OFFICES_URL,
    SETTINGS_OFFICES_URL_REGEX,
    SETTINGS_URL,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { initialPaginationState } from 'Models/InitialPaginationState';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OfficeService } from 'Services/OfficeService';
import { FilterStore } from 'Stores';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import { getCountryNameFromCountryCode } from 'Utils/TextUtils';
import { tableSkeletonShape } from '../skeleton-shapes';

const getAdvancedFilters = (): AdvancedFilter[] => [];

const Offices: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const officeService = useService(OfficeService);
    const { toastStore } = useStores();
    const [createOfficeModalVisible, setCreateOfficeModalVisible] = useState(false);
    const advancedFilters = getAdvancedFilters();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(false);
    const [officesList, setOfficesList] = useState<OfficeDto[]>();
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const history = useHistory();
    const paginationRef = useRef(initialPaginationState);
    const [isLoading, setIsLoading] = useState({
        offices: false,
    });
    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex: SETTINGS_OFFICES_URL_REGEX,
            requestType: RequestType.Offices,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            const filterStore = filterStoreRef.current;
            const request: GetOfficesRequestDto = history.request;

            filterStore.searchTerm = request.searchTerm ?? '';

            paginationRef.current = {
                ...paginationRef.current,
                current: request.page ? request.page + 1 : 1,
                pageSize: request.pageSize ?? initialPaginationState.pageSize,
            };
        },
    });

    const renderAddress = (office: OfficeDto): string => {
        let address = '';

        if (office.address) {
            address = office.address?.addressLine1 ? office.address?.addressLine1 : address;
            address = office.address?.addressLine2
                ? address + ', ' + office.address?.addressLine2
                : address;
            address = office.address?.city ? address + ' ' + office.address?.city : address;
            address = office.address?.zipCode ? address + ', ' + office.address?.zipCode : address;
        }

        return address;
    };

    const columns: ColumnType<OfficeDto>[] = [
        {
            title: t('name'),
            render: (office: OfficeDto) => office.name,
            key: GetOfficesSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('address'),
            render: renderAddress,
            key: GetOfficesSortColumnDto.Address,
            sorter: true,
        },
        {
            title: t('country'),
            render: (office: OfficeDto) =>
                getCountryNameFromCountryCode(office.address?.countryCode),
        },
    ];

    const onRowClick = (office: OfficeDto) => {
        history.push(SETTINGS_URL + OFFICES_URL + `/${office.id}`);
    };

    const onPressCreateOffice = () => setCreateOfficeModalVisible(true);

    const fetchOffices = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetOfficesSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetOfficesRequestDto = setRequestHistory({
                    request: {
                        pageSize: params.pagination.pageSize ?? 0,
                        page: (params.pagination.current ?? 1) - 1,
                        searchTerm: params.searchTerm,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                    },
                    requestType: RequestType.Offices,
                });

                const [items, totalItemsCount] = await officeService.getOffices(request);

                setIsLoading((prev) => {
                    return { ...prev, offices: true };
                });
                setOfficesList(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [officeService]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        const { searchTerm } = filterStoreRef.current;

        await fetchOffices({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string }) => {
            fetchOffices({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    return (
        <div className="Offices">
            <TablePageSecondHeader
                title={t('offices')}
                createBtnString={t('create_entity', {
                    param1: t('office'),
                    param2: 'un',
                    param3: t('Entity.lowercase_office'),
                })}
                onCreateBtnClick={onPressCreateOffice}
                isLoaded={isLoading.offices}
            />

            <Content>
                <TableFilters
                    includeSearch
                    filterStore={filterStoreRef.current}
                    advancedFiltersDefaultValue={() => getAdvancedFilters()}
                    isLoaded={isLoading.offices}
                />

                <Skeleton isLoaded={isLoading.offices} placeholder={tableSkeletonShape}>
                    <Table
                        className="table-action-rows table-striped-rows"
                        dataSource={officesList}
                        columns={columns}
                        loading={loading}
                        rowKey={(office): string => office.id ?? ''}
                        pagination={pagination}
                        onChange={handleTableChange}
                        onRow={(row: OfficeDto) => ({
                            onClick: (): void => {
                                onRowClick(row);
                            },
                        })}
                        //rows are all equal, no resizing
                        tableLayout={'fixed'}
                    />
                </Skeleton>
            </Content>

            {createOfficeModalVisible && (
                <CreateOfficeModal
                    visible={createOfficeModalVisible}
                    onComplete={(success, addSpaces, id) => {
                        setCreateOfficeModalVisible(false);
                        if (success && id) {
                            history.push(
                                SETTINGS_URL +
                                    OFFICES_URL +
                                    `/${id}${addSpaces ? '?addSpace=true' : ''}`
                            );
                        }
                    }}
                />
            )}
        </div>
    );
});

export default Offices;
