import React, { ReactElement } from 'react';
import './index.less';

export const coworkersDrawerContentSkeletonShape = (
    <div className="coworkersDrawerContentSkeletonShape">
        <div className="one-row container-top">
            <div className="circle" />
            <div className="one-column container-right">
                <div className="rect-medium" />
                <div className="rect-large" />

                <div className="separator" />

                <div className="rect-medium" />
                <div className="rect-large" />
            </div>
        </div>

        <div className="separator" />

        <div className="one-column container-teams">
            <div className="rect-180" />
            <div className="rect-350" />
        </div>

        <div className="separator" />

        <div className="one-column container-teams">
            <div className="rect-180" />
            <div className="rect-350" />
        </div>

        <div className="separator" />

        <div className="one-row">
            <div className="one-column container-left">
                <div className="rect" />
                <div className="rect-bottom" />
            </div>
            <div className="rect-btn-right" />
        </div>

        <div className="rect-big" />
        <div className="rect-big-but-small-height" />

        <div className="separator" />

        <div className="one-column container-bottom">
            <div className="rect-top" />

            <div className="block">
                <div className="rect-top" />
                <div className="rect-bottom" />
            </div>

            <div className="block">
                <div className="rect-top" />
                <div className="rect-bottom" />
            </div>

            <div className="block">
                <div className="rect-top" />
                <div className="rect-bottom" />
            </div>
        </div>
    </div>
);

export const coworkersTimeSheetTabSkeletonShapes = (rows?: number): ReactElement => (
    <div className="coworkersTimeSheetTabSkeletonShapes">
        {[...Array(rows ?? 10)].map((_, key) => {
            return (
                <div className="coworkersTimeSheetTabOneRowSkeletonShapes" key={key}>
                    <div className="rect user" />
                    <div className="rect sheet" />
                </div>
            );
        })}
    </div>
);
