import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useFetchQueue, useService } from 'Hooks';
import { observer } from 'mobx-react';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import {
    FloorPlanFloorDtoWithAvailability,
    FloorPlanManager,
    FloorPlanOfficeSpaceAreaDtoWithAvailability,
    FloorPlanType,
} from 'Components/floor-plan/FloorPlanManager';
import { FloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanFloorDto';
import { MagnifyingLessIcon, MagnifyingPlusIcon, SpaceViewerIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import './floor-plan-viewer.less';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { OfficeService } from 'Services/OfficeService';
import { GetOfficeSpaceOccupancyRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestDto';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { SpaceViewerScheduleType } from '../space-viewer-modal-content';
import SpaceViewerCard from '../space-viewer-card';
import SpaceViewerTag, { SpaceViewerTagType } from '../space-viewer-tag';
import { useTranslation } from 'react-i18next';
import Skeleton from 'Components/skeleton';
import { floorPlanShape } from 'Routes/settings/offices/id/skeleton-shapes';
import { useFetch } from 'Hooks/use-fetch';

interface FloorPlanViewerProps {
    floorPlanFloors: FloorPlanFloorDto[];
    timeRange: {
        startTime: string;
        endTime: string;
    };
    selectedSpace?: SingleSelectCustomOption;
    setSelectedSpace: (space: SingleSelectCustomOption | undefined) => void;
    scheduleType: SpaceViewerScheduleType;
}

const FloorPlanViewer: FunctionComponent<FloorPlanViewerProps> = observer(
    ({ floorPlanFloors, timeRange, selectedSpace, setSelectedSpace, scheduleType }) => {
        const { t } = useTranslation();
        const officeService = useService(OfficeService);
        const [floorPlanManager, setFloorPlanManager] = useState<FloorPlanManager>();
        const [currentFloorWithAvailability, setCurrentFloorWithAvailability] =
            useState<FloorPlanFloorDtoWithAvailability>();
        const [selectedFloorId, setSelectedFloorId] = useState<string | undefined>(
            floorPlanFloors[0].id
        );
        const [currentFloorPlanPolygonSpaceId, setCurrentFloorPlanPolygonSpaceId] =
            useState<string>();

        const [addToFetchQueue, emptyQueue] = useFetchQueue();
        const { apiRequest, loadingStateKeys } = useFetch();

        useEffect(() => {
            //clear the fetch queue on unmount
            return () => {
                emptyQueue();
            };
        }, []);

        //Create floor manager object
        useEffect(() => {
            setFloorPlanManager(
                new FloorPlanManager(
                    (officeSpace: FloorPlanOfficeSpaceAreaDtoWithAvailability) => {
                        //setting undefined first because SpaceViewerCard only fetches when in view so we need to render empty first to get the card in view again
                        setCurrentFloorPlanPolygonSpaceId(undefined);
                        setCurrentFloorPlanPolygonSpaceId(officeSpace.officeSpaceId);
                        if (officeSpace.isAvailableForUser) {
                            setSelectedSpace({
                                value: officeSpace.officeSpaceId ?? '',
                                label: officeSpace.officeSpaceName ?? '',
                            });
                        } else setSelectedSpace(undefined);
                    },
                    () => {
                        setCurrentFloorPlanPolygonSpaceId(undefined);
                        setSelectedSpace(undefined);
                    },
                    FloorPlanType.visualiser,
                    true
                )
            );
        }, []);

        //Call the init on floor manager
        useEffect(() => {
            if (floorPlanManager) floorPlanManager.init();
        }, [floorPlanManager]);

        useEffect(() => {
            //on floor select fetch the current space capacities
            if (floorPlanFloors.length > 0 && selectedFloorId && floorPlanManager) {
                const floor = floorPlanFloors.find((x) => x.id === selectedFloorId);
                fetchSpaceOccupancyForCurrentFloor(
                    floor!.officeSpaceAreas
                        ?.filter((x) => x?.officeSpaceId !== undefined)
                        .map((x) => x!.officeSpaceId!) ?? [],
                    floor!
                );
            }
        }, [selectedFloorId, floorPlanFloors, floorPlanManager]);

        useEffect(() => {
            //when getting into view and space viewer could already have a space selected, find the approriate floor on floor plan and set it as the current floor.
            if (selectedSpace && floorPlanFloors) {
                const floorPlanFloor = floorPlanFloors.find((x) =>
                    x.officeSpaceAreas?.some((x) => x?.officeSpaceId == selectedSpace.value)
                );
                if (floorPlanFloor?.id) {
                    //the space is on a floor plan
                    setSelectedFloorId(floorPlanFloor.id);
                    setCurrentFloorPlanPolygonSpaceId(selectedSpace.value);
                    //--must be done in seperate useeffect because the floorplanmanager needs to init the floor first. Below UseEffect--
                    //--floorPlanManager.onSideControlSpaceClicked(selectedSpace?.value ?? '');--
                }
            }
        }, [selectedSpace, floorPlanFloors]);

        useEffect(() => {
            //once the floormanager has init the floor, if a pre-selected space should be applied we can select the poly
            if ((floorPlanManager?.floorViewModelOfficeSpaceAreas.length ?? 0) > 0) {
                floorPlanManager!.onSideControlSpaceClicked(selectedSpace?.value ?? '');
            }
        }, [floorPlanManager?.floorViewModelOfficeSpaceAreas]);

        const fetchSpaceOccupancyForCurrentFloor = async (
            spaceIds: string[],
            floor: FloorPlanFloorDto
        ) => {
            const periods = spaceIds.map((x) => ({
                officeSpaceId: x,
                startTime: timeRange.startTime,
                endTime: timeRange.endTime,
            }));
            const requestParams: GetOfficeSpaceOccupancyRequestDto = {
                periods: periods,
            };

            const spaceOccupancies: GetOfficeSpaceOccupancyResponsePeriodDto[] = await apiRequest({
                requestFunction: (param) =>
                    scheduleType == SpaceViewerScheduleType.schedule
                        ? officeService.getOfficeSpaceOccupancy(param)
                        : officeService.getOfficeSpaceDefaultOccupancy(param),
                requestParameters: requestParams,
                loadingStateKey: 'floorplan',
            });

            const floorWithAvailability: FloorPlanFloorDtoWithAvailability = {
                ...floor,
                officeSpaceAreasWithAvailability: floor.officeSpaceAreas?.map((officeSpace) => ({
                    ...officeSpace,
                    isAvailableForUser: spaceOccupancies.find(
                        (x) => x.officeSpace?.id === officeSpace?.officeSpaceId
                    )?.isAvailableForUser,
                })),
            };

            setCurrentFloorWithAvailability(floorWithAvailability);
            initFloor(floorWithAvailability);
        };

        const initFloor = (floor: FloorPlanFloorDtoWithAvailability) => {
            floorPlanManager?.initFloor(floor ?? null);
        };

        const selectedPolygonOfficeSpaceInfo = useMemo(():
            | FloorPlanOfficeSpaceAreaDtoWithAvailability
            | undefined => {
            const space = currentFloorWithAvailability?.officeSpaceAreasWithAvailability?.find(
                (x) => x.officeSpaceId === currentFloorPlanPolygonSpaceId
            );
            return space;
        }, [currentFloorWithAvailability, currentFloorPlanPolygonSpaceId]);

        return (
            <div className="FloorPlanViewer">
                <div className="left-container">
                    <Skeleton
                        isLoaded={!loadingStateKeys.has('floorplan')}
                        defaultPadding={false}
                        placeholder={floorPlanShape}
                    >
                        <StaticSingleSelect
                            placeholder={''}
                            selected={selectedFloorId}
                            options={floorPlanFloors.map((x) => ({
                                label: x.name ?? '',
                                value: x.id ?? '',
                            }))}
                            onChange={(option?: SingleSelectCustomOption) => {
                                setSelectedFloorId(option?.value);
                                setSelectedSpace(undefined);
                                setCurrentFloorPlanPolygonSpaceId(undefined);
                            }}
                        />
                    </Skeleton>

                    <div
                        //this must always rendered for floorplanmanager. just play with height
                        id="svg-container-div"
                        style={{
                            height: loadingStateKeys.has('floorplan') ? 0 : 400,
                        }}
                    />

                    {!loadingStateKeys.has('floorplan') && (
                        <div className="zoom-btn-container">
                            <div
                                className="zoom-btn"
                                onClick={() => floorPlanManager?.panZoom?.zoomOut()}
                            >
                                <MagnifyingLessIcon
                                    width={24}
                                    height={24}
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>
                            <div
                                className="zoom-btn"
                                onClick={() => floorPlanManager?.panZoom?.zoomIn()}
                            >
                                <MagnifyingPlusIcon
                                    width={24}
                                    height={24}
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="right-container">
                    <div
                        className="dont-assign-btn-container cursor-pointer"
                        onClick={() => {
                            //when clicking dont assigns space, we need to unselected the polygon. This function with current selection triggers the same behavior
                            floorPlanManager?.onSideControlSpaceClicked(selectedSpace?.value ?? '');
                            setSelectedSpace(undefined);
                        }}
                    >
                        <div
                            className={`dont-assign-btn ${
                                selectedSpace?.value === undefined ? 'selected' : ''
                            }`}
                        >
                            <span className="text-body">{t('SpaceViewer.dont_assign_space')}</span>
                            <div className="ml-auto">
                                {selectedSpace?.value === undefined && (
                                    <SpaceViewerTag type={SpaceViewerTagType.selected} />
                                )}

                                <SpaceViewerIcon
                                    fill={
                                        selectedSpace?.value === undefined
                                            ? theme['layout-mid-main']
                                            : theme['text-disabled']
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {selectedPolygonOfficeSpaceInfo && (
                        <SpaceViewerCard
                            space={{
                                id: selectedPolygonOfficeSpaceInfo.officeSpaceId,
                                capacity: selectedPolygonOfficeSpaceInfo.capacity,
                                name: selectedPolygonOfficeSpaceInfo.officeSpaceName,
                            }}
                            isSelected={
                                (selectedPolygonOfficeSpaceInfo.isAvailableForUser ?? false) &&
                                selectedSpace?.value == selectedPolygonOfficeSpaceInfo.officeSpaceId
                            }
                            onSelectSpace={(space: SingleSelectCustomOption) =>
                                setSelectedSpace(space)
                            }
                            addToFetchQueue={addToFetchQueue}
                            timeRange={timeRange}
                            scheduleType={scheduleType}
                            floorPlanView
                        />
                    )}
                </div>
            </div>
        );
    }
);

export default FloorPlanViewer;
