import React from 'react';
import { DayReplicatorStateEnum } from './day-replicator';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from 'Components/tooltip-wrapper';
import { theme } from 'Style/theme';

interface FactoryDayReplicatorProps {
    DayReplicatorComponent: (i: number, isDisabled: boolean) => any;
    dayStates: DayReplicatorStateEnum[];
    i: number;
}

const FactoryDayReplicator: React.FunctionComponent<FactoryDayReplicatorProps> = ({
    DayReplicatorComponent,
    dayStates,
    i,
}) => {
    const { t } = useTranslation();

    return dayStates[i] === DayReplicatorStateEnum.Disabled ? (
        <TooltipWrapper
            key={i + 'tooltip'}
            title={t('restricted_period')}
            backgroundColor={theme['layout-high-contrast']}
            textColor={theme['text-low-contrast']}
            placement="top"
        >
            {DayReplicatorComponent(i, true)}
        </TooltipWrapper>
    ) : dayStates[i] === DayReplicatorStateEnum.Disabled_full_capacity ? (
        <TooltipWrapper
            key={i + 'tooltip'}
            title={t('restricted_period_capacity_full')}
            backgroundColor={theme['layout-high-contrast']}
            textColor={theme['text-low-contrast']}
            placement="top"
        >
            {DayReplicatorComponent(i, true)}
        </TooltipWrapper>
    ) : (
        DayReplicatorComponent(i, false)
    );
};

export default FactoryDayReplicator;
