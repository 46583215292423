// GENERATED FILE - DO NOT MODIFY
import { GetOfficeUtilizationStatsRequestDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsRequestDto';
import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class StatsProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getOfficeUtilizationStats(request: GetOfficeUtilizationStatsRequestDto | null): Promise<GetOfficeUtilizationStatsResponseDto | null> {
        const uri = this.buildUri(
            "/stats/office-utilization-stats",
            null,
            { ...request || undefined }
        );

        const data: GetOfficeUtilizationStatsResponseDto | null = await this.httpClient.get<GetOfficeUtilizationStatsResponseDto | null>(uri);
        return data;
    }
}