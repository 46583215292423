import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubCinemaIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M4.8 5L6.6 8.5H9.3L7.5 5H9.3L11.1 8.5H13.8L12 5H13.8L15.6 8.5H18.3L16.5 5H19.2C19.695 5 20.1189 5.1715 20.4717 5.5145C20.8239 5.85692 21 6.26875 21 6.75V17.25C21 17.7312 20.8239 18.1434 20.4717 18.4864C20.1189 18.8288 19.695 19 19.2 19H4.8C4.305 19 3.8814 18.8288 3.5292 18.4864C3.1764 18.1434 3 17.7312 3 17.25V6.75C3 6.26875 3.1764 5.85692 3.5292 5.5145C3.8814 5.1715 4.305 5 4.8 5ZM4.8 10.25V17.25H19.2V10.25H4.8Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubCinemaIcon;
