import { useEffect, useState } from 'react';

export interface QueuedFetch {
    /**The fetch function. async () => service.fetch({params})*/
    fetchFunction: any;
    /**A unique id to identify the response. Mandatory to be unique if useFetchQueue(maxConcurrentCall) is greater than 1*/
    fetchId: string;
    /**Declare a function to handle receiving responses from fetch */
    callback: (fetchId: string, hasError: boolean, response: any, extraData?: any) => void;
    /**Any extra object you would need in callback */
    extraCallbackData?: any;
}

/**
 * Hook that lets you add fetch functions and queues them
 * @returns [add a fetch function to the queue, empty the queue say if we navigate elsewhere we dont need to continue fetching]
 */
export const useFetchQueue = (
    maxConcurrentCall = 1
): [(fetchParams: QueuedFetch) => void, () => void] => {
    const [queue, setQueue] = useState<QueuedFetch[]>([]);
    const [processingQueue, setProcessingQueue] = useState<QueuedFetch[]>([]);

    const addToQueue = (fetch: QueuedFetch) => {
        setQueue((prev) => [...prev, fetch]);
    };

    const emptyQueue = () => {
        setQueue(() => []);
    };

    const executeQueue = async (processQueue: QueuedFetch[]) => {
        if (processQueue.length + 1 <= maxConcurrentCall) {
            const queuedFetch = queue[0];
            setQueue((prev) => prev.slice(1)); //remove from queue

            setProcessingQueue((prev) => [...prev, queuedFetch]); //add to processing queue

            try {
                const response = await queuedFetch.fetchFunction();
                queuedFetch.callback(
                    queuedFetch.fetchId,
                    false,
                    response,
                    queuedFetch.extraCallbackData
                );
            } catch (error) {
                queuedFetch.callback(
                    queuedFetch.fetchId,
                    true,
                    null,
                    queuedFetch.extraCallbackData
                );
            } finally {
                setProcessingQueue((prev) => {
                    const completedFetch = prev.find(
                        (fetch) => fetch.fetchId === queuedFetch.fetchId
                    );
                    return [...prev.filter((fetch) => fetch.fetchId !== completedFetch?.fetchId)];
                });
            }
        }
    };

    useEffect(() => {
        if (queue.length > 0) {
            executeQueue(processingQueue);
        }
    }, [queue, processingQueue]);

    return [addToQueue, emptyQueue];
};
