import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Badge: FunctionComponent<SvgProps> = ({ fill, onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 30 29" fill="none" {...props}>
                <path
                    d="M22 11.75C22 11.1977 21.5523 10.75 21 10.75H9C8.44772 10.75 8 11.1977 8 11.75V24.284C8 24.9885 8.70901 25.4721 9.36492 25.215L15.0131 23.0012C15.2477 22.9092 15.5084 22.9092 15.743 23.0012L20.6351 24.9186C21.291 25.1757 22 24.6921 22 23.9876V11.75Z"
                    fill={fill ?? '#BEB9F8'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 19.25C19.4183 19.25 23 15.6683 23 11.25C23 6.83172 19.4183 3.25 15 3.25C10.5817 3.25 7 6.83172 7 11.25C7 15.6683 10.5817 19.25 15 19.25Z"
                    fill={fill ?? '#E9E8FD'}
                    stroke={fill ?? '#BEB9F8'}
                    strokeWidth="2"
                />
            </svg>
        </span>
    );
};

export default Badge;
