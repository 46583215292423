import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import locationIcon from 'Assets/Images/location-icon.svg';
import './index.less';
import { theme } from 'Style/theme';
import { useTranslation } from 'react-i18next';
import { useStores } from 'Hooks';

interface GooglePlacesAutoCompleteProps {
    onChange: (value?: string, label?: string) => void;
    defaultValue?: { label: string; value: string };
}

const GooglePlacesAutoComplete: FunctionComponent<GooglePlacesAutoCompleteProps> = observer(
    ({ onChange, defaultValue }) => {
        const { t } = useTranslation();
        const { languageStore } = useStores();

        const handleChange = (event: any) => {
            if (event === null) onChange(undefined, undefined);
            else onChange(event.value.place_id, event.label);
        };

        const icon = () => ({
            alignItems: 'center',
            display: 'flex',

            ':before': {
                content: `url(${locationIcon})`,
                marginRight: 15,
                marginBottom: 4,
                height: 15,
                width: 15,
            },
        });

        //https://react-select.com/styles
        const customStyles = {
            indicatorSeparator: (provided: any) => ({
                ...provided,
                display: 'none',
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                display: 'none',
            }),
            singleValue: (provided: any) => ({
                ...provided,
                fontSize: '15px',
                fontWeight: '400',
                color: theme['text-high-contrast'],
                ...icon(),
            }),
            placeholder: (provided: any) => ({
                ...provided,
                ...icon(),
            }),
            input: (provided: any) => ({
                ...provided,
                padding: '8px',
            }),
        };

        return (
            <div className="GooglePlacesAutoComplete">
                <GooglePlacesAutocomplete
                    autocompletionRequest={{ types: ['(regions)'] }}
                    selectProps={{
                        defaultValue: defaultValue,
                        onChange: handleChange,
                        styles: customStyles,
                        placeholder: t('Location.search_city'),
                        isClearable: true,
                    }}
                    apiKey={window.Environment.REACT_APP_GOOGLE_API_KEY}
                    apiOptions={{ language: languageStore.currentLanguage }}
                />
            </div>
        );
    }
);

export default GooglePlacesAutoComplete;
