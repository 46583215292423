import { ConfigProvider, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table';
import { useApiRequestHistory, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import {
    SETTINGS_URL,
    DEBOUNCE_DELAY_400,
    SETTINGS_POLICIES_URL_REGEX,
    POLICIES_URL,
} from 'Models/Constants';
import { initialPaginationState } from 'Models/InitialPaginationState';
import {
    default as React,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import { FilterStore } from 'Stores';
import TableFilters from 'Components/table-filters/table-filters';
import { observer } from 'mobx-react-lite';
import TablePageSecondHeader from 'Components/table-page-second-header';
import { useHistory } from 'react-router-dom';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { Empty } from 'Components/empty';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import './index.less';
import CreatePolicyModal from './create-policy-modal';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { GetPolicyBundlesSortColumnDto } from 'Api/Features/Policies/Dtos/GetPolicyBundlesSortColumnDto';
import { GetPolicyBundlesRequestDto } from 'Api/Features/Policies/Dtos/GetPolicyBundlesRequestDto';
import OnelinePolicySummary from 'Components/oneline-policy-summary';
import Content from 'Components/Content';
import Skeleton from 'Components/skeleton';
import { tableSkeletonShape } from '../skeleton-shapes';

const Policies: FunctionComponent = observer(() => {
    const policyBundleService = useService(PolicyBundleService);
    const history = useHistory();
    const paginationRef = useRef(initialPaginationState);
    const { t } = useTranslation();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const { toastStore } = useStores();
    const [policyList, setPolicyList] = useState<PolicyBundleDto[]>([]);
    const [noPolicies, setNoPolicies] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState({
        policy: false,
    });
    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex: SETTINGS_POLICIES_URL_REGEX,
            requestType: RequestType.Policies,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            const filterStore = filterStoreRef.current;
            const request: GetPolicyBundlesRequestDto = history.request;
            filterStore.searchTerm = request.searchTerm ?? '';

            paginationRef.current = {
                ...paginationRef.current,
                current: request.page ? request.page + 1 : 1,
                pageSize: request.pageSize ?? initialPaginationState.pageSize,
            };
        },
    });

    const columns: ColumnType<PolicyBundleDto>[] = [
        {
            title: t('name'),
            render: (policy: PolicyBundleDto) => policy.name,
            key: GetPolicyBundlesSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            width: '30%',
            sorter: true,
        },
        {
            title: t('summary'),
            render: (policy: PolicyBundleDto) => <OnelinePolicySummary policy={policy} />,
            width: '45%',
        },
        {
            title: t('Policy.number_of_teams'),
            sorter: true,
            width: '25%',
            render: (policy: PolicyBundleDto) => policy.numberOfTeams,
            key: GetPolicyBundlesSortColumnDto.NumberOfTeams,
        },
    ];

    const fetchPolicies = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: any | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetPolicyBundlesRequestDto = setRequestHistory({
                    request: {
                        pageSize: params.pagination.pageSize || 0,
                        page: (params.pagination.current || 1) - 1,
                        searchTerm: params.searchTerm,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                    },
                    requestType: RequestType.Policies,
                });

                const [items, totalItemsCount] = await policyBundleService.getPolicyBundles(
                    request
                );
                setPolicyList(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
                setIsLoading((prev) => {
                    return { ...prev, policy: true };
                });

                if (items.length > 0) setNoPolicies(false);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [policyBundleService]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm } = filterStoreRef.current;
        await fetchPolicies({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string }) => {
            fetchPolicies({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const onRowClick = (policy: PolicyBundleDto): void => {
        history.push(SETTINGS_URL + POLICIES_URL + `/${policy.id}`);
    };

    return (
        <div className="PoliciesTable">
            <TablePageSecondHeader
                title={t('Settings.title_policy')}
                createBtnString={t('create_entity', {
                    param1: t('bundle'),
                    param2: 'un',
                    param3: t('Entity.lowercase_bundle'),
                })}
                onCreateBtnClick={() => {
                    setCreateModalVisible(true);
                }}
                isLoaded={isLoading.policy}
            />
            <Content>
                {noPolicies ? (
                    <div className="table-empty-yet">
                        <Empty />
                    </div>
                ) : (
                    <>
                        <TableFilters
                            filterStore={filterStoreRef.current}
                            includeSearch
                            isLoaded={isLoading.policy}
                        />

                        <Skeleton placeholder={tableSkeletonShape} isLoaded={isLoading.policy}>
                            <ConfigProvider renderEmpty={() => <Empty />}>
                                <Table
                                    className="table-action-rows table-striped-rows"
                                    dataSource={policyList}
                                    columns={columns}
                                    loading={loading}
                                    rowKey={(team): string => team.id ?? ''}
                                    pagination={pagination}
                                    onChange={handleTableChange}
                                    onRow={(row: PolicyBundleDto) => ({
                                        onClick: (): void => {
                                            onRowClick(row);
                                        },
                                    })}
                                />
                            </ConfigProvider>
                        </Skeleton>
                    </>
                )}
            </Content>

            {createModalVisible && (
                <CreatePolicyModal
                    visible={createModalVisible}
                    onComplete={(success: boolean, addPeople: boolean, id?: string) => {
                        setCreateModalVisible(false);
                        if (success && id) {
                            history.push(
                                SETTINGS_URL +
                                    POLICIES_URL +
                                    `/${id}${addPeople ? '?addPeople=true' : ''}`
                            );
                        }
                    }}
                />
            )}
        </div>
    );
});

export default Policies;
