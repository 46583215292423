import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import SectionHeaderMobile from 'Components/section-header-mobile';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { DASHBOARD_URL_REGEX, ONBOARDING_URL } from 'Models/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ProfileModalMobile, {
    ProfileModalMobileData,
    VisibleData,
} from 'Routes/profile/components/profile-modal-mobile/profile-modal-mobile';
import { AuthenticationService } from 'Services/AuthenticationService';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import { StatusCode } from 'Stores/AuthorizationStore';
import { img, ImgSize } from 'Utils/ImgUtils';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import './index.less';

interface AuthenticatedLayoutMobileProps {
    onlyAuthorizedRoles?: ManagementRoleDto[];
}

const AuthenticatedLayoutMobile: React.FunctionComponent<AuthenticatedLayoutMobileProps> = observer(
    ({ onlyAuthorizedRoles = [], children }) => {
        const authenticationService = useService(AuthenticationService);
        const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
        const azureAuthService = useService(AzureAdAuthenticationService);
        const { userStore, authenticationStore, policyStore, authorizationStore, languageStore } =
            useStores();
        const [profileModalMobileVisible, setProfileModalMobileVisible] = useState(false);
        const location = useLocation();
        const history = useHistory();
        const { t } = useTranslation();

        const onClickLogout = async (): Promise<void> => {
            authenticationStore.clearSession();
            await azureAdAuthenticationService.signOut();
        };

        const profilImageUrl = userStore.userInfo?.imageUrl
            ? img(userStore.userInfo?.imageUrl, ImgSize.m)
            : NoProfilePicture;

        const profileModalMobileVisibleData: VisibleData = {
            visible: profileModalMobileVisible,
            onClose: () => setProfileModalMobileVisible(false),
        };

        const profileModalMobileData: ProfileModalMobileData = {
            imgUrl: profilImageUrl,
            managementRoleText: userStore.ManagementRole
                ? t(`ManagementRoleDto.ManagementRoleDto_${userStore.ManagementRole}`)
                : undefined,
            name: userStore.userInfo?.firstName + ' ' + userStore.userInfo?.lastName,
            jobTitle: userStore.userInfo?.jobTitle ?? '',
            badges: userStore.userInfo?.badges ?? undefined,
            teamName: userStore.userInfo?.team?.name ?? '',
            department: userStore.userInfo?.department ?? '',
        };

        const headerTitle = useCallback((): string => {
            if (DASHBOARD_URL_REGEX.test(location.pathname)) {
                return t('SideNav.nav_dashboard');
            }

            return '';
        }, [location.pathname]);

        const fetchPolicyInfo = useCallback(async () => {
            await policyStore.setPolicyRestrictionsAndObjectivesStats();
        }, []);

        const fetchUserInfo = useCallback(async () => {
            try {
                if (authenticationStore.userId) {
                    policyStore.setCallInProgress(true);
                    await userStore.setUserInfo(authenticationStore.userId!);
                    await fetchPolicyInfo();
                } else {
                    if (!authenticationService.runSignInPromise) {
                        authenticationStore.setReturnUrl(
                            window.location.pathname + window.location.search
                        );
                        authenticationService.runSignInPromise = azureAuthService.runSignInFlow(
                            languageStore.currentLanguage
                        );
                    }
                    await authenticationService.runSignInPromise;
                }
            } finally {
                policyStore.setCallInProgress(false);
            }
        }, []);

        useEffect(() => {
            if (userStore.userInfo === null && !userStore.callInProgress) fetchUserInfo();
        }, [fetchUserInfo, userStore.userInfo, userStore.callInProgress]);

        useEffect(() => {
            if (
                userStore.userInfo &&
                !userStore.userInfo.hasSeenOnboarding &&
                !policyStore.callInProgress
            )
                history.push(ONBOARDING_URL);
        }, [
            userStore.userInfo,
            policyStore.callInProgress,
            userStore?.userInfo?.hasSeenOnboarding,
        ]);

        // Manage user is on page he does not have authorization for
        useEffect(() => {
            if (userStore.userInfo?.managementRoles && onlyAuthorizedRoles.length > 0) {
                if (
                    //employee has managementRoles: []
                    userStore.userInfo.managementRoles.length === 0 ||
                    !onlyAuthorizedRoles.some(
                        (authRoles) => authRoles === userStore.userInfo!.managementRoles![0]?.name
                    )
                )
                    return authorizationStore.handleErrorCode(StatusCode.forbidden);
            }
            authorizationStore.resetNotFoundAndForbidden();
        }, [userStore?.userInfo?.managementRoles, location.pathname, onlyAuthorizedRoles]);

        return (
            <>
                <Helmet>
                    <title>{`${headerTitle()} - Flexy`}</title>
                </Helmet>

                <div className="AuthenticatedLayoutMobile">
                    <SectionHeaderMobile
                        imgUrl={profilImageUrl}
                        onImgClick={() => setProfileModalMobileVisible(true)}
                    />

                    <div className="content">{children}</div>
                </div>

                {profileModalMobileVisible && (
                    <ProfileModalMobile
                        visibleData={profileModalMobileVisibleData}
                        data={profileModalMobileData}
                        onClickLogout={onClickLogout}
                    />
                )}
            </>
        );
    }
);

export default AuthenticatedLayoutMobile;
