import { ConnectSlackWorkspaceRequestDto } from 'Api/Features/Slack/Dtos/ConnectSlackWorkspaceRequestDto';
import { GetSlackConfigResponseDto } from 'Api/Features/Slack/Dtos/GetSlackConfigResponseDto';
import { ApiService } from './ApiService';
import { SlackProxy } from 'Api/Features/Slack/SlackProxy';
import { inject } from 'aurelia-dependency-injection';
import SlackParametersStore from 'Stores/SlackParametersStore';
import { GetSlackConfigResponseWorkspaceDto } from 'Api/Features/Slack/Dtos/GetSlackConfigResponseWorkspaceDto';
import { GetSlackConfigResponseEnterpriseDto } from 'Api/Features/Slack/Dtos/GetSlackConfigResponseEnterpriseDto';
import { SETTINGS_URL, SLACK_INTEGRATION_URL } from 'Models/Constants';
import { UpdateSlackConfigRequestDto } from 'Api/Features/Slack/Dtos/UpdateSlackConfigRequestDto';
import { GetSlackChannelsRequestDto } from 'Api/Features/Slack/Dtos/GetSlackChannelsRequestDto';
import { GetSlackChannelsResponseDto } from 'Api/Features/Slack/Dtos/GetSlackChannelsResponseDto';
import { SlackChannel } from 'Api/Features/Slack/Dtos/SlackChannel';

export class SlackParameters {
    constructor(
        public readonly authorizeEndpoint: string,
        public readonly redirectUri: string,
        public readonly slackWorkspace?: GetSlackConfigResponseWorkspaceDto | null,
        public readonly slackEnterprise?: GetSlackConfigResponseEnterpriseDto | null,
        public readonly notificationsEnabled?: boolean
    ) {}
}

@inject(SlackProxy, SlackParametersStore)
export class SlackService extends ApiService {
    constructor(
        private readonly slackProxy: SlackProxy,
        private readonly slackParametersStore: SlackParametersStore
    ) {
        super();
    }

    private redirectUri =
        window.location.protocol +
        '//' +
        window.location.host +
        `${SETTINGS_URL}${SLACK_INTEGRATION_URL}`;

    public async runConnectSlackFlow(): Promise<void> {
        const slackParameters = await this.getSlackConfig();

        if (slackParameters) {
            this.slackParametersStore.clearParameters();

            const args = new URLSearchParams({
                redirect_uri: slackParameters.redirectUri,
            });

            window.location = (slackParameters.authorizeEndpoint + '&' + args) as any;
        }
    }

    public async getSlackConfig(): Promise<SlackParameters | null> {
        if (this.slackParametersStore.slackParameters) {
            return this.slackParametersStore.slackParameters;
        }

        const data: GetSlackConfigResponseDto | null = await this.slackProxy.getSlackConfig();

        const parameters: SlackParameters = {
            authorizeEndpoint: data?.authorizeUrl ?? '',
            slackEnterprise: data?.slackEnterprise,
            slackWorkspace: data?.slackWorkspace,
            redirectUri: this.redirectUri,
            notificationsEnabled: data?.notificationsEnabled,
        };

        this.slackParametersStore.setParameters(parameters);

        return parameters;
    }

    public async connectSlackWorkspace(
        request: ConnectSlackWorkspaceRequestDto | null
    ): Promise<void> {
        await this.slackProxy.connectSlackWorkspace(request);

        const data: GetSlackConfigResponseDto | null = await this.slackProxy.getSlackConfig();

        const parameters: SlackParameters = {
            authorizeEndpoint: data?.authorizeUrl ?? '',
            slackEnterprise: data?.slackEnterprise,
            slackWorkspace: data?.slackWorkspace,
            redirectUri: this.redirectUri,
        };
        this.slackParametersStore.setParameters(parameters);
    }

    public async disconnectSlackWorkspace(): Promise<void> {
        await this.slackProxy.disconnectSlackWorkspace();
        this.slackParametersStore.clearParameters();
    }

    public async updateSlackConfig(request: UpdateSlackConfigRequestDto | null): Promise<void> {
        await this.slackProxy.updateSlackConfig(request);
    }

    public async getSlackChannels(
        request: GetSlackChannelsRequestDto | null
    ): Promise<[SlackChannel[], number]> {
        const data: GetSlackChannelsResponseDto | null = await this.slackProxy.getSlackChannels(
            request
        );
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async isSlackWorkspaceConnected(): Promise<boolean> {
        const data: boolean = await this.slackProxy.isSlackWorkspaceConnected();
        return data;
    }
}
