// GENERATED FILE - DO NOT MODIFY
import { CreatePolicyBundleRequestDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleRequestDto';
import { CreatePolicyBundleResponseDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleResponseDto';
import { GetPolicyBundlesRequestDto } from 'Api/Features/Policies/Dtos/GetPolicyBundlesRequestDto';
import { GetPolicyBundlesResponseDto } from 'Api/Features/Policies/Dtos/GetPolicyBundlesResponseDto';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { UpdatePolicyBundleRequestDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleRequestDto';
import { UpdatePolicyBundleTeamsRequestDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleTeamsRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class PolicyBundleProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getPolicyBundle(bundleId: string): Promise<PolicyBundleDto | null> {
        const uri = this.buildUri(
            "/policy-bundles/{bundleId}",
            { bundleId: bundleId },
            null
        );

        const data: PolicyBundleDto | null = await this.httpClient.get<PolicyBundleDto | null>(uri);
        return data;
    }

    public async getPolicyBundles(request: GetPolicyBundlesRequestDto | null): Promise<GetPolicyBundlesResponseDto | null> {
        const uri = this.buildUri(
            "/policy-bundles",
            null,
            { ...request || undefined }
        );

        const data: GetPolicyBundlesResponseDto | null = await this.httpClient.get<GetPolicyBundlesResponseDto | null>(uri);
        return data;
    }

    public async createPolicyBundle(request: CreatePolicyBundleRequestDto | null): Promise<CreatePolicyBundleResponseDto | null> {
        const uri = this.buildUri(
            "/policy-bundles",
            null,
            null
        );

        const data: CreatePolicyBundleResponseDto | null = await this.httpClient.post<CreatePolicyBundleRequestDto | null, CreatePolicyBundleResponseDto | null>(uri, request);
        return data;
    }

    public async updatePolicyBundle(bundleId: string, request: UpdatePolicyBundleRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/policy-bundles/{bundleId}",
            { bundleId: bundleId },
            null
        );

        await this.httpClient.patch<UpdatePolicyBundleRequestDto | null, void>(uri, request);
    }

    public async updatePolicyBundleTeams(bundleId: string, request: UpdatePolicyBundleTeamsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/policy-bundles/{bundleId}/update-teams",
            { bundleId: bundleId },
            null
        );

        await this.httpClient.post<UpdatePolicyBundleTeamsRequestDto | null, void>(uri, request);
    }

    public async deletePolicyBundle(bundleId: string): Promise<void> {
        const uri = this.buildUri(
            "/policy-bundles/{bundleId}",
            { bundleId: bundleId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}