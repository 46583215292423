import { CALENDLY_URL, SLACK_URL } from 'Models/Constants';
import * as yup from 'yup';

//REMINDER CHANGES HERE COULD AFFECT ONBOARDING FLOW

const commonShape = {
    firstName: yup
        .string()
        .required('Errors.error_required')
        .min(2, 'Errors.error_min_2')
        .max(40, 'Errors.error_max_40'),
    lastName: yup
        .string()
        .required('Errors.error_required')
        .min(2, 'Errors.error_min_2')
        .max(40, 'Errors.error_max_40'),
    contactInfo: yup.object({
        email: yup
            .string()
            .required('Errors.error_required')
            .email('Errors.error_email_invalid')
            .max(100, 'Errors.error_max_100'),
    }),
    jobTitle: yup.string().nullable().min(2, 'Errors.error_min_2').max(80, 'Errors.error_max_80'),
    department: yup.string().nullable().max(40, 'Errors.error_max_40'),
    timeZone: yup.string().required('Errors.error_required'),
    defaultOfficeEnabled: yup.boolean(),
    defaultOfficeId: yup.string().when('defaultOfficeEnabled', {
        is: true,
        then: yup.string().required('Errors.error_required'),
    }),
    defaultFloor: yup.string().nullable().max(20, 'Errors.error_max_20'),
    defaultDesk: yup.string().nullable().max(20, 'Errors.error_max_20'),
    skills: yup.array().of(yup.string().max(40, 'Errors.errors_skill_40')).nullable(),
};

const editProfileShape = {
    contactPreferencesPhoneNumberEnabled: yup.boolean(),
    contactPreferencesPhoneNumber: yup.string().when('contactPreferencesPhoneNumberEnabled', {
        is: true,
        then: yup.string().required('Errors.error_required_if_enabled'),
    }),
    contactPreferencesEmailEnabled: yup.boolean(),
    contactPreferencesEmail: yup
        .string()
        .email('Errors.error_email_invalid')
        .max(100, 'Errors.error_max_100')
        .nullable()
        .when('contactPreferencesEmailEnabled', {
            is: true,
            then: yup.string().required('Errors.error_required_if_enabled'),
        }),
    contactPreferencesSlackEnabled: yup.boolean(),
    contactPreferencesSlack: yup
        .string()
        .matches(SLACK_URL, 'Errors.error_valid_url_slack')
        .max(100, 'Errors.error_max_100')
        .nullable()
        .when('contactPreferencesSlackEnabled', {
            is: true,
            then: yup.string().required('Errors.error_required_if_enabled'),
        }),
    contactPreferencesCalendlyEnabled: yup.boolean(),
    contactPreferencesCalendly: yup
        .string()
        .matches(CALENDLY_URL, 'Errors.error_valid_url_calendly')
        .max(100, 'Errors.error_max_100')
        .nullable()
        .when('contactPreferencesCalendlyEnabled', {
            is: true,
            then: yup.string().required('Errors.error_required_if_enabled'),
        }),

    defaultOfficeEnabled: yup.boolean(),
    defaultOfficeId: yup.string().when('defaultOfficeEnabled', {
        is: true,
        then: yup.string().required('Errors.error_required'),
    }),
};

export const CreateUserSchema = yup.object(Object.assign({}, commonShape));

export const EditProfileSchema = yup.object(Object.assign({}, commonShape, editProfileShape));

export const OnboardingWorkplaceSchema = yup.object({
    defaultOfficeEnabled: yup.boolean(),
    defaultOffice: yup.string().when('defaultOfficeEnabled', {
        is: true,
        then: yup.string().required('Errors.error_required'),
    }),
    defaultFloor: yup.string().max(20, 'Errors.error_max_20').nullable(),
    defaultDesk: yup.string().max(20, 'Errors.error_max_20').nullable(),
});
