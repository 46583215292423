import { ContactMethodTypeDto } from 'Api/Features/General/Dtos/ContactMethodTypeDto';
import { ContactPreferencesMethodDto } from 'Api/Features/General/Dtos/ContactPreferencesMethodDto';

export const getContactPreferencesMethodArray = (methods: {
    [key: string]: { value: string | undefined; enabled: boolean };
}): ContactPreferencesMethodDto[] => {
    const methodArray = Object.keys(methods).map((method) => ({
        type: ContactMethodTypeDto[method.charAt(0).toUpperCase() + method.slice(1)],
        value: methods[method].value,
        enabled: methods[method].enabled,
    }));

    return methodArray;
};

export const getEachContactPreferencesMethod = (
    methods?: (ContactPreferencesMethodDto | null)[] | null
): { [key: string]: { value: string; enabled: boolean } } => {
    const mappedMethods = {};
    (Object.keys(ContactMethodTypeDto) as Array<keyof typeof ContactMethodTypeDto>).forEach(
        (key) => {
            mappedMethods[key.charAt(0).toLowerCase() + key.slice(1)] = {
                value: undefined,
                enabled: false,
            };
        }
    );

    methods?.forEach((method) => {
        if (method?.type)
            mappedMethods[method.type.charAt(0).toLowerCase() + method.type.slice(1)] = {
                value: method.value,
                enabled: method.enabled,
            };
    });

    return mappedMethods;
};

export const getEnabledContactPreferencesMethod = (
    methods?: (ContactPreferencesMethodDto | null)[] | null
): { [key: string]: string } | undefined => {
    const eachMethods = getEachContactPreferencesMethod(methods);
    const enabledMethods = {};
    let hasValueToDisplay = false;

    Object.keys(eachMethods).forEach((method) => {
        if (eachMethods[method].enabled) hasValueToDisplay = true;

        enabledMethods[method] = eachMethods[method].enabled
            ? eachMethods[method].value
            : undefined;
    });

    return hasValueToDisplay ? enabledMethods : undefined;
};
