import React, { FunctionComponent, ReactNode, useEffect, useState, useCallback } from 'react';
import './multi-select-common.less';
import Select, { components } from 'react-select';
import { MultiSelectCommonStyles, MultiSelectCustomOption } from './multi-select-common';
import { Checkbox } from 'antd';
import { theme } from 'Style/theme';
import { CloseIcon } from 'Components/icons';
import { useTranslation } from 'react-i18next';

export interface StaticMultiSelectProps {
    className?: string;
    options?: MultiSelectCustomOption[];
    onChange?: (value: MultiSelectCustomOption[]) => void | undefined;
    placeholder?: string | null;
    // defaultImg?: ReactNode;
    strongLabel?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string[] | null;
    disabled?: boolean;
    borderless?: boolean;
    round?: boolean;
    dropdownIndicatorIcon?: ReactNode;
}

const StaticMultiSelect: FunctionComponent<StaticMultiSelectProps> = ({
    className,
    options,
    onChange,
    placeholder,
    // defaultImg,
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    selected,
    disabled,
    borderless,
    round,
    dropdownIndicatorIcon,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<MultiSelectCustomOption[]>([]);

    useEffect(() => {
        if (options === undefined) return;
        if (selected) {
            setValue(
                options?.filter((x) => {
                    return selected?.includes(x.value);
                })
            );
        } else {
            setValue([]);
        }
    }, [selected, options]);

    const handleOnChange = (data: any): void => {
        if (onChange) {
            onChange(data ?? []);
        }
    };

    // -- 02/11/2022 as of now, never have an option tu put image --
    // const optionImage = (data: MultiSelectCustomOption, selectProps: any) => {
    //     return (
    //         <>
    //             {data.imageUrl ? (
    //                 <img
    //                     style={{ flex: '0 0 auto' }}
    //                     className="dropdown-option-img"
    //                     width="24px"
    //                     height="24px"
    //                     src={data.imageUrl}
    //                     alt=""
    //                 />
    //             ) : (
    //                 <span className="dropdown-option-img">{selectProps.defaultImg}</span>
    //             )}
    //         </>
    //     );
    // };

    /* -- The open dropdown menu -- */
    const menuOptionDetails = (data: MultiSelectCustomOption, selectProps: any) => {
        let labelColor = 'black';
        let contentColor = theme['text-mid-contrast'];

        if (data?.isDisabled) {
            labelColor = theme['text-disabled'];
            contentColor = theme['text-disabled'];
        }

        let optionIsSelected = false;
        if (selectProps.value.some((option) => option.value === data.value)) {
            optionIsSelected = true;
        }

        return (
            <div className="menuOptionDetails">
                <div className="selected-checkmark">
                    <Checkbox checked={optionIsSelected} value={optionIsSelected} />
                </div>

                <div className="center-content">
                    <div className="dropdown-menu-option-label">
                        {selectProps.strongLabel ? (
                            <strong>{data.label}</strong>
                        ) : (
                            <div style={{ color: labelColor }}>{data.label}</div>
                        )}
                    </div>
                    {data.content && (
                        <div
                            className={`${data.content.className ?? ''}`}
                            style={{ color: contentColor }}
                        >
                            {data.content.node}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props} className="multi-dropdown-menu-option-container">
                    {/* {defaultImg && optionImage(props.data, props.selectProps)} */}

                    {menuOptionDetails(props.data, props.selectProps)}
                </components.Option>
            </div>
        );
    };
    /* -- The open dropdown menu -- */

    /* -- The selected values in dropdown -- */
    const selectedOptionDetails = (data: MultiSelectCustomOption) => {
        return (
            <div className="selected-option-details">
                <div className="text-caption-3-bold">{data.label}</div>
            </div>
        );
    };

    //This is not the input container, it is the rounded badge container
    const MultiValueContainer = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-multivalue-container';
        return <components.MultiValueContainer innerProps={innerProps} {...props} />;
    };

    const MultiValueLabel = ({ data, innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-selected-element';
        data.content = data.content ? data.content : null;
        return (
            <components.MultiValueLabel innerProps={innerProps} {...props}>
                {selectedOptionDetails(data)}
            </components.MultiValueLabel>
        );
    };

    //icone X dans les selected items pour retirer
    const MultiValueRemove = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-value-remove';
        return (
            <components.MultiValueRemove innerProps={innerProps} {...props}>
                <CloseIcon width={13} height={13} fill={theme['primary-high-contrast']} />
            </components.MultiValueRemove>
        );
    };
    /* -- The selected values in dropdown -- */

    const Input = useCallback(({ ...props }: any) => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback(
        (props): any => {
            return dropdownIndicatorIcon ? (
                <components.DropdownIndicator {...props}>
                    {dropdownIndicatorIcon}
                </components.DropdownIndicator>
            ) : (
                <components.DropdownIndicator {...props}></components.DropdownIndicator>
            );
        },
        [dropdownIndicatorIcon]
    );

    return (
        <Select
            components={{
                Option,
                MultiValueContainer,
                MultiValueLabel,
                MultiValueRemove,
                DropdownIndicator,
                Input,
            }}
            theme={(defaultTheme) => ({
                ...defaultTheme,
                colors: {
                    ...defaultTheme.colors,
                    primary: 'transparent', //dictates the color of select option
                    primary25: theme['primary-low-contrast'], //dictates the color hovering options
                },
            })}
            className={`MultiSelect StaticMultiSelect ${className || ''} ${
                disabled ? 'disabled' : ''
            } ${borderless ? 'borderless' : ''}  ${round ? 'round' : ''}`}
            value={value}
            styles={MultiSelectCommonStyles}
            options={options}
            onChange={handleOnChange}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder ?? t('SelectCustom.default_select_placeholder')}
            // defaultImg={defaultImg}
            isDisabled={disabled}
            noOptionsMessage={(): string => t('SelectCustom.select_no_options')}
            //permet au menu ouvert de sortir de l'epace d'un modal
            menuPortalTarget={document.body}
            isMulti
            isClearable={false}
        />
    );
};

export default StaticMultiSelect;
