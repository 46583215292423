import { Spin, Tooltip } from 'antd';
import StarToggle from 'Components/star-toggle';
import { Fetches, TriggerFetchContext } from 'Contexts/TriggerFetchContext';
import { useService, useStores } from 'Hooks';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from 'Services/UserService';
import './index.less';
import { LoadingOutlined } from '@ant-design/icons';
import { theme } from 'Style/theme';
import { CreateUserFavoriteUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserFavoriteUserRequestDto';

interface FavToggleProps {
    coworkerId?: string;
    relationId?: string;
    fetch?: Fetches[];
    TooltipPlacement?: 'top' | 'left' | 'right' | 'bottom';
    onChange?: (state: 'add' | 'remove', coworkerId: string, relationId: string) => void;
    hasTooltipOnHover?: boolean;
}

interface FavState {
    tooltip: string;
    isFavorite: boolean;
}

const tooltipKeyString = {
    fav: 'Coworker.add_favorite',
    unFav: 'Coworker.remove_favorite',
};

const getFavData = (isFavorite: boolean): FavState => ({
    tooltip: isFavorite ? tooltipKeyString.unFav : tooltipKeyString.fav,
    isFavorite: isFavorite,
});

const FavToggle: React.FunctionComponent<FavToggleProps> = ({
    coworkerId,
    relationId,
    fetch = [],
    TooltipPlacement = 'top',
    onChange,
    hasTooltipOnHover = true,
}) => {
    const triggerFetchContext = useContext(TriggerFetchContext);
    const userService = useService(UserService);
    const { userStore, toastStore } = useStores();
    const [favData, setFavData] = useState<FavState>(getFavData(Boolean(relationId)));
    const [isLoading, setIsLoading] = useState(false);

    const [newRelationId, setNewRelationId] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setFavData(getFavData(Boolean(relationId)));
    }, [relationId]);

    const toggleFavorite = useCallback(async () => {
        setIsLoading(true);

        try {
            if ((newRelationId || relationId) && favData.isFavorite) {
                await userService.deleteUserFavoriteUser(
                    newRelationId && newRelationId.length > 0 ? newRelationId : relationId || ''
                );

                if (onChange)
                    onChange(
                        'remove',
                        coworkerId || '',
                        newRelationId.length > 0 ? newRelationId : relationId || ''
                    );
                setFavData(getFavData(false));
            } else {
                if (userStore.userInfo?.id && coworkerId) {
                    const response = await userService.createUserFavoriteUser({
                        userId: userStore.userInfo.id,
                        favoriteUserId: coworkerId,
                    } as CreateUserFavoriteUserRequestDto);

                    if (response) setNewRelationId(response);
                    if (onChange) onChange('add', coworkerId || '', response);
                }

                setFavData(getFavData(true));
            }

            triggerFetchContext?.trigger(fetch);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setIsLoading(false);
        }
    }, [toastStore, favData.isFavorite]);

    const handleOnClick = (e) => {
        e.stopPropagation();
        toggleFavorite();
    };

    return (
        <React.Fragment>
            {hasTooltipOnHover ? (
                <Tooltip
                    overlay={t(favData.tooltip)}
                    overlayClassName="FavToggle-tooltip"
                    placement={TooltipPlacement}
                    arrowPointAtCenter={true}
                >
                    <div className="FavToggle" onClick={handleOnClick}>
                        {isLoading ? (
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{ fontSize: 20, color: theme['secondary-color'] }}
                                        spin
                                    />
                                }
                                size="large"
                            />
                        ) : (
                            <StarToggle isActive={favData.isFavorite} />
                        )}
                    </div>
                </Tooltip>
            ) : (
                <div className="FavToggle" onClick={handleOnClick}>
                    {isLoading ? (
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{ fontSize: 20, color: theme['secondary-color'] }}
                                    spin
                                />
                            }
                            size="large"
                        />
                    ) : (
                        <StarToggle isActive={favData.isFavorite} />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default FavToggle;
