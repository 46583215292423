import { ChevronDownIcon, ChevronUpIcon } from 'Components/icons';
import PolicyObjectiveCircleIcon from 'Components/policyObjectiveCircleIcon';
import TooltipWrapper from 'Components/tooltip-wrapper';
import WhiteCard from 'Components/white-card';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyInfoStoreObjectiveStats } from 'Stores/PolicyStore';
import { theme } from 'Style/theme';
import { objectivePopoverContent } from 'Utils/PolicyObjectivesUtils';
import './card-objectives.less';

interface EditHourPrefModalCardObjectivesProps {
    policyObjectiveStats: PolicyInfoStoreObjectiveStats | undefined;
}

const EditHourPrefModalCardObjectives: React.FunctionComponent<
    EditHourPrefModalCardObjectivesProps
> = ({ policyObjectiveStats }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();

    const handleToggle = () => setMenuOpen((prev) => !prev);

    if (!policyObjectiveStats) return <></>;

    return (
        <WhiteCard
            paddingTop={10}
            paddingBottom={15}
            style={{ borderRadius: 10 }}
            className="EditHourPrefModalCardObjectives"
        >
            <div className="container-header">
                <div className="stat-container">
                    <PolicyObjectiveCircleIcon
                        width={40}
                        policyGoal={policyObjectiveStats?.totalScore.total}
                        policyCurrentValue={policyObjectiveStats?.totalScore.completed}
                    />
                    <div className="text-caption-2-bold number-progress">
                        {policyObjectiveStats?.totalScore?.completed} /{' '}
                        {policyObjectiveStats?.totalScore?.total}
                    </div>
                </div>

                <div
                    className="text-caption-2 text-week-objectives"
                    dangerouslySetInnerHTML={{ __html: t('PlanSchedule.this_weeks_objectives') }}
                />

                <div className="chevron-down-icon" onClick={handleToggle}>
                    {menuOpen ? (
                        <ChevronUpIcon width={24} height={24} fill={theme['black']} />
                    ) : (
                        <ChevronDownIcon width={24} height={24} fill={theme['black']} />
                    )}
                </div>
            </div>

            <div className={`container-bottom toggle-${menuOpen}`}>
                {policyObjectiveStats?.policyBundleObjectivesDetails.map((policy, key) => {
                    const progression = policy.completed;
                    const displayProgression =
                        progression >= policy.total ? policy.total : progression;

                    return (
                        <TooltipWrapper
                            placement="left"
                            title={() => objectivePopoverContent(policy, t)}
                            key={key}
                            backgroundColor={theme['layout-low-contrast']}
                            textColor={theme['text-high-contrast']}
                            borderRadius={5}
                            padding={[10, 20, 12, 20]}
                            width={453}
                        >
                            <div key={key} className="container-policy">
                                <PolicyObjectiveCircleIcon
                                    policyKey={policy.label}
                                    policyCurrentValue={policy.completed}
                                    policyGoal={policy.total}
                                    width={25}
                                    iconSize={15}
                                />
                                <div>
                                    <div className="text-caption-2-bold policy-value">
                                        {displayProgression} / {policy.total}
                                    </div>
                                    <div className="text-caption-3">
                                        {t(
                                            `PolicyBundleObjectivesDto.PolicyBundleObjectivesDto_${policy.label}`
                                        )}
                                    </div>
                                </div>
                            </div>
                        </TooltipWrapper>
                    );
                })}
            </div>
        </WhiteCard>
    );
};

export default EditHourPrefModalCardObjectives;
