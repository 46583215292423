import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const DuplicateIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M20.0732 20.3164H20.3545C22.0508 20.3164 23.1406 19.3936 23.1406 17.4336V9.14551H18.2188C17.3223 9.14551 16.7686 8.5918 16.7686 7.7041V2.47461H12.0488C10.2119 2.47461 9.20117 3.46777 9.20117 5.27832V5.7002H10.5547C11.917 5.7002 12.9277 6.0166 13.9033 6.99219L18.8867 11.9756C19.8447 12.9336 20.0732 14.0234 20.0732 15.3242V20.3164ZM18.4473 7.95898H23C22.9648 7.66895 22.7451 7.39648 22.4111 7.0625L18.8516 3.40625C18.5264 3.07227 18.2539 2.86133 17.9551 2.81738L17.9639 7.4668C17.9639 7.81836 18.0957 7.95898 18.4473 7.95898ZM7.71582 24.8252H15.9424C17.8232 24.8252 18.79 23.832 18.79 21.9424V15.1221H12.541C11.4248 15.1221 10.8711 14.5684 10.8711 13.4434V6.97461H7.71582C5.83496 6.97461 4.85938 7.96777 4.85938 9.85742V21.9424C4.85938 23.8408 5.83496 24.8252 7.71582 24.8252ZM12.6289 13.8389H18.6318C18.5352 13.4609 18.3066 13.1094 17.9023 12.6963L13.2793 7.99414C12.875 7.58105 12.5234 7.30859 12.1543 7.20312V13.3555C12.1543 13.6719 12.3213 13.8389 12.6289 13.8389Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default DuplicateIcon;
