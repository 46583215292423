import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const SquareAndPencilIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill={fill}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.98145 21.8633H19.2207C20.9082 21.8633 21.9014 20.8789 21.9014 18.998V9.7168L20.1436 11.4658V18.8398C20.1436 19.6836 19.6953 20.1055 19.1064 20.1055H9.0957C8.28711 20.1055 7.84766 19.6836 7.84766 18.8398V9.0752C7.84766 8.23145 8.28711 7.80957 9.0957 7.80957H16.5576L18.3066 6.05176H8.98145C7.08301 6.05176 6.08984 7.03613 6.08984 8.91699V18.998C6.08984 20.8789 7.08301 21.8633 8.98145 21.8633Z"
                    fill={fill}
                />
                <path
                    d="M14.6911 13.6838C15.0143 14.007 15.5363 14.007 15.8471 13.6963L21.8195 7.72384L22.6896 6.75433L22.6212 7.59333L22.5653 9.3894C22.5528 9.60071 22.6398 9.79958 22.7828 9.94252C23.0687 10.2284 23.5099 10.2595 23.802 9.96738C23.9574 9.81201 24.0071 9.638 24.0071 9.42669L23.9325 5.33735C23.9263 5.03283 23.8393 4.84638 23.6839 4.69101C23.5348 4.54186 23.3421 4.44863 23.0438 4.44863L18.9482 4.36784C18.7369 4.36784 18.5691 4.42377 18.4138 4.57914C18.1217 4.87124 18.1465 5.30628 18.4324 5.59216C18.5754 5.7351 18.7804 5.82832 18.9918 5.81589L20.7816 5.75374L21.6268 5.67916L20.6573 6.56167L14.6849 12.5341C14.3742 12.8448 14.3742 13.3669 14.6911 13.6838Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default SquareAndPencilIcon;
