import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import Button from 'Components/button';
import { DayCircle } from 'Components/circle-toggle';
import CircleToggleDays from 'Components/circle-toggle-days';
import { CreateCoreHoursRow } from 'Components/display-core-hours';
import Icon from 'Components/icons/Icon';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from 'Utils/ArrayUtils';
import TimeRange from '../time-range';
import './index.less';

const minusPlus15min = (type: '-' | '+', time: string) => {
    const timeArray = time.split(':');
    let h = parseInt(timeArray[0]);
    let m = parseInt(timeArray[1]);

    if (type === '-') {
        if (!(h === 0 && m === 0)) {
            if (h === 23 && m === 59) m = 45;
            else {
                if (m === 0) {
                    h--;
                    m = 45;
                } else {
                    m -= 15;
                }
            }
        }
    } else {
        if (h === 23 && (m === 45 || m === 59)) {
            m = 59;
        } else {
            if (m < 45) {
                m += 15;
            } else {
                h++;
                m = 0;
            }
        }
    }

    return String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
};

const isAddTimeBlocDisabled = (coreHoursList: CreateCoreHoursRow[], coreHoursRowNumber: number) =>
    coreHoursList[coreHoursRowNumber].timeRange[
        coreHoursList[coreHoursRowNumber].timeRange.length - 1
    ].endTime === undefined ||
    coreHoursList[coreHoursRowNumber].timeRange[
        coreHoursList[coreHoursRowNumber].timeRange.length - 1
    ].endTime === '23:59' ||
    coreHoursList[coreHoursRowNumber].timeRange[
        coreHoursList[coreHoursRowNumber].timeRange.length - 1
    ].endTime === '23:45';

const daysChange = (
    coreHoursList: CreateCoreHoursRow[],
    data: DaysChange
): CreateCoreHoursRow[] => {
    let dayToRemove = '';
    if (data.days.length > coreHoursList[data.coreHoursRowNumber].activeDays.length) {
        dayToRemove = data.days.filter(
            (day) => !coreHoursList[data.coreHoursRowNumber].activeDays.includes(day)
        )[0];
    }

    const newCoreHoursList = coreHoursList.map((coreHours, currentCoreHoursRowNumber) => {
        if (currentCoreHoursRowNumber === data.coreHoursRowNumber) {
            return { ...coreHours, activeDays: data.days };
        } else {
            if (dayToRemove) {
                return {
                    ...coreHours,
                    activeDays: coreHours.activeDays.filter(
                        (activeDays) => !activeDays.includes(dayToRemove)
                    ),
                };
            } else {
                return coreHours;
            }
        }
    });

    return newCoreHoursList;
};

const isLastRow = (coreHoursList: CreateCoreHoursRow[], coreHoursRowNumber: number) =>
    coreHoursList.length - 1 === coreHoursRowNumber;

const isLastTimeRange = (coreHours: CreateCoreHoursRow, timeRowNumber: number) =>
    coreHours.timeRange.length - 1 === timeRowNumber;

const getDisabledDays = (coreHoursList: CreateCoreHoursRow[], coreHoursRowNumber: number) => {
    const newCoreHoursList = [...coreHoursList];
    const disabledDays: DayOfWeekDto[] = [];

    let index = coreHoursRowNumber;
    while (index > 0) {
        const currentDisabledDays = (
            Object.keys(DayOfWeekDto) as Array<keyof typeof DayOfWeekDto>
        ).filter((key) =>
            newCoreHoursList[index - 1].activeDays.includes(DayOfWeekDto[key])
        ) as DayOfWeekDto[];

        currentDisabledDays.forEach((day) => {
            disabledDays.push(day);
        });

        index--;
    }

    return disabledDays;
};

const addSection = (coreHoursList: CreateCoreHoursRow[]) => {
    const newCoreHoursList = [...coreHoursList] as CreateCoreHoursRow[];
    const selectedActiveDays: (DayOfWeekDto | DayOfWeekDto[])[] = [];
    let newActiveDays: DayOfWeekDto[] = [];

    newCoreHoursList.forEach((coreHours) => {
        selectedActiveDays.push(coreHours.activeDays);
    });
    newActiveDays = (Object.keys(DayOfWeekDto) as Array<keyof typeof DayOfWeekDto>).filter(
        (key) => !selectedActiveDays.flat().includes(DayOfWeekDto[key])
    ) as DayOfWeekDto[];

    newCoreHoursList.push({ activeDays: newActiveDays, timeRange: [timeRangeInit] });

    return newCoreHoursList;
};

const removeSection = (coreHoursList: CreateCoreHoursRow[]) => {
    const newCoreHoursList = [...coreHoursList] as CreateCoreHoursRow[];
    newCoreHoursList.pop();

    return newCoreHoursList;
};

const addTimeBloc = (coreHoursList: CreateCoreHoursRow[], timeAddRemoveData: TimeAddRemove) => {
    const newCoreHoursList = [...coreHoursList] as CreateCoreHoursRow[];
    newCoreHoursList[timeAddRemoveData.coreHoursRowNumber].timeRange.push(timeRangeInit);

    return newCoreHoursList;
};

const removeTimeBloc = (coreHoursList: CreateCoreHoursRow[], timeAddRemoveData: TimeAddRemove) => {
    const newCoreHoursList = [...coreHoursList];
    newCoreHoursList[timeAddRemoveData.coreHoursRowNumber].timeRange.pop();

    return newCoreHoursList;
};

const timeChange = (
    coreHoursList: CreateCoreHoursRow[],
    data: TimeChange
): CreateCoreHoursRow[] => {
    const newCoreHoursList = coreHoursList.map((coreHours, currentCoreHoursRowNumber) => {
        if (currentCoreHoursRowNumber === data.coreHoursRowNumber) {
            const newTimeRange = coreHours.timeRange.map((range, currentTimeRowNumber) => {
                if (currentTimeRowNumber === data.timeRowNumber) {
                    if (data.type === TimeType.start) return { ...range, startTime: data.time };
                    else return { ...range, endTime: data.time };
                } else {
                    return range;
                }
            });

            return { ...coreHours, timeRange: newTimeRange };
        } else {
            return coreHours;
        }
    });

    return newCoreHoursList;
};

export const getTotalCoreHours = (coreHoursList: CreateCoreHoursRow[]): number => {
    let totalCoreHours = 0;

    coreHoursList.forEach((coreHours) => {
        const nbrActiveDays = coreHours.activeDays.length;

        coreHours.timeRange.forEach((time) => {
            if (time.startTime !== undefined && time.endTime !== undefined) {
                const start = moment(time.startTime, 'h:mm');
                const end = moment(time.endTime, 'h:mm');

                const diffStartAndEnd = moment.duration(end.diff(start)).asHours();
                const totalHours = diffStartAndEnd * nbrActiveDays;
                totalCoreHours += totalHours;
            }
        });
    });

    return totalCoreHours;
};

const timeRangeInit = { startTime: undefined, endTime: undefined };
const activeDaysInit: CreateCoreHoursRow = {
    activeDays: [
        DayOfWeekDto.Sunday,
        DayOfWeekDto.Monday,
        DayOfWeekDto.Tuesday,
        DayOfWeekDto.Wednesday,
        DayOfWeekDto.Thursday,
        DayOfWeekDto.Friday,
        DayOfWeekDto.Saturday,
    ],
    timeRange: [timeRangeInit],
};

enum TimeType {
    start = 'start',
    end = 'end',
}

enum ChangesType {
    Days = 'Days',
    Time = 'TimeValue',
    AddSection = 'AddSection',
    RemoveSection = 'RemoveSection',
    AddTimeBloc = 'AddTimeBloc',
    RemoveTimeBloc = 'RemoveTimeBloc',
}
interface DaysChange {
    days: DayOfWeekDto[];
    coreHoursRowNumber: number;
}

interface TimeChange {
    type: TimeType;
    time: string;
    coreHoursRowNumber: number;
    timeRowNumber: number;
}

interface TimeAddRemove {
    coreHoursRowNumber: number;
}

interface Changes {
    type: ChangesType;
    daysChangeData?: DaysChange;
    timeChangeData?: TimeChange;
    timeAddRemoveData?: TimeAddRemove;
}

interface CoreHoursFormProps {
    data?: CreateCoreHoursRow[];
    onChange: (coreHoursList: CreateCoreHoursRow[]) => void;
}

const CoreHoursForm: React.FunctionComponent<CoreHoursFormProps> = ({ data, onChange }) => {
    const [coreHoursList, setCoreHoursList] = useState<CreateCoreHoursRow[]>(
        data ? data : [activeDaysInit]
    );
    const { t } = useTranslation();

    const isLastSectionComplete = !(
        coreHoursList[coreHoursList.length - 1].timeRange[
            coreHoursList[coreHoursList.length - 1].timeRange.length - 1
        ].endTime === undefined
    );

    const areDaysAvailable =
        coreHoursList[coreHoursList.length - 1].activeDays.length +
            getDisabledDays(coreHoursList, coreHoursList.length - 1).length <
        7;

    const handleChanges = (data: Changes) => {
        let newCoreHoursList: CreateCoreHoursRow[] = [];

        switch (data.type) {
            case ChangesType.Days:
                if (data.daysChangeData)
                    newCoreHoursList = daysChange(coreHoursList, data.daysChangeData);
                break;
            case ChangesType.Time:
                if (data.timeChangeData)
                    newCoreHoursList = timeChange(coreHoursList, data.timeChangeData);
                break;
            case ChangesType.AddSection:
                newCoreHoursList = addSection(coreHoursList);
                break;
            case ChangesType.RemoveSection:
                newCoreHoursList = removeSection(coreHoursList);
                break;
            case ChangesType.AddTimeBloc:
                if (data.timeAddRemoveData)
                    newCoreHoursList = addTimeBloc(coreHoursList, data.timeAddRemoveData);
                break;
            case ChangesType.RemoveTimeBloc:
                if (data.timeAddRemoveData)
                    newCoreHoursList = removeTimeBloc(coreHoursList, data.timeAddRemoveData);
                break;
        }

        setCoreHoursList(newCoreHoursList);
        onChange(newCoreHoursList);
    };

    return (
        <div className="CoreHoursForm">
            {coreHoursList.map((coreHours: CreateCoreHoursRow, coreHoursRowNumber) => (
                <div
                    className="core-hours-row"
                    key={generateKey('coreHoursList' + coreHoursRowNumber)}
                >
                    <CircleToggleDays
                        activeDays={coreHours.activeDays}
                        disabledDays={getDisabledDays(coreHoursList, coreHoursRowNumber)}
                        onClick={(days: DayCircle[]) =>
                            handleChanges({
                                type: ChangesType.Days,
                                daysChangeData: {
                                    days: days
                                        .filter((day) => day.isActive)
                                        .map((day) => day.id as DayOfWeekDto),
                                    coreHoursRowNumber: coreHoursRowNumber,
                                },
                            })
                        }
                        tooltips
                    />
                    <div className="time-range-wrapper">
                        {coreHours.timeRange.map((range, timeRowNumber) => (
                            <TimeRange
                                key={generateKey('TimeRange' + timeRowNumber)}
                                start={{
                                    onChange: (value) =>
                                        handleChanges({
                                            type: ChangesType.Time,
                                            timeChangeData: {
                                                type: TimeType.start,
                                                time: value,
                                                coreHoursRowNumber: coreHoursRowNumber,
                                                timeRowNumber: timeRowNumber,
                                            },
                                        }),
                                    minimumAvailableTime:
                                        timeRowNumber > 0
                                            ? minusPlus15min(
                                                  '+',
                                                  coreHours.timeRange[timeRowNumber - 1].endTime ??
                                                      '00:00'
                                              )
                                            : '00:00',
                                    maximumAvailableTime: minusPlus15min(
                                        '-',
                                        range.endTime ?? '23:45'
                                    ),
                                    selected: range.startTime,
                                    disabled: !isLastTimeRange(coreHours, timeRowNumber),
                                }}
                                end={{
                                    onChange: (value) =>
                                        handleChanges({
                                            type: ChangesType.Time,
                                            timeChangeData: {
                                                type: TimeType.end,
                                                time: value,
                                                coreHoursRowNumber: coreHoursRowNumber,
                                                timeRowNumber: timeRowNumber,
                                            },
                                        }),
                                    selected: range.endTime,
                                    minimumAvailableTime: minusPlus15min(
                                        '+',
                                        range.startTime ?? '00:00'
                                    ),
                                    disabled:
                                        coreHoursList[coreHoursRowNumber].timeRange[timeRowNumber]
                                            .startTime === undefined ||
                                        !isLastTimeRange(coreHours, timeRowNumber),
                                    midnightAvailable: true,
                                }}
                            />
                        ))}

                        <div className="cta-wrapper">
                            <div
                                className={`add disabled-${isAddTimeBlocDisabled(
                                    coreHoursList,
                                    coreHoursRowNumber
                                )}`}
                                onClick={
                                    isAddTimeBlocDisabled(coreHoursList, coreHoursRowNumber)
                                        ? undefined
                                        : () =>
                                              handleChanges({
                                                  type: ChangesType.AddTimeBloc,
                                                  timeAddRemoveData: {
                                                      coreHoursRowNumber: coreHoursRowNumber,
                                                  },
                                              })
                                }
                            >
                                <Icon iconName="AddIcon" />
                            </div>
                            {coreHoursList[coreHoursRowNumber].timeRange.length > 1 && (
                                <div
                                    className="remove"
                                    onClick={() =>
                                        handleChanges({
                                            type: ChangesType.RemoveTimeBloc,
                                            timeAddRemoveData: {
                                                coreHoursRowNumber: coreHoursRowNumber,
                                            },
                                        })
                                    }
                                >
                                    <Icon iconName="DeleteIcon" />
                                </div>
                            )}
                        </div>
                    </div>

                    {isLastRow(coreHoursList, coreHoursRowNumber) && coreHoursRowNumber !== 0 && (
                        <Button
                            className="remove-section"
                            text={t('remove_section')}
                            type="link"
                            width="hugged"
                            onClick={() => handleChanges({ type: ChangesType.RemoveSection })}
                        />
                    )}
                </div>
            ))}

            <div
                className={`add-section disabled-${!(isLastSectionComplete && areDaysAvailable)}`}
                onClick={
                    isLastSectionComplete && areDaysAvailable
                        ? () => handleChanges({ type: ChangesType.AddSection })
                        : undefined
                }
            >
                <div className="add-section-icon">
                    <Icon iconName="AddIcon" />
                </div>
            </div>
        </div>
    );
};

export default CoreHoursForm;
