import { Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetHappeningAttendeesResponseItemDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesResponseItemDto';
import { GetHappeningAttendeesSortColumnDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesSortColumnDto';
import Content from 'Components/Content';
import Skeleton from 'Components/skeleton';
import TableFilters from 'Components/table-filters/table-filters';
import { useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { DEBOUNCE_DELAY_400, SETTINGS_URL, USERS_URL } from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { initialPaginationState } from 'Models/InitialPaginationState';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tableSkeletonShape } from 'Routes/settings/skeleton-shapes';
import { HappeningService } from 'Services/HappeningService';
import { FilterStore } from 'Stores';

import './index.less';
import { renderDefaultOfficeColumn } from 'Utils/WorkplaceUtils';

const getAdvancedFilters = (): AdvancedFilter[] => [];

interface TabHappeningAttendeesProps {
    id: string;
}

const TabHappeningAttendees: React.FunctionComponent<TabHappeningAttendeesProps> = ({ id }) => {
    const { toastStore } = useStores();
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [attendees, setAttendees] = useState<GetHappeningAttendeesResponseItemDto[]>([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const advancedFilters = getAdvancedFilters();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const paginationRef = useRef(initialPaginationState);
    const history = useHistory();
    const happeningService = useService(HappeningService);

    const columns: ColumnType<GetHappeningAttendeesResponseItemDto>[] = [
        {
            title: t('attendees'),
            render: (attendees: GetHappeningAttendeesResponseItemDto) => (
                <>
                    <div>
                        {attendees.firstName} {attendees.lastName}
                    </div>
                    <div className="email">{attendees.contactInfo?.email}</div>
                </>
            ),
            key: GetHappeningAttendeesSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('Profile.job_title'),
            render: (attendees: GetHappeningAttendeesResponseItemDto) =>
                attendees.jobTitle ? attendees.jobTitle : t('n/a'),
            key: GetHappeningAttendeesSortColumnDto.JobTitle,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('default_office'),
            render: (user: GetHappeningAttendeesResponseItemDto) => renderDefaultOfficeColumn(user),
            key: GetHappeningAttendeesSortColumnDto.DefaultOffice,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
    ];

    const onRowClick = (attendees: GetHappeningAttendeesResponseItemDto) => {
        history.push(SETTINGS_URL + USERS_URL + `/${attendees.id}`);
    };

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm } = filterStoreRef.current;

        await fetchAttendees({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const fetchAttendees = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetHappeningAttendeesSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            try {
                setLoading(true);

                const request = {
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                };

                const reponse = await happeningService.getHappeningAttendees(id, request);

                setAttendees(reponse.items);

                setPagination({
                    ...params.pagination,
                    total: reponse?.totalItemCount,
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [useService]
    );

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string }) => {
            fetchAttendees({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    return (
        <Content className="TabHappeningAttendees">
            <TableFilters
                filterStore={filterStoreRef.current}
                includeSearch
                advancedFiltersDefaultValue={() => getAdvancedFilters()}
                isLoaded={!loading}
            />
            <Skeleton placeholder={tableSkeletonShape} isLoaded={!loading}>
                <Table
                    className="table-action-rows table-striped-rows"
                    dataSource={attendees}
                    columns={columns}
                    loading={loading}
                    rowKey={(attendees): string => attendees.id ?? ''}
                    pagination={pagination}
                    onChange={handleTableChange}
                    onRow={(row: GetHappeningAttendeesResponseItemDto) => ({
                        onClick: (): void => {
                            onRowClick(row);
                        },
                    })}
                />
            </Skeleton>
        </Content>
    );
};

export default TabHappeningAttendees;
