import HappeningBadge from 'Assets/Images/happening-badge.svg';
import HappeningWave from 'Assets/Images/plan-schedule-happening-wave.svg';
import { ChevronRightIcon } from 'Components/icons';
import ImagesCoworkers from 'Components/images-coworkers';
import StarNumber from 'Components/star-number';
import React from 'react';
import './card-coworkers-at-office.less';

interface CardCoworkersAtTheOfficeProps {
    day: string;
    listUrlImagesTeams: string[];
    usersCount: number;
    favCoworkers?: number;
    hasHappening?: boolean;
}

const CardCoworkersAtTheOffice: React.FunctionComponent<CardCoworkersAtTheOfficeProps> = ({
    day,
    listUrlImagesTeams,
    usersCount,
    favCoworkers = 0,
    hasHappening,
}) => {
    return (
        <div className="CardCoworkersAtTheOffice">
            {hasHappening && <img className="happening-wave" src={HappeningWave} />}

            <div className="day-container text-body capitalize">{day}</div>

            {hasHappening && <img className="happening-badge" src={HappeningBadge} />}

            <ImagesCoworkers
                imageUrls={listUrlImagesTeams}
                usersCount={usersCount}
                maxDisplayCoworkers={5}
            />

            {favCoworkers > 0 && <StarNumber number={favCoworkers} />}

            <div className={`chevron-right visible-${usersCount > 0 || hasHappening}`}>
                <ChevronRightIcon />
            </div>
        </div>
    );
};

export default React.memo(CardCoworkersAtTheOffice);
