import { ScheduleEventOfficeSpaceDto } from 'Api/Features/Schedules/Dtos/ScheduleEventOfficeSpaceDto';
import { UserDetailsOfficeDto } from 'Api/Features/Users/Dtos/UserDetailsOfficeDto';
import { UserDetailsOfficeSpaceDto } from 'Api/Features/Users/Dtos/UserDetailsOfficeSpaceDto';
import i18next from 'i18next';

export const getWorkplaceFullName = (
    office?: UserDetailsOfficeDto | null,
    officeSpace?: UserDetailsOfficeSpaceDto | null,
    desk?: string | null,
    floor?: string | null,
    isDeactivated?: boolean
): string => {
    if (!office) return '';

    const stringSections: string[] = [];
    if (office?.name) stringSections.push(office.name);
    if (officeSpace?.name) stringSections.push(officeSpace.name);
    if (floor) stringSections.push(floor);
    if (desk) stringSections.push(desk);

    if (isDeactivated) {
        return stringSections.join(' - ') + ` (${i18next.t('deactivated')})`;
    } else {
        return stringSections.join(' - ');
    }
};

export const isWorkplaceActive = (
    office: UserDetailsOfficeDto | null | undefined,
    officeSpace: ScheduleEventOfficeSpaceDto | null | undefined
): boolean => {
    if (office !== null && office !== undefined && office.isActive) return true;
    else if (office && office.isActive && (!officeSpace || officeSpace.isActive)) return true;

    return false;
};

export const renderDefaultOfficeColumn = (userDefaultOfficeDetails: {
    defaultOffice?: UserDetailsOfficeDto | null;
    defaultOfficeSpace?: UserDetailsOfficeSpaceDto | null;
    defaultFloor?: string | null;
    defaultDesk?: string | null;
}) => {
    if (userDefaultOfficeDetails.defaultOffice) {
        return getWorkplaceFullName(
            userDefaultOfficeDetails.defaultOffice,
            userDefaultOfficeDetails.defaultOfficeSpace,
            userDefaultOfficeDetails.defaultDesk,
            userDefaultOfficeDetails.defaultFloor
        );
    } else return i18next.t('n/a');
};
