import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { UserProxy } from 'Api/Features/Users/UserProxy';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { GetUsersResponseDto } from 'Api/Features/Users/Dtos/GetUsersResponseDto';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { CreateUserResponseDto } from 'Api/Features/Users/Dtos/CreateUserResponseDto';
import { UserObjectivesStatusDetailsDto } from 'Api/Features/Users/Dtos/UserObjectivesStatusDetailsDto';
import { GetUserObjectivesStatusRequestDto } from 'Api/Features/Users/Dtos/GetUserObjectivesStatusRequestDto';
import { CreateUserFavoriteUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserFavoriteUserRequestDto';
import { CreateUserFavoriteUserResponseDto } from 'Api/Features/Users/Dtos/CreateUserFavoriteUserResponseDto';
import { GetUserSlackInfoResponseDto } from 'Api/Features/Users/Dtos/GetUserSlackInfoResponseDto';
import { GetUserExternalIdentitiesResponseDto } from 'Api/Features/Users/Dtos/GetUserExternalIdentitiesResponseDto';
import { RemoveUserExternalIdentityRequestDto } from 'Api/Features/Users/Dtos/RemoveUserExternalIdentityRequestDto';

@inject(UserProxy)
export class UserService extends ApiService {
    constructor(private readonly userProxy: UserProxy) {
        super();
    }

    public async getUser(userId: string): Promise<UserDetailsDto | null> {
        const data: UserDetailsDto | null = await this.userProxy.getUser(userId);
        return data;
    }

    public async getUsers(request: GetUsersRequestDto | null): Promise<[UserDto[], number]> {
        const response: GetUsersResponseDto | null = await this.userProxy.getUsers(request);
        return [
            response?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            response?.totalItemCount ?? 0,
        ];
    }

    public async getUserObjectivesStatus(
        userId: string,
        request: GetUserObjectivesStatusRequestDto | null
    ): Promise<UserObjectivesStatusDetailsDto | null> {
        const data: UserObjectivesStatusDetailsDto | null =
            await this.userProxy.getUserObjectivesStatus(userId, request);
        return data;
    }

    public async createUser(
        request: CreateUserRequestDto | null
    ): Promise<CreateUserResponseDto | null> {
        const data: CreateUserResponseDto | null = await this.userProxy.createUser(request);
        return data;
    }

    public async updateUser(userId: string, request: UpdateUserRequestDto | null): Promise<void> {
        await this.userProxy.updateUser(userId, request);
    }

    public async deactivateUser(userId: string): Promise<void> {
        await this.userProxy.deactivateUser(userId);
    }

    public async reactivateUser(userId: string): Promise<void> {
        await this.userProxy.reactivateUser(userId);
    }

    public async resendConfirmationToken(userId: string): Promise<void> {
        await this.userProxy.resendConfirmationToken(userId);
    }

    public async createUserFavoriteUser(
        request: CreateUserFavoriteUserRequestDto
    ): Promise<string> {
        const relationId: CreateUserFavoriteUserResponseDto | null =
            await this.userProxy.createUserFavoriteUser(request);
        return relationId?.id ?? '';
    }

    public async deleteUserFavoriteUser(id: string): Promise<void> {
        await this.userProxy.deleteUserFavoriteUser(id);
    }

    public async getUserSlackInfo(userId: string): Promise<GetUserSlackInfoResponseDto | null> {
        const data: GetUserSlackInfoResponseDto | null = await this.userProxy.getUserSlackInfo(
            userId
        );
        return data;
    }

    public async getUserExternalIdentities(
        userId: string
    ): Promise<GetUserExternalIdentitiesResponseDto | null> {
        const data: GetUserExternalIdentitiesResponseDto | null =
            await this.userProxy.getUserExternalIdentities(userId);
        return data;
    }

    public async removeUserExternalIdentity(
        userId: string,
        request: RemoveUserExternalIdentityRequestDto | null
    ): Promise<void> {
        await this.userProxy.removeUserExternalIdentity(userId, request);
    }
}
