import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AlarmClockIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M6.65234 7.40527C6.78418 7.40527 6.88086 7.37891 7.0127 7.28223L10.124 5.00586C10.2646 4.90039 10.3525 4.75098 10.3525 4.60156C10.3525 4.4082 10.2559 4.24121 10.0977 4.10059C9.71973 3.78418 9.11328 3.6084 8.55078 3.6084C7.12695 3.6084 5.97559 4.7334 5.97559 6.13086C5.97559 6.49121 6.03711 6.8252 6.13379 7.04492C6.23047 7.26465 6.42383 7.40527 6.65234 7.40527ZM21.3389 7.40527C21.5674 7.40527 21.752 7.25586 21.8574 7.04492C21.9541 6.83398 22.0068 6.49121 22.0068 6.13086C22.0068 4.7334 20.8643 3.6084 19.4404 3.6084C18.8691 3.6084 18.2715 3.78418 17.8936 4.10059C17.7354 4.24121 17.6387 4.4082 17.6387 4.60156C17.6387 4.75098 17.7178 4.90039 17.8672 5.00586L20.9785 7.28223C21.1104 7.37891 21.207 7.40527 21.3389 7.40527ZM6.56445 21.9424C6.87207 22.25 7.35547 22.2412 7.66309 21.9248L9.08691 20.5098C10.4668 21.4941 12.1631 22.0742 13.9912 22.0742C15.8281 22.0742 17.5156 21.4941 18.9043 20.5098L20.3281 21.9248C20.6357 22.2412 21.1191 22.25 21.4268 21.9424C21.7168 21.6523 21.7256 21.1777 21.418 20.8789L20.0557 19.5254C21.541 17.9961 22.4639 15.9131 22.4639 13.6104C22.4639 8.93457 18.6758 5.14648 13.9912 5.14648C9.31543 5.14648 5.52734 8.93457 5.52734 13.6104C5.52734 15.9131 6.44141 17.9961 7.93555 19.5254L6.57324 20.8789C6.26562 21.1777 6.27441 21.6523 6.56445 21.9424ZM13.9912 20.3076C10.291 20.3076 7.29395 17.3105 7.29395 13.6104C7.29395 9.91016 10.291 6.91309 13.9912 6.91309C17.6914 6.91309 20.6885 9.91016 20.6885 13.6104C20.6885 17.3105 17.6914 20.3076 13.9912 20.3076ZM10.1768 14.7002H13.9912C14.3779 14.7002 14.6768 14.4014 14.6768 14.0146V8.9082C14.6768 8.52148 14.3779 8.22266 13.9912 8.22266C13.6045 8.22266 13.3057 8.52148 13.3057 8.9082V13.3291H10.1768C9.79004 13.3291 9.49121 13.6367 9.49121 14.0146C9.49121 14.4014 9.79004 14.7002 10.1768 14.7002Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AlarmClockIcon;
