import { default as React, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import DetailsPageNavigationTabs from 'Components/details-page-navigation-tabs';
import { useService, useStores } from 'Hooks';
import { TeamService } from 'Services/TeamService';
import { useHistory, useParams } from 'react-router-dom';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import CoworkersTab from './coworkers-tab';
import TeamDetailsHeader from 'Components/team-details-header';
import ManagersTab from './managers-tab';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';
import { SETTINGS_URL, TEAMS_URL } from 'Models/Constants';
import AddTeamCoworkersModal from './add-team-coworkers-modal';
import CreateTeamModal from '../create-team-modal';
import TabPolicyDetails from 'Routes/settings/policies/components/tab-details';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import Content from 'Components/Content';
import Skeleton from 'Components/skeleton';
import { TeamDetailsHeaderSkeletonShape } from './skeleton-shapes';
import TeamScheduleTab from './team-schedule-tab';

export enum TeamDetailsContext {
    Settings = 'Settings',
    MyTeams = 'MyTeams',
}

interface TeamDetailsProps {
    context: TeamDetailsContext;
}

const TeamDetails: FunctionComponent<TeamDetailsProps> = observer(({ context }) => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const urlParams = new URLSearchParams(window.location.search);
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const history = useHistory();
    const teamService = useService(TeamService);
    const policyBundleService = useService(PolicyBundleService);
    const [team, setTeam] = useState<TeamDto>();
    const [addCoworkersModalOpen, setAddCoworkersModalOpen] = useState(false);
    const [editTeamModalOpen, setEditTeamModalOpen] = useState(false);
    const [managersInteractionCausesFetch, setManagerInteractionCausesFetch] = useState(false);
    const [coworkerInteractionCausesFetch, setCoworkerInteractionCausesFetch] = useState(false);
    const [policyBundleDetails, setPolicyBundleDetails] = useState<PolicyBundleDto>();
    const [isLoading, setIsLoading] = useState({
        team: false,
        policy: false,
    });

    const fetchTeam = useCallback(async () => {
        try {
            const response = await teamService.getTeam(id);
            if (response) setTeam(response);
            setIsLoading((prev) => {
                return { ...prev, team: true };
            });
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, [globalLoadingStore, toastStore, teamService, id]);

    useEffect(() => {
        fetchTeam();
    }, [fetchTeam]);

    useEffect(() => {
        //Create team modal has an option to create team and after creation, add coworkers right away
        const addPeople = urlParams.get('addPeople');
        if (addPeople) setAddCoworkersModalOpen(true);
    }, []);

    const fetchPolicy = useCallback(async () => {
        try {
            if (team?.policyBundle?.id) {
                setIsLoading((prev) => {
                    return { ...prev, policy: true };
                });
                const response: PolicyBundleDto | null = await policyBundleService.getPolicyBundle(
                    team?.policyBundle?.id
                );

                if (response) setPolicyBundleDetails(response);
                setIsLoading((prev) => {
                    return { ...prev, policy: false };
                });
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, [PolicyBundleService, team?.policyBundle?.id]);

    useEffect(() => {
        if (team?.policyBundle?.id) {
            fetchPolicy();
        }
    }, [team?.policyBundle?.id]);

    const onEdit = () => setEditTeamModalOpen(true);
    const onAddCoworkers = () => setAddCoworkersModalOpen(true);

    const onDeleteTeam = async (): Promise<void> => {
        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                    title: t('Cta.delete_confirm_title', {
                        param1: t('Entity.lowercase_team'),
                        param2: 'cette',
                    }),
                    message: t('Cta.delete_confirm_message', {
                        param1: t('Entity.lowercase_team'),
                        param2: "L'",
                        existante: 'e',
                        supprimee: 'e',
                    }),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;
            globalLoadingStore.addLoading();
            await teamService.deleteTeam(id);
            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('team'),
                    param2: t('Entity.lowercase_action_deleted'),
                }),
            });
            history.push(SETTINGS_URL + TEAMS_URL);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="TeamDetails">
            <Skeleton placeholder={TeamDetailsHeaderSkeletonShape} isLoaded={isLoading.team}>
                <TeamDetailsHeader
                    name={team?.name}
                    actionButtons={{ onEdit, onAddCoworkers, onDeleteTeam }}
                />
            </Skeleton>

            <DetailsPageNavigationTabs defaultActiveKey="1">
                <Tabs.TabPane tab={t('coworkers')} key="1">
                    <Content>
                        <CoworkersTab
                            interactionCausesFetch={coworkerInteractionCausesFetch}
                            onFetch={() => {
                                if (coworkerInteractionCausesFetch) {
                                    setCoworkerInteractionCausesFetch(false);
                                    userStore.setUserInfo(userStore.userInfo?.id ?? '');
                                }
                            }}
                            context={context}
                        />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane tab={t('managers')} key="2">
                    <Content>
                        <ManagersTab
                            team={team}
                            interactionCausesFetch={managersInteractionCausesFetch}
                            onFetch={() => {
                                if (managersInteractionCausesFetch)
                                    setManagerInteractionCausesFetch(false);
                            }}
                            context={context}
                            fetchTeam={fetchTeam}
                        />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane tab={t('policies')} key="3">
                    <Content>
                        <TabPolicyDetails
                            data={policyBundleDetails}
                            isLoading={isLoading.policy}
                            displayName
                        />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane tab={t('team_schedule')} key="4">
                    <Content>
                        <TeamScheduleTab />
                    </Content>
                </Tabs.TabPane>
            </DetailsPageNavigationTabs>

            {editTeamModalOpen && (
                <CreateTeamModal
                    visible={editTeamModalOpen}
                    onComplete={(success: boolean) => {
                        setEditTeamModalOpen(false);
                        if (success) {
                            fetchTeam();
                            setManagerInteractionCausesFetch(true);
                        }
                    }}
                    team={team}
                />
            )}

            {addCoworkersModalOpen && (
                <AddTeamCoworkersModal
                    visible={addCoworkersModalOpen}
                    onComplete={(success: boolean) => {
                        setAddCoworkersModalOpen(false);
                        if (success) {
                            setCoworkerInteractionCausesFetch(true);
                            userStore.setUserInfo(userStore.userInfo?.id ?? '');
                        }
                    }}
                    teamId={id}
                />
            )}
        </div>
    );
});

export default TeamDetails;
