import React, { ReactElement } from 'react';
import './index.less';
import { SupportedLanguage } from 'Stores/LanguageStore';

export const InputSkeletonShape = (
    label: string,
    lang: SupportedLanguage,
    inputHeight?: number
): ReactElement => (
    <div className="InputSkeletonShape">
        <div className="ant-col ant-form-item-label">
            <div
                className={`text-caption-1 ${lang === SupportedLanguage.FR ? 'french-label' : ''}`}
            >
                <div className=" caption-medium element-light-primary">{label}</div>
            </div>
        </div>
        <div className="rect input" style={{ height: inputHeight }} />
    </div>
);
