import { GetUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserDefaultScheduleRequestDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { UpdateUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserDefaultScheduleRequestDto';
import Button from 'Components/button';
import WeeklyHourPrefCalendar, { CalendarForType } from 'Components/weekly-hour-pref-calendar';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { E004006, FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleService } from 'Services/ScheduleService';
import { toStartNextDayIfEndOfDay } from 'Utils/TimeUtils';
import { OnboardingBaseProps } from '..';
import { PolicyWrapperBoxInformations } from 'Components/policy-wrapper-box-informations';
import EditHourPrefModal from 'Routes/profile/components/edit-hour-pref-modal';
import './base-styles.less';
import './schedule.less';
import {
    DefaultScheduleEventWithCapacity,
    fetchDefaultScheduleAttendence,
} from 'Utils/CalendarUtils';
import { OfficeService } from 'Services/OfficeService';

interface ScheduleProps extends OnboardingBaseProps {
    schedule: ScheduleEventDto[];
}

const OnboardingSchedule: React.FunctionComponent<ScheduleProps> = observer(
    ({ onNextClick, schedule }) => {
        const { t } = useTranslation();
        const { policyStore, userStore, toastStore, globalLoadingStore } = useStores();
        const scheduleService = useService(ScheduleService);
        const officeService = useService(OfficeService);
        const [events, setEvents] = useState<ScheduleEventDto[]>(schedule);
        const [eventsWithCapacity, setEventsWithCapacity] = useState<
            DefaultScheduleEventWithCapacity[]
        >([]);

        const [editHoursPrefModalVisible, setEditHoursPrefModalVisible] = useState(false);

        const fetchSchedule = async () => {
            try {
                const schedule = await scheduleService.getUserDefaultSchedule(
                    userStore.userInfo!.id!,
                    {
                        week: moment().format(FORMAT_YEAR_MONTH_DAY),
                    } as GetUserDefaultScheduleRequestDto
                );

                if (schedule) {
                    const events =
                        schedule.events
                            ?.filter((event) => event !== null && event !== undefined)
                            .map((event) => event!) ?? [];
                    setEvents(events);
                    setEventsWithCapacity(events); //we can set events to render so that we dont have to wait on capacity to be able to show periods in calendar
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        };

        useEffect(() => {
            if (userStore.userInfo?.id) fetchSchedule();
        }, [userStore.userInfo?.id]);

        const fetchScheduleAttendence = async (events: ScheduleEventDto[]) => {
            setEventsWithCapacity(
                await fetchDefaultScheduleAttendence(
                    events,
                    officeService,
                    userStore.userInfo?.timeZone ?? '',
                    toastStore
                )
            );
        };

        useEffect(() => {
            if (events) {
                fetchScheduleAttendence(events);
            }
        }, [events]);

        const handleNextClick = async () => {
            try {
                globalLoadingStore.addLoading();
                const request: UpdateUserDefaultScheduleRequestDto = {
                    events: events.map((event) => ({
                        ...event,
                        endTime: toStartNextDayIfEndOfDay(true, event.endTime),
                        officeId: event.office?.id,
                        officeSpaceId: event.officeSpace?.id,
                    })),
                };

                if (userStore.userInfo?.id) {
                    await scheduleService.updateUserDefaultSchedule(userStore.userInfo.id, request);
                }

                onNextClick(events);
            } catch (e: any) {
                if (e.response?.data?.errorCode === E004006) {
                    toastStore.toast({
                        type: 'error',
                        message: e.response.data.errorDescription,
                    });
                    e.treated = true;
                }
                if (!e.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        useEffect(() => {
            policyStore.setPolicyRestrictionsAndObjectivesStats();
        }, []);

        return (
            <div className="OnboardingSchedule OnboardingBase">
                <div className="text-title-1-bold mb-20 centered pt-40">
                    {t('Onboarding.schedule_title')}
                </div>

                <div className="text-callout text-mid-contrast mb-20 centered subtitle">
                    {t('Onboarding.schedule_sutitle')}
                </div>

                <div className="body-container">
                    <div className="text-headline-bold mb-20">{t('hours_preferences')}</div>

                    <div className="objectives-container mb-20">
                        <PolicyWrapperBoxInformations
                            events={events}
                            objectives={policyStore.policyInfo?.policyBundleObjectivesDto}
                        />

                        <Button
                            className="edit-btn"
                            text={t('edit')}
                            type="primary"
                            width="hugged"
                            leftIcon="PencilIcon"
                            onClick={() => setEditHoursPrefModalVisible(true)}
                        />
                    </div>

                    <WeeklyHourPrefCalendar
                        events={eventsWithCapacity}
                        isInteractable={false}
                        calendarForType={CalendarForType.UserProfile}
                        restrictions={policyStore.policyInfo?.restrictions ?? undefined}
                    />
                </div>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button
                            text={t('confirm')}
                            type="primary"
                            onClick={() => handleNextClick()}
                        />
                    </div>
                </div>

                {editHoursPrefModalVisible && (
                    <EditHourPrefModal
                        visible={editHoursPrefModalVisible}
                        onComplete={() => {
                            //onComplete is not used in this case
                        }}
                        onboardingOnComplete={(hasChanges, events) => {
                            if (hasChanges) {
                                setEvents(events ?? []);
                            }
                            setEditHoursPrefModalVisible(false);
                        }}
                        schedule={eventsWithCapacity}
                        calendarForType={CalendarForType.UserProfile}
                    />
                )}
            </div>
        );
    }
);

export default OnboardingSchedule;
