import { CreatePolicyBundleRequestDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleRequestDto';
import { CreatePolicyBundleResponseDto } from 'Api/Features/Policies/Dtos/CreatePolicyBundleResponseDto';
import { GetPolicyBundlesRequestDto } from 'Api/Features/Policies/Dtos/GetPolicyBundlesRequestDto';
import { GetPolicyBundlesResponseDto } from 'Api/Features/Policies/Dtos/GetPolicyBundlesResponseDto';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { UpdatePolicyBundleRequestDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleRequestDto';
import { UpdatePolicyBundleTeamsRequestDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleTeamsRequestDto';
import { PolicyBundleProxy } from 'Api/Features/Policies/PolicyBundleProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(PolicyBundleProxy)
export class PolicyBundleService extends ApiService {
    constructor(private readonly policyBundleProxy: PolicyBundleProxy) {
        super();
    }

    public async getPolicyBundle(bundleId: string): Promise<PolicyBundleDto | null> {
        const data: PolicyBundleDto | null = await this.policyBundleProxy.getPolicyBundle(bundleId);
        return data;
    }

    public async getPolicyBundles(
        request: GetPolicyBundlesRequestDto | null
    ): Promise<[PolicyBundleDto[], number]> {
        const response: GetPolicyBundlesResponseDto | null =
            await this.policyBundleProxy.getPolicyBundles(request);
        return [
            response?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            response?.totalItemCount ?? 0,
        ];
    }

    public async createPolicyBundle(
        request: CreatePolicyBundleRequestDto | null
    ): Promise<CreatePolicyBundleResponseDto | null> {
        const data: CreatePolicyBundleResponseDto | null =
            await this.policyBundleProxy.createPolicyBundle(request);
        return data;
    }

    public async updatePolicyBundle(
        bundleId: string,
        request: UpdatePolicyBundleRequestDto | null
    ): Promise<void> {
        await this.policyBundleProxy.updatePolicyBundle(bundleId, request);
    }

    public async updatePolicyBundleTeams(
        bundleId: string,
        request: UpdatePolicyBundleTeamsRequestDto | null
    ): Promise<void> {
        await this.policyBundleProxy.updatePolicyBundleTeams(bundleId, request);
    }

    public async deletePolicyBundle(bundleId: string): Promise<void> {
        await this.policyBundleProxy.deletePolicyBundle(bundleId);
    }
}
