import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const EnvelopeIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M7.0127 21.3887H21.1895C22.8857 21.3887 23.8701 20.4043 23.8701 18.5234V8.81152C23.8701 6.93066 22.877 5.94629 20.9785 5.94629H6.80176C5.10547 5.94629 4.12109 6.92188 4.12109 8.81152V18.5234C4.12109 20.4043 5.11426 21.3887 7.0127 21.3887ZM13.0068 13.9707L6.6084 7.64258C6.73145 7.61621 6.87207 7.59863 7.02148 7.59863H20.9697C21.1279 7.59863 21.2686 7.61621 21.4004 7.65137L15.0107 13.9707C14.6416 14.3311 14.334 14.4893 14.0088 14.4893C13.6836 14.4893 13.376 14.3223 13.0068 13.9707ZM5.76465 8.93457L10.5459 13.6367L5.76465 18.3652V8.93457ZM17.4717 13.6367L22.2266 8.96094V18.3477L17.4717 13.6367ZM7.02148 19.7275C6.86328 19.7275 6.71387 19.71 6.58203 19.6836L11.6182 14.6914L12.0928 15.166C12.7344 15.79 13.3496 16.0537 14.0088 16.0537C14.6592 16.0537 15.2832 15.79 15.916 15.166L16.3994 14.6914L21.4268 19.6748C21.2861 19.71 21.1367 19.7275 20.9697 19.7275H7.02148Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default EnvelopeIcon;
