import { Divider, Popover } from 'antd';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import { SideNavigationContext } from 'Components/authenticated-layout/authenticated-layout';
import { ChevronDownIcon, ChevronLeftIcon, PersonIcon, PowerIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import Skeleton from 'Components/skeleton';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { PROFILE_URL, SIDENAV_WIDTH_COLLAPSED, SIDENAV_WIDTH_OPEN } from 'Models/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import { img, ImgSize } from 'Utils/ImgUtils';
import './section-header.less';
import { sectionHeaderShapes } from './skeleton-shapes';

export interface HeaderBackButton {
    url: string;
    title: string;
}

interface ListSectionHeaderProps {
    title: string;
    backButton?: HeaderBackButton;
    onHandleDisplayModeClick: () => void;
}

const SectionHeader: React.FunctionComponent<ListSectionHeaderProps> = observer(
    ({ title, backButton, onHandleDisplayModeClick }) => {
        const sideNavContext = useContext(SideNavigationContext);
        const { userStore, authenticationStore } = useStores();
        const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
        const { t } = useTranslation();
        const history = useHistory();
        const [popOverVisible, setPopOverVisible] = useState(false);
        const [marginLeft, setMarginLeft] = useState<number | undefined>();

        const logOut = async (): Promise<void> => {
            authenticationStore.clearSession();
            await azureAdAuthenticationService.signOut();
        };

        useEffect(() => {
            if (sideNavContext?.isAutoCollapseThreshold) {
                setMarginLeft(
                    sideNavContext?.collapsed
                        ? undefined
                        : SIDENAV_WIDTH_OPEN - SIDENAV_WIDTH_COLLAPSED
                );
            } else {
                setMarginLeft(undefined);
            }
        }, [sideNavContext?.collapsed, sideNavContext?.isAutoCollapseThreshold]);

        const popOverContent = (
            <>
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        history.push(PROFILE_URL);
                        setPopOverVisible(false);
                    }}
                >
                    <PersonIcon />
                    {t('my_profile')}
                </div>
                <Divider />
                <div className="item text-body text-high-contrast" onClick={logOut}>
                    <PowerIcon />
                    {t('sign_out')}
                </div>
            </>
        );

        return (
            <div className="SectionHeader" style={{ marginLeft }}>
                <div className="container-icon-menu-burger">
                    <Icon iconName="MenuIcon" onClick={onHandleDisplayModeClick} />
                </div>

                {backButton ? (
                    <div
                        className="back-btn text-callout-bold"
                        onClick={() => history.push(backButton.url)}
                    >
                        <ChevronLeftIcon />
                        {backButton.title}
                    </div>
                ) : (
                    <div className="header-info">
                        {title && <div className="text-title-3 text-high-contrast">{title}</div>}
                    </div>
                )}

                <div className="right-section">
                    <Divider className="divider-full-height" type="vertical" />

                    <Skeleton
                        isLoaded={userStore.userInfo?.id !== undefined}
                        placeholder={sectionHeaderShapes}
                    >
                        <div className="contact-card">
                            <div
                                className="img-container"
                                style={{
                                    backgroundImage: `url(${
                                        userStore.userInfo?.imageUrl
                                            ? img(userStore.userInfo.imageUrl, ImgSize.s)
                                            : NoProfilePicture
                                    })`,
                                }}
                            />

                            <Popover
                                content={popOverContent}
                                trigger="click"
                                visible={popOverVisible}
                                onVisibleChange={(visible: boolean) => setPopOverVisible(visible)}
                                placement="bottomRight"
                                overlayClassName="header-pop-over"
                            >
                                <div className="info-container">
                                    <div className="info">
                                        <div className="text-callout-bold text-high-contrast">
                                            {userStore.userInfo?.firstName}{' '}
                                            {userStore.userInfo?.lastName}
                                        </div>
                                        <div className="text-caption-2 text-mid-contrast">
                                            {userStore.userInfo?.contactInfo?.email}
                                        </div>
                                    </div>
                                    <ChevronDownIcon width={24} height={24} />
                                </div>
                            </Popover>
                        </div>
                    </Skeleton>
                </div>
            </div>
        );
    }
);

export default SectionHeader;
