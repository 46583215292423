// GENERATED FILE - DO NOT MODIFY
import { CreateTeamRequestDto } from 'Api/Features/Teams/Dtos/CreateTeamRequestDto';
import { CreateTeamResponseDto } from 'Api/Features/Teams/Dtos/CreateTeamResponseDto';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { GetTeamsResponseDto } from 'Api/Features/Teams/Dtos/GetTeamsResponseDto';
import { TeamDetailsDto } from 'Api/Features/Teams/Dtos/TeamDetailsDto';
import { UpdateTeamMembersRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersRequestDto';
import { UpdateTeamRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class TeamProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getTeam(teamId: string): Promise<TeamDetailsDto | null> {
        const uri = this.buildUri(
            "/teams/{teamId}",
            { teamId: teamId },
            null
        );

        const data: TeamDetailsDto | null = await this.httpClient.get<TeamDetailsDto | null>(uri);
        return data;
    }

    public async getTeams(request: GetTeamsRequestDto | null): Promise<GetTeamsResponseDto | null> {
        const uri = this.buildUri(
            "/teams",
            null,
            { ...request || undefined }
        );

        const data: GetTeamsResponseDto | null = await this.httpClient.get<GetTeamsResponseDto | null>(uri);
        return data;
    }

    public async createTeam(request: CreateTeamRequestDto | null): Promise<CreateTeamResponseDto | null> {
        const uri = this.buildUri(
            "/teams",
            null,
            null
        );

        const data: CreateTeamResponseDto | null = await this.httpClient.post<CreateTeamRequestDto | null, CreateTeamResponseDto | null>(uri, request);
        return data;
    }

    public async updateTeam(teamId: string, request: UpdateTeamRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/teams/{teamId}",
            { teamId: teamId },
            null
        );

        await this.httpClient.patch<UpdateTeamRequestDto | null, void>(uri, request);
    }

    public async updateTeamMembers(teamId: string, request: UpdateTeamMembersRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/teams/{teamId}/update-members",
            { teamId: teamId },
            null
        );

        await this.httpClient.post<UpdateTeamMembersRequestDto | null, void>(uri, request);
    }

    public async deleteTeam(teamId: string): Promise<void> {
        const uri = this.buildUri(
            "/teams/{teamId}",
            { teamId: teamId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }
}