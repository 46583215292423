// GENERATED FILE - DO NOT MODIFY
import { CreateHappeningRequestDto } from 'Api/Features/Happenings/Dtos/CreateHappeningRequestDto';
import { CreateHappeningResponseDto } from 'Api/Features/Happenings/Dtos/CreateHappeningResponseDto';
import { GetHappeningAttendeesRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesRequestDto';
import { GetHappeningAttendeesResponseDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesResponseDto';
import { GetHappeningRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningRequestDto';
import { GetHappeningsRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningsRequestDto';
import { GetHappeningsResponseDto } from 'Api/Features/Happenings/Dtos/GetHappeningsResponseDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { PostHappeningToSlackRequestDto } from 'Api/Features/Happenings/Dtos/PostHappeningToSlackRequestDto';
import { UpdateHappeningAttendanceRequestDto } from 'Api/Features/Happenings/Dtos/UpdateHappeningAttendanceRequestDto';
import { UpdateHappeningRequestDto } from 'Api/Features/Happenings/Dtos/UpdateHappeningRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class HappeningProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getHappening(happeningId: string, request: GetHappeningRequestDto | null): Promise<HappeningDto | null> {
        const uri = this.buildUri(
            "/happenings/{happeningId}",
            { happeningId: happeningId },
            { ...request || undefined }
        );

        const data: HappeningDto | null = await this.httpClient.get<HappeningDto | null>(uri);
        return data;
    }

    public async getHappenings(request: GetHappeningsRequestDto | null): Promise<GetHappeningsResponseDto | null> {
        const uri = this.buildUri(
            "/happenings",
            null,
            { ...request || undefined }
        );

        const data: GetHappeningsResponseDto | null = await this.httpClient.get<GetHappeningsResponseDto | null>(uri);
        return data;
    }

    public async getHappeningAttendees(happeningId: string, request: GetHappeningAttendeesRequestDto | null): Promise<GetHappeningAttendeesResponseDto | null> {
        const uri = this.buildUri(
            "/happenings/{happeningId}/attendees",
            { happeningId: happeningId },
            { ...request || undefined }
        );

        const data: GetHappeningAttendeesResponseDto | null = await this.httpClient.get<GetHappeningAttendeesResponseDto | null>(uri);
        return data;
    }

    public async createHappening(request: CreateHappeningRequestDto | null): Promise<CreateHappeningResponseDto | null> {
        const uri = this.buildUri(
            "/happenings",
            null,
            null
        );

        const data: CreateHappeningResponseDto | null = await this.httpClient.post<CreateHappeningRequestDto | null, CreateHappeningResponseDto | null>(uri, request);
        return data;
    }

    public async updateHappening(happeningId: string, request: UpdateHappeningRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/happenings/{happeningId}",
            { happeningId: happeningId },
            null
        );

        await this.httpClient.patch<UpdateHappeningRequestDto | null, void>(uri, request);
    }

    public async deleteHappening(happeningId: string): Promise<void> {
        const uri = this.buildUri(
            "/happenings/{happeningId}",
            { happeningId: happeningId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateHappeningAttendance(happeningId: string, request: UpdateHappeningAttendanceRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/happenings/{happeningId}/update-attendance",
            { happeningId: happeningId },
            null
        );

        await this.httpClient.post<UpdateHappeningAttendanceRequestDto | null, void>(uri, request);
    }

    public async postHappeningToSlack(happeningId: string, request: PostHappeningToSlackRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/happenings/{happeningId}/post-to-slack",
            { happeningId: happeningId },
            null
        );

        await this.httpClient.post<PostHappeningToSlackRequestDto | null, void>(uri, request);
    }
}