import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LaptopAndPhoneIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M2.47754 21.0283H24.6084C25.8301 21.0283 26.6387 20.2461 26.6387 19.042V10.4814C26.6387 9.27734 25.8301 8.49512 24.6084 8.49512H24.3008V8.40723C24.3008 7.02734 23.5449 6.29785 22.1738 6.29785H6.45898C5.14062 6.29785 4.33203 7.02734 4.33203 8.40723V18.7695H2.47754C1.8623 18.7695 1.35254 19.2793 1.35254 19.9033C1.35254 20.5186 1.8623 21.0283 2.47754 21.0283ZM18.5352 10.4814V18.7695H6.08105V8.87305C6.08105 8.31055 6.35352 8.04688 6.90723 8.04688H21.7256C22.1299 8.04688 22.3848 8.19629 22.4902 8.49512H20.6006C19.3613 8.49512 18.5352 9.27734 18.5352 10.4814ZM19.9326 10.6924C19.9326 10.1914 20.249 9.89258 20.7764 9.89258H20.8994V10.2441C20.8994 10.5518 21.1016 10.7539 21.4004 10.7539H23.7822C24.0898 10.7539 24.2832 10.5518 24.2832 10.2441V9.89258H24.3975C24.9248 9.89258 25.2412 10.2002 25.2412 10.7012V18.8311C25.2412 19.332 24.9248 19.6309 24.3975 19.6309H20.7764C20.249 19.6309 19.9326 19.332 19.9326 18.8311V10.6924ZM21.251 19.0508H23.9316C24.1338 19.0508 24.2832 18.9102 24.2832 18.708C24.2832 18.4971 24.1338 18.3564 23.9316 18.3564H21.251C21.0488 18.3564 20.8994 18.4971 20.8994 18.708C20.8994 18.9102 21.0488 19.0508 21.251 19.0508Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LaptopAndPhoneIcon;
