import { GetTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetTeamDefaultScheduleRequestDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import Button from 'Components/button';
import WeeklyHourPrefCalendar, { CalendarForType } from 'Components/weekly-hour-pref-calendar';
import WhiteCard from 'Components/white-card';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditHourPrefModal from 'Routes/profile/components/edit-hour-pref-modal';
import { ScheduleService } from 'Services/ScheduleService';
import './team-schedule-tab.less';
import { PolicyWrapperBoxInformations } from 'Components/policy-wrapper-box-informations';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import { TeamService } from 'Services/TeamService';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';

const TeamScheduleTab: React.FunctionComponent = observer(() => {
    const { id } = useParams<{ id: string }>();
    const scheduleService = useService(ScheduleService);
    const policyService = useService(PolicyBundleService);
    const teamService = useService(TeamService);
    const { t } = useTranslation();
    const { toastStore } = useStores();

    const [team, setTeam] = useState<TeamDto>();
    const [teamPolicy, setTeamPolicy] = useState<PolicyBundleDto>();

    const [events, setEvents] = useState<ScheduleEventDto[]>([]);
    const [editHoursPrefModalVisible, setEditHoursPrefModalVisible] = useState(false);

    const fetchTeamSchedule = useCallback(async () => {
        try {
            const request: GetTeamDefaultScheduleRequestDto = {
                week: moment().format(FORMAT_YEAR_MONTH_DAY),
            };
            const response = await scheduleService.getTeamDefaultSchedule(id, request);

            if (response)
                setEvents(
                    response.events
                        ?.filter((event) => event !== null && event !== undefined)
                        .map((event) => event!) ?? []
                );
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, [scheduleService, id]);

    useEffect(() => {
        fetchTeamSchedule();
    }, [id]);

    const fetchTeam = useCallback(async () => {
        try {
            const response = await teamService.getTeam(id);

            if (response) setTeam(response);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, [teamService, id]);

    useEffect(() => {
        fetchTeam();
    }, [id]);

    const fetchTeamPolicy = useCallback(async (policyId: string) => {
        try {
            const response = await policyService.getPolicyBundle(policyId);
            if (response) setTeamPolicy(response);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, []);

    useEffect(() => {
        if (team?.policyBundle?.id) fetchTeamPolicy(team.policyBundle.id);
    }, [team?.policyBundle?.id]);

    return (
        <div className="TeamScheduleTab">
            <div className="mb-20">
                <div className="text-headline-bold mb-10">{t('team_schedule')}</div>
                <div className="text-callout text-mid-contrast">
                    {t('team_schedule_explanation')}
                </div>
            </div>

            <WhiteCard paddingTop={40} paddingBottom={20} paddingLeft={20} paddingRight={20}>
                <div className="top-container mb-30">
                    <PolicyWrapperBoxInformations
                        events={events}
                        objectives={teamPolicy?.objectives}
                        forSpecificTeamSchedule={true}
                    />
                    <Button
                        className="edit-btn"
                        text={t('edit')}
                        type="primary"
                        width="hugged"
                        leftIcon="PencilIcon"
                        onClick={() => setEditHoursPrefModalVisible(true)}
                    />
                </div>

                <WeeklyHourPrefCalendar
                    events={events}
                    isInteractable={false}
                    calendarForType={CalendarForType.TeamSchedule}
                    teamScheduleCoreHoursGroups={teamPolicy?.objectives?.coreHoursGroups}
                    restrictions={teamPolicy?.restrictions ?? undefined}
                    roundedBorders={true}
                />
            </WhiteCard>

            {editHoursPrefModalVisible && (
                <EditHourPrefModal
                    visible={editHoursPrefModalVisible}
                    onComplete={(success) => {
                        setEditHoursPrefModalVisible(false);
                        if (success) fetchTeamSchedule();
                    }}
                    schedule={events}
                    calendarForType={CalendarForType.TeamSchedule}
                    schedulePreferenceTeamId={id}
                    teamSchedulePolicy={teamPolicy}
                />
            )}
        </div>
    );
});

export default TeamScheduleTab;
