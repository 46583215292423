import { Divider, Layout, Menu, Tooltip } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import FlexyLogoSmall from 'Assets/Images/logo-color-small.svg';
import FlexyLogo from 'Assets/Images/logo-color.svg';
import { SideNavigationContext } from 'Components/authenticated-layout/authenticated-layout';
import Button from 'Components/button';
import Icon from 'Components/icons/Icon';
import Skeleton from 'Components/skeleton';
import { useStores, useWindowDimensions } from 'Hooks';
import { observer } from 'mobx-react';
import {
    COWORKERS_URL,
    COWORKERS_URL_REGEX,
    EVENTS_URL,
    EVENTS_URL_REGEX,
    PLAN_SCHEDULE_URL,
    SETTINGS_URL,
    SETTINGS_URL_REGEX,
    STATISTICS_REAL_ESTATE_URL,
    STATISTICS_REAL_ESTATE_URL_REGEX,
    TEAMS_URL,
    TEAMS_URL_REGEX,
    DASHBOARD_URL,
    DASHBOARD_URL_REGEX,
    FORMAT_YEAR_MONTH_DAY,
    CLUBS_URL,
    CLUBS_URL_REGEX,
    SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD,
    SIDENAV_WIDTH_COLLAPSED,
    SIDENAV_WIDTH_OPEN,
    PLAN_SCHEDULE_URL_REGEX,
} from 'Models/Constants';
import moment from 'moment';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { theme } from 'Style/theme';
import './side-navigation.less';
import { sideNavigationShape, sideNavigationShapeSmall } from './side-skeleton-shapes';

const { Sider } = Layout;

const SideNavigation: React.FunctionComponent = observer(() => {
    const sideNavContext = useContext(SideNavigationContext);
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { userStore } = useStores();
    const [openSubMenuKey, setOpenSubMenuKey] = useState<string>('');
    const { windowWidth } = useWindowDimensions();

    const onSelectItem = (param: MenuInfo): void => {
        history.push(`${param.key}`);
    };

    const selectedKeys = useCallback((): string[] => {
        const keys: string[] = [];

        if (DASHBOARD_URL_REGEX.test(location.pathname)) {
            keys.push(DASHBOARD_URL);
        }
        if (COWORKERS_URL_REGEX.test(location.pathname)) {
            keys.push(COWORKERS_URL);
        }
        if (CLUBS_URL_REGEX.test(location.pathname)) {
            keys.push(CLUBS_URL);
        }
        if (PLAN_SCHEDULE_URL_REGEX.test(location.pathname)) {
            keys.push(PLAN_SCHEDULE_URL);
        }
        if (EVENTS_URL_REGEX.test(location.pathname)) {
            keys.push(EVENTS_URL);
        }
        if (TEAMS_URL_REGEX.test(location.pathname)) {
            keys.push(TEAMS_URL);
            keys.push(SUBMENU_KEYS.admin);
        }
        if (SETTINGS_URL_REGEX.test(location.pathname)) {
            keys.push(SETTINGS_URL);
            keys.push(SUBMENU_KEYS.admin);
        }
        if (STATISTICS_REAL_ESTATE_URL_REGEX.test(location.pathname)) {
            keys.push(STATISTICS_REAL_ESTATE_URL);
            keys.push(SUBMENU_KEYS.reports);
        }
        return keys;
    }, [location.pathname]);

    const onSubMenuClick = (key: string) => {
        setOpenSubMenuKey((state) => {
            if (state === key) return '';
            else return key;
        });
    };

    useEffect(() => {
        if (TEAMS_URL_REGEX.test(location.pathname)) {
            setOpenSubMenuKey(SUBMENU_KEYS.admin);
        } else if (SETTINGS_URL_REGEX.test(location.pathname)) {
            setOpenSubMenuKey(SUBMENU_KEYS.admin);
        } else if (STATISTICS_REAL_ESTATE_URL_REGEX.test(location.pathname)) {
            setOpenSubMenuKey(SUBMENU_KEYS.reports);
        } else {
            setOpenSubMenuKey('');
        }
    }, [location.pathname]);

    const onClickScheduleBtn = () =>
        history.push(
            PLAN_SCHEDULE_URL + `?date=${moment().add(1, 'week').format(FORMAT_YEAR_MONTH_DAY)}`
        );

    // CONSTANT
    const WIDTH_ICON_MENU = 24;
    const HEIGHT_ICON_MENU = 24;
    const FILL_IS_CURRENT = theme['white'];
    const PROPS_ICON_CURRENT = {
        width: WIDTH_ICON_MENU,
        height: HEIGHT_ICON_MENU,
        fill: FILL_IS_CURRENT,
    };
    const INLINE_INDENT = 20;
    const INLINE_INDENT_SMALL = (SIDENAV_WIDTH_COLLAPSED - WIDTH_ICON_MENU) / 2;
    const SUBMENU_KEYS = {
        admin: 'administration-submenu',
        reports: 'reports-submenu',
    };

    const getIcon = (iconName: string, label: string): ReactElement =>
        sideNavContext?.collapsed ? (
            <Tooltip title={label} placement="right">
                <Icon iconName={iconName} {...PROPS_ICON_CURRENT} />
            </Tooltip>
        ) : (
            <Icon iconName={iconName} {...PROPS_ICON_CURRENT} />
        );

    const OPTIONS_MENU = [
        {
            currentIcon: getIcon('HouseIcon', t('SideNav.nav_dashboard')),
            label: t('SideNav.nav_dashboard'),
            url: DASHBOARD_URL,
        },
        {
            currentIcon: getIcon('CalendarIcon', t('SideNav.nav_schedule')),
            label: t('SideNav.nav_schedule'),
            url: PLAN_SCHEDULE_URL,
        },
        {
            currentIcon: getIcon('PeopleIcon', t('SideNav.nav_coworkers')),
            label: t('SideNav.nav_coworkers'),
            url: COWORKERS_URL,
        },
        {
            currentIcon: getIcon('People3Icon', t('SideNav.nav_clubs')),
            label: t('SideNav.nav_clubs'),
            url: CLUBS_URL,
        },
        {
            currentIcon: getIcon('CalendarStarIcon', t('SideNav.nav_events')),
            label: t('SideNav.nav_events'),
            url: EVENTS_URL,
        },
        {
            icon: getIcon('GearIcon', t('SideNav.nav_administration')),
            isAllow: () => {
                return (
                    userStore.userInfo?.managementRoles &&
                    userStore.userInfo?.managementRoles.length > 0
                );
            },
            isSubMenu: true,
            key: SUBMENU_KEYS.admin,
            label: t('SideNav.nav_administration'),
            onTitleClick: (event: any) => onSubMenuClick(event.key),
            items: [
                {
                    label: t('SideNav.nav_teams'),
                    url: TEAMS_URL,
                    isAllow: () => {
                        return (
                            userStore.userInfo?.managementRoles &&
                            userStore.userInfo?.managementRoles.length > 0
                        );
                    },
                    icon: getIcon('PeopleFill3Icon', t('SideNav.nav_teams')),
                },
                {
                    label: t('SideNav.nav_settings'),
                    url: SETTINGS_URL,
                    isAllow: () => {
                        return (
                            userStore?.userInfo?.managementRoles &&
                            userStore.userInfo.managementRoles[0]?.name ===
                                ManagementRoleDto.Administrator
                        );
                    },
                    icon: getIcon('GearIcon', t('SideNav.nav_settings')),
                },
            ],
        },
        {
            icon: getIcon('Statistics', t('SideNav.nav_reports')),
            isAllow: () => {
                return (
                    userStore.userInfo?.managementRoles &&
                    userStore.userInfo?.managementRoles.length > 0
                );
            },
            isSubMenu: true,
            key: SUBMENU_KEYS.reports,
            label: t('SideNav.nav_reports'),
            onTitleClick: (event: any) => onSubMenuClick(event.key),
            items: [
                {
                    label: t('SideNav.nav_real_estate_statistics'),
                    url: STATISTICS_REAL_ESTATE_URL,
                    isAllow: () => {
                        return (
                            userStore.userInfo?.managementRoles &&
                            userStore.userInfo.managementRoles.length > 0
                        );
                    },
                    icon: getIcon('Statistics', t('SideNav.nav_real_estate_statistics')),
                },
            ],
        },
    ];

    return (
        <Sider
            className={`SideNavigation collapsed-${sideNavContext?.collapsed} ${
                windowWidth <= SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD ? 'small-screen-threshold' : ''
            }`}
            width={sideNavContext?.collapsed ? SIDENAV_WIDTH_COLLAPSED : SIDENAV_WIDTH_OPEN}
        >
            <div
                className={`logo-container`}
                onClick={() =>
                    selectedKeys().includes(DASHBOARD_URL)
                        ? history.go(0)
                        : history.push(DASHBOARD_URL)
                }
            >
                <img
                    className="logo"
                    src={sideNavContext?.collapsed ? FlexyLogoSmall : FlexyLogo}
                    alt="logo"
                />
            </div>

            <Divider />

            <Skeleton
                isLoaded={userStore.userInfo?.id !== undefined}
                placeholder={
                    sideNavContext?.collapsed ? sideNavigationShapeSmall : sideNavigationShape
                }
            >
                <Menu
                    theme="light"
                    mode="inline"
                    onSelect={onSelectItem}
                    onClick={onSelectItem}
                    selectedKeys={selectedKeys()}
                    openKeys={[openSubMenuKey]}
                    inlineIndent={sideNavContext?.collapsed ? INLINE_INDENT : INLINE_INDENT_SMALL}
                >
                    {OPTIONS_MENU.map((option) => {
                        if (option.isSubMenu) {
                            if (option.isSubMenu && option.isAllow()) {
                                if (!sideNavContext?.collapsed) {
                                    return (
                                        <SubMenu
                                            key={option.key}
                                            icon={option.icon}
                                            title={option.label}
                                            onTitleClick={option.onTitleClick}
                                            className="text-body"
                                        >
                                            {option?.items?.map((item) => {
                                                if (!item.isAllow()) return;

                                                return (
                                                    <Menu.Item key={item.url} className="text-body">
                                                        {!sideNavContext?.collapsed && item.label}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </SubMenu>
                                    );
                                } else {
                                    return option?.items?.map((item) => {
                                        if (!item.isAllow()) return;

                                        return (
                                            <Menu.Item
                                                key={item.url}
                                                className="text-body"
                                                icon={item.icon}
                                            >
                                                {!sideNavContext?.collapsed && item.label}
                                            </Menu.Item>
                                        );
                                    });
                                }
                            } else {
                                return null;
                            }
                        } else {
                            return (
                                <Menu.Item
                                    key={option.url}
                                    className="text-body"
                                    icon={option.currentIcon}
                                >
                                    {option.label}
                                </Menu.Item>
                            );
                        }
                    })}
                </Menu>
            </Skeleton>

            {location.pathname !== PLAN_SCHEDULE_URL && !sideNavContext?.collapsed && (
                <Button
                    className="schedule-btn"
                    text={t('SideNav.nav_schedule_next_week')}
                    type="tertiary"
                    width="auto"
                    onClick={onClickScheduleBtn}
                />
            )}
            {location.pathname !== PLAN_SCHEDULE_URL && sideNavContext?.collapsed && (
                <div className="schedule-btn small text-body" onClick={onClickScheduleBtn}>
                    <Tooltip title={t('SideNav.nav_schedule_next_week')} placement="right">
                        <Icon
                            iconName="CalendarPencilIcon"
                            width={24}
                            height={24}
                            fill={theme['layout-mid-main']}
                        />
                    </Tooltip>
                </div>
            )}
        </Sider>
    );
});

export default SideNavigation;
