import { MagnifyingGlassIcon, SpaceViewerIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { Input } from 'antd';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './space-viewer-modal-content.less';
import SpaceViewerTag, { SpaceViewerTagType } from './space-viewer-tag';
import { useService, useStores, useFetchQueue, useDebounce } from 'Hooks';
import { OfficeService } from 'Services/OfficeService';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import SpaceViewerCard from './space-viewer-card';
import Skeleton from 'Components/skeleton';
import { spaceViewerInitialSkeletonShape } from './space-viewer-skeleton-shapes';
import { observer } from 'mobx-react';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { PAGE_SIZE_INFINITE_LOADING } from 'Models/Constants';

interface SpaceViewerModalContentProps {
    officeId: string;
    timeRange: {
        startTime: string;
        endTime: string;
    };
    selectedSpace?: SingleSelectCustomOption;
    setSelectedSpace: (space: SingleSelectCustomOption | undefined) => void;
    scheduleType: SpaceViewerScheduleType;
}

export enum SpaceViewerScheduleType {
    defaultSchedule = 'defaultSchedule',
    schedule = 'schedule',
}

const SpaceViewerModalContent: FunctionComponent<SpaceViewerModalContentProps> = observer(
    ({ officeId, timeRange, selectedSpace, setSelectedSpace, scheduleType }) => {
        const { t } = useTranslation();
        const { toastStore } = useStores();
        const officeService = useService(OfficeService);

        const [spaces, setSpaces] = useState<OfficeSpaceDto[]>([]);
        const [filteredOutSpaces, setFilteredOutSpaces] = useState<string[]>([]);
        const [spacesLoading, setSpacesLoading] = useState(false);

        const [searchTerm, setSearchTerm] = useState<string>();
        const debounceSearch = useDebounce(searchTerm);
        const [noSpaces, setNoSpaces] = useState(false);
        const [noResults, setNoResults] = useState(false);

        const [addToFetchQueue, emptyQueue] = useFetchQueue();

        const fetchSpaces = useCallback(
            async (page: number, accumulatedReponse: OfficeSpaceDto[]) => {
                try {
                    setSpacesLoading(true);
                    const request: GetOfficeSpacesRequestDto = {
                        officeIds: [officeId],
                        page: page,
                        pageSize: PAGE_SIZE_INFINITE_LOADING,
                    };
                    const [spaces, totalItemCount] = await officeService.getOfficeSpaces(request);

                    if (spaces) {
                        setNoSpaces(totalItemCount === 0);
                        if (spaces.length + page * PAGE_SIZE_INFINITE_LOADING < totalItemCount) {
                            fetchSpaces(page + 1, [...accumulatedReponse, ...spaces]);
                        } else {
                            setSpaces([...accumulatedReponse, ...spaces]);
                        }
                    }
                } catch (err: any) {
                    if (!err.treated) toastStore.genericError();
                } finally {
                    setSpacesLoading(false);
                }
            },
            []
        );

        useEffect(() => {
            fetchSpaces(0, []);
        }, []);

        useEffect(() => {
            //clear the fetch queue on unmount
            return () => {
                emptyQueue();
            };
        }, []);

        useEffect(() => {
            if (debounceSearch && debounceSearch !== '') {
                const filteredOut = spaces
                    .filter((space) => !space.name?.toLowerCase()?.includes(debounceSearch))
                    .map((space) => space.id ?? '');
                setFilteredOutSpaces(filteredOut);
                setNoResults(filteredOut.length === spaces.length);
            } else {
                setFilteredOutSpaces([]);
                setNoResults(false);
            }
        }, [debounceSearch]);

        return (
            <div className="SpaceViewerModalContent">
                <div className="sticky-section">
                    <div className="top-section">
                        <Input
                            className="search-bar"
                            placeholder={t('search')}
                            prefix={<MagnifyingGlassIcon fill={theme['primary-mid-contrast']} />}
                            onChange={(value) => {
                                setSearchTerm(value.target.value);
                            }}
                        />
                    </div>
                </div>

                <div
                    className="dont-assign-btn-container cursor-pointer"
                    onClick={() => setSelectedSpace(undefined)}
                >
                    <div
                        className={`dont-assign-btn ${
                            selectedSpace?.value === undefined ? 'selected' : ''
                        }`}
                    >
                        <span className="text-body">{t('SpaceViewer.dont_assign_space')}</span>
                        <div className="ml-auto">
                            {selectedSpace?.value === undefined && (
                                <SpaceViewerTag type={SpaceViewerTagType.selected} />
                            )}

                            <SpaceViewerIcon
                                fill={
                                    selectedSpace?.value === undefined
                                        ? theme['layout-mid-main']
                                        : theme['text-disabled']
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="space-viewer-outer-container">
                    <div className="space-viewer-container">
                        <Skeleton
                            isLoaded={!spacesLoading}
                            placeholder={spaceViewerInitialSkeletonShape}
                        >
                            <>
                                {spaces.map((space) => (
                                    <div
                                        //only adding display none to filtered out elements will prevent from always re-fetching for occupants instead or re-rendering cards
                                        className={` d-flex ${
                                            filteredOutSpaces.includes(space.id ?? '') && 'd-none'
                                        } `}
                                        key={'space-viewer-container' + space.id}
                                    >
                                        <SpaceViewerCard
                                            space={space}
                                            isSelected={space.id === selectedSpace?.value}
                                            onSelectSpace={(space: SingleSelectCustomOption) =>
                                                setSelectedSpace(space)
                                            }
                                            addToFetchQueue={addToFetchQueue}
                                            timeRange={timeRange}
                                            scheduleType={scheduleType}
                                        />
                                    </div>
                                ))}

                                {!noSpaces && noResults && (
                                    <div className="empty-results text-body">
                                        {t('Table.table_empty')}
                                    </div>
                                )}
                                {noSpaces && (
                                    <div className="empty-results text-body">{t('no_spaces')}</div>
                                )}
                            </>
                        </Skeleton>
                    </div>
                </div>
            </div>
        );
    }
);

export default SpaceViewerModalContent;
