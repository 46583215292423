import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MinusIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M7.63672 15.0039H20.3633C20.8994 15.0039 21.3564 14.5557 21.3564 14.002C21.3564 13.457 20.8994 13 20.3633 13H7.63672C7.11816 13 6.64355 13.457 6.64355 14.002C6.64355 14.5557 7.11816 15.0039 7.63672 15.0039Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default MinusIcon;
