import { ClubCategoryDto } from 'Api/Features/Clubs/Dtos/ClubCategoryDto';

export const ClubCategoryIconMap: Map<ClubCategoryDto, { iconName: string; color: string }> =
    new Map([
        [ClubCategoryDto.Animals, { iconName: 'ClubAnimalIcon', color: '#D912CD' }],
        [ClubCategoryDto.Art, { iconName: 'ClubArtIcon', color: '#F2C500' }],
        [ClubCategoryDto.Books, { iconName: 'ClubBookIcon', color: '#00BD9C' }],
        [ClubCategoryDto.Cinema, { iconName: 'ClubCinemaIcon', color: '#ED406B' }],
        [ClubCategoryDto.Food, { iconName: 'ClubFoodieIcon', color: '#1AAF5D' }],
        [ClubCategoryDto.Games, { iconName: 'ClubGamesIcon', color: '#6B40ED' }],
        [ClubCategoryDto.Hobbies, { iconName: 'ClubHobbieIcon', color: '#A3D214' }],
        [ClubCategoryDto.Music, { iconName: 'ClubMusicIcon', color: '#7F8C8D' }],
        [ClubCategoryDto.Social, { iconName: 'ClubSocialIcon', color: '#ED982F' }],
        [ClubCategoryDto.Sports, { iconName: 'ClubSportsIcon', color: '#2F84EE' }],
        [ClubCategoryDto.Technology, { iconName: 'ClubTechIcon', color: '#33495F' }],
        [ClubCategoryDto.Travel, { iconName: 'ClubTravelIcon', color: '#9C56B8' }],
    ]);
