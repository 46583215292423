import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import TitledWhitecardSection from 'Routes/profile/components/titled-whitecard-section';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import UserValueDisplay from '../user-value-display/user-value-display';
import { CalendlyIcon, EnvelopeIcon, Phone, SlackIcon } from 'Components/icons';
import { ContactMethodTypeDto } from 'Api/Features/General/Dtos/ContactMethodTypeDto';

interface ContactPreferencesTabProps {
    data?: UserDetailsDto;
}

const ContactPreferencesTab: FunctionComponent<ContactPreferencesTabProps> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="ContactPreferencesTab">
            <TitledWhitecardSection title={t('contact_preferences')}>
                <UserValueDisplay
                    label={t('phone_number')}
                    value={
                        data?.contactPreferences?.methods?.find(
                            (met) => met?.type === ContactMethodTypeDto.PhoneNumber
                        )?.value
                    }
                    icon={<Phone width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('email')}
                    value={
                        data?.contactPreferences?.methods?.find(
                            (met) => met?.type === ContactMethodTypeDto.Email
                        )?.value
                    }
                    icon={<EnvelopeIcon width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('ContactMethodTypeDto.ContactMethodTypeDto_input_label_Slack')}
                    value={
                        data?.contactPreferences?.methods?.find(
                            (met) => met?.type === ContactMethodTypeDto.Slack
                        )?.value
                    }
                    icon={<SlackIcon width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('ContactMethodTypeDto.ContactMethodTypeDto_input_label_Calendly')}
                    value={
                        data?.contactPreferences?.methods?.find(
                            (met) => met?.type === ContactMethodTypeDto.Calendly
                        )?.value
                    }
                    icon={<CalendlyIcon width={24} height={24} />}
                />
            </TitledWhitecardSection>
        </div>
    );
};

export default ContactPreferencesTab;
