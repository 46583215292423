import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import ImagesCoworkers from 'Components/images-coworkers';
import ModalAttendingCoworkers from 'Components/modal-attending-coworkers';
import StarNumber from 'Components/star-number';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import { SupportedLanguage } from 'Stores/LanguageStore';

export interface RSVPInfoDetailProps {
    maxCapacity?: number;
    currentPeople?: number;
    peopleImageUrls?: string[];
    favCoworkers?: number;
    viewAllButton?: boolean;
    numberCoworkers?: number;
    happening: HappeningDto;
    hideImages?: boolean;
    isPast?: boolean;
}

const RSVPInfoDetail: React.FunctionComponent<RSVPInfoDetailProps> = observer(
    ({
        maxCapacity,
        currentPeople,
        peopleImageUrls = [],
        favCoworkers,
        viewAllButton = false,
        numberCoworkers,
        happening,
        hideImages,
        isPast,
    }) => {
        const { languageStore } = useStores();
        const { t } = useTranslation();
        const [modalAttendingCoworkersIsVisible, setModalAttendingCoworkersIsVisible] =
            useState(false);

        const onPressViewAll = () => setModalAttendingCoworkersIsVisible(true);

        const amountOfImagesToDisplay = useMemo((): number => {
            //french breaks display this was solution https://appcom.atlassian.net/browse/FLEXY-3177
            if (languageStore.currentLanguage == SupportedLanguage.FR) {
                return favCoworkers == 0 ? 4 : 3;
            } else return 6;
        }, [languageStore.currentLanguage, favCoworkers]);

        return (
            <div className={`RSVPInfoDetail hideImages-${hideImages}`}>
                <div className={`text-footnote ${isPast ? 'is-past' : ''}`}>
                    {t(`Happening.${isPast ? 'who_attending' : 'attending'}`)}:{' '}
                    <span className="text-footnote-bold">
                        {currentPeople}
                        {`${happening.capacity != null ? `/${maxCapacity}` : ''}`}
                    </span>
                </div>

                {!hideImages && (
                    <div className="bottom-coworkers">
                        <ImagesCoworkers
                            imageUrls={peopleImageUrls}
                            maxDisplayCoworkers={amountOfImagesToDisplay}
                            usersCount={numberCoworkers ? numberCoworkers : 0}
                        />

                        {favCoworkers && favCoworkers > 0 ? (
                            <StarNumber number={favCoworkers} />
                        ) : null}
                        {viewAllButton && (numberCoworkers || 0) > 0 ? (
                            <div className="view-all text-callout-bold" onClick={onPressViewAll}>
                                {t('view_all')}
                            </div>
                        ) : null}
                    </div>
                )}

                {modalAttendingCoworkersIsVisible && (
                    <ModalAttendingCoworkers
                        happening={happening}
                        onClose={() => setModalAttendingCoworkersIsVisible(false)}
                    />
                )}
            </div>
        );
    }
);

export default RSVPInfoDetail;
