import { PolicyBundleRestrictionsDto } from 'Api/Features/Policies/Dtos/PolicyBundleRestrictionsDto';
import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { ScheduleEventSourceDto } from 'Api/Features/Schedules/Dtos/ScheduleEventSourceDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { ClockIcon, WarningRedIcon, WarningYellowIcon } from 'Components/icons';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import TooltipWrapper from 'Components/tooltip-wrapper';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    FORMAT_DAY_COMMA_YEAR,
    FORMAT_DAY_WRITTEN,
    FORMAT_MONTH_DAY,
    FORMAT_SHORT_MONTH_DATE,
    FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE,
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    TWENTY_FOUR_HOUR_MINUTE,
} from 'Models/Constants';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, Culture, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { theme } from 'Style/theme';
import {
    DefaultScheduleEventWithCapacity,
    calendarEventOverlapsRestriction,
    coreHoursToCalendarEventTime,
    isTimeRestricted,
} from 'Utils/CalendarUtils';
import {
    createMomentFromUtcWithoutChangingTime,
    getTimeFromMomentString,
    momentIsLastPossibleTimePeriod,
} from 'Utils/TimeUtils';
import PreferenceTimePeriodModal from './components/preference-time-period-modal';
import './index.less';
import { PolicyBundleObjectivesCoreHoursGroupDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesCoreHoursGroupDto';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { mergeStrings } from 'Utils/TextUtils';

interface PreferenceCalendarEvent {
    start: Date;
    end: Date;
    preferenceType: PreferenceCalendarEventType;
    workAreaType: PreferenceWorkAreaType;
    officeName?: string | null;
    officeId?: string | null;
    spaceId?: string | null;
    spaceName?: string | null;
    id?: string;
    eventStartString?: string;
    eventEndString?: string;
    desk?: string;
    floor?: string;
    capacityString?: string;
    schedulePreviewExceptions?: GetOfficeSpaceOccupancyResponsePeriodDto[];
    hasUnavailableCapacityWarning?: boolean;
}

enum PreferenceCalendarEventType {
    Office = 'Office',
    Remote = 'Remote',
    OffHour = 'OffHour',
    CoreHour = 'CoreHour',
}

enum PreferenceWorkAreaType {
    Office = 'Office',
    Remote = 'Remote',
}

interface TimePeriodModalState {
    visible: boolean;
    startTime?: string;
    endTime?: string;
    date?: string;
    office?: SingleSelectCustomOption;
    space?: SingleSelectCustomOption;
    isOffHour?: boolean;
    workType?: WorkTypeDto;
    isEditing?: boolean;
    id?: string;
    eventStartString?: string;
    desk?: string;
    floor?: string;
}

export enum CalendarForType {
    TeamSchedule = 'TeamSchedule',
    UserProfile = 'UserProfile',
}

export interface DefaultScheduleEventExtended extends DefaultScheduleEventWithCapacity {
    schedulePreviewExceptions?: GetOfficeSpaceOccupancyResponsePeriodDto[];
}

interface WeeklyHourPrefCalendarProps {
    events: DefaultScheduleEventExtended[];
    onEventsChange?: (events: ScheduleEventDto[]) => void;
    newPeriodClicked?: boolean;
    onPeriodModalClosed?: () => void;
    restrictions?: PolicyBundleRestrictionsDto;
    isInteractable?: boolean;
    calendarForType: CalendarForType;
    teamScheduleCoreHoursGroups?: (PolicyBundleObjectivesCoreHoursGroupDto | null)[] | null;
    roundedBorders?: boolean;
}

const WeeklyHourPrefCalendar: React.FunctionComponent<WeeklyHourPrefCalendarProps> = observer(
    ({
        events,
        onEventsChange,
        newPeriodClicked,
        onPeriodModalClosed,
        restrictions,
        isInteractable = true,
        calendarForType,
        teamScheduleCoreHoursGroups,
        roundedBorders,
    }) => {
        const { t } = useTranslation();
        const { policyStore, languageStore } = useStores();

        const [calendarEvents, setCalendarEvents] = useState<PreferenceCalendarEvent[]>([]);
        const [timePeriodModalState, setTimePeriodModalState] = useState<TimePeriodModalState>({
            visible: false,
        });

        const getTimezonedMomentObject = (dateString?: string): moment.Moment => {
            return moment(dateString).utc();
        };

        const parseEventType = (
            eventType?: EventTypeDto,
            workType?: WorkTypeDto | null
        ): PreferenceCalendarEventType => {
            if (eventType && eventType === EventTypeDto.OffHourAvailability)
                return PreferenceCalendarEventType.OffHour;
            if (eventType && eventType === EventTypeDto.Work) {
                if (workType && workType === WorkTypeDto.Office)
                    return PreferenceCalendarEventType.Office;
                if (workType && workType === WorkTypeDto.Remote)
                    return PreferenceCalendarEventType.Remote;
            }

            return PreferenceCalendarEventType.OffHour;
        };

        const parseWorkAreaType = (workType?: WorkTypeDto | null): PreferenceWorkAreaType => {
            if (workType === WorkTypeDto.Office) return PreferenceWorkAreaType.Office;
            else return PreferenceWorkAreaType.Remote;
        };

        useEffect(() => {
            const parsedEvents: PreferenceCalendarEvent[] = events.map(
                (event: DefaultScheduleEventExtended) => {
                    const start = getTimezonedMomentObject(event.startTime);
                    const end = getTimezonedMomentObject(event.endTime);
                    const eventEndsAtMidnight = end.hour() === 0 && end.minute() === 0;

                    const calendarEvent: PreferenceCalendarEvent = {
                        start: new Date(
                            start.year(),
                            start.month(),
                            start.date(),
                            start.hour(),
                            start.minute()
                        ),
                        end: new Date(
                            //<= should always be same as start. Disregarding what api sends because of overlaps
                            //if converting timezone makes an event overlap into an other day, api seperates it into two events.
                            //the first event end is 00:00 but we must put it as 23:59 so that it can be displayed correctly
                            start.year(),
                            start.month(),
                            start.date(),
                            eventEndsAtMidnight ? 23 : end.hour(),
                            eventEndsAtMidnight ? 59 : end.minute()
                        ),
                        preferenceType: parseEventType(event.type, event.workType),
                        workAreaType: parseWorkAreaType(event.workType),
                        id: event.id,
                        officeId: event.office?.id,
                        officeName: event.office?.name,
                        spaceId: event.officeSpace?.id,
                        spaceName: event.officeSpace?.name,
                        eventStartString: event.startTime,
                        eventEndString: event.endTime,
                        desk: event.desk ?? undefined,
                        floor: event.floor ?? undefined,
                        capacityString: event.capacity,
                        schedulePreviewExceptions: event.schedulePreviewExceptions,
                        hasUnavailableCapacityWarning:
                            event.isAvailableForUser !== true &&
                            event.isAvailableForUser !== undefined,
                    };

                    return calendarEvent;
                }
            );

            //create core hours fake events.
            if (calendarForType === CalendarForType.UserProfile) {
                if (policyStore.policyInfo?.policyBundleObjectivesDto?.coreHoursGroups) {
                    const coreHourDates = coreHoursToCalendarEventTime(
                        policyStore.policyInfo?.policyBundleObjectivesDto?.coreHoursGroups,
                        { start: moment().format(), end: moment().format() },
                        languageStore.currentLanguage
                    );

                    coreHourDates.forEach((coreHour) => {
                        parsedEvents.push({
                            start: coreHour.start,
                            end: coreHour.end,
                            preferenceType: PreferenceCalendarEventType.CoreHour,
                            workAreaType: PreferenceWorkAreaType.Office,
                        });
                    });
                }
            } else if (
                calendarForType === CalendarForType.TeamSchedule &&
                teamScheduleCoreHoursGroups
            ) {
                const coreHourDates = coreHoursToCalendarEventTime(
                    teamScheduleCoreHoursGroups,
                    { start: moment().format(), end: moment().format() },
                    languageStore.currentLanguage
                );

                coreHourDates.forEach((coreHour) => {
                    parsedEvents.push({
                        start: coreHour.start,
                        end: coreHour.end,
                        preferenceType: PreferenceCalendarEventType.CoreHour,
                        workAreaType: PreferenceWorkAreaType.Office,
                    });
                });
            }

            setCalendarEvents(parsedEvents);
        }, [
            events,
            policyStore.policyInfo?.policyBundleObjectivesDto,
            teamScheduleCoreHoursGroups,
        ]);

        useEffect(() => {
            if (newPeriodClicked) {
                setTimePeriodModalState({ visible: true });
            }
        }, [newPeriodClicked]);

        //Scroll to Earliest event
        const getTimeToScrollTo = useCallback(() => {
            if (events.length === 0) return new Date();

            let timeToScroll;
            let comparerDate;
            events.forEach((event, i) => {
                if (
                    i === 0 ||
                    getTimezonedMomentObject(event.startTime).day(1).month(1).isBefore(comparerDate)
                ) {
                    comparerDate = getTimezonedMomentObject(event.startTime).day(1).month(1);
                    timeToScroll = event.startTime;
                }
            });
            return createMomentFromUtcWithoutChangingTime(timeToScroll).toDate();
        }, [events]);

        // -- Region Calendar props

        // if (userStore.userInfo?.timeZone) moment.tz.setDefault(userStore.userInfo?.timeZone);
        //-- Might need to use this in the other version of calendar when events are not utc
        // *** It is important to note that changing moment's default timezone affects all dates, created by moment, from that point forward, so you may want to reset the default when your component unmounts
        // https://github.com/jquense/react-big-calendar/issues/118#issuecomment-367902629 possible solution
        const localizer = momentLocalizer(moment);

        const formats = {
            timeGutterFormat: (start: Date, culture?: Culture, localizer?: DateLocalizer) =>
                localizer?.format(
                    start,
                    FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE[languageStore.currentLanguage],
                    languageStore.currentLanguage
                ) ?? '',
            dayFormat: (start: Date, culture?: Culture, localizer?: DateLocalizer): string =>
                localizer?.format(start, FORMAT_DAY_WRITTEN, languageStore.currentLanguage) ?? '',
            dayRangeHeaderFormat: (
                range: { start: Date; end: Date },
                culture?: Culture,
                localizer?: DateLocalizer
            ): string =>
                localizer
                    ? localizer.format(
                          range.start,
                          FORMAT_MONTH_DAY[languageStore.currentLanguage],
                          languageStore.currentLanguage
                      ) +
                      ' - ' +
                      localizer?.format(
                          range.end,
                          FORMAT_DAY_COMMA_YEAR,
                          languageStore.currentLanguage
                      )
                    : '',
        };

        const CustomEventComponentCoreHour = (event: PreferenceCalendarEvent): JSX.Element => {
            return (
                <div className="custom-event-inner-container" title={''}>
                    <TooltipWrapper
                        backgroundColor={theme['layout-high-contrast']}
                        textColor={theme['text-low-contrast']}
                        borderRadius={5}
                        padding={[10]}
                        title={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className="text-caption-2-bold text-white"
                                    style={{ paddingBottom: '5px' }}
                                >
                                    {t('Policy.core_hours_title')}
                                </div>
                                <div className="text-caption-3 text-white">
                                    {moment(event.start).format(
                                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                            languageStore.currentLanguage
                                        ]
                                    )}
                                    {' - '}
                                    {moment(event.end).format(
                                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                            languageStore.currentLanguage
                                        ]
                                    )}
                                </div>
                            </div>
                        }
                        placement="left"
                    >
                        <div className="core-hour-blueline-indicator"></div>
                    </TooltipWrapper>
                </div>
            );
        };

        const CustomEventComponent = useCallback((calendarObject: any): JSX.Element => {
            const event: PreferenceCalendarEvent = calendarObject.event;
            let fill, title, hoverTitle, offHourOfficeText;

            //Core hour events are fake events we create to have the left blue bar
            if (event.preferenceType === PreferenceCalendarEventType.CoreHour) {
                return CustomEventComponentCoreHour(event);
            }

            const isPolicyBundleInvalid = calendarEventOverlapsRestriction(
                event.eventStartString ?? '',
                event.eventEndString ?? '',
                languageStore.currentLanguage,
                restrictions,
                true
            );

            switch (event.preferenceType) {
                case PreferenceCalendarEventType.OffHour:
                    {
                        fill = theme['warning-high-contrast'];
                        title = t('PrefCalendar.off_hour_availability');
                        hoverTitle =
                            t('PrefCalendar.off_hour_availability') +
                            (event.officeName
                                ? ' - ' +
                                  event.officeName +
                                  (event.spaceName ? ' - ' + event.spaceName : '')
                                : '');
                        offHourOfficeText =
                            event.workAreaType === PreferenceWorkAreaType.Office
                                ? event.officeName ?? t('at_office')
                                : t('remote');
                    }
                    break;
                case PreferenceCalendarEventType.Office:
                    {
                        fill = theme['accent-high-contrast'];
                        title = event.officeName ?? t('PrefCalendar.working_office');
                        hoverTitle = event.officeName
                            ? event.officeName + (event.spaceName ? ' - ' + event.spaceName : '')
                            : t('PrefCalendar.working_office');
                    }
                    break;
                case PreferenceCalendarEventType.Remote:
                    {
                        fill = theme['primary-high-contrast'];
                        title = t('PrefCalendar.working_remote');
                        hoverTitle = t('PrefCalendar.working_remote');
                    }
                    break;
            }
            const timeString = `${moment(event.start).format(
                FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
            )} - ${
                momentIsLastPossibleTimePeriod(event.end.toString(), true)
                    ? '12:00 AM'
                    : moment(event.end).format(
                          FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
                      )
            }`;

            if (moment(event.start).add(30, 'minute').isSameOrAfter(moment(event.end))) {
                title = title + '...';
            }

            return (
                <div
                    className="custom-event-inner-container"
                    title={`${hoverTitle}: ${timeString}`}
                >
                    <div>
                        <div className="title text-caption-1-bold" style={{ color: fill }}>
                            {isPolicyBundleInvalid && (
                                <TooltipWrapper
                                    backgroundColor={theme['layout-high-contrast']}
                                    textColor={theme['text-low-contrast']}
                                    width={
                                        languageStore.currentLanguage === SupportedLanguage.EN
                                            ? 290
                                            : 310
                                    }
                                    title={t('PrefCalendar.event_time_restriction')}
                                    placement="top"
                                >
                                    <span className="warning">
                                        <WarningRedIcon width={16} height={16} />
                                    </span>
                                </TooltipWrapper>
                            )}

                            {!isPolicyBundleInvalid && event.hasUnavailableCapacityWarning && (
                                <TooltipWrapper
                                    backgroundColor={theme['layout-high-contrast']}
                                    textColor={theme['text-low-contrast']}
                                    title={t('Errors.office_space_capacity_reached')}
                                    width={
                                        languageStore.currentLanguage === SupportedLanguage.EN
                                            ? 315
                                            : 255
                                    }
                                    placement="top"
                                >
                                    <span className="warning">
                                        <WarningRedIcon width={16} height={16} />
                                    </span>
                                </TooltipWrapper>
                            )}

                            {!isPolicyBundleInvalid &&
                                !event.hasUnavailableCapacityWarning &&
                                (event.schedulePreviewExceptions?.length ?? 0) > 0 && (
                                    <TooltipWrapper
                                        backgroundColor={theme['layout-high-contrast']}
                                        textColor={theme['text-low-contrast']}
                                        width={250}
                                        borderRadius={5}
                                        title={
                                            <div>
                                                <span className="text-caption-2-bold text-low-contrast">
                                                    {t('Schedule.space_unavailable_dates')}
                                                </span>

                                                <div
                                                    className="d-flex-justify text-caption-3 text-low-contrast"
                                                    style={{ flexWrap: 'wrap' }}
                                                >
                                                    {mergeStrings(
                                                        event.schedulePreviewExceptions?.map(
                                                            (exception) =>
                                                                moment
                                                                    .utc(exception.startTime)
                                                                    .format(
                                                                        FORMAT_SHORT_MONTH_DATE[
                                                                            languageStore
                                                                                .currentLanguage
                                                                        ]
                                                                    )
                                                        ) ?? [],
                                                        ' • '
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        placement="top"
                                    >
                                        <span className="warning">
                                            <WarningYellowIcon width={16} height={16} />
                                        </span>
                                    </TooltipWrapper>
                                )}

                            {title}
                            <div className="capacity">{event.capacityString}</div>
                        </div>
                        <div
                            className="off-hour-office text-caption-3-bold"
                            style={{ color: fill }}
                        >
                            {offHourOfficeText}
                        </div>
                        <span className="uppercase">
                            <ClockIcon width={11} height={11} fill={fill} />
                            <span
                                className="time-string text-caption-3-bold"
                                style={{ color: fill }}
                            >
                                {timeString}
                            </span>
                        </span>
                    </div>

                    <div className="office text-caption-3-bold" style={{ color: fill }}>
                        {event.spaceName} {event.floor} {event.desk}
                    </div>
                </div>
            );
        }, []);

        const SlotPropGetter = useCallback(
            (date) => {
                return {
                    className: isTimeRestricted(date, languageStore.currentLanguage, restrictions)
                        ? 'restricted'
                        : '',
                };
            },
            [restrictions]
        );

        const handleTimeSlotClick = useCallback(
            (e) => {
                if (
                    !isInteractable ||
                    isTimeRestricted(e.start, languageStore.currentLanguage, restrictions)
                )
                    return;

                const startTimeString = e.start.toString();
                const momentStartTime =
                    startTimeString.includes('15:00') || startTimeString.includes('45:00')
                        ? moment(e.start).add(-15, 'minutes')
                        : moment(e.start);

                setTimePeriodModalState({
                    visible: true,
                    startTime: momentStartTime.format(TWENTY_FOUR_HOUR_MINUTE),
                    date: e.start.toString(),
                    isEditing: false,
                });
            },
            [isInteractable]
        );

        const handleEventClick = useCallback(
            (event: PreferenceCalendarEvent) => {
                if (!isInteractable) return;

                setTimePeriodModalState({
                    visible: true,
                    isEditing: true,
                    startTime: getTimeFromMomentString(moment(event.start).format()),
                    date: event.start.toString(),
                    endTime: getTimeFromMomentString(moment(event.end).format()),
                    office: event.officeId
                        ? { value: event.officeId, label: event.officeName ?? '' }
                        : undefined,
                    space: event.spaceId
                        ? {
                              value: event.spaceId,
                              label: event.spaceName ?? '',
                          }
                        : undefined,
                    isOffHour: event.preferenceType === PreferenceCalendarEventType.OffHour,
                    workType:
                        event.workAreaType === PreferenceWorkAreaType.Office
                            ? WorkTypeDto.Office
                            : WorkTypeDto.Remote,
                    id: event.id,
                    eventStartString: event.eventStartString,
                    desk: event.desk,
                    floor: event.floor,
                });
            },
            [isInteractable]
        );

        //https://jquense.github.io/react-big-calendar/examples/iframe.html?id=props--event-prop-getter&viewMode=docs&args=
        const eventPropGetter = useCallback(
            (event: PreferenceCalendarEvent /*, start, end, isSelected*/) => {
                let quarterHourEvent = '';
                if (
                    getTimezonedMomentObject(event.start.toString())
                        .add(15, 'minute')
                        .isSameOrAfter(getTimezonedMomentObject(event.end.toString()))
                ) {
                    quarterHourEvent = 'quarterHourEvent';
                }

                return {
                    ...(event.preferenceType === PreferenceCalendarEventType.Office && {
                        className: `pref-type-office text-caption-1-bold ${quarterHourEvent} core-hour-displayed`,
                    }),
                    ...(event.preferenceType === PreferenceCalendarEventType.OffHour && {
                        className: `pref-type-offhour text-caption-1-bold ${quarterHourEvent} core-hour-displayed`,
                    }),
                    ...(event.preferenceType === PreferenceCalendarEventType.Remote && {
                        className: `pref-type-remote text-caption-1-bold ${quarterHourEvent} core-hour-displayed`,
                    }),
                    ...(event.preferenceType === PreferenceCalendarEventType.CoreHour && {
                        className: `pref-type-coreHour ${quarterHourEvent}`,
                    }),
                };
            },
            []
        );
        // -- End Region Calendar props

        const handleTimePeriodModalSubmit = (
            newPrefs?: ScheduleEventDto[],
            removeEvents?: { eventStartString?: string }[]
        ) => {
            if (!onEventsChange || !onPeriodModalClosed) return;

            setTimePeriodModalState({ visible: false });

            let newEventList = events;

            if (removeEvents) {
                newEventList = events.filter(
                    (event) =>
                        !removeEvents.some((remove) => remove.eventStartString === event.startTime)
                );
            }
            if (newPrefs) newEventList.push(...newPrefs);

            onEventsChange([...newEventList]);
            onPeriodModalClosed();
        };

        return (
            <div className={`WeeklyHourPrefCalendar ${roundedBorders ? 'rounded-top' : ''}`}>
                <Calendar
                    selectable
                    localizer={localizer}
                    style={{ height: '100%' }}
                    events={calendarEvents}
                    getNow={() => getTimezonedMomentObject().toDate()}
                    defaultView="week"
                    views={['week']}
                    step={15}
                    timeslots={4}
                    formats={formats}
                    eventPropGetter={eventPropGetter}
                    slotPropGetter={SlotPropGetter}
                    components={{
                        event: CustomEventComponent,
                        toolbar: () => <></>,
                    }}
                    tooltipAccessor={(event) => event.workAreaType}
                    scrollToTime={getTimeToScrollTo()}
                    onSelectSlot={handleTimeSlotClick}
                    onSelectEvent={handleEventClick}
                    onSelecting={() => false} //disable the dragging create event
                />

                {timePeriodModalState.visible && (
                    <PreferenceTimePeriodModal
                        visible={timePeriodModalState.visible}
                        startTime={timePeriodModalState.startTime}
                        endTime={timePeriodModalState.endTime}
                        date={timePeriodModalState.date}
                        isEditingTimePeriod={timePeriodModalState.isEditing}
                        isOffHour={timePeriodModalState.isOffHour}
                        office={timePeriodModalState.office}
                        space={timePeriodModalState.space}
                        workType={timePeriodModalState.workType}
                        onComplete={handleTimePeriodModalSubmit}
                        calendarEvents={events}
                        id={timePeriodModalState.id}
                        eventStartString={timePeriodModalState.eventStartString}
                        source={ScheduleEventSourceDto.DefaultSchedule}
                        desk={timePeriodModalState.desk}
                        floor={timePeriodModalState.floor}
                        isEditingTeamSchedule={calendarForType === CalendarForType.TeamSchedule}
                    />
                )}
            </div>
        );
    }
);
export default WeeklyHourPrefCalendar;
