import Tag, { TagColor } from 'Components/tag/tag';
import 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

interface WorkHoursGlanceDropdownModalProps {
    workHours: string;
    offHours: string;
}

const WorkHoursGlanceDropdownModal: React.FunctionComponent<WorkHoursGlanceDropdownModalProps> = ({
    workHours,
    offHours,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`WorkHoursGlanceDropdownModal`}>
            <div className="hours-balance-hours text-footnote">
                {workHours} {t('hours')}
                <Tag text={t('PlanSchedule.of_work')} color={TagColor.green} />
            </div>

            <div className="hours-balance-hours off-hours text-footnote">
                {offHours} {t('hours')}
                <Tag text={t('PlanSchedule.off_hours')} color={TagColor.yellow} />
            </div>
        </div>
    );
};
export default WorkHoursGlanceDropdownModal;
