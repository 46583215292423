import HorizontalGridSlide, { ColsPosition } from 'Components/horizontal-grid-slide';
import OfficeOccupancyCard, {
    OfficeOccupancyCardData,
    OfficeOccupancyCardWorkType,
} from 'Components/office-occupancy-card';
import Skeleton from 'Components/skeleton';
import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { dashboardNextDaysShape } from 'Routes/dashboard/skeleton-shapes';
import './index.less';
import { useService, useStores } from 'Hooks';
import { ScheduleService } from 'Services/ScheduleService';
import { UpdateUserWorkDayRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserWorkDayRequestDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { ScheduleDto } from 'Api/Features/Schedules/Dtos/ScheduleDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { eventOverlapsEvent } from 'Utils/CalendarUtils';
import { createMomentFromUtcWithoutChangingTime, dateToDayOfWeek } from 'Utils/TimeUtils';
import { GetOfficeSpaceOccupancyRequestPeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestPeriodDto';
import moment from 'moment';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { OfficeService } from 'Services/OfficeService';
import { GetDailyWorkStatsResponseDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsResponseDto';
import { GetDailyWorkStatsResponseDayDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsResponseDayDto';
import { observer } from 'mobx-react';
import { WorkingCoworkersModalMode } from 'Components/coworkers-at-office-modal/working-coworkers-modal';
import { UserService } from 'Services/UserService';
import {
    E004006,
    FORMAT_DAY_WRITTEN,
    FORMAT_MONTH_DATE_COMMA_YEAR,
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    FORMAT_YEAR_MONTH_DAY,
    PLAN_SCHEDULE_URL,
} from 'Models/Constants';
import { GetDailyWorkStatsRequestDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsRequestDto';
import { HappeningFilterModeDto } from 'Api/Features/Happenings/Dtos/HappeningFilterModeDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'Components/error-message';

interface DashboardNextDaysProps {
    selectedOfficeOption?: SingleSelectCustomOption;
    setWorkingCoworkerModalState: (
        visible: boolean,
        startingDate: string | undefined,
        workType: WorkTypeDto | undefined,
        mode: WorkingCoworkersModalMode
    ) => void;
}

enum UpdateDayType {
    workType = 'worktype',
    office = 'office',
}

const DashboardNextDays: React.FunctionComponent<DashboardNextDaysProps> = observer(
    ({ selectedOfficeOption, setWorkingCoworkerModalState }) => {
        const { t } = useTranslation();
        const scheduleService = useService(ScheduleService);
        const officeService = useService(OfficeService);
        const userService = useService(UserService);
        const { userStore, toastStore, policyStore, languageStore } = useStores();
        const [hasError, setHasError] = useState(false);

        const NEXT_DAYS_DISPLAY = 5;
        const NEXT_DAYS_TO_LOAD = 2;
        const NEXT_DAYS_FIRST_LOAD = NEXT_DAYS_DISPLAY + NEXT_DAYS_TO_LOAD;
        const NEXT_DAYS_MAX = NEXT_DAYS_DISPLAY + NEXT_DAYS_TO_LOAD * 13;
        const [colsPosition, setColsPosition] = useState<ColsPosition>();
        const nextDaysFirstLoadComplete = useRef<boolean>(false);
        const nextDaysDayOffset = useRef<number>(0);
        const [gridSlideCanMoveForward, setGridSlideCanMoveForward] = useState(false);

        const [nextDaysData, setNextDaysData] = useState<OfficeOccupancyCardData[]>([]);
        const [officeOccupancyCards, setOfficeOccupancyCards] = useState<ReactElement[]>([]);

        useEffect(() => {
            if (nextDaysData && nextDaysData.length > 0) {
                const cards = nextDaysData.map((item, i) => (
                    <OfficeOccupancyCard key={i} officeOccupancyCardData={item} />
                ));

                for (let index = nextDaysData.length; index < NEXT_DAYS_MAX; index++) {
                    cards.push(
                        <Skeleton
                            key={'loadingCards-' + index}
                            isLoaded={false}
                            placeholder={dashboardNextDaysShape}
                            defaultPadding={false}
                        />
                    );
                }

                setOfficeOccupancyCards(cards);
            } else {
                const skeletonCards: ReactElement[] = [];
                for (let index = 0; index < (NEXT_DAYS_MAX ?? 0); index++) {
                    skeletonCards.push(
                        <Skeleton
                            key={'loadingCards-' + index}
                            isLoaded={false}
                            placeholder={dashboardNextDaysShape}
                            defaultPadding={false}
                        />
                    );
                }
                setOfficeOccupancyCards(skeletonCards);
            }
        }, [nextDaysData]);

        const handlePositionChanges = useCallback(
            (colsPosition) => setColsPosition(colsPosition),
            []
        );

        const getDaysWorkingAtOfficeInfo = (
            schedule: ScheduleDto
        ): Map<
            string, //date
            {
                workStartTime: string | null | undefined;
                workEndTime: string | null | undefined;
                events: (ScheduleEventDto | null)[];
            }
        > => {
            const daysWorkingAtOffice = schedule.days
                ?.filter(
                    (day) =>
                        day?.workTypes?.includes(WorkTypeDto.Office) &&
                        day.workStartTime &&
                        day.workEndTime
                )
                .map((day) => ({
                    date: day?.date,
                    workStartTime: day?.workStartTime,
                    workEndTime: day?.workEndTime,
                    events:
                        schedule.events?.filter(
                            (event) =>
                                event !== null &&
                                event.workType === WorkTypeDto.Office &&
                                //we can judge if event is within the day by creating a whole day event
                                eventOverlapsEvent(
                                    {
                                        startTime: createMomentFromUtcWithoutChangingTime(
                                            day?.date ?? '',
                                            userStore.userInfo?.timeZone ?? ''
                                        )
                                            .startOf('day')
                                            .format(),
                                        endTime: createMomentFromUtcWithoutChangingTime(
                                            day?.date ?? '',
                                            userStore.userInfo?.timeZone ?? ''
                                        )
                                            .endOf('day')
                                            .format(),
                                    },
                                    event
                                )
                        ) ?? [],
                }));

            return new Map(
                daysWorkingAtOffice?.map((day) => [
                    day.date ?? '',
                    {
                        workStartTime: day.workStartTime,
                        workEndTime: day.workEndTime,
                        events: day.events,
                    },
                ])
            );
        };

        const getCardOfficeChangerData = (
            schedule: ScheduleDto,
            date: string
        ): {
            hasMultipleOffices: boolean;
            hasMultipleOfficeSpaces: boolean;
            selectedOffice?: {
                isDefaultOffice?: boolean;
                capacity?: {
                    value: number;
                    total: number;
                };
                officeName?: string;
                officeId?: string;
                spaceName?: string;
                spaceId?: string;
            };
            capacityFetchParams: {
                startTime: string;
                endTime: string;
            };
        } => {
            const daysWorkingAtOfficeInfo = getDaysWorkingAtOfficeInfo(schedule);
            const currentOccupancyCardWorkingAtOfficeInfo = daysWorkingAtOfficeInfo.get(date);

            const currentCardOfficeEvents = currentOccupancyCardWorkingAtOfficeInfo?.events
                .filter((event) => event !== null && event.workType === WorkTypeDto.Office)
                .map((event) => event!);

            const hasMultipleOffices =
                //no office is considered like an office. So defined office, not defined office === 2 offices
                [...new Set(currentCardOfficeEvents?.map((event) => event?.office?.id))].length > 1;

            //when day has multiple events in same office but different office spaces. Can be set to true if multiple offices
            const hasMultipleOfficeSpaces = hasMultipleOffices
                ? true
                : //no space is considered like a space. So defined space, not defined space === 2 spaces
                  [...new Set(currentCardOfficeEvents?.map((event) => event?.officeSpace?.id))]
                      .length > 1;

            const selectedOffice = hasMultipleOffices
                ? undefined
                : currentCardOfficeEvents
                ? {
                      officeId: currentCardOfficeEvents[0].office?.id,
                      officeName: currentCardOfficeEvents[0].office?.name ?? undefined,
                      isDefaultOffice: hasMultipleOffices
                          ? false
                          : currentCardOfficeEvents[0].office !== null &&
                            currentCardOfficeEvents[0].office?.id ===
                                userStore.userInfo?.defaultOffice?.id &&
                            currentCardOfficeEvents[0].officeSpace?.id ===
                                userStore.userInfo?.defaultOfficeSpace?.id,
                      spaceId: hasMultipleOfficeSpaces
                          ? undefined
                          : currentCardOfficeEvents[0].officeSpace?.id,
                      spaceName: currentCardOfficeEvents[0].officeSpace?.name ?? undefined,
                      capacity: undefined, //capacity is set after  fetchNextDaysOfficeSpaceCapacities
                  }
                : undefined;

            const capacityFetchParams = {
                startTime:
                    currentCardOfficeEvents?.[0]?.startTime ??
                    schedule?.days?.[0]?.workStartTime ??
                    '',
                endTime:
                    currentCardOfficeEvents?.[0]?.endTime &&
                    currentCardOfficeEvents?.[0].startTime === currentCardOfficeEvents?.[0]?.endTime
                        ? moment(currentCardOfficeEvents?.[0]?.endTime).add(30, 'minutes').format()
                        : currentCardOfficeEvents?.[0]?.endTime ??
                          schedule?.days?.[0]?.workEndTime ??
                          '',
            };

            return {
                hasMultipleOffices,
                hasMultipleOfficeSpaces,
                selectedOffice,
                capacityFetchParams,
            };
        };

        const updateUserWorkDay = async (
            request: UpdateUserWorkDayRequestDto,
            updateType: UpdateDayType,
            overrideUsingDefaultOffice?: boolean
        ) => {
            try {
                if (!userStore.userInfo?.id) return;

                //set loading on the specific card day changer inputs
                setNextDaysData((prev) => {
                    const newState = prev;
                    const changedDayIndex = newState?.findIndex(
                        (day) => day.apiDateId === request.date
                    );

                    if (changedDayIndex !== undefined && changedDayIndex > -1 && newState) {
                        newState[changedDayIndex] = {
                            ...newState[changedDayIndex],
                            dayChangersAreLoading: true,
                        };
                    }
                    return newState ? [...newState] : [];
                });

                //sending the updateType will help with error catching
                let requestWithType = { ...request, updateType: updateType };

                //when changing the day work type, if user has default office attempt to put those values
                if (
                    updateType === UpdateDayType.workType &&
                    request.workType === WorkTypeDto.Office &&
                    !overrideUsingDefaultOffice
                ) {
                    requestWithType = {
                        ...request,
                        officeId: userStore.userInfo.defaultOffice?.id,
                        officeSpaceId: userStore.userInfo.defaultOfficeSpace?.id,
                        desk: userStore.userInfo.defaultDesk,
                        floor: userStore.userInfo.defaultFloor,
                        updateType: updateType,
                    };
                }

                await scheduleService.updateUserWorkDay(userStore.userInfo?.id, requestWithType);
                const scheduleRequest: GetUserScheduleRequestDto = {
                    startDate: request.date,
                    endDate: request.date,
                    timeZone: userStore?.userInfo?.timeZone,
                };
                const response = await scheduleService.getUserSchedule(
                    userStore?.userInfo?.id || '',
                    scheduleRequest
                );

                if (response) {
                    const cardOfficeChangerData = getCardOfficeChangerData(
                        response,
                        request.date ?? ''
                    );
                    setNextDaysData((prev) => {
                        const newState = prev;

                        const changedDayIndex = newState?.findIndex(
                            (day) => day.apiDateId === request.date
                        );

                        if (changedDayIndex !== undefined && changedDayIndex > -1 && newState) {
                            newState[changedDayIndex] = {
                                ...newState[changedDayIndex],
                                officeAllDayChangerData: {
                                    ...newState[changedDayIndex].officeAllDayChangerData,
                                    officeButtonData: { ...cardOfficeChangerData },
                                },
                                worktypeData:
                                    updateType === UpdateDayType.workType
                                        ? {
                                              ...newState[changedDayIndex].worktypeData,
                                              selected:
                                                  request.workType === WorkTypeDto.Away
                                                      ? OfficeOccupancyCardWorkType.Away
                                                      : request.workType === WorkTypeDto.Office
                                                      ? OfficeOccupancyCardWorkType.Office
                                                      : OfficeOccupancyCardWorkType.Remote,
                                          }
                                        : { ...newState[changedDayIndex].worktypeData },
                                dayChangersAreLoading: false,
                            };
                        }

                        return newState ? [...newState] : [];
                    });
                    fetchNextDaysOfficeSpaceCapacities(response);
                }
            } catch (e: any) {
                handleUpdateUserWorkDayError(e);
            }
        };

        const handleUpdateUserWorkDayError = (error: any) => {
            if (error.response?.data?.errorCode === E004006 && error.response?.config?.data) {
                //if the space is full with a request type UpdateDayType.workType, repeat call but this time we will ignore the space.
                const originalRequest = JSON.parse(error.response.config.data);

                if (originalRequest.updateType === UpdateDayType.workType) {
                    error.treated = true;
                    updateUserWorkDay(
                        { ...originalRequest, officeSpaceId: undefined },
                        UpdateDayType.workType,
                        true
                    );
                } else {
                    error.treated = true;
                    toastStore.toast({
                        type: 'error',
                        message: error.response.data.errorDescription,
                    });
                    //remove the loading state from the card that made the fetch
                    setNextDaysData((prev) => {
                        const newState = prev;

                        const changedDayIndex = newState?.findIndex(
                            (day) => day.apiDateId === originalRequest.date
                        );

                        if (changedDayIndex !== undefined && changedDayIndex > -1 && newState) {
                            newState[changedDayIndex] = {
                                ...newState[changedDayIndex],
                                dayChangersAreLoading: false,
                            };
                        }

                        return newState ? [...newState] : [];
                    });
                }
            }
            if (!error.treated) {
                toastStore.genericError();
            }
        };

        const fetchNextDaysOfficeSpaceCapacities = async (schedule: ScheduleDto) => {
            try {
                const daysWorkingAtOffice = getDaysWorkingAtOfficeInfo(schedule);

                const getOfficeSpaceOccupancyRequestPeriods: {
                    date?: string;
                    periods: GetOfficeSpaceOccupancyRequestPeriodDto;
                }[] = [];

                daysWorkingAtOffice?.forEach((value, key) => {
                    const uniqueOfficeSpaceIds = [
                        ...new Set(
                            value.events
                                .filter((event) => event?.officeSpace?.id)
                                .map((event) => event?.officeSpace?.id)
                        ),
                    ];
                    //even if day has multiple events and possibly in different spaces therefore making this call for capacity very wrong, this is what was approved
                    if (uniqueOfficeSpaceIds.length > 0) {
                        getOfficeSpaceOccupancyRequestPeriods.push({
                            date: key,
                            periods: {
                                officeSpaceId: uniqueOfficeSpaceIds[0],
                                startTime: value.workStartTime,
                                endTime:
                                    value.workStartTime === value.workEndTime
                                        ? moment(value.workEndTime).add(30, 'minutes').format()
                                        : value.workEndTime,
                            },
                        });
                    }
                });

                const occupancyResponse: GetOfficeSpaceOccupancyResponsePeriodDto[] =
                    await officeService.getOfficeSpaceOccupancy({
                        periods: getOfficeSpaceOccupancyRequestPeriods.map(
                            (request) => request.periods
                        ),
                        timeZone: userStore?.userInfo?.timeZone,
                    });

                const attendanceDateResponseMap: Map<
                    string | undefined,
                    GetOfficeSpaceOccupancyResponsePeriodDto
                > = new Map(
                    getOfficeSpaceOccupancyRequestPeriods.map((request, i) => [
                        request.date,
                        occupancyResponse[i],
                    ])
                );

                setNextDaysData((prev) => {
                    if (prev) {
                        return prev.map((officeCardData) => {
                            return {
                                ...officeCardData,
                                officeAllDayChangerData: {
                                    ...officeCardData.officeAllDayChangerData,
                                    officeButtonData: {
                                        ...officeCardData.officeAllDayChangerData.officeButtonData,
                                        selectedOffice: {
                                            ...officeCardData.officeAllDayChangerData
                                                .officeButtonData.selectedOffice,
                                            //This is what we are setting here. If values from attence request are present set those.
                                            capacity: attendanceDateResponseMap.has(
                                                officeCardData.apiDateId
                                            )
                                                ? {
                                                      total:
                                                          attendanceDateResponseMap.get(
                                                              officeCardData.apiDateId
                                                          )?.officeSpace?.capacity ??
                                                          officeCardData.officeAllDayChangerData
                                                              .officeButtonData.selectedOffice
                                                              ?.capacity?.total ??
                                                          0,
                                                      value:
                                                          attendanceDateResponseMap.get(
                                                              officeCardData.apiDateId
                                                          )?.occupancy ??
                                                          officeCardData.officeAllDayChangerData
                                                              .officeButtonData.selectedOffice
                                                              ?.capacity?.value ??
                                                          0,
                                                  }
                                                : // Otherwise set the info that was already in card.
                                                officeCardData.officeAllDayChangerData
                                                      .officeButtonData.selectedOffice?.capacity
                                                ? {
                                                      ...officeCardData.officeAllDayChangerData
                                                          .officeButtonData.selectedOffice
                                                          ?.capacity,
                                                  }
                                                : undefined,
                                        },
                                    },
                                },
                            };
                        });
                    } else return [];
                });
            } catch (err: any) {
                if (!err.treated) {
                    toastStore.genericError();
                    setHasError(() => true);
                }
            }
        };

        const createOfficeOccupancyCardData = (
            day: GetDailyWorkStatsResponseDayDto,
            indexInCurrentResponsePaging: number,
            response: [
                ScheduleDto,
                GetDailyWorkStatsResponseDto,
                GetDailyWorkStatsResponseDto,
                number
            ]
        ): OfficeOccupancyCardData => {
            const cardDateUTC = moment.utc(day?.date);
            const openWorkingCoworkersModal = () =>
                setWorkingCoworkerModalState(
                    true,
                    cardDateUTC.format(FORMAT_YEAR_MONTH_DAY),
                    WorkTypeDto.Office,
                    WorkingCoworkersModalMode.CoworkerList
                );

            let currentDayWorkType = OfficeOccupancyCardWorkType.Unavailable;
            if (response[0]?.days?.[indexInCurrentResponsePaging]) {
                currentDayWorkType = workTypeDtoToOfficeOccypancyCardWorktype(
                    response[0].days[indexInCurrentResponsePaging]?.workTypes ?? []
                );
            }

            const cardOfficeChangerData = getCardOfficeChangerData(response[0], day.date ?? '');

            return {
                apiDateId: day?.date ?? '',
                onOpenWorkingCoworkersModal:
                    (day?.happenings?.length ?? 0) > 0 ? openWorkingCoworkersModal : undefined,
                isToday: moment.utc(day.date).dayOfYear() === moment().dayOfYear(),
                isSuggestedDay: policyStore.policyInfo?.suggestions?.officeDays?.includes(
                    dateToDayOfWeek(day?.date ?? '')
                ),
                date: {
                    day: cardDateUTC.format(FORMAT_DAY_WRITTEN),
                    month: cardDateUTC.format(
                        FORMAT_MONTH_DATE_COMMA_YEAR[languageStore.currentLanguage]
                    ),
                },
                workingFrom:
                    response[0]?.days?.[indexInCurrentResponsePaging]?.workStartTime &&
                    response[0]?.days?.[indexInCurrentResponsePaging]?.workEndTime
                        ? moment(response[0]?.days?.[indexInCurrentResponsePaging]?.workStartTime)
                              .tz(userStore.userInfo?.timeZone || '')
                              .format(
                                  FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                      languageStore.currentLanguage
                                  ]
                              ) +
                          ' - ' +
                          moment(response[0]?.days?.[indexInCurrentResponsePaging]?.workEndTime)
                              .tz(userStore.userInfo?.timeZone || '')
                              .format(
                                  FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                      languageStore.currentLanguage
                                  ]
                              )
                        : undefined,
                //changing all day office,remote, away
                worktypeData: {
                    selected: currentDayWorkType,
                    buttonLink: PLAN_SCHEDULE_URL,
                    onChangeAllDayWorkType: async (worktype) =>
                        await updateUserWorkDay(
                            {
                                date: day?.date ?? '',
                                workType: officeOccupancyCardWorkTypeToWorktypeDto(worktype),
                                useSmartOfficeSelection: true,
                            },
                            UpdateDayType.workType
                        ),
                },
                officeAllDayChangerData: {
                    onChangeAllDayOffices: async (selectedOffice, selectedSpace) =>
                        await updateUserWorkDay(
                            {
                                date: day?.date ?? '',
                                workType: WorkTypeDto.Office,
                                officeId: selectedOffice?.id,
                                officeSpaceId: selectedSpace?.id,
                                useSmartOfficeSelection: false,
                            },
                            UpdateDayType.office
                        ),
                    makeHybridButtonLink: PLAN_SCHEDULE_URL,
                    officeButtonData: {
                        ...cardOfficeChangerData,
                    },
                },
                workingFromlink:
                    PLAN_SCHEDULE_URL +
                    `?date=${moment(
                        response[0]?.days?.[indexInCurrentResponsePaging]?.workStartTime
                    )
                        .tz(userStore.userInfo?.timeZone || '')
                        .format(FORMAT_YEAR_MONTH_DAY)}`,
                office: {
                    officeName: selectedOfficeOption?.label ?? t('all_offices'),
                    totalCoworkers: response[2]?.days?.[indexInCurrentResponsePaging]?.usersCount
                        ? response[2].days[indexInCurrentResponsePaging]?.usersCount ?? 0
                        : 0,
                    myTeam: userStore.userInfo?.team
                        ? { atOffice: day?.usersCount ?? 0, total: response[3] }
                        : undefined,
                },
                team: {
                    imgUrls: day?.users?.map((user) => user?.imageUrl ?? '') ?? [],
                    atOffice: day?.usersCount ?? 0,
                    favCoworkers: day?.favoriteUsersCount ?? 0,
                    onOpenCoworkersAtOfficeModal: openWorkingCoworkersModal,
                },
                dayChangersAreLoading: false,
            };
        };

        const fetchNextDays = async (loadedDays?: number) => {
            if (!userStore.userInfo?.id) return;

            const todayMoment = moment();
            const requestStartDay = todayMoment.format(FORMAT_YEAR_MONTH_DAY);
            const requestNextWorkDays = nextDaysFirstLoadComplete.current
                ? NEXT_DAYS_TO_LOAD
                : NEXT_DAYS_FIRST_LOAD;

            try {
                const fetchParallelForNextDaysData = async () => {
                    if (
                        !userStore.userInfo ||
                        !userStore.userInfo.timeZone ||
                        !userStore.userInfo.id
                    )
                        return;

                    const officeId =
                        selectedOfficeOption?.value && selectedOfficeOption?.value !== 'all'
                            ? [selectedOfficeOption?.value ?? '']
                            : undefined;
                    const scheduleRequest = {
                        startDate: requestStartDay,
                        nextWorkDays: requestNextWorkDays,
                        nextWorkDaysOffset: loadedDays,
                        timeZone: userStore?.userInfo?.timeZone,
                    };
                    const commonDailyWorkStatsRequest = {
                        teamIds: userStore.userInfo?.team?.id
                            ? [userStore?.userInfo?.team?.id]
                            : undefined,
                        favoritesForUserId: userStore?.userInfo?.id,
                    };
                    const dailyWorkStatsRequestWithTeam: GetDailyWorkStatsRequestDto = {
                        ...commonDailyWorkStatsRequest,
                        startDate: requestStartDay,
                        nextWorkDays: requestNextWorkDays,
                        nextWorkDaysOffset: loadedDays,
                        policyBundleId: policyStore.policyInfo?.policyBundleId,
                        timeZone: userStore?.userInfo?.timeZone,
                        workType: WorkTypeDto?.Office,
                        officeIds: officeId,
                        happeningOfficeIds: officeId,
                        excludedUserIds: [userStore?.userInfo?.id || ''],
                        happeningFilterMode: HappeningFilterModeDto.GeneralAndUserClubs,
                        clubsForUserId: userStore.userInfo.id,
                    };

                    const promiseAll: any = [
                        scheduleService.getUserSchedule(
                            userStore?.userInfo?.id || '',
                            scheduleRequest
                        ),
                        scheduleService.getDailyWorkStats(dailyWorkStatsRequestWithTeam),
                        scheduleService.getDailyWorkStats({
                            ...dailyWorkStatsRequestWithTeam,
                            teamIds: undefined,
                        }),
                        userService.getUsers({
                            ...commonDailyWorkStatsRequest,
                            pageSize: 0,
                        }),
                    ];

                    const [
                        scheduleResponse,
                        dailyWorkStatsResponseWithTeam,
                        dailyWorkStatsResponseWithoutTeam,
                        listUsers,
                    ] = await Promise.all(promiseAll);

                    const totalCoworkers = listUsers[1];

                    return [
                        scheduleResponse,
                        dailyWorkStatsResponseWithTeam,
                        dailyWorkStatsResponseWithoutTeam,
                        totalCoworkers,
                    ] as [
                        ScheduleDto,
                        GetDailyWorkStatsResponseDto,
                        GetDailyWorkStatsResponseDto,
                        number
                    ];
                };

                const response:
                    | [
                          ScheduleDto,
                          GetDailyWorkStatsResponseDto,
                          GetDailyWorkStatsResponseDto,
                          number
                      ]
                    | undefined = await fetchParallelForNextDaysData();

                if (!response) return;

                const OfficeOccupancyCardData: OfficeOccupancyCardData[] = [];
                response[1].days?.forEach((day, i) => {
                    if (day)
                        OfficeOccupancyCardData.push(
                            createOfficeOccupancyCardData(day, i, response)
                        );
                });

                setNextDaysData((prev) => {
                    const newCards = prev
                        ? [...prev, ...OfficeOccupancyCardData]
                        : [...OfficeOccupancyCardData];

                    return newCards;
                });
                fetchNextDaysOfficeSpaceCapacities(response[0]);
            } catch (err: any) {
                if (!err.treated) {
                    toastStore.genericError();
                    setHasError(() => true);
                }
            }
        };

        useEffect(() => {
            if (nextDaysFirstLoadComplete.current) {
                nextDaysFirstLoadComplete.current = false;
                setNextDaysData([]);
                fetchNextDays();
                nextDaysFirstLoadComplete.current = true;
                nextDaysDayOffset.current = NEXT_DAYS_FIRST_LOAD;
            }
        }, [selectedOfficeOption]);

        useEffect(() => {
            if (
                userStore.userInfo?.id &&
                policyStore.policyInfo &&
                !nextDaysFirstLoadComplete.current
            ) {
                fetchNextDays();
                nextDaysFirstLoadComplete.current = true;
                nextDaysDayOffset.current = NEXT_DAYS_FIRST_LOAD;
            }
        }, [userStore.userInfo?.id, policyStore.policyInfo]);

        useEffect(() => {
            if (
                nextDaysDayOffset.current >= NEXT_DAYS_FIRST_LOAD &&
                nextDaysDayOffset.current < NEXT_DAYS_MAX &&
                colsPosition
            ) {
                if (
                    colsPosition.leftNb + colsPosition.displayNb >=
                    nextDaysDayOffset.current - NEXT_DAYS_TO_LOAD + 1
                ) {
                    fetchNextDays(nextDaysDayOffset.current);
                    nextDaysDayOffset.current = nextDaysDayOffset.current + NEXT_DAYS_TO_LOAD;
                }
            }
        }, [colsPosition]);

        useEffect(() => {
            //solution for fast clicking next on a slow connection which can make cards be out of order. It disables moving forward if cards are loading.
            //quick solution for deadlines, better solution would be to always have cards in order even if certain calls finish before others
            if (colsPosition) {
                if (nextDaysData.length < colsPosition?.displayNb + colsPosition?.leftNb) {
                    setGridSlideCanMoveForward(false);
                } else {
                    setGridSlideCanMoveForward(true);
                }
            }
        }, [colsPosition, nextDaysData]);

        return (
            <>
                {hasError ? (
                    <ErrorMessage className="DashboardNextDays" />
                ) : (
                    <HorizontalGridSlide
                        className="DashboardNextDays"
                        colSize={304}
                        gap={15}
                        onPositionChanges={handlePositionChanges}
                        canMoveForward={gridSlideCanMoveForward}
                    >
                        {officeOccupancyCards.map((card) => card)}
                    </HorizontalGridSlide>
                )}
            </>
        );
    }
);

export default memo(DashboardNextDays);

const workTypeDtoToOfficeOccypancyCardWorktype = (
    worktypes: WorkTypeDto[]
): OfficeOccupancyCardWorkType => {
    let worktype = OfficeOccupancyCardWorkType.Unavailable;

    if (worktypes.includes(WorkTypeDto.Office) && worktypes.includes(WorkTypeDto.Remote)) {
        worktype = OfficeOccupancyCardWorkType.Hybride;
    } else {
        switch (worktypes[0]) {
            case WorkTypeDto.Office:
                worktype = OfficeOccupancyCardWorkType.Office;
                break;
            case WorkTypeDto.Remote:
                worktype = OfficeOccupancyCardWorkType.Remote;
                break;
            case WorkTypeDto.Away:
                worktype = OfficeOccupancyCardWorkType.Away;
                break;
        }
    }

    return worktype;
};

const officeOccupancyCardWorkTypeToWorktypeDto = (
    worktype: OfficeOccupancyCardWorkType
): WorkTypeDto | null => {
    let worktypeDto: WorkTypeDto | null = WorkTypeDto.Away;

    switch (worktype) {
        case OfficeOccupancyCardWorkType.Hybride:
            worktypeDto = null;
            break;
        case OfficeOccupancyCardWorkType.Office:
            worktypeDto = WorkTypeDto.Office;
            break;
        case OfficeOccupancyCardWorkType.Remote:
            worktypeDto = WorkTypeDto.Remote;
            break;
        case OfficeOccupancyCardWorkType.Away:
            worktypeDto = WorkTypeDto.Away;
            break;
    }

    return worktypeDto;
};
