import { Col, Form, Input, Row } from 'antd';
import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { CreateOfficeRequestDto } from 'Api/Features/Offices/Dtos/CreateOfficeRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { UpdateOfficeRequestDto } from 'Api/Features/Offices/Dtos/UpdateOfficeRequestDto';
import Button from 'Components/button';
import Modal from 'Components/modal';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORM_GUTTER } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateOfficeSchema } from 'Schemas/CreateOfficeSchema';
import { OfficeService } from 'Services/OfficeService';
import './index.less';
import { countryOptions, timeZonesOptions } from 'Utils/IntlUtils';
import { WarningIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import SubmitButton from 'Components/submit-button/submit-button';

interface CreateOfficeModalProps {
    visible: boolean;
    onComplete: (success: boolean, addSpaces?: boolean, id?: string) => void;
    edition?: boolean;
    valueEdition?: OfficeDto;
}

const CreateOfficeModal: React.FunctionComponent<CreateOfficeModalProps> = observer(
    ({ visible, valueEdition, edition, onComplete }) => {
        const { t } = useTranslation();
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors] = useFormValidation(CreateOfficeSchema, form);
        const officeService = useService(OfficeService);
        const { globalLoadingStore, toastStore, languageStore, confirmationModalStore } =
            useStores();
        const [selectCountry, setSelectCountry] = useState<string | undefined>(
            valueEdition?.address?.countryCode ?? 'US'
        );
        const [selectedtimeZone, setSelectedTimeZone] = useState<string>();

        useEffect(() => {
            if (valueEdition) {
                form.setFieldsValue({
                    timeZone: valueEdition.timeZone,
                });
                setSelectedTimeZone(valueEdition.timeZone ?? '');
            }
        }, [valueEdition]);

        const dismiss = (success: boolean, addSpaces = false, id?: string) => {
            form.resetFields();
            resetErrors();
            onComplete(success, addSpaces, id ?? undefined);
        };

        const createOffice = async (formData: any, addSpaces: boolean) => {
            const address: AddressDto = {
                addressLine1: formData.adress,
                city: formData.city,
                state: formData.state,
                countryCode: selectCountry,
                zipCode: formData.zip_code,
            };
            const request: CreateOfficeRequestDto = {
                name: formData.name,
                address,
                timeZone: selectedtimeZone,
            };
            globalLoadingStore.addLoading();

            try {
                const response = await officeService.createOffice(request);
                dismiss(true, addSpaces, response?.id);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        const updateOffice = async (formData: any) => {
            if (!valueEdition?.id) return;
            const officeId = valueEdition.id;
            const address: AddressDto = {
                addressLine1: formData.adress,
                city: formData.city,
                state: formData.state,
                countryCode: selectCountry,
                zipCode: formData.zip_code,
            };
            const request: UpdateOfficeRequestDto = {
                name: formData.name,
                address,
                timeZone: selectedtimeZone,
            };

            if (request.timeZone !== valueEdition.timeZone && !(await confirmTimezoneChange())) {
                return;
            }

            globalLoadingStore.addLoading();
            try {
                await officeService.updateOffice(officeId, request);
                dismiss(true);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        const onSubmit = async (addSpaces: boolean) => {
            const formData = form.getFieldsValue();

            const validForm = await validateForm(formData);
            if (!validForm) return;

            if (!edition) return createOffice(formData, addSpaces);
            updateOffice(formData);
        };

        const confirmTimezoneChange = async (): Promise<boolean> => {
            return await confirmationModalStore.confirm({
                icon: <WarningIcon fill={theme['warning-mid-contrast']} />,
                title: t('Office.office_change_timezone_warning_title'),
                message: t('Office.office_change_timezone_warning_message'),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            });
        };

        return (
            <Modal
                className="CreateOfficeModal"
                visible={visible}
                onCancel={() => dismiss(false)}
                headerText={
                    edition
                        ? t('Office.edit_office')
                        : t('create_entity', {
                              param1: t('office'),
                              param2: 'un',
                              param3: t('Entity.lowercase_office'),
                          })
                }
                footer={
                    <>
                        <Button text={t('cancel')} type="tertiary" onClick={() => dismiss(false)} />

                        <div className="btn-right">
                            {!edition && (
                                <SubmitButton
                                    text={t('Office.create_office_and_space')}
                                    type="link"
                                    width="hugged"
                                    onClick={() => onSubmit(true)}
                                />
                            )}

                            <SubmitButton
                                text={
                                    edition
                                        ? t('save_changes')
                                        : t('create_entity', { param1: t('office') })
                                }
                                type="primary"
                                onClick={() => onSubmit(false)}
                            />
                        </div>
                    </>
                }
            >
                <div className="CreateOfficeModal-children">
                    <Form layout="vertical" onFinish={onSubmit} form={form}>
                        <div className="firstRow">
                            <ValidatedFormItem
                                name="name"
                                label={t('name')}
                                errors={errors}
                                initialValue={valueEdition?.name ?? ''}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </div>
                        <div>
                            <div className="text-callout-bold mb-20">{t('location')}</div>
                            <Row gutter={FORM_GUTTER}>
                                <Col span={8}>
                                    <ValidatedFormItem
                                        name="adress"
                                        label={t('address')}
                                        initialValue={valueEdition?.address?.addressLine1 ?? ''}
                                        errors={errors}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={8}>
                                    <ValidatedFormItem
                                        name="city"
                                        label={t('city')}
                                        initialValue={valueEdition?.address?.city ?? ''}
                                        errors={errors}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={8}>
                                    <ValidatedFormItem
                                        name="state"
                                        label={t('state')}
                                        initialValue={valueEdition?.address?.state ?? ''}
                                        errors={errors}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                            <Row gutter={FORM_GUTTER}>
                                <Col span={8}>
                                    <ValidatedFormItem
                                        name="country"
                                        label={t('country')}
                                        initialValue={valueEdition?.address?.countryCode ?? 'US'}
                                        errors={errors}
                                    >
                                        <StaticSingleSelect
                                            options={countryOptions(languageStore.currentLanguage)}
                                            onChange={(ev?: SingleSelectCustomOption) =>
                                                setSelectCountry(ev?.value)
                                            }
                                            selected={selectCountry}
                                            placeholder={t(
                                                'SelectCustom.default_select_placeholder'
                                            )}
                                        />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={8}>
                                    <ValidatedFormItem
                                        name="zip_code"
                                        label={t('zip_code')}
                                        initialValue={valueEdition?.address?.zipCode ?? ''}
                                        errors={errors}
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={8}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="timeZone"
                                        label={t('time_zone')}
                                        required
                                    >
                                        <StaticSingleSelect
                                            options={timeZonesOptions(
                                                languageStore.currentLanguage
                                            )}
                                            onChange={(value?: SingleSelectCustomOption): void => {
                                                setSelectedTimeZone(value?.value);
                                            }}
                                            selected={selectedtimeZone}
                                            hideSelectedOptions={false}
                                            placeholder={t(
                                                'SelectCustom.default_select_placeholder'
                                            )}
                                        />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </Modal>
        );
    }
);

export default CreateOfficeModal;
