import * as yup from 'yup';

export const CreateHappeningSchema = yup.object({
    name: yup.string().required('Errors.error_required').max(85, 'Errors.error_max_85'),
    description: yup.string().max(4000, 'Errors.error_max_4000'),
    attendanceType: yup.string().required('Errors.error_required'),
    officeId: yup.string().required('Errors.error_required'),
    startDate: yup.string().required('Errors.error_required'),
    startTime: yup.string().required('Errors.error_required'),
    endDate: yup.string().required('Errors.error_required'),
    endTime: yup.string().required('Errors.error_required'),
    attendeesEnabled: yup.boolean(),
    capacity: yup.number().when('attendeesEnabled', {
        is: true,
        then: yup
            .number()
            .typeError('Errors.error_type_number')
            .min(1, 'Errors.error_min_number_1')
            .nullable(true)
            .transform((_, val) => (val === Number(val) ? val : null)),
    }),
    sendSlackChecked: yup.boolean(),
    slackChannelId: yup.string().when('sendSlackChecked', {
        is: true,
        then: yup.string().required('Errors.error_required'),
    }),
});

export const PostHappeningToSlackSchema = yup.object({
    slackChannelId: yup.string().required('Errors.error_required'),
});
