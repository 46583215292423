import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import Tag, { TagColor, TagProps } from 'Components/tag/tag';
import React, { ReactElement } from 'react';

export const getUserStatusTag = (
    t: Function,
    accountStatus?: UserAccountStatusDto
): TagProps | undefined => {
    switch (accountStatus) {
        case UserAccountStatusDto.Active:
            return {
                text: t(`UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Active}`),
                color: TagColor.green,
            };
        case UserAccountStatusDto.Deactivated:
            return {
                text: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Deactivated}`
                ),
                color: TagColor.red,
            };
        case UserAccountStatusDto.Pending:
            return {
                text: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Pending}`
                ),
                color: TagColor.yellow,
            };
        default:
            return undefined;
    }
};

export const getUserStatusTagComponent = (
    t: Function,
    accountStatus?: UserAccountStatusDto
): ReactElement | undefined => {
    const tagData = getUserStatusTag(t, accountStatus);

    if (tagData) {
        return <Tag text={tagData?.text} color={tagData?.color} />;
    } else {
        return undefined;
    }
};
