import { wavePatternSvg } from 'Assets/StaticSvgs/wave-pattern';
import Button from 'Components/button';
import LanguageCapitalizedText from 'Components/language-capitalized-text';
import Skeleton from 'Components/skeleton';
import VerticalThreeDotMenuButton, {
    ThreeDotMenuOption,
} from 'Components/vertical-three-dot-menu-button';
import React from 'react';
import './index.less';
import { tablePageSecondHeaderSkeletonShape } from './skeleton-shapes';

interface TablePageSecondHeaderProps {
    title: string;
    createBtnString?: string;
    onCreateBtnClick?: () => void;
    menuOptions?: ThreeDotMenuOption[];
    isLoaded?: boolean;
}

const TablePageSecondHeader: React.FunctionComponent<TablePageSecondHeaderProps> = ({
    title,
    createBtnString,
    onCreateBtnClick,
    menuOptions,
    isLoaded,
}) => {
    return (
        <div className="TablePageSecondHeader">
            <div className="header-container content-header">
                <Skeleton isLoaded={isLoaded} placeholder={tablePageSecondHeaderSkeletonShape}>
                    <>
                        <div className="text-title-3">
                            <LanguageCapitalizedText>{title}</LanguageCapitalizedText>
                        </div>

                        {wavePatternSvg}

                        {createBtnString && onCreateBtnClick && (
                            <Button
                                text={createBtnString}
                                type="link"
                                width="hugged"
                                rightCircleIcon="AddIcon"
                                onClick={onCreateBtnClick}
                            />
                        )}

                        {menuOptions && (
                            <VerticalThreeDotMenuButton menuOptions={menuOptions || []} />
                        )}
                    </>
                </Skeleton>
            </div>
        </div>
    );
};

export default TablePageSecondHeader;
