import React from 'react';
import './index.less';

export const realEstateHighLightsShape = (
    <div className="realEstateHighLightsShape">
        <div className="rect-1" />
        <div className="circle-3" />
    </div>
);

export const realEstateTimeSpentPieChartShape = (
    <div className="realEstateTimeSpentPieChartShape">
        <div className="wrapper-3">
            <div className="wrapper-4">
                <div className="wrapper-5">
                    <div className="circle-5" />
                    <div className="rect-7" />
                </div>
                <div className="wrapper-5">
                    <div className="circle-5" />
                    <div className="rect-7" />
                </div>
                <div className="wrapper-5">
                    <div className="circle-5" />
                    <div className="rect-7" />
                </div>
                <div className="wrapper-5">
                    <div className="circle-5" />
                    <div className="rect-7" />
                </div>
                <div className="wrapper-5">
                    <div className="circle-5" />
                    <div className="rect-7" />
                </div>
                <div className="wrapper-5">
                    <div className="circle-5" />
                    <div className="rect-7" />
                </div>
                <div className="wrapper-5">
                    <div className="circle-5" />
                    <div className="rect-7" />
                </div>
            </div>
            <div className="circle-6" />
        </div>
    </div>
);

export const realEstateTimeSpentBarChartShape = (
    <div className="realEstateTimeSpentBarChartShape">
        <div className="wrapper-14">
            <div className="wrapper-15">
                <div className="rect-18" />
                <div className="rect-18" />
                <div className="rect-18" />
                <div className="rect-18" />
                <div className="rect-18" />
            </div>
            <div className="wrapper-18">
                <div className="rect-6" />
                <div className="rect-6" />
                <div className="rect-6" />
                <div className="rect-6" />
                <div className="rect-6" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
                <div className="rect-23" />
                <div className="rect-23 dark" />
            </div>
            <div className="rect-6" />
        </div>
        <div className="wrapper-16">
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
            <div className="rect-19" />
        </div>
        <div className="wrapper-17">
            <div className="circle-20" />
            <div className="rect-21" />
            <div className="circle-20" />
            <div className="rect-22" />
        </div>
    </div>
);

export const realEstateOfficeOccupationShape = (
    <div className="realEstateOfficeOccupationShape">
        <div className="wrapper-9">
            <div className="rect-14" />
            <div className="rect-15" />
        </div>
        <div className="rect-6" />
        <div className="wrapper-10">
            <div className="wrapper-11">
                <div className="rect-16" />
                <div className="rect-16" />
                <div className="rect-16" />
                <div className="rect-16" />
                <div className="rect-16" />
            </div>
            <div className="wrapper-12">
                <svg
                    width="685"
                    height="263"
                    viewBox="0 0 685 263"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.466955 191.054L0 211.258V263H685V67.3873C685 51.6166 658.809 47.2118 651.348 61.7277L621.226 116.318C616.355 127.251 597.107 127.137 593.602 115.852C593.033 114.022 592.457 112.165 591.876 110.287C587.761 96.9973 564.292 97.1289 560.547 110.493L552.562 138.994C548.011 155.237 521.421 157.971 512.189 143.146L491.788 110.385C489.518 106.739 482.98 107.412 481.862 111.407C480.619 115.847 473.03 115.99 471.542 111.602L435.762 6.07889C432.892 -2.38575 418.165 -1.87329 416.166 6.76075L410.648 30.5979C409.618 35.049 401.867 34.9552 400.995 30.4811C400.193 26.366 393.296 25.8153 391.555 29.7273L351.896 118.839C348.219 127.101 333.652 125.933 331.966 117.24C330.704 110.727 321.367 107.798 315.191 111.979L287.482 130.733C279.45 136.169 267.368 133.869 263.025 126.077L250.682 103.93C248.852 100.647 243.075 100.988 241.828 104.453C240.626 107.792 235.143 108.272 233.12 105.216L219.101 84.0319C212.283 73.73 194.06 74.5452 188.628 85.3951L152.381 157.8C150.335 161.887 143.04 161.021 142.47 156.623C142.392 156.026 142.164 155.45 141.798 154.931L114.547 116.229C104.067 102.756 78.9438 107.422 76.4503 123.304L67.8092 178.341C65.5836 192.516 42.8507 196.263 34.2116 183.879C25.061 170.762 0.816822 175.917 0.466955 191.054Z"
                        fill="#F9F9F9"
                    />
                </svg>
                <div className="rect-6" />
                <div className="rect-6" />
                <div className="rect-6" />
                <div className="rect-6" />
                <div className="rect-6" />
            </div>
            <div className="rect-6" />
        </div>
        <div className="wrapper-13">
            <div className="rect-17" />
            <div className="rect-17" />
            <div className="rect-17" />
            <div className="rect-17" />
            <div className="rect-17" />
            <div className="rect-17" />
        </div>
    </div>
);

export const realEstatePopularSpacesShape = (
    <div className="realEstatePopularSpacesShape">
        <div className="wrapper-6">
            <div className="rect-7" />
            <div className="rect-8" />
            <div className="wrapper-7">
                <div className="rect-9" />
                <div className="rect-10" />
            </div>
            <div className="wrapper-8">
                <div className="rect-11" />
                <div className="rect-12" />
                <div className="rect-13" />
            </div>
        </div>
        <div className="rect-6" />
        <div className="wrapper-6 dark">
            <div className="rect-7" />
            <div className="rect-8" />
            <div className="wrapper-7">
                <div className="rect-9" />
                <div className="rect-10" />
            </div>
            <div className="wrapper-8">
                <div className="rect-11" />
                <div className="rect-12" />
                <div className="rect-13" />
            </div>
        </div>
        <div className="rect-6" />
        <div className="wrapper-6">
            <div className="rect-7" />
            <div className="rect-8" />
            <div className="wrapper-7">
                <div className="rect-9" />
                <div className="rect-10" />
            </div>
            <div className="wrapper-8">
                <div className="rect-11" />
                <div className="rect-12" />
                <div className="rect-13" />
            </div>
        </div>
        <div className="rect-6" />
        <div className="wrapper-6 dark">
            <div className="rect-7" />
            <div className="rect-8" />
            <div className="wrapper-7">
                <div className="rect-9" />
                <div className="rect-10" />
            </div>
            <div className="wrapper-8">
                <div className="rect-11" />
                <div className="rect-12" />
                <div className="rect-13" />
            </div>
        </div>
        <div className="rect-6" />
        <div className="wrapper-6">
            <div className="rect-7" />
            <div className="rect-8" />
            <div className="wrapper-7">
                <div className="rect-9" />
                <div className="rect-10" />
            </div>
            <div className="wrapper-8">
                <div className="rect-11" />
                <div className="rect-12" />
                <div className="rect-13" />
            </div>
        </div>
    </div>
);
