import { CreateHappeningRequestDto } from 'Api/Features/Happenings/Dtos/CreateHappeningRequestDto';
import { CreateHappeningResponseDto } from 'Api/Features/Happenings/Dtos/CreateHappeningResponseDto';
import { GetHappeningAttendeesRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesRequestDto';
import { GetHappeningAttendeesResponseDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesResponseDto';
import { GetHappeningAttendeesResponseItemDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesResponseItemDto';
import { GetHappeningRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningRequestDto';
import { GetHappeningsRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningsRequestDto';
import { GetHappeningsResponseDto } from 'Api/Features/Happenings/Dtos/GetHappeningsResponseDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { UpdateHappeningAttendanceRequestDto } from 'Api/Features/Happenings/Dtos/UpdateHappeningAttendanceRequestDto';
import { UpdateHappeningRequestDto } from 'Api/Features/Happenings/Dtos/UpdateHappeningRequestDto';
import { HappeningProxy } from 'Api/Features/Happenings/HappeningProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { PostHappeningToSlackRequestDto } from 'Api/Features/Happenings/Dtos/PostHappeningToSlackRequestDto';
import { HttpClient } from 'HttpClient/HttpClient';

export interface GetHappeningAttendeesResponseDtoNonNull {
    items: GetHappeningAttendeesResponseItemDto[];
    totalItemCount: number;
}

@inject(HappeningProxy, HttpClient)
export class HappeningService extends ApiService {
    constructor(
        private readonly happeningProxy: HappeningProxy,
        private readonly httpClient: HttpClient
    ) {
        super();
    }

    public addAbortSignal(abortSignal: AbortSignal) {
        this.httpClient.addAbortSignal(abortSignal);
    }

    public async getHappening(
        happeningId: string,
        request?: GetHappeningRequestDto | null
    ): Promise<HappeningDto | null> {
        const data: HappeningDto | null = await this.happeningProxy.getHappening(
            happeningId,
            request ?? null
        );
        return data;
    }

    public async getHappenings(
        request: GetHappeningsRequestDto | null
    ): Promise<[HappeningDto[], number]> {
        const data: GetHappeningsResponseDto | null = await this.happeningProxy.getHappenings(
            request
        );
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async getHappeningAttendees(
        happeningId: string,
        request: GetHappeningAttendeesRequestDto | null
    ): Promise<GetHappeningAttendeesResponseDtoNonNull> {
        const data: GetHappeningAttendeesResponseDto | null =
            await this.happeningProxy.getHappeningAttendees(happeningId, request);

        const nonNullData: GetHappeningAttendeesResponseItemDto[] = data?.items
            ? data.items.filter(
                  (item): item is GetHappeningAttendeesResponseItemDto => item !== null
              )
            : [];

        return { items: nonNullData, totalItemCount: data?.totalItemCount ?? 0 };
    }

    public async createHappening(
        request: CreateHappeningRequestDto | null
    ): Promise<CreateHappeningResponseDto | null> {
        const data: CreateHappeningResponseDto | null = await this.happeningProxy.createHappening(
            request
        );
        return data;
    }

    public async updateHappening(
        happeningId: string,
        request: UpdateHappeningRequestDto | null
    ): Promise<void> {
        await this.happeningProxy.updateHappening(happeningId, request);
    }

    public async deleteHappening(happeningId: string): Promise<void> {
        await this.happeningProxy.deleteHappening(happeningId);
    }

    public async updateHappeningAttendance(
        happeningId: string,
        request: UpdateHappeningAttendanceRequestDto
    ): Promise<void> {
        await this.happeningProxy.updateHappeningAttendance(happeningId, request);
    }

    public async postHappeningToSlack(
        happeningId: string,
        request: PostHappeningToSlackRequestDto | null
    ): Promise<void> {
        await this.happeningProxy.postHappeningToSlack(happeningId, request);
    }
}
