import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubHobbieIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M8.5 19.2H15.5V16.5C15.5 15.51 15.1573 14.6625 14.4719 13.9575C13.7865 13.2525 12.9625 12.9 12 12.9C11.0375 12.9 10.2135 13.2525 9.52813 13.9575C8.84271 14.6625 8.5 15.51 8.5 16.5V19.2ZM12 11.1C12.9625 11.1 13.7865 10.7475 14.4719 10.0425C15.1573 9.3375 15.5 8.49 15.5 7.5V4.8H8.5V7.5C8.5 8.49 8.84271 9.3375 9.52813 10.0425C10.2135 10.7475 11.0375 11.1 12 11.1ZM5 21V19.2H6.75V16.5C6.75 15.585 6.95796 14.7261 7.37387 13.9233C7.78921 13.1211 8.36875 12.48 9.1125 12C8.36875 11.52 7.78921 10.8786 7.37387 10.0758C6.95796 9.2736 6.75 8.415 6.75 7.5V4.8H5V3H19V4.8H17.25V7.5C17.25 8.415 17.0423 9.2736 16.627 10.0758C16.2111 10.8786 15.6313 11.52 14.8875 12C15.6313 12.48 16.2111 13.1211 16.627 13.9233C17.0423 14.7261 17.25 15.585 17.25 16.5V19.2H19V21H5Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubHobbieIcon;
