import { action, observable } from 'mobx';

//non exhaustive list of impacts by adding a new language
//-constants TIME FORMATS
export enum SupportedLanguage {
    FR = 'FR',
    EN = 'EN',
}

class LanguageStore {
    @observable
    public currentLanguage: SupportedLanguage = localStorage.lang ?? SupportedLanguage.EN; //persistant so after logout azure pages can stay localised

    @action
    setCurrentLanguage(lang: SupportedLanguage): void {
        this.currentLanguage = localStorage.lang = lang;
    }
}

export default LanguageStore;
