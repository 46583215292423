import { Col, Input, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { ClubDto } from 'Api/Features/Clubs/Dtos/ClubDto';
import { CreateClubRequestDto } from 'Api/Features/Clubs/Dtos/CreateClubRequestDto';
import Button from 'Components/button';
import { PadlockIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import Modal from 'Components/modal';
import QuillWrapper from 'Components/quill-wrapper/quill-wrapper';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import ToggleableRow from 'Components/toggleable-row';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { FORM_GUTTER } from 'Models/Constants';
import { default as React, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateClubSchema } from 'Schemas/CreateClubSchema';
import { ClubService } from 'Services/ClubService';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import './index.less';
import SubmitButton from 'Components/submit-button/submit-button';

interface CreateClubModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string, invitePeople?: boolean) => void;
    club?: ClubDto;
}

const CreateClubModal: React.FunctionComponent<CreateClubModalProps> = observer(
    ({ visible, onComplete, club }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const clubService = useService(ClubService);
        const { toastStore, globalLoadingStore, userStore } = useStores();
        const [errors, validateForm, resetErrors] = useFormValidation(CreateClubSchema, form);
        const [selectedCategory, setSelectedCategory] = useState<string>();
        const [categoryOptions, setCategoryOptions] = useState<SingleSelectCustomOption[]>([]);

        const [isPrivateClub, setIsPrivateClub] = useState(false);

        const [noMarkupDescription, setNoMarkupDescription] = useState<string>();

        const debounceOnDescriptionChange = useRef(
            debounce((editor: any) => {
                setNoMarkupDescription(editor?.getText());
            }, 200)
        );

        useEffect(() => {
            if (club) {
                form.setFieldsValue({
                    name: club.name,
                    description: club.description,
                    category: club.category,
                    isPrivate: club.isPrivate,
                });
                setSelectedCategory(club.category);
                setIsPrivateClub(club.isPrivate ?? false);
            }
        }, [club]);

        useEffect(() => {
            const options: SingleSelectCustomOption[] = [];
            for (const category of ClubCategoryIconMap.entries()) {
                options.push({
                    label: t(`Clubs.ClubCategory_${category[0]}`),
                    value: category[0],
                    leftIcon: (
                        <Icon
                            iconName={category[1].iconName}
                            fill={category[1].color}
                            width={24}
                            height={24}
                        />
                    ),
                    showLeftIconSelected: true,
                } as SingleSelectCustomOption);
            }
            setCategoryOptions(options);
        }, []);

        //#region Submit / Exit
        const dismiss = (success = false, id?: string, invitePeople?: boolean): void => {
            form.resetFields();
            resetErrors();
            onComplete(success, id, invitePeople);
        };

        const submit = async (invitePeople?: boolean): Promise<void> => {
            const formData = form.getFieldsValue();

            const validationObject = {
                ...formData,
                description: noMarkupDescription,
            };

            if (!(await validateForm(validationObject))) return;

            try {
                globalLoadingStore.addLoading();

                const request: CreateClubRequestDto = {
                    ...formData,
                    isPrivate: isPrivateClub,
                    category: selectedCategory,
                    ownerId: userStore.userInfo?.id,
                };

                let response;
                if (club?.id) {
                    response = await clubService.updateClub(club.id, request);
                } else {
                    response = await clubService.createClub(request);
                    //when creating a club, reset clubs in userstore to see it as joined
                    await userStore.setUserInfo(userStore.userInfo?.id ?? '');
                }

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('club'),
                    }),
                });

                dismiss(true, response?.id, invitePeople);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#region Submit / Exit
        return (
            <Modal
                className="CreateClubModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t(`${club ? 'edit' : 'create'}_entity`, {
                    param1: t('club'),
                    param2: 'un',
                    param3: t('Entity.lowercase_club'),
                })}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />

                        <SubmitButton
                            className="add-people"
                            text={t('Clubs.create_and_invite_coworkers')}
                            type="link"
                            width="hugged"
                            onClick={() => submit(true)}
                        />

                        <SubmitButton
                            text={
                                club ? t('save_changes') : t('create_entity', { param1: t('club') })
                            }
                            type="primary"
                            onClick={() => submit()}
                        />
                    </>
                }
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <div className="text-callout-bold mb-15 capitalize">{t('details')}</div>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="category"
                                label={t('Clubs.club_category')}
                                required
                            >
                                <StaticSingleSelect
                                    options={categoryOptions}
                                    onChange={(value?: SingleSelectCustomOption): void => {
                                        setSelectedCategory(value?.value);
                                    }}
                                    selected={selectedCategory}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <ToggleableRow
                        title={
                            <div className="d-flex-align">
                                <PadlockIcon width={22} height={22} /> {t('private_club')}
                            </div>
                        }
                        subTitle={t('Clubs.private_club_desc')}
                        toggle={{
                            checked: isPrivateClub,
                            onChange: (e) => {
                                setIsPrivateClub(e);
                                if (!e) {
                                    form.resetFields(['isPrivate']);
                                }
                            },
                        }}
                    />

                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <div className="text-callout-bold mb-15 capitalize">
                                {t('description')}*
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <QuillWrapper
                                onChange={(value: string, editor) => {
                                    form.setFieldsValue({ description: value });
                                    debounceOnDescriptionChange.current(editor);
                                }}
                                value={form.getFieldValue('description') ?? null}
                                errors={errors}
                                fieldName="description"
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
);

export default CreateClubModal;
