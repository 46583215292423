import { Col, Form, Input, InputNumber, Row, Tabs } from 'antd';
import Button from 'Components/button';
import Modal from 'Components/modal';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useAsyncSingleSelectProps, useFormValidation, useService } from 'Hooks';
import { observer } from 'mobx-react';
import { FORM_GUTTER } from 'Models/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButton from 'Components/submit-button/submit-button';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { OfficeService } from 'Services/OfficeService';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { AssignSpaceSchema } from 'Schemas/FloorPlanSchema';
import './assign-space-modal.less';
import { useFetch } from 'Hooks/use-fetch';
import { CreateOfficeSpaceRequestDto } from 'Api/Features/Offices/Dtos/CreateOfficeSpaceRequestDto';
import { CreateOfficeResponseDto } from 'Api/Features/Offices/Dtos/CreateOfficeResponseDto';
import { FloorPlanOfficeSpaceAreaDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanOfficeSpaceAreaDto';

interface AssignSpaceModalProps {
    visible: boolean;
    officeId: string;
    points: string;
    unavailableSpaceIds: (string | undefined)[];
    onComplete: (success?: boolean, space?: FloorPlanOfficeSpaceAreaDto) => void;
}

enum AssignSpaceTab {
    ExistingSpace = 'ExistingSpace',
    NewSpace = 'NewSpace',
}

const AssignSpaceModal: React.FunctionComponent<AssignSpaceModalProps> = observer(
    ({ visible, officeId, points, unavailableSpaceIds, onComplete }) => {
        const officeService = useService(OfficeService);
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors] = useFormValidation(AssignSpaceSchema, form);
        const [selectedTab, setSelectedTab] = useState<AssignSpaceTab>(
            AssignSpaceTab.ExistingSpace
        );
        const { t } = useTranslation();
        const { apiRequest } = useFetch();
        const { asyncSingleSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficeSpacesRequestDto) =>
                    await officeService.getOfficeSpaces(request),
                fetchFunctionExtraParams: {
                    officeIds: [officeId],
                },
            },
            entityToSingleSelectCustomOption: (space: OfficeSpaceDto) => {
                const alreadyAssigned = unavailableSpaceIds.some((x) => x === space.id);
                return {
                    value: space.id,
                    label: space.name,
                    isDisabled: alreadyAssigned,
                    content: alreadyAssigned
                        ? { node: <div>{t('space_already_assigned')}</div> }
                        : undefined,
                    extraData: space,
                } as SingleSelectCustomOption;
            },
        });

        const dismiss = (success = false, space?: FloorPlanOfficeSpaceAreaDto): void => {
            form.resetFields();
            resetErrors();
            onComplete(success, space);
        };

        const submit = async (): Promise<void> => {
            const formData = form.getFieldsValue();

            const validationObject = {
                createNew: selectedTab === AssignSpaceTab.NewSpace,
                spaceId: asyncSingleSelectProps.selected,
                name: formData.name,
                capacity: formData.capacity,
            };

            if (!(await validateForm(validationObject))) return;

            if (selectedTab === AssignSpaceTab.NewSpace) {
                const response: CreateOfficeResponseDto = await apiRequest({
                    requestFunction: (params) => officeService.createOfficeSpace(params),
                    requestParameters: {
                        officeId: officeId,
                        name: validationObject.name,
                        capacity: validationObject.capacity,
                    } as CreateOfficeSpaceRequestDto,
                    useGlobalLoading: true,
                });
                dismiss(true, {
                    officeSpaceId: response.id,
                    officeSpaceName: validationObject.name,
                    capacity: validationObject.capacity,
                    points: points,
                } as FloorPlanOfficeSpaceAreaDto);
            } else {
                dismiss(true, {
                    officeSpaceId: asyncSingleSelectProps.selectedOption?.extraData.id,
                    officeSpaceName: asyncSingleSelectProps.selectedOption?.extraData.name,
                    capacity: asyncSingleSelectProps.selectedOption?.extraData.capacity,
                    points: points,
                } as FloorPlanOfficeSpaceAreaDto);
            }
        };

        return (
            <Modal
                className="AssignSpaceModal"
                visible={visible}
                width={650}
                headerText={t('assign_space')}
                onCancel={() => dismiss(false)}
                footer={
                    <>
                        <Button text={t('cancel')} type="tertiary" onClick={() => dismiss(false)} />
                        <SubmitButton text={t('assign_space')} type="primary" onClick={submit} />
                    </>
                }
            >
                <Tabs
                    activeKey={selectedTab}
                    onChange={(e) => {
                        setSelectedTab(e as AssignSpaceTab);
                    }}
                >
                    <Tabs.TabPane tab={t('existing_space')} key={AssignSpaceTab.ExistingSpace}>
                        <Form layout="vertical" onFinish={submit} form={form}>
                            <ValidatedFormItem
                                errors={errors}
                                name="spaceId"
                                label={t('choose_a_space')}
                                required
                            >
                                <AsyncSingleSelect {...asyncSingleSelectProps} />
                            </ValidatedFormItem>
                        </Form>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={t('create_new_space')} key={AssignSpaceTab.NewSpace}>
                        <Form layout="vertical" onFinish={submit} form={form}>
                            <Row gutter={FORM_GUTTER}>
                                <Col span={18}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="name"
                                        label={t('name')}
                                        required
                                    >
                                        <Input />
                                    </ValidatedFormItem>
                                </Col>

                                <Col span={6}>
                                    <ValidatedFormItem
                                        errors={errors}
                                        name="capacity"
                                        label={t('capacity')}
                                        required
                                    >
                                        <InputNumber />
                                    </ValidatedFormItem>
                                </Col>
                            </Row>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        );
    }
);

export default AssignSpaceModal;
