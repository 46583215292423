import { ClubDto } from 'Api/Features/Clubs/Dtos/ClubDto';
import { UpdateClubMembersActionDto } from 'Api/Features/Clubs/Dtos/UpdateClubMembersActionDto';
import Button from 'Components/button';
import Divider from 'Components/divider';
import { CloseIcon, PadlockIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import ImagesCoworkers from 'Components/images-coworkers';
import Skeleton from 'Components/skeleton';
import StarNumber from 'Components/star-number';
import WhiteCard from 'Components/white-card';
import { CLUBS_URL } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { theme } from 'Style/theme';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import { truncate } from 'Utils/TextUtils';
import { clubCardSkeletonShape } from '../../skeleton-shapes';
import './index.less';

interface ClubCardProps {
    club?: ClubDto;
    isInvitation?: boolean;
    isSkeleton?: boolean;
    handleInvitationAction?: (
        clubId: string | undefined,
        action: UpdateClubMembersActionDto
    ) => void;
    className?: string;
}

const ClubCard: React.FunctionComponent<ClubCardProps> = ({
    club,
    isInvitation,
    isSkeleton,
    handleInvitationAction,
    className,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [borderColor, setBorderColor] = useState<string>();
    const [clubIcon, setClubIcon] = useState<string>();

    useEffect(() => {
        if (club?.category && ClubCategoryIconMap.has(club.category)) {
            const clubInfo = ClubCategoryIconMap.get(club.category)!;
            setBorderColor(clubInfo.color);
            setClubIcon(clubInfo.iconName);
        }
    }, [club]);

    return (
        <div className={`ClubCard ${className ?? ''}`}>
            <Skeleton placeholder={clubCardSkeletonShape} isLoaded={!isSkeleton}>
                {club && (
                    <WhiteCard padding={20} paddingTop={25} style={{ borderColor: borderColor }}>
                        {clubIcon && (
                            <div className="icon">
                                <Icon
                                    iconName={clubIcon}
                                    fill={borderColor}
                                    width={60}
                                    height={60}
                                />
                            </div>
                        )}

                        {isInvitation && handleInvitationAction && (
                            <div
                                className="reject cursor-pointer"
                                onClick={() =>
                                    handleInvitationAction(
                                        club.id,
                                        UpdateClubMembersActionDto.Remove
                                    )
                                }
                            >
                                <CloseIcon
                                    width={32}
                                    height={32}
                                    fill={theme['text-mid-contrast']}
                                />
                            </div>
                        )}

                        <div className="name text-headline-bold">
                            {truncate(club?.name ?? '', 20)}
                            {club.isPrivate && <PadlockIcon width={22} height={22} />}
                        </div>

                        <div className="type text-caption-1">
                            {t(`Clubs.ClubCategory_${club?.category}`)}
                        </div>

                        <div className="members">
                            <ImagesCoworkers
                                usersCount={club?.membersCount ?? 0}
                                imageUrls={
                                    club.members?.map((member) => member?.imageUrl ?? '') ?? []
                                }
                            />

                            {(club.favoriteMembersCount ?? 0) > 0 && (
                                <StarNumber number={club?.favoriteMembersCount ?? 0} />
                            )}
                        </div>

                        <Divider marginBottom={20} marginTop={20} />

                        <div className="btns">
                            {isInvitation && handleInvitationAction && (
                                <Button
                                    className="join-btn"
                                    text="Join"
                                    type="box"
                                    width="hugged"
                                    onClick={() => {
                                        handleInvitationAction(
                                            club.id,
                                            UpdateClubMembersActionDto.Add
                                        );
                                    }}
                                />
                            )}

                            <Button
                                text={t('view')}
                                type="secondary"
                                onClick={() => history.push(CLUBS_URL + `/${club.id}`)}
                                height={'small'}
                                width={'hugged'}
                            />
                        </div>
                    </WhiteCard>
                )}
            </Skeleton>
        </div>
    );
};

export default ClubCard;
