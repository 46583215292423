import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersSortColumnDto } from 'Api/Features/Users/Dtos/GetUsersSortColumnDto';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import Content from 'Components/Content';
import Skeleton from 'Components/skeleton';
import TableFilters from 'Components/table-filters/table-filters';
import TablePageSecondHeader from 'Components/table-page-second-header';
import Tag, { TagColor } from 'Components/tag/tag';
import { useApiRequestHistory, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    DEBOUNCE_DELAY_400,
    SETTINGS_URL,
    SETTINGS_USERS_URL_REGEX,
    USERS_URL,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { default as React, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserService } from 'Services/UserService';
import { FilterStore } from 'Stores';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import { getUserStatusTag } from 'Utils/TagUtils';
import { tableSkeletonShape } from '../skeleton-shapes';
import './index.less';
import { renderDefaultOfficeColumn } from 'Utils/WorkplaceUtils';
import CreateUserModal from './create-user-modal';

const getAdvancedFilters = (t: Function): AdvancedFilter[] => [
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: UserAccountStatusDto.Active,
                checked: true,
                displayNameKey: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Active}`
                ),
            },
            {
                key: UserAccountStatusDto.Pending,
                checked: true,
                displayNameKey: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Pending}`
                ),
            },
            {
                key: UserAccountStatusDto.Deactivated,
                checked: false,
                displayNameKey: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Deactivated}`
                ),
            },
        ],
    },
];

const Users: React.FunctionComponent = observer(() => {
    const userService = useService(UserService);
    const paginationRef = useRef(initialPaginationState);
    const { t } = useTranslation();
    const advancedFilters = getAdvancedFilters(t);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [userList, setUserList] = useState<UserDto[]>();
    const { toastStore } = useStores();
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [selectedTeamIdsFromCache, setSelectedTeamIdsFromCache] = useState<
        string[] | undefined
    >();
    const [isLoading, setIsLoading] = useState({
        users: false,
    });
    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex: SETTINGS_USERS_URL_REGEX,
            requestType: RequestType.Users,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            const filterStore = filterStoreRef.current;
            const request: GetUsersRequestDto = history.request;

            const filtersList = [{ key: request?.accountStatuses ?? [], parentKey: 'status' }];
            filterStore.tickMultipleAdvancedFilter(filtersList);
            filterStore.searchTerm = request?.searchTerm ?? '';
            filterStore.teamIds = request?.teamIds ?? [];
            setSelectedTeamIdsFromCache(request?.teamIds ?? undefined);

            paginationRef.current = {
                ...paginationRef.current,
                current: request?.page ? request.page + 1 : 1,
                pageSize: request?.pageSize ?? initialPaginationState.pageSize,
            };
        },
    });

    const renderNameColumn = (user: UserDto) => {
        const tagProps = getUserStatusTag(t, user.accountStatus);
        return (
            <div className="TdWithTag">
                <div className="user-infos">
                    <span className="title text-callout">
                        {user.firstName} {user.lastName}
                    </span>
                    <br />
                    <span className="subtitle text-caption">{user.contactInfo?.email}</span>
                </div>
                {user.accountStatus !== UserAccountStatusDto.Active && (
                    <Tag text={tagProps?.text ?? ''} color={tagProps?.color ?? TagColor.blue} />
                )}
            </div>
        );
    };

    const renderRole = (user: UserDto) => {
        return user?.managementRoles && user.managementRoles.length > 0
            ? t(`ManagementRoleDto.ManagementRoleDto_${user.managementRoles[0]?.name}`)
            : t('user');
    };

    const columns: ColumnType<UserDto>[] = [
        {
            title: t('name'),
            render: renderNameColumn,
            key: GetUsersSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('Profile.job_title'),
            render: (user: UserDto) => user.jobTitle ?? t('n/a'),
            key: GetUsersSortColumnDto.JobTitle,
            sorter: true,
        },
        {
            title: t('role'),
            render: renderRole,
            key: GetUsersSortColumnDto.ManagementRoles,
            sorter: true,
        },
        {
            title: t('team'),
            render: (user: UserDto) => user.team?.name ?? t('n/a'),
            key: GetUsersSortColumnDto.Team,
            sorter: true,
        },
        {
            title: t('default_office'),
            render: (user: UserDto) => renderDefaultOfficeColumn(user),
            key: GetUsersSortColumnDto.DefaultOffice,
            sorter: true,
        },
    ];

    const getAdvancedFilterStatus = (): {
        checkedStatuses: UserAccountStatusDto[];
        statusesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'status'
        );
        const checkedStatuses = statuses?.items
            .filter((item) => item.checked)
            .map((item) => {
                return item.key;
            });

        return {
            checkedStatuses:
                checkedStatuses?.map(
                    (x) => UserAccountStatusDto[x as keyof typeof UserAccountStatusDto]
                ) ?? [],
            statusesCount: statuses?.items.length ?? 0,
        };
    };

    const fetchUsers = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            advancedFilters?: AdvancedFilter[];
            teamIds: string[];
            sortColumn: GetUsersSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const statuses = getAdvancedFilterStatus();
                if (statuses.checkedStatuses.length === 0) {
                    setUserList([]);
                } else {
                    const request: GetUsersRequestDto = setRequestHistory({
                        request: {
                            pageSize: params.pagination.pageSize ?? 0,
                            page: (params.pagination.current ?? 1) - 1,
                            searchTerm: params.searchTerm,
                            teamIds: params.teamIds,
                            accountStatuses: statuses.checkedStatuses,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                        },
                        requestType: RequestType.Users,
                    });

                    const [items, totalItemsCount] = await userService.getUsers(request);
                    setUserList(items);
                    setIsLoading((prev) => {
                        return {
                            ...prev,
                            users: true,
                        };
                    });
                    setPagination({
                        ...params.pagination,
                        total: totalItemsCount,
                    });
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [userService]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { advancedFilters, searchTerm, teamIds } = filterStoreRef.current;
        await fetchUsers({
            pagination,
            searchTerm,
            advancedFilters,
            teamIds,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                advancedFilters?: AdvancedFilter[];
                teamIds: string[];
            }) => {
                fetchUsers({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    teamIds: params.teamIds,
                    advancedFilters: params.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
            },
            DEBOUNCE_DELAY_400
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                advancedFilters: filterStore.advancedFilters,
                teamIds: filterStore.teamIds,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const onRowClick = (user: UserDto): void => {
        history.push(SETTINGS_URL + USERS_URL + `/${user.id}`);
    };

    return (
        <div className="UsersTable">
            <TablePageSecondHeader
                title={t('users')}
                createBtnString={t('create_entity', {
                    param1: t('user'),
                    param2: 'un',
                    param3: t('Entity.lowercase_user'),
                })}
                onCreateBtnClick={() => setCreateModalVisible(true)}
                isLoaded={isLoading.users}
            />

            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                    includeTeamSelect
                    advancedFiltersDefaultValue={() => getAdvancedFilters(t)}
                    defaultSelectedTeamIds={selectedTeamIdsFromCache}
                    isLoaded={isLoading.users}
                />

                <Skeleton placeholder={tableSkeletonShape} isLoaded={isLoading.users}>
                    <Table
                        className="table-action-rows table-striped-rows"
                        dataSource={userList}
                        columns={columns}
                        loading={loading}
                        rowKey={(user): string => user.id ?? ''}
                        pagination={pagination}
                        onChange={handleTableChange}
                        onRow={(row: UserDto) => ({
                            onClick: (): void => {
                                onRowClick(row);
                            },
                        })}
                        //rows are all equal, no resizing
                        tableLayout={'fixed'}
                        scroll={{ x: 700 }}
                    />
                </Skeleton>
            </Content>

            {createModalVisible && (
                <CreateUserModal
                    visible={createModalVisible}
                    onComplete={(success: boolean, id?: string) => {
                        setCreateModalVisible(false);
                        if (success && id) history.push(SETTINGS_URL + USERS_URL + `/${id}`);
                    }}
                />
            )}
        </div>
    );
});

export default Users;
