import { Divider } from 'antd';
import Skeleton from 'Components/skeleton';
import Tag, { TagColor } from 'Components/tag/tag';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTotalHoursInEvents, getTotalHoursInEventsType } from 'Utils/CalendarUtils';
import { wrapperBoxInformationsShape } from './skeleton-shapes';
import './time-info.less';

interface TimeInfoProps {
    events: any;
    dividerOrientation?: 'vertical' | 'horizontal';
    isLoaded?: boolean;
}

const TimeInfo: React.FunctionComponent<TimeInfoProps> = observer(
    ({ events, dividerOrientation = 'vertical', isLoaded = true }) => {
        const { t } = useTranslation();

        const getHoursWorks = () => {
            return getTotalHoursInEvents(events, getTotalHoursInEventsType.workHoursPerWeek);
        };

        const getOffhoursWorks = () => {
            return getTotalHoursInEvents(
                events,
                getTotalHoursInEventsType.availabilityHoursPerWeek
            );
        };

        return (
            <Skeleton
                isLoaded={events !== undefined && isLoaded}
                placeholder={wrapperBoxInformationsShape}
            >
                <div
                    className="TimeInfo valide-container" /* <- "valide-container" -> Indisepensable pour le border changant dans './wrapper-box-informations.less' */
                >
                    {dividerOrientation === 'horizontal' && (
                        <Divider
                            type={dividerOrientation}
                            className="divider"
                            style={{ marginTop: '-10px' }}
                        />
                    )}

                    <div className="hour-container">
                        <div
                            className={`numberHours ${
                                dividerOrientation === 'horizontal'
                                    ? 'text-footnote horizontal'
                                    : 'text-callout-bold'
                            }`}
                        >
                            {getHoursWorks()}
                            <div className="text-caption-1">{t('hours')}</div>
                        </div>
                        <Tag color={TagColor.green} text={t('PlanSchedule.of_work')} />
                    </div>

                    {dividerOrientation !== 'horizontal' && (
                        <Divider type={dividerOrientation} className="divider" />
                    )}

                    <div
                        className={`hour-container ${
                            dividerOrientation === 'horizontal' ? 'horizontal' : ''
                        }`}
                    >
                        <div
                            className={`numberHours ${
                                dividerOrientation === 'horizontal'
                                    ? 'text-footnote horizontal'
                                    : 'text-callout-bold'
                            }`}
                        >
                            {getOffhoursWorks()}
                            <div className="text-caption-1">{t('hours')}</div>
                        </div>
                        <Tag color={TagColor.yellow} text={t('PlanSchedule.off_hours')} />
                    </div>
                </div>
            </Skeleton>
        );
    }
);

export default TimeInfo;
