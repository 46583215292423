import { CloseIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import TooltipWrapper from 'Components/tooltip-wrapper';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { SupportedLanguage } from 'Stores/LanguageStore';
import './tag.less';

export enum TagColor {
    purple = 'purple',
    blue = 'blue',
    grey = 'grey',
    red = 'red',
    green = 'green',
    yellow = 'yellow',
    blueTransparent = 'blueTransparent',
}
export interface TagProps extends TagStyle {
    text: string;
    color: TagColor;
}

interface TagStyle {
    squareTag?: boolean;
    uppercase?: boolean;
    dotTag?: boolean;
    canDelete?: boolean;
    onClick?: () => void;
    toolTipTitle?: string;
    iconName?: string;
}

const Tag: React.FunctionComponent<TagProps> = observer(
    ({
        text,
        color,
        squareTag = false,
        dotTag = false,
        uppercase = false,
        canDelete = false,
        toolTipTitle,
        onClick,
        iconName,
    }) => {
        const { languageStore } = useStores();
        const [textTransform, setTextTransform] = useState('capitalize');

        useEffect(() => {
            switch (languageStore.currentLanguage) {
                //English is capitalized by default.
                case SupportedLanguage.EN:
                    setTextTransform('capitalize');
                    break;
                //French never capitlize
                case SupportedLanguage.FR:
                    setTextTransform('');
            }

            //Uppercase overides everything
            if (uppercase) setTextTransform('uppercase');
        }, [uppercase, languageStore.currentLanguage]);

        return (
            <div
                onClick={onClick}
                className={`Tag text-caption-1-bold ${color} square-${squareTag} dot-${dotTag} ${textTransform} ${
                    canDelete ? 'hoverPointer' : ''
                }`}
            >
                <TooltipWrapper placement="top" title={toolTipTitle ?? ''}>
                    <div className="tag-inner-container">
                        {canDelete && (
                            <div className="delete">
                                <CloseIcon height={18} width={18} />
                            </div>
                        )}
                        {iconName && <Icon iconName={iconName} />}
                        <div>{text}</div>
                    </div>
                </TooltipWrapper>
            </div>
        );
    }
);

export default Tag;
