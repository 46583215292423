import { HappeningAttendanceTypeDto } from 'Api/Features/Happenings/Dtos/HappeningAttendanceTypeDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import Tag, { TagColor } from 'Components/tag/tag';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { pointInTimeAgainstRange, PointInTimeAgainstRangeEnum } from './TimeUtils';
import HybridHappening from 'Assets/Images/hybrid-happening.svg';
import RemoteHappening from 'Assets/Images/remote-happening.svg';
import InPersonbHappening from 'Assets/Images/inperson-happening.svg';
import Icon from 'Components/icons/Icon';
import { TFunction } from 'i18next';

export const getHappeningStatusTag = (happening: HappeningDto, t: TFunction): ReactNode => {
    let color, text;
    const start = moment.tz(happening.startTime, happening.office?.timeZone ?? '');
    const end = moment.tz(happening.endTime, happening.office?.timeZone ?? '');
    const current = moment.tz(happening.office?.timeZone ?? '');

    switch (pointInTimeAgainstRange(current, start, end)) {
        case PointInTimeAgainstRangeEnum.Before:
            {
                color = TagColor.blue;
                text = t('upcoming');
            }
            break;
        case PointInTimeAgainstRangeEnum.After:
            {
                color = TagColor.red;
                text = t('ended');
            }
            break;
        case PointInTimeAgainstRangeEnum.Between: {
            color = TagColor.green;
            text = t('ongoing');
        }
    }

    return <Tag text={text} color={color} />;
};

export const getHappeningDefaultImg = (attendanceType?: HappeningAttendanceTypeDto): string => {
    switch (attendanceType) {
        case HappeningAttendanceTypeDto.Hybrid:
            return HybridHappening;
        case HappeningAttendanceTypeDto.InPerson:
            return InPersonbHappening;
        case HappeningAttendanceTypeDto.Remote:
            return RemoteHappening;
        default:
            return HybridHappening;
    }
};

export const getHappeningAttendanceTypeIcon = (
    attendanceType?: HappeningAttendanceTypeDto
): ReactNode => {
    switch (attendanceType) {
        case HappeningAttendanceTypeDto.Hybrid:
            return <Icon iconName="LaptopIcon" />;
        case HappeningAttendanceTypeDto.InPerson:
            return <Icon iconName="PeopleFillIcon" />;
        case HappeningAttendanceTypeDto.Remote:
            return <Icon iconName="LaptopAndPhoneIcon" />;
        default:
            return <Icon iconName="LaptopAndPhoneIcon" />;
    }
};
