import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import AsyncMultiSelect from 'Components/select-custom/multi-select/async-multi-select';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { multiSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { SelectFetchFunctionPromise } from 'Components/select-custom/single-select/async-single-select';
import { useService } from 'Hooks';
import { DROPDOWN_PAGE_SIZE } from 'Models/Constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfficeService } from 'Services/OfficeService';

interface TeamFilterSelectProps {
    defaultSelectedIds?: string[];
    onChange: (values: MultiSelectCustomOption[]) => void;
}

const OfficeFilterSelect: FunctionComponent<TeamFilterSelectProps> = ({
    defaultSelectedIds,
    onChange,
}) => {
    const { t } = useTranslation();
    const officeService = useService(OfficeService);
    const [officesSearchResults, setOfficesSearchResults] = useState<OfficeDto[]>([]);
    const [officesOptions, setOfficesOptions] = useState<MultiSelectCustomOption[]>([]);
    const [selectedOfficesOptions, setSelectedOfficesOptions] = useState<MultiSelectCustomOption[]>(
        []
    );
    const [selectedOfficesIds, setSelectedOfficesIds] = useState<string[]>(
        defaultSelectedIds ?? []
    );

    useEffect(() => {
        if (defaultSelectedIds) setSelectedOfficesIds(defaultSelectedIds);
    }, [defaultSelectedIds]);

    const searchOffices = async (
        page: number,
        searchTerm: string
    ): Promise<SelectFetchFunctionPromise> => {
        const args: GetOfficesRequestDto = {
            pageSize: DROPDOWN_PAGE_SIZE,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await officeService.getOffices(args);
        setOfficesSearchResults((prev) => [...prev, ...results]);

        return {
            maxResultHit: results.length + DROPDOWN_PAGE_SIZE * page >= totalItemCount,
        };
    };

    useEffect(() => {
        const searchResults = officesSearchResults?.map(
            (x: OfficeDto) =>
                ({
                    value: x?.id,
                    label: x?.name,
                } as MultiSelectCustomOption)
        );

        const merged = multiSelectMergeSelectedOptionsWithSearchResults(
            searchResults,
            selectedOfficesOptions
        );

        setOfficesOptions(merged);
    }, [officesSearchResults, selectedOfficesOptions]);

    return (
        <div className="OfficeFilterSelect">
            <AsyncMultiSelect
                fetchFunction={searchOffices}
                resetSearchResults={() => setOfficesSearchResults([])}
                placeholder={t('SelectCustom.choose_an_entity', {
                    param1: t('office'),
                    param2: 'un',
                    param3: t('Entity.lowercase_office'),
                })}
                options={officesOptions}
                selected={selectedOfficesIds}
                onChange={(values: MultiSelectCustomOption[]) => {
                    const ids = values?.map((x: MultiSelectCustomOption) => x.value);
                    setSelectedOfficesIds(ids);
                    setSelectedOfficesOptions(values);
                    onChange(values);
                }}
            />
        </div>
    );
};

export default OfficeFilterSelect;
