import { observable, action } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UserService } from 'Services/UserService';

@inject(UserService)
class UserStore {
    constructor(private readonly userService: UserService) {}

    @observable
    public callInProgress = false;

    @observable
    public userInfo: UserDetailsDto | null = null;

    @observable
    /**
     * Regular users do not have a ManagementRoleDto
     */
    public ManagementRole: ManagementRoleDto | undefined = undefined;

    @action
    async setUserInfo(userId: string): Promise<UserDetailsDto | null> {
        this.callInProgress = true;
        const userInfo = await this.userService.getUser(userId);
        this.userInfo = userInfo;
        this.ManagementRole = userInfo?.managementRoles?.[0]?.name;
        this.callInProgress = false;
        return userInfo;
    }

    @action
    /** Create a new instance of userInfo object with same values as before. Pratical for the useEffect that depend on the object */
    reInitUserInfoObject(): void {
        this.userInfo = { ...this.userInfo };
    }

    @action
    setCallInProgress(value: boolean): void {
        this.callInProgress = value;
    }

    @action
    clearUserInfo(): void {
        this.userInfo = null;
        this.ManagementRole = undefined;
    }
}

export default UserStore;
