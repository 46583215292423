import { ScheduleDayDto } from 'Api/Features/Schedules/Dtos/ScheduleDayDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserBadgeDto } from 'Api/Features/Users/Dtos/UserBadgeDto';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Badges from 'Components/badges';
import Divider from 'Components/divider';
import { FavToggle } from 'Components/toggle';
import Skeleton from 'Components/skeleton';
import Tag, { TagColor } from 'Components/tag/tag';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkingHours } from 'Utils/CoworkerUtils';
import { img, ImgSize } from 'Utils/ImgUtils';
import './index.less';
import { coworkerCardSkeletonShapes } from './skeleton-shapes';

interface CoworkerCardProps {
    selected?: boolean;
    imageUrl?: string | null;
    name?: string;
    jobTitle?: string | null;
    workingHours?: WorkingHours;
    day?: ScheduleDayDto | null;
    onClick?: () => void;
    isManagerUser?: boolean;
    isSkeleton?: boolean;
    relationId?: string; // <- Mandatory for favorite system
    coworkerId?: string; // <- Mandataroy for favorite system
    showFavToggle?: boolean; // <- Mandataroy for favorite system
    onChangeFavorites?: (state: 'add' | 'remove', coworkerId: string, relationId: string) => void; // <- Mandataroy for favorite system
    badges?: UserBadgeDto[];
}

const CoworkerCard: React.FunctionComponent<CoworkerCardProps> = ({
    selected,
    imageUrl,
    name,
    jobTitle,
    workingHours,
    day,
    onClick,
    relationId = '',
    coworkerId = '',
    showFavToggle = false,
    isManagerUser = false,
    isSkeleton = false,
    onChangeFavorites,
    badges,
}) => {
    const getWorkingOrNot = () => {
        if (workingHours?.start && workingHours?.end) return <div>{t('working_from')}</div>;
        return <div>{t('not_working')}</div>;
    };

    const unavailableOrAway = useCallback(() => {
        const isAway = day?.workTypes?.includes(WorkTypeDto.Away);
        if (isAway) return t('away');
        return t('unavailable');
    }, [day?.workTypes]);

    const { t } = useTranslation();

    return (
        <div
            className={`CoworkerCard selected-${selected}`}
            onClick={onClick ? onClick : undefined}
        >
            {badges && badges.length > 0 && <Badges types={badges} tooltip />}

            {showFavToggle && coworkerId && (
                <FavToggle
                    relationId={relationId}
                    coworkerId={coworkerId}
                    onChange={onChangeFavorites}
                />
            )}

            <Skeleton isLoaded={!isSkeleton} placeholder={coworkerCardSkeletonShapes}>
                <div className="img-status-container">
                    <div
                        className="img-container"
                        style={{
                            backgroundImage: `url(${
                                imageUrl ? img(imageUrl, ImgSize.m) : NoProfilePicture
                            })`,
                        }}
                    >
                        {isManagerUser && (
                            <div className="status-circle">
                                <Tag text={t('manager')} color={TagColor.blue} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="info-container">
                    <div className="name text-footnote-bold">{name}</div>
                    <div className="text-caption-2 job-title">{jobTitle}</div>

                    <Divider marginTop={12} marginBottom={12} />

                    <div className="working-hours-title text-mid-contrast text-caption-2">
                        {getWorkingOrNot()}
                    </div>

                    <div className="working-hours text-footnote-bold">
                        {workingHours?.start && workingHours?.end
                            ? workingHours.start + ' - ' + workingHours.end
                            : unavailableOrAway()}
                    </div>
                </div>
            </Skeleton>
        </div>
    );
};

export default CoworkerCard;
