import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const EmptyBoxIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M6.47656 24.9883H21.5146C23.4219 24.9883 24.415 24.0039 24.415 22.123V17.0869C24.415 16.1992 24.3008 15.7598 23.8965 15.2061L20.9785 11.2598C19.959 9.87109 19.2471 9.20312 17.6387 9.20312H16.4521V10.6445H17.7266C18.3418 10.6445 18.957 11.1543 19.3438 11.6729L22.332 15.8389C22.6309 16.2344 22.5254 16.4014 22.0771 16.4014H16.8301C16.3203 16.4014 16.0918 16.7529 16.0918 17.1309V17.166C16.0918 18.1943 15.292 19.3018 14 19.3018C12.708 19.3018 11.9082 18.1943 11.9082 17.166V17.1309C11.9082 16.7529 11.6797 16.4014 11.1699 16.4014H5.92285C5.46582 16.4014 5.37793 16.208 5.65918 15.8389L8.64746 11.6729C9.04297 11.1543 9.6582 10.6445 10.2734 10.6445H16.4521V9.20312H10.3525C8.74414 9.20312 8.04102 9.87109 7.02148 11.2598L4.09473 15.2061C3.69043 15.7598 3.58496 16.1992 3.58496 17.0869V22.123C3.58496 24.0039 4.57812 24.9883 6.47656 24.9883Z"
                    fill={fill}
                />
                <rect x="13" y="3" width="2" height="4" fill={fill} />
                <rect
                    x="7.13403"
                    y="4"
                    width="2"
                    height="4"
                    transform="rotate(-30 7.13403 4)"
                    fill={fill}
                />
                <rect x="19" y="3" width="2" height="4" transform="rotate(30 19 3)" fill={fill} />
            </svg>
        </span>
    );
};

export default EmptyBoxIcon;
