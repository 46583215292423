// GENERATED FILE - DO NOT MODIFY

export enum ClubCategoryDto {
    Animals = "Animals",
    Art = "Art",
    Books = "Books",
    Cinema = "Cinema",
    Food = "Food",
    Games = "Games",
    Hobbies = "Hobbies",
    Music = "Music",
    Social = "Social",
    Sports = "Sports",
    Technology = "Technology",
    Travel = "Travel",
}