import { DEBOUNCE_DELAY_400 } from 'Models/Constants';
import { useEffect, useState } from 'react';

function useDebounce<Type>(value: Type, delay?: number): Type {
    const [debounceValue, setDebouceValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(
            () => {
                setDebouceValue(value);
            },
            delay ? delay : DEBOUNCE_DELAY_400
        );

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debounceValue;
}

export default useDebounce;
