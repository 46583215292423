import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { default as React, FunctionComponent, useEffect, useState } from 'react';
import { PolicyInfoStoreObjectiveStats } from 'Stores/PolicyStore';
import { calculateObjectiveProgressFromDefaultScheduleEvents } from 'Utils/PolicyObjectivesUtils';
import ObjectiveInfo from './objective-info';
import TimeInfo from './time-info';
import './policy-wrapper-box-informations.less';
import { PolicyBundleObjectivesDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesDto';

interface PolicyWrapperBoxInformationsProps {
    events: ScheduleEventDto[];
    objectives?: PolicyBundleObjectivesDto | null;
    forSpecificTeamSchedule?: boolean;
}

const PolicyWrapperBoxInformations: FunctionComponent<PolicyWrapperBoxInformationsProps> = ({
    events,
    objectives,
    forSpecificTeamSchedule,
}) => {
    const [objectiveStats, setObjectiveStats] = useState<PolicyInfoStoreObjectiveStats>();

    useEffect(() => {
        if (objectives) {
            setObjectiveStats(
                calculateObjectiveProgressFromDefaultScheduleEvents(events, objectives)
            );
        }
    }, [objectives, events]);

    return (
        <div className="PolicyWrapperBoxInformations">
            {objectiveStats?.objectives &&
                Object.values(objectiveStats?.objectives).some((obj) => obj !== null) && (
                    <ObjectiveInfo
                        objectiveStats={objectiveStats}
                        displayEditButton={!forSpecificTeamSchedule}
                    />
                )}

            <TimeInfo events={events} />
        </div>
    );
};

export default PolicyWrapperBoxInformations;
