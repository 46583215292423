import Icon from 'Components/icons/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

interface ErrorMessageProps {
    stringKey?: string;
    className?: string;
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
    stringKey,
    className,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`ErrorMessage ${className ?? ''}`}>
            <Icon iconName="WarningIcon" />
            <div className="text">{t(`${stringKey || 'Errors.token_error_title'}`)}</div>
        </div>
    );
};
