// GENERATED FILE - DO NOT MODIFY
import { ClubDetailsDto } from 'Api/Features/Clubs/Dtos/ClubDetailsDto';
import { CreateClubRequestDto } from 'Api/Features/Clubs/Dtos/CreateClubRequestDto';
import { CreateClubResponseDto } from 'Api/Features/Clubs/Dtos/CreateClubResponseDto';
import { GetClubMembersRequestDto } from 'Api/Features/Clubs/Dtos/GetClubMembersRequestDto';
import { GetClubMembersResponseDto } from 'Api/Features/Clubs/Dtos/GetClubMembersResponseDto';
import { GetClubRequestDto } from 'Api/Features/Clubs/Dtos/GetClubRequestDto';
import { GetClubsRequestDto } from 'Api/Features/Clubs/Dtos/GetClubsRequestDto';
import { GetClubsResponseDto } from 'Api/Features/Clubs/Dtos/GetClubsResponseDto';
import { UpdateClubInvitationsRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubInvitationsRequestDto';
import { UpdateClubMembersRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubMembersRequestDto';
import { UpdateClubRequestDto } from 'Api/Features/Clubs/Dtos/UpdateClubRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ClubProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getClub(clubId: string, request: GetClubRequestDto | null): Promise<ClubDetailsDto | null> {
        const uri = this.buildUri(
            "/clubs/{clubId}",
            { clubId: clubId },
            { ...request || undefined }
        );

        const data: ClubDetailsDto | null = await this.httpClient.get<ClubDetailsDto | null>(uri);
        return data;
    }

    public async getClubs(request: GetClubsRequestDto | null): Promise<GetClubsResponseDto | null> {
        const uri = this.buildUri(
            "/clubs",
            null,
            { ...request || undefined }
        );

        const data: GetClubsResponseDto | null = await this.httpClient.get<GetClubsResponseDto | null>(uri);
        return data;
    }

    public async getClubMembers(clubId: string, request: GetClubMembersRequestDto | null): Promise<GetClubMembersResponseDto | null> {
        const uri = this.buildUri(
            "/clubs/{clubId}/members",
            { clubId: clubId },
            { ...request || undefined }
        );

        const data: GetClubMembersResponseDto | null = await this.httpClient.get<GetClubMembersResponseDto | null>(uri);
        return data;
    }

    public async createClub(request: CreateClubRequestDto | null): Promise<CreateClubResponseDto | null> {
        const uri = this.buildUri(
            "/clubs",
            null,
            null
        );

        const data: CreateClubResponseDto | null = await this.httpClient.post<CreateClubRequestDto | null, CreateClubResponseDto | null>(uri, request);
        return data;
    }

    public async updateClub(clubId: string, request: UpdateClubRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/clubs/{clubId}",
            { clubId: clubId },
            null
        );

        await this.httpClient.patch<UpdateClubRequestDto | null, void>(uri, request);
    }

    public async deleteClub(clubId: string): Promise<void> {
        const uri = this.buildUri(
            "/clubs/{clubId}",
            { clubId: clubId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateClubMembers(clubId: string, request: UpdateClubMembersRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/clubs/{clubId}/update-members",
            { clubId: clubId },
            null
        );

        await this.httpClient.post<UpdateClubMembersRequestDto | null, void>(uri, request);
    }

    public async updateClubInvitations(clubId: string, request: UpdateClubInvitationsRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/clubs/{clubId}/update-invitations",
            { clubId: clubId },
            null
        );

        await this.httpClient.post<UpdateClubInvitationsRequestDto | null, void>(uri, request);
    }
}