import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import CircleToggleDays from 'Components/circle-toggle-days';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    TWENTY_FOUR_HOUR_MINUTE,
} from 'Models/Constants';
import moment from 'moment';
import React from 'react';
import TimeDisplay from 'Routes/settings/policies/components/time-display';
import { generateKey } from 'Utils/ArrayUtils';
import './index.less';

interface TimeRange {
    startTime: string;
    endTime: string;
}

interface CreateTimeRange {
    startTime?: string;
    endTime?: string;
}

export interface CreateCoreHoursRow {
    activeDays: DayOfWeekDto[];
    timeRange: CreateTimeRange[];
}
export interface CoreHoursRow {
    activeDays: DayOfWeekDto[];
    timeRange: TimeRange[];
}

interface DisplayCoreHoursProps {
    data: CoreHoursRow[];
}

const DisplayCoreHours: React.FunctionComponent<DisplayCoreHoursProps> = observer(({ data }) => {
    const { languageStore } = useStores();
    return (
        <div className="DisplayCoreHours">
            {data.map((row, i) => (
                <div className="core-hours-row" key={generateKey(i)}>
                    <CircleToggleDays activeDays={row.activeDays} />

                    <div className="TimeDisplay-wrapper">
                        {row.timeRange.map((timeRange, ii) => (
                            <TimeDisplay
                                key={generateKey(i + ii)}
                                start={moment(timeRange.startTime, TWENTY_FOUR_HOUR_MINUTE).format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )}
                                end={moment(timeRange.endTime, TWENTY_FOUR_HOUR_MINUTE).format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
});

export default DisplayCoreHours;
