import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CertificationIcon: FunctionComponent<SvgProps> = ({ onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="30"
                height="29"
                viewBox="0 0 30 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g clipPath="url(#clip0_3884_39084)">
                    <path
                        d="M24.9049 7.37993L24.9749 10.8799C24.9749 10.9899 25.0149 11.0899 25.0749 11.1799L27.1849 13.9699C27.3149 14.1399 27.3249 14.3799 27.1949 14.5599L25.1949 17.4299C25.1349 17.5199 25.1049 17.6199 25.1049 17.7299L25.1749 21.2299C25.1749 21.4499 25.0449 21.6499 24.8349 21.7199L21.5249 22.8699C21.4249 22.8999 21.3349 22.9699 21.2749 23.0599L19.2749 25.9299C19.1449 26.1099 18.9249 26.1899 18.7149 26.1199L15.3649 25.0999C15.2649 25.0699 15.1549 25.0699 15.0549 25.0999L11.7449 26.2499C11.5349 26.3199 11.3049 26.2499 11.1749 26.0799L9.06492 23.2899C9.00492 23.1999 8.91492 23.1399 8.80492 23.1099L5.45492 22.0899C5.24492 22.0299 5.10492 21.8399 5.09492 21.6199L5.02492 18.1199C5.02492 18.0099 4.98492 17.9099 4.92492 17.8199L2.81492 15.0299C2.68492 14.8599 2.67492 14.6199 2.80492 14.4399L4.80492 11.5699C4.86492 11.4799 4.89492 11.3799 4.89492 11.2699L4.82492 7.76993C4.82492 7.54993 4.95492 7.34993 5.16492 7.27993L8.47492 6.12993C8.57492 6.09993 8.66492 6.02993 8.72492 5.93993L10.7249 3.06993C10.8549 2.88993 11.0749 2.80993 11.2849 2.87993L14.6349 3.89993C14.7349 3.92993 14.8449 3.92993 14.9449 3.89993L18.2549 2.74993C18.4649 2.67993 18.6949 2.74993 18.8249 2.91993L20.9349 5.70993C20.9949 5.79993 21.0849 5.85993 21.1949 5.88993L24.5449 6.90993C24.7549 6.96993 24.8949 7.15993 24.9049 7.37993Z"
                        fill="#E9E8FD"
                        stroke="url(#paint0_linear_3884_39084)"
                    />
                    <path
                        d="M11.665 13.82L12.68 14.86L13.695 15.9L18.325 11.11C18.515 10.91 18.745 10.81 18.995 10.82C19.245 10.82 19.465 10.93 19.665 11.13C19.845 11.33 19.935 11.56 19.945 11.82C19.945 12.08 19.855 12.3 19.665 12.5L14.485 17.86C14.255 18.09 13.995 18.21 13.695 18.21C13.395 18.21 13.135 18.09 12.905 17.86L10.355 15.22C10.175 15.03 10.085 14.81 10.085 14.54C10.085 14.27 10.175 14.05 10.355 13.86C10.545 13.66 10.775 13.56 11.025 13.57C11.285 13.57 11.495 13.67 11.665 13.84V13.82Z"
                        fill="#6E53DC"
                        fillOpacity="0.6"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_3884_39084"
                        x1="2.71228"
                        y1="2.72302"
                        x2="25.4263"
                        y2="17.7273"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#6B40ED" />
                        <stop offset="1" stopColor="#9373F2" />
                    </linearGradient>
                    <clipPath id="clip0_3884_39084">
                        <rect
                            width="25.57"
                            height="24.56"
                            fill="white"
                            transform="translate(2.21497 2.21997)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default CertificationIcon;
