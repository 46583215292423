import * as yup from 'yup';

export const CreateOfficeSchema = yup.object(
    Object.assign(
        {},
        {
            name: yup
                .string()
                .required('Errors.error_required')
                .min(2, 'Errors.error_min_2')
                .max(40, 'Errors.error_max_40'),
            timeZone: yup.string().required('Errors.error_required'),
        }
    )
);

export const AddEditSpaceModalSchema = yup.object(
    Object.assign(
        {},
        {
            name: yup
                .string()
                .required('Errors.error_required')
                .min(2, 'Errors.error_min_2')
                .max(40, 'Errors.error_max_40'),
            capacity: yup
                .number()
                .required('Errors.error_required')
                .min(1, 'Errors.error_min_number_1')
                .max(500, 'Errors.error_max_number_500'),
        }
    )
);
