import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubTravelIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M12 21C10.755 21 9.585 20.7636 8.49 20.2908C7.395 19.8186 6.4425 19.1775 5.6325 18.3675C4.8225 17.5575 4.1814 16.605 3.7092 15.51C3.2364 14.415 3 13.245 3 12C3 10.755 3.2364 9.585 3.7092 8.49C4.1814 7.395 4.8225 6.4425 5.6325 5.6325C6.4425 4.8225 7.395 4.1811 8.49 3.7083C9.585 3.2361 10.755 3 12 3C13.245 3 14.415 3.2361 15.51 3.7083C16.605 4.1811 17.5575 4.8225 18.3675 5.6325C19.1775 6.4425 19.8186 7.395 20.2908 8.49C20.7636 9.585 21 10.755 21 12C21 13.245 20.7636 14.415 20.2908 15.51C19.8186 16.605 19.1775 17.5575 18.3675 18.3675C17.5575 19.1775 16.605 19.8186 15.51 20.2908C14.415 20.7636 13.245 21 12 21ZM11.1 19.155V17.4C10.605 17.4 10.1814 17.2239 9.8292 16.8717C9.4764 16.5189 9.3 16.095 9.3 15.6V14.7L4.98 10.38C4.935 10.65 4.8936 10.92 4.8558 11.19C4.8186 11.46 4.8 11.73 4.8 12C4.8 13.815 5.3964 15.405 6.5892 16.77C7.7814 18.135 9.285 18.93 11.1 19.155ZM17.31 16.86C17.61 16.53 17.88 16.1736 18.12 15.7908C18.36 15.4086 18.5589 15.0111 18.7167 14.5983C18.8739 14.1861 18.9939 13.7625 19.0767 13.3275C19.1589 12.8925 19.2 12.45 19.2 12C19.2 10.53 18.7914 9.1875 17.9742 7.9725C17.1564 6.7575 16.065 5.88 14.7 5.34V5.7C14.7 6.195 14.5239 6.6186 14.1717 6.9708C13.8189 7.3236 13.395 7.5 12.9 7.5H11.1V9.3C11.1 9.555 11.0139 9.7686 10.8417 9.9408C10.6689 10.1136 10.455 10.2 10.2 10.2H8.4V12H13.8C14.055 12 14.2689 12.0861 14.4417 12.2583C14.6139 12.4311 14.7 12.645 14.7 12.9V15.6H15.6C15.99 15.6 16.3425 15.7161 16.6575 15.9483C16.9725 16.1811 17.19 16.485 17.31 16.86Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubTravelIcon;
