import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import {
    AppleIcon,
    CalendarIcon,
    CalendarPlusIcon,
    GoogleCalendarIconSvg,
    OutlookIcon,
} from 'Components/icons';
import { theme } from 'Style/theme';
import { Divider, Popover, Tooltip } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import {
    generateCalendarICSFileForHappening,
    generateGoogleCalendarLinkForHappening,
    generateOutlookLinkForHappening,
} from 'Utils/ExternalCalendarUtils';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';

interface AddToCalendarBtnProps {
    happening: HappeningDto;
    iconProps: AddCalendarIconProps;
}

export interface AddCalendarIconProps {
    width: number;
    color: string;
}

const AddToCalendarBtn: FunctionComponent<AddToCalendarBtnProps> = observer(
    ({ happening, iconProps }) => {
        const { t } = useTranslation();
        const { userStore } = useStores();
        const [popOverVisible, setPopOverVisible] = useState(false);
        const popOverContent = (
            <>
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        generateGoogleCalendarLinkForHappening(happening);
                        setPopOverVisible(false);
                    }}
                >
                    <GoogleCalendarIconSvg />
                    {t('google_calendar')}
                </div>
                <Divider />
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        generateOutlookLinkForHappening(happening);
                        setPopOverVisible(false);
                    }}
                >
                    <OutlookIcon />
                    {t('outlook')}
                </div>
                <Divider />
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        generateCalendarICSFileForHappening(
                            happening,
                            userStore.userInfo?.timeZone ?? ''
                        );
                        setPopOverVisible(false);
                    }}
                >
                    <AppleIcon />
                    {t('apple')}
                </div>
                <Divider />
                <div
                    className="item text-body text-high-contrast"
                    onClick={() => {
                        generateCalendarICSFileForHappening(
                            happening,
                            userStore.userInfo?.timeZone ?? ''
                        );
                        setPopOverVisible(false);
                    }}
                >
                    <CalendarIcon fill={theme['primary-mid-contrast']} />
                    {t('other_calendar')}
                </div>
            </>
        );

        return (
            <div className="AddToCalendarBtn">
                <div className="cursor-pointer">
                    <Tooltip
                        overlay={t('add_to_calendar')}
                        placement="top"
                        arrowPointAtCenter={true}
                    >
                        <Popover
                            content={popOverContent}
                            trigger="click"
                            visible={popOverVisible}
                            onVisibleChange={(visible: boolean) => setPopOverVisible(visible)}
                            placement="bottomRight"
                            overlayClassName="header-pop-over"
                        >
                            <CalendarPlusIcon
                                width={iconProps.width}
                                height={iconProps.width}
                                fill={iconProps.color}
                            />
                        </Popover>
                    </Tooltip>
                </div>
            </div>
        );
    }
);

export default AddToCalendarBtn;
