import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect } from 'react';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';

/**
 * ResetPasswordCallback
 * Azure AD will redirect here once the "reset password" flow is finished
 */
const ResetPasswordCallback: FunctionComponent = observer(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    const { languageStore } = useStores();

    useEffect(() => {
        const resetPasswordCallback = async () => {
            // Check if success or error.
            const error = urlParams.get('error');
            const errorDescription = urlParams.get('error_description');

            if (!error) {
                // Success => go to login page
                await azureAdAuthenticationService.runSignInFlow(languageStore.currentLanguage);
            } else {
                // User cancelled
                if (errorDescription?.startsWith('AADB2C90091')) {
                    await azureAdAuthenticationService.runSignInFlow(languageStore.currentLanguage);
                }

                // Other error, show error page?
            }
        };

        resetPasswordCallback();
    }, []);

    return <div className="ResetPasswordCallback"></div>;
});

export default ResetPasswordCallback;
