import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const InformationCircleIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M14.0058 2C7.42871 2 2 7.43133 2 14C2 20.5687 7.44031 26 14.0174 26C20.5829 26 26 20.5687 26 14C26 7.43133 20.5713 2 14.0058 2ZM14.0058 4.39071C19.3301 4.39071 23.5872 8.67311 23.5872 14C23.5872 19.3269 19.3301 23.5977 14.0174 23.5977C8.69309 23.5977 4.41276 19.3269 4.40116 14C4.38956 8.67311 8.68149 4.39071 14.0058 4.39071ZM14.0174 12.0735C13.3794 12.0735 13.0082 12.4333 12.9966 13.1064L12.8226 18.6886C12.7994 19.3733 13.3098 19.8723 14.029 19.8723C14.7482 19.8723 15.247 19.3849 15.2238 18.7002L15.0614 13.1064C15.0382 12.4449 14.667 12.0735 14.0174 12.0735ZM14.0174 8.2205C13.2634 8.2205 12.6486 8.76595 12.6486 9.5087C12.6486 10.2398 13.2518 10.7853 14.0174 10.7853C14.7714 10.7853 15.3862 10.2398 15.3862 9.5087C15.3862 8.77756 14.7598 8.2205 14.0174 8.2205Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default InformationCircleIcon;
