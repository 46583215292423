import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const DoorIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <rect x="6" y="6" width="6" height="16" fill={fill} />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2584 4.41724C13.3455 4.39648 13.4361 4.39546 13.5236 4.41426L13.5224 4.41546L21.9224 6.21426C22.0564 6.24304 22.1765 6.31685 22.2628 6.42339C22.349 6.52993 22.3962 6.66279 22.3964 6.79986V21.1987C22.3968 21.336 22.35 21.4693 22.264 21.5763C22.178 21.6833 22.0578 21.7575 21.9236 21.7867L13.5236 23.5879C13.4361 23.6067 13.3455 23.6056 13.2584 23.5849C13.1714 23.5641 13.0901 23.5241 13.0204 23.4679C12.9508 23.4116 12.8947 23.3405 12.8561 23.2597C12.8176 23.1789 12.7976 23.0906 12.7976 23.0011V5.00106C12.7976 4.91155 12.8176 4.82318 12.8561 4.7424C12.8947 4.66163 12.9508 4.59051 13.0204 4.53424C13.0901 4.47798 13.1714 4.43801 13.2584 4.41724Z"
                    fill={fill}
                />
                <path
                    d="M12 22V20.8567H7.19952V7.14327H12V6H6.59976C6.44069 6 6.28814 6.06023 6.17567 6.16743C6.06319 6.27463 6 6.42003 6 6.57163V21.4284C6 21.58 6.06319 21.7254 6.17567 21.8326C6.28814 21.9398 6.44069 22 6.59976 22H12Z"
                    fill="white"
                />
                <path
                    d="M16.9821 13.9999C16.9821 14.2385 16.8873 14.4675 16.7185 14.6362C16.5497 14.805 16.3208 14.8999 16.0821 14.8999C15.8434 14.8999 15.6145 14.805 15.4457 14.6362C15.277 14.4675 15.1821 14.2385 15.1821 13.9999C15.1821 13.7612 15.277 13.5322 15.4457 13.3635C15.6145 13.1947 15.8434 13.0999 16.0821 13.0999C16.3208 13.0999 16.5497 13.1947 16.7185 13.3635C16.8873 13.5322 16.9821 13.7612 16.9821 13.9999Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default DoorIcon;
