import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MapPinWithCircleIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M13.877 20.502C14.2637 20.502 14.7998 19.0342 14.7998 16.6611V11.124C16.2852 10.7197 17.3662 9.35742 17.3662 7.74902C17.3662 5.81543 15.8193 4.24219 13.877 4.24219C11.9434 4.24219 10.3965 5.81543 10.3965 7.74902C10.3965 9.34863 11.4688 10.7109 12.9541 11.1152V16.6611C12.9541 19.0254 13.499 20.502 13.877 20.502ZM12.8926 7.94238C12.2598 7.94238 11.6973 7.38867 11.6973 6.73828C11.6973 6.09668 12.2598 5.55176 12.8926 5.55176C13.5518 5.55176 14.0879 6.09668 14.0879 6.73828C14.0879 7.38867 13.5518 7.94238 12.8926 7.94238ZM13.877 23.9648C19.4668 23.9648 22.8154 22.084 22.8154 19.9219C22.8154 17.5576 19.1943 16.0283 16.3027 15.9053V17.4785C18.2715 17.5664 20.8115 18.4717 20.8115 19.7461C20.8115 21.2578 17.8936 22.3037 13.877 22.3037C9.86035 22.3037 6.95117 21.2666 6.95117 19.7461C6.95117 18.4717 9.49121 17.5664 11.4512 17.4785V15.9053C8.55957 16.0283 4.93848 17.5576 4.93848 19.9219C4.93848 22.084 8.28711 23.9648 13.877 23.9648Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default MapPinWithCircleIcon;
