import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import './index.less';
import Skeleton from 'Components/skeleton';
import { CountSkeletonShape } from './skeleton-shape';

interface HorizontalRadioProps {
    name: string;
    defaultValue: string;
    options: HorizontalRadioOption[];
    onChange: (value: string) => void;
    showBorderBottom?: boolean;
}

export interface HorizontalRadioOption {
    label: string;
    value: string;
    count?: number;
    countIsLoading: boolean;
}

const HorizontalRadio: FunctionComponent<HorizontalRadioProps> = ({
    name,
    defaultValue,
    options,
    onChange,
    showBorderBottom = false,
}) => {
    const [radioValue, setRadioValue] = useState({
        value: defaultValue,
    });

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRadioValue({ value: e.target.value });
        onChange(e.target.value);
    };

    useEffect(() => {
        setRadioValue({ value: defaultValue });
    }, [defaultValue]);

    return (
        <div className="HorizontalRadio">
            <div className={`HorizontalRadio-wrapper showBorderBottom-${showBorderBottom}`}>
                {options.map((option, i) => (
                    <React.Fragment key={name + i}>
                        <input
                            type="radio"
                            id={name + i}
                            name={name}
                            value={option.value}
                            checked={option.value === radioValue.value}
                            onChange={(e) => handleOnChange(e)}
                        />

                        <label className="text-callout" htmlFor={name + i}>
                            {option.label}{' '}
                            <Skeleton
                                placeholder={CountSkeletonShape}
                                isLoaded={!option.countIsLoading}
                                defaultPadding={false}
                            >
                                {option.count !== undefined ? (
                                    <span className="text-caption-1-body">
                                        {option.count > 999 ? '999+' : option.count}
                                    </span>
                                ) : undefined}
                            </Skeleton>
                        </label>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default HorizontalRadio;
