import React from 'react';
import './index.less';

export const dashboardMobileHelloGlanceDropdownShape = (
    <div className="dashboardMobileHelloGlanceDropdownShape HelloGlanceDropdown">
        <div className="hello">
            <div className="rect-01" />
        </div>
    </div>
);

export const dashboardMobileOfficeAndTeam = (
    <div className="dashboardMobileOfficeAndTeam OfficeAndTeam">
        <div className="office-name">
            <div className="roundRect-01" />
            <div className="rect-02" />
        </div>

        <div className="label-value-row border-bottom">
            <div className="rect-03" />
            <div className="rect-04" />
        </div>

        <div className="label-value-row">
            <div className="rect-03" />
            <div className="rect-04" />
        </div>

        <div className="roundRect-05" />

        <div className="coworkers-images">
            <div className="rect-06" />
            <div className="roundRect-07" />
            <div className="rect-08" />
        </div>
    </div>
);

export const wrapperCardDayHorizontal = (
    <div className="Skeleton animation wrapperCardDayHorizontal">
        <div className="ChronoTypeDate isToday-false">
            <div className="circle-1" />

            <div className="wrapper">
                <div className="rect-2 day" />
                <div className="rect-3 month" />
            </div>

            <div className="circle-4" />
            <div className="roundRect-5" />
        </div>
    </div>
);
