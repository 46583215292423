import { Form } from 'antd';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { UpdateUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserDefaultScheduleRequestDto';
import Button from 'Components/button';
import Modal from 'Components/modal';
import WeeklyHourPrefCalendar, {
    CalendarForType,
    DefaultScheduleEventExtended,
} from 'Components/weekly-hour-pref-calendar';
import WhiteCard from 'Components/white-card';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleService } from 'Services/ScheduleService';
import { PolicyInfoStoreObjectiveStats } from 'Stores/PolicyStore';
import {
    calendarEventOverlapsRestriction,
    fetchDefaultScheduleAttendence,
} from 'Utils/CalendarUtils';
import { calculateObjectiveProgressFromDefaultScheduleEvents } from 'Utils/PolicyObjectivesUtils';
import { toStartNextDayIfEndOfDay } from 'Utils/TimeUtils';
import EditHourPrefModalCardObjectives from './card-objectives';
import './index.less';
import { UpdateTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/UpdateTeamDefaultScheduleRequestDto';
import { TimeInfo } from 'Components/policy-wrapper-box-informations';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { GetTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetTeamDefaultScheduleRequestDto';
import { E004006, E006002, FORMAT_YEAR_MONTH_DAY, TWENTY_FOUR_HOUR_MINUTE } from 'Models/Constants';
import moment from 'moment';
import { WarningIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { UpdateUserDefaultSchedulePreviewResponseDto } from 'Api/Features/Schedules/Dtos/UpdateUserDefaultSchedulePreviewResponseDto';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { OfficeService } from 'Services/OfficeService';
import SubmitButton from 'Components/submit-button/submit-button';

interface EditHourPrefModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
    schedule: ScheduleEventDto[];
    calendarForType: CalendarForType;
    schedulePreferenceTeamId?: string;
    teamSchedulePolicy?: PolicyBundleDto;
    onboardingOnComplete?: (hasChanges: boolean, events?: ScheduleEventDto[]) => void;
    onResetEventCapacities?: () => void;
}

const EditHourPrefModal: React.FunctionComponent<EditHourPrefModalProps> = observer(
    ({
        visible,
        onComplete,
        schedule,
        calendarForType,
        schedulePreferenceTeamId,
        teamSchedulePolicy,
        onboardingOnComplete,
    }) => {
        //#region Hooks
        const {
            globalLoadingStore,
            toastStore,
            userStore,
            policyStore,
            languageStore,
            confirmationModalStore,
        } = useStores();
        const scheduleService = useService(ScheduleService);
        const officeService = useService(OfficeService);

        const { t } = useTranslation();

        const [newPeriodClicked, setNewPeriodClicked] = useState(false);
        const [events, setEvents] = useState<DefaultScheduleEventExtended[]>(schedule);
        const [invalidCount, setInvalidCount] = useState(0);
        const [policyObjectiveStats, setPolicyObjectiveStats] = useState<
            PolicyInfoStoreObjectiveStats | undefined
        >();

        useEffect(() => {
            if (userStore.userInfo?.id) {
                policyStore.cachedSetObjectivesStats();
                policyStore.cachedSetPolicyRestrictions();
            }
        }, [userStore.userInfo?.id]);

        useEffect(() => {
            //if any events are invalid, set the string near the save button
            let count = 0;
            events.forEach((event) => {
                if (
                    event.isAvailableForUser === false ||
                    calendarEventOverlapsRestriction(
                        event.startTime ?? '',
                        event.endTime ?? '',
                        languageStore.currentLanguage,
                        policyStore.policyInfo?.restrictions ?? undefined,
                        true,
                        userStore.userInfo?.timeZone ?? ''
                    )
                )
                    count++;
            });

            setInvalidCount(count);
        }, [events, policyStore.policyInfo?.restrictions]);

        useEffect(() => {
            //take policies from store for current user
            if (schedulePreferenceTeamId === undefined) {
                setPolicyObjectiveStats(
                    calculateObjectiveProgressFromDefaultScheduleEvents(
                        events,
                        policyStore.policyInfo?.policyBundleObjectivesDto
                    )
                );
            } else {
                setPolicyObjectiveStats(
                    calculateObjectiveProgressFromDefaultScheduleEvents(
                        events,
                        teamSchedulePolicy?.objectives
                    )
                );
            }
        }, [
            events,
            policyStore.policyInfo,
            userStore.userInfo,
            schedulePreferenceTeamId,
            teamSchedulePolicy,
        ]);

        const fetchScheduleAttendence = async (events: ScheduleEventDto[]) => {
            setEvents(
                await fetchDefaultScheduleAttendence(
                    events,
                    officeService,
                    userStore.userInfo?.timeZone ?? '',
                    toastStore
                )
            );
        };

        //some of the period's space has been fully booked before we sent request; reset the capacity indications on all calendar events
        const handleSpaceConcurrencyError = async () => {
            await fetchScheduleAttendence(events);
        };

        const handleRefusedSpaceExceptions = (
            exceptions: UpdateUserDefaultSchedulePreviewResponseDto | null
        ) => {
            //group exceptions by day of week. Exceptions can be up to x amount of days in future all that is important is the day of week
            const exceptionsByDayOfWeek: Map<number, GetOfficeSpaceOccupancyResponsePeriodDto[]> =
                new Map();

            exceptions?.occupancyExceptions?.forEach((exception) => {
                if (exception !== null) {
                    const dayOfException: number = moment.utc(exception?.startTime).day();
                    const exceptionsforDay: GetOfficeSpaceOccupancyResponsePeriodDto[] =
                        exceptionsByDayOfWeek.get(dayOfException) ?? [];
                    exceptionsforDay.push(exception);
                    exceptionsByDayOfWeek.set(dayOfException, exceptionsforDay);
                }
            });

            //look through events and find corresponding start/end time with correct day of week
            setEvents((prev) => {
                const eventsWithExceptions = prev.map((event) => {
                    const eventDayOfWeek: number = moment.utc(event.startTime).day();
                    const exceptionsForDay: GetOfficeSpaceOccupancyResponsePeriodDto[] | undefined =
                        exceptionsByDayOfWeek.get(eventDayOfWeek);
                    const eventWithExceptions: DefaultScheduleEventExtended = {
                        ...event,
                        schedulePreviewExceptions: exceptionsForDay?.filter(
                            (exception) =>
                                moment.utc(exception?.startTime).format(TWENTY_FOUR_HOUR_MINUTE) ===
                                    moment.utc(event.startTime).format(TWENTY_FOUR_HOUR_MINUTE) &&
                                moment.utc(exception?.endTime).format(TWENTY_FOUR_HOUR_MINUTE) ===
                                    moment.utc(event.endTime).format(TWENTY_FOUR_HOUR_MINUTE)
                        ),
                    };
                    return eventWithExceptions;
                });
                return [...eventsWithExceptions];
            });
        };

        const confirmSpaceExceptions = async (): Promise<boolean> => {
            return await confirmationModalStore.confirm({
                icon: <WarningIcon fill={theme['warning-mid-contrast']} />,
                title: t('Profile.default_schedule_space_capacity_warning_title'),
                message: t('Profile.default_schedule_space_capacity_warning_message'),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            });
        };

        //#region Submit / Exit
        const onDismiss = (success = false): void => {
            if (onboardingOnComplete) {
                onboardingOnComplete(success);
                return;
            }
            onComplete(success);
        };

        const onSubmit = async (): Promise<void> => {
            try {
                globalLoadingStore.addLoading();

                const request: UpdateUserDefaultScheduleRequestDto = {
                    events: events.map((event) => ({
                        ...event,
                        endTime: toStartNextDayIfEndOfDay(true, event.endTime),
                        officeId: event.office?.id,
                        officeSpaceId: event.officeSpace?.id,
                    })),
                };

                if (calendarForType === CalendarForType.UserProfile && userStore.userInfo?.id) {
                    const previewResponse = await scheduleService.updateUserDefaultSchedulePreview(
                        userStore.userInfo.id,
                        request
                    );
                    globalLoadingStore.removeLoading();
                    if (
                        previewResponse?.occupancyExceptions?.length === 0 ||
                        (await confirmSpaceExceptions())
                    ) {
                        if (onboardingOnComplete) {
                            onboardingOnComplete(true, events);
                            return;
                        }
                        globalLoadingStore.addLoading();
                        await scheduleService.updateUserDefaultSchedule(
                            userStore.userInfo.id,
                            request
                        );
                    } else {
                        handleRefusedSpaceExceptions(previewResponse);
                        return;
                    }
                }

                if (calendarForType === CalendarForType.TeamSchedule && schedulePreferenceTeamId) {
                    const teamRequest: UpdateTeamDefaultScheduleRequestDto = {
                        events: request.events,
                    };
                    await scheduleService.updateTeamDefaultSchedule(
                        schedulePreferenceTeamId,
                        teamRequest
                    );
                }

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('Profile.weekly_hours_preferences'),
                    }),
                });

                onDismiss(true);
            } catch (e: any) {
                if (e.response?.data?.errorCode === E004006) {
                    toastStore.toast({
                        type: 'error',
                        message: e.response.data.errorDescription,
                    });
                    handleSpaceConcurrencyError();
                } else if (e.response?.data?.errorCode === E006002) {
                    toastStore.toast({
                        type: 'error',
                        message: e.response.data.errorDescription,
                    });
                } else if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#endregion

        const handleClearSchedule = () => {
            setEvents([]);
        };

        const handleRevertToTeamSchedule = async () => {
            try {
                globalLoadingStore.addLoading();
                if (userStore.userInfo?.team?.id) {
                    const request: GetTeamDefaultScheduleRequestDto = {
                        week: moment().format(FORMAT_YEAR_MONTH_DAY),
                    };
                    const response = await scheduleService.getTeamDefaultSchedule(
                        userStore.userInfo.team.id!,
                        request
                    );
                    setEvents(
                        response?.events
                            ?.filter((event) => event !== null)
                            .map((event) => event!) ?? []
                    );
                }
            } catch (e: any) {
                if (!e.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        //#region Render
        return (
            <Modal
                className="EditHourPrefModal"
                visible={visible}
                onCancel={() => onDismiss()}
                headerText={t('Profile.weekly_hours_preferences')}
                width={1238}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => onDismiss()} />
                        {invalidCount > 0 && (
                            <div className="invalid-count text-callout text-mid-contrast">
                                {t('warning')} :&nbsp;
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            invalidCount > 1
                                                ? t('PlanSchedule.invalid_time_period_plural', {
                                                      param1: invalidCount,
                                                  })
                                                : t('PlanSchedule.invalid_time_period_singular', {
                                                      param1: invalidCount,
                                                  }),
                                    }}
                                />
                            </div>
                        )}
                        <SubmitButton text={t('save_changes')} type="primary" onClick={onSubmit} />
                    </>
                }
                noPadding
            >
                <div className={`calendar-container`}>
                    <Form layout="vertical" onFinish={onSubmit}>
                        <WeeklyHourPrefCalendar
                            events={events}
                            onEventsChange={(events) => setEvents(events)}
                            newPeriodClicked={newPeriodClicked}
                            onPeriodModalClosed={() => setNewPeriodClicked(false)}
                            restrictions={
                                schedulePreferenceTeamId
                                    ? teamSchedulePolicy?.restrictions ?? undefined
                                    : policyStore.policyInfo?.restrictions ?? undefined
                            }
                            calendarForType={
                                calendarForType === CalendarForType.UserProfile
                                    ? CalendarForType.UserProfile
                                    : CalendarForType.TeamSchedule
                            }
                            teamScheduleCoreHoursGroups={
                                teamSchedulePolicy?.objectives?.coreHoursGroups
                            }
                        />
                    </Form>
                </div>

                <div className="widgets-container">
                    <Button
                        text={t('add_new')}
                        type="floating"
                        width="hugged"
                        leftCircleIcon="AddIcon"
                        onClick={() => setNewPeriodClicked(true)}
                    />

                    {calendarForType === CalendarForType.UserProfile &&
                        userStore.userInfo?.team?.id && (
                            <div className="mt-20">
                                <Button
                                    text={t('team_schedule')}
                                    type="floating"
                                    width="hugged"
                                    height={'small'}
                                    leftCircleIcon="ArrowClockWiseIcon"
                                    onClick={() => handleRevertToTeamSchedule()}
                                />
                            </div>
                        )}

                    <div
                        className="cursor-pointer text-body text-primary-mid-contrast mt-20"
                        onClick={() => handleClearSchedule()}
                    >
                        {t('clear_schedule')}
                    </div>

                    <EditHourPrefModalCardObjectives policyObjectiveStats={policyObjectiveStats} />

                    <WhiteCard>
                        <TimeInfo events={events} dividerOrientation={'horizontal'} />
                    </WhiteCard>
                </div>
            </Modal>
        );
        //#endregion
    }
);

export default React.memo(EditHourPrefModal);
