import { Col, Input, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import Button from 'Components/button';
import { CustomDatePicker } from 'Components/date-picker';
import { CalendarIcon, CertificationIcon } from 'Components/icons';
import Modal from 'Components/modal';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORM_GUTTER } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import { CertificationSchema } from 'Schemas/CertificationSchema';
import LanguageCapitalizedText from 'Components/language-capitalized-text';
import moment from 'moment';
import { CertificationDtoWithId } from '../profile-tabs-content/profile-details-tab';
import { v4 as uuidv4 } from 'uuid';
import SubmitButton from 'Components/submit-button/submit-button';

interface EditCertificationModalProps {
    visible: boolean;
    onComplete: (success: boolean, certification?: CertificationDtoWithId) => void;
    certification?: CertificationDtoWithId;
}

const EditCertificationModal: React.FunctionComponent<EditCertificationModalProps> = observer(
    ({ visible, onComplete, certification }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const [errors, validateForm] = useFormValidation(CertificationSchema, form);
        const [selectedDate, setSelectedDate] = useState<Date | null>(null);

        useEffect(() => {
            if (certification) {
                const date = new Date(certification.date ?? '');
                form.setFieldsValue({
                    title: certification.title,
                    date: date,
                    source: certification.source,
                    url: certification.url,
                });
                setSelectedDate(date);
            }
        }, [certification]);

        //#region Submit / Exit
        const dismiss = (success = false, certification?: CertificationDtoWithId): void => {
            form.resetFields();
            onComplete(success, certification);
        };

        const submit = async (): Promise<void> => {
            const formData = form.getFieldsValue();

            const certificationObject: CertificationDtoWithId = {
                ...formData,
                date: moment(selectedDate).format(),
                id: certification ? certification.id : uuidv4(),
            };

            if (!(await validateForm(certificationObject))) {
                return;
            }

            dismiss(true, certificationObject);
        };
        //#region Submit / Exit

        return (
            <Modal
                className="EditCertificationModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t('certifications')}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                        <SubmitButton
                            text={certification ? t('save_changes') : t('add_certification')}
                            type="primary"
                            onClick={() => submit()}
                        />
                    </>
                }
                fixedHeight
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <div className="t-align-center mt-20 mb-20">
                        <CertificationIcon width={88} height={85} />
                    </div>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <div className="text-callout-bold mb-15">
                                <LanguageCapitalizedText>
                                    {t('information')}
                                </LanguageCapitalizedText>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <ValidatedFormItem label={t('name')} name={'title'} errors={errors}>
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                className="acquired-container"
                                label={t('acquired_on')}
                                name={'date'}
                                errors={errors}
                            >
                                <CustomDatePicker
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                    }}
                                    value={selectedDate}
                                />
                                <div className="calendar-icon">
                                    <CalendarIcon width={24} height={24} />
                                </div>
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <ValidatedFormItem
                                label={t('source') + ' ' + t('optional')}
                                name={'source'}
                                errors={errors}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={12}>
                            <ValidatedFormItem
                                label={t('information_link') + ' ' + t('optional')}
                                name={'url'}
                                errors={errors}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
);

export default EditCertificationModal;
