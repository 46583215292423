import { Layout } from 'antd';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import SectionHeader, { HeaderBackButton } from 'Components/section-header/section-header';
import SideNavigation from 'Components/side-navigation/side-navigation';
import { useService, useStores, useWindowDimensions } from 'Hooks';
import { observer } from 'mobx-react';
import {
    CLUBS_URL,
    CLUBS_URL_REGEX,
    COWORKERS_URL,
    COWORKERS_URL_REGEX,
    COWORKER_TIMESHEET_URL_REGEX,
    DASHBOARD_URL_REGEX,
    EVENTS_URL,
    EVENTS_URL_REGEX,
    GLOBAL_LANGUAGE_URL,
    HAPPENINGS_URL,
    KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED,
    OFFICES_URL,
    ONBOARDING_URL,
    PLAN_SCHEDULE_URL_REGEX,
    POLICIES_URL,
    PROFILE_URL_REGEX,
    SETTINGS_GLOBAL_LANGUAGE_URL_REGEX,
    SETTINGS_HAPPENINGS_URL_REGEX,
    SETTINGS_OFFICES_URL_REGEX,
    SETTINGS_POLICIES_URL_REGEX,
    SETTINGS_SLACK_INTEGRATION_URL_REGEX,
    SETTINGS_TEAMS_URL_REGEX,
    SETTINGS_URL,
    SETTINGS_URL_REGEX,
    SETTINGS_USERS_URL_REGEX,
    SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD,
    SLACK_INTEGRATION_URL,
    STATISTICS_REAL_ESTATE_URL_REGEX,
    TEAMS_URL,
    TEAMS_URL_REGEX,
    USERS_URL,
} from 'Models/Constants';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthenticationService } from 'Services/AuthenticationService';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import { StatusCode } from 'Stores/AuthorizationStore';
import { SupportedLanguage } from 'Stores/LanguageStore';
import './authenticated-layout.less';

interface AuthenticatedLayoutProps {
    onlyAuthorizedRoles?: ManagementRoleDto[];
}

interface SideNavigationContextInterface {
    collapsed: boolean;
    /**When screen size is smaller then 1560px, the sidenav is close by default and when it opens, should be over site content.
     * Otherwise when sidenav is open it pushes site content */
    isAutoCollapseThreshold: boolean;
}

export const SideNavigationContext = createContext<SideNavigationContextInterface | undefined>(
    undefined
);

const AuthenticatedLayout: React.FunctionComponent<AuthenticatedLayoutProps> = observer(
    ({ onlyAuthorizedRoles = [], children }) => {
        const location = useLocation();
        const history = useHistory();
        const { t } = useTranslation();
        const {
            userStore,
            authenticationStore,
            policyStore,
            authorizationStore,
            languageStore,
            systemStore,
        } = useStores();
        const azureAuthService = useService(AzureAdAuthenticationService);
        const authenticationService = useService(AuthenticationService);
        const windowDimensions = useWindowDimensions();
        const [sideNavContext, setSideNavContext] = useState<
            SideNavigationContextInterface | undefined
        >(undefined);

        //collapse
        useEffect(() => {
            const key = KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED;

            const autoCollapseThreshold =
                windowDimensions.windowWidth <= SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD;

            const previousSession = localStorage.getItem(key);
            if (autoCollapseThreshold) {
                setSideNavContext({
                    isAutoCollapseThreshold: autoCollapseThreshold,
                    collapsed: true,
                });
            } else if (previousSession) {
                setSideNavContext({
                    isAutoCollapseThreshold: autoCollapseThreshold,
                    collapsed: previousSession === 'true',
                });
            }
        }, [location.pathname, windowDimensions.windowWidth]);

        const onHandleDisplayModeClick = () => {
            const key = KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED;

            setSideNavContext((prev) => {
                localStorage.setItem(key, prev?.collapsed ? 'false' : 'true');
                return {
                    collapsed: !prev?.collapsed,
                    isAutoCollapseThreshold: prev?.isAutoCollapseThreshold ?? false,
                };
            });
        };

        const fetchPolicyInfo = useCallback(async () => {
            await policyStore.setPolicyRestrictionsAndObjectivesStats();
        }, []);

        const fetchUserInfo = useCallback(async () => {
            try {
                if (authenticationStore.userId) {
                    policyStore.setCallInProgress(true);
                    const user = await userStore.setUserInfo(authenticationStore.userId);
                    await fetchPolicyInfo();
                    if (user)
                        languageStore.setCurrentLanguage(
                            user.preferredLanguageCode as SupportedLanguage
                        );
                } else {
                    if (!authenticationService.runSignInPromise) {
                        authenticationStore.setReturnUrl(
                            window.location.pathname + window.location.search
                        );
                        authenticationService.runSignInPromise = azureAuthService.runSignInFlow(
                            languageStore.currentLanguage
                        );
                    }
                    await authenticationService.runSignInPromise;
                }
            } finally {
                policyStore.setCallInProgress(false);
            }
        }, []);

        useEffect(() => {
            if (userStore.userInfo === null && !userStore.callInProgress) fetchUserInfo();
        }, [fetchUserInfo, userStore.userInfo, userStore.callInProgress]);

        useEffect(() => {
            systemStore.cachedSetDefaultSystemLang();
        }, []);

        useEffect(() => {
            //redirect to onboarding in user has not seen onboarding
            if (
                userStore.userInfo &&
                !userStore.userInfo.hasSeenOnboarding &&
                !policyStore.callInProgress
            )
                history.push(ONBOARDING_URL);
        }, [
            userStore.userInfo,
            policyStore.callInProgress,
            userStore?.userInfo?.hasSeenOnboarding,
        ]);

        // Manage user is on page he does not have authorization for
        useEffect(() => {
            if (userStore.userInfo?.managementRoles && onlyAuthorizedRoles.length > 0) {
                if (
                    //employee has managementRoles: []
                    userStore.userInfo.managementRoles.length === 0 ||
                    !onlyAuthorizedRoles.some(
                        (authRoles) => authRoles === userStore.userInfo!.managementRoles![0]?.name
                    )
                )
                    return authorizationStore.handleErrorCode(StatusCode.forbidden);
            }
            authorizationStore.resetNotFoundAndForbidden();
        }, [userStore?.userInfo?.managementRoles, location.pathname, onlyAuthorizedRoles]);

        const headerTitle = useCallback((): string => {
            if (DASHBOARD_URL_REGEX.test(location.pathname)) {
                return t('SideNav.nav_dashboard');
            }
            if (COWORKERS_URL_REGEX.test(location.pathname)) {
                return t('SideNav.nav_coworkers');
            }
            if (CLUBS_URL_REGEX.test(location.pathname)) {
                return t('SideNav.nav_clubs');
            }
            if (EVENTS_URL_REGEX.test(location.pathname)) {
                return t('SideNav.nav_events');
            }
            if (PLAN_SCHEDULE_URL_REGEX.test(location.pathname)) {
                return t('Schedule.plan_your_schedule');
            }
            if (TEAMS_URL_REGEX.test(location.pathname)) {
                return t('SideNav.nav_teams');
            }
            if (SETTINGS_URL_REGEX.test(location.pathname)) {
                if (SETTINGS_USERS_URL_REGEX.test(location.pathname)) return t('users');
                else if (SETTINGS_TEAMS_URL_REGEX.test(location.pathname)) return t('teams');
                else if (SETTINGS_OFFICES_URL_REGEX.test(location.pathname)) return t('offices');
                else if (SETTINGS_POLICIES_URL_REGEX.test(location.pathname)) return t('policies');
                else return t('SideNav.nav_settings');
            }
            if (PROFILE_URL_REGEX.test(location.pathname)) {
                return t('my_profile');
            }

            if (STATISTICS_REAL_ESTATE_URL_REGEX.test(location.pathname)) {
                return t('SideNav.nav_real_estate_statistics');
            }

            return '';
        }, [location.pathname]);

        const backButton = useCallback((): HeaderBackButton | undefined => {
            const urlSections = location.pathname.split('/');
            if (SETTINGS_USERS_URL_REGEX.test(location.pathname)) {
                if (urlSections[urlSections.length - 1] === USERS_URL.replace('/', ''))
                    return { title: t('SideNav.nav_settings'), url: SETTINGS_URL };
                else return { title: t('users'), url: SETTINGS_URL + USERS_URL };
            }

            if (SETTINGS_TEAMS_URL_REGEX.test(location.pathname)) {
                if (urlSections[urlSections.length - 1] === TEAMS_URL.replace('/', ''))
                    return { title: t('SideNav.nav_settings'), url: SETTINGS_URL };
                else return { title: t('teams'), url: SETTINGS_URL + TEAMS_URL };
            }

            if (SETTINGS_OFFICES_URL_REGEX.test(location.pathname)) {
                if (urlSections[urlSections.length - 1] === OFFICES_URL.replace('/', ''))
                    return { title: t('SideNav.nav_settings'), url: SETTINGS_URL };
                else return { title: t('offices'), url: SETTINGS_URL + OFFICES_URL };
            }
            if (SETTINGS_POLICIES_URL_REGEX.test(location.pathname)) {
                if (urlSections[urlSections.length - 1] === POLICIES_URL.replace('/', ''))
                    return { title: t('SideNav.nav_settings'), url: SETTINGS_URL };
                else return { title: t('policy_bundle'), url: SETTINGS_URL + POLICIES_URL };
            }

            if (SETTINGS_HAPPENINGS_URL_REGEX.test(location.pathname)) {
                if (urlSections[urlSections.length - 1] === HAPPENINGS_URL.replace('/', ''))
                    return { title: t('SideNav.nav_settings'), url: SETTINGS_URL };
                else return { title: t('happenings'), url: SETTINGS_URL + HAPPENINGS_URL };
            }

            if (SETTINGS_SLACK_INTEGRATION_URL_REGEX.test(location.pathname)) {
                if (urlSections[urlSections.length - 1] === SLACK_INTEGRATION_URL.replace('/', ''))
                    return { title: t('SideNav.nav_settings'), url: SETTINGS_URL };
            }

            if (SETTINGS_GLOBAL_LANGUAGE_URL_REGEX.test(location.pathname)) {
                if (urlSections[urlSections.length - 1] === GLOBAL_LANGUAGE_URL.replace('/', ''))
                    return { title: t('SideNav.nav_settings'), url: SETTINGS_URL };
            }

            if (TEAMS_URL_REGEX.test(location.pathname)) {
                if (urlSections.length !== 2)
                    return { title: t('SideNav.nav_teams'), url: TEAMS_URL };
            }

            if (CLUBS_URL_REGEX.test(location.pathname)) {
                if (urlSections.length !== 2)
                    return { title: t('SideNav.nav_clubs'), url: CLUBS_URL };
            }

            if (EVENTS_URL_REGEX.test(location.pathname)) {
                if (urlSections.length !== 2)
                    return { title: t('SideNav.nav_events'), url: EVENTS_URL };
            }

            if (COWORKER_TIMESHEET_URL_REGEX.test(location.pathname)) {
                if (urlSections.length !== 2)
                    return { title: t('SideNav.nav_coworkers'), url: COWORKERS_URL };
            }

            return undefined;
        }, [location.pathname]);

        return (
            <Layout className={`AuthenticatedLayout`}>
                <Helmet>
                    <title>{`${headerTitle()} - Flexy`}</title>
                </Helmet>
                <SideNavigationContext.Provider value={sideNavContext}>
                    <SideNavigation />
                    <Layout
                        className={`site-layout ${
                            windowDimensions.windowWidth > SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD
                                ? sideNavContext?.collapsed
                                    ? 'small-screen-sidenav-collapsed'
                                    : 'small-screen-sidenav-open'
                                : ''
                        }`}
                    >
                        <SectionHeader
                            onHandleDisplayModeClick={onHandleDisplayModeClick}
                            title={headerTitle()}
                            backButton={backButton()}
                        />
                        <div className="site-layout-content">{children}</div>
                    </Layout>
                </SideNavigationContext.Provider>
            </Layout>
        );
    }
);

export default AuthenticatedLayout;
