import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import { DayCircle } from 'Components/circle-toggle';
import CircleToggleList from 'Components/circle-toggle-list';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { dayOfWeekLangMap } from 'Utils/TimeUtils';

export const getDayNumber = (day: DayOfWeekDto): number => Object.keys(DayOfWeekDto).indexOf(day);

interface CircleToggleDaysProps {
    activeDays: DayOfWeekDto[];
    disabledDays?: DayOfWeekDto[];
    onClick?: (days: DayCircle[]) => void;
    tooltips?: boolean;
}

const daysInit = (lang: SupportedLanguage): DayCircle[] => {
    return Object.keys(DayOfWeekDto).map((day) => ({
        id: DayOfWeekDto[day],
        text: dayOfWeekLangMap(DayOfWeekDto[day], lang)[0],
        isActive: false,
        isDisabled: false,
        tooltip: dayOfWeekLangMap(DayOfWeekDto[day], lang),
    }));
};

const CircleToggleDays: React.FunctionComponent<CircleToggleDaysProps> = observer(
    ({ activeDays, disabledDays, onClick, tooltips }) => {
        const { languageStore } = useStores();
        const [daysList, setDaysList] = useState<DayCircle[]>(
            daysInit(languageStore.currentLanguage)
        );

        const handleClick = (position: number) => {
            const newDays = daysList;
            newDays[position] = { ...newDays[position], isActive: !newDays[position].isActive };

            onClick && onClick(newDays);
            setDaysList([...newDays]);
        };

        useEffect(() => {
            const daysNumber = activeDays.map((day) => getDayNumber(day));
            let newDaysList = daysList.map((day, i) => ({
                ...day,
                isActive: daysNumber.includes(i),
                tooltip: tooltips
                    ? dayOfWeekLangMap(
                          Object.keys(DayOfWeekDto)[i] as DayOfWeekDto,
                          languageStore.currentLanguage
                      )
                    : undefined,
            }));

            if (disabledDays && disabledDays.length > 0) {
                const daysNumber = disabledDays.map((day) => getDayNumber(day));
                newDaysList = newDaysList.map((day, i) => ({
                    ...day,
                    isDisabled: daysNumber.includes(i),
                }));
            } else {
                newDaysList = newDaysList.map((day) => ({
                    ...day,
                    isDisabled: false,
                }));
            }

            setDaysList(newDaysList);
        }, [activeDays, disabledDays]);

        return (
            <div className="CircleToggleDays">
                <CircleToggleList
                    list={daysList}
                    onClick={onClick !== undefined ? handleClick : undefined}
                />
            </div>
        );
    }
);

export default CircleToggleDays;
