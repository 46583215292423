import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Statistics: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M10 19C9.45 19 9 18.55 9 18V13C9 12.45 9.45 12 10 12C10.55 12 11 12.45 11 13V18C11 18.55 10.55 19 10 19ZM14 19C13.45 19 13 18.55 13 18V10C13 9.45 13.45 9 14 9C14.55 9 15 9.45 15 10V18C15 18.55 14.55 19 14 19ZM18 19C17.45 19 17 18.55 17 18V16C17 15.45 17.45 15 18 15C18.55 15 19 15.45 19 16V18C19 18.55 18.55 19 18 19ZM20 21H8C7.45 21 7 20.55 7 20V8C7 7.45 7.45 7 8 7H20C20.55 7 21 7.45 21 8V20C21 20.55 20.55 21 20 21ZM21 5H7C5.9 5 5 5.9 5 7V21C5 22.1 5.9 23 7 23H21C22.1 23 23 22.1 23 21V7C23 5.9 22.1 5 21 5Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Statistics;
