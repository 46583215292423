import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CalendarStarIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M7.8916 22.4268H19.9941C21.9014 22.4268 22.8945 21.4424 22.8945 19.5615V8.86523C22.8945 6.98438 21.9014 6 19.9941 6H7.8916C5.99316 6 5 6.97559 5 8.86523V19.5615C5 21.4424 5.99316 22.4268 7.8916 22.4268ZM7.88281 20.6777C7.15332 20.6777 6.74902 20.2998 6.74902 19.5264V11.4404C6.74902 10.667 7.15332 10.2891 7.88281 10.2891H20.0029C20.7324 10.2891 21.1367 10.667 21.1367 11.4404V19.5264C21.1367 20.2998 20.7324 20.6777 20.0029 20.6777H7.88281Z"
                    fill={fill}
                />
                <path
                    d="M13.5245 12.4635C13.6741 12.0029 14.3259 12.0029 14.4755 12.4635L15.0103 14.1094C15.0773 14.3154 15.2692 14.4549 15.4858 14.4549H17.2164C17.7008 14.4549 17.9022 15.0747 17.5103 15.3594L16.1102 16.3766C15.935 16.504 15.8617 16.7297 15.9286 16.9357L16.4634 18.5816C16.6131 19.0422 16.0858 19.4253 15.694 19.1406L14.2939 18.1234C14.1186 17.996 13.8814 17.996 13.7061 18.1234L12.306 19.1406C11.9142 19.4253 11.3869 19.0422 11.5366 18.5816L12.0714 16.9357C12.1383 16.7297 12.065 16.504 11.8898 16.3766L10.4897 15.3594C10.0978 15.0747 10.2992 14.4549 10.7836 14.4549H12.5142C12.7308 14.4549 12.9227 14.3154 12.9897 14.1094L13.5245 12.4635Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default CalendarStarIcon;
