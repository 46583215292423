import Icon from 'Components/icons/Icon';
import React from 'react';

interface StarToggleProps {
    isActive: boolean;
}

const StarToggle: React.FunctionComponent<StarToggleProps> = ({ isActive }) => {
    const iconName = isActive ? 'Star' : 'StarEmpty';

    return (
        <div className="StarToggle">
            <Icon iconName={iconName} />
        </div>
    );
};

export default StarToggle;
