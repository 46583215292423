import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import TitledWhitecardSection from 'Routes/profile/components/titled-whitecard-section';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import UserValueDisplay from '../user-value-display/user-value-display';
import {
    Badge,
    BriefcaseIcon,
    ClockIcon,
    EnvelopeIcon,
    LocationIcon,
    MessageBubbleIcon,
    People3Icon,
    PeopleIcon,
    PersonIcon,
} from 'Components/icons';
import { mergeStrings } from 'Utils/TextUtils';
import { timeZoneUtil } from 'appcom-timezones';
import { displayLanguages } from 'Utils/IntlUtils';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';

interface ProfileDetailsTabProps {
    data?: UserDetailsDto;
}

const ProfileDetailsTab: FunctionComponent<ProfileDetailsTabProps> = observer(({ data }) => {
    const { t } = useTranslation();
    const { languageStore } = useStores();

    return (
        <div className="ProfileDetailsTab">
            <TitledWhitecardSection title={t('profile')}>
                <UserValueDisplay
                    label={t('first_name')}
                    value={data?.firstName}
                    icon={<PersonIcon width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('last_name')}
                    value={data?.lastName}
                    icon={<PersonIcon width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('location')}
                    value={data?.location?.description}
                    icon={<LocationIcon width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('time_zone')}
                    value={
                        timeZoneUtil
                            .getTimeZones(languageStore.currentLanguage.toLowerCase())
                            .find((x) => x.id === data?.timeZone)?.description
                    }
                    icon={<ClockIcon width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('languages')}
                    value={displayLanguages(data?.languageCodes, languageStore.currentLanguage)}
                    icon={<MessageBubbleIcon width={24} height={24} />}
                />

                <UserValueDisplay
                    label={t('email')}
                    value={data?.contactInfo?.email}
                    icon={<EnvelopeIcon width={24} height={24} />}
                />
            </TitledWhitecardSection>

            <TitledWhitecardSection title={t('account_details')}>
                <UserValueDisplay
                    label={t('Profile.job_title')}
                    value={data?.jobTitle}
                    icon={<BriefcaseIcon width={24} height={24} />}
                />
                <UserValueDisplay
                    label={t('department')}
                    value={data?.department}
                    icon={<People3Icon width={24} height={24} />}
                />
                <UserValueDisplay
                    label={t('role')}
                    value={data?.managementRoles?.[0]?.name ?? t('user')}
                    icon={<PersonIcon width={24} height={24} />}
                />
                <UserValueDisplay
                    label={t('team')}
                    value={data?.team?.name}
                    icon={<PeopleIcon width={24} height={24} />}
                />
                <UserValueDisplay
                    label={t('Coworker.badges')}
                    value={mergeStrings(data?.badges?.map((badge) => badge.toString()) ?? [], ', ')}
                    icon={<Badge width={24} height={24} />}
                />
            </TitledWhitecardSection>
        </div>
    );
});

export default ProfileDetailsTab;
