import React from 'react';

export const grayWaveSvg = (
    <svg
        width="792"
        height="63"
        viewBox="0 0 792 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.1" clip-path="url(#clip0_13406_113462)">
            <path
                opacity="0.4"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M193.918 42.8384L307.931 29.4909C420.242 16.1433 648.268 -10.5518 874.593 11.6941C1100.92 33.94 1248.9 71.0401 1555.27 71.0401C1861.64 71.0401 2009.62 42.8384 2121.93 29.4908L2235.94 -25.0283L2235.94 229.704L2121.93 229.704C2009.62 229.704 1781.59 229.704 1555.27 229.704C1328.94 229.704 1100.92 229.704 874.593 229.704C648.268 229.704 420.242 229.704 307.931 229.704L193.918 229.704L193.918 42.8384Z"
                fill="#737373"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1214.72 72.1745L1100.7 58.8269C988.393 45.4794 760.368 18.7842 534.043 41.0302C307.719 63.2761 159.739 100.376 -146.631 100.376C-453.002 100.376 -600.982 72.1744 -713.293 58.8268L-827.306 4.30773L-827.306 259.04L-713.293 259.04C-600.982 259.04 -372.956 259.04 -146.631 259.04C79.6929 259.04 307.719 259.04 534.043 259.04C760.367 259.04 988.393 259.04 1100.7 259.04L1214.72 259.04L1214.72 72.1745Z"
                fill="#737373"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-541.356 57.4631L-427.285 44.1156C-314.916 30.768 -86.7727 4.07291 139.668 26.3188C366.108 48.5647 514.164 85.6648 820.692 85.6648C1127.22 85.6647 1275.28 57.4631 1387.64 44.1155L1501.72 -10.4036L1501.72 244.329L1387.64 244.329C1275.28 244.329 1047.13 244.329 820.692 244.329C594.252 244.329 366.108 244.329 139.668 244.329C-86.7727 244.329 -314.916 244.329 -427.285 244.329L-541.356 244.329L-541.356 57.4631Z"
                fill="#737373"
            />
        </g>
        <defs>
            <clipPath id="clip0_13406_113462">
                <rect width="792" height="63" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
