import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const OfficeIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill={fill}
                {...props}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M24 7H4V13.2222H5.42857V21H6.85714V13.2222H21.1429V21H22.5714V13.2222H24V7ZM22.5714 11.6667H5.42857V8.55556H22.5714V11.6667Z"
                    fill="black"
                />
            </svg>
        </span>
    );
};

export default OfficeIcon;
