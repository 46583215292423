import ConfirmationModalContainer from 'Components/confirmation-modal/confirmation-modal-container';
import GlobalLoadingContainer from 'Components/global-loading-container/global-loading-container';
import { ToastContainer } from 'Components/toast-container';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationService } from 'Services/AuthenticationService';
import Routes from '../Routes';
import './App.less';
import { HelmetProvider } from 'react-helmet-async';
import { TriggerFetch } from 'Contexts/TriggerFetchContext';
import { Locale } from 'antd/es/locale-provider';
import en_US from 'antd/es/locale/en_US';
import fr_CA from 'antd/es/locale/fr_CA';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr-ca';
import { ConfigProvider } from 'antd';
import { observer } from 'mobx-react';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { FormSubmittingContext } from 'Contexts/FormSubmittingContext';

const App: FunctionComponent = observer(() => {
    const { languageStore } = useStores();
    const authenticationService = useService(AuthenticationService);
    const [currentLanguage, setCurrentLanguage] = useState<Locale | undefined>(undefined);
    const { i18n } = useTranslation();

    useLayoutEffect(() => {
        //http intercepter needs to be replaced on language change
        authenticationService.installInterceptors(languageStore.currentLanguage);
    }, [authenticationService, languageStore.currentLanguage]);

    useEffect(() => {
        //Triggered on language change or first render. Set appropriate local
        switch (languageStore.currentLanguage) {
            case SupportedLanguage.EN:
                setCurrentLanguage(en_US);
                i18n.changeLanguage('en');
                moment.locale('en');
                break;
            case SupportedLanguage.FR:
                setCurrentLanguage(fr_CA);
                i18n.changeLanguage('fr');
                moment.locale('fr-ca');
                break;
        }
    }, [languageStore.currentLanguage]);

    return (
        <HelmetProvider>
            <ConfigProvider locale={currentLanguage}>
                <BrowserRouter>
                    <TriggerFetch>
                        <FormSubmittingContext>
                            <Routes />
                        </FormSubmittingContext>
                    </TriggerFetch>
                    <ToastContainer />
                    <GlobalLoadingContainer />
                    <ConfirmationModalContainer />
                </BrowserRouter>
            </ConfigProvider>
        </HelmetProvider>
    );
});

export default App;
