import { Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { UpdateBundleTeamsEntriesRequestDto } from 'Api/Features/Policies/Dtos/UpdateBundleTeamsEntriesRequestDto';
import { UpdatePolicyBundleTeamsActionDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleTeamsActionDto';
import { UpdatePolicyBundleTeamsRequestDto } from 'Api/Features/Policies/Dtos/UpdatePolicyBundleTeamsRequestDto';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { GetTeamsSortColumnDto } from 'Api/Features/Teams/Dtos/GetTeamsSortColumnDto';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import Icon from 'Components/icons/Icon';
import Skeleton from 'Components/skeleton';
import TableFilters from 'Components/table-filters/table-filters';
import VerticalThreeDotMenuButton from 'Components/vertical-three-dot-menu-button';
import { useApiRequestHistory, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import {
    SETTINGS_URL,
    DEBOUNCE_DELAY_400,
    SETTINGS_POLICIES_URL_REGEX,
    TEAMS_URL,
} from 'Models/Constants';
import { initialPaginationState } from 'Models/InitialPaginationState';
import React, { FunctionComponent, useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tableSkeletonShape } from 'Routes/settings/skeleton-shapes';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import { TeamService } from 'Services/TeamService';
import { FilterStore } from 'Stores';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import { theme } from 'Style/theme';
import './index.less';

interface TabTeamsProps {
    id: string;
    interactionCausesFetch: boolean;
    onFetch: () => void;
}

const TabTeams: FunctionComponent<TabTeamsProps> = ({ id, interactionCausesFetch, onFetch }) => {
    const teamService = useService(TeamService);
    const policyService = useService(PolicyBundleService);
    const { toastStore, globalLoadingStore, confirmationModalStore, policyStore } = useStores();
    const [loading, setLoading] = useState(false);
    const [teamsList, setTeamsList] = useState<TeamDto[]>([]);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [isLoading, setIsLoading] = useState({
        teams: false,
    });
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const paginationRef = useRef(initialPaginationState);
    const history = useHistory();
    const { t } = useTranslation();
    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex: SETTINGS_POLICIES_URL_REGEX,
            requestType: RequestType.TeamsPolicies,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            const filterStore = filterStoreRef.current;
            const request: GetTeamsRequestDto = history.request;

            filterStore.searchTerm = request.searchTerm ?? '';

            paginationRef.current = {
                ...paginationRef.current,
                current: request.page ? request.page + 1 : 1,
                pageSize: request.pageSize ?? initialPaginationState.pageSize,
            };
        },
    });

    useEffect(() => {
        if (interactionCausesFetch) {
            const filterStore = filterStoreRef.current;
            fetchTeams({
                pagination: {
                    ...paginationRef.current,
                },
                searchTerm: filterStore.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }
    }, [interactionCausesFetch]);

    const onRemoveTeam = async (teamId: string | null): Promise<void> => {
        if (!teamId) return;

        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                    title: t('Policy.remove_team_from_policy_title'),
                    message: t('Policy.remove_team_from_policy_message'),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;
            globalLoadingStore.addLoading();
            const request: UpdatePolicyBundleTeamsRequestDto = {
                entries: [
                    {
                        teamId,
                        action: UpdatePolicyBundleTeamsActionDto.Remove,
                    } as UpdateBundleTeamsEntriesRequestDto,
                ],
            };
            await policyService.updatePolicyBundleTeams(id, request);

            if (id === policyStore.policyInfo?.policyBundleId)
                await policyStore.setPolicyRestrictionsAndObjectivesStats();

            const filterStore = filterStoreRef.current;
            fetchTeams({
                pagination: {
                    ...paginationRef.current,
                },
                searchTerm: filterStore.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });

            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('team'),
                    param2: t('Entity.lowercase_action_removed'),
                }),
            });
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const columns: ColumnType<TeamDto>[] = [
        {
            title: t('name'),
            render: (team: TeamDto) => team.name,
            key: GetTeamsSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('Policy.number_of_users'),
            render: (team: TeamDto) => team.numberOfMembers,
            key: GetTeamsSortColumnDto.NumberOfMembers,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('managers'),
            render: (team: TeamDto) =>
                team.managers
                    ?.map((manager) => manager?.firstName + ' ' + manager?.lastName)
                    .join(', '),
        },
        {
            render: (team: TeamDto) => (
                <div className="t-align-center">
                    <VerticalThreeDotMenuButton
                        menuOptions={[
                            {
                                title: t('Policy.team_details'),
                                onClick: (): void =>
                                    history.push(SETTINGS_URL + TEAMS_URL + `/${team.id}`),
                            },
                            {
                                title: t('remove_from_policy'),
                                className: 'secondary',
                                onClick: (): Promise<void> => onRemoveTeam(team.id ?? null),
                            },
                        ]}
                    />
                </div>
            ),
            width: 100,
        },
    ];

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm } = filterStoreRef.current;
        await fetchTeams({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const fetchTeams = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: any | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const request: GetTeamsRequestDto = setRequestHistory({
                    request: {
                        pageSize: params.pagination.pageSize || 0,
                        page: (params.pagination.current || 1) - 1,
                        searchTerm: params.searchTerm,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                        policyBundleIds: id,
                    },
                    requestType: RequestType.TeamsPolicies,
                });

                const [items, totalItemsCount] = await teamService.getTeams(request);

                setTeamsList(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
                setIsLoading((prev) => {
                    return { ...prev, teams: true };
                });
                onFetch();
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        []
    );

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string }) => {
            fetchTeams({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    return (
        <div className="TabTeams">
            <TableFilters
                filterStore={filterStoreRef.current}
                includeSearch
                isLoaded={isLoading.teams}
            />
            <Skeleton placeholder={tableSkeletonShape} isLoaded={isLoading.teams}>
                <Table
                    className="table-action-rows table-striped-rows"
                    dataSource={teamsList}
                    columns={columns}
                    loading={loading}
                    rowKey={(team): string => team.id ?? ''}
                    pagination={pagination}
                    onChange={handleTableChange}
                />
            </Skeleton>
        </div>
    );
};

export default TabTeams;
