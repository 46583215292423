import React, { FunctionComponent, ReactNode, useEffect, useState, useCallback } from 'react';
import './single-select-common.less';
import Select, { components } from 'react-select';
import { theme } from 'Style/theme';
import { CheckmarkIcon } from 'Components/icons';
import { SingleSelectCommonStyles, SingleSelectCustomOption } from './single-select-common';
import { useTranslation } from 'react-i18next';

export interface StaticSingleSelectProps {
    isLoading?: boolean;
    className?: string;
    options?: SingleSelectCustomOption[];
    onChange: (value?: SingleSelectCustomOption) => void | undefined;
    placeholder?: string | null;
    // defaultImg?: ReactNode;
    strongLabel?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string | null;
    disabled?: boolean;
    isClearable?: boolean;
    borderless?: boolean;
    round?: boolean;
    dropdownIndicatorIcon?: ReactNode;
}

const StaticSingleSelect: FunctionComponent<StaticSingleSelectProps> = ({
    isLoading = false,
    className,
    options,
    onChange,
    placeholder,
    // defaultImg,
    strongLabel = false,
    isClearable = false,
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    selected,
    disabled,
    borderless,
    round,
    dropdownIndicatorIcon,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<SingleSelectCustomOption | null>();

    useEffect(() => {
        if (options === undefined) return;
        if (selected) {
            setValue(options.find((x) => x.value === selected));
        } else {
            setValue(null);
        }
    }, [selected, options]);

    const handleOnChange = (data: any): void => {
        if (onChange) {
            onChange(data ?? undefined);
        }
    };

    // -- 02/11/2022 as of now, never have an option tu put image --
    // const optionImage = (data: SingleSelectCustomOption, selectProps: any) => {
    //     return (
    //         <>
    //             {data.imageUrl ? (
    //                 <img
    //                     style={{ flex: '0 0 auto' }}
    //                     className="dropdown-option-img"
    //                     width="24px"
    //                     height="24px"
    //                     src={data.imageUrl}
    //                     alt=""
    //                 />
    //             ) : (
    //                 <span className="dropdown-option-img">{selectProps.defaultImg}</span>
    //             )}
    //         </>
    //     );
    // };

    /* -- The open dropdown menu -- */
    const menuOptionDetails = (data: SingleSelectCustomOption, selectProps: any) => {
        let labelColor = 'black';
        if (data?.isDisabled) labelColor = theme['text-disabled'];

        let optionIsSelected = false;
        if (data?.value === selectProps.value?.value) {
            optionIsSelected = true;
            labelColor = theme.white;
        }

        return (
            <div className="menuOptionDetails">
                {!data.hideLeftIconDropDown && data.leftIcon ? (
                    <div className="left-icon">{data.leftIcon}</div>
                ) : null}

                <div className="center-content">
                    <div className="dropdown-menu-option-label">
                        {selectProps.strongLabel ? (
                            <strong>{data.label}</strong>
                        ) : (
                            <div style={{ color: labelColor }}>{data.label}</div>
                        )}
                    </div>
                    {data.content && (
                        <div
                            className={`${data.content.className ?? ''}`}
                            style={{ color: labelColor }}
                        >
                            {data.content.node}
                        </div>
                    )}
                </div>

                {optionIsSelected && (
                    <div className="selected-checkmark">
                        <CheckmarkIcon />
                    </div>
                )}
            </div>
        );
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props} className={'single-dropdown-menu-option-container'}>
                    {/* {defaultImg && optionImage(props.data, props.selectProps)} */}

                    {menuOptionDetails(props.data, props.selectProps)}
                </components.Option>
            </div>
        );
    };
    /* -- The open dropdown menu -- */

    /* -- The selected value in dropdown -- */
    const selectedOptionDetails = (data: SingleSelectCustomOption, selectProps: any) => {
        let labelColor = 'black';
        if (data.isDisabled) labelColor = theme['text-disabled'];

        return (
            <div className={`selected-option-details`}>
                {selectProps.strongLabel ? (
                    <strong>{data.label}</strong>
                ) : (
                    <span className="label-in-dropdown" style={{ color: labelColor }}>
                        {data.label}
                    </span>
                )}
                {data.content && data.content.showSelected && (
                    <div
                        className={`${data.content.className ?? ''}`}
                        style={{ color: labelColor }}
                    >
                        {data.content.node}
                    </div>
                )}
            </div>
        );
    };

    const SingleValue = ({ data, selectProps }: any): any => {
        return (
            !selectProps.menuIsOpen && (
                <div className="dropdown-selected-element">
                    {/* {defaultImg && optionImage(data, selectProps)} */}
                    <div className="with-icon">
                        {data.showLeftIconSelected && data.leftIcon ? data.leftIcon : undefined}
                    </div>

                    {selectedOptionDetails(data, selectProps)}
                </div>
            )
        );
    };
    /* -- The selected value in dropdown -- */

    const Input = useCallback(({ ...props }: any) => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback(
        (props): any => {
            return dropdownIndicatorIcon ? (
                <components.DropdownIndicator {...props}>
                    {dropdownIndicatorIcon}
                </components.DropdownIndicator>
            ) : (
                <components.DropdownIndicator {...props}></components.DropdownIndicator>
            );
        },
        [dropdownIndicatorIcon]
    );

    return (
        <Select
            components={{
                Option,
                SingleValue,
                DropdownIndicator,
                Input,
            }}
            theme={(defaultTheme) => ({
                ...defaultTheme,
                colors: {
                    ...defaultTheme.colors,
                    primary: theme['primary-mid-contrast'], //dictates the color of select option
                    primary25: theme['primary-low-contrast'], //dictates the color hovering options
                },
            })}
            className={`SingleSelect StaticSingleSelect ${className || ''} ${
                disabled || isLoading ? 'disabled' : ''
            } ${borderless ? 'borderless' : ''}  ${round ? 'round' : ''}`}
            value={value}
            styles={SingleSelectCommonStyles}
            options={options}
            onChange={handleOnChange}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder ?? t('SelectCustom.default_select_placeholder')}
            // defaultImg={defaultImg}
            strongLabel={strongLabel}
            isDisabled={disabled || isLoading}
            noOptionsMessage={(): string => t('SelectCustom.select_no_options')}
            //permet au menu ouvert de sortir de l'epace d'un modal
            menuPortalTarget={document.body}
            isLoading={isLoading}
        />
    );
};

export default StaticSingleSelect;
