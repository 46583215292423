import Icon from 'Components/icons/Icon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './preferred-workplace-toggle.less';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';

interface PreferredWorkplaceToggleProps {
    type: UserPreferredWorkTypeDto;
    isSelected: boolean;
    onClick: (type: UserPreferredWorkTypeDto) => void;
}

const PreferredWorkplaceToggle: FunctionComponent<PreferredWorkplaceToggleProps> = ({
    type,
    isSelected,
    onClick,
}) => {
    const { t } = useTranslation();
    const [elementsColor, setElementsColor] = useState<string>(theme['layout-mid-contrast']);

    useEffect(() => {
        if (isSelected) {
            setElementsColor(
                type === UserPreferredWorkTypeDto.Office
                    ? theme['accent-mid-contrast']
                    : theme['primary-mid-contrast']
            );
        } else {
            setElementsColor(theme['layout-mid-contrast']);
        }
    }, [isSelected, type]);

    return (
        <div
            className={`PreferredWorkplaceToggle ${type} selected-${isSelected}`}
            style={{ borderColor: elementsColor }}
            onClick={() => onClick(type)}
        >
            {type === UserPreferredWorkTypeDto.Office ? (
                <Icon iconName="AppartementIcon" fill={elementsColor} width={32} height={32} />
            ) : (
                <Icon iconName="SimpleHouseIcon" fill={elementsColor} width={32} height={32} />
            )}

            <div>
                {type === UserPreferredWorkTypeDto.Office ? (
                    <div className="type">
                        <div className="text-body-bold">{t('office')}</div>
                        <div className="text-caption-2">{t('mainly_work_office')}</div>
                    </div>
                ) : (
                    <div className="type">
                        <div className="text-body-bold">{t('remote')}</div>
                        <div className="text-caption-2">{t('mainly_work_remote')}</div>
                    </div>
                )}
            </div>

            <div className="main-icon">
                <Icon iconName="MainWorkplaceIcon" fill={elementsColor} width={24} height={24} />
            </div>
        </div>
    );
};

export default PreferredWorkplaceToggle;
