import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const StarEmpty: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="28" height="28" viewBox="1 0 28 28" fill="none" {...props}>
                <path
                    d="M17.8339 10.7294L14.9999 4.14282L12.166 10.7294L4.81006 11.2994L10.4145 15.9402L8.70225 22.8791L14.9999 19.1607L21.2976 22.8791L19.5854 15.9402L25.1898 11.2994L17.8339 10.7294ZM22.5364 12.1328L17.096 11.7112L14.9999 6.83978L12.9039 11.7112L7.46346 12.1328L11.6085 15.5652L10.3421 20.6972L14.9999 17.947L19.6578 20.6972L18.3914 15.5652L22.5364 12.1328Z"
                    fill={fill ?? '#CCCCCC'}
                    fillRule="evenodd"
                />
            </svg>
        </span>
    );
};

export default StarEmpty;
