import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { AlarmClockIcon, CalendarIcon, TargetArrowIcon } from 'Components/icons';
import { useStores } from 'Hooks';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react';
import { FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { theme } from 'Style/theme';
import './index.less';

interface OnelinePolicySummaryProps {
    policy: PolicyBundleDto;
}

export const getOneLinePolicySummaryStringSections = (
    policy: PolicyBundleDto,
    t: TFunction,
    language: SupportedLanguage
): { days: string; time: string; objectives: string } => {
    return {
        days: t('OnelinePolicySummary.number_amount_days', {
            param1: policy.restrictions?.daysOfWeek?.length ?? 0,
        }),
        time: `${
            policy.restrictions?.workPeriodStartTime
                ? moment('2020-01-01' + 'T' + policy.restrictions?.workPeriodStartTime).format(
                      FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[language]
                  )
                : '12:00 am'
        } ${t('to')} ${
            policy.restrictions?.workPeriodEndTime
                ? moment('2020-01-01' + 'T' + policy.restrictions?.workPeriodEndTime).format(
                      FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[language]
                  )
                : '12:00 am'
        }`,
        objectives: t('OnelinePolicySummary.number_amount_objectives', {
            param1: policy.objectives
                ? Object.keys(policy.objectives).filter((key) => policy.objectives![key] !== null)
                      .length
                : 0,
        }),
    };
};

const OnelinePolicySummary: FunctionComponent<OnelinePolicySummaryProps> = observer(
    ({ policy }) => {
        const { t } = useTranslation();
        const { languageStore } = useStores();
        const policyString = getOneLinePolicySummaryStringSections(
            policy,
            t,
            languageStore.currentLanguage
        );
        return (
            <div className="OnelinePolicySummary">
                <div>
                    <CalendarIcon fill={theme['primary-mid-contrast']} />
                    {policyString.days}
                </div>
                <div className="divider" />
                <div>
                    <AlarmClockIcon fill={theme['primary-mid-contrast']} />
                    {policyString.time}
                </div>
                <div className="divider" />
                <div>
                    <TargetArrowIcon width={18} height={18} fill={theme['primary-mid-contrast']} />
                    {policyString.objectives}
                </div>
            </div>
        );
    }
);

export default OnelinePolicySummary;
