import { Tabs } from 'antd';
import React, { FunctionComponent } from 'react';
import './navigation-tabs-with-blocking.less';

interface DetailsPageNavigationTabsProps {
    activeKey: string;
    onTabClick: (activeKey: string) => void;
}

//Specific component for profile tabs because we need the on click event and therefor need to manage the active tab manually
const NavigationTabsWithBlocking: FunctionComponent<DetailsPageNavigationTabsProps> = ({
    activeKey,
    onTabClick,
    children,
}) => {
    return (
        <div className="NavigationTabsWithBlocking">
            <Tabs activeKey={activeKey} onTabClick={onTabClick}>
                {children}
            </Tabs>
        </div>
    );
};

export default NavigationTabsWithBlocking;
