import React, { createContext, useState } from 'react';

interface FormSubmittingContextProps {
    formSubmitting: boolean;
    setFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormSubmittingContextProvider = createContext<FormSubmittingContextProps | undefined>(
    undefined
);

export const FormSubmittingContext: React.FunctionComponent = ({ children }) => {
    const [formSubmitting, setFormSubmitting] = useState(false);

    return (
        <FormSubmittingContextProvider.Provider value={{ formSubmitting, setFormSubmitting }}>
            {children}
        </FormSubmittingContextProvider.Provider>
    );
};
