import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';
import { TimeUnitTypeDto } from 'Api/Features/Stats/Dtos/TimeUnitTypeDto';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    FORMAT_MONTH_DATE_COMMA_YEAR,
    FORMAT_MONTH_YEAR,
    FORMAT_SHORT_MONTH,
    FORMAT_SHORT_MONTH_DATE,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Bar,
    BarChart as RecharsBarChart,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { theme } from 'Style/theme';
import { RealEstateChartBaseProps } from '..';
import './bar-chart.less';
import { GraphContext } from './graph-card';

interface BarChartDataStructure {
    GroupName: string;
    Date: moment.Moment;
    RemoteTotalHours: number;
    OfficeTotalHours: number;
}

const BarChart: React.FunctionComponent<RealEstateChartBaseProps> = observer(({ chartData }) => {
    const { languageStore } = useStores();
    const { t } = useTranslation();
    const graphContext = useContext(GraphContext);
    const [chartParsedData, setChartParsedData] = useState<BarChartDataStructure[]>([]);

    const parseApiDataForChart = useCallback(
        (apiData: GetOfficeUtilizationStatsResponseDto) => {
            const parsedData =
                apiData?.groups?.map(
                    (group) =>
                        ({
                            GroupName: moment
                                .utc(group?.info?.timeUnit)
                                .format(
                                    graphContext?.timeUnitType === TimeUnitTypeDto.Week
                                        ? FORMAT_SHORT_MONTH_DATE[languageStore.currentLanguage]
                                        : FORMAT_SHORT_MONTH
                                ),
                            OfficeTotalHours: group?.groups?.find(
                                (x) => x?.info?.workType === WorkTypeDto.Office
                            )?.totalHours,
                            RemoteTotalHours: group?.groups?.find(
                                (x) => x?.info?.workType === WorkTypeDto.Remote
                            )?.totalHours,
                            Date: moment.utc(group?.info?.timeUnit),
                        } as BarChartDataStructure)
                ) ?? [];

            setChartParsedData(parsedData);
        },
        [chartData]
    );

    useEffect(() => {
        if (chartData) parseApiDataForChart(chartData);
    }, [chartData]);

    const renderCustomLabel = ({ x, y, payload }) => {
        return (
            <text fontSize={13} x={x - 20} y={y + 15} color={theme['text-mid-contrast']}>
                {payload.value}
            </text>
        );
    };

    const CustomTooltip = (props) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className=" title text-caption-3 text-mid-contrast">
                        {graphContext?.timeUnitType === TimeUnitTypeDto.Week
                            ? `${t('week_of')} ${props.payload[0].payload.Date.format(
                                  FORMAT_MONTH_DATE_COMMA_YEAR[languageStore.currentLanguage]
                              )}`
                            : props.payload[0].payload.Date.format(FORMAT_MONTH_YEAR)}
                    </div>

                    <div className="office text-footnote d-flex">
                        <div className="circle office"></div>
                        <div>
                            {props.payload[0].name} - {props.payload[0].value} {t('hours')}
                        </div>
                    </div>

                    <div className="text-footnote d-flex">
                        <div className="circle remote" />
                        <div>
                            {props.payload[1].name} - {props.payload[1].value} {t('hours')}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="BarChart">
            <RecharsBarChart
                width={780}
                height={345}
                data={chartParsedData}
                margin={{
                    top: 0,
                    right: 5,
                    left: 0,
                    bottom: 20,
                }}
                barSize={21}
            >
                <CartesianGrid strokeDasharray="0" vertical={false} />
                <XAxis dataKey="GroupName" tick={renderCustomLabel} minTickGap={1} />
                <YAxis />
                <Tooltip offset={30} content={<CustomTooltip />} />
                <Legend iconSize={16} iconType={'circle'} />
                <Bar
                    dataKey="OfficeTotalHours"
                    fill={theme['accent-mid-contrast']}
                    radius={[20, 20, 0, 0]}
                    name={t('office')}
                />
                <Bar
                    dataKey="RemoteTotalHours"
                    fill={theme['primary-low-contrast']}
                    radius={[20, 20, 0, 0]}
                    name={t('remote')}
                />
            </RecharsBarChart>
        </div>
    );
});

export default BarChart;
