import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const GoogleCalendarIconSvg: FunctionComponent<SvgProps> = ({ onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="534" height="534" viewBox="0 0 534 534" fill="none" {...props}>
                <g clip-path="url(#clip0_623_3908)">
                    <path d="M407.351 126.649H126.649V407.351H407.351V126.649Z" fill="white" />
                    <path d="M407.351 533.667L533.666 407.351H407.351V533.667Z" fill="#EA4335" />
                    <path d="M533.666 126.649H407.351V407.351H533.666V126.649Z" fill="#FBBC04" />
                    <path d="M407.351 407.351H126.649V533.667H407.351V407.351Z" fill="#34A853" />
                    <path
                        d="M0.333008 407.351V491.561C0.333008 514.825 19.1751 533.667 42.4383 533.667H126.649V407.351H0.333008Z"
                        fill="#188038"
                    />
                    <path
                        d="M533.666 126.649V42.4385C533.666 19.1754 514.824 0.333252 491.561 0.333252H407.351V126.649H533.666Z"
                        fill="#1967D2"
                    />
                    <path
                        d="M407.351 0.333252H42.4383C19.1751 0.333252 0.333008 19.1754 0.333008 42.4385V407.351H126.649V126.649H407.351V0.333252Z"
                        fill="#4285F4"
                    />
                    <path
                        d="M184.228 344.403C173.737 337.316 166.474 326.965 162.509 313.281L186.86 303.246C189.07 311.667 192.93 318.193 198.439 322.824C203.912 327.456 210.579 329.737 218.368 329.737C226.333 329.737 233.175 327.316 238.895 322.474C244.614 317.631 247.491 311.456 247.491 303.982C247.491 296.333 244.474 290.088 238.439 285.245C232.404 280.403 224.825 277.982 215.772 277.982H201.702V253.877H214.333C222.123 253.877 228.684 251.772 234.018 247.561C239.351 243.351 242.018 237.596 242.018 230.263C242.018 223.737 239.632 218.544 234.86 214.649C230.088 210.754 224.053 208.789 216.719 208.789C209.561 208.789 203.877 210.684 199.667 214.509C195.459 218.343 192.295 223.185 190.474 228.579L166.368 218.544C169.561 209.491 175.421 201.491 184.018 194.579C192.614 187.667 203.597 184.193 216.93 184.193C226.789 184.193 235.667 186.088 243.526 189.912C251.386 193.737 257.561 199.035 262.018 205.772C266.474 212.544 268.684 220.123 268.684 228.544C268.684 237.14 266.614 244.403 262.474 250.368C258.333 256.333 253.246 260.895 247.211 264.088V265.526C255.003 268.739 261.772 274.016 266.789 280.789C271.877 287.631 274.439 295.807 274.439 305.351C274.439 314.895 272.018 323.421 267.175 330.895C262.333 338.368 255.632 344.263 247.14 348.544C238.614 352.824 229.035 355 218.404 355C206.088 355.035 194.719 351.491 184.228 344.403ZM333.807 223.561L307.07 242.895L293.702 222.614L341.667 188.017H360.053V351.21H333.807V223.561Z"
                        fill="#4285F4"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_623_3908">
                        <rect width="534" height="534" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default GoogleCalendarIconSvg;
