// GENERATED FILE - DO NOT MODIFY
import { FloorPlanDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanDto';
import { UpdateFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/UpdateFloorPlanRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class FloorPlanProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getFloorPlan(officeId: string): Promise<FloorPlanDto | null> {
        const uri = this.buildUri(
            "/offices/{officeId}/floorplan",
            { officeId: officeId },
            null
        );

        const data: FloorPlanDto | null = await this.httpClient.get<FloorPlanDto | null>(uri);
        return data;
    }

    public async updateFloorPlan(officeId: string, request: UpdateFloorPlanRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/offices/{officeId}/floorplan",
            { officeId: officeId },
            null
        );

        await this.httpClient.put<UpdateFloorPlanRequestDto | null, void>(uri, request);
    }
}