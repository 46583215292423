import { GetHappeningsRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningsRequestDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { HappeningFilterModeDto } from 'Api/Features/Happenings/Dtos/HappeningFilterModeDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { GetOfficesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficesSortColumnDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetDayUserCountsRequestDto } from 'Api/Features/Schedules/Dtos/GetDayUserCountsRequestDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { GetOfficeUtilizationStatsRequestDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsRequestDto';
import { GroupingTypeDto } from 'Api/Features/Stats/Dtos/GroupingTypeDto';
import { TimeUnitTypeDto } from 'Api/Features/Stats/Dtos/TimeUnitTypeDto';
import { UserTeamFilterModeDto } from 'Api/Features/Users/Dtos/UserTeamFilterModeDto';
import Content from 'Components/Content';
import { ErrorMessage } from 'Components/error-message';
import ModalWeeklyObjectives from 'Components/modal-weekly-objectives';
import { singleSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import AsyncSingleSelect, {
    SelectFetchFunctionPromise,
} from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import Skeleton from 'Components/skeleton';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import {
    DROPDOWN_PAGE_SIZE,
    FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE,
    FORMAT_YEAR_MONTH_DAY,
    PLAN_SCHEDULE_URL,
} from 'Models/Constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HappeningService } from 'Services/HappeningService';
import { OfficeService } from 'Services/OfficeService';
import { ScheduleService } from 'Services/ScheduleService';
import { StatsService } from 'Services/StatsService';
import { getTotalHoursInEvents, getTotalHoursInEventsType } from 'Utils/CalendarUtils';
import { img, ImgSize } from 'Utils/ImgUtils';
import { TimeRangeFilterMap, TimeRangeKey } from 'Utils/ReportsUtils';
import {
    createTimeMomentInTimezoneWithoutConverting,
    createTimezonedMomentFromDateAndTime,
} from 'Utils/TimeUtils';
import DashboardHappeningTeam from './components/dashboard-happening-team';
import DashboardNextDays from './components/dashboard-next-days';

import { Team } from './components/dashboard-team';
import DashboardToday, { DashboardTodayData } from './components/dashboard-today';
import './index.less';
import { dashboardHappeningShape, dashboardTodayShape } from './skeleton-shapes';
import WorkingCoworkersModal, {
    WorkingCoworkersModalMode,
} from 'Components/coworkers-at-office-modal/working-coworkers-modal';

const Dashboard: React.FunctionComponent = observer(() => {
    const { userStore, toastStore, policyStore, languageStore } = useStores();
    const scheduleService = useService(ScheduleService);
    const officeService = useService(OfficeService);
    const hapenningService = useService(HappeningService);
    const statsService = useService(StatsService);
    const [modalWeeklyObjectivesIsVisible, setModalWeeklyObjectivesIsVisible] = useState(false);
    const [workingCoworkerModalState, setWorkingCoworkerModalState] = useState<{
        visible: boolean;
        startingDate?: string;
        workType?: WorkTypeDto;
        mode: WorkingCoworkersModalMode;
    }>({
        visible: false,
        mode: WorkingCoworkersModalMode.CoworkerList,
    });
    const [officeSearchResults, setOfficeSearchResults] = useState<OfficeDto[]>([]);
    const [officeOptions, setOfficeOptions] = useState<SingleSelectCustomOption[]>([]);
    const [selectedOfficeOption, setSelectedOfficeFilterOption] = useState<
        SingleSelectCustomOption | undefined
    >();
    const [selectedOfficeId, setSelectedOfficeFilterId] = useState<string | undefined>('all');
    const [todayData, setTodayData] = useState<DashboardTodayData | null | undefined>(null);
    const [happening, setHappening] = useState<HappeningDto[]>([]);
    const [teamData, setTeamData] = useState<Team | null | undefined>(null);

    const [hasError, setHasError] = useState({
        today: false,
        happening: false,
        team: false,
    });
    const history = useHistory();
    const { t } = useTranslation();

    const fetchToday = async (): Promise<void> => {
        try {
            if (userStore.userInfo?.id) {
                const dayUserCountsRequest: GetDayUserCountsRequestDto = {
                    teamIds: userStore.userInfo.team?.id ? [userStore.userInfo.team.id] : undefined,
                    startTime: moment().format(),
                    endTime: moment().format(),
                    excludedUserIds: [userStore.userInfo.id],
                    favoritesForUserId: userStore.userInfo.id,
                    teamFilterMode: UserTeamFilterModeDto.Members,
                };

                const coworkers = await scheduleService.getDayUserCounts(dayUserCountsRequest);

                const userScheduleRequest: GetUserScheduleRequestDto = {
                    timeZone: userStore.userInfo.timeZone,
                    startDate: moment().startOf('week').format('YYYY-MM-DD'),
                    endDate: moment().endOf('week').format('YYYY-MM-DD'),
                };

                const schedule = await scheduleService.getUserSchedule(
                    userStore.userInfo.id,
                    userScheduleRequest
                );

                const totalHoursWorks = getTotalHoursInEvents(
                    schedule?.events?.filter((event) => event !== null).map((event) => event!) ??
                        [],
                    getTotalHoursInEventsType.workHoursPerWeek
                );
                const totalAvailabilityHours = getTotalHoursInEvents(
                    schedule?.events?.filter((event) => event !== null).map((event) => event!) ??
                        [],
                    getTotalHoursInEventsType.availabilityHoursPerWeek
                );

                setTodayData({
                    profile: {
                        imgUrl: userStore.userInfo.imageUrl
                            ? img(userStore.userInfo.imageUrl, ImgSize.m)
                            : undefined,
                        name: userStore.userInfo.firstName ?? '',
                    },
                    currentlyWorking: {
                        office: coworkers?.office ?? 0,
                        remote: coworkers?.remote ?? 0,
                        onClick: {
                            office: () =>
                                setWorkingCoworkerModalState({
                                    visible: true,
                                    startingDate: moment().format(FORMAT_YEAR_MONTH_DAY),
                                    workType: WorkTypeDto.Office,
                                    mode: WorkingCoworkersModalMode.CurrentlyWorking,
                                }),
                            remote: () =>
                                setWorkingCoworkerModalState({
                                    visible: true,
                                    startingDate: moment().format(FORMAT_YEAR_MONTH_DAY),
                                    workType: WorkTypeDto.Remote,
                                    mode: WorkingCoworkersModalMode.CurrentlyWorking,
                                }),
                        },
                    },
                    objectives: {
                        completedObjectives:
                            policyStore.policyInfo?.policyObjectiveStats?.totalScore.completed ?? 0,
                        totalObjectives:
                            policyStore.policyInfo?.policyObjectiveStats?.totalScore.total ?? 0,
                        openObjectivesModal: () => setModalWeeklyObjectivesIsVisible(true),
                        policyBundleObjectivesDetails:
                            policyStore.policyInfo?.policyObjectiveStats
                                ?.policyBundleObjectivesDetails ?? [],
                    },
                    hoursOfWork: {
                        workHours: totalHoursWorks,
                        availabilityHours: totalAvailabilityHours,
                    },
                });
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
                setHasError((prev) => ({ ...prev, today: true }));
            }
        }
    };

    const fetchHappenings = async () => {
        try {
            const request: GetHappeningsRequestDto = {
                minEndTime: createTimezonedMomentFromDateAndTime(
                    moment().format(FORMAT_YEAR_MONTH_DAY),
                    '00:00',
                    userStore.userInfo?.timeZone ?? ''
                ).format(),
                officeIds: undefined,
                filterMode: HappeningFilterModeDto.GeneralAndUserClubs,
                clubsForUserId: userStore.userInfo?.id,
                pageSize: 5,
            };

            const [happenings]: [HappeningDto[], number] = await hapenningService.getHappenings(
                request
            );
            setHappening(happenings);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
                setHasError((prev) => ({ ...prev, happening: true }));
            }
        }
    };

    /**fetchMostPopularStatsForMyTeam: Récupère le retour api pour les valeurs { popularDay, popularWorkPeriod }. Pour la team de l'utilisateur courant! */
    const fetchMostPopularStatsForMyTeam = async () => {
        if (!userStore.userInfo?.team?.id) return { popularDay: '0', popularWorkPeriod: '0' };

        const getPopularDay = async () => {
            const request: GetOfficeUtilizationStatsRequestDto = {
                teamIds: userStore.userInfo?.team?.id ? [userStore.userInfo.team.id] : undefined,
                groupingTypes: [GroupingTypeDto.WeekDay],
                workTypes: [WorkTypeDto.Office],
                timeUnitType: TimeUnitTypeDto.Day,
                startDate: TimeRangeFilterMap.get(TimeRangeKey.Last4Weeks)?.start ?? '',
                endDate: TimeRangeFilterMap.get(TimeRangeKey.Last4Weeks)?.end ?? '',
                timeZone: userStore.userInfo?.timeZone,
                officeIdsIncludeRemote: false,
            };

            const response = await statsService.getOfficeUtilizationStats(request);

            const sortGroupsByTotalHours: any = response?.groups?.sort((a, b) =>
                (a?.totalHours || 0) > (b?.totalHours || 0) ? -1 : +1
            );

            return sortGroupsByTotalHours[0]?.info?.dayOfWeek || '';
        };

        const getPopularWorkPeriod = async () => {
            const request: GetOfficeUtilizationStatsRequestDto = {
                teamIds: userStore.userInfo?.team?.id ? [userStore.userInfo?.team?.id] : undefined,
                groupingTypes: [GroupingTypeDto.DayFourHoursPeriod],
                workTypes: [WorkTypeDto.Office],
                timeUnitType: TimeUnitTypeDto.Day,
                startDate: TimeRangeFilterMap.get(TimeRangeKey.Last4Weeks)?.start ?? '',
                endDate: TimeRangeFilterMap.get(TimeRangeKey.Last4Weeks)?.end ?? '',
                officeIdsIncludeRemote: false,
            };

            const response = await statsService.getOfficeUtilizationStats(request);

            const mostPopularWorkPeriod = response?.groups?.sort(
                (a, b) =>
                    (b?.totalUsers ?? 0) - (a?.totalUsers ?? 0) ||
                    (b?.totalHours ?? 0) - (a?.totalHours ?? 0)
            )?.[0];

            const periods = `
            ${moment
                .utc(mostPopularWorkPeriod?.info?.fourHoursPeriodStartTime)
                .format(
                    FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE[languageStore.currentLanguage]
                )} - ${moment
                .utc(mostPopularWorkPeriod?.info?.fourHoursPeriodStartTime)
                .add(4, 'hours')
                .format(FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE[languageStore.currentLanguage])} `;

            return periods;
        };

        const popularWorkPeriod = await getPopularWorkPeriod();
        const popularDay = await getPopularDay();

        return { popularDay, popularWorkPeriod };
    };

    const fetchStatsTeams = async (): Promise<void> => {
        try {
            if (!userStore.userInfo?.team?.id) {
                setTeamData(undefined);
                return;
            }

            const { popularDay, popularWorkPeriod } = await fetchMostPopularStatsForMyTeam();

            setTeamData({
                popularDay: popularDay,
                popularWorkPeriod: popularWorkPeriod,
            });
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
                setHasError((prev) => ({ ...prev, team: true }));
            }
        }
    };

    const searchOffices = async (
        page: number,
        searchTerm: string
    ): Promise<SelectFetchFunctionPromise> => {
        const args: GetOfficesRequestDto = {
            sortColumn: GetOfficesSortColumnDto.IsUserDefaultOffice,
            page: page,
            pageSize: DROPDOWN_PAGE_SIZE,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await officeService.getOffices(args);
        setOfficeSearchResults((prev) => [...prev, ...results]);

        return {
            maxResultHit: results.length + DROPDOWN_PAGE_SIZE * page >= totalItemCount,
        };
    };

    const onChangeSelectedOfficeFilter = (value?: SingleSelectCustomOption) => {
        if (value?.value !== selectedOfficeId) {
            setSelectedOfficeFilterId(value?.value);
            setSelectedOfficeFilterOption(value);
        }
    };

    useEffect(() => {
        const searchResults = officeSearchResults?.map(
            (office: OfficeDto) =>
                ({
                    value: office.id || '',
                    label: office.name || '',
                } as SingleSelectCustomOption)
        );

        searchResults.unshift({
            value: 'all',
            label: t('all_offices'),
        });

        const merged = singleSelectMergeSelectedOptionsWithSearchResults(searchResults, [
            selectedOfficeOption,
        ]);

        setOfficeOptions(merged);
    }, [officeSearchResults, selectedOfficeOption]);

    useEffect(() => {
        if (userStore.userInfo?.id) fetchToday();
    }, [userStore.userInfo?.id]);

    useEffect(() => {
        if (userStore.userInfo?.id) {
            policyStore.setPolicyObjectivesStats();
            fetchHappenings();
        }
    }, [userStore.userInfo?.id]);

    return (
        <div className="Dashboard">
            <Content>
                <div className="top-wrapper">
                    {hasError.today ? (
                        <ErrorMessage />
                    ) : (
                        <Skeleton isLoaded={todayData !== null} placeholder={dashboardTodayShape}>
                            <DashboardToday data={todayData ?? undefined} />
                        </Skeleton>
                    )}

                    {hasError.happening || hasError.team ? (
                        <ErrorMessage />
                    ) : (
                        <Skeleton
                            isLoaded={happening !== null}
                            placeholder={dashboardHappeningShape}
                        >
                            <DashboardHappeningTeam
                                team={teamData ?? undefined}
                                happenings={happening ?? undefined}
                                fetchStatsTeams={fetchStatsTeams}
                            />
                        </Skeleton>
                    )}
                </div>

                <AsyncSingleSelect
                    fetchFunction={searchOffices}
                    resetSearchResults={() => setOfficeOptions([])}
                    options={officeOptions}
                    selected={selectedOfficeId}
                    onChange={onChangeSelectedOfficeFilter}
                    isSearchable={false}
                    placeholder={t('SelectCustom.default_select_placeholder')}
                />

                <DashboardNextDays
                    selectedOfficeOption={selectedOfficeOption}
                    setWorkingCoworkerModalState={(visible, startingDate, workType, mode) =>
                        setWorkingCoworkerModalState({
                            visible,
                            startingDate,
                            workType,
                            mode,
                        })
                    }
                />
            </Content>

            {modalWeeklyObjectivesIsVisible && (
                <ModalWeeklyObjectives
                    isVisible={modalWeeklyObjectivesIsVisible}
                    onCancel={() => setModalWeeklyObjectivesIsVisible(false)}
                    isConstantWeek
                />
            )}

            {workingCoworkerModalState.visible && (
                <WorkingCoworkersModal
                    onComplete={(copyEvent?: ScheduleEventDto) => {
                        setWorkingCoworkerModalState({
                            visible: false,
                            mode: WorkingCoworkersModalMode.CoworkerList,
                        });
                        if (copyEvent) {
                            history.push(
                                PLAN_SCHEDULE_URL +
                                    `?date=${createTimeMomentInTimezoneWithoutConverting(
                                        copyEvent.startTime ?? ''
                                    ).format(FORMAT_YEAR_MONTH_DAY)}`,
                                { copyEvent }
                            );
                        }
                    }}
                    visible={workingCoworkerModalState.visible}
                    startingDate={workingCoworkerModalState.startingDate ?? ''}
                    teamId={userStore.userInfo?.team?.id ?? ''}
                    workType={workingCoworkerModalState.workType ?? WorkTypeDto.Office}
                    mode={workingCoworkerModalState.mode}
                    selectedOfficeFilter={
                        selectedOfficeOption && selectedOfficeOption.value !== 'all'
                            ? selectedOfficeOption
                            : undefined
                    }
                />
            )}
        </div>
    );
});

export default Dashboard;
