import * as yup from 'yup';

export const SchedulePreferenceSchema = yup.object({
    startTime: yup.string().required('Errors.error_required'),
    endTime: yup.string().required('Errors.error_required'),
});

export const TimePeriodSchema = yup.object({
    startTime: yup.string().required('Errors.error_required'),
    endTime: yup.string().required('Errors.error_required'),
    startDate: yup.string().required('Errors.error_required'),
    endDate: yup.string().required('Errors.error_required'),
});