import { Switch } from 'antd';
import Divider from 'Components/divider';
import { ClockIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import TimePickerDropdown from 'Components/time-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import DayReplicator, {
    DayReplicatorStateEnum,
} from 'Components/weekly-hour-pref-calendar/components/day-replicator';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FORMAT_DAY_WRITTEN } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import { getWrittendDayIndex } from 'Utils/TimeUtils';

interface AwayTabProps {
    errors: Map<string, string[]>;
    startDayOptions: SingleSelectCustomOption[];
    selectedStartDayWritten?: string;
    onStartDayChange: (writtenDay: string) => void;
    onStartTimeChange: (value: string) => void;
    startTime?: string;
    selectedStartDate?: moment.Moment;
    endTime?: string;
    endDayOptions: SingleSelectCustomOption[];
    selectedEndDayWritten?: string;
    onEndDayChange: (writtenDay: string) => void;
    onEndTimeChange: (value: string) => void;
    selectedEndDate?: moment.Moment;
    getMinimumEndTimeAvailable: () => string;
    getMaximumEndTimeAvailable: () => string | undefined;
    replicateIsChecked: boolean;
    onReplicateChange: () => void;
    replicateDaysState: DayReplicatorStateEnum[];
    onReplicateDaysStateChange: (dayIndex: number) => void;
    allDayChecked: boolean;
    onAllDayChange: (checked: boolean) => void;
}

const AwayTab: FunctionComponent<AwayTabProps> = observer(
    ({
        errors,
        startDayOptions,
        selectedStartDayWritten,
        onStartDayChange,
        onStartTimeChange,
        startTime,
        selectedStartDate,
        endTime,
        endDayOptions,
        selectedEndDayWritten,
        onEndDayChange,
        onEndTimeChange,
        selectedEndDate,
        getMinimumEndTimeAvailable,
        getMaximumEndTimeAvailable,
        replicateIsChecked,
        onReplicateChange,
        replicateDaysState,
        onReplicateDaysStateChange,
        allDayChecked,
        onAllDayChange,
    }) => {
        const { t } = useTranslation();
        const { policyStore, languageStore } = useStores();
        return (
            <div className="AwayTab">
                <div className="px-20">
                    <div className="off-hour-container px-20">
                        <span
                            className="text-body text cursor-pointer"
                            onClick={() => onAllDayChange(!allDayChecked)}
                        >
                            {t('all_day')}
                        </span>
                        <Switch
                            checked={allDayChecked}
                            onChange={() => onAllDayChange(!allDayChecked)}
                            size="small"
                        />
                    </div>
                </div>

                <Divider marginTop={20} marginBottom={40} />

                <div className="px-20">
                    <div className="date-time-container">
                        <div className="section-title-container">
                            <div className="blue-icon-container">
                                <ClockIcon
                                    width={16}
                                    height={16}
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>

                            <span className="title">{t('date_time')}</span>
                        </div>
                        <div className="controls-container pl-40">
                            <div className="control">
                                <ValidatedFormItem
                                    label={t('from')}
                                    errors={errors}
                                    name="startDate"
                                >
                                    <StaticSingleSelect
                                        className="day"
                                        options={startDayOptions}
                                        selected={selectedStartDayWritten}
                                        onChange={(option?: SingleSelectCustomOption) => {
                                            onStartDayChange(option?.value ?? '');
                                            if (allDayChecked) {
                                                onEndDayChange(
                                                    moment()
                                                        .day(
                                                            getWrittendDayIndex(
                                                                option?.value ?? '',
                                                                languageStore.currentLanguage
                                                            )
                                                        )
                                                        .add(1, 'day')
                                                        .format(FORMAT_DAY_WRITTEN)
                                                );
                                            } else onEndDayChange(option?.value ?? '');
                                        }}
                                        dropdownIndicatorIcon={<div></div>}
                                        placeholder={t('SelectCustom.default_select_placeholder')}
                                    />
                                </ValidatedFormItem>

                                <ValidatedFormItem label="" errors={errors} name="startTime">
                                    <TimePickerDropdown
                                        onChange={onStartTimeChange}
                                        selected={startTime}
                                        restrictions={
                                            policyStore.policyInfo?.restrictions ?? undefined
                                        }
                                        forSpecificDate={selectedStartDate?.format()}
                                        disabled={allDayChecked}
                                    />
                                </ValidatedFormItem>
                            </div>

                            <div className="control">
                                <ValidatedFormItem label={t('to')} name="EndDate">
                                    <StaticSingleSelect
                                        className="day"
                                        options={endDayOptions}
                                        selected={selectedEndDayWritten}
                                        onChange={(option?: SingleSelectCustomOption) =>
                                            onEndDayChange(option?.value ?? '')
                                        }
                                        dropdownIndicatorIcon={<div></div>}
                                        disabled={allDayChecked}
                                        placeholder={t('SelectCustom.default_select_placeholder')}
                                    />
                                </ValidatedFormItem>

                                <ValidatedFormItem label="" errors={errors} name="endTime">
                                    <TimePickerDropdown
                                        onChange={(value: string) => {
                                            onEndTimeChange(value);
                                        }}
                                        selected={endTime}
                                        minimumAvailableTime={getMinimumEndTimeAvailable()}
                                        maximumAvailableTime={getMaximumEndTimeAvailable()}
                                        restrictions={
                                            policyStore.policyInfo?.restrictions ?? undefined
                                        }
                                        forSpecificDate={selectedEndDate?.format()}
                                        disabled={allDayChecked}
                                    />
                                </ValidatedFormItem>
                            </div>
                        </div>
                    </div>
                </div>

                <Divider marginTop={16} marginBottom={40} />

                <div className="px-20">
                    <div className="section-title-container">
                        <div className="blue-icon-container">
                            <Icon
                                width={13}
                                height={13}
                                iconName="DuplicateIcon"
                                fill={theme['primary-mid-contrast']}
                            />
                        </div>
                        <span
                            className={`title replicate cursor-pointer ${
                                selectedStartDate && startTime && endTime ? '' : 'disabled'
                            }`}
                            onClick={() => {
                                if (selectedStartDate && startTime && endTime) onReplicateChange();
                            }}
                        >
                            {t('replicate_period')}
                        </span>
                        <Switch
                            size="small"
                            checked={replicateIsChecked}
                            disabled={
                                selectedStartDate === undefined ||
                                startTime === undefined ||
                                startTime === '' ||
                                endTime === undefined ||
                                endTime === ''
                            }
                            onChange={() => {
                                if (selectedStartDate && startTime && endTime) onReplicateChange();
                            }}
                        />
                    </div>

                    {replicateIsChecked && replicateDaysState && (
                        <div className="replicate-container pl-40">
                            <Divider marginBottom={20} marginTop={20} />
                            <div className="text-caption-1 mb-10">{t('replicate_on')}</div>
                            <DayReplicator
                                onDayChange={(dayIndex: number) =>
                                    onReplicateDaysStateChange(dayIndex)
                                }
                                dayStates={replicateDaysState}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default AwayTab;
