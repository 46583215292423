import { Input } from 'antd';
import { GetClubMembersRequestDto } from 'Api/Features/Clubs/Dtos/GetClubMembersRequestDto';
import { GetClubMembersResponseItemDto } from 'Api/Features/Clubs/Dtos/GetClubMembersResponseItemDto';
import { MagnifyingGlassIcon } from 'Components/icons';
import ImageWithPlaceholder from 'Components/image-with-placeholder/image-with-placeholder';
import BaseModal from 'Components/modal';
import ScrollInfinite from 'Components/scroll-infinite';
import Tag, { TagColor } from 'Components/tag/tag';
import { FavToggle } from 'Components/toggle';
import { useService, useStores, useDebounce } from 'Hooks';
import { PAGE_SIZE_INFINITE_LOADING } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClubService } from 'Services/ClubService';
import { theme } from 'Style/theme';
import { img, ImgSize } from 'Utils/ImgUtils';
import './index.less';
import { ClubMemberSkeletonShape } from './skeleton-shapes';

export interface ClubMembersListModalData {
    clubId: string;
    ownerId: string;
    userId: string;
}

interface ClubMembersListModalProps {
    visible: boolean;
    data?: ClubMembersListModalData;
    onComplete: () => void;
}

const ClubMembersListModal: React.FunctionComponent<ClubMembersListModalProps> = ({
    visible,
    data,
    onComplete,
}) => {
    const clubSerivce = useService(ClubService);
    const { toastStore, userStore } = useStores();
    const [clubMembers, setClubMembers] = useState<GetClubMembersResponseItemDto[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const debounceSearch = useDebounce(searchTerm);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const fetchClubMembers = async (currentPage: number, searchTerm?: string) => {
        try {
            setIsLoading(true);
            if (data) {
                const request: GetClubMembersRequestDto = {
                    favoritesForUserId: data.userId,
                    searchTerm,
                    pageSize: PAGE_SIZE_INFINITE_LOADING,
                    page: currentPage,
                };

                const response = await clubSerivce.getClubMembers(data.clubId, request);

                if (response?.items && response.items.length > 0)
                    setClubMembers((prev: GetClubMembersResponseItemDto[]) => {
                        const nonNullFilteredResponse: GetClubMembersResponseItemDto[] =
                            response.items?.filter(
                                (member): member is GetClubMembersResponseItemDto => member !== null
                            ) ?? [];
                        return [...prev, ...nonNullFilteredResponse];
                    });

                setTotalItemCount(response?.totalItemCount ?? 0);
            }
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        resetMembersSearchResults();
    }, [debounceSearch]);

    const resetMembersSearchResults = () => {
        setClubMembers([]);
        setCurrentPage(0);
        setTotalItemCount(0);
    };

    const handlecurrentPage = (action: 'next' | 'prev') => {
        const maxIsAlreadyLoaded =
            totalItemCount > 0 ? clubMembers.length >= totalItemCount : false;

        if (action === 'prev' && currentPage === 0) return;
        if (maxIsAlreadyLoaded) return;

        if (action === 'next') return setCurrentPage((prev) => prev + 1);
        setCurrentPage((prev) => prev - 1);
    };

    useEffect(() => {
        fetchClubMembers(currentPage, debounceSearch);
    }, [debounceSearch, currentPage]);

    return (
        <BaseModal
            className="ClubMembersListModal"
            visible={visible}
            onCancel={onComplete}
            headerText={t('Clubs.club_members_list_title')}
            oustideOverflow={{
                element: (
                    <Input
                        className="search"
                        prefix={
                            <MagnifyingGlassIcon
                                width={22}
                                height={22}
                                fill={theme['primary-mid-contrast']}
                            />
                        }
                        onChange={(value) => {
                            setSearchTerm(value.target.value);
                        }}
                        placeholder={t('search')}
                        name="name"
                    />
                ),
                height: 100,
            }}
            fixedHeight
        >
            <ScrollInfinite
                items={clubMembers}
                RenderItem={({ item }) => (
                    <>
                        {item.id !== userStore.userInfo?.id ? (
                            <FavToggle coworkerId={item?.id} relationId={item?.favoriteInfo?.id} />
                        ) : (
                            <div className="fav-toggle-space" />
                        )}

                        <ImageWithPlaceholder
                            imgSrc={item?.imageUrl ? img(item?.imageUrl, ImgSize.s) : undefined}
                        />

                        <div className="member-name">
                            {item?.firstName} {item?.lastName}
                        </div>

                        {item.id === data?.ownerId && (
                            <Tag text={t('owner')} color={TagColor.purple} />
                        )}
                    </>
                )}
                RenderItemSkeleton={() => ClubMemberSkeletonShape}
                handlePagination={handlecurrentPage}
                numberSkeletonLoading={5}
                paginationOptions={{
                    pageSize: PAGE_SIZE_INFINITE_LOADING,
                    isLoading: isLoading,
                }}
            />
        </BaseModal>
    );
};

export default ClubMembersListModal;
