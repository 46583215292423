import TableFilters from 'Components/table-filters/table-filters';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import ReorderSpaceModal from '../reorder-space-modal';
import { Table, TablePaginationConfig } from 'antd';
import { autorun } from 'mobx';
import { DEBOUNCE_DELAY_400 } from 'Models/Constants';
import debounce from 'lodash.debounce';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetOfficeSpacesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesSortColumnDto';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import VerticalThreeDotMenuButton from 'Components/vertical-three-dot-menu-button';
import { useService, useStores } from 'Hooks';
import { OfficeService } from 'Services/OfficeService';
import { FilterStore } from 'Stores';
import { ColumnType } from 'antd/lib/table';
import AddEditSpaceModal from '../add-edit-space-modal';
import Tag, { TagColor } from 'Components/tag/tag';
import ReorderSpaceModal from '../reorder-space-modal';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';

interface SpacesTabProps {
    officeId: string;
    officeSpaceOrderingMethod: OrderingMethodDto;
    fetchOfficeSpaces: (params: {
        pagination: TablePaginationConfig;
        searchTerm?: string;
        sortColumn: GetOfficeSpacesSortColumnDto | null;
        sortDirection: SortDirection | null;
    }) => void;
    spacesList: OfficeSpaceDto[];
    isLoading: boolean;
    pagination: TablePaginationConfig;
    filterStoreRef: React.MutableRefObject<FilterStore>;
    paginationRef: React.MutableRefObject<TablePaginationConfig>;
}

const SpacesTab: React.FC<SpacesTabProps> = ({
    officeId,
    officeSpaceOrderingMethod,
    fetchOfficeSpaces,
    spacesList,
    isLoading,
    pagination,
    filterStoreRef,
    paginationRef,
}) => {
    const { t } = useTranslation();
    const { confirmationModalStore, globalLoadingStore, toastStore } = useStores();
    const officeService = useService(OfficeService);
    const [spaceOrderMethod, setSpaceOrderMethod] = useState(officeSpaceOrderingMethod);

    const [reorderModalVisible, setReorderModalVisible] = useState(false);
    const [editSpaceModalState, setEditSpaceModalState] = useState<{
        visible: boolean;
        space: OfficeSpaceDto | undefined;
    }>({
        visible: false,
        space: undefined,
    });

    const columns: ColumnType<OfficeSpaceDto>[] = [
        {
            title: t('Office.space_name'),
            render: (space: OfficeSpaceDto) => space.name,
            key: GetOfficeSpacesSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('floor_plan'),
            render: (space: OfficeSpaceDto) => (
                <div className="d-flex">
                    {space.floorPlanFloorId ? (
                        <Tag text={t('FloorPlan.area_assigned')} color={TagColor.green} />
                    ) : (
                        <Tag text={t('FloorPlan.no_area_assigned')} color={TagColor.grey} />
                    )}
                </div>
            ),
            key: GetOfficeSpacesSortColumnDto.IsOnFloorPlan,
            sorter: true,
        },
        {
            title: t('capacity'),
            render: (space: OfficeSpaceDto) => space.capacity,
            key: GetOfficeSpacesSortColumnDto.Capacity,
            sorter: true,
        },
        {
            title: t('order'),
            render: (space: OfficeSpaceDto) => space.order,
            key: GetOfficeSpacesSortColumnDto.Order,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            render: (space: OfficeSpaceDto) => (
                <div className="t-align-center">
                    <VerticalThreeDotMenuButton
                        menuOptions={[
                            {
                                title: t('edit'),
                                onClick: (): void => {
                                    setEditSpaceModalState({
                                        visible: true,
                                        space: space,
                                    });
                                },
                            },
                            {
                                title: t('deactivate'),
                                className: 'secondary',
                                onClick: (): Promise<void> => onDeactivateSpace(space.id),
                            },
                        ]}
                    />
                </div>
            ),
            width: 75,
        },
    ];

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        const { searchTerm } = filterStoreRef.current;

        await fetchOfficeSpaces({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const onDeactivateSpace = async (spaceId?: string): Promise<void> => {
        if (spaceId) {
            try {
                if (
                    !(await confirmationModalStore.confirm({
                        icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                        title: t('Office.space_deactivate_title'),
                        message: t('Office.space_deactivate_message'),
                        positiveText: t('confirm'),
                        negativeText: t('cancel'),
                    }))
                )
                    return;

                globalLoadingStore.addLoading();

                await officeService.deleteOfficeSpace(spaceId);

                const filterStore = filterStoreRef.current;
                fetchOfficeSpaces({
                    pagination: { ...paginationRef.current },
                    searchTerm: filterStore.searchTerm,
                    sortColumn: null,
                    sortDirection: null,
                });

                toastStore.toast({
                    type: 'success',
                    messageKey: t('Toast.entity_action_success_message', {
                        param1: t('Office.space'),
                        param2: t('Entity.lowercase_action_deactivated'),
                    }),
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string }) => {
            fetchOfficeSpaces({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    return (
        <div className="SpacesTab">
            <TableFilters
                includeSearch
                filterStore={filterStoreRef.current}
                isLoaded={!isLoading}
                genericActionButton={{
                    text: t('Office.space_edit_display_order'),
                    genericActionOnClick: () => setReorderModalVisible(true),
                }}
            />
            <Table
                className="table-action-rows table-striped-rows"
                dataSource={spacesList}
                columns={columns}
                loading={isLoading}
                rowKey={(user): string => user.id ?? ''}
                pagination={pagination}
                onChange={handleTableChange}
                //rows are all equal, no resizing
                tableLayout={'fixed'}
            />

            {reorderModalVisible && (
                <ReorderSpaceModal
                    officeId={officeId}
                    officeSpaceOrderingMethod={spaceOrderMethod}
                    onComplete={(success: boolean, orderMethod?: OrderingMethodDto) => {
                        setReorderModalVisible(false);
                        if (success && orderMethod) {
                            fetchOfficeSpaces({
                                pagination: { ...paginationRef.current },
                                searchTerm: filterStoreRef.current.searchTerm,
                                sortColumn: null,
                                sortDirection: null,
                            });
                            setSpaceOrderMethod(orderMethod);
                        }
                    }}
                    visible={reorderModalVisible}
                />
            )}

            {editSpaceModalState.visible && (
                <AddEditSpaceModal
                    visible={editSpaceModalState.visible}
                    idOffice={officeId}
                    spaceData={editSpaceModalState.space}
                    onComplete={(success?: boolean) => {
                        setEditSpaceModalState({ visible: false, space: undefined });

                        success &&
                            fetchOfficeSpaces({
                                pagination: { ...paginationRef.current },
                                searchTerm: filterStoreRef.current.searchTerm,
                                sortColumn: null,
                                sortDirection: null,
                            });
                    }}
                />
            )}
        </div>
    );
};

export default SpacesTab;
