import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const HouseFilledIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M3 13.8835C3 14.3406 3.35156 14.7361 3.91406 14.7361C4.18652 14.7361 4.43262 14.5867 4.65234 14.4109L13.3711 7.0896C13.6172 6.87866 13.8984 6.87866 14.1445 7.0896L22.8545 14.4109C23.0654 14.5867 23.3115 14.7361 23.584 14.7361C24.1025 14.7361 24.498 14.4109 24.498 13.9011C24.498 13.6023 24.3838 13.365 24.1553 13.1716L21.6855 11.0974V7.44116C21.6855 7.04565 21.4307 6.79956 21.0352 6.79956H19.8223C19.4355 6.79956 19.1719 7.04565 19.1719 7.44116V8.97925L15.0059 5.4812C14.2412 4.8396 13.2744 4.8396 12.5098 5.4812L3.35156 13.1716C3.11426 13.365 3 13.6287 3 13.8835ZM5.85645 21.7849C5.85645 23.0505 6.64746 23.8152 7.95703 23.8152H19.5498C20.8506 23.8152 21.6504 23.0505 21.6504 21.7849V15.1052L14.2939 8.94409C13.9512 8.64526 13.5381 8.65405 13.2041 8.94409L5.85645 15.1052V21.7849ZM16.0605 22.1628H11.4463V16.5378C11.4463 16.1248 11.7188 15.8611 12.1318 15.8611H15.3838C15.7969 15.8611 16.0605 16.1248 16.0605 16.5378V22.1628Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default HouseFilledIcon;
