import { Button } from 'antd';
import { useService } from 'Hooks';
import LayoutErrorsPage from 'Layout/errors_page';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';

/**
 * SignInFailure
 * In the event of getting token failure
 */
const SignInFailure: FunctionComponent = () => {
    const { t } = useTranslation();
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);

    return (
        <LayoutErrorsPage className="NotFoundPage">
            <div className="text-titleXL">{t('Errors.token_error_title')}</div>
            <div className="text-callout">{t('Errors.token_error_subtitle')}</div>
            <Button
                type="default"
                className="primary"
                onClick={() => azureAdAuthenticationService.signOut()}
            >
                {t('SignInFailure.click_here_to_try_again')}
            </Button>
        </LayoutErrorsPage>
    );
};

export default SignInFailure;
