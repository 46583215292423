import { Tabs } from 'antd';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import DetailsPageNavigationTabs from 'Components/details-page-navigation-tabs';
import Icon from 'Components/icons/Icon';
import TablePageSecondHeader from 'Components/table-page-second-header';
import { ThreeDotMenuOption } from 'Components/vertical-three-dot-menu-button';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { HAPPENINGS_URL, SETTINGS_URL } from 'Models/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { HappeningService } from 'Services/HappeningService';
import { theme } from 'Style/theme';
import TabHappeningAttendees from '../components/tab-happening-attendees';
import TabHappeningDetails from '../components/tab-happening-details';
import CreateSocialEventModal, {
    SocialEventType,
} from '../../../../Components/social-event-modal/create-social-event-modal';
import './index.less';
import { SlackService } from 'Services/SlackService';
import PostOnSlackModal from 'Routes/events/components/post-slack-modal';

const HappeningDetails: React.FunctionComponent = observer(() => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const history = useHistory();
    const { toastStore, confirmationModalStore, globalLoadingStore } = useStores();
    const happeningService = useService(HappeningService);
    const slackService = useService(SlackService);
    const [happeningDetails, setHappeningDetails] = useState<HappeningDto>();
    const [loading, setLoading] = useState(true);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [isSlackWorkspaceConnected, setIsSlackWorkspaceConnected] = useState(false);
    const [postOnSlackModalVisible, setPostOnSlackModalVisible] = useState(false);

    const [menuOptions, setMenuOptions] = useState<ThreeDotMenuOption[]>([
        {
            title: t('edit'),
            onClick: () => setEditModalVisible(true),
        },
        {
            title: t('delete'),
            onClick: () => onDeleteHappening(),
            className: 'secondary',
        },
    ]);

    const fetchHappening = useCallback(async () => {
        try {
            setLoading(true);
            const response: HappeningDto | null = await happeningService.getHappening(id);

            if (response) {
                setHappeningDetails(response);
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchHappening();
    }, [fetchHappening]);

    const onDeleteHappening = async (): Promise<void> => {
        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                    title: t('Cta.delete_confirm_title', {
                        param1: t('Entity.lowercase_happening'),
                        param2: 'cet',
                    }),
                    message: t('Cta.delete_confirm_message', {
                        param1: t('Entity.lowercase_happening'),
                        param2: "L'",
                    }),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;
            globalLoadingStore.addLoading();

            await happeningService.deleteHappening(id);

            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('happening'),
                    param2: t('Entity.lowercase_action_deleted'),
                }),
            });
            history.push(SETTINGS_URL + HAPPENINGS_URL);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const fetchIsSlackWorkspaceConnected = useCallback(async (): Promise<void> => {
        try {
            const connected = await slackService.isSlackWorkspaceConnected();
            setIsSlackWorkspaceConnected(connected);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, []);

    useEffect(() => {
        fetchIsSlackWorkspaceConnected();
    }, []);

    useEffect(() => {
        if (isSlackWorkspaceConnected) {
            setMenuOptions(() => [
                {
                    title: t('edit'),
                    onClick: () => setEditModalVisible(true),
                },
                {
                    title: t('Happening.happening_post_on_slack'),
                    onClick: () => setPostOnSlackModalVisible(true),
                },
                {
                    title: t('delete'),
                    onClick: () => onDeleteHappening(),
                    className: 'secondary',
                },
            ]);
        }
    }, [isSlackWorkspaceConnected]);

    return (
        <div className="HappeningDetails">
            <TablePageSecondHeader
                title={happeningDetails?.name ?? ''}
                menuOptions={menuOptions}
                isLoaded={!loading}
            />
            <DetailsPageNavigationTabs defaultActiveKey="1">
                <Tabs.TabPane tab={t('details')} key="1">
                    <TabHappeningDetails loading={loading} happeningDetails={happeningDetails} />
                </Tabs.TabPane>

                {happeningDetails?.attendeesEnabled && (
                    <Tabs.TabPane tab={t('attendees')} key="2">
                        <TabHappeningAttendees id={id} />
                    </Tabs.TabPane>
                )}
            </DetailsPageNavigationTabs>

            {editModalVisible && (
                <CreateSocialEventModal
                    onComplete={(success?: boolean) => {
                        setEditModalVisible(false);
                        if (success) fetchHappening();
                    }}
                    visible={editModalVisible}
                    socialEvent={happeningDetails}
                    socialEventType={SocialEventType.Happening}
                />
            )}

            {postOnSlackModalVisible && (
                <PostOnSlackModal
                    visible={postOnSlackModalVisible}
                    onComplete={() => setPostOnSlackModalVisible(false)}
                    happeningId={happeningDetails?.id ?? ''}
                    channel={happeningDetails?.slackChannel}
                />
            )}
        </div>
    );
});

export default HappeningDetails;
