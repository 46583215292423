import { action, observable } from 'mobx';

export enum StatusCode {
    notFound = 404,
    forbidden = 403,
}

class AuthorizationStore {
    @observable currentRequestForbidden = false;
    @observable currentRequestNotFound = false;
    @observable userCannotAccessSite = false;

    @action
    handleErrorCode(statusCode: StatusCode): void {
        if (statusCode === StatusCode.forbidden) this.currentRequestForbidden = true;
        if (statusCode === StatusCode.notFound) this.currentRequestNotFound = true;
    }

    @action
    resetNotFound(): void {
        this.currentRequestNotFound = false;
    }

    @action
    resetForbidden(): void {
        this.currentRequestForbidden = false;
    }

    resetNotFoundAndForbidden(): void {
        this.resetForbidden();
        this.resetNotFound();
    }

    @action 
    setCurrentRequestForbidden(): void {
        this.currentRequestForbidden = true;
    }

    @action
    handleUserCannotAccessSite(): void {
        this.userCannotAccessSite = true;
    }

    @action
    resetUserCannotAccessSite(): void {
        this.userCannotAccessSite = false;
    }
}
export default AuthorizationStore;
