import React, { createContext, useState } from 'react';

export enum Fetches {
    coworkersAtOfficeModal = 'coworkersAtOfficeModal',
    dashboardFetchSchedule = 'dashboardFetchSchedule',
    planScheduleFetchOfficeWorkingCoworkers = 'planScheduleFetchOfficeWorkingCoworkers',
    scheduleFetchOfficeWorkingCoworkers = 'scheduleFetchOfficeWorkingCoworkers',
}

interface TriggerFetchContextProps {
    run: (fetchName: Fetches, fetchToRun: () => Promise<void> | void) => void;
    trigger: (fetchNames: Fetches[]) => void;
}

export const TriggerFetchContext = createContext<TriggerFetchContextProps | undefined>(undefined);

export const TriggerFetch: React.FunctionComponent = ({ children }) => {
    const [fetch, setFetch] = useState<(Fetches | undefined)[]>([]);

    const trigger = (fetchNames: Fetches[]) => {
        setFetch(fetchNames);
    };

    const run = (fetchName: Fetches, fetchToRun: () => Promise<void> | void): void => {
        if (fetch?.includes(fetchName)) {
            setFetch((prev) => {
                const currentFetch = [...prev];
                const fetchIndex = currentFetch.findIndex((fetch) => fetch === fetchName);
                currentFetch[fetchIndex] = undefined;
                return [...currentFetch];
            });

            fetchToRun();
        }
    };

    return (
        <TriggerFetchContext.Provider value={{ run, trigger }}>
            {children}
        </TriggerFetchContext.Provider>
    );
};
