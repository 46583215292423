import React from 'react';
import './index.less';

export interface OccupancyProgressBarProps {
    occupancy: { atOffice: number; max: number };
}

const OccupancyProgressBar: React.FunctionComponent<OccupancyProgressBarProps> = ({
    occupancy,
}) => {
    const percentage = Math.round(occupancy.atOffice / (occupancy.max / 100));
    const style = { paddingRight: `${100 - percentage}%` };

    return <div className="OccupancyProgressBar" style={style} />;
};

export default OccupancyProgressBar;
