import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubFoodieIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M10.2 19.2H13.8V18.21L14.925 17.76C15.9 17.37 16.7289 16.8261 17.4117 16.1283C18.0939 15.4311 18.585 14.655 18.885 13.8H5.115C5.415 14.655 5.9025 15.4311 6.5775 16.1283C7.2525 16.8261 8.085 17.37 9.075 17.76L10.2 18.21V19.2ZM8.4 21V19.425C6.795 18.795 5.4939 17.82 4.4967 16.5C3.4989 15.18 3 13.68 3 12H4.8V4.8L21 3V4.35L10.65 5.52V7.05H21V8.4H10.65V12H21C21 13.68 20.5011 15.18 19.5033 16.5C18.5061 17.82 17.205 18.795 15.6 19.425V21H8.4ZM8.4 7.05H9.3V5.655L8.4 5.745V7.05ZM6.15 7.05H7.05V5.925L6.15 6.015V7.05ZM8.4 12H9.3V8.4H8.4V12ZM6.15 12H7.05V8.4H6.15V12Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubFoodieIcon;
