import React from 'react';
import './space-viewer-tag.less';
import { useTranslation } from 'react-i18next';

export enum SpaceViewerTagType {
    selected = 'selected',
    available = 'available',
    unavailable = 'unavailable',
}

interface SpaceViewerTagProps {
    type: SpaceViewerTagType;
}

const SpaceViewerTag: React.FC<SpaceViewerTagProps> = ({ type }) => {
    const { t } = useTranslation();
    return (
        <div className={`SpaceViewerTag ${type}`}>
            <span>
                {type === SpaceViewerTagType.selected
                    ? t('selected')
                    : type === SpaceViewerTagType.available
                    ? t('available')
                    : t('unavailable')}
            </span>
            <div className="circle" />
        </div>
    );
};

export default SpaceViewerTag;
