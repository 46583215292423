import { UpdateUserGoogleCalendarAccountConfigRequestDto } from 'Api/Features/GoogleCalendar/Dtos/UpdateUserGoogleCalendarAccountConfigRequestDto';
import { UserGoogleCalendarAccountConfigDto } from 'Api/Features/GoogleCalendar/Dtos/UserGoogleCalendarAccountConfigDto';
import { GoogleCalendarProxy } from 'Api/Features/GoogleCalendar/GoogleCalendarProxy';
import { inject } from 'aurelia-dependency-injection';
import { PROFILE_URL } from 'Models/Constants';
import { ApiService } from './ApiService';
import { ProfileTabs } from 'Routes/profile';

@inject(GoogleCalendarProxy)
export class GoogleCalendarService extends ApiService {
    constructor(private readonly googleCalendarProxy: GoogleCalendarProxy) {
        super();
    }

    public async runConnectGoogleCalendarFlow(authorizeUrl: string, idUser: string): Promise<void> {
        const redirectUri =
            window.location.protocol +
            '//' +
            window.location.host +
            '/actions/google-calendar-callback';

        if (authorizeUrl) {
            const args = new URLSearchParams({
                redirect_uri: redirectUri, // https://dev.flexy-webapp.appcom.ca/actions/google-calendar-callback pour essayer en DEV
                state: idUser,
            });

            window.location = (authorizeUrl + '&' + args) as any;
        }
    }

    public async runPromiseCallbackGoogleCalendarFlow(code: string, state: string): Promise<void> {
        if (state && code && code.length > 0) {
            await this.updateUserGoogleCalendarAccountConfig(state, {
                availabilitiesSyncEnabled: true,
                code: code,
            });

            const url = (window.location.protocol +
                '//' +
                window.location.host +
                `${PROFILE_URL}?activeTab=${ProfileTabs.ManageAccount}`) as any;
            window.location = url;
        }
    }

    public async getUserGoogleCalendarAccountConfig(
        userId: string
    ): Promise<UserGoogleCalendarAccountConfigDto | null> {
        return await this.googleCalendarProxy.getUserGoogleCalendarAccountConfig(userId);
    }

    public async updateUserGoogleCalendarAccountConfig(
        userId: string,
        request: UpdateUserGoogleCalendarAccountConfigRequestDto | null
    ): Promise<void> {
        return await this.googleCalendarProxy.updateUserGoogleCalendarAccountConfig(
            userId,
            request
        );
    }
}
