import Icon from 'Components/icons/Icon';
import BaseModalMobile from 'Components/modal/base-modal-mobile';
import React, { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardObjectives, {
    DashboardObjectivesProps,
} from 'Routes/dashboard/components/dashboard-objectives';
import WorkHoursGlanceDropdownModal from 'Routes/dashboard/modals/work-hours-glance-dropdown-modal';
import { theme } from 'Style/theme';
import './index.less';

interface CurrentlyWorking {
    remote: number;
    office: number;
    openRemoteModal: () => void;
    openOfficeModal: () => void;
}

export interface ObjectivesData extends DashboardObjectivesProps {
    openModal: () => void;
}

export interface HelloGlanceDropdownData {
    name: string;
    currentlyWorking: CurrentlyWorking;
    objectives: ObjectivesData;
    hoursOfWork: { workHours: string; availabilityHours: string };
}

interface HelloGlanceDropdownProps {
    data: HelloGlanceDropdownData;
}

const HelloGlanceDropdown: React.FunctionComponent<HelloGlanceDropdownProps> = ({ data }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [workHoursModalOpen, setWorkHoursModalOpen] = useState(false);

    const toggleOpen = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (event.currentTarget === event.target) setOpen((prev) => !prev);
    };

    return (
        <div className={`HelloGlanceDropdown open-${open}`} onClick={toggleOpen}>
            <div className="hello" onClick={toggleOpen}>
                {data.name} <Icon iconName="ChevronDownIcon" />
            </div>

            <div className="glance">
                <div className="title">{t('Coworker.currently_working_coworkers')}</div>

                <div className="info-bloc" onClick={data.currentlyWorking.openRemoteModal}>
                    <Icon iconName="SimpleHouseIcon" />
                    <div>
                        {data.currentlyWorking.remote} {t('coworkers')}
                    </div>
                </div>

                <div className="info-bloc" onClick={data.currentlyWorking.openOfficeModal}>
                    <Icon iconName="AppartementIcon" />
                    <div>
                        {data.currentlyWorking.office} {t('coworkers')}
                    </div>
                </div>

                <div className="title">{t('guardrails_of_week')}</div>

                <div className="info-bloc" onClick={data.objectives.openModal}>
                    <DashboardObjectives
                        completedObjectives={data?.objectives.completedObjectives}
                        totalObjectives={data?.objectives.totalObjectives}
                    />
                </div>

                <div className="info-bloc" onClick={() => setWorkHoursModalOpen(true)}>
                    <Icon iconName="ClockIcon" fill={theme['primary-mid-contrast']} />
                    {data?.hoursOfWork.workHours} {t('hours_of_availabilities')}
                </div>

                {workHoursModalOpen && (
                    <BaseModalMobile
                        className="WorkHoursGlanceDropdownModalMobileWrapper"
                        visible={workHoursModalOpen}
                        onCancel={() => setWorkHoursModalOpen(false)}
                        maskClosable
                    >
                        <WorkHoursGlanceDropdownModal
                            offHours={data?.hoursOfWork.availabilityHours ?? '0'}
                            workHours={data?.hoursOfWork.workHours ?? '0'}
                        />
                    </BaseModalMobile>
                )}
            </div>
        </div>
    );
};

export default HelloGlanceDropdown;
