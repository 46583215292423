import WhiteCard from 'Components/white-card';
import React from 'react';
import './index.less';

export const happeningDetailsSkeletonShape = (
    <div className="happeningDetailsSkeletonShape">
        <div className="wrapper mb52">
            <div className="rect shape-big-height shape-238" />
        </div>
        <div className="wrapper mb20">
            <div className="rect shape-big-height shape-150" />
        </div>

        <div className="wrapper mb20">
            <div>
                <div className="rect shape-big-height shape-182 mb-8" />
                <div className="rect shape-small-height shape-210" />
            </div>
            <div>
                <div className="rect shape-big-height shape-182 mb-8" />
                <div className="rect shape-small-height shape-210" />
            </div>
            <div>
                <div className="rect shape-big-height shape-182 mb-8" />
                <div className="rect shape-small-height shape-210" />
            </div>
        </div>

        <div className="wrapper mb20">
            <div>
                <div className="rect shape-big-height shape-182 mb-8" />
                <div className="rect shape-small-height shape-210" />
            </div>
            <div>
                <div className="rect shape-big-height shape-182 mb-8" />
                <div className="rect shape-small-height shape-210" />
            </div>
            <div>
                <div className="rect shape-big-height shape-182 mb-8" />
                <div className="rect shape-small-height shape-210" />
            </div>
        </div>
    </div>
);

export const happeningImageSkeletonShape = <WhiteCard className="image-container" />;
