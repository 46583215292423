import { FloorPlanProxy } from 'Api/Features/FloorPlans/FloorPlanProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { FloorPlanDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanDto';
import { UpdateFloorPlanRequestDto } from 'Api/Features/FloorPlans/Dtos/UpdateFloorPlanRequestDto';

@inject(FloorPlanProxy)
export class FloorPlanService extends ApiService {
    constructor(private readonly floorPlanProxy: FloorPlanProxy) {
        super();
    }

    public async getFloorPlan(officeId: string): Promise<FloorPlanDto | null> {
        const data: FloorPlanDto | null = await this.floorPlanProxy.getFloorPlan(officeId);
        return data;
    }

    public async updateFloorPlan(
        officeId: string,
        request: UpdateFloorPlanRequestDto | null
    ): Promise<void> {
        await this.floorPlanProxy.updateFloorPlan(officeId, request);
    }
}
