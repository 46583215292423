import FlexyLogo from 'Assets/Images/logo-color.svg';
import React from 'react';

import './index.less';

interface SectionHeaderMobileProps {
    imgUrl: string;
    onImgClick?: () => void;
}

const SectionHeaderMobile: React.FunctionComponent<SectionHeaderMobileProps> = ({
    onImgClick,
    imgUrl,
}) => {
    return (
        <div className="SectionHeaderMobile">
            <img className="logo" src={FlexyLogo} alt="logo" />
            <div onClick={onImgClick ?? undefined}>
                <img className="profil-img" src={imgUrl} alt="Profil Image" />
            </div>
        </div>
    );
};

export default SectionHeaderMobile;
