import { Col, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { UpdateTeamMembersActionDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersActionDto';
import { UpdateTeamMembersEntryRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersEntryRequestDto';
import { UpdateTeamMembersRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersRequestDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import { MagnifyingGlassIcon } from 'Components/icons';
import Modal from 'Components/modal';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { default as React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamService } from 'Services/TeamService';
import { UserService } from 'Services/UserService';
import { theme } from 'Style/theme';
import SelectedUsersComponent from '../components/selected-users-component';
import './add-team-coworkers-modal.less';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import Button from 'Components/button';
import SubmitButton from 'Components/submit-button/submit-button';

interface EditTeamCoworkersModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    teamId: string;
}

const AddTeamCoworkersModal: React.FunctionComponent<EditTeamCoworkersModalProps> = observer(
    ({ visible, onComplete, teamId }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const { toastStore, globalLoadingStore, userStore, policyStore } = useStores();
        const userService = useService(UserService);
        const teamService = useService(TeamService);

        const { asyncSingleSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetUsersRequestDto) =>
                    await userService.getUsers(request),
            },
            entityToSingleSelectCustomOption: (user: UserDto) =>
                ({
                    value: user.id,
                    label: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
                    extraData: user,
                } as SingleSelectCustomOption),
        });

        const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);

        const handleRemoveClick = (user: UserDto) => {
            setSelectedUsers((prev) => [...prev.filter((x) => x.id !== user.id)]);
        };

        //#region Submit / Exit
        const dismiss = (success = false): void => {
            form.resetFields();
            onComplete(success);
        };

        const submit = async (): Promise<void> => {
            try {
                globalLoadingStore.addLoading();
                const request: UpdateTeamMembersRequestDto = {
                    entries: [
                        ...selectedUsers.map(
                            (x) =>
                                ({
                                    userId: x.id,
                                    action: UpdateTeamMembersActionDto.Add,
                                } as UpdateTeamMembersEntryRequestDto)
                        ),
                    ],
                };

                await teamService.updateTeamMembers(teamId, request);

                if (request.entries?.some((x) => x?.userId === userStore.userInfo?.id)) {
                    await userStore.setUserInfo(userStore.userInfo?.id ?? '');
                    await policyStore.setPolicyRestrictionsAndObjectivesStats();
                }

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('team'),
                    }),
                });

                dismiss(true);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#region Submit / Exit

        return (
            <Modal
                className="AddTeamCoworkersModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t('add_entity', { param1: t('coworkers') })}
                fixedHeight
                oustideOverflow={{
                    element: (
                        <Form
                            layout="vertical"
                            className="padding-scroll container-scroll-actions-fixed"
                            onFinish={submit}
                            form={form}
                        >
                            <div className="text-caption-1 text-mid-contrast mb-10 t-align-center">
                                {t('Team.search_coworkers_add_team')}
                            </div>

                            <Row>
                                <Col span={10} offset={7}>
                                    <AsyncSingleSelect
                                        {...asyncSingleSelectProps}
                                        placeholder={t('search_for_entity', {
                                            param1: t('coworker'),
                                        })}
                                        selected={undefined}
                                        onChange={(value: any) => {
                                            setSelectedUsers((prev) => [
                                                ...prev.filter((x) => x.id !== value.extraData.id),
                                                value.extraData,
                                            ]);
                                        }}
                                        dropdownIndicatorIcon={
                                            <MagnifyingGlassIcon
                                                fill={theme['primary-mid-contrast']}
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form>
                    ),
                    height: 120,
                }}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                        <SubmitButton text={t('save_changes')} type="primary" onClick={submit} />
                    </>
                }
            >
                <SelectedUsersComponent
                    users={selectedUsers}
                    handleRemoveClick={handleRemoveClick}
                />
            </Modal>
        );
    }
);

export default AddTeamCoworkersModal;
