import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const SpaceViewerIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M8.01929 22C7.18595 22 6.47762 21.7083 5.89429 21.125C5.31095 20.5417 5.01929 19.8333 5.01929 19C5.01929 18.1667 5.31095 17.4583 5.89429 16.875C6.47762 16.2917 7.18595 16 8.01929 16C8.85262 16 9.56095 16.2917 10.1443 16.875C10.7276 17.4583 11.0193 18.1667 11.0193 19C11.0193 19.8333 10.7276 20.5417 10.1443 21.125C9.56095 21.7083 8.85262 22 8.01929 22ZM20.0193 22C19.186 22 18.4776 21.7083 17.8943 21.125C17.311 20.5417 17.0193 19.8333 17.0193 19C17.0193 18.1667 17.311 17.4583 17.8943 16.875C18.4776 16.2917 19.186 16 20.0193 16C20.8526 16 21.561 16.2917 22.1443 16.875C22.7276 17.4583 23.0193 18.1667 23.0193 19C23.0193 19.8333 22.7276 20.5417 22.1443 21.125C21.561 21.7083 20.8526 22 20.0193 22ZM14.0193 12C13.186 12 12.4776 11.7083 11.8943 11.125C11.311 10.5417 11.0193 9.83333 11.0193 9C11.0193 8.16667 11.311 7.45833 11.8943 6.875C12.4776 6.29167 13.186 6 14.0193 6C14.8526 6 15.561 6.29167 16.1443 6.875C16.7276 7.45833 17.0193 8.16667 17.0193 9C17.0193 9.83333 16.7276 10.5417 16.1443 11.125C15.561 11.7083 14.8526 12 14.0193 12Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default SpaceViewerIcon;
