import TablePageSecondHeader from 'Components/table-page-second-header';
import { observer } from 'mobx-react-lite';
import {
    default as React,
    FunctionComponent,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ThreeDotMenuOption } from 'Components/vertical-three-dot-menu-button';
import './index.less';
import CreateOfficeModal from 'Components/create-office-modal';
import ConfirmationModal from 'Components/confirmation-modal/confirmation-modal';
import { WarningIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import AddEditSpaceModal from 'Routes/settings/offices/id/add-edit-space-modal';
import { useParams, useHistory } from 'react-router-dom';
import { useFormNavigationBlocker, useService, useStores } from 'Hooks';
import { OfficeService } from 'Services/OfficeService';
import { OFFICES_URL, SETTINGS_URL } from 'Models/Constants';
import DetailsTab, { LabelAndValue } from './tabs/details-tab';
import SpacesTab from './tabs/spaces-tab';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { countryNameIntlFromCountryCode } from 'Utils/IntlUtils';
import { timeZoneUtil } from 'appcom-timezones';
import { TablePaginationConfig, Tabs } from 'antd';
import Content from 'Components/Content';
import { GetOfficeSpacesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesSortColumnDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { FilterStore } from 'Stores';
import FloorPlanTab from './tabs/floor-plan-tab';
import NavigationTabsWithBlocking from 'Components/navigation-tabs-with-blocking/navigation-tabs-with-blocking';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';

enum EMenuOption {
    edit,
    add,
    delete,
}

export enum OfficeDetailsTabs {
    Details = 'Details',
    Spaces = 'Spaces',
    FloorPlan = 'FloorPlan',
}

const OfficesDetails: FunctionComponent = observer(() => {
    const officeService = useService(OfficeService);
    const { globalLoadingStore, toastStore, languageStore } = useStores();
    const history = useHistory();
    const [editOfficeModalVisible, setEditOfficeModalVisible] = useState(false);
    const [deleteOfficeModalVisible, setDeleteOfficeModalVisible] = useState(false);
    const [addSpaceModalVisible, setAddSpaceModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState(OfficeDetailsTabs.Details);
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [officeDetails, setOfficeDetails] = useState<LabelAndValue[]>([]);
    const [officeDetailsJson, setOfficeDetailsJson] = useState<OfficeDto>({});
    const [officeIsLoading, setOfficeIsLoading] = useState(true);

    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const paginationRef = useRef(initialPaginationState);
    const [spacesList, setSpacesList] = useState<OfficeSpaceDto[]>([]);
    const [spacesLoading, setSpacesLoading] = useState(true);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);

    const [setNavigationIsBlocked, navigationIsBlocked] = useFormNavigationBlocker();
    const [navBlockedModalState, setNavBlockedModalState] = useState({
        visible: false,
        tabOnAccept: OfficeDetailsTabs.Details,
    });

    const urlParams = new URLSearchParams(window.location.search);

    const getAddress = (office: OfficeDto): string => {
        let address = '';

        if (office.address) {
            address = office.address?.addressLine1 ? office.address?.addressLine1 : address;
            address = office.address?.addressLine2
                ? address + ', ' + office.address?.addressLine2
                : address;
            address = office.address?.zipCode ? address + ', ' + office.address?.zipCode : address;
        }

        return address;
    };

    const fetchOffice = useCallback(async () => {
        try {
            setOfficeIsLoading(true);
            const response = await officeService.getOffice(id);

            setOfficeDetails([
                { label: t('name'), value: response?.name || '' },
                { label: t('address'), value: response ? getAddress(response) : '' },
                { label: t('city'), value: response?.address?.city || '' },
                { label: t('state'), value: response?.address?.state || '' },
                {
                    label: t('country'),
                    value: countryNameIntlFromCountryCode(
                        languageStore.currentLanguage,
                        response?.address?.countryCode || ''
                    ),
                },
                {
                    label: t('time_zone'),
                    value:
                        timeZoneUtil
                            .getTimeZones(languageStore.currentLanguage.toLowerCase())
                            .find((x) => x.id === response?.timeZone)?.description ?? '',
                },
            ]);
            if (response) setOfficeDetailsJson(response);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setOfficeIsLoading(false);
        }
    }, [toastStore, officeService, id]);

    useEffect(() => {
        fetchOffice();
    }, [fetchOffice]);

    useEffect(() => {
        //Create office modal has an option to add spaces
        const addSpace = urlParams.get('addSpace');
        if (addSpace) setAddSpaceModalVisible(true);
    }, []);

    const onPressMenuOption = (mode: EMenuOption) => {
        if (mode == EMenuOption.edit) setEditOfficeModalVisible(!editOfficeModalVisible);
        if (mode == EMenuOption.delete) setDeleteOfficeModalVisible(!deleteOfficeModalVisible);
    };

    const menuOptionsCreateSpace: ThreeDotMenuOption[] = [
        {
            title: t('edit'),
            onClick: () => onPressMenuOption(EMenuOption.edit),
        },
        {
            title: t('add_a_space'),
            onClick: () => setAddSpaceModalVisible(true),
        },
        {
            title: t('deactivate'),
            onClick: () => onPressMenuOption(EMenuOption.delete),
            className: 'secondary',
        },
    ];

    const deleteOfficeResponse = async (reponse: boolean) => {
        if (reponse) {
            globalLoadingStore.addLoading();
            try {
                await officeService.deleteOffice(id);
                history.push(SETTINGS_URL + OFFICES_URL);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
        onPressMenuOption(EMenuOption.delete);
    };

    const onCompleteEditOffice = (success: boolean) => {
        if (success) fetchOffice();
        setEditOfficeModalVisible(false);
    };

    const fetchOfficeSpaces = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetOfficeSpacesSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setSpacesLoading(true);

            try {
                const request: GetOfficeSpacesRequestDto = {
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    officeIds: [id],
                };

                const [items, totalItemsCount] = await officeService.getOfficeSpaces(request);

                setSpacesList(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setSpacesLoading(false);
            }
        },
        [officeService]
    );

    const handleNavigationTabClick = (activeKey: string) => {
        if (navigationIsBlocked)
            setNavBlockedModalState({ visible: true, tabOnAccept: activeKey as OfficeDetailsTabs });
        else setActiveTab(activeKey as OfficeDetailsTabs);
    };

    return (
        <div className="OfficesDetails">
            <TablePageSecondHeader
                title={officeDetails?.[0]?.value}
                createBtnString={t('create_entity', { param1: t('office') })}
                menuOptions={menuOptionsCreateSpace}
                isLoaded={!officeIsLoading}
            />
            <NavigationTabsWithBlocking
                activeKey={activeTab}
                onTabClick={(active) => handleNavigationTabClick(active)}
            >
                <Tabs.TabPane tab={t('details')} key={OfficeDetailsTabs.Details}>
                    <Content className="layout-small-size">
                        <DetailsTab officeDetails={officeDetails} isLoading={officeIsLoading} />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane tab={t('spaces')} key={OfficeDetailsTabs.Spaces}>
                    <Content>
                        <SpacesTab
                            officeId={id}
                            officeSpaceOrderingMethod={
                                officeDetailsJson.spaceOrderMethod ?? OrderingMethodDto.Alphabetical
                            }
                            fetchOfficeSpaces={fetchOfficeSpaces}
                            isLoading={spacesLoading}
                            pagination={pagination}
                            spacesList={spacesList}
                            filterStoreRef={filterStoreRef}
                            paginationRef={paginationRef}
                        />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane tab={t('floor_plan')} key={OfficeDetailsTabs.FloorPlan}>
                    {activeTab === OfficeDetailsTabs.FloorPlan && (
                        <Content>
                            <FloorPlanTab
                                officeId={id}
                                setNavigationBlocked={setNavigationIsBlocked}
                            />
                        </Content>
                    )}
                </Tabs.TabPane>
            </NavigationTabsWithBlocking>

            {editOfficeModalVisible && (
                <CreateOfficeModal
                    edition
                    valueEdition={officeDetailsJson}
                    visible={editOfficeModalVisible}
                    onComplete={onCompleteEditOffice}
                />
            )}

            {deleteOfficeModalVisible && (
                <ConfirmationModal
                    icon={<WarningIcon fill={theme['warning-mid-contrast']} />}
                    id="confirmation-delete-modal"
                    title={t('Office.office_about_to_deactivate')}
                    message={t('Office.space_deactivate_message')}
                    positive={{
                        text: t('confirm'),
                        action: () => deleteOfficeResponse(true),
                    }}
                    negative={{
                        text: t('cancel'),
                        action: () => deleteOfficeResponse(false),
                    }}
                />
            )}

            {addSpaceModalVisible && (
                <AddEditSpaceModal
                    visible={addSpaceModalVisible}
                    idOffice={id}
                    onComplete={(success?: boolean) => {
                        setAddSpaceModalVisible(false);

                        success &&
                            fetchOfficeSpaces({
                                pagination: { ...paginationRef.current },
                                searchTerm: filterStoreRef.current.searchTerm,
                                sortColumn: null,
                                sortDirection: null,
                            });
                    }}
                />
            )}

            {navBlockedModalState.visible && (
                <ConfirmationModal
                    id="navigation-blocker-confirmation"
                    icon={<WarningIcon fill={theme['warning-mid-contrast']} />}
                    title={t('FormNavBlocker.form_nav_title')}
                    message={t('FormNavBlocker.form_nav_message')}
                    positive={{
                        text: t('continue'),
                        action: () => {
                            setActiveTab(navBlockedModalState.tabOnAccept);
                            setNavBlockedModalState({
                                visible: false,
                                tabOnAccept: navBlockedModalState.tabOnAccept,
                            });
                            setNavigationIsBlocked(false);
                        },
                    }}
                    negative={{
                        text: t('cancel'),
                        action: () => {
                            setNavBlockedModalState({
                                visible: false,
                                tabOnAccept: navBlockedModalState.tabOnAccept,
                            });
                        },
                    }}
                />
            )}
        </div>
    );
});

export default OfficesDetails;
