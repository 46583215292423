import React, { FunctionComponent } from 'react';
import './tag-number.less';

interface TagNumberProps {
    number: string;
}

const TagNumber: FunctionComponent<TagNumberProps> = ({
    number,
}) => {

    return (
        <div
            className={`TagNumber`}
        >
            <div className='value text-body-bold'>{number}</div>
        </div>
    );
};

export default TagNumber;
