import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import AsyncMultiSelect from 'Components/select-custom/multi-select/async-multi-select';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { multiSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { SelectFetchFunctionPromise } from 'Components/select-custom/single-select/async-single-select';
import { useService } from 'Hooks';
import { DROPDOWN_PAGE_SIZE } from 'Models/Constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamService } from 'Services/TeamService';

interface TeamFilterSelectProps {
    defaultSelectedIds?: string[];
    onChange: (values: MultiSelectCustomOption[]) => void;
}

const TeamFilterSelect: FunctionComponent<TeamFilterSelectProps> = ({
    defaultSelectedIds,
    onChange,
}) => {
    const { t } = useTranslation();
    const teamService = useService(TeamService);
    const [teamsSearchResults, setTeamsSearchResults] = useState<TeamDto[]>([]);
    const [teamsOptions, setTeamsOptions] = useState<MultiSelectCustomOption[]>([]);
    const [selectedTeamsOptions, setSelectedTeamsOptions] = useState<MultiSelectCustomOption[]>([]);
    const [selectedTeamsIds, setSelectedTeamsIds] = useState<string[]>(defaultSelectedIds ?? []);

    useEffect(() => {
        if (defaultSelectedIds) setSelectedTeamsIds(defaultSelectedIds);
    }, [defaultSelectedIds]);

    const searchTeams = async (
        page: number,
        searchTerm: string
    ): Promise<SelectFetchFunctionPromise> => {
        const args: GetTeamsRequestDto = {
            pageSize: DROPDOWN_PAGE_SIZE,
            page: page,
            searchTerm: searchTerm,
        };
        const [results, totalItemCount] = await teamService.getTeams(args);
        setTeamsSearchResults((prev) => [...prev, ...results]);

        return {
            maxResultHit: results.length + DROPDOWN_PAGE_SIZE * page >= totalItemCount,
        };
    };

    useEffect(() => {
        const searchResults = teamsSearchResults?.map(
            (x: TeamDto) =>
                ({
                    value: x?.id,
                    label: x?.name,
                } as MultiSelectCustomOption)
        );

        const merged = multiSelectMergeSelectedOptionsWithSearchResults(
            searchResults,
            selectedTeamsOptions
        );

        setTeamsOptions(merged);
    }, [teamsSearchResults, selectedTeamsOptions]);

    return (
        <div className="TeamFilterSelect">
            <AsyncMultiSelect
                fetchFunction={searchTeams}
                resetSearchResults={() => setTeamsSearchResults([])}
                placeholder={t('SelectCustom.select_a_entity', {
                    param1: t('team'),
                    param2: 'une',
                    param3: t('Entity.lowercase_team'),
                })}
                options={teamsOptions}
                selected={selectedTeamsIds}
                onChange={(values: MultiSelectCustomOption[]) => {
                    const ids = values?.map((x: any) => x.value);
                    setSelectedTeamsIds(ids);
                    setSelectedTeamsOptions(values);
                    onChange(values);
                }}
            />
        </div>
    );
};

export default TeamFilterSelect;
