import React from 'react';
import './index.less';

export const coworkerCardSkeletonShapes = (
    <div className="coworkerCardSkeletonShapes">
        <div className="circle" />
        <div className="rect" />
        <div className="rect-separator" />
        <div className="rect" />
    </div>
);
