import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const TargetArrowIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.0807 3L21.4058 6.30983L25 6.86974L23.1753 9.08094C24.1183 10.6493 24.659 12.4762 24.659 14.4263C24.659 20.2659 19.8105 24.9999 13.8295 24.9999C7.84854 24.9999 3 20.2659 3 14.4263C3 8.58664 7.84854 3.85266 13.8295 3.85266C15.5094 3.85266 17.1 4.22613 18.5189 4.89268L20.0807 3ZM17.3492 6.31022C16.2677 5.86254 15.0782 5.61493 13.8295 5.61493C8.84537 5.61493 4.80492 9.55991 4.80492 14.4263C4.80492 19.2927 8.84537 23.2376 13.8295 23.2376C18.8137 23.2376 22.8541 19.2927 22.8541 14.4263C22.8541 13.0434 22.5278 11.7349 21.9463 10.5702L21.2595 11.4026L18.6496 10.9662L18.2432 11.363C18.8735 12.2272 19.2443 13.2844 19.2443 14.4262C19.2443 17.346 16.82 19.713 13.8295 19.713C10.839 19.713 8.41473 17.346 8.41473 14.4262C8.41473 11.5064 10.839 9.13938 13.8295 9.13938C14.999 9.13938 16.0818 9.50136 16.967 10.1168L17.2815 9.80976L16.3405 7.53263L17.3492 6.31022ZM19.533 6.47261L18.4107 7.83265L18.9904 9.23524L20.5192 9.49088L21.6413 8.13108L20.1009 7.89111L19.533 6.47261ZM17.4393 14.4262C17.4393 16.3727 15.8232 17.9507 13.8295 17.9507C11.8358 17.9507 10.2197 16.3727 10.2197 14.4262C10.2197 12.4796 11.8358 10.9017 13.8295 10.9017C14.499 10.9017 15.1259 11.0796 15.6633 11.3897L13.1914 13.8031C12.839 14.1472 12.839 14.7052 13.1914 15.0493C13.5439 15.3934 14.1153 15.3934 14.4677 15.0493L16.9396 12.6358C17.2571 13.1605 17.4393 13.7726 17.4393 14.4262Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default TargetArrowIcon;
