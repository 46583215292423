import { Tabs } from 'antd';
import { UpdateTeamMembersActionDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersActionDto';
import { UpdateTeamMembersEntryRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersEntryRequestDto';
import { UpdateTeamMembersRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamMembersRequestDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import Content from 'Components/Content';
import DetailsPageNavigationTabs from 'Components/details-page-navigation-tabs';
import Icon from 'Components/icons/Icon';
import UserDetailsHeader, { UserDetailsHeaderProps } from 'Components/user-details-header';
import { useService, useStores } from 'Hooks';
import { TEAMS_URL } from 'Models/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { TeamService } from 'Services/TeamService';
import { UserService } from 'Services/UserService';
import { theme } from 'Style/theme';
import { mergeStrings } from 'Utils/TextUtils';
import CreateUserModal from '../create-user-modal';
import ProfileDetailsTab from './components/tabs/profile-details-tab';
import './index.less';
import ContactPreferencesTab from './components/tabs/contact-preferences-tab';
import WorkplacesTab from './components/tabs/workplaces-tab';
import HoursPreferencesTab from './components/tabs/hours-preferences-tab';

interface UserData {
    header: UserDetailsHeaderProps;
    info: UserDetailsDto;
}

export enum UserDetailsContext {
    Settings = 'Settings',
    MyTeams = 'MyTeams',
}

enum UserDetailsTab {
    ProfileDetails = 'ProfileDetails',
    ContactPreferences = 'ContactPreferences',
    Workplace = 'Workplaces',
    HoursPreferences = 'HoursPreferences',
}

interface UserDetailsProps {
    context: UserDetailsContext;
}

const UserDetails: React.FunctionComponent<UserDetailsProps> = ({ context }) => {
    const userService = useService(UserService);
    const teamService = useService(TeamService);
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();
    const [userData, setUserData] = useState<UserData>();
    const { id, teamId } = useParams<{ id: string; teamId?: string }>();
    const { t } = useTranslation();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [isLoaded, setIsLoaded] = useState({
        user: false,
    });
    const history = useHistory();

    const onResentConfirmationEmail = async (): Promise<void> => {
        try {
            globalLoadingStore.addLoading();
            await userService.resendConfirmationToken(id);
            globalLoadingStore.removeLoading();

            toastStore.toast({
                type: 'success',
                message: t('Users.user_resend_confirmation_email_comfirm_title'),
            });
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onDeactivate = async (): Promise<void> => {
        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                    title: t('Users.user_deactivate_comfirm_title'),
                    message: t('Users.user_deactivate_confirm_message'),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;

            globalLoadingStore.addLoading();
            await userService.deactivateUser(id);

            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('user'),
                    param2: t('Entity.lowercase_action_deactivated'),
                }),
            });

            fetchUser();
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onReactivate = async (): Promise<void> => {
        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                    title: t('Users.user_reactivate_comfirm_title'),
                    message: t('Users.user_reactivate_confirm_message'),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;

            globalLoadingStore.addLoading();
            await userService.reactivateUser(id);

            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('user'),
                    param2: t('Entity.lowercase_action_activated'),
                }),
            });

            fetchUser();
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onRemoveFromTeam = async (coworkerId: string | null): Promise<void> => {
        if (!coworkerId || !teamId) return;

        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                    title:
                        coworkerId === userStore.userInfo?.id
                            ? t('Team.remove_self_from_team_title')
                            : t('Team.remove_coworker_from_team_title'),
                    message:
                        coworkerId === userStore.userInfo?.id
                            ? t('Team.remove_self_from_team_message')
                            : t('Team.remove_coworker_from_team_message'),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;

            globalLoadingStore.addLoading();
            const request: UpdateTeamMembersRequestDto = {
                entries: [
                    {
                        userId: coworkerId,
                        action: UpdateTeamMembersActionDto.Remove,
                    } as UpdateTeamMembersEntryRequestDto,
                ],
            };
            await teamService.updateTeamMembers(teamId, request);
            if (coworkerId === userStore.userInfo?.id) {
                await userStore.setUserInfo(coworkerId);
            }
            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('coworker'),
                    param2: t('Entity.lowercase_action_removed'),
                }),
            });

            if (context === UserDetailsContext.MyTeams && teamId) {
                history.push(TEAMS_URL + `/${teamId}`);
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const fetchUser = useCallback(async () => {
        try {
            const response = await userService.getUser(id);

            const mappedHeaderResponse: UserDetailsHeaderProps = {
                imageUrl: response?.imageUrl || undefined,
                name: `${response?.firstName} ${response?.lastName}`,
                status: response?.accountStatus,
                subTitle: mergeStrings([response?.jobTitle, response?.team?.name], ' - '),
                actionButtons: {
                    onEdit:
                        context === UserDetailsContext.Settings
                            ? () => setEditModalVisible(true)
                            : undefined,
                    onResentConfirmationEmail:
                        context === UserDetailsContext.Settings
                            ? onResentConfirmationEmail
                            : undefined,
                    onDeactivate:
                        context === UserDetailsContext.Settings ? onDeactivate : undefined,
                    onReactivate:
                        context === UserDetailsContext.Settings ? onReactivate : undefined,
                    onRemoveFromTeam:
                        context === UserDetailsContext.MyTeams
                            ? () => onRemoveFromTeam(id)
                            : undefined,
                },
                role: response?.managementRoles?.[0]?.name ?? ManagementRoleDto.None,
            };

            if (response) setUserData({ header: mappedHeaderResponse, info: response });
            setIsLoaded((prev) => {
                return { ...prev, user: true };
            });
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, [globalLoadingStore, toastStore, userService, id, setUserData]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return (
        <div className="UserDetails">
            <UserDetailsHeader
                imageUrl={userData?.header.imageUrl}
                name={userData?.header.name}
                status={userData?.header.status}
                subTitle={userData?.header.subTitle}
                actionButtons={userData?.header.actionButtons}
                role={userData?.header.role ?? ManagementRoleDto.None}
                isLoaded={isLoaded.user}
            />

            <DetailsPageNavigationTabs defaultActiveKey={UserDetailsTab.ProfileDetails}>
                <Tabs.TabPane
                    tab={
                        <>
                            <Icon iconName="PersonFillIcon" width={26} height={26} />
                            {t('profile_details')}
                        </>
                    }
                    key={UserDetailsTab.ProfileDetails}
                >
                    <Content className="layout-small-size">
                        <ProfileDetailsTab data={userData?.info} />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane
                    key={UserDetailsTab.ContactPreferences}
                    tab={
                        <>
                            <Icon iconName="PeopleFillIcon" width={26} height={26} />
                            {t('contact_preferences')}
                        </>
                    }
                >
                    <Content className="layout-small-size">
                        <ContactPreferencesTab data={userData?.info} />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane
                    key={UserDetailsTab.Workplace}
                    tab={
                        <>
                            <Icon
                                iconName="HouseBuildingIcon"
                                fill="currentColor"
                                width={26}
                                height={26}
                            />
                            {t('workplaces')}
                        </>
                    }
                >
                    <Content className="layout-small-size">
                        <WorkplacesTab data={userData?.info} />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane
                    key={UserDetailsTab.HoursPreferences}
                    tab={
                        <>
                            <Icon iconName="ClockFilledIcon" width={26} height={26} />
                            {t('hours_preferences')}
                        </>
                    }
                >
                    <Content>
                        <HoursPreferencesTab data={userData?.info} />
                    </Content>
                </Tabs.TabPane>
            </DetailsPageNavigationTabs>

            {editModalVisible && (
                <CreateUserModal
                    visible={editModalVisible}
                    onComplete={(success?: boolean) => {
                        setEditModalVisible(false);
                        if (success) fetchUser();
                        if (success && userData?.info?.id === userStore.userInfo?.id)
                            userStore.setUserInfo(userData?.info.id ?? '');
                    }}
                    editUserObject={userData?.info}
                />
            )}
        </div>
    );
};

export default UserDetails;
