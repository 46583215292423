import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { VerticalCarousel } from 'Components/vertical-carousel';
import React, { useState } from 'react';
import DashboardHappening from '../dashboard-happening';
import DashboardTeam, { Team } from '../dashboard-team';
import './index.less';

interface DashboardHappeningTeamProps {
    happenings: HappeningDto[];
    team?: Team;
    fetchStatsTeams: () => Promise<void>;
}

const DashboardHappeningTeam: React.FunctionComponent<DashboardHappeningTeamProps> = ({
    happenings,
    team,
    fetchStatsTeams,
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const activeSlide = 0;

    const onChange = async (index: number) => {
        const isSlideTeam = happenings.length === 0 || happenings.length + 1 === index;

        if (isSlideTeam && !isLoaded) {
            await fetchStatsTeams();
            setIsLoaded(true);
        }
    };

    return (
        <div className="DashboardHappeningTeam">
            <div className="waves-container">
                <div className="waves" />
            </div>

            <VerticalCarousel activeSlide={activeSlide} height="270px" onChange={onChange}>
                {happenings.map((hap) => (
                    <DashboardHappening key={hap.id} happening={hap} />
                ))}

                <DashboardTeam isLoaded={isLoaded} data={team} />
            </VerticalCarousel>
        </div>
    );
};

export default DashboardHappeningTeam;
