import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MainWorkplaceIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.3293 7.17107L14.1785 2L12.0278 7.17107L6.44517 7.61862L10.6985 11.2621L9.39905 16.7097L14.1785 13.7904L18.958 16.7097L17.6586 11.2621L21.9119 7.61862L16.3293 7.17107ZM22.1988 23.1611C22.1988 25.1146 19.1733 26.8139 14.123 26.8139C9.07267 26.8139 6.04724 25.1146 6.04724 23.1611C6.04724 21.0251 9.31884 19.6434 11.9313 19.5322V20.9536C10.1606 21.033 7.86568 21.8509 7.86568 23.0023C7.86568 24.3761 10.4941 25.3131 14.123 25.3131C17.7519 25.3131 20.3883 24.3681 20.3883 23.0023C20.3883 21.8509 18.0934 21.033 16.3147 20.9536V19.5322C18.9272 19.6434 22.1988 21.0251 22.1988 23.1611Z"
                    fill={fill}
                />
                <path
                    d="M13.4099 23.368L13.8254 23.7827C14.0206 23.9775 14.3366 23.9775 14.5318 23.7827L14.9473 23.368C15.0341 23.2814 15.086 23.1659 15.0932 23.0435L15.517 15.8385C15.5274 15.6611 15.443 15.4916 15.2952 15.3931L14.4559 14.8336C14.288 14.7216 14.0692 14.7216 13.9012 14.8336L13.062 15.3931C12.9142 15.4916 12.8298 15.6611 12.8402 15.8385L13.2639 23.0435C13.2711 23.1659 13.3231 23.2814 13.4099 23.368Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default MainWorkplaceIcon;
