// GENERATED FILE - DO NOT MODIFY
import { CreateOfficeRequestDto } from 'Api/Features/Offices/Dtos/CreateOfficeRequestDto';
import { CreateOfficeResponseDto } from 'Api/Features/Offices/Dtos/CreateOfficeResponseDto';
import { CreateOfficeSpaceRequestDto } from 'Api/Features/Offices/Dtos/CreateOfficeSpaceRequestDto';
import { CreateOfficeSpaceResponseDto } from 'Api/Features/Offices/Dtos/CreateOfficeSpaceResponseDto';
import { GetOfficeSpaceOccupancyRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestDto';
import { GetOfficeSpaceOccupancyResponseDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponseDto';
import { GetOfficeSpaceOccupantsRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupantsRequestDto';
import { GetOfficeSpaceOccupantsResponseDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupantsResponseDto';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { GetOfficeSpacesResponseDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesResponseDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { GetOfficesResponseDto } from 'Api/Features/Offices/Dtos/GetOfficesResponseDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { UpdateOfficeRequestDto } from 'Api/Features/Offices/Dtos/UpdateOfficeRequestDto';
import { UpdateOfficeSpaceOrderingRequest } from 'Api/Features/Offices/Dtos/UpdateOfficeSpaceOrderingRequest';
import { UpdateOfficeSpaceRequestDto } from 'Api/Features/Offices/Dtos/UpdateOfficeSpaceRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class OfficeProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getOffice(officeId: string): Promise<OfficeDto | null> {
        const uri = this.buildUri(
            "/offices/{officeId}",
            { officeId: officeId },
            null
        );

        const data: OfficeDto | null = await this.httpClient.get<OfficeDto | null>(uri);
        return data;
    }

    public async getOffices(request: GetOfficesRequestDto | null): Promise<GetOfficesResponseDto | null> {
        const uri = this.buildUri(
            "/offices",
            null,
            { ...request || undefined }
        );

        const data: GetOfficesResponseDto | null = await this.httpClient.get<GetOfficesResponseDto | null>(uri);
        return data;
    }

    public async createOffice(request: CreateOfficeRequestDto | null): Promise<CreateOfficeResponseDto | null> {
        const uri = this.buildUri(
            "/offices",
            null,
            null
        );

        const data: CreateOfficeResponseDto | null = await this.httpClient.post<CreateOfficeRequestDto | null, CreateOfficeResponseDto | null>(uri, request);
        return data;
    }

    public async updateOffice(officeId: string, request: UpdateOfficeRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/offices/{officeId}",
            { officeId: officeId },
            null
        );

        await this.httpClient.patch<UpdateOfficeRequestDto | null, void>(uri, request);
    }

    public async deleteOffice(officeId: string): Promise<void> {
        const uri = this.buildUri(
            "/offices/{officeId}",
            { officeId: officeId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getOfficeSpace(officeSpaceId: string): Promise<OfficeSpaceDto | null> {
        const uri = this.buildUri(
            "/office-spaces/{officeSpaceId}",
            { officeSpaceId: officeSpaceId },
            null
        );

        const data: OfficeSpaceDto | null = await this.httpClient.get<OfficeSpaceDto | null>(uri);
        return data;
    }

    public async getOfficeSpaces(request: GetOfficeSpacesRequestDto | null): Promise<GetOfficeSpacesResponseDto | null> {
        const uri = this.buildUri(
            "/office-spaces",
            null,
            { ...request || undefined }
        );

        const data: GetOfficeSpacesResponseDto | null = await this.httpClient.get<GetOfficeSpacesResponseDto | null>(uri);
        return data;
    }

    public async createOfficeSpace(request: CreateOfficeSpaceRequestDto | null): Promise<CreateOfficeSpaceResponseDto | null> {
        const uri = this.buildUri(
            "/office-spaces",
            null,
            null
        );

        const data: CreateOfficeSpaceResponseDto | null = await this.httpClient.post<CreateOfficeSpaceRequestDto | null, CreateOfficeSpaceResponseDto | null>(uri, request);
        return data;
    }

    public async updateOfficeSpace(officeSpaceId: string, request: UpdateOfficeSpaceRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/office-spaces/{officeSpaceId}",
            { officeSpaceId: officeSpaceId },
            null
        );

        await this.httpClient.patch<UpdateOfficeSpaceRequestDto | null, void>(uri, request);
    }

    public async deleteOfficeSpace(officeSpaceId: string): Promise<void> {
        const uri = this.buildUri(
            "/office-spaces/{officeSpaceId}",
            { officeSpaceId: officeSpaceId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async getOfficeSpaceOccupancy(request: GetOfficeSpaceOccupancyRequestDto | null): Promise<GetOfficeSpaceOccupancyResponseDto | null> {
        const uri = this.buildUri(
            "/office-space-occupancy",
            null,
            null
        );

        const data: GetOfficeSpaceOccupancyResponseDto | null = await this.httpClient.post<GetOfficeSpaceOccupancyRequestDto | null, GetOfficeSpaceOccupancyResponseDto | null>(uri, request);
        return data;
    }

    public async getOfficeSpaceDefaultOccupancy(request: GetOfficeSpaceOccupancyRequestDto | null): Promise<GetOfficeSpaceOccupancyResponseDto | null> {
        const uri = this.buildUri(
            "/office-space-default-occupancy",
            null,
            null
        );

        const data: GetOfficeSpaceOccupancyResponseDto | null = await this.httpClient.post<GetOfficeSpaceOccupancyRequestDto | null, GetOfficeSpaceOccupancyResponseDto | null>(uri, request);
        return data;
    }

    public async getOfficeSpaceOccupants(request: GetOfficeSpaceOccupantsRequestDto | null): Promise<GetOfficeSpaceOccupantsResponseDto | null> {
        const uri = this.buildUri(
            "/office-space-occupants",
            null,
            { ...request || undefined }
        );

        const data: GetOfficeSpaceOccupantsResponseDto | null = await this.httpClient.get<GetOfficeSpaceOccupantsResponseDto | null>(uri);
        return data;
    }

    public async getOfficeSpaceDefaultOccupants(request: GetOfficeSpaceOccupantsRequestDto | null): Promise<GetOfficeSpaceOccupantsResponseDto | null> {
        const uri = this.buildUri(
            "/office-space-default-occupants",
            null,
            { ...request || undefined }
        );

        const data: GetOfficeSpaceOccupantsResponseDto | null = await this.httpClient.get<GetOfficeSpaceOccupantsResponseDto | null>(uri);
        return data;
    }

    public async updateOfficeSpaceOrdering(officeId: string, request: UpdateOfficeSpaceOrderingRequest | null): Promise<void> {
        const uri = this.buildUri(
            "/offices/{officeId}/office-spaces/order",
            { officeId: officeId },
            null
        );

        await this.httpClient.put<UpdateOfficeSpaceOrderingRequest | null, void>(uri, request);
    }
}