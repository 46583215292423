import { Tooltip } from 'antd';
import React from 'react';
import './index.less';

export interface DayCircle {
    id: string;
    text: string;
    isActive: boolean;
    isDisabled?: boolean;
    tooltip?: string;
}

interface CircleToggleProps {
    data: DayCircle;
    onClick?: () => void;
}

const CircleToggle: React.FunctionComponent<CircleToggleProps> = ({ data, onClick }) => {
    const isClicable = !data.isDisabled && onClick !== undefined;

    return (
        <Tooltip overlay={data.tooltip} overlayClassName="circle-tooltip">
            <div
                className={`CircleToggle isClicable-${isClicable} isActive-${data.isActive} isDisabled-${data.isDisabled}`}
                onClick={isClicable ? onClick : undefined}
            >
                {data.text}
            </div>
        </Tooltip>
    );
};

export default CircleToggle;
