import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Content from 'Components/Content';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';
import moment from 'moment';
import {
    PAGE_SIZE_INFINITE_LOADING,
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    CloseIcon,
    MagnifyingGlassIcon,
    PeopleFill3Icon,
} from 'Components/icons';
import './index.less';
import { useAsyncSingleSelectProps, useService, useStores, useWindowDimensions } from 'Hooks';
import { img, ImgSize } from 'Utils/ImgUtils';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import { GetDaySchedulesRequestDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesRequestDto';
import { ScheduleService } from 'Services/ScheduleService';
import { GetDaySchedulesResponseItemDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesResponseItemDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { TeamService } from 'Services/TeamService';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { observer } from 'mobx-react-lite';
import { UserService } from 'Services/UserService';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import CustomDatePicker from 'Components/date-picker/custom-date-picker';
import TooltipWrapper from 'Components/tooltip-wrapper';
import { FavToggle } from 'Components/toggle';
import Skeleton from 'Components/skeleton';
import { coworkersTimeSheetTabSkeletonShapes } from '../skeleton-shapes';
import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { useTranslation } from 'react-i18next';
import { createMomentFromUtcWithoutChangingTime, getUtcOffsetString } from 'Utils/TimeUtils';

enum SpecificCoworkerLoadingTypeEnum {
    Personal = 'Personal',
    Single = 'Single',
    All = 'All',
}

const CoworkerTimeSheet: FunctionComponent = observer(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const { t } = useTranslation();
    const COLUMN_JUMP_ON_CLICK = 4;
    const SHEET_COLUMN_WIDTH = 79;
    const SHEET_COLUMNS_TOTAL_WIDTH = SHEET_COLUMN_WIDTH * 24; //1896
    const headerTimeContainerRef: any = useRef();
    const windowDimensions = useWindowDimensions();
    const { userStore, toastStore, languageStore } = useStores();
    const scheduleService = useService(ScheduleService);
    const teamService = useService(TeamService);
    const userService = useService(UserService);
    const teamCoworkersObserver: any = useRef();

    // skeleton loading states
    const [fetchingTeamCoworkers, setFetchingTeamCoworkers] = useState(false);
    const [fetchingSpecificCoworker, setFetchingSpecificCoworker] = useState(false);
    const [reFetchAllSpecificCoworkers, setRefetchAllSpecificCoworkers] = useState(false);
    const [fetchingPersonalInfo, setFetchingPersonalInfo] = useState(false);

    const { asyncSingleSelectProps: teamSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetTeamsRequestDto) =>
                await teamService.getTeams(request),
        },
        entityToSingleSelectCustomOption: (team: TeamDto) =>
            ({
                value: team.id,
                label: team.name,
            } as SingleSelectCustomOption),
    });
    const { asyncSingleSelectProps: userSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetUsersRequestDto) =>
                await userService.getUsers(request),
            fetchFunctionExtraParams: {
                excludedIds: userStore.userInfo?.id,
            },
        },
        entityToSingleSelectCustomOption: (user: UserDto) =>
            ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
            } as SingleSelectCustomOption),
    });

    const [personalSchedule, setPersonalSchedule] = useState<GetDaySchedulesResponseItemDto>();
    const [personalScheduleSheetColumns, setPersonalScheduleSheetColumns] = useState<ReactNode[]>(
        []
    );

    const [teamCoworkersPaginationCurrentPage, setTeamCoworkersPaginationCurrentPage] = useState(0);
    const [teamCoworkersTotalItemCount, setTeamCoworkersTotalItemCount] = useState(0);
    const [teamCoworkerSchedules, setTeamCoworkerSchedules] = useState<
        GetDaySchedulesResponseItemDto[]
    >([]);
    const [teamCoworkerSheetColumns, setTeamCoworkerSheetColumns] = useState<ReactNode[][]>([]);

    const [currentlySelectedSpecificCoworkerIds, setCurrentlySelectedSpecificCoworkerIds] =
        useState<string[]>([]);
    const [specificCoworkersSchedule, setSpecificCoworkersSchedule] = useState<
        GetDaySchedulesResponseItemDto[]
    >([]);
    const [specificCoworkersSheetColumns, setSpecificCoworkerSheetColumns] = useState<
        ReactNode[][]
    >([]);

    const [showPrevNav, setShowPrevNav] = useState(false);
    const [showNextNav, setShowNextNav] = useState(true);
    const [currentColumnOffset, setCurrentColumnOffset] = useState(0);
    const [maxColumnOffsetHit, setMaxColumnOffsetHit] = useState(false);
    const [sheetTranslateValue, setSheetTranslateValue] = useState(0);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [centerSheetOnPersonalSchedule, setCenterSheetOnPersonalSchedule] = useState(false);

    useEffect(() => {
        //slack command creates a link to this page with a specific user
        if (urlParams) {
            const userId = urlParams.get('userId');
            if (userId) {
                onSpecificUserSelectChange({ value: userId, label: '' });
            }
        }
    }, []);

    useEffect(() => {
        //should only trigger once. After initial fetch of personal schedule, let user decide sheet offset
        if (centerSheetOnPersonalSchedule && personalSchedule) {
            if (
                (personalSchedule.events?.length ?? 0) < 1 ||
                moment(personalSchedule.events?.[0]?.startTime).day() !== moment(selectedDate).day()
            ) {
                setCurrentColumnOffset(0);
            } else {
                const start = moment(personalSchedule.events?.[0]?.startTime).hour();
                if (start < 4) {
                    setCurrentColumnOffset(0);
                } else if (start < 11) {
                    setCurrentColumnOffset(1);
                } else if (start <= 23) {
                    setCurrentColumnOffset(2);
                }
            }
        }
    }, [centerSheetOnPersonalSchedule, personalSchedule]);

    /** team logic */
    const fetchTeamSchedules = useCallback(
        async (date: string) => {
            try {
                setFetchingTeamCoworkers(true);
                const request: GetDaySchedulesRequestDto = {
                    teamIds: teamSelectProps.selected ? [teamSelectProps.selected] : undefined,
                    date: date,
                    timeZone: userStore.userInfo?.timeZone,
                    pageSize: PAGE_SIZE_INFINITE_LOADING,
                    page: teamCoworkersPaginationCurrentPage,
                    favoritesForUserId: userStore.userInfo?.id,
                };

                const [responseCoworkers, responseTotalCoworkers] =
                    await scheduleService.getDailySchedules(request);

                if (responseCoworkers !== undefined && responseTotalCoworkers !== undefined) {
                    const coworkers = responseCoworkers.filter(
                        (x) => x.user?.id !== undefined && x.user?.id !== userStore.userInfo?.id
                    );
                    setTeamCoworkerSchedules((prev) => [...prev, ...coworkers]);
                    setTeamCoworkersTotalItemCount(responseTotalCoworkers);
                }
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                setFetchingTeamCoworkers(false);
            }
        },
        [
            toastStore,
            scheduleService,
            teamSelectProps.selected,
            selectedDate,
            teamCoworkersPaginationCurrentPage,
            userStore.userInfo?.id,
        ]
    );

    const onTeamChange = (value?: SingleSelectCustomOption) => {
        teamSelectProps.onChange(value);
        setTeamCoworkerSchedules([]);
        setTeamCoworkersPaginationCurrentPage(0);
    };

    useEffect(() => {
        if (teamSelectProps.selected && selectedDate)
            fetchTeamSchedules(moment(selectedDate).format(FORMAT_YEAR_MONTH_DAY));
    }, [teamSelectProps.selected, selectedDate]);

    const clearTeam = () => {
        setTeamCoworkerSchedules([]);
        setTeamCoworkerSheetColumns([]);
        setTeamCoworkersPaginationCurrentPage(0);
        setTeamCoworkersTotalItemCount(0);
        teamSelectProps.onChange(undefined);
    };

    useEffect(() => {
        if (userStore.userInfo?.team) {
            teamSelectProps.onChange({
                label: userStore.userInfo?.team?.name ?? '',
                value: userStore.userInfo?.team?.id ?? '',
            });
        }
    }, [userStore.userInfo?.team]);

    useEffect(() => {
        setTeamCoworkerSheetColumns(
            teamCoworkerSchedules.map((coworker) => getQuarterHourColumns(coworker))
        );
    }, [teamCoworkerSchedules]);

    const teamCoworkersLastItemInList = useCallback(
        (node) => {
            if (teamCoworkersObserver.current) teamCoworkersObserver.current.disconnect();
            teamCoworkersObserver.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    handleTeamInfiniteScrollPagination();
                    teamCoworkersObserver.current.disconnect();
                }
            });
            if (node) teamCoworkersObserver.current.observe(node);
        },
        [teamCoworkersObserver]
    );

    const handleTeamInfiniteScrollPagination = () => {
        const maxIsAlreadyLoaded =
            teamCoworkersTotalItemCount > 0
                ? teamCoworkerSchedules.length >= teamCoworkersTotalItemCount
                : false;

        if (maxIsAlreadyLoaded) return;

        return setTeamCoworkersPaginationCurrentPage((prev) => prev + 1);
    };

    //infinite scroll trigger
    useEffect(() => {
        if (teamCoworkersPaginationCurrentPage > 0)
            fetchTeamSchedules(moment(selectedDate).format(FORMAT_YEAR_MONTH_DAY));
    }, [teamCoworkersPaginationCurrentPage]);
    /** team logic */

    /**Specific users logic */
    const fetchSpecificUserSchedule = useCallback(
        async (
            userIds: string[],
            date: string,
            loadingType: SpecificCoworkerLoadingTypeEnum
        ): Promise<GetDaySchedulesResponseItemDto[] | undefined> => {
            try {
                if (loadingType === SpecificCoworkerLoadingTypeEnum.Personal)
                    setFetchingPersonalInfo(true);
                else if (loadingType === SpecificCoworkerLoadingTypeEnum.All)
                    setRefetchAllSpecificCoworkers(true);
                else setFetchingSpecificCoworker(true);

                const request: GetDaySchedulesRequestDto = {
                    userIds: userIds,
                    date: date,
                    timeZone: userStore.userInfo?.timeZone,
                    pageSize: PAGE_SIZE_INFINITE_LOADING,
                    page: 0,
                    favoritesForUserId: userStore.userInfo?.id,
                };

                const [response] = await scheduleService.getDailySchedules(request);

                return response;
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
                return undefined;
            } finally {
                if (loadingType === SpecificCoworkerLoadingTypeEnum.Personal)
                    setFetchingPersonalInfo(false);
                else if (loadingType === SpecificCoworkerLoadingTypeEnum.All)
                    setRefetchAllSpecificCoworkers(false);
                else setFetchingSpecificCoworker(false);
            }
        },
        [toastStore, scheduleService, userStore.userInfo?.id]
    );

    const onSpecificUserSelectChange = async (option?: SingleSelectCustomOption) => {
        if (
            option?.value &&
            !currentlySelectedSpecificCoworkerIds.some((id) => id === option.value)
        ) {
            setCurrentlySelectedSpecificCoworkerIds((prev) => [...prev, option?.value]);
            const specificSchedule = await fetchSpecificUserSchedule(
                [option.value],
                moment(selectedDate).format(FORMAT_YEAR_MONTH_DAY),
                SpecificCoworkerLoadingTypeEnum.Single
            );
            if (specificSchedule)
                setSpecificCoworkersSchedule((prev) => [...prev, specificSchedule[0]]);
        }
    };

    useEffect(() => {
        const fetchCurrentUser = async (id: string) => {
            const schedule = await fetchSpecificUserSchedule(
                [id],
                moment(selectedDate).format(FORMAT_YEAR_MONTH_DAY),
                SpecificCoworkerLoadingTypeEnum.Personal
            );
            if (schedule) setPersonalSchedule(schedule[0]);
        };
        if (userStore.userInfo?.id && selectedDate) {
            fetchCurrentUser(userStore.userInfo?.id);
            setCenterSheetOnPersonalSchedule(true);
        }
    }, [userStore.userInfo?.id, selectedDate]);

    useEffect(() => {
        setPersonalScheduleSheetColumns(getQuarterHourColumns(personalSchedule));
    }, [personalSchedule]);

    useEffect(() => {
        setSpecificCoworkerSheetColumns(
            specificCoworkersSchedule.map((coworker) => getQuarterHourColumns(coworker))
        );
    }, [specificCoworkersSchedule]);

    const removeSpecificUser = (id?: string) => {
        setCurrentlySelectedSpecificCoworkerIds((prev) => prev.filter((ids) => ids !== id));
        setSpecificCoworkersSchedule((prev) => prev.filter((schedule) => schedule.user?.id !== id));
    };
    /**Specific users logic */

    const onClearClick = () => {
        setCurrentlySelectedSpecificCoworkerIds([]);
        setSpecificCoworkersSchedule([]);
        setSpecificCoworkerSheetColumns([]);
        clearTeam();
    };

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        setTeamCoworkerSchedules([]);
        setTeamCoworkersPaginationCurrentPage(0);
    };

    //On date change, refetch all specificly selected coworkers
    useEffect(() => {
        const fetchSchedules = async () => {
            const schedules = await fetchSpecificUserSchedule(
                currentlySelectedSpecificCoworkerIds,
                moment(selectedDate).format(FORMAT_YEAR_MONTH_DAY),
                SpecificCoworkerLoadingTypeEnum.All
            );
            if (schedules) setSpecificCoworkersSchedule(schedules);
        };
        //fetch schedule for all specific users
        if (selectedDate && currentlySelectedSpecificCoworkerIds.length > 0) fetchSchedules();
    }, [selectedDate]);

    //Sheet header time cells
    const timeColumnHeaders = useMemo((): ReactNode[] => {
        const headers: ReactNode[] = [];
        const time = moment().startOf('day');
        for (let index = 0; index < 24; index++) {
            headers.push(
                <div className="time text-caption-2" key={index}>
                    {time.format(
                        FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
                    )}
                </div>
            );
            time.add(1, 'hour');
        }
        return headers;
    }, []);

    //Sheet schedule cells
    const getQuarterHourColumns = (schedule?: GetDaySchedulesResponseItemDto): ReactNode[] => {
        const columns: ReactNode[] = [];
        const quarterHourPointInTime = moment
            .tz(
                createMomentFromUtcWithoutChangingTime(
                    schedule?.day?.date ?? moment().format(),
                    userStore.userInfo?.timeZone ?? ''
                ),
                userStore.userInfo?.timeZone ?? ''
            )
            .startOf('day');
        const COLUMN_WIDTH = 79;
        const events = schedule?.events
            ?.filter((event) => event !== null && event.type !== EventTypeDto.Away)
            .map((event) => event!);

        const emptyCell = (index: number, color: string) => (
            <div
                className={`schedule-quarterhour-column ${color}`}
                key={`${index}${schedule?.user?.id}`}
            />
        );

        if (!events?.length || events?.length < 1) {
            //no events for day, every cell is clear
            for (
                let quarterHourIndex = 0;
                quarterHourIndex < 24 /*(h)*/ * 4 /*(quarterhour)*/;
                quarterHourIndex++
            ) {
                columns.push(
                    emptyCell(
                        quarterHourIndex,
                        quarterHourPointInTime.hour() % 2 ? 'grey' : 'white'
                    )
                );
                quarterHourPointInTime.add(15, 'minute');
            }
        } else {
            for (let quarterHourIndex = 0; quarterHourIndex < 24 /*(h)*/ * 4 /*(quarterhour)*/; ) {
                const overlappingEvent = events.find(
                    (event) =>
                        moment
                            .tz(event?.startTime, userStore.userInfo?.timeZone ?? '')
                            .isSame(quarterHourPointInTime) ||
                        //if event overlaps into day from day before
                        (quarterHourPointInTime.isSame(
                            quarterHourPointInTime.clone().startOf('day')
                        ) &&
                            moment
                                .tz(event?.startTime, userStore.userInfo?.timeZone ?? '')
                                .day() !== quarterHourPointInTime.day())
                );

                if (overlappingEvent) {
                    const workType =
                        overlappingEvent.workType === WorkTypeDto.Office ? 'office' : 'remote';
                    const eventType = overlappingEvent.type;
                    const eventStart = moment.tz(
                        overlappingEvent.startTime,
                        userStore.userInfo?.timeZone ?? ''
                    );
                    const eventEnd = moment.tz(
                        overlappingEvent.endTime,
                        userStore.userInfo?.timeZone ?? ''
                    );
                    const eventDurationInMinutes = moment
                        .tz(overlappingEvent?.endTime, userStore.userInfo?.timeZone ?? '')
                        .diff(
                            //if event overlaps into day from day before, only consider time for this day
                            moment
                                .tz(overlappingEvent?.startTime, userStore.userInfo?.timeZone ?? '')
                                .day() !== quarterHourPointInTime.day()
                                ? moment
                                      .tz(
                                          overlappingEvent?.endTime,
                                          userStore.userInfo?.timeZone ?? ''
                                      )
                                      .clone()
                                      .startOf('day')
                                : moment.tz(
                                      overlappingEvent?.startTime,
                                      userStore.userInfo?.timeZone ?? ''
                                  ),
                            'minutes'
                        );

                    columns.push(
                        <TooltipWrapper
                            title={
                                <span className="text-caption-2-bold text-white">{`${
                                    overlappingEvent?.office
                                        ? `${overlappingEvent?.office.name}:`
                                        : ''
                                } ${eventStart.format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )} - ${eventEnd.format(
                                    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                        languageStore.currentLanguage
                                    ]
                                )}`}</span>
                            }
                            backgroundColor={theme['layout-high-contrast']}
                            placement={'top'}
                            mouseEnterDelay={0.5}
                            key={`${quarterHourIndex}${schedule?.user?.id}`}
                        >
                            <div
                                className={`schedule-quarterhour-column ${workType}`}
                                style={{
                                    minWidth: (COLUMN_WIDTH / 4) * (eventDurationInMinutes / 15),
                                }} // Column width / quarter hours * event duration / quarter hours minutes
                            >
                                <div className={`schedule-line ${workType} ${eventType}`} />
                            </div>
                        </TooltipWrapper>
                    );

                    //increment index equivalent to event duration
                    quarterHourIndex = quarterHourIndex + eventDurationInMinutes / 15;
                    quarterHourPointInTime.add(eventDurationInMinutes, 'minute');
                } else {
                    columns.push(
                        emptyCell(
                            quarterHourIndex,
                            quarterHourPointInTime.hour() % 2 ? 'grey' : 'white'
                        )
                    );
                    quarterHourIndex++;
                    quarterHourPointInTime.add(15, 'minute');
                }
            }
        }

        return columns;
    };

    // Check which navigation buttons should be visible
    useEffect(() => {
        if (currentColumnOffset === 0) setShowPrevNav(false);
        else setShowPrevNav(true);

        if (maxColumnOffsetHit) setShowNextNav(false);
        else setShowNextNav(true);
    }, [currentColumnOffset, maxColumnOffsetHit]);

    //Translate sheet on nav click
    useEffect(() => {
        const maxOffsetHit =
            currentColumnOffset * (SHEET_COLUMN_WIDTH * COLUMN_JUMP_ON_CLICK) +
                headerTimeContainerRef.current?.offsetWidth >=
            SHEET_COLUMNS_TOTAL_WIDTH;

        setMaxColumnOffsetHit(maxOffsetHit);

        setSheetTranslateValue(
            -(maxOffsetHit
                ? SHEET_COLUMNS_TOTAL_WIDTH - headerTimeContainerRef.current?.offsetWidth
                : currentColumnOffset * (SHEET_COLUMN_WIDTH * COLUMN_JUMP_ON_CLICK))
        );
    }, [
        currentColumnOffset,
        headerTimeContainerRef.current?.offsetWidth,
        windowDimensions.windowWidth,
    ]);

    return (
        <div className="CoworkerTimeSheet">
            {/* filters */}
            <div className="filters-container">
                <CustomDatePicker
                    onChange={(date: Date) => {
                        handleDateChange(date);
                    }}
                    value={selectedDate}
                    minDate={new Date()}
                    hasTodayBtn
                />

                <AsyncSingleSelect
                    className="team-select"
                    {...teamSelectProps}
                    onChange={(value?: SingleSelectCustomOption) => onTeamChange(value)}
                />

                <AsyncSingleSelect
                    {...userSelectProps}
                    className="user-select"
                    placeholder={t('add_a_coworker')}
                    selected={undefined}
                    dropdownIndicatorIcon={<MagnifyingGlassIcon />}
                    onChange={(option?: SingleSelectCustomOption) => {
                        onSpecificUserSelectChange(option);
                    }}
                />
            </div>

            <Content>
                {/* timesheet */}
                <div className="timesheet-container Shadow-100">
                    {/* Header */}
                    <div className="timesheet-header">
                        <div className="clear-container text-body text-primary-mid-contrast">
                            <div className="cursor-pointer" onClick={() => onClearClick()}>
                                <Icon
                                    iconName="ArrowClockWiseIcon"
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>
                            <span className="cursor-pointer" onClick={() => onClearClick()}>
                                {t('clear')}
                            </span>
                            <span className="utc">
                                <ClockIcon width={20} height={20} />
                                UTC {getUtcOffsetString(userStore.userInfo?.timeZone ?? '')}
                            </span>
                        </div>

                        {showPrevNav && (
                            <div
                                className="nav-btn prev-btn cursor-pointer"
                                onClick={() => setCurrentColumnOffset((prev) => prev - 1)}
                            >
                                <ChevronLeftIcon fill={theme.white} width={20} />
                            </div>
                        )}

                        <div className="time-container" ref={headerTimeContainerRef}>
                            <div
                                className="time-inner-container"
                                style={{
                                    transition: 'all 0.2s ease-in-out',
                                    translate: sheetTranslateValue,
                                }}
                            >
                                {timeColumnHeaders}
                            </div>
                        </div>

                        {showNextNav && (
                            <div
                                className="nav-btn next-btn cursor-pointer"
                                onClick={() => setCurrentColumnOffset((prev) => prev + 1)}
                            >
                                <ChevronRightIcon fill={theme.white} width={20} />
                            </div>
                        )}
                    </div>

                    {/* personal row */}
                    {/* Loading specific users */}
                    <Skeleton
                        placeholder={coworkersTimeSheetTabSkeletonShapes(1)}
                        isLoaded={!fetchingPersonalInfo}
                    >
                        <div className="sheet-row-container personal-row">
                            <div className="user-info-container Shadow-100">
                                <div
                                    className="img-container"
                                    style={{
                                        backgroundImage: `url(${
                                            userStore.userInfo?.imageUrl
                                                ? img(userStore.userInfo.imageUrl, ImgSize.s)
                                                : NoProfilePicture
                                        })`,
                                    }}
                                />
                                <span className="name text-caption-2">{t('your_schedule')}</span>
                            </div>
                            <div className="schedule-row-container">
                                <div
                                    className="schedule-row-inner-container"
                                    style={{
                                        transition: 'all 0.2s ease-in-out',
                                        translate: sheetTranslateValue,
                                    }}
                                >
                                    {personalScheduleSheetColumns}
                                </div>
                            </div>
                        </div>
                    </Skeleton>

                    {/* Specific added users */}
                    <Skeleton
                        placeholder={coworkersTimeSheetTabSkeletonShapes(
                            specificCoworkersSchedule.length
                        )}
                        isLoaded={!reFetchAllSpecificCoworkers}
                    >
                        {specificCoworkersSchedule.map((coworker, i) => {
                            return (
                                <div
                                    key={coworker.user?.id}
                                    className="sheet-row-container specific-user-row"
                                >
                                    <div className="user-info-container Shadow-100">
                                        <div
                                            className="remove"
                                            onClick={() => removeSpecificUser(coworker.user?.id)}
                                        >
                                            <CloseIcon
                                                width={17}
                                                fill={theme['text-mid-contrast']}
                                            />
                                        </div>
                                        <div
                                            className="img-container"
                                            style={{
                                                backgroundImage: `url(${
                                                    coworker.user?.imageUrl
                                                        ? img(coworker.user?.imageUrl, ImgSize.s)
                                                        : NoProfilePicture
                                                })`,
                                            }}
                                        />
                                        <span className="name text-caption-2">{`${coworker.user?.firstName} ${coworker.user?.lastName}`}</span>
                                        <FavToggle
                                            coworkerId={coworker.user?.id}
                                            relationId={coworker.user?.favoriteInfo?.id}
                                        />
                                    </div>
                                    <div className="schedule-row-container">
                                        <div
                                            className="schedule-row-inner-container"
                                            style={{
                                                transition: 'all 0.2s ease-in-out',
                                                translate: sheetTranslateValue,
                                            }}
                                        >
                                            {specificCoworkersSheetColumns[i]}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Skeleton>

                    {/* Loading adding specific user */}
                    <Skeleton
                        placeholder={coworkersTimeSheetTabSkeletonShapes(1)}
                        isLoaded={!fetchingSpecificCoworker}
                    />

                    {/* team row  */}
                    {teamSelectProps.selected && (
                        <div className="sheet-row-container team-row">
                            <div className="user-info-container Shadow-100">
                                <div className="remove" onClick={() => clearTeam()}>
                                    <CloseIcon width={17} fill={theme['text-mid-contrast']} />
                                </div>
                                <span className="name text-caption-1-bold">
                                    {teamSelectProps.selectedOption?.label}
                                </span>
                                <div className="people">
                                    <PeopleFill3Icon fill={theme['accent-mid-contrast']} />
                                </div>
                            </div>
                            <div className="schedule-row-container">
                                <div
                                    className="schedule-row-inner-container"
                                    style={{
                                        transition: 'all 0.2s ease-in-out',
                                        translate: sheetTranslateValue,
                                    }}
                                >
                                    {getQuarterHourColumns()}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* team users rows */}
                    <div className="team-coworkers-container">
                        {teamSelectProps.selected &&
                            teamCoworkerSchedules.map((coworker, i, array) => {
                                const isLast = i === array.length - 1;
                                return (
                                    <div
                                        ref={isLast ? teamCoworkersLastItemInList : undefined}
                                        key={i}
                                        className="sheet-row-container team-coworker-row"
                                    >
                                        <div className="user-info-container Shadow-100">
                                            <div
                                                className="img-container"
                                                style={{
                                                    backgroundImage: `url(${
                                                        coworker.user?.imageUrl
                                                            ? img(
                                                                  coworker.user?.imageUrl,
                                                                  ImgSize.s
                                                              )
                                                            : NoProfilePicture
                                                    })`,
                                                }}
                                            />
                                            <span className="name text-caption-2">{`${coworker.user?.firstName} ${coworker.user?.lastName}`}</span>
                                            <FavToggle
                                                coworkerId={coworker.user?.id}
                                                relationId={coworker.user?.favoriteInfo?.id}
                                            />
                                        </div>
                                        <div className="schedule-row-container">
                                            <div
                                                className="schedule-row-inner-container"
                                                style={{
                                                    transition: 'all 0.2s ease-in-out',
                                                    translate: sheetTranslateValue,
                                                }}
                                            >
                                                {teamCoworkerSheetColumns[i]}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        {/* Loading more coworkers from team infinite scroll */}
                        <Skeleton
                            placeholder={coworkersTimeSheetTabSkeletonShapes()}
                            isLoaded={!fetchingTeamCoworkers}
                        />
                    </div>
                </div>
            </Content>
        </div>
    );
});

export default CoworkerTimeSheet;
