import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';
import { OfficeUtilizationStatsGroupDto } from 'Api/Features/Stats/Dtos/OfficeUtilizationStatsGroupDto';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart as RechartsPieChart, Tooltip } from 'recharts';
import { theme } from 'Style/theme';
import { RealEstateChartBaseProps } from '..';
import './pie-chart.less';

interface PieChartDataStructure {
    name: string;
    value: number;
    trend: number;
    color: string;
    percentage: string;
}

export interface PieChartProps extends RealEstateChartBaseProps {
    lastPeriodChartData?: GetOfficeUtilizationStatsResponseDto;
}

const PieChart: React.FunctionComponent<PieChartProps> = ({ chartData, lastPeriodChartData }) => {
    const { t } = useTranslation();
    const [chartParsedData, setChartParsedData] = useState<PieChartDataStructure[]>([]);
    const [allOfficeTrend, setAllOfficeTrend] = useState<number>(0);
    const [allOfficePercentage, setAllOfficePercentage] = useState<string>();

    const RADIAN = Math.PI / 180;

    const getHourPercentageRoundedAsString = (
        hours: number | undefined,
        totalHours: number | undefined
    ): string => (totalHours === 0 ? '0' : (((hours ?? 0) / (totalHours ?? 1)) * 100).toFixed(2));

    const getHourPercentageDecimal = (
        hours: number | undefined,
        totalHours: number | undefined
    ): number => (totalHours === 0 ? 0 : ((hours ?? 0) / (totalHours ?? 1)) * 100);

    const parseApiDataForChart = useCallback(
        (
            apiData: GetOfficeUtilizationStatsResponseDto,
            lastPeriodApiData: GetOfficeUtilizationStatsResponseDto
        ) => {
            const statsGroups: OfficeUtilizationStatsGroupDto[] =
                apiData.groups?.filter((x) => x !== null)?.map((x) => x!) ?? [];

            const lastPeriodStatsGroups: OfficeUtilizationStatsGroupDto[] =
                lastPeriodApiData.groups?.filter((x) => x !== null)?.map((x) => x!) ?? [];

            //office stats
            const officesGroup = statsGroups.find((x) => x.info?.workType === WorkTypeDto.Office);
            const lastPeriodOfficesGroup = lastPeriodStatsGroups.find(
                (x) => x.info?.workType === WorkTypeDto.Office
            );

            const definedOfficesOrderedByHourValue =
                officesGroup?.groups
                    ?.filter((x) => x?.info?.office !== null)
                    .sort((a, b) => (b?.totalHours ?? 0) - (a?.totalHours ?? 0)) ?? [];

            const top3DefinedOffices = definedOfficesOrderedByHourValue.slice(0, 3);

            //offices from current period top3
            const lastPeriodOfficesFromTop3 = lastPeriodOfficesGroup?.groups
                ?.filter((x) =>
                    top3DefinedOffices
                        .map((x) => x?.info?.office?.id)
                        .some((id) => x?.info?.office?.id === id)
                )
                .map((office) => ({
                    id: office?.info?.office?.id,
                    percent: getHourPercentageDecimal(
                        office?.totalHours,
                        lastPeriodApiData.totalHours
                    ),
                }));

            const top3DefinedOfficeStats =
                top3DefinedOffices?.map(
                    (group) =>
                        ({
                            name: group?.info?.office?.name,
                            trend:
                                getHourPercentageDecimal(group?.totalHours, apiData?.totalHours) -
                                (lastPeriodOfficesFromTop3?.find(
                                    (x) => x.id === group?.info?.office?.id
                                )?.percent ?? 0),
                            value: group?.totalHours ?? 0,
                            color: theme['accent-low-contrast'],
                            percentage: getHourPercentageRoundedAsString(
                                group?.totalHours ?? 0,
                                apiData.totalHours
                            ),
                        } as PieChartDataStructure)
                ) ?? [];

            const unspecifiedOfficeStats =
                officesGroup?.groups
                    ?.filter((x) => x?.info?.office === null)
                    ?.map(
                        (group) =>
                            ({
                                name: t('unspecified_office'),
                                trend:
                                    getHourPercentageDecimal(
                                        group?.totalHours,
                                        apiData?.totalHours
                                    ) -
                                    getHourPercentageDecimal(
                                        lastPeriodOfficesGroup?.groups?.find(
                                            (group) => group?.info?.office?.id === null
                                        )?.totalHours ?? 0,
                                        lastPeriodApiData.totalHours
                                    ),
                                value: group?.totalHours ?? 0,
                                color: theme['accent-low-contrast'],
                                percentage: getHourPercentageRoundedAsString(
                                    group?.totalHours ?? 0,
                                    apiData.totalHours
                                ),
                            } as PieChartDataStructure)
                    ) ?? [];

            //if there are more then 3 offices, we need to make another group of "Other Offices"
            const otherDefinedOfficeStats: PieChartDataStructure[] = [];

            if ((definedOfficesOrderedByHourValue?.length ?? 0) > 3) {
                const totalHours = definedOfficesOrderedByHourValue
                    ?.slice(3, definedOfficesOrderedByHourValue.length - 1)
                    .reduce((prev, current) => prev + (current?.totalHours ?? 0), 0);

                //exlude offices that are in the current period top3 and the undefined office group
                const previousPeriodTotalHours = lastPeriodOfficesGroup?.groups
                    ?.filter((group) => group?.info?.office?.id)
                    ?.filter(
                        (group) =>
                            !lastPeriodOfficesFromTop3?.some(
                                (top3) => top3.id === group?.info?.office?.id
                            )
                    )
                    .reduce((prev, current) => prev + (current?.totalHours ?? 0), 0);

                otherDefinedOfficeStats.push({
                    name: t('other_offices'),
                    value: totalHours,
                    trend:
                        getHourPercentageDecimal(totalHours, apiData?.totalHours ?? 0) -
                        getHourPercentageDecimal(
                            previousPeriodTotalHours,
                            lastPeriodApiData.totalHours ?? 0
                        ),
                    color: theme['accent-low-contrast'],
                    percentage: getHourPercentageRoundedAsString(totalHours, apiData.totalHours),
                } as PieChartDataStructure);
            }

            //remote stats
            const remoteGroup = statsGroups.find((x) => x.info?.workType === WorkTypeDto.Remote);
            const previousPeriodRemoteGroup = lastPeriodStatsGroups.find(
                (x) => x.info?.workType === WorkTypeDto.Remote
            );

            //Set states
            setChartParsedData([
                ...top3DefinedOfficeStats,
                ...otherDefinedOfficeStats,
                ...unspecifiedOfficeStats,
                {
                    name: 'Remote',
                    value: remoteGroup?.totalHours ?? 0,
                    trend:
                        getHourPercentageDecimal(remoteGroup?.totalHours ?? 0, apiData.totalHours) -
                        getHourPercentageDecimal(
                            previousPeriodRemoteGroup?.totalHours ?? 0,
                            lastPeriodApiData.totalHours ?? 0
                        ),
                    color: theme['primary-low-contrast'],
                    percentage: getHourPercentageRoundedAsString(
                        remoteGroup?.totalHours,
                        apiData.totalHours
                    ),
                },
            ]);
        },
        [chartData, lastPeriodChartData]
    );

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        fill,
    }) => {
        //too small an area dont put label
        if (percent * 100 < 10) return;

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill={
                    fill === theme['accent-low-contrast']
                        ? theme['accent-high-contrast']
                        : theme['primary-high-contrast']
                }
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                className="text-caption-1-bold"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const getAllOfficePercentage = useCallback((): string => {
        if (chartData?.totalHours === 0) return '0';
        return (
            100 - ((chartParsedData.find((x) => x.name === 'Remote')?.percentage ?? 0) as number)
        ).toFixed(2);
    }, [chartParsedData, chartData]);

    const getAllOfficeTrend = useCallback((): number => {
        return (
            getHourPercentageDecimal(
                chartData?.groups
                    ?.filter((group) => group?.info?.workType === WorkTypeDto.Office)
                    .reduce((prev, current) => prev + (current?.totalHours ?? 0), 0),
                chartData?.totalHours
            ) -
            getHourPercentageDecimal(
                lastPeriodChartData?.groups
                    ?.filter((group) => group?.info?.workType === WorkTypeDto.Office)
                    .reduce((prev, current) => prev + (current?.totalHours ?? 0), 0),
                lastPeriodChartData?.totalHours
            )
        );
    }, [chartData, lastPeriodChartData]);

    useEffect(() => {
        if (chartData && lastPeriodChartData) parseApiDataForChart(chartData, lastPeriodChartData);
    }, [chartData, lastPeriodChartData]);

    useEffect(() => {
        if (chartData && lastPeriodChartData && chartParsedData) {
            setAllOfficeTrend(getAllOfficeTrend());
            setAllOfficePercentage(getAllOfficePercentage());
        }
    }, [chartData, lastPeriodChartData, chartParsedData]);

    const CustomTooltip = (props) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className="text-footnote content">
                        <div
                            className={`circle ${
                                props.payload[0].name === 'Remote' ? 'remote' : 'office'
                            }`}
                        ></div>
                        <div className="name text-caption-2">
                            {props.payload[0].name === 'Remote'
                                ? t('remote')
                                : props.payload[0].name}
                        </div>

                        <div
                            className={`trend text-caption-2-bold ${
                                props.payload[0].payload.trend > -2 &&
                                props.payload[0].payload.trend < 2
                                    ? 'stagnant'
                                    : props.payload[0].payload.trend > 0
                                    ? 'positive'
                                    : 'negative'
                            }`}
                        >
                            {props.payload[0].payload.trend > 0 && '+'}
                            {props.payload[0].payload.trend.toFixed(2)}%
                        </div>

                        <div className="percentage text-caption-2-bold">
                            {props.payload[0].payload.percentage}%
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="PieChart">
            <div className="legend-container">
                <div className="legend-line office-group">
                    <div
                        className="legend-circle"
                        style={{ backgroundColor: theme['accent-mid-contrast'] }}
                    />

                    <div>{t('offices')}</div>

                    <div
                        className={`trend ${
                            allOfficeTrend < 2 && allOfficeTrend > -2
                                ? 'stagnant'
                                : allOfficeTrend > 0
                                ? 'positive'
                                : 'negative'
                        }`}
                    >
                        <>
                            {allOfficeTrend > 0 && '+'}
                            {allOfficeTrend.toFixed(2)}%
                        </>
                    </div>

                    <div className="text-caption-2-bold score">{allOfficePercentage}%</div>
                </div>

                {chartParsedData.map((data, i) => (
                    <div
                        className={`legend-line ${data.name === 'Remote' ? 'remote' : 'office'}`}
                        key={i}
                    >
                        {data.name === 'Remote' && (
                            <div
                                className="legend-circle"
                                style={{ backgroundColor: theme['primary-light-contrast'] }}
                            />
                        )}

                        {data.name === 'Remote' ? (
                            <div className="name">{t('remote')}</div>
                        ) : (
                            <div className="name">{data.name}</div>
                        )}

                        <div
                            className={`trend ${
                                data.trend < 2 && data.trend > -2
                                    ? 'stagnant'
                                    : data.trend > 0
                                    ? 'positive'
                                    : 'negative'
                            }`}
                        >
                            <>
                                {data.trend > 0 && '+'}
                                {data.trend.toFixed(2)}%
                            </>
                        </div>

                        <div className="text-caption-2-bold score">{data.percentage}%</div>
                    </div>
                ))}
            </div>

            <RechartsPieChart width={285} height={349}>
                <Pie
                    data={chartParsedData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={142}
                    dataKey="value"
                    innerRadius={15}
                >
                    {chartParsedData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                            strokeWidth={3}
                            style={{
                                filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08)',
                            }}
                        />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </RechartsPieChart>
        </div>
    );
};

export default PieChart;
