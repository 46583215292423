// GENERATED FILE - DO NOT MODIFY
import { CreateUserScheduleEventRequestDto } from 'Api/Features/Schedules/Dtos/CreateUserScheduleEventRequestDto';
import { GetDailyWorkStatsRequestDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsRequestDto';
import { GetDailyWorkStatsResponseDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsResponseDto';
import { GetDaySchedulesRequestDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesRequestDto';
import { GetDaySchedulesResponseDto } from 'Api/Features/Schedules/Dtos/GetDaySchedulesResponseDto';
import { GetDaySimilarSchedulesRequestDto } from 'Api/Features/Schedules/Dtos/GetDaySimilarSchedulesRequestDto';
import { GetDaySimilarSchedulesResponseDto } from 'Api/Features/Schedules/Dtos/GetDaySimilarSchedulesResponseDto';
import { GetDayUserCountsRequestDto } from 'Api/Features/Schedules/Dtos/GetDayUserCountsRequestDto';
import { GetDayUserCountsResponseDto } from 'Api/Features/Schedules/Dtos/GetDayUserCountsResponseDto';
import { GetTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetTeamDefaultScheduleRequestDto';
import { GetUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserDefaultScheduleRequestDto';
import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { GetUserWorkStatusRequestDto } from 'Api/Features/Schedules/Dtos/GetUserWorkStatusRequestDto';
import { GetUserWorkStatusResponseDto } from 'Api/Features/Schedules/Dtos/GetUserWorkStatusResponseDto';
import { ScheduleDto } from 'Api/Features/Schedules/Dtos/ScheduleDto';
import { UpdateTeamDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/UpdateTeamDefaultScheduleRequestDto';
import { UpdateUserDefaultSchedulePreviewResponseDto } from 'Api/Features/Schedules/Dtos/UpdateUserDefaultSchedulePreviewResponseDto';
import { UpdateUserDefaultScheduleRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserDefaultScheduleRequestDto';
import { UpdateUserScheduleEventRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserScheduleEventRequestDto';
import { UpdateUserScheduleTimelineRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserScheduleTimelineRequestDto';
import { UpdateUserWorkDayRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserWorkDayRequestDto';
import { UpdateUserWorkDayResponseDto } from 'Api/Features/Schedules/Dtos/UpdateUserWorkDayResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ScheduleProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUserDefaultSchedule(userId: string, request: GetUserDefaultScheduleRequestDto | null): Promise<ScheduleDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/default-schedule",
            { userId: userId },
            { ...request || undefined }
        );

        const data: ScheduleDto | null = await this.httpClient.get<ScheduleDto | null>(uri);
        return data;
    }

    public async updateUserDefaultSchedule(userId: string, request: UpdateUserDefaultScheduleRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/default-schedule",
            { userId: userId },
            null
        );

        await this.httpClient.put<UpdateUserDefaultScheduleRequestDto | null, void>(uri, request);
    }

    public async updateUserDefaultSchedulePreview(userId: string, request: UpdateUserDefaultScheduleRequestDto | null): Promise<UpdateUserDefaultSchedulePreviewResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/default-schedule-update-preview",
            { userId: userId },
            null
        );

        const data: UpdateUserDefaultSchedulePreviewResponseDto | null = await this.httpClient.post<UpdateUserDefaultScheduleRequestDto | null, UpdateUserDefaultSchedulePreviewResponseDto | null>(uri, request);
        return data;
    }

    public async getTeamDefaultSchedule(teamId: string, request: GetTeamDefaultScheduleRequestDto | null): Promise<ScheduleDto | null> {
        const uri = this.buildUri(
            "/teams/{teamId}/default-schedule",
            { teamId: teamId },
            { ...request || undefined }
        );

        const data: ScheduleDto | null = await this.httpClient.get<ScheduleDto | null>(uri);
        return data;
    }

    public async updateTeamDefaultSchedule(teamId: string, request: UpdateTeamDefaultScheduleRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/teams/{teamId}/default-schedule",
            { teamId: teamId },
            null
        );

        await this.httpClient.put<UpdateTeamDefaultScheduleRequestDto | null, void>(uri, request);
    }

    public async getUserSchedule(userId: string, request: GetUserScheduleRequestDto | null): Promise<ScheduleDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/schedule",
            { userId: userId },
            { ...request || undefined }
        );

        const data: ScheduleDto | null = await this.httpClient.get<ScheduleDto | null>(uri);
        return data;
    }

    public async createUserScheduleEvent(userId: string, request: CreateUserScheduleEventRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/schedule-events",
            { userId: userId },
            null
        );

        await this.httpClient.post<CreateUserScheduleEventRequestDto | null, void>(uri, request);
    }

    public async updateUserScheduleEvent(userId: string, scheduleEventId: string, request: UpdateUserScheduleEventRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/schedule-events/{scheduleEventId}",
            { userId: userId, scheduleEventId: scheduleEventId },
            null
        );

        await this.httpClient.patch<UpdateUserScheduleEventRequestDto | null, void>(uri, request);
    }

    public async deleteUserScheduleEvent(userId: string, scheduleEventId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/schedule-events/{scheduleEventId}",
            { userId: userId, scheduleEventId: scheduleEventId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async updateUserScheduleTimeline(userId: string, request: UpdateUserScheduleTimelineRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/schedule/update-timeline",
            { userId: userId },
            null
        );

        await this.httpClient.post<UpdateUserScheduleTimelineRequestDto | null, void>(uri, request);
    }

    public async updateUserWorkDay(userId: string, request: UpdateUserWorkDayRequestDto | null): Promise<UpdateUserWorkDayResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/schedule/update-work-day",
            { userId: userId },
            null
        );

        const data: UpdateUserWorkDayResponseDto | null = await this.httpClient.post<UpdateUserWorkDayRequestDto | null, UpdateUserWorkDayResponseDto | null>(uri, request);
        return data;
    }

    public async getDaySchedules(request: GetDaySchedulesRequestDto | null): Promise<GetDaySchedulesResponseDto | null> {
        const uri = this.buildUri(
            "/day-schedules",
            null,
            { ...request || undefined }
        );

        const data: GetDaySchedulesResponseDto | null = await this.httpClient.get<GetDaySchedulesResponseDto | null>(uri);
        return data;
    }

    public async getDaySimilarSchedules(request: GetDaySimilarSchedulesRequestDto | null): Promise<GetDaySimilarSchedulesResponseDto | null> {
        const uri = this.buildUri(
            "/day-similar-schedules",
            null,
            { ...request || undefined }
        );

        const data: GetDaySimilarSchedulesResponseDto | null = await this.httpClient.get<GetDaySimilarSchedulesResponseDto | null>(uri);
        return data;
    }

    public async getDayUserCounts(request: GetDayUserCountsRequestDto | null): Promise<GetDayUserCountsResponseDto | null> {
        const uri = this.buildUri(
            "/day-user-counts",
            null,
            { ...request || undefined }
        );

        const data: GetDayUserCountsResponseDto | null = await this.httpClient.get<GetDayUserCountsResponseDto | null>(uri);
        return data;
    }

    public async getUserWorkStatus(userId: string, request: GetUserWorkStatusRequestDto | null): Promise<GetUserWorkStatusResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/work-status",
            { userId: userId },
            { ...request || undefined }
        );

        const data: GetUserWorkStatusResponseDto | null = await this.httpClient.get<GetUserWorkStatusResponseDto | null>(uri);
        return data;
    }

    public async getDailyWorkStats(request: GetDailyWorkStatsRequestDto | null): Promise<GetDailyWorkStatsResponseDto | null> {
        const uri = this.buildUri(
            "/daily-work-stats",
            null,
            { ...request || undefined }
        );

        const data: GetDailyWorkStatsResponseDto | null = await this.httpClient.get<GetDailyWorkStatsResponseDto | null>(uri);
        return data;
    }
}