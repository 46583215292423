import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import { PolicyBundleObjectivesCoreHoursGroupDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesCoreHoursGroupDto';
import { PolicyBundleObjectivesCoreHoursPeriodDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesCoreHoursPeriodDto';
import { PolicyBundleRestrictionsDto } from 'Api/Features/Policies/Dtos/PolicyBundleRestrictionsDto';
import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import {
    FORMAT_DAY_WRITTEN,
    TWENTY_FOUR_HOUR_MINUTE_SECOND,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import moment from 'moment';
import { SupportedLanguage } from 'Stores/LanguageStore';
import {
    createTimeMomentInTimezoneWithoutConverting,
    createTimezonedMomentFromDateAndTime,
    dayOfWeekLangMap,
    toStartNextDayIfEndOfDay,
} from './TimeUtils';
import { GetOfficeSpaceOccupancyRequestPeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestPeriodDto';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { GetOfficeSpaceOccupancyRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestDto';

export const EventTypeDtoTogetTotalHoursInEventsType = (
    type: EventTypeDto
): getTotalHoursInEventsType => {
    if (type == EventTypeDto.OffHourAvailability)
        return getTotalHoursInEventsType.availabilityHoursPerWeek;
    if (type == EventTypeDto.Work) return getTotalHoursInEventsType.workHoursPerWeek;
    return getTotalHoursInEventsType.availabilityHoursPerWeek;
};

export enum getTotalHoursInEventsType {
    workHoursPerWeek = 'workHoursPerWeek',
    daysPerWeek = 'daysPerWeek',
    officeDaysPerWeek = 'officeDaysPerWeek',
    availabilityHoursPerWeek = 'availabilityHoursPerWeek',
}

const defaultFilter = (el: any, type: string) => {
    if (type == 'workHoursPerWeek') return el.type == 'Work';
    if (type == 'daysPerWeek') return el.type == 'Work';
    if (type == 'officeDaysPerWeek') return el.type == 'Work' && el.workType == 'Office';
    if (type == 'availabilityHoursPerWeek') return el.type == 'OffHourAvailability';
    return false;
};

export function getTotalHoursInEvents<TypeGenericEventCalendar>(
    calendarEvents: TypeGenericEventCalendar[],
    type: getTotalHoursInEventsType,
    customFilter?: (el: any) => boolean
): string {
    const selectEvents = calendarEvents.filter(
        customFilter ? customFilter : (el) => defaultFilter(el, type)
    );

    if (
        type === getTotalHoursInEventsType.workHoursPerWeek ||
        type === getTotalHoursInEventsType.availabilityHoursPerWeek
    ) {
        let totalTime = 0;

        selectEvents.map((evt) => {
            const startTimeAndEndTimeExist = evt['endTime'] && evt['startTime'];

            if (startTimeAndEndTimeExist) {
                const end = moment.utc(toStartNextDayIfEndOfDay(true, evt['endTime']));
                const start = moment.utc(evt['startTime']);

                totalTime = totalTime + end.diff(start) / 1000 / 60 / 60;
            }
        });
        return `${totalTime}`;
    }

    if (
        type === getTotalHoursInEventsType.daysPerWeek ||
        type === getTotalHoursInEventsType.officeDaysPerWeek
    ) {
        const listDays: string[] = [];

        selectEvents.map((evt) => {
            const startTimeExist = evt['startTime'] && evt['startTime'].slice(8, 10);

            if (startTimeExist) {
                const isAlreadyStock = !listDays.includes(evt['startTime'].slice(8, 10));
                if (isAlreadyStock) listDays.push(evt['startTime'].slice(8, 10));
            }
        });
        return String(listDays.length);
    }
    return '0';
}

/**
 *
 * @param event Target event
 * @param possibleOverlappingEvent Potentially overlapping event
 * @param timezone Timezone to convert
 * @returns Does possibleOverlappingEvent overlap event
 */
export const eventOverlapsEvent = (
    event: ScheduleEventDto,
    possibleOverlappingEvent: ScheduleEventDto,
    timezone?: string
): boolean => {
    return (
        moment
            .tz(event.startTime, timezone ?? '')
            .isBefore(moment.tz(possibleOverlappingEvent.endTime, timezone ?? '')) &&
        moment
            .tz(event.endTime, timezone ?? '')
            .isAfter(moment.tz(possibleOverlappingEvent.startTime, timezone ?? ''))
    );
};

export const calendarEventOverlapsRestriction = (
    startTime: string,
    endTime: string,
    lang: SupportedLanguage,
    restrictions?: PolicyBundleRestrictionsDto,
    isUTC?: boolean,
    timezone?: string
): boolean => {
    if (!restrictions) return false;

    const start = isUTC ? moment.utc(startTime) : moment.tz(startTime, timezone ?? '');
    const end = isUTC ? moment.utc(endTime) : moment.tz(endTime, timezone ?? '');

    //full day restrictions
    if (
        restrictions?.daysOfWeek &&
        //daysOfWeek is a list of days we CAN work
        (!restrictions?.daysOfWeek?.some(
            (day) => dayOfWeekLangMap(day, lang) === start.format(FORMAT_DAY_WRITTEN)
        ) ||
            //if end is midnight, even if day is restricted its ok
            (!restrictions?.daysOfWeek?.some(
                (day) => dayOfWeekLangMap(day, lang) === end.format(FORMAT_DAY_WRITTEN)
            ) &&
                end.isAfter(end.clone().startOf('day'))))
    )
        return true;

    //specific time restrictions
    const restrictionStart = restrictions.workPeriodStartTime
        ? isUTC
            ? moment.utc(
                  start.format(FORMAT_YEAR_MONTH_DAY) + 'T' + restrictions.workPeriodStartTime
              )
            : moment.tz(
                  start.format(FORMAT_YEAR_MONTH_DAY) + 'T' + restrictions.workPeriodStartTime,
                  timezone ?? ''
              )
        : undefined;
    const restrictionEnd = restrictions.workPeriodEndTime
        ? isUTC
            ? moment.utc(start.format(FORMAT_YEAR_MONTH_DAY) + 'T' + restrictions.workPeriodEndTime)
            : moment.tz(
                  start.format(FORMAT_YEAR_MONTH_DAY) + 'T' + restrictions.workPeriodEndTime,
                  timezone ?? ''
              )
        : undefined;

    if (
        (restrictionStart && start.isBefore(restrictionStart)) ||
        (restrictionEnd && end.isAfter(restrictionEnd)) ||
        //if there is restriction Start, any event that spans multiple days are invalid
        (restrictionStart && start.date() !== end.date() && end.isAfter(end.clone().startOf('day')))
    )
        return true;

    return false;
};

export const isTimeRestricted = (
    dateTime: string,
    lang: SupportedLanguage,
    restrictions?: PolicyBundleRestrictionsDto
): boolean => {
    if (!restrictions) return false;

    const dateMoment = moment(dateTime);

    let restricted = false;
    //full day restrictions
    if (
        restrictions?.daysOfWeek &&
        !restrictions?.daysOfWeek?.some(
            (day) => dayOfWeekLangMap(day, lang) === dateMoment.format(FORMAT_DAY_WRITTEN)
        )
    )
        restricted = true;

    //specific time restrictions
    const restrictionStart = restrictions.workPeriodStartTime
        ? moment(dateMoment.format(FORMAT_YEAR_MONTH_DAY) + 'T' + restrictions.workPeriodStartTime)
        : moment(dateTime).startOf('day');
    const restrictionEnd = restrictions.workPeriodEndTime
        ? moment(dateMoment.format(FORMAT_YEAR_MONTH_DAY) + 'T' + restrictions.workPeriodEndTime)
        : moment(dateTime).endOf('day').add(1, 'second');

    if (dateMoment.isBefore(restrictionStart)) restricted = true;

    if (dateMoment.isSameOrAfter(restrictionEnd)) restricted = true;

    return restricted;
};

/**
 * Parse policy core hours to Dates interpreted by calendar to create core hour fake events
 */
export const coreHoursToCalendarEventTime = (
    policyCoreHours: (PolicyBundleObjectivesCoreHoursGroupDto | null)[] | null | undefined,
    currentWeek: { start: string; end: string },
    lang: SupportedLanguage
): { start: Date; end: Date }[] => {
    if (!policyCoreHours) return [];

    const dateReference = createTimeMomentInTimezoneWithoutConverting(currentWeek.start);

    const datesResult: { start: Date; end: Date }[] = [];

    policyCoreHours.forEach((coreHour: PolicyBundleObjectivesCoreHoursGroupDto | null) => {
        coreHour?.daysOfWeek?.forEach((day: DayOfWeekDto) => {
            const translatedDay = dayOfWeekLangMap(day, lang);
            coreHour.periods?.forEach((period) => {
                if (period?.startTime && period.endTime) {
                    const startSplit = period?.startTime.split(':');
                    const endSplit = period?.endTime.split(':');
                    datesResult.push({
                        start: dateReference
                            .clone()
                            .day(translatedDay)
                            .hour(parseInt(startSplit[0]))
                            .minute(parseInt(startSplit[1]))
                            .toDate(),
                        end: dateReference
                            .clone()
                            .day(translatedDay)
                            .hour(parseInt(endSplit[0]))
                            .minute(parseInt(endSplit[1]))
                            .toDate(),
                    });
                }
            });
        });
    });
    return datesResult;
};

/**
 * For weekly hours preferences
 * Calculate the amount of time the calendar events overlap with core hours for a week.
 */
export const getTotalEventOverlappingCoreHours = (
    events: ScheduleEventDto[],
    coreHoursGroups?: (PolicyBundleObjectivesCoreHoursGroupDto | null)[] | null
): number => {
    if (!coreHoursGroups || events.length < 1) return 0;

    let result = 0;

    const filteredEvents: ScheduleEventDto[] = events.filter(
        (event) =>
            event.type === EventTypeDto.Work || event.type === EventTypeDto.OffHourAvailability
    );

    const MULTIPL = 10000; // '093000' (avant replace '09:30:00') -> transformé en : 09.3
    const hourFractionMap = { '15': '25', '30': '50', '45': '75', '00': '00' }; // les fractions d'heure sont .25 .5 .75

    filteredEvents.forEach((event) => {
        const eventStartMoment = moment.utc(event.startTime);
        const eventEndMoment = moment.utc(event.endTime);
        const eventStartNumber = Number(
            eventStartMoment.format(TWENTY_FOUR_HOUR_MINUTE_SECOND).replaceAll(':', '')
        );
        const eventEndNumber = Number(
            eventEndMoment.format(TWENTY_FOUR_HOUR_MINUTE_SECOND).replaceAll(':', '')
        );
        const daysOfWeek = Object.keys(DayOfWeekDto).map((day) => day);

        coreHoursGroups
            //only corehours on days same as event
            .filter((group) =>
                group?.daysOfWeek?.includes(daysOfWeek[eventStartMoment.day()] as DayOfWeekDto)
            )
            .forEach((group: PolicyBundleObjectivesCoreHoursGroupDto | null) =>
                group?.periods?.forEach(
                    (corePeriod: PolicyBundleObjectivesCoreHoursPeriodDto | null) => {
                        const coreHourStartNumber = Number(
                            corePeriod?.startTime?.replaceAll(':', '')
                        );
                        const coreHourEndNumber = Number(corePeriod?.endTime?.replaceAll(':', ''));

                        if (
                            //event overlaps with a core hour period
                            eventStartNumber < coreHourEndNumber &&
                            eventEndNumber > coreHourStartNumber
                        ) {
                            //between the event and the core hour times,
                            //take what is greater in time for start
                            //take what is smaller in time for end
                            const startSplit =
                                eventStartNumber > coreHourStartNumber
                                    ? eventStartMoment
                                          .format(TWENTY_FOUR_HOUR_MINUTE_SECOND)
                                          .split(':')
                                    : corePeriod!.startTime!.split(':');

                            const endSplit =
                                eventEndNumber < coreHourEndNumber
                                    ? eventEndMoment
                                          .format(TWENTY_FOUR_HOUR_MINUTE_SECOND)
                                          .split(':')
                                    : corePeriod!.endTime!.split(':');

                            const endTime =
                                Number(
                                    `${endSplit[0]}${hourFractionMap[endSplit[1]]}${endSplit[2]}`
                                ) / MULTIPL;
                            const startTime =
                                Number(
                                    `${startSplit[0]}${hourFractionMap[startSplit[1]]}${
                                        startSplit[2]
                                    }`
                                ) / MULTIPL;

                            result += endTime - startTime;
                        }
                    }
                )
            );
    });

    return result;
};

export const getAllDatesOfThisWeek = (currentDay: moment.Moment): moment.Moment[] => {
    const selectedStartDate = moment(currentDay);
    const currentDate = selectedStartDate.clone().startOf('week');
    const endDate = selectedStartDate.clone().endOf('week');
    const dates: moment.Moment[] = [];

    while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.clone());
        currentDate.add(1, 'day');
    }

    return dates;
};

export interface DefaultScheduleEventWithCapacity extends ScheduleEventDto {
    capacity?: string;
    isAvailableForUser?: boolean;
}
/**
 * Fetch function for all the uses of default schedule in the app to get the capacities/attendence on the events
 * @param events schedule evenst
 * @param officeService
 * @param timeZone
 * @param toastStore
 * @returns parsed scheduleEvents with capacity/attendence
 */
export const fetchDefaultScheduleAttendence = async (
    events: ScheduleEventDto[],
    officeService,
    timeZone: string,
    toastStore
): Promise<DefaultScheduleEventWithCapacity[]> => {
    const periods: GetOfficeSpaceOccupancyRequestPeriodDto[] = [];
    const parsedEventWithCapacity: DefaultScheduleEventWithCapacity[] = [];

    const getCapacity = (reponse: GetOfficeSpaceOccupancyResponsePeriodDto[], index: number) => {
        const capacityIsEqualTo0 = reponse[index].officeSpace?.capacity === 0;
        if (capacityIsEqualTo0) return undefined;

        return `${reponse[index].occupancy} / ${reponse[index].officeSpace?.capacity}`;
    };

    // Créer le periods array.
    for (const event of events) {
        if (event?.officeSpace?.id && event?.officeSpace?.id.length > 0 && event.startTime) {
            periods.push({
                officeSpaceId: event?.officeSpace?.id || '',
                startTime: event.startTime,
                endTime: event.endTime,
            });
        }
    }

    // Appel API.
    try {
        const request: GetOfficeSpaceOccupancyRequestDto = {
            periods: periods,
            timeZone: timeZone,
        };
        const reponse: GetOfficeSpaceOccupancyResponsePeriodDto[] =
            await officeService.getOfficeSpaceDefaultOccupancy(request);

        // Réutilise l'index du retour API pour savoir le quel correspond au quel.
        let idx = 0;
        for (const event of events) {
            if (event?.officeSpace?.id && event?.officeSpace?.id.length > 0 && event.startTime) {
                periods.push({
                    officeSpaceId: event?.officeSpace?.id || '',
                    startTime: event.startTime,
                    endTime: moment.tz(event.startTime, timeZone).add(30, 'minutes').format(),
                });

                parsedEventWithCapacity.push({
                    ...event,
                    capacity: getCapacity(reponse, idx),
                    isAvailableForUser: reponse[idx].isAvailableForUser,
                });
                idx = idx + 1;
            } else {
                parsedEventWithCapacity.push(event);
            }
        }

        return parsedEventWithCapacity;
    } catch (err: any) {
        if (!err.treated) toastStore.genericError();
        return events.map((event) => ({ ...event, capacity: undefined }));
    }
};

export const validateCanFetchOccupancy = (
    startDate: moment.Moment | undefined,
    startTime: string | undefined,
    endDate: moment.Moment | undefined,
    endTime: string | undefined,
    timeZone: string | undefined | null
): boolean => {
    if (startTime && endTime && startDate && endDate) {
        const start = createTimezonedMomentFromDateAndTime(
            moment(startDate).format(FORMAT_YEAR_MONTH_DAY),
            startTime,
            timeZone ?? ''
        );
        const end = createTimezonedMomentFromDateAndTime(
            moment(endDate).format(FORMAT_YEAR_MONTH_DAY),
            endTime,
            timeZone ?? ''
        );

        if (start.isSameOrAfter(end)) return false;

        const duration = moment.duration(start.diff(end)).asHours();

        if (duration > 24 || duration < -24) {
            return false;
        }

        return true;
    } else return false;
};
