import { ClubDetailsDto } from 'Api/Features/Clubs/Dtos/ClubDetailsDto';
import { UpdateClubMembersActionDto } from 'Api/Features/Clubs/Dtos/UpdateClubMembersActionDto';
import Icon from 'Components/icons/Icon';
import { useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { CLUBS_URL, PAGE_SIZE_INFINITE_LOADING } from 'Models/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import CreateSocialEventModal, {
    SocialEventType,
} from 'Components/social-event-modal/create-social-event-modal';
import { ClubService } from 'Services/ClubService';
import { theme } from 'Style/theme';
import ClubInviteModal from '../modals/club-invite-modal';
import ClubMembersListModal, { ClubMembersListModalData } from '../modals/club-members-list-modal';
import CreateClubModal from '../modals/create-club-modal';
import ClubDetailsUi, { ClubDetailsProps } from './ui';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { GetOfficesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficesSortColumnDto';
import { OfficeService } from 'Services/OfficeService';
import { GetHappeningsRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningsRequestDto';
import moment from 'moment';
import { HappeningService } from 'Services/HappeningService';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import ScrollInfinite from 'Components/scroll-infinite';
import EventLine from 'Routes/events/components/event-line';
import Skeleton from 'Components/skeleton';
import './index.less';
import { gatheringsSkeletonShapes } from '../skeleton-shapes';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import HorizontalRadio from 'Components/horizontal-radio';
import { HappeningStatusDto } from 'Api/Features/Happenings/Dtos/HappeningStatusDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';

export enum ClubDetailsTab {
    PAST = 'PAST',
    NOW_OR_UPCOMING = 'NOW_OR_UPCOMING',
}

const ClubDetails: React.FunctionComponent = observer(() => {
    const history = useHistory();
    const { t } = useTranslation();
    const { id: clubId } = useParams<{ id: string }>();
    const urlParams = new URLSearchParams(window.location.search);
    const { globalLoadingStore, toastStore, confirmationModalStore, userStore } = useStores();

    // Services
    const clubService = useService(ClubService);
    const officeService = useService(OfficeService);
    const happeningService = useService(HappeningService);

    // UI
    const [hideGatheringsSection, setHideGatheringsSection] = useState(true);
    const [currentTab, setCurrentTab] = useState<ClubDetailsTab>(ClubDetailsTab.NOW_OR_UPCOMING);
    const [gatheringsLoading, setGatheringsLoading] = useState(false);

    // Club data
    const [clubData, setClubData] = useState<ClubDetailsProps | null>(null);
    const [gatherings, setGatherings] = useState<HappeningDto[]>([]);

    // Pagination
    const [gatheringsPaginationCurrentPage, setGatheringsPaginationCurrentPage] = useState(0);
    const [gatheringsPaginationTotalItemCount, setGatheringsPaginationTotalItemCount] = useState(0);

    const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetOfficesRequestDto) =>
                await officeService.getOffices(request),
            fetchFunctionExtraParams: {
                sortColumn: GetOfficesSortColumnDto.IsUserDefaultOffice,
                workplacesForUserId: userStore.userInfo?.id,
            },
        },
        entityToSingleSelectCustomOption: (office: OfficeDto) =>
            ({ value: office.id, label: office.name } as SingleSelectCustomOption),
        extraMandatoryOptions: {
            unshift: [
                {
                    value: 'all',
                    label: t('all_offices'),
                },
            ],
        },
        defaultSelectedOption: { value: 'all', label: t('all_offices') },
    });

    const [editClubModal, setEditClubModal] = useState<{
        visible: boolean;
        clubData?: ClubDetailsDto;
    }>({
        visible: false,
    });
    const [inviteCoworkersModal, setInviteCoworkersModal] = useState<{
        visible: boolean;
        clubId?: string;
    }>({
        visible: false,
    });
    const [membersListModal, setMemberListModal] = useState<{
        visible: boolean;
        data?: ClubMembersListModalData;
    }>({
        visible: false,
    });
    const [createGatheringModalVisible, setCreateGatheringModalVisible] = useState(false);

    const joinLeaveOnClick = useCallback(
        async (action: UpdateClubMembersActionDto, userId: string, userIsOwner?: boolean) => {
            try {
                if (action === UpdateClubMembersActionDto.Remove && userIsOwner) {
                    if (
                        !(await confirmationModalStore.confirm({
                            icon: (
                                <Icon
                                    iconName="WarningIcon"
                                    width={64}
                                    height={64}
                                    fill={theme['warning-mid-contrast']}
                                />
                            ),
                            title: t('Clubs.club_owner_leave_confirmation_modal_title'),
                            message: t('Clubs.club_owner_leave_confirmation_modal_subtitle'),
                            positiveText: t('Clubs.club_owner_leave_confirmation_modal_confirm'),
                            negativeText: t('cancel'),
                        }))
                    )
                        return;
                }

                globalLoadingStore.addLoading();

                await clubService.updateClubMembers(clubId, {
                    entries: [
                        {
                            action: action,
                            userId: userId,
                        },
                    ],
                });
                //dont await, if removing from private club we will get 403
                userStore.setUserInfo(userId);
                if (action === UpdateClubMembersActionDto.Remove) history.push(CLUBS_URL);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        },
        []
    );

    const deleteOnClick = useCallback(async () => {
        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: (
                        <Icon
                            iconName="WarningIcon"
                            width={64}
                            height={64}
                            fill={theme['warning-mid-contrast']}
                        />
                    ),
                    title: t('Clubs.club_delete_confirmation_modal_title'),
                    message: t('Clubs.club_delete_confirmation_modal_subtitle'),
                    positiveText: t('Clubs.club_delete_confirmation_modal_confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;

            globalLoadingStore.addLoading();

            await clubService.deleteClub(clubId);

            history.push(CLUBS_URL);
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, []);

    const fetchClub = useCallback(async () => {
        try {
            const userId = userStore.userInfo?.id ?? '';
            const response = await clubService.getClub(clubId, {
                favoritesForUserId: userId,
            });

            if (response && response.category) {
                const isOwner = userId === response.owner?.id;
                const isJoined = userStore.userInfo?.clubs
                    ? userStore.userInfo.clubs.map((club) => club?.id).includes(clubId)
                    : false;
                const membersImageUrls: string[] =
                    response.members && response.members.length > 0
                        ? response.members.map((member) => member?.imageUrl ?? '')
                        : [];
                const menuOptions = [
                    {
                        title: t('Clubs.club_invite_coworkers'),
                        onClick: (): void =>
                            setInviteCoworkersModal({ visible: true, clubId: clubId }),
                    },
                    {
                        title: t('Clubs.club_create_gathering'),
                        onClick: (): void => setCreateGatheringModalVisible(true),
                    },
                ];
                if (isOwner)
                    menuOptions.push({
                        title: t('edit'),
                        onClick: (): void =>
                            setEditClubModal({ visible: true, clubData: response }),
                    });
                menuOptions.push({
                    title: t('leave'),
                    onClick: () =>
                        joinLeaveOnClick(UpdateClubMembersActionDto.Remove, userId, isOwner),
                });
                if (isOwner)
                    menuOptions.push({
                        title: t('delete'),
                        onClick: () => deleteOnClick(),
                    });

                setClubData({
                    headerData: {
                        isJoined: isJoined,
                        joinOnClick: () => joinLeaveOnClick(UpdateClubMembersActionDto.Add, userId),
                        clubCategory: response.category,
                        menuOptions: menuOptions,
                    },
                    infosData: {
                        clubInfos: {
                            isJoined: isJoined,
                            isPrivate: response.isPrivate ?? true,
                            name: response.name ?? '',
                            description: response.description ?? '',
                        },
                        clubMembers: {
                            total: response.membersCount ?? 0,
                            favTotal: response.favoriteMembersCount ?? 0,
                            imageUrls: membersImageUrls,
                            viewAllOnClick: () =>
                                setMemberListModal({
                                    visible: true,
                                    data: {
                                        clubId: clubId,
                                        ownerId: response.owner?.id ?? '',
                                        userId: userId,
                                    },
                                }),
                        },
                    },
                });
            }
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        }
    }, []);

    useEffect(() => {
        if (userStore.userInfo?.id) fetchClub();
    }, [clubId, userStore.userInfo]);

    useEffect(() => {
        //Create club modal has an option to create club and after creation, add coworkers right away
        const inviteCoworkers = urlParams.get('invitePeople');
        if (inviteCoworkers) setInviteCoworkersModal({ visible: true, clubId });
    }, []);

    const fetchGatherings = useCallback(async () => {
        try {
            setGatheringsLoading(true);
            const request: GetHappeningsRequestDto = {
                officeIds:
                    officeSelectProps.selected && officeSelectProps.selected != 'all'
                        ? [officeSelectProps.selected]
                        : undefined,
                page: gatheringsPaginationCurrentPage,
                pageSize: PAGE_SIZE_INFINITE_LOADING,
                favoritesForUserId: userStore?.userInfo?.id || '',
                clubIds: [clubId],
                minEndTime:
                    currentTab === ClubDetailsTab.NOW_OR_UPCOMING
                        ? moment().startOf('day').format()
                        : undefined,
                statuses:
                    currentTab === ClubDetailsTab.PAST ? [HappeningStatusDto.Past] : undefined,
                sortDirection:
                    currentTab === ClubDetailsTab.PAST
                        ? SortDirection.Descending
                        : SortDirection.Ascending,
            };

            const [items, totalItemCount] = await happeningService.getHappenings(request);

            setGatheringsPaginationTotalItemCount(totalItemCount);
            setGatherings((prev: HappeningDto[]) => {
                return [...prev, ...items];
            });
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        } finally {
            setGatheringsLoading(false);
        }
    }, [
        officeSelectProps.selected,
        gatheringsPaginationCurrentPage,
        gatheringsPaginationTotalItemCount,
        userStore?.userInfo?.id,
        currentTab,
    ]);

    const resetGatheringSearch = () => {
        setGatheringsPaginationTotalItemCount(0);
        setGatheringsPaginationCurrentPage(0);
        setGatherings([]);
    };

    useEffect(() => {
        resetGatheringSearch();
    }, [officeSelectProps.selected, currentTab]);

    useEffect(() => {
        if (!hideGatheringsSection) fetchGatherings();
    }, [
        hideGatheringsSection,
        gatheringsPaginationCurrentPage,
        officeSelectProps.selected,
        currentTab,
    ]);

    useEffect(() => {
        if (
            userStore.ManagementRole === ManagementRoleDto.Administrator ||
            userStore.userInfo?.clubs?.some((club) => club?.id === clubId)
        )
            setHideGatheringsSection(false);
    }, [userStore.userInfo]);

    const handleGatheringsPaginationCurrentPage = (action: 'next' | 'prev') => {
        const maxIsAlreadyLoaded =
            gatheringsPaginationTotalItemCount > 0
                ? gatherings.length >= gatheringsPaginationTotalItemCount
                : false;

        if (action === 'prev' && gatheringsPaginationCurrentPage === 0) return;
        if (maxIsAlreadyLoaded) return;

        if (action === 'next') return setGatheringsPaginationCurrentPage((prev) => prev + 1);
        setGatheringsPaginationCurrentPage((prev) => prev - 1);
    };

    const handleCreatedGathering = () => {
        setGatherings([]);
        if (gatheringsPaginationCurrentPage !== 0) {
            //this will trigger refetching of gatherings
            setGatheringsPaginationCurrentPage(0);
        } else if (officeSelectProps.selected !== 'all') {
            //this will trigger refetching of gatherings
            officeSelectProps.onChange({
                value: 'all',
                label: t('all_offices'),
            });
        } else {
            //this will trigger refetching of gatherings
            setHideGatheringsSection(true);
            setHideGatheringsSection(false);
        }
    };

    return (
        <div className="ClubDetails">
            <ClubDetailsUi headerData={clubData?.headerData} infosData={clubData?.infosData} />

            {!hideGatheringsSection && (
                <div className="gatherings-container">
                    <div className="gathering-filter-container">
                        <div className="text-title-2-bold">{t('gatherings')}</div>

                        <div className="d-flex-align">
                            <HorizontalRadio
                                name="#"
                                options={[
                                    {
                                        label: t('ClubIdTab.ClubTabId_now_upcoming'),
                                        value: ClubDetailsTab.NOW_OR_UPCOMING,
                                        countIsLoading: false,
                                    },
                                    {
                                        label: t('ClubIdTab.ClubTabId_past'),
                                        value: ClubDetailsTab.PAST,
                                        countIsLoading: false,
                                    },
                                ]}
                                defaultValue={currentTab}
                                //@ts-ignore
                                onChange={setCurrentTab}
                                showBorderBottom={true}
                            />
                            <AsyncSingleSelect
                                {...officeSelectProps}
                                placeholder={t('SelectCustom.default_select_placeholder')}
                            />
                        </div>
                    </div>

                    <Skeleton placeholder={gatheringsSkeletonShapes} isLoaded={!gatheringsLoading}>
                        <div className="content">
                            <div className="body">
                                <ScrollInfinite
                                    items={gatherings}
                                    RenderItem={({ item }) => (
                                        <EventLine
                                            isPast={currentTab === ClubDetailsTab.PAST}
                                            happening={item}
                                        />
                                    )}
                                    RenderItemSkeleton={() => <EventLine isSkeleton />}
                                    handlePagination={handleGatheringsPaginationCurrentPage}
                                    paginationOptions={{
                                        pageSize: PAGE_SIZE_INFINITE_LOADING,
                                        isLoading: gatheringsLoading,
                                    }}
                                />
                            </div>
                        </div>
                    </Skeleton>
                </div>
            )}

            {editClubModal.visible && (
                <CreateClubModal
                    visible={editClubModal.visible}
                    club={editClubModal.clubData}
                    onComplete={(success?: boolean) => {
                        setEditClubModal({ visible: false });
                        if (success) fetchClub();
                    }}
                />
            )}

            {inviteCoworkersModal.visible && (
                <ClubInviteModal
                    visible={inviteCoworkersModal.visible}
                    clubId={inviteCoworkersModal.clubId ?? ''}
                    onComplete={() => setInviteCoworkersModal({ visible: false })}
                />
            )}

            {membersListModal.visible && (
                <ClubMembersListModal
                    visible={membersListModal.visible}
                    data={membersListModal.data}
                    onComplete={() => setMemberListModal({ visible: false })}
                />
            )}

            {createGatheringModalVisible && (
                <CreateSocialEventModal
                    socialEventType={SocialEventType.ClubGathering}
                    visible={createGatheringModalVisible}
                    onComplete={(success?: boolean) => {
                        setCreateGatheringModalVisible(false);
                        if (success) {
                            handleCreatedGathering();
                        }
                    }}
                    clubId={clubId}
                />
            )}
        </div>
    );
});

export default ClubDetails;
