import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserDetailsClubDto } from 'Api/Features/Users/Dtos/UserDetailsClubDto';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Badges from 'Components/badges';
import Button from 'Components/button';
import DisplayContactPreferences, {
    DisplayContactPreferencesData,
} from 'Components/display-contact-preferences';
import Divider from 'Components/divider';
import HourTimeline from 'Components/hour-timeline';
import { CloseIcon } from 'Components/icons';
import Skeleton from 'Components/skeleton';
import Tag, { TagColor } from 'Components/tag/tag';
import { FavToggle } from 'Components/toggle';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScheduleService } from 'Services/ScheduleService';
import { UserService } from 'Services/UserService';
import { getEnabledContactPreferencesMethod } from 'Utils/ContactPreferencesUtils';
import { getCoworkerWorkingHours, WorkingHours } from 'Utils/CoworkerUtils';
import { img, ImgSize } from 'Utils/ImgUtils';
import { GetDailySchedulesResponseItem } from '..';
import { coworkersDrawerContentSkeletonShape } from '../skeleton-shapes';
import ClubSquare from './club-square';
import './drawer-content.less';
import { getWorkplaceFullName } from 'Utils/WorkplaceUtils';
import { CertificationDto } from 'Api/Features/Users/Dtos/CertificationDto';
import CertificationItem from 'Routes/profile/components/certification-item';

interface DrawerContentProps {
    setDrawerVisible: (value: boolean) => void;
    selectedCoworker?: GetDailySchedulesResponseItem | null;
    onSeeWeekScheduleClick: () => void;
    onChangeFavorites?: (state: 'add' | 'remove', coworkerId: string, relationId: string) => void; // <- Mandataroy for favorite system
}

enum actionHandleStepLoading {
    addStep,
    reset,
}

const DrawerContent: React.FunctionComponent<DrawerContentProps> = observer(
    ({ setDrawerVisible, selectedCoworker, onSeeWeekScheduleClick, onChangeFavorites }) => {
        const scheduleService = useService(ScheduleService);
        const userService = useService(UserService);
        const { t } = useTranslation();
        const { toastStore, userStore, languageStore } = useStores();
        const [workingHours, setWorkingHours] = useState<WorkingHours>();
        const [userWorkStatus, setUserWorkStatus] = useState<string>();
        const [contactPreferences, setContactPreferences] =
            useState<DisplayContactPreferencesData>();
        const [isManager, setIsManager] = useState(false);
        const [stepLoading, setStepLoading] = useState({
            fetchUserWorkStatus: false,
        });
        const [skills, setSkills] = useState<string[]>([]);
        const [certifications, setCertifications] = useState<CertificationDto[]>([]);
        const [clubs, setClubs] = useState<UserDetailsClubDto[]>([]);

        const handleStepLoading = (name?: string, action?: actionHandleStepLoading) => {
            if (action === actionHandleStepLoading.addStep && name)
                setStepLoading((prev) => {
                    return { ...prev, [name]: true };
                });

            if (action === actionHandleStepLoading.reset)
                setStepLoading({
                    fetchUserWorkStatus: false,
                });
        };

        const fetchUserWorkStatus = useCallback(async () => {
            try {
                if (selectedCoworker?.user.id) {
                    const request: any = {};
                    const scheduleResponse = await scheduleService.getUserWorkStatus(
                        selectedCoworker.user.id,
                        request
                    );
                    const userResponse = await userService.getUser(selectedCoworker.user.id);

                    const enabledMethods = getEnabledContactPreferencesMethod(
                        userResponse?.contactPreferences?.methods
                    );
                    setContactPreferences(enabledMethods);
                    setSkills(
                        userResponse?.skills
                            ?.filter((skill) => skill !== null)
                            .map((skill) => skill!) ?? []
                    );
                    setCertifications(
                        userResponse?.certifications
                            ?.filter((cert) => cert !== null)
                            .map((cert) => cert!) ?? []
                    );
                    setClubs(
                        userResponse?.clubs?.filter((club) => club !== null).map((club) => club!) ??
                            []
                    );

                    handleStepLoading('fetchUserWorkStatus', actionHandleStepLoading.addStep);
                    if (scheduleResponse) {
                        switch (scheduleResponse.workType) {
                            case WorkTypeDto.Office:
                                setUserWorkStatus(
                                    scheduleResponse.office
                                        ? getWorkplaceFullName(
                                              scheduleResponse.office,
                                              scheduleResponse.officeSpace,
                                              scheduleResponse.desk,
                                              scheduleResponse.floor
                                          )
                                        : t('PlanSchedule.work_at_office')
                                );
                                break;

                            case WorkTypeDto.Remote:
                                setUserWorkStatus(
                                    t(`WorkTypeDto.WorkTypeDto_${scheduleResponse.workType}`)
                                );
                                break;

                            default:
                                setUserWorkStatus(undefined);
                                break;
                        }
                    }
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        }, [selectedCoworker?.user.id]);

        const onClickOnClose = () => setDrawerVisible(false);

        const getTimezoneString = useCallback((timezone): string => {
            const utc = moment
                .tz(new Date(), timezone ?? '')
                .format('Z')
                .split(':')?.[0];

            if (utc[1] === '0') return utc[0] + utc[2];
            else return utc;
        }, []);

        useEffect(() => {
            if (selectedCoworker) {
                setWorkingHours(
                    getCoworkerWorkingHours(
                        selectedCoworker,
                        userStore.userInfo?.timeZone ?? '',
                        languageStore.currentLanguage
                    )
                );
            }
        }, [
            selectedCoworker?.user.id,
            userStore.userInfo?.timeZone,
            languageStore.currentLanguage,
        ]);

        useEffect(() => {
            fetchUserWorkStatus();
        }, [selectedCoworker?.user.id]);

        useEffect(() => {
            handleStepLoading('', actionHandleStepLoading.reset);
            if (selectedCoworker)
                setIsManager(
                    (selectedCoworker.user.managedTeamIds &&
                        selectedCoworker.user.managedTeamIds.length > 0) ??
                        false
                );
        }, [selectedCoworker?.user.id]);

        return (
            <div className="DrawerContent">
                <Skeleton
                    isLoaded={stepLoading.fetchUserWorkStatus}
                    placeholder={coworkersDrawerContentSkeletonShape}
                >
                    <>
                        <div className="close" onClick={onClickOnClose}>
                            <CloseIcon fill="#737373" />
                        </div>

                        <FavToggle
                            TooltipPlacement="left"
                            relationId={selectedCoworker?.user.favoriteInfo?.id || ''}
                            coworkerId={selectedCoworker?.user.id}
                            onChange={onChangeFavorites}
                        />

                        <div className="personal-info-container">
                            <div className="img-status-container">
                                <div
                                    className="img-container"
                                    style={{
                                        backgroundImage: `url(${
                                            selectedCoworker?.user.imageUrl
                                                ? img(selectedCoworker.user.imageUrl, ImgSize.m)
                                                : NoProfilePicture
                                        })`,
                                    }}
                                >
                                    {isManager && (
                                        <div className="status-circle">
                                            <Tag text={t('manager')} color={TagColor.blue} />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="personal-info">
                                <div className="name text-headline-bold">
                                    {selectedCoworker?.user.firstName}{' '}
                                    {selectedCoworker?.user.lastName}
                                </div>
                                <div className="jobtitle text-caption-1">
                                    {selectedCoworker?.user.jobTitle}
                                </div>

                                <Divider marginTop={10} marginBottom={10} />

                                <div className="text-caption-1 text-mid-contrast">
                                    {t('location')}
                                </div>
                                <div className="text-body-bold">
                                    {selectedCoworker?.user.location
                                        ? selectedCoworker?.user?.location?.description
                                        : t('n/a')}
                                    ,
                                    {selectedCoworker?.user?.timeZone
                                        ? ` UTC${getTimezoneString(
                                              selectedCoworker?.user?.timeZone
                                          )}`
                                        : t('n/a')}
                                </div>
                            </div>
                        </div>

                        <div className="scroll-details-wrapper">
                            <div className="padding-cancel">
                                <Divider marginTop={0} marginBottom={15} />
                            </div>

                            {selectedCoworker?.user.team?.name && (
                                <>
                                    <div className="currently-work-info">
                                        <div className="text-caption-1 text-mid-contrast">
                                            {t('Coworker.details_teams')}
                                        </div>
                                        <div className="text-callout-bold">
                                            {selectedCoworker?.user?.team?.name}
                                        </div>
                                    </div>
                                    <div className="padding-cancel">
                                        <Divider marginTop={13} marginBottom={20} />
                                    </div>
                                </>
                            )}

                            {userWorkStatus && (
                                <>
                                    <div className="currently-work-info">
                                        <div className="text-caption-1 text-mid-contrast">
                                            {t('Coworker.currently_working')}
                                        </div>
                                        <div className="text-callout-bold">{userWorkStatus}</div>
                                    </div>
                                    <div className="padding-cancel">
                                        <Divider marginTop={20} marginBottom={20} />
                                    </div>
                                </>
                            )}

                            <div className="today-work-hours">
                                <div>
                                    <div className="text-callout-bold">{t('today_work_hours')}</div>
                                    <div className="text-caption-1 text-mid-contrast lowercase">
                                        &nbsp;({t('in_your_timezone')})
                                    </div>
                                </div>
                                <Button
                                    className="schedule-btn"
                                    text={t('see_schedule')}
                                    type="tertiary"
                                    width="hugged"
                                    onClick={onSeeWeekScheduleClick}
                                />
                            </div>

                            {workingHours && (
                                <div className="working-from-container text-caption-1">
                                    {t('working_from')}{' '}
                                    <span className="text-footnote-bold">
                                        {`${workingHours?.start} - ${workingHours?.end}`}
                                    </span>{' '}
                                    ({t('in_your_timezone')})
                                </div>
                            )}

                            <div className="hour-timeline-container">
                                <HourTimeline
                                    date={moment().format(FORMAT_YEAR_MONTH_DAY)}
                                    events={selectedCoworker?.events ?? []}
                                    timezoneConvert={userStore.userInfo?.timeZone}
                                />
                            </div>

                            {(skills.length > 0 || certifications.length > 0) && (
                                <>
                                    <div className="padding-cancel">
                                        <Divider marginTop={0} marginBottom={15} />
                                    </div>
                                    <div className="mb-10 text-caption-1 text-mid-contrast t-align-center">
                                        {t('skills_and_certifications')}
                                    </div>
                                    <div className="skill-tag-container">
                                        {skills.map((skill, i) => (
                                            <Tag
                                                text={skill}
                                                key={skill + i}
                                                color={TagColor.blueTransparent}
                                                uppercase={false}
                                            />
                                        ))}
                                    </div>
                                    {skills.length > 0 && certifications.length > 0 && (
                                        <Divider marginTop={20} marginBottom={15} />
                                    )}
                                </>
                            )}

                            {certifications.length > 0 && (
                                <>
                                    {certifications.map((cert, i, arr) => (
                                        <div key={cert.title ?? '' + cert.date ?? ''}>
                                            <CertificationItem
                                                key={cert.date}
                                                certification={{ ...cert, id: '' }}
                                            />
                                            {i + 1 < arr.length && (
                                                <Divider marginTop={10} marginBottom={10} />
                                            )}
                                        </div>
                                    ))}
                                </>
                            )}

                            {selectedCoworker?.user.badges &&
                                selectedCoworker.user.badges.length > 0 && (
                                    <>
                                        <div className="padding-cancel">
                                            <Divider marginTop={13} marginBottom={20} />
                                        </div>
                                        <div className="currently-work-info">
                                            <div className="text-caption-1 text-mid-contrast">
                                                {t('Coworker.badges')}
                                            </div>
                                            <Badges types={selectedCoworker.user.badges} />
                                        </div>
                                    </>
                                )}

                            {contactPreferences && (
                                <>
                                    <div className="padding-cancel">
                                        <Divider marginTop={20} marginBottom={20} />
                                        <DisplayContactPreferences
                                            simpleMode
                                            data={contactPreferences}
                                        />
                                    </div>
                                </>
                            )}

                            {clubs.length > 0 && (
                                <>
                                    <div className="padding-cancel">
                                        <Divider marginTop={20} marginBottom={20} />
                                    </div>
                                    <div className="clubs-container">
                                        <div className="mb-10">Clubs ({clubs.length})</div>
                                        <div className="club-squares">
                                            {clubs.map((club) => (
                                                <ClubSquare key={club.id} club={club} />
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                </Skeleton>
            </div>
        );
    }
);

export default DrawerContent;
