import Button from 'Components/button';
import { useStores } from 'Hooks';
import LayoutErrorsPage from 'Layout/errors_page';
import { DASHBOARD_URL } from 'Models/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './index.less';

export const ForbiddenPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { authorizationStore } = useStores();

    return (
        <LayoutErrorsPage className="ForbiddenPage">
            <div className="text-titleXL">{t('ForbiddenPage.page_forbidden_title')}</div>
            <div className="text-callout">{t('ForbiddenPage.this_page_no_access')}</div>
            <Button
                text={t('ForbiddenPage.go_to_dashboard')}
                type="primary"
                onClick={() => {
                    authorizationStore.resetForbidden();
                    history.push(DASHBOARD_URL);
                }}
            />
        </LayoutErrorsPage>
    );
};

export default ForbiddenPage;
