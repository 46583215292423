import React from 'react';
import './index.less';

export const policyDetailsCard = (
    <div className="policyDetailsCard">
        <div className="wrapper-1">
            <div className="rect-1" />
            <div className="rect-2" />
            <div className="rect-2" />
        </div>
        <div className="wrapper-2">
            <div className="rect-3" />
            <div className="rect-4" />
            <div className="rect-5" />
        </div>
    </div>
);
