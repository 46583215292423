import { Col, Form, Input, Row, Switch, Tabs } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { EventTypeDto } from 'Api/Features/Schedules/Dtos/EventTypeDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { ScheduleEventSourceDto } from 'Api/Features/Schedules/Dtos/ScheduleEventSourceDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import Button from 'Components/button';
import Divider from 'Components/divider';
import { ClockIcon, DeleteIcon, SpaceViewerIcon, WarningIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import TimePickerDropdown from 'Components/time-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useAsyncSingleSelectProps, useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import {
    MINIMUM_TIME_PERIOD_MINUTES,
    TWENTY_FOUR_HOUR_MINUTE,
    FORMAT_YEAR_MONTH_DAY,
    FORM_GUTTER,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SchedulePreferenceSchema } from 'Schemas/SchedulePreferenceSchema';
import { OfficeService } from 'Services/OfficeService';
import { theme } from 'Style/theme';
import { eventOverlapsEvent, getAllDatesOfThisWeek } from 'Utils/CalendarUtils';
import {
    createMomentFromUtcWithoutChangingTime,
    createUtcWithoutChangingTime,
    dayOfWeekLangMap,
    toStartNextDayIfEndOfDay,
} from 'Utils/TimeUtils';
import CustomRadioButton from './custom-radio-button';
import DayReplicator, { DayReplicatorStateEnum } from './day-replicator';
import './preference-time-period-modal.less';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';
import Modal, { ModalHeaderColor } from 'Components/modal';
import SpaceViewerModalContent, {
    SpaceViewerScheduleType,
} from 'Components/space-viewer-modal/components/space-viewer-modal-content';
import { mergeStrings } from 'Utils/TextUtils';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { SpaceWithCapacityExtended } from 'Components/weekly-schedule-calendar/components/work-tab';
import { singleSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { GetOfficeSpaceOccupancyRequestPeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestPeriodDto';
import Skeleton from 'Components/skeleton';
import { TimePeriodModalDayReplicatorSkeletonShapes } from 'Components/weekly-schedule-calendar/skeleton-shapes';
import SubmitButton from 'Components/submit-button/submit-button';
import ViewTypeToggle, {
    SpaceViewerViewType,
} from 'Components/space-viewer-modal/components/view-type-toggle';
import { FloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanFloorDto';
import { useFetch } from 'Hooks/use-fetch';
import { FloorPlanDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanDto';
import { FloorPlanService } from 'Services/FloorPlanService';
import FloorPlanViewer from 'Components/space-viewer-modal/components/floor-plan-viewer/floor-plan-viewer';

interface PreferenceTimePeriodModalProps {
    visible: boolean;
    onComplete: (newEvents?: ScheduleEventDto[], removeEvent?: RemoveEvent[]) => void;
    isEditingTimePeriod?: boolean;
    startTime?: string;
    endTime?: string;
    date?: string;
    isOffHour?: boolean;
    workType?: WorkTypeDto;
    eventStartString?: string;
    eventEndString?: string;
    calendarEvents: ScheduleEventDto[];
    id?: string;
    source?: ScheduleEventSourceDto;
    office?: SingleSelectCustomOption;
    space?: SingleSelectCustomOption;
    desk?: string;
    floor?: string;
    isEditingTeamSchedule?: boolean;
}

export interface RemoveEvent {
    id?: string;
    eventStartString?: string;
    eventEndString?: string;
    source?: ScheduleEventSourceDto;
}

const PreferenceTimePeriodModal: React.FunctionComponent<PreferenceTimePeriodModalProps> = observer(
    ({
        visible,
        onComplete,
        isEditingTimePeriod,
        startTime: startTimeProp,
        endTime: endTimeProp,
        date: dateProp,
        office,
        space,
        isOffHour,
        workType,
        calendarEvents,
        eventStartString,
        eventEndString,
        id,
        source: timelinePeriodStatus,
        desk,
        floor,
        isEditingTeamSchedule,
    }) => {
        const officeService = useService(OfficeService);
        const floorPlanService = useService(FloorPlanService);
        const { t } = useTranslation();
        const { userStore, confirmationModalStore, policyStore, languageStore, toastStore } =
            useStores();
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors, setErrors] = useFormValidation(
            SchedulePreferenceSchema,
            form
        );
        const [capacityBlocksSubmit, setCapacityBlocksSubmit] = useState(false);

        const [selectedWorkType, setSelectedWorkType] = useState<WorkTypeDto>(
            workType ??
                (userStore.userInfo?.preferredWorkType === UserPreferredWorkTypeDto.Remote
                    ? WorkTypeDto.Remote
                    : WorkTypeDto.Office)
        );
        const [isLoaded, setIsLoaded] = useState({
            checkReplicatePeriodIsAvailable: false,
        });
        const [offHourIsChecked, setOffHourIsChecked] = useState(isOffHour);
        const [date, setDate] = useState<moment.Moment>();
        const [selectedDay, setSelectedDay] = useState<string>(); //'written day'
        const [startTime, setStartTime] = useState<string | undefined>(undefined);
        const [endTime, setEndTime] = useState<string | undefined>(endTimeProp);
        const [replicateIsChecked, setReplicateIsChecked] = useState(false);
        const [replicateDaysState, setReplicateDaysState] = useState<DayReplicatorStateEnum[]>([]);
        const [spaceViewerVisible, setSpaceViewerVisible] = useState(false);
        const [spaceViewerBtnEnabled, setSpaceViewerBtnEnabled] = useState(false);

        const [spaceViewerSelectedSpace, setSpaceViewerSelectedSpace] = useState<
            SingleSelectCustomOption | undefined
        >(space); //user can cancel when in space viewer so only set selectedSpace when confirmed

        const [selectedOfficeOption, setSelectedOfficeOption] = useState<
            SingleSelectCustomOption | undefined
        >(office);
        const [defaultOfficeIsDisabled, setDefaultOfficeIsDisabled] = useState(true); //disabled by default in case slow network and trying to click faster

        const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficesRequestDto) =>
                    await officeService.getOffices(request),
            },
            entityToSingleSelectCustomOption: (office: OfficeDto) =>
                ({ value: office.id, label: office.name } as SingleSelectCustomOption),
            defaultSelectedOption: office,
            extraMandatoryOptions: userStore.userInfo?.defaultOffice?.id
                ? {
                      unshift: [
                          {
                              value: t('OfficeOccupancyCard.use_default_office'),
                              label: t('OfficeOccupancyCard.use_default_office'),
                              content: {
                                  node: `${mergeStrings(
                                      [
                                          userStore.userInfo.defaultOfficeSpace?.name,
                                          userStore.userInfo.defaultFloor,
                                          userStore.userInfo.defaultDesk,
                                      ],
                                      ' - '
                                  )}`,
                              },
                              isDisabled: defaultOfficeIsDisabled,
                          },
                      ],
                  }
                : undefined,
        });

        const [spacesWithCapacity, setSpacesWithCapacity] = useState<SpaceWithCapacityExtended[]>(
            []
        );
        const [spacesWithCapacityOptions, setSpacesWithCapacityOptions] = useState<
            SingleSelectCustomOption[]
        >([]);
        const [selectedSpace, setSelectedSpace] = useState<SingleSelectCustomOption | undefined>(
            space
        );
        const { asyncSingleSelectProps: spaceSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficeSpacesRequestDto) =>
                    await officeService.getOfficeSpaces(request),
                fetchFunctionExtraParams: {
                    officeIds: selectedOfficeOption ? [selectedOfficeOption.value] : undefined,
                },
            },
            entityToSingleSelectCustomOption: (space: OfficeSpaceDto) =>
                ({
                    value: space.id,
                    label: space.name,
                } as SingleSelectCustomOption),
            defaultSelectedOption: space,
        });

        const [spaceViewerType, setSpaceViewerType] = useState<SpaceViewerViewType>(
            SpaceViewerViewType.SpaceViewer
        );

        const [floorPlanFloors, setFloorPlanFloors] = useState<FloorPlanFloorDto[]>([]);
        const { apiRequest, loadingStateKeys } = useFetch();

        //floor plan must be fetched in order to determine if the view switcher is active
        const fetchFloorPlan = useCallback(
            async (officeId: string) => {
                const floorPlan: FloorPlanDto = await apiRequest({
                    requestFunction: () => floorPlanService.getFloorPlan(officeId),
                    requestParameters: undefined,
                    loadingStateKey: 'floorplan',
                });
                if (floorPlan) {
                    setFloorPlanFloors(
                        floorPlan.floors?.filter((x) => x !== null).map((x) => x!) ?? []
                    );
                }
            },
            [floorPlanService]
        );

        useEffect(() => {
            if (selectedOfficeOption?.value && spaceViewerVisible)
                fetchFloorPlan(selectedOfficeOption.value);
        }, [selectedOfficeOption, spaceViewerVisible]);

        useEffect(() => {
            if (startTimeProp) {
                setStartTime(startTimeProp);
            }
        }, [startTimeProp]);

        useEffect(() => {
            form.setFieldsValue({
                floor: floor,
                desk: desk,
            });
        }, [floor, desk]);

        useEffect(() => {
            if (dateProp) {
                setDate(moment(dateProp));
                const daysWritten = Object.keys(DayOfWeekDto).map((day) => day);
                const dayIndex = moment(dateProp).day();
                //this has to be english value
                setSelectedDay(daysWritten[dayIndex]);
            }
        }, [dateProp]);

        useEffect(() => {
            //when fetch from space select is done, fetch the capacity for those spaces
            if (spaceSelectProps.options) {
                fetchDropdownSpaceCapacities(
                    spaceSelectProps.options.map(
                        (option) =>
                            ({
                                id: option.value,
                                name: option.label,
                            } as OfficeSpaceDto)
                    )
                );
            }
        }, [spaceSelectProps.options]);

        useEffect(() => {
            policyStore.cachedSetPolicyRestrictions();
        }, []);

        useEffect(() => {
            setSpaceViewerBtnEnabled(
                date !== undefined && startTime !== undefined && endTime !== undefined
            );
        }, [date, startTime, endTime]);

        useEffect(() => {
            getDefaultOfficeDisabledState();
        }, [userStore.userInfo?.defaultOfficeSpace, date, startTime, endTime]);

        useEffect(() => {
            //gestion des dates restreinte de replicate selon le policy
            if (date) {
                const dayArray: DayReplicatorStateEnum[] = [];
                const selectedDayIndex = date.day();

                Object.keys(DayOfWeekDto).forEach((day, dayIndex) => {
                    //event day is mandatory
                    if (dayIndex === selectedDayIndex) {
                        dayArray[dayIndex] = DayReplicatorStateEnum.Mandatory;
                    }
                    //if no restrictions, all days are available
                    else if (!policyStore.policyInfo?.restrictions) {
                        dayArray[dayIndex] = DayReplicatorStateEnum.Normal;
                    }
                    //if restrictions, day is available if in daysOfWeek list
                    else if (
                        policyStore.policyInfo.restrictions.daysOfWeek?.some(
                            (daysOfWeek: DayOfWeekDto) => day === daysOfWeek
                        )
                    ) {
                        dayArray[dayIndex] = DayReplicatorStateEnum.Normal;
                    }
                    //day is restricted
                    else dayArray[dayIndex] = DayReplicatorStateEnum.Disabled;
                });

                setReplicateDaysState(dayArray);
            }
        }, [policyStore.policyInfo?.restrictions, date]);

        useEffect(() => {
            updateReplicatorAvailability();
        }, [date, startTime, endTime, selectedSpace]);

        useEffect(() => {
            handleSelectedSpaceAvailabilityOnDateChanges(selectedSpace, date, startTime, endTime);
        }, [selectedSpace, date, startTime, endTime]);

        const handleSelectedSpaceAvailabilityOnDateChanges = async (
            selectedSpace: SingleSelectCustomOption | undefined,
            selectedDate: moment.Moment | undefined,
            selectedStartTime: string | undefined,
            selectedEndTime: string | undefined
        ) => {
            if (!selectedSpace || !selectedDate || !selectedStartTime || !selectedEndTime) return;

            const getOfficeSpaceOccupancyRequest: GetOfficeSpaceOccupancyRequestPeriodDto[] =
                officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                    [
                        {
                            spaceId: selectedSpace.value,
                            startTime: startTime!,
                            endTime: endTime!,
                            momentSelectedStartDate: moment(date),
                            momentSelectedEndDate: moment(date),
                        },
                    ],
                    userStore.userInfo?.timeZone ?? ''
                );

            const getOfficeSpaceOccupancyResponse = await getOfficeSpaceOccupancies(
                getOfficeSpaceOccupancyRequest
            );

            const isFull = !getOfficeSpaceOccupancyResponse?.[0].isAvailableForUser;
            if (isFull) {
                setErrors(new Map([['space', [t('space_capacity_full')]]]));
                setCapacityBlocksSubmit(true);
            } else {
                resetErrors();
                setCapacityBlocksSubmit(false);
            }
        };

        const fetchDropdownSpaceCapacities = async (spaces: OfficeSpaceDto[]): Promise<void> => {
            //without these informations, we cannot get space capacities. We must still go through with creating options for the space dropdown
            if (!date || !startTime || !endTime) {
                setSpacesWithCapacity(spaces);
                return;
            }

            const getOfficeSpaceOccupancyRequest: GetOfficeSpaceOccupancyRequestPeriodDto[] =
                officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                    spaces.map((space) => ({
                        spaceId: space.id!,
                        startTime: startTime,
                        endTime: endTime,
                        momentSelectedStartDate: moment(date),
                        momentSelectedEndDate: moment(date),
                    })),
                    userStore.userInfo?.timeZone ?? ''
                );

            const getOfficeSpaceOccupancyResponse = await getOfficeSpaceOccupancies(
                getOfficeSpaceOccupancyRequest
            );

            if (getOfficeSpaceOccupancyResponse) {
                const newWorkplacesWithCapacity = spaces.map((space) => {
                    if (space.id) {
                        const period = getOfficeSpaceOccupancyResponse.find(
                            (period) => period.officeSpace?.id === space?.id
                        );

                        return {
                            ...space,
                            currentCapacity: {
                                occupancy: period?.occupancy ?? 0,
                                capacity: period?.officeSpace?.capacity ?? 0,
                            },
                            isAvailableForUser: period?.isAvailableForUser,
                        };
                    } else {
                        return { ...space };
                    }
                });
                setSpacesWithCapacity([...newWorkplacesWithCapacity]);
            }
        };

        const getOfficeSpaceOccupancies = async (
            periods: GetOfficeSpaceOccupancyRequestPeriodDto[]
        ): Promise<GetOfficeSpaceOccupancyResponsePeriodDto[] | null> => {
            try {
                const getOfficeSpaceOccupancyResponse =
                    await officeService.getOfficeSpaceDefaultOccupancy({
                        periods: periods,
                        timeZone: userStore?.userInfo?.timeZone,
                    });

                return getOfficeSpaceOccupancyResponse;
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
                return null;
            }
        };

        useEffect(() => {
            //once the capacities for the spaces have been fetched make new options for the space select
            const searchResults = spacesWithCapacity.map((space) => {
                return {
                    label: space.name,
                    value: space.id,
                    extraData: {
                        occupancy: space.currentCapacity?.occupancy,
                        capacity: space.currentCapacity?.capacity,
                    },
                    content: space.currentCapacity
                        ? {
                              node: (
                                  <div>
                                      {t('TimePeriodModal.occupancy_period')}:{' '}
                                      {space.currentCapacity?.occupancy}/
                                      {space.currentCapacity?.capacity}
                                  </div>
                              ),
                              className: 'text-caption-1',
                              showSelected: true,
                          }
                        : undefined,
                    isDisabled: !space.isAvailableForUser,
                } as SingleSelectCustomOption;
            });
            const merged = singleSelectMergeSelectedOptionsWithSearchResults(searchResults, [
                spaceSelectProps.selectedOption,
            ]);
            setSpacesWithCapacityOptions(merged);
        }, [spacesWithCapacity, spaceSelectProps.selectedOption]);

        const dayOptions = useCallback((): SingleSelectCustomOption[] => {
            const options: SingleSelectCustomOption[] = [];
            Object.keys(DayOfWeekDto).forEach((day) => {
                const dayWritten = dayOfWeekLangMap(
                    day as DayOfWeekDto,
                    languageStore.currentLanguage
                );
                options.push({
                    label: dayWritten.charAt(0).toUpperCase() + dayWritten.slice(1), //capitalise
                    value: day,
                    isDisabled:
                        policyStore.policyInfo?.restrictions !== null &&
                        policyStore.policyInfo?.restrictions !== undefined &&
                        !policyStore?.policyInfo?.restrictions?.daysOfWeek?.some(
                            (day: DayOfWeekDto) =>
                                dayOfWeekLangMap(day, languageStore.currentLanguage) === dayWritten
                        ),
                });
            });
            return options;
        }, [policyStore.policyInfo?.restrictions]);

        const onDaySelectChange = (writtenDay: DayOfWeekDto) => {
            setSelectedDay(writtenDay);
            let dayIndex;
            switch (writtenDay) {
                case 'Sunday':
                    dayIndex = 0;
                    break;
                case 'Monday':
                    dayIndex = 1;
                    break;
                case 'Tuesday':
                    dayIndex = 2;
                    break;
                case 'Wednesday':
                    dayIndex = 3;
                    break;
                case 'Thursday':
                    dayIndex = 4;
                    break;
                case 'Friday':
                    dayIndex = 5;
                    break;
                case 'Saturday':
                    dayIndex = 6;
                    break;
            }
            setDate(moment().day(dayIndex));
        };

        //#region Submit / Exit
        const dismiss = (newEvents?: ScheduleEventDto[], removeEvent?: RemoveEvent[]): void => {
            form.resetFields();
            onComplete(newEvents, removeEvent);
        };

        const getOverlappingEvents = (newPref: ScheduleEventDto): ScheduleEventDto[] => {
            const overlappingEvents = calendarEvents
                //if editing an existing event, filter out that event
                .filter((event) =>
                    isEditingTimePeriod
                        ? !createUtcWithoutChangingTime(dateProp!, startTimeProp!).isSame(
                              event.startTime
                          )
                        : true
                )
                //only events of same day as new one
                .filter(
                    (event) =>
                        moment.utc(event.startTime).date() === moment.utc(newPref.startTime).date()
                )
                //check for overlaps
                .filter((event) => eventOverlapsEvent(newPref, event));

            return overlappingEvents;
        };

        const confirmOverlapOverwrite = async (): Promise<boolean> => {
            return await confirmationModalStore.confirm({
                icon: <WarningIcon fill={theme['warning-mid-contrast']} />,
                title: t('PrefCalendar.overwrite_confirm_title'),
                message: t('PrefCalendar.overwrite_confirm_message'),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            });
        };

        const submit = async (): Promise<void> => {
            if (capacityBlocksSubmit) return;
            const validationObject = {
                startTime: startTime,
                endTime: endTime,
            };
            const formData = form.getFieldsValue();

            if (!(await validateForm(validationObject))) return;
            const newEvent: ScheduleEventDto = {
                workType: selectedWorkType,
                type: offHourIsChecked ? EventTypeDto.OffHourAvailability : EventTypeDto.Work,
                startTime: createUtcWithoutChangingTime(
                    moment(date).format(FORMAT_YEAR_MONTH_DAY),
                    startTime!
                ).format(),
                endTime: createUtcWithoutChangingTime(
                    moment(date).format(FORMAT_YEAR_MONTH_DAY),
                    endTime!
                ).format(),
                office:
                    !isEditingTeamSchedule && selectedOfficeOption
                        ? {
                              id: selectedOfficeOption.value,
                              name: selectedOfficeOption.label,
                          }
                        : undefined,
                officeSpace:
                    !isEditingTeamSchedule &&
                    selectedSpace &&
                    selectedWorkType !== WorkTypeDto.Remote
                        ? {
                              id: selectedSpace.value,
                              name: selectedSpace.label,
                          }
                        : undefined,
                desk: isEditingTeamSchedule ? formData.desk : undefined,
                floor: isEditingTeamSchedule ? formData.floor : undefined,
                source: timelinePeriodStatus,
                id: id,
            };

            const replicatedEvents: ScheduleEventDto[] = [];

            if (replicateIsChecked) {
                replicateDaysState.forEach((replicateDayState, index) => {
                    if (replicateDayState === DayReplicatorStateEnum.Active)
                        replicatedEvents.push({
                            workType: selectedWorkType,
                            type: offHourIsChecked
                                ? EventTypeDto.OffHourAvailability
                                : EventTypeDto.Work,
                            startTime: createUtcWithoutChangingTime(
                                moment().day(index).format(FORMAT_YEAR_MONTH_DAY),
                                startTime!
                            ).format(),
                            endTime: createUtcWithoutChangingTime(
                                moment().day(index).format(FORMAT_YEAR_MONTH_DAY),
                                endTime!
                            ).format(),
                            office: selectedOfficeOption
                                ? {
                                      id: selectedOfficeOption.value,
                                      name: selectedOfficeOption.label,
                                  }
                                : undefined,
                            officeSpace: spaceSelectProps.selected
                                ? {
                                      id: spaceSelectProps.selected,
                                      name: spaceSelectProps.selectedOption?.label,
                                  }
                                : undefined,
                            desk: formData.desk,
                            floor: formData.floor,
                            source: timelinePeriodStatus,
                        });
                });
            }

            const newPrefs: ScheduleEventDto[] = [newEvent, ...replicatedEvents];

            const overlappingEvents = newPrefs.flatMap((event) => getOverlappingEvents(event));
            if (overlappingEvents.length < 1 || (await confirmOverlapOverwrite())) {
                const removeEvents: RemoveEvent[] = [];
                removeEvents.push(
                    ...overlappingEvents.map(
                        (event) =>
                            ({
                                id: undefined,
                                eventStartString: event.startTime ?? '',
                                eventEndString: event.endTime ?? '',
                            } as RemoveEvent)
                    )
                );
                if (isEditingTimePeriod)
                    removeEvents.push({
                        eventStartString: eventStartString!,
                        eventEndString: eventEndString!,
                        id: undefined,
                        source: ScheduleEventSourceDto.DefaultSchedule,
                    });

                dismiss(newPrefs, removeEvents);
            }
        };

        const remove = async (removeEvent: RemoveEvent): Promise<void> => {
            dismiss(undefined, [removeEvent]);
        };

        const onChangeStartTime = (value: string) => {
            if (!value) {
                return;
            }

            setStartTime(value);

            if (endTime && value) {
                if (value >= endTime) setEndTime('');
            }
        };

        const handleUseDefaultOffice = () => {
            setSelectedOfficeOption({
                value: userStore.userInfo?.defaultOffice?.id ?? '',
                label: userStore.userInfo?.defaultOffice?.name ?? '',
            });
            if (userStore.userInfo?.defaultOfficeSpace?.id) {
                const option = {
                    value: userStore.userInfo.defaultOfficeSpace.id,
                    label: userStore.userInfo?.defaultOfficeSpace.name ?? '',
                };
                spaceSelectProps.onChange(option);
                setSelectedSpace(option);
            } else {
                spaceSelectProps.onChange(undefined);
                setSelectedSpace(undefined);
            }
            resetErrors();
            setCapacityBlocksSubmit(false);
            form.setFieldsValue({
                desk: userStore.userInfo?.defaultDesk,
                floor: userStore.userInfo?.defaultFloor,
            });
        };

        const getDefaultOfficeDisabledState = async (): Promise<void> => {
            if (userStore.userInfo?.defaultOfficeSpace?.id) {
                if (startTime && endTime) {
                    const getOfficeSpaceOccupancyRequest: GetOfficeSpaceOccupancyRequestPeriodDto[] =
                        officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                            [
                                {
                                    spaceId: userStore.userInfo?.defaultOfficeSpace?.id,
                                    startTime: startTime,
                                    endTime: endTime,
                                    momentSelectedStartDate: moment(date),
                                    momentSelectedEndDate: moment(date),
                                },
                            ],
                            userStore.userInfo?.timeZone ?? ''
                        );
                    const getOfficeSpaceOccupancyResponse = await getOfficeSpaceOccupancies(
                        getOfficeSpaceOccupancyRequest
                    );

                    setDefaultOfficeIsDisabled(
                        !getOfficeSpaceOccupancyResponse?.[0]?.isAvailableForUser ?? false
                    );
                }
            } else {
                setDefaultOfficeIsDisabled(false);
            }
        };

        const updateReplicatorAvailability = useCallback(async () => {
            if (selectedSpace === undefined) {
                setIsLoaded((prev) => ({ ...prev, checkReplicatePeriodIsAvailable: true }));
                return;
            }
            if (!date || !selectedSpace || !startTime || !endTime) {
                return;
            }

            setIsLoaded((prev) => ({ ...prev, checkReplicatePeriodIsAvailable: false }));

            const allDates = getAllDatesOfThisWeek(date);

            const draftPeriods = allDates.map((date) => {
                return {
                    spaceId: selectedSpace?.value,
                    startTime: startTime,
                    endTime: endTime,
                    momentSelectedStartDate: date,
                    momentSelectedEndDate: date,
                };
            });

            const periods = officeService.createGetOfficeSpaceOccupancyRequestPeriodDto(
                draftPeriods,
                userStore.userInfo?.timeZone ?? ''
            );

            const spaceOccupancyResponse: GetOfficeSpaceOccupancyResponsePeriodDto[] | null =
                await getOfficeSpaceOccupancies(periods);

            if (spaceOccupancyResponse) {
                const newState = [...replicateDaysState];
                spaceOccupancyResponse.forEach((period, index) => {
                    const isNotAvailable = !period.isAvailableForUser;
                    const isMandatoryDate =
                        moment.tz(period.startTime, userStore.userInfo?.timeZone ?? '').day() ===
                        date.day();

                    if (isNotAvailable) {
                        newState[index] = DayReplicatorStateEnum.Disabled_full_capacity;
                    } else if (isMandatoryDate) {
                        newState[index] = DayReplicatorStateEnum.Active;
                    } else {
                        newState[index] = DayReplicatorStateEnum.Normal;
                    }
                });
                setReplicateDaysState(newState);
            }

            setIsLoaded((prev) => ({ ...prev, checkReplicatePeriodIsAvailable: true }));
        }, [date, selectedSpace, startTime, endTime]);

        //#region Render
        return (
            <Modal
                centered
                footer={
                    spaceViewerVisible ? (
                        <>
                            <Button
                                text={t('cancel')}
                                type="tertiary"
                                onClick={() => {
                                    setSpaceViewerVisible(false);
                                    setSpaceViewerSelectedSpace(spaceSelectProps.selectedOption);
                                }}
                            />
                            <Button
                                text={t('confirm')}
                                type="primary"
                                onClick={() => {
                                    setSpaceViewerVisible(false);
                                    spaceSelectProps.onChange(spaceViewerSelectedSpace);
                                    setSelectedSpace(spaceViewerSelectedSpace);
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Button text={t('cancel')} type="tertiary" onClick={() => dismiss()} />
                            <SubmitButton
                                text={isEditingTimePeriod ? t('save') : t('add')}
                                type="primary"
                                onClick={() => submit()}
                            />
                        </>
                    )
                }
                width={
                    !spaceViewerVisible
                        ? 640
                        : spaceViewerType == SpaceViewerViewType.SpaceViewer
                        ? 640
                        : 1200
                }
                onCancel={() => dismiss()}
                visible={visible}
                closeIcon={<Icon iconName="CloseIcon" fill={theme['text-mid-contrast']} />}
                wrapClassName="PreferenceTimePeriodModalWrapper"
                headerText={t('PrefCalendar.time_period')}
                headerActionIcon={
                    isEditingTimePeriod ? (
                        <DeleteIcon
                            onClick={() =>
                                remove({
                                    id,
                                    eventStartString,
                                    eventEndString,
                                    source: timelinePeriodStatus,
                                })
                            }
                            fill={theme['text-mid-contrast']}
                            width={25}
                            height={25}
                        />
                    ) : null
                }
                headerColor={ModalHeaderColor.white}
                noPadding
            >
                <Form
                    layout="vertical"
                    form={form}
                    className={`TimePeriodModal-form ${spaceViewerVisible && 'grey'}`}
                >
                    <div
                        className={`TimePeriodModal ${
                            spaceViewerVisible &&
                            spaceViewerType == SpaceViewerViewType.FloorPlan &&
                            'floor-plan-visible'
                        }`}
                    >
                        <div
                            className={`time-period-container ${spaceViewerVisible && 'fade-out'}`}
                        >
                            <Tabs defaultActiveKey={'1'}>
                                <Tabs.TabPane tab={'Work Period'} key="1">
                                    <Content>
                                        <div className="px-20">
                                            <div className="off-hour-container px-20">
                                                <span
                                                    className="text-body text cursor-pointer"
                                                    onClick={() =>
                                                        setOffHourIsChecked((old) => !old)
                                                    }
                                                >
                                                    {t('PrefCalendar.off_hour_availability')}
                                                </span>
                                                <Switch
                                                    onChange={(e) => setOffHourIsChecked(e)}
                                                    checked={offHourIsChecked}
                                                    size="small"
                                                />
                                            </div>
                                        </div>

                                        <Divider marginTop={20} marginBottom={40} />

                                        <div className="px-20">
                                            <div className="date-time-container">
                                                <div className="section-title-container">
                                                    <div className="blue-icon-container">
                                                        <ClockIcon
                                                            width={16}
                                                            height={16}
                                                            fill={theme['primary-mid-contrast']}
                                                        />
                                                    </div>

                                                    <span className="title">{t('date_time')}</span>
                                                </div>
                                                <div className="controls-container pl-40">
                                                    <div className="control">
                                                        <ValidatedFormItem label="from">
                                                            <StaticSingleSelect
                                                                className="day"
                                                                options={dayOptions()}
                                                                selected={selectedDay}
                                                                onChange={(
                                                                    option?: SingleSelectCustomOption
                                                                ) => {
                                                                    if (option) {
                                                                        onDaySelectChange(
                                                                            option.value as DayOfWeekDto
                                                                        );
                                                                    }
                                                                }}
                                                                dropdownIndicatorIcon={<div></div>}
                                                                placeholder={t(
                                                                    'SelectCustom.default_select_placeholder'
                                                                )}
                                                            />
                                                        </ValidatedFormItem>

                                                        <ValidatedFormItem
                                                            label=""
                                                            errors={errors}
                                                            name="startTime"
                                                        >
                                                            <TimePickerDropdown
                                                                onChange={onChangeStartTime}
                                                                selected={startTime}
                                                                restrictions={
                                                                    policyStore.policyInfo
                                                                        ?.restrictions ?? undefined
                                                                }
                                                                forSpecificDate={date?.format()}
                                                            />
                                                        </ValidatedFormItem>
                                                    </div>

                                                    <div className="control">
                                                        <ValidatedFormItem label={t('to')}>
                                                            <StaticSingleSelect
                                                                className="day"
                                                                options={dayOptions()}
                                                                selected={selectedDay}
                                                                disabled
                                                                onChange={() => {
                                                                    /**always disabled */
                                                                }}
                                                                dropdownIndicatorIcon={<div></div>}
                                                                placeholder={t(
                                                                    'SelectCustom.default_select_placeholder'
                                                                )}
                                                            />
                                                        </ValidatedFormItem>

                                                        <ValidatedFormItem
                                                            label=""
                                                            errors={errors}
                                                            name="endTime"
                                                        >
                                                            <TimePickerDropdown
                                                                onChange={(value: string) => {
                                                                    setEndTime(value);
                                                                }}
                                                                selected={endTime}
                                                                minimumAvailableTime={moment()
                                                                    .startOf('day')
                                                                    .add(startTime)
                                                                    .add(
                                                                        MINIMUM_TIME_PERIOD_MINUTES,
                                                                        'minute'
                                                                    )
                                                                    .format(
                                                                        TWENTY_FOUR_HOUR_MINUTE
                                                                    )}
                                                                midnightAvailable={true}
                                                                restrictions={
                                                                    policyStore.policyInfo
                                                                        ?.restrictions ?? undefined
                                                                }
                                                                forSpecificDate={date?.format()}
                                                            />
                                                        </ValidatedFormItem>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Divider marginTop={16} marginBottom={40} />

                                        <div className="px-20">
                                            <div className="section-title-container">
                                                <div className="blue-icon-container">
                                                    <Icon
                                                        width={16}
                                                        height={16}
                                                        iconName="MapPinWithCircleIcon"
                                                        fill={theme['primary-mid-contrast']}
                                                    />
                                                </div>
                                                <span className="title">{t('workplace')}</span>
                                            </div>

                                            <div className="pl-40">
                                                <div className="work-type-container">
                                                    <CustomRadioButton
                                                        label={t('WorkTypeDto.WorkTypeDto_Office')}
                                                        onSelect={(value: string) => {
                                                            setSelectedWorkType(
                                                                value as WorkTypeDto
                                                            );
                                                        }}
                                                        selected={
                                                            selectedWorkType === WorkTypeDto.Office
                                                        }
                                                        value={WorkTypeDto.Office}
                                                    />
                                                    <CustomRadioButton
                                                        label={t('WorkTypeDto.WorkTypeDto_Remote')}
                                                        onSelect={(value: string) => {
                                                            setSelectedWorkType(
                                                                value as WorkTypeDto
                                                            );
                                                        }}
                                                        selected={
                                                            selectedWorkType === WorkTypeDto.Remote
                                                        }
                                                        value={WorkTypeDto.Remote}
                                                    />
                                                </div>

                                                {!isEditingTeamSchedule &&
                                                    selectedWorkType === WorkTypeDto.Office && (
                                                        <>
                                                            <Row gutter={FORM_GUTTER}>
                                                                <Col span={24}>
                                                                    <ValidatedFormItem
                                                                        label={
                                                                            t('choose_an_office') +
                                                                            ' ' +
                                                                            t('optional')
                                                                        }
                                                                        errors={errors}
                                                                        name="office"
                                                                    >
                                                                        <AsyncSingleSelect
                                                                            {...officeSelectProps}
                                                                            placeholder={t(
                                                                                'SelectCustom.default_select_placeholder'
                                                                            )}
                                                                            selected={
                                                                                selectedOfficeOption?.value
                                                                            }
                                                                            onChange={(
                                                                                option?: SingleSelectCustomOption
                                                                            ) => {
                                                                                if (
                                                                                    option?.value ===
                                                                                    t(
                                                                                        'OfficeOccupancyCard.use_default_office'
                                                                                    )
                                                                                ) {
                                                                                    handleUseDefaultOffice();
                                                                                } else {
                                                                                    setSelectedOfficeOption(
                                                                                        option
                                                                                    );
                                                                                    spaceSelectProps.resetSearchResults();
                                                                                    setSelectedSpace(
                                                                                        undefined
                                                                                    );
                                                                                    resetErrors();
                                                                                    setCapacityBlocksSubmit(
                                                                                        false
                                                                                    );
                                                                                }
                                                                            }}
                                                                            isClearable
                                                                            disabled={
                                                                                endTime ===
                                                                                    undefined ||
                                                                                startTime ===
                                                                                    undefined ||
                                                                                date === undefined
                                                                            }
                                                                        />
                                                                    </ValidatedFormItem>
                                                                </Col>
                                                            </Row>

                                                            {!isEditingTeamSchedule &&
                                                                selectedOfficeOption && (
                                                                    <>
                                                                        <Row gutter={FORM_GUTTER}>
                                                                            <Col
                                                                                span={24}
                                                                                className="space-column"
                                                                            >
                                                                                <ValidatedFormItem
                                                                                    label={
                                                                                        t(
                                                                                            'choose_a_space'
                                                                                        ) +
                                                                                        ' ' +
                                                                                        t(
                                                                                            'optional'
                                                                                        )
                                                                                    }
                                                                                    errors={errors}
                                                                                    name="space"
                                                                                >
                                                                                    <div className="dropdown-space-viewer">
                                                                                        <AsyncSingleSelect
                                                                                            {...spaceSelectProps}
                                                                                            selected={
                                                                                                selectedSpace?.value
                                                                                            }
                                                                                            onChange={(
                                                                                                option?: SingleSelectCustomOption
                                                                                            ) => {
                                                                                                setSelectedSpace(
                                                                                                    option
                                                                                                );
                                                                                                setSpaceViewerSelectedSpace(
                                                                                                    option
                                                                                                );
                                                                                                resetErrors();
                                                                                                setCapacityBlocksSubmit(
                                                                                                    false
                                                                                                );
                                                                                            }}
                                                                                            options={
                                                                                                spacesWithCapacityOptions
                                                                                            }
                                                                                            isClearable
                                                                                            disabled={
                                                                                                endTime ===
                                                                                                    undefined ||
                                                                                                startTime ===
                                                                                                    undefined ||
                                                                                                date ===
                                                                                                    undefined
                                                                                            }
                                                                                        />
                                                                                        <div
                                                                                            className={`space-viewer-btn ${
                                                                                                spaceViewerBtnEnabled
                                                                                                    ? 'enabled'
                                                                                                    : 'disabled'
                                                                                            }`}
                                                                                            onClick={
                                                                                                spaceViewerBtnEnabled
                                                                                                    ? () =>
                                                                                                          setSpaceViewerVisible(
                                                                                                              true
                                                                                                          )
                                                                                                    : undefined
                                                                                            }
                                                                                        >
                                                                                            <SpaceViewerIcon
                                                                                                width={
                                                                                                    24
                                                                                                }
                                                                                                height={
                                                                                                    24
                                                                                                }
                                                                                                fill={
                                                                                                    theme[
                                                                                                        'accent-mid-contrast'
                                                                                                    ]
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </ValidatedFormItem>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row gutter={FORM_GUTTER}>
                                                                            <Col span={12}>
                                                                                <ValidatedFormItem
                                                                                    label={
                                                                                        t('floor') +
                                                                                        ' ' +
                                                                                        t(
                                                                                            'optional'
                                                                                        )
                                                                                    }
                                                                                    errors={errors}
                                                                                    name="floor"
                                                                                >
                                                                                    <Input />
                                                                                </ValidatedFormItem>
                                                                            </Col>

                                                                            <Col span={12}>
                                                                                <ValidatedFormItem
                                                                                    label={
                                                                                        t(
                                                                                            'Office.desk_number'
                                                                                        ) +
                                                                                        ' ' +
                                                                                        t(
                                                                                            'optional'
                                                                                        )
                                                                                    }
                                                                                    errors={errors}
                                                                                    name="desk"
                                                                                >
                                                                                    <Input />
                                                                                </ValidatedFormItem>
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )}
                                                        </>
                                                    )}
                                            </div>
                                        </div>

                                        <Divider marginBottom={40} marginTop={40} />

                                        <div className="px-20 mb-30">
                                            <div className="section-title-container">
                                                <div className="blue-icon-container">
                                                    <Icon
                                                        width={13}
                                                        height={13}
                                                        iconName="DuplicateIcon"
                                                        fill={theme['primary-mid-contrast']}
                                                    />
                                                </div>
                                                <span
                                                    className={`title replicate cursor-pointer ${
                                                        selectedDay && startTime && endTime
                                                            ? ''
                                                            : 'disabled'
                                                    }`}
                                                    onClick={() => {
                                                        if (selectedDay && startTime && endTime)
                                                            setReplicateIsChecked((old) => !old);
                                                    }}
                                                >
                                                    {t('replicate_period')}
                                                </span>
                                                <Switch
                                                    size="small"
                                                    checked={replicateIsChecked}
                                                    disabled={
                                                        selectedDay === undefined ||
                                                        startTime === undefined ||
                                                        startTime === '' ||
                                                        endTime === undefined ||
                                                        endTime === ''
                                                    }
                                                    onChange={(e) => {
                                                        if (selectedDay && startTime && endTime)
                                                            setReplicateIsChecked(e);
                                                    }}
                                                />
                                            </div>

                                            {replicateIsChecked && replicateDaysState && (
                                                <div className="replicate-container pl-40">
                                                    <Divider marginBottom={20} marginTop={20} />
                                                    <div className="text-caption-1 mb-10">
                                                        {t('replicate_on')}
                                                    </div>
                                                    <Skeleton
                                                        placeholder={
                                                            TimePeriodModalDayReplicatorSkeletonShapes
                                                        }
                                                        isLoaded={
                                                            isLoaded.checkReplicatePeriodIsAvailable
                                                        }
                                                    >
                                                        <DayReplicator
                                                            onDayChange={(dayIndex: number) => {
                                                                setReplicateDaysState((old) => {
                                                                    old[dayIndex] =
                                                                        old[dayIndex] ===
                                                                        DayReplicatorStateEnum.Active
                                                                            ? DayReplicatorStateEnum.Normal
                                                                            : DayReplicatorStateEnum.Active;
                                                                    return [...old];
                                                                });
                                                            }}
                                                            dayStates={replicateDaysState}
                                                        />
                                                    </Skeleton>
                                                </div>
                                            )}
                                        </div>
                                    </Content>
                                </Tabs.TabPane>
                            </Tabs>
                        </div>

                        {spaceViewerVisible ? (
                            <div className="space-viewer-time-period-container">
                                <div className="viewer-type-container">
                                    <div className="text-callout">{t('choose_a_space')}</div>
                                    <Skeleton
                                        isLoaded={!loadingStateKeys.has('floorplan')}
                                        defaultPadding={false}
                                        placeholder={
                                            <div
                                                style={{
                                                    width: 'auto',
                                                    marginLeft: 'auto',
                                                }}
                                            >
                                                <div
                                                    className="roundRect"
                                                    style={{ width: 82, height: 47 }}
                                                />
                                            </div>
                                        }
                                    >
                                        <ViewTypeToggle
                                            selected={spaceViewerType}
                                            onChange={(selected: SpaceViewerViewType) => {
                                                setSpaceViewerType(selected);
                                            }}
                                            floorPlanAvailable={floorPlanFloors.length > 0}
                                        />
                                    </Skeleton>
                                </div>

                                {spaceViewerType == SpaceViewerViewType.SpaceViewer ? (
                                    <SpaceViewerModalContent
                                        officeId={selectedOfficeOption?.value ?? ''}
                                        selectedSpace={spaceViewerSelectedSpace}
                                        setSelectedSpace={(space) =>
                                            setSpaceViewerSelectedSpace(space)
                                        }
                                        timeRange={{
                                            startTime: createMomentFromUtcWithoutChangingTime(
                                                moment(date).format(FORMAT_YEAR_MONTH_DAY) +
                                                    'T' +
                                                    startTime +
                                                    '.00',
                                                userStore.userInfo?.timeZone ?? ''
                                            ).format(),
                                            endTime:
                                                toStartNextDayIfEndOfDay(
                                                    false,
                                                    createMomentFromUtcWithoutChangingTime(
                                                        moment(date).format(FORMAT_YEAR_MONTH_DAY) +
                                                            'T' +
                                                            endTime +
                                                            '.00',
                                                        userStore.userInfo?.timeZone ?? ''
                                                    ).format()
                                                ) ?? '',
                                        }}
                                        scheduleType={SpaceViewerScheduleType.defaultSchedule}
                                    />
                                ) : (
                                    <FloorPlanViewer
                                        floorPlanFloors={floorPlanFloors}
                                        timeRange={{
                                            startTime: createMomentFromUtcWithoutChangingTime(
                                                moment(date).format(FORMAT_YEAR_MONTH_DAY) +
                                                    'T' +
                                                    startTime +
                                                    '.00',
                                                userStore.userInfo?.timeZone ?? ''
                                            ).format(),
                                            endTime:
                                                toStartNextDayIfEndOfDay(
                                                    false,
                                                    createMomentFromUtcWithoutChangingTime(
                                                        moment(date).format(FORMAT_YEAR_MONTH_DAY) +
                                                            'T' +
                                                            endTime +
                                                            '.00',
                                                        userStore.userInfo?.timeZone ?? ''
                                                    ).format()
                                                ) ?? '',
                                        }}
                                        selectedSpace={spaceViewerSelectedSpace}
                                        setSelectedSpace={setSpaceViewerSelectedSpace}
                                        scheduleType={SpaceViewerScheduleType.defaultSchedule}
                                    />
                                )}
                            </div>
                        ) : (
                            <div></div> //always render an element this helps with animation
                        )}
                    </div>
                </Form>
            </Modal>
        );
        //#endregion
    }
);

export default React.memo(PreferenceTimePeriodModal);
