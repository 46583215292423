import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubMusicIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M9 21H5C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V12C3 10.75 3.23733 9.579 3.712 8.487C4.18733 7.39567 4.82933 6.44567 5.638 5.637C6.446 4.829 7.396 4.18733 8.488 3.712C9.57933 3.23733 10.75 3 12 3C13.25 3 14.421 3.23733 15.513 3.712C16.6043 4.18733 17.5543 4.829 18.363 5.637C19.171 6.44567 19.8127 7.39567 20.288 8.487C20.7627 9.579 21 10.75 21 12V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H15V13H19V12C19 10.05 18.3207 8.39567 16.962 7.037C15.604 5.679 13.95 5 12 5C10.05 5 8.396 5.679 7.038 7.037C5.67933 8.39567 5 10.05 5 12V13H9V21ZM7 15H5V19H7V15ZM17 15V19H19V15H17Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubMusicIcon;
