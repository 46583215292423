import Content from 'Components/Content';
import {
    CalendarStarIcon,
    ChevronRightIcon,
    FolderIcon,
    MessageBubbleIcon,
    OfficeIcon,
    People3Icon,
    PersonIcon,
    SlackIcon,
} from 'Components/icons';
import Skeleton from 'Components/skeleton';
import WhiteCard from 'Components/white-card';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    GLOBAL_LANGUAGE_URL,
    HAPPENINGS_URL,
    OFFICES_URL,
    POLICIES_URL,
    SETTINGS_URL,
    SLACK_INTEGRATION_URL,
    TEAMS_URL,
    USERS_URL,
} from 'Models/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { SlackService } from 'Services/SlackService';
import './index.less';
import { settingsSlackShape } from './skeleton-shapes';

const Settings: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();
    const { globalLoadingStore, toastStore, slackParametersStore, userStore } = useStores();
    const slackService = useService(SlackService);
    const [menuOptions, setMenuOptions] = useState<any>();

    const fetchSlackParameters = useCallback(async (): Promise<void> => {
        try {
            await slackService.getSlackConfig();
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, slackService]);

    useEffect(() => {
        fetchSlackParameters();
    }, []);

    const slackOptionRender = useCallback(
        (key: string) => {
            const connected =
                slackParametersStore.slackParameters?.slackWorkspace !== null &&
                slackParametersStore.slackParameters?.slackWorkspace !== undefined;

            return (
                <div
                    key={key}
                    className={`slack text-body connected-${connected}`}
                    onClick={() => history.push(SETTINGS_URL + SLACK_INTEGRATION_URL)}
                >
                    <Skeleton
                        isLoaded={connected === true || connected === false}
                        placeholder={settingsSlackShape}
                    >
                        <>
                            <div className="slack-icon">
                                <SlackIcon />
                            </div>

                            <div className="text-container">
                                <div className="text-body-bold">{t('Settings.title_slack')}</div>
                                <div className="text-caption-2">
                                    {t('Settings.menu_slack_subtitle')}
                                </div>
                            </div>

                            <div className="connected-label">
                                {connected ? t('connected') : t('not_connected')}
                            </div>

                            <div className="chevron">
                                <ChevronRightIcon />
                            </div>
                        </>
                    </Skeleton>
                </div>
            );
        },
        [slackParametersStore.slackParameters]
    );

    const getMenuOptions = useCallback(() => {
        return [
            {
                sectionTitle: t('Settings.menu_management_title'),
                options: [
                    {
                        title: t('users'),
                        icon: <PersonIcon />,
                        redirect: SETTINGS_URL + USERS_URL,
                        subtitle: t('Settings.menu_users_subtitle'),
                    },
                    {
                        title: t('teams'),
                        icon: <People3Icon />,
                        redirect: SETTINGS_URL + TEAMS_URL,
                        subtitle: t('Settings.menu_teams_subtitle'),
                    },
                    {
                        title: t('happenings'),
                        icon: <CalendarStarIcon />,
                        redirect: SETTINGS_URL + HAPPENINGS_URL,
                        subtitle: t('Settings.menu_happenings_subtitle'),
                    },
                ],
            },
            {
                sectionTitle: t('Settings.menu_configuration_title'),
                options: [
                    {
                        title: t('offices'),
                        icon: <OfficeIcon />,
                        redirect: SETTINGS_URL + OFFICES_URL,
                        subtitle: t('Settings.menu_offices_subtitle'),
                    },
                    {
                        title: t('Settings.title_policy'),
                        redirect: SETTINGS_URL + POLICIES_URL,
                        icon: <FolderIcon />,
                        subtitle: t('Settings.menu_guardrails_subtitle'),
                    },
                ],
            },
            {
                sectionTitle: t('Settings.menu_integration_title'),
                options: [{ customRender: (key: string) => slackOptionRender(key) }],
            },
            {
                sectionTitle: t('Settings.menu_general'),
                options: [
                    {
                        title: t('SupportedLanguage.language_preferences'),
                        icon: <MessageBubbleIcon />,
                        redirect: SETTINGS_URL + GLOBAL_LANGUAGE_URL,
                        subtitle: t('SupportedLanguage.change_console_pref_for_you'),
                    },
                ],
            },
        ];
    }, [slackParametersStore.slackParameters]);

    useEffect(() => {
        setMenuOptions(getMenuOptions());
    }, [userStore.userInfo?.id]);

    return (
        <Content className="Settings">
            {menuOptions?.map((item) => {
                return (
                    <div key={item.title}>
                        <div className="text-headline-bold mb-20">{item.sectionTitle}</div>
                        <WhiteCard padding={40}>
                            {item.options.map((option, i) => {
                                if (option?.customRender)
                                    return option.customRender(option.title + i);

                                return (
                                    <Link
                                        to={option?.redirect || ''}
                                        key={option.title + i}
                                        className="link menu-options"
                                    >
                                        {option.icon}
                                        <div className="text-container">
                                            <div className="text-body-bold">{option.title}</div>
                                            <div className="text-caption-2">{option.subtitle}</div>
                                        </div>
                                        <ChevronRightIcon />
                                    </Link>
                                );
                            })}
                        </WhiteCard>
                    </div>
                );
            })}
        </Content>
    );
});

export default Settings;
