import { PolicyBundleRestrictionsDto } from 'Api/Features/Policies/Dtos/PolicyBundleRestrictionsDto';
import Icon from 'Components/icons/Icon';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    MINIMUM_TIME_PERIOD_MINUTES,
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    TWENTY_FOUR_HOUR_MINUTE,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { isTimeRestricted } from 'Utils/CalendarUtils';
import { createTimeMomentInTimezoneWithoutConverting } from 'Utils/TimeUtils';
import './index.less';

export interface TimePickerDropdownProps {
    selected?: string;
    onChange: (value: string) => void;
    minimumAvailableTime?: string;
    maximumAvailableTime?: string;
    disabled?: boolean;
    midnightAvailable?: boolean;
    restrictions?: PolicyBundleRestrictionsDto;
    forSpecificDate?: string;
    endTimeMidnightRestrictionOverride?: boolean; //Allow 12 AM as a valid time selection when selecting for end time
}

const TimePickerDropdown: React.FunctionComponent<TimePickerDropdownProps> = observer(
    ({
        selected,
        onChange,
        minimumAvailableTime,
        maximumAvailableTime,
        disabled,
        midnightAvailable,
        restrictions,
        forSpecificDate,
        endTimeMidnightRestrictionOverride,
    }) => {
        const { t } = useTranslation();
        const { languageStore } = useStores();
        const options = useCallback((): SingleSelectCustomOption[] => {
            const time = minimumAvailableTime
                ? moment(forSpecificDate).startOf('day').add(minimumAvailableTime)
                : moment(forSpecificDate).startOf('day');
            const resultOptions: SingleSelectCustomOption[] = [];

            const maxTime = moment(forSpecificDate).startOf('day').add(maximumAvailableTime);

            while (time.isBefore(moment(forSpecificDate).startOf('day').add(1, 'day'))) {
                if (maximumAvailableTime && time.isAfter(maxTime)) {
                    //break out
                    time.endOf('day').add(1, 'second');
                    break;
                } else {
                    resultOptions.push({
                        label: time.format(
                            FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[languageStore.currentLanguage]
                        ),
                        value: time.format(TWENTY_FOUR_HOUR_MINUTE),
                        isDisabled:
                            endTimeMidnightRestrictionOverride &&
                            time.format(TWENTY_FOUR_HOUR_MINUTE) === '00:00'
                                ? false
                                : isTimeRestricted(
                                      createTimeMomentInTimezoneWithoutConverting(
                                          time.format()
                                      ).format(),
                                      languageStore.currentLanguage,
                                      restrictions
                                  ),
                    });
                    time.add(MINIMUM_TIME_PERIOD_MINUTES, 'minute');
                }
            }
            if (midnightAvailable) {
                resultOptions.push({
                    label:
                        languageStore.currentLanguage === SupportedLanguage.EN ? '12:00am' : '0:00',
                    value: '23:59',
                    isDisabled: isTimeRestricted(
                        moment(forSpecificDate).endOf('day').format(),
                        languageStore.currentLanguage,
                        restrictions
                    ),
                });
            }

            return resultOptions;
        }, [
            minimumAvailableTime,
            maximumAvailableTime,
            midnightAvailable,
            restrictions,
            forSpecificDate,
            languageStore.currentLanguage,
        ]);

        useEffect(() => {
            //changing date could have different restrictions than previous date. Clear if selected time is restricted
            if (forSpecificDate && restrictions && selected) {
                if (
                    isTimeRestricted(
                        moment(
                            moment(forSpecificDate).format(FORMAT_YEAR_MONTH_DAY) + 'T' + selected
                        ).format(),
                        languageStore.currentLanguage,
                        restrictions
                    )
                )
                    onChange('');
            }
        }, [forSpecificDate, restrictions]);

        return (
            <div className="TimePickerDropdown">
                <StaticSingleSelect
                    options={options()}
                    onChange={(value?: SingleSelectCustomOption) => {
                        onChange(value?.value ?? '');
                    }}
                    placeholder={t('SelectCustom.default_select_placeholder')}
                    selected={selected}
                    disabled={disabled}
                    hideSelectedOptions={false}
                    dropdownIndicatorIcon={
                        <Icon iconName="AlarmClockIcon" width={24} height={24} />
                    }
                />
            </div>
        );
    }
);

export default TimePickerDropdown;
