import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PeopleFill3Icon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M14.0073 13.646C15.6113 13.646 16.9663 12.2251 16.9663 10.3867C16.9663 8.59229 15.604 7.22266 14.0073 7.22266C12.418 7.22266 11.0557 8.61426 11.0557 10.4014C11.0557 12.2251 12.4106 13.646 14.0073 13.646ZM5.5332 13.8145C6.9248 13.8145 8.104 12.5693 8.104 10.9727C8.104 9.40527 6.91748 8.21875 5.5332 8.21875C4.15625 8.21875 2.95508 9.42725 2.9624 10.9873C2.9624 12.5693 4.1416 13.8145 5.5332 13.8145ZM22.4595 13.8145C23.8511 13.8145 25.0376 12.5693 25.0376 10.9873C25.0376 9.42725 23.8438 8.21875 22.4595 8.21875C21.0825 8.21875 19.8887 9.40527 19.8887 10.9727C19.8887 12.5693 21.0752 13.8145 22.4595 13.8145ZM1.49756 20.4575H6.9834C6.20703 19.3369 7.06396 17.125 8.71191 15.8359C7.90625 15.3086 6.85889 14.9277 5.5332 14.9277C2.25928 14.9277 0.142578 17.3374 0.142578 19.3296C0.142578 20.0474 0.508789 20.4575 1.49756 20.4575ZM26.5024 20.4575C27.4912 20.4575 27.8574 20.0474 27.8574 19.3296C27.8574 17.3374 25.7407 14.9277 22.4668 14.9277C21.1411 14.9277 20.0938 15.3086 19.2881 15.8359C20.936 17.125 21.793 19.3369 21.0093 20.4575H26.5024ZM9.43701 20.4575H18.5703C19.7788 20.4575 20.1963 20.0913 20.1963 19.4248C20.1963 17.5352 17.8013 14.9424 14.0073 14.9424C10.2061 14.9424 7.81836 17.5352 7.81836 19.4248C7.81836 20.0913 8.23584 20.4575 9.43701 20.4575Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PeopleFill3Icon;
