import { Tooltip } from 'antd';
import { UserBadgeDto } from 'Api/Features/Users/Dtos/UserBadgeDto';
import Icon from 'Components/icons/Icon';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from 'Utils/ArrayUtils';
import './index.less';

interface BadgesProps {
    types: UserBadgeDto[];
    tooltip?: boolean;
}

const Badges: React.FunctionComponent<BadgesProps> = ({ types, tooltip = false }) => {
    const { t } = useTranslation();

    const renderTooltipBadges = (): ReactNode => {
        const typesNb = types.length;
        const text =
            typesNb > 0 &&
            types.map((type, i) => {
                let separator = '';
                if (i + 1 < typesNb) separator = i + 1 > typesNb - 2 ? ' & ' : ', ';

                return t(`Coworker.badges_${type}`) + separator;
            });

        return (
            <Tooltip
                overlay={text}
                overlayClassName="badges-tooltip"
                placement="left"
                arrowPointAtCenter={true}
            >
                <div className="badges-tooltip-wrapper">
                    <div className="badges-tooltip-number text-caption-3-bold">{typesNb}</div>
                    <Icon iconName="Badge" />
                </div>
            </Tooltip>
        );
    };

    const renderBadgesList = (): ReactNode => (
        <div className="badges-list">
            {types.map((type, i) => (
                <div className="badges-list-item" key={generateKey(`badge-${type}-${i}`)}>
                    <Icon iconName="Badge" />
                    <span className="text-footnote-bold">{t(`Coworker.badges_${type}`)}</span>
                </div>
            ))}
        </div>
    );

    return <div className="Badges">{tooltip ? renderTooltipBadges() : renderBadgesList()}</div>;
};

export default Badges;
