import Skeleton from 'Components/skeleton';
import React, { memo } from 'react';
import ClubDetailsHeader, {
    ClubDetailsHeaderProps,
} from 'Routes/clubs/components/club-details-header';
import ClubDetailsInfos, {
    ClubDetailsInfosProps,
} from 'Routes/clubs/components/club-details-infos';
import { ClubDetailsHeaderShape, ClubDetailsInfosShape } from 'Routes/clubs/skeleton-shapes';
import './index.less';

export interface ClubDetailsProps {
    headerData?: ClubDetailsHeaderProps;
    infosData?: ClubDetailsInfosProps;
}

const ClubDetailsUi: React.FunctionComponent<ClubDetailsProps> = ({ headerData, infosData }) => {
    return (
        <div className="ClubDetailsUi">
            <Skeleton isLoaded={headerData !== undefined} placeholder={ClubDetailsHeaderShape}>
                {headerData !== undefined ? (
                    <ClubDetailsHeader
                        isJoined={headerData.isJoined}
                        joinOnClick={headerData.joinOnClick}
                        clubCategory={headerData.clubCategory}
                        menuOptions={headerData.menuOptions}
                    />
                ) : (
                    <></>
                )}
            </Skeleton>

            <div className="club-details-content">
                <Skeleton isLoaded={infosData !== undefined} placeholder={ClubDetailsInfosShape}>
                    {infosData !== undefined ? (
                        <ClubDetailsInfos
                            clubInfos={infosData.clubInfos}
                            clubMembers={infosData.clubMembers}
                        />
                    ) : (
                        <></>
                    )}
                </Skeleton>
            </div>
        </div>
    );
};

export default memo(ClubDetailsUi);
