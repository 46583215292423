import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import TitledWhitecardSection from 'Routes/profile/components/titled-whitecard-section';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import UserValueDisplay from '../user-value-display/user-value-display';
import { AppartementIcon, MainWorkplaceIcon, SimpleHouseIcon } from 'Components/icons';
import Divider from 'Components/divider';
import { theme } from 'Style/theme';
import './workplaces-tab.less';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';
import { displayAddress } from 'Utils/TextUtils';
import { grayWaveSvg } from 'Assets/StaticSvgs/gray-wave';

interface WorkplacesTabProps {
    data?: UserDetailsDto;
}

const WorkplacesTab: FunctionComponent<WorkplacesTabProps> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="WorkplacesTab">
            <TitledWhitecardSection title={t('preferred_workplace')}>
                <div className="pref">
                    {data?.preferredWorkType === UserPreferredWorkTypeDto.Office ? (
                        <AppartementIcon width={24} height={24} />
                    ) : (
                        <SimpleHouseIcon width={24} height={24} />
                    )}

                    <div className="text-body-bold value">
                        {t(
                            `UserPreferredWorkTypeDto.UserPreferredWorkTypeDto_${data?.preferredWorkType}`
                        )}
                    </div>

                    <div className="main-icon">
                        <MainWorkplaceIcon
                            width={24}
                            height={24}
                            fill={theme['accent-mid-contrast']}
                        />
                    </div>
                </div>
                <Divider marginTop={12} />
            </TitledWhitecardSection>

            {data?.defaultOffice ? (
                <TitledWhitecardSection title={t('default_office')}>
                    <UserValueDisplay label={t('name')} value={data?.defaultOffice?.name} />

                    <UserValueDisplay
                        label={t('building_address')}
                        value={displayAddress(data?.defaultOffice?.address)}
                    />

                    <UserValueDisplay
                        label={t('Office.space')}
                        value={data?.defaultOfficeSpace?.name}
                    />

                    <UserValueDisplay label={t('floor')} value={data?.defaultFloor} />

                    <UserValueDisplay label={t('Office.desk_number')} value={data?.defaultDesk} />
                </TitledWhitecardSection>
            ) : (
                <div>
                    <div className={'text-headline-bold mb-20'}>{t('default_office')}</div>
                    <div className="no-default-office-container">
                        <div className="circle-icon">
                            <AppartementIcon width={36} height={36} fill={theme.white} />
                        </div>
                        <span className="text-headline text-mid-contrast text">
                            {t('none_added')}
                        </span>
                        {grayWaveSvg}
                    </div>
                </div>
            )}
        </div>
    );
};

export default WorkplacesTab;
