import React, { FunctionComponent } from 'react';
import FlexyLogo from 'Assets/Images/logo-color.svg';
import OnboadringWave from 'Assets/Images/onboarding-wave.svg';
import './index.less'

interface LayoutErrorsPageProps {
    className?: string
}

const LayoutErrorsPage: FunctionComponent<LayoutErrorsPageProps> = ({ children, className }) => {
    return (
        <div className={`LayoutErrorsPage ${className}`}>
            <div className="header">
                <img src={FlexyLogo} alt="logo" />
            </div>

            <div className="content">
                <div className="content-header">
                    <div className="circle" />
                    <div className="circle" />
                    <div className="circle" />
                </div>
                {children}
            </div>

            <div className="footer">
                <img width={'100%'} src={OnboadringWave} />
            </div>
        </div>
    );
};

export default LayoutErrorsPage;
