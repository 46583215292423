import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Skeleton from 'Components/skeleton';
import Tag, { TagColor } from 'Components/tag/tag';
import VerticalThreeDotMenuButton, {
    ThreeDotMenuOption,
} from 'Components/vertical-three-dot-menu-button';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserStatusTagComponent } from 'Utils/TagUtils';
import './index.less';
import { userDetailsHeaderSkeletonShape } from './skeleton-shapes';

interface ActionsButtons {
    onEdit?: () => void;
    onResentConfirmationEmail?: () => void;
    onDeactivate?: () => void;
    onReactivate?: () => void;
    onRemoveFromTeam?: () => void;
}

export interface UserDetailsHeaderProps {
    imageUrl?: string;
    name?: string;
    status?: UserAccountStatusDto;
    subTitle?: string;
    actionButtons?: ActionsButtons;
    role: ManagementRoleDto;
    isLoaded?: boolean;
}

const UserDetailsHeader: React.FunctionComponent<UserDetailsHeaderProps> = ({
    imageUrl,
    name,
    status,
    subTitle,
    actionButtons,
    role,
    isLoaded,
}) => {
    const { t } = useTranslation();
    const [ctaOptions, setCtaOptions] = useState<ThreeDotMenuOption[]>([]);

    const userImageStyle = { backgroundImage: `url(${imageUrl ?? NoProfilePicture})` };

    const generateCtaOptions = useCallback(() => {
        const ctaOptions: ThreeDotMenuOption[] = [];

        if (status !== UserAccountStatusDto.Deactivated) {
            actionButtons?.onEdit &&
                ctaOptions.push({
                    title: t('edit'),
                    onClick: (): void => actionButtons.onEdit!(),
                });
            actionButtons?.onResentConfirmationEmail &&
                status === UserAccountStatusDto.Pending &&
                ctaOptions.push({
                    title: t('Users.user_resend_confirmation_email'),
                    onClick: (): void => actionButtons.onResentConfirmationEmail!(),
                });
            actionButtons?.onDeactivate &&
                ctaOptions.push({
                    title: t('deactivate'),
                    className: 'secondary',
                    onClick: (): void => actionButtons.onDeactivate!(),
                });
            actionButtons?.onRemoveFromTeam &&
                ctaOptions.push({
                    title: t('remove_from_team'),
                    className: 'secondary',
                    onClick: (): void => actionButtons.onRemoveFromTeam!(),
                });
        } else {
            actionButtons?.onEdit &&
                ctaOptions.push({
                    title: t('Users.user_reactivate'),
                    onClick: (): void => actionButtons.onReactivate!(),
                });
        }

        setCtaOptions(ctaOptions);
    }, [status]);

    useEffect(() => {
        if (status) generateCtaOptions();
    }, [status]);

    return (
        <div className="UserDetailsHeader content-header">
            <Skeleton placeholder={userDetailsHeaderSkeletonShape} isLoaded={isLoaded}>
                <div className="user-image" style={userImageStyle} />

                <div className="user-info">
                    <div className="user-name">
                        <span className="text-title-3">{name}</span>
                        <Tag
                            color={TagColor.blue}
                            text={t(`ManagementRoleDto.ManagementRoleDto_${role}`)}
                            iconName="PersonFillIcon"
                        />
                        {status &&
                            status !== UserAccountStatusDto.Active &&
                            getUserStatusTagComponent(t, status)}
                    </div>
                    {subTitle && <div className="user-subTitle text-caption-1">{subTitle}</div>}
                </div>

                <VerticalThreeDotMenuButton menuOptions={ctaOptions} />
            </Skeleton>
        </div>
    );
};

export default UserDetailsHeader;
