import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const FunnelIconFill: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            {...props}
        >
            <path d="M5 6L12 16V22.5L16 20.5V16L23 6H5Z" fill={fill} />
            <path
                d="M4.11302 5.53819C4.28522 5.20746 4.62713 5 5 5H23C23.3729 5 23.7148 5.20746 23.887 5.53819C24.0592 5.86892 24.0331 6.26799 23.8192 6.57346L17 16.3152V20.5C17 20.8788 16.786 21.225 16.4472 21.3944L12.4472 23.3944C12.1372 23.5494 11.7691 23.5329 11.4743 23.3507C11.1795 23.1684 11 22.8466 11 22.5V16.3152L4.18077 6.57346C3.96694 6.26799 3.94083 5.86892 4.11302 5.53819ZM6.92066 7L12.8192 15.4265C12.9369 15.5946 13 15.7948 13 16V20.882L15 19.882V16C15 15.7948 15.0631 15.5946 15.1808 15.4265L21.0793 7H6.92066Z"
                fill={fill}
            />
        </svg>
    );
};

export default FunnelIconFill;
