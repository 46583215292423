import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import Button from 'Components/button';
import Icon from 'Components/icons/Icon';
import RSVPInfo from 'Components/rsvp-info';
import Tag, { TagColor } from 'Components/tag/tag';
import { ButtonDropdownAttendingStyle } from 'Components/toggle/rsvp-toggle/components/button-dropdown-attending';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { EVENTS_URL, FORMAT_SHORT_MONTH_DATE_TIME } from 'Models/Constants';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import { getHappeningAttendanceTypeIcon, getHappeningDefaultImg } from 'Utils/HappeningUtils';
import { img, ImgSize } from 'Utils/ImgUtils';
import './index.less';

interface DashboardHappeningProps {
    className?: string;
    happening?: HappeningDto;
}

const DashboardHappening: React.FunctionComponent<DashboardHappeningProps> = observer(
    ({ className, happening }) => {
        const { languageStore } = useStores();
        const { t } = useTranslation();
        const hasHappening = happening !== undefined;

        const dashboardHappeningStyle = {
            backgroundImage: hasHappening
                ? `url(${
                      happening.imageUrl
                          ? img(happening.imageUrl, ImgSize.l, false)
                          : getHappeningDefaultImg(happening.attendanceType)
                  }`
                : undefined,
        };
        const tagText = moment(happening?.startTime).isSameOrBefore(new Date(), 'day')
            ? t('Dashboard.event_today', {
                  param1: happening?.club ? t('gathering') : t('happening'),
              })
            : t('Dashboard.event_next', {
                  param1: happening?.club ? t('gathering') : t('happening'),
              });

        return (
            <div
                className={`DashboardHappening ${className ?? ''}`}
                style={dashboardHappeningStyle}
            >
                {hasHappening ? (
                    <>
                        <Tag text={tagText} color={TagColor.purple} iconName="CalendarStarIcon" />

                        <div className="happening-info">
                            <div className="info">
                                <div className="text-caption-1 text-low-contrast d-flex-align">
                                    <div className="office">{happening.office?.name}</div>

                                    {happening.club?.category &&
                                        ClubCategoryIconMap.has(happening.club.category) && (
                                            <>
                                                <div className="spacer" />
                                                <div className="d-flex-align club">
                                                    {t('club')}:
                                                    <Icon
                                                        iconName={
                                                            ClubCategoryIconMap.get(
                                                                happening.club.category
                                                            )!.iconName
                                                        }
                                                        width={24}
                                                        fill={theme.white}
                                                    />
                                                    {happening.club.name}
                                                </div>
                                            </>
                                        )}
                                </div>

                                <div className="title">{happening.name}</div>

                                <div className="date">
                                    {moment(happening.startTime).format(
                                        FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage]
                                    )}
                                    <span className="dot" />
                                    {moment(happening.endTime).format(
                                        FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage]
                                    )}
                                    <div className="spacer" />
                                    {getHappeningAttendanceTypeIcon(happening.attendanceType)}
                                    <span className="attendence">
                                        {t(
                                            `HappeningAttendanceTypeDto.HappeningAttendanceTypeDto_${happening.attendanceType}`
                                        )}
                                    </span>
                                </div>
                            </div>

                            <RSVPInfo
                                happening={happening}
                                detail={false}
                                dropdownBtnStyle={ButtonDropdownAttendingStyle.dashboard}
                                addToCalendarButtonProps={{
                                    color: theme['accent-low-contrast'],
                                    width: 31,
                                }}
                            />

                            <Button
                                text={t('view')}
                                type="smallLight"
                                width="hugged"
                                height="small"
                                link={EVENTS_URL + '/' + happening.id}
                            />
                        </div>
                    </>
                ) : (
                    <div className="no-events">{t('Dashboard.happening_no_events')}</div>
                )}
            </div>
        );
    }
);

export default DashboardHappening;
