import { Tabs } from 'antd';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import DetailsPageNavigationTabs from 'Components/details-page-navigation-tabs';
import TablePageSecondHeader from 'Components/table-page-second-header';
import { useService, useStores } from 'Hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import './index.less';
import TabDetails from '../components/tab-details';
import TabTeams from '../components/tab-teams';
import { ThreeDotMenuOption } from 'Components/vertical-three-dot-menu-button';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';
import ConfirmationModal from 'Components/confirmation-modal/confirmation-modal';
import { POLICIES_URL, SETTINGS_URL } from 'Models/Constants';
import CreatePolicyModal from '../create-policy-modal';
import PolicyAddTeamsModal from './policy-add-teams-modal';
import Content from 'Components/Content';

const PolicyDetails: FunctionComponent = () => {
    const policyBundleService = useService(PolicyBundleService);
    const { toastStore, globalLoadingStore, policyStore } = useStores();
    const [deletePolicyBundleModalVisible, setDeletePolicyBundleModalVisible] = useState(false);
    const [policyBundleDetails, setPolicyBundleDetails] = useState<PolicyBundleDto>();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const urlParams = new URLSearchParams(window.location.search);
    const { t } = useTranslation();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [addTeamModalVisible, setAddTeamModalVisible] = useState(false);
    const [teamInteractionCausesFetch, setTeamInteractionCausesFetch] = useState(false);
    const [isTabDetailsLoading, setIsTabDetailsLoading] = useState(true);
    const [isLoading, setIsLoading] = useState({
        policy: false,
    });

    useEffect(() => {
        //Create team modal has an option to create team and after creation, add coworkers right away
        const addPeople = urlParams.get('addPeople');
        if (addPeople) setAddTeamModalVisible(true);
    }, []);

    const deletePolicyBundleResponse = async (reponse: boolean) => {
        if (reponse) {
            globalLoadingStore.addLoading();

            try {
                await policyBundleService.deletePolicyBundle(id);
                if (id === policyStore.policyInfo?.policyBundleId)
                    await policyStore.setPolicyRestrictionsAndObjectivesStats();

                history.push(SETTINGS_URL + POLICIES_URL);
                toastStore.toast({
                    type: 'success',
                    messageKey: t('Toast.entity_action_success_message', {
                        param1: t('policy_bundle'),
                        param2: t('Entity.lowercase_action_deleted'),
                    }),
                });
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
        setDeletePolicyBundleModalVisible(false);
    };

    const fetchPolicy = useCallback(async () => {
        try {
            const response: PolicyBundleDto | null = await policyBundleService.getPolicyBundle(id);

            if (response) {
                setPolicyBundleDetails(response);
                setIsTabDetailsLoading(false);
                setIsLoading((prev) => {
                    return { ...prev, policy: true };
                });
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        }
    }, [PolicyBundleService]);

    useEffect(() => {
        fetchPolicy();
    }, [fetchPolicy]);

    const menuOptions: ThreeDotMenuOption[] = [
        {
            title: t('edit'),
            onClick: () => setEditModalVisible(true),
        },
        {
            title: t('add_entity', {
                param1: t('teams'),
                param2: 'une',
                param3: t('Entity.lowercase_team'),
            }),
            onClick: () => setAddTeamModalVisible(true),
        },
        {
            title: t('delete'),
            onClick: () => setDeletePolicyBundleModalVisible(true),
            className: 'secondary',
        },
    ];

    return (
        <div className="PolicyDetails">
            <TablePageSecondHeader
                title={policyBundleDetails?.name || ''}
                menuOptions={menuOptions}
                isLoaded={isLoading.policy}
            />
            <DetailsPageNavigationTabs defaultActiveKey="1">
                <Tabs.TabPane tab={t('details')} key="1">
                    <Content>
                        <TabDetails data={policyBundleDetails} isLoading={isTabDetailsLoading} />
                    </Content>
                </Tabs.TabPane>

                <Tabs.TabPane tab={t('teams')} key="2">
                    <Content>
                        <TabTeams
                            id={id}
                            interactionCausesFetch={teamInteractionCausesFetch}
                            onFetch={() => setTeamInteractionCausesFetch(false)}
                        />
                    </Content>
                </Tabs.TabPane>
            </DetailsPageNavigationTabs>

            {deletePolicyBundleModalVisible && (
                <ConfirmationModal
                    icon={<Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />}
                    id="confirmation-delete-modal"
                    title={t('Policy.delete_policy_bundle_title')}
                    message={t('Policy.delete_policy_bundle_message')}
                    positive={{
                        text: t('confirm'),
                        action: () => deletePolicyBundleResponse(true),
                    }}
                    negative={{
                        text: t('cancel'),
                        action: () => deletePolicyBundleResponse(false),
                    }}
                />
            )}

            {editModalVisible && (
                <CreatePolicyModal
                    visible={editModalVisible}
                    policy={policyBundleDetails}
                    onComplete={(success: boolean) => {
                        setEditModalVisible(false);
                        if (success) fetchPolicy();
                    }}
                />
            )}

            {addTeamModalVisible && policyBundleDetails && (
                <PolicyAddTeamsModal
                    visible={addTeamModalVisible}
                    onComplete={(success?: boolean) => {
                        setAddTeamModalVisible(false);
                        if (success) setTeamInteractionCausesFetch(true);
                    }}
                    policy={policyBundleDetails}
                />
            )}
        </div>
    );
};

export default PolicyDetails;
