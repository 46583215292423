import React from 'react';
import './index.less';

export const dashboardTodayShape = (
    <div className="dashboardTodayShape DashboardToday">
        <div className="wrapper-1 profile">
            <div className="rect-2" />
            <div className="rect-3" />
        </div>
        <div className="info-container">
            <div className="rect-4" />
            <div className="wrapper-2 content">
                <div className="buttonRect-5" />
                <div className="buttonRect-5" />
            </div>
            <div className="rect-4" />
            <div className="wrapper-2 content">
                <div className="buttonRect-5" />
                <div className="buttonRect-5" />
            </div>
        </div>
    </div>
);

export const dashboardHappeningShape = (
    <div className="dashboardHappeningShape DashboardHappening">
        <div className="rect-1 Tag" />
        <div className="rect-2 happening-info" />
    </div>
);

export const dashboardAsyncSingleSelectShape = (
    <div className="dashboardAsyncSingleSelectShape SingleSelect">
        <div className="rect-1" />
    </div>
);

export const dashboardNextDaysShape = (
    <div className="dashboardNextDaysShape OfficeOccupancyCard">
        <div className="card">
            <div className="content top">
                <div className="mb-10">
                    <div className="rect-2 day" />
                    <div className="rect-3 month" />
                </div>

                <div className="roundRect-4" />
                <div className="roundRect-5" />
                <div className="roundRect-6" />
            </div>

            <div className="content">
                <div className="OfficeAndTeam">
                    <div className="office-name">
                        <div className="roundRect-7" />
                        <div className="rect-8" />
                    </div>
                    <div className="label-value-row border-bottom">
                        <div className="rect-9" />
                        <div className="rect-10" />
                    </div>
                    <div className="label-value-row">
                        <div className="rect-11" />
                        <div className="rect-10" />
                    </div>
                    <div className="roundRect-12" />
                    <div className="coworkers-images">
                        <div className="rect-13" />
                        <div />
                        <div className="rect-14" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export const dashboardNextDayContentTop = (
    <div className="dashboardNextDaysShape OfficeOccupancyCard content-top">
        <div className="card">
            <div className="top">
                <div className="roundRect-4" />
                <div className="roundRect-5" />
                <div className="roundRect-6" />
            </div>
        </div>
    </div>
);
