import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { FunctionComponent, ReactNode } from 'react';
import { theme } from 'Style/theme';
import './index.less';

interface TooltipWrapperProps {
    title: ReactNode;
    placement: TooltipPlacement;
    borderRadius?: number;
    backgroundColor?: string;
    textColor?: string;
    /**Normal 4 value padding. [Top, right, bottom, left] */
    padding?: number[];
    width?: number;
    mouseEnterDelay?: number;
}

const TooltipWrapper: FunctionComponent<TooltipWrapperProps> = ({
    title,
    placement,
    borderRadius = 100,
    backgroundColor = theme['layout-low-contrast'],
    textColor = theme['text-mid-contrast'],
    padding = [2, 10, 2, 10],
    width,
    mouseEnterDelay,
    children,
}) => {
    return (
        <Tooltip
            overlayClassName={`TooltipWrapper ${placement}`}
            title={title}
            placement={placement}
            overlayInnerStyle={{
                borderRadius: borderRadius,
                color: textColor,
                backgroundColor: backgroundColor,
                padding: `${padding.join('px ')}px`,
                width: width,
                marginLeft: placement === 'left' ? `-${width}px` : '',
            }}
            mouseEnterDelay={mouseEnterDelay}
        >
            {children}
        </Tooltip>
    );
};
export default TooltipWrapper;
