import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LaptopIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M1.20775 21.1991H16.2293C16.4602 20.2798 17.0289 19.4092 17.7723 18.7151H5.07632V7.83194C5.07632 7.21336 5.36882 6.9234 5.96326 6.9234H21.8716C22.4566 6.9234 22.7585 7.21336 22.7585 7.83194V10.0677C23.4481 10.0806 24.0882 10.2896 24.6362 10.6432V7.31968C24.6362 5.80222 23.8247 5 22.3528 5H5.48205C4.06672 5 3.19865 5.80222 3.19865 7.31968V18.7151H1.20775C0.547261 18.7151 0 19.2757 0 19.9619C0 20.6385 0.547261 21.1991 1.20775 21.1991Z"
                    fill={fill}
                />
                <path
                    d="M27.5904 20.7028C27.1534 19.9386 26.3805 19.1999 25.3374 18.7151C25.1156 18.612 24.8817 18.5205 24.6362 18.4425C24.2814 18.3299 23.9024 18.2459 23.5011 18.197C23.2614 18.1678 23.0137 18.1511 22.7585 18.1484C22.734 18.1481 22.7093 18.148 22.6847 18.148C21.6783 18.148 20.7866 18.3656 20.0347 18.7151C18.7665 19.3046 17.896 20.2695 17.544 21.1991C17.4289 21.5029 17.3693 21.803 17.3693 22.085C17.3693 22.6778 17.7278 23 18.7658 23H26.6035C27.6415 23 28 22.6778 28 22.085C28 21.6499 27.8584 21.1715 27.5904 20.7028Z"
                    fill={fill}
                />
                <path
                    d="M24.6362 12.3503C25.0022 12.8296 25.226 13.4497 25.226 14.1401C25.226 14.8487 25.0026 15.4868 24.6362 15.9802C24.3137 16.4145 23.8804 16.7366 23.3912 16.8959C23.1886 16.9619 22.9764 17 22.7585 17.0065C22.7361 17.0072 22.7135 17.0075 22.6909 17.0075C21.3133 17.0075 20.1496 15.7575 20.1496 14.153C20.1433 12.5808 21.3196 11.3501 22.6909 11.3501C22.7135 11.3501 22.736 11.3504 22.7585 11.3511C23.5003 11.3728 24.1791 11.7517 24.6362 12.3503Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LaptopIcon;
