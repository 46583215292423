import Icon from 'Components/icons/Icon';
import React from 'react';
import './index.less';

interface StarNumberProps {
    number: number;
}

const StarNumber: React.FunctionComponent<StarNumberProps> = ({ number }) => {
    return (
        <div className="StarNumber">
            <Icon iconName="Star" />
            {number}
        </div>
    );
};

export default StarNumber;
