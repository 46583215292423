import React from 'react';
import './index.less';

interface ContentProps {
    className?: string;
    children: any | any[];
}

const Content: React.FunctionComponent<ContentProps> = ({ className, children }) => {
    return <div className={`Content ${className}`}>{children}</div>;
};

export default Content;
