import OnboardingMan from 'Assets/Images/onboarding-man.svg';
import OnboadringWave from 'Assets/Images/onboarding-wave.svg';
import Onboardingwoman from 'Assets/Images/onboarding-woman.svg';
import Button from 'Components/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './welcome-page.less';

interface WelcomePageProps {
    onSkipClick: () => void;
    onStartClick: () => void;
}

const OnboardingWelcomePage: React.FunctionComponent<WelcomePageProps> = ({
    onSkipClick,
    onStartClick,
}) => {
    const { t } = useTranslation();
    return (
        <div className="OnboardingWelcomePage">
            <div className="content">
                <div className="left-container">
                    <div className="text-x-large-title-bold text-1">
                        {t('Onboarding.welcome_to_flexy')}
                    </div>

                    <div className="text-title-1-bold text-2">{t('Onboarding.quickly_set_up')}</div>

                    <div className="text-callout text-3">
                        {t('Onboarding.run_through_features')}
                    </div>

                    <div className="buttons">
                        <Button
                            text={t('Onboarding.skip_onboarding')}
                            type="link"
                            width="hugged"
                            onClick={onSkipClick}
                        />

                        <Button
                            text={t('Onboarding.lets_get_started')}
                            type="primary"
                            width="hugged"
                            rightIcon="ArrowRightIcon"
                            onClick={onStartClick}
                        />
                    </div>
                </div>

                <div className="right-container">
                    <div className="man">
                        <img src={OnboardingMan} />
                    </div>
                    <div className="woman">
                        <img src={Onboardingwoman} />
                    </div>
                </div>
            </div>

            <div className="footer">
                <img src={OnboadringWave} />
            </div>
        </div>
    );
};

export default OnboardingWelcomePage;
