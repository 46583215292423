import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment, { Moment } from 'moment';

const get3MonthEndDate = (endOfMonth: Moment): string => {
    const endDay = endOfMonth.day();

    if (endDay === 6) return endOfMonth.format(FORMAT_YEAR_MONTH_DAY);
    else return endOfMonth.add(6 - endDay, 'd').format(FORMAT_YEAR_MONTH_DAY);
};

export enum TimeRangeKey {
    Last4Weeks = 'Last4Weeks',
    Last3Months = 'Last3Months',
    Last6Months = 'Last6Months',
    CurrentYear = 'CurrentYear',
}

export const TimeRangeFilterMap = new Map<TimeRangeKey, { start: string; end: string }>([
    [
        TimeRangeKey.Last4Weeks,
        //Filtre qui inclut les 4 dernières semaines avant celle actuelle
        {
            start: moment().add(-4, 'week').startOf('week').format(FORMAT_YEAR_MONTH_DAY),
            end: moment().add(-1, 'week').endOf('week').format(FORMAT_YEAR_MONTH_DAY),
        },
    ],
    [
        TimeRangeKey.Last3Months,
        //Filtre qui inclut les 3 derniers mois avant celui en cours + les jours du mois suivant
        //si le mois ne se fini pas un samedi. Aujourd’hui: 15 juin 2022 Filtre: Février 2022 à Mai 2022 + 1, 2, 3 et 4 Juin
        {
            start: moment().add(-3, 'month').startOf('month').format(FORMAT_YEAR_MONTH_DAY),
            end: get3MonthEndDate(moment().add(-1, 'month').endOf('month')),
        },
    ],
    [
        TimeRangeKey.Last6Months,
        //Filtre qui inclut les 6 derniers mois avant celui en cours. Aujourd’hui: 15 aout 2022 Filtre: Janvier 2022 à Juillet 2022
        {
            start: moment().add(-6, 'month').startOf('month').format(FORMAT_YEAR_MONTH_DAY),
            end: moment().add(-1, 'month').endOf('month').format(FORMAT_YEAR_MONTH_DAY),
        },
    ],
    [
        TimeRangeKey.CurrentYear,
        //Filtre qui inclut tout les jours de l’année. 1 janvier 2022 à aujourd’hui
        {
            start: moment().startOf('year').format(FORMAT_YEAR_MONTH_DAY),
            end: moment().format(FORMAT_YEAR_MONTH_DAY),
        },
    ],
]);

export const PreviousPeriodTimeRangeFilterMap = new Map<
    TimeRangeKey,
    { start: string; end: string }
>([
    [
        TimeRangeKey.Last4Weeks,
        {
            start: moment().add(-8, 'week').startOf('week').format(FORMAT_YEAR_MONTH_DAY),
            end: moment().add(-5, 'week').endOf('week').format(FORMAT_YEAR_MONTH_DAY),
        },
    ],
    [
        TimeRangeKey.Last3Months,
        {
            start: moment().add(-6, 'month').startOf('month').format(FORMAT_YEAR_MONTH_DAY),
            end: moment().add(-4, 'month').endOf('month').format(FORMAT_YEAR_MONTH_DAY),
        },
    ],
    [
        TimeRangeKey.Last6Months,
        {
            start: moment().add(-12, 'month').startOf('month').format(FORMAT_YEAR_MONTH_DAY),
            end: moment().add(-7, 'month').endOf('month').format(FORMAT_YEAR_MONTH_DAY),
        },
    ],
    [
        TimeRangeKey.CurrentYear,
        {
            start: moment().add(-1, 'year').startOf('year').format(FORMAT_YEAR_MONTH_DAY),
            end: moment().add(-1, 'year').endOf('year').format(FORMAT_YEAR_MONTH_DAY),
        },
    ],
]);
