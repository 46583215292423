import React from 'react';
import App from './App/App';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { HttpClient } from 'HttpClient/HttpClient';
import { Container } from 'aurelia-dependency-injection';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import 'mobx-react-lite/batchingForReactDom';
import { Environment } from 'Misc/Environment';
import 'appcom-timezones/locales/fr';  // Do not remove or locale will not be bundled.

declare global {
    interface Window {
        Environment: Environment;
    }
}

// Setup i18n
import 'i18n';

// Setup DI
const rootContainer = new Container();
rootContainer.makeGlobal();

// Register the object containing environment variables.
if (!window.Environment) {
    throw new Error('Environment object not found.');
}
rootContainer.registerInstance(Environment, window.Environment);

// Determine the current tenant.
// If the tenant is not directly configured in the settings, use the subdomain from the URL (tenant-a.flexy.ai => "tenant-a")
if (!window.Environment.REACT_APP_TENANT_ID) {
    const parts = window.location.hostname.split('.');
    const subDomains = parts.slice(0, parts.length - 2);

    if (subDomains && subDomains.length > 0) {
        window.Environment.REACT_APP_TENANT_ID = subDomains[0];
    }
}

// Register HttpClient under both HttpClient and ApiHttpClient.
rootContainer.registerSingleton(HttpClient, HttpClient);
const httpClient = rootContainer.get(HttpClient);
rootContainer.registerInstance(ApiHttpClient, httpClient);

// Go!
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
