import { useEffect } from 'react';
import { useStores } from './use-stores';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';

interface HistoryTrigger {
    /**Url regex to check if previous url is matching current*/
    urlRegex: RegExp;
    /**The request type sent in the request history store */
    requestType: RequestType;
}

interface UseRequestHistoryProps {
    /** Parameters to determine when to trigger using the history function callback*/
    useHistoryTrigger: HistoryTrigger;
    /** Callback to use request information from request history. This is where your component sets filter store info or pagination etc.. */
    useHistoryCallback: (request: HistoryRequest) => void;
}

/**Handle logic when request history is needed. The typical case is being in a page that has filters then
 * clicking on an element that navigates us elsewhere, then when we comes back to page keep the filters as they where */
export const useApiRequestHistory = (
    props: UseRequestHistoryProps
): ((request: HistoryRequest) => any) => {
    const { requestHistoryStore } = useStores();

    useEffect(() => {
        if (
            props.useHistoryTrigger.urlRegex.test(requestHistoryStore.previousUrl) &&
            requestHistoryStore?.apiRequestHistory?.request &&
            requestHistoryStore?.apiRequestHistory?.requestType ===
                props.useHistoryTrigger.requestType
        ) {
            props.useHistoryCallback(requestHistoryStore.apiRequestHistory);
        } else {
            requestHistoryStore.setApiRequestHistory({
                request: undefined,
                requestType: undefined,
            });
        }
    }, [requestHistoryStore.previousUrl]);

    const setRequestHistory = (request: HistoryRequest): any => {
        return requestHistoryStore.setApiRequestHistory(request);
    };

    return setRequestHistory;
};
