import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { countries } from 'appcom-globalization';

export const stripHtml = (text: string | null): string | null => {
    if (text) {
        return text.trim().replace(/<[^>]*>?/gm, '');
    }
    return text;
};

export const htmlContainsText = (text: string | null): boolean => {
    if (text) {
        return stripHtml(text) !== '';
    }
    return false;
};

export function truncate(str: string, n: number): string {
    return str.length > n ? str.substr(0, n - 1) + '…' : str;
}

export const displayAddress = (address?: AddressDto | null): string => {
    if (address) {
        const addressArray: string[] = [];
        if (address.addressLine1) addressArray.push(address.addressLine1);
        if (address.addressLine2) addressArray.push(address.addressLine2);
        if (address.state) addressArray.push(address.state);
        if (address.countryCode) addressArray.push(address.countryCode);
        if (address.zipCode) addressArray.push(address.zipCode);

        return addressArray.join(', ');
    } else {
        return '';
    }
};

export const getCountryNameFromCountryCode = (countryCode?: string | null): string | undefined =>
    countries.find((country) => country.twoLetterCode === countryCode)?.englishName;

export const getCountryCodeFromCountryName = (CountryName?: string | null): string | undefined =>
    countries.find((country) => country.englishName === CountryName)?.twoLetterCode;

export const mergeStrings = (
    strings: (string | undefined | null)[],
    separator = ' '
): string | undefined => {
    const mergedString = strings.filter(
        (string) => string !== undefined && string !== null && string !== ''
    );

    return mergedString.length > 0 ? mergedString.join(separator) : undefined;
};

export const displayAorAN = (firstStringToModify: string, secondString: string): string => {
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    const firstCharIsVowels = vowels.includes(secondString.charAt(0).toLowerCase());

    if (!firstCharIsVowels) return firstStringToModify.slice(0, -1);
    return firstStringToModify;
};
