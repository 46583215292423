import { observable, action } from 'mobx';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';

interface FilterStoreArgs {
    advancedFilters: AdvancedFilter[];
}

class FilterStore {
    constructor({ advancedFilters }: FilterStoreArgs) {
        this.advancedFilters = advancedFilters;
    }

    @observable advancedFilters?: AdvancedFilter[];
    @observable searchTerm = '';
    @observable teamIds: string[] = [];
    @observable officeIds: string[] = [];

    @action
    updateSearchTerm(term: string): void {
        this.searchTerm = term;
    }

    @action
    updateTeamIds(teamIds: string[]): void {
        this.teamIds = teamIds;
    }

    @action
    updateOfficeIds(officeIds: string[]): void {
        this.officeIds = officeIds;
    }

    @action
    toggleAdvancedFilter(key: string, parentKey: string): void {
        const filters = this.advancedFilters?.slice();
        const parent = filters?.find((filter) => filter.key === parentKey);
        const filterItem = parent?.items.find((item) => item.key === key);
        if (filterItem) {
            filterItem.checked = !filterItem.checked;
        }
        this.advancedFilters = filters;
    }

    @action
    tickMultipleAdvancedFilter(
        keysList: {
            key: string[];
            parentKey: string;
        }[]
    ): void {
        const filters = this.advancedFilters?.slice();

        keysList.forEach((list) => {
            const parent = filters?.find((filter) => filter.key === list.parentKey);

            if (list.key.length > 0) {
                parent?.items.forEach((item) => {
                    item.checked = list.key.includes(item.key);
                });
            }
        });

        this.advancedFilters = filters;
    }

    @action
    clear(): void {
        this.searchTerm = '';
    }
}

export default FilterStore;
