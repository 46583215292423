import { observable, action, computed } from 'mobx';
import jwtDecode, { JwtPayload } from 'jwt-decode';

class AuthenticationStore {
    @observable accessToken?: string | null | undefined = sessionStorage.accessToken;
    @observable refreshToken?: string | null = sessionStorage.refreshToken;
    @observable expiresIn?: number = parseInt(sessionStorage.expiresIn, 10);
    @observable userId?: string | null = sessionStorage.userId;
    @observable azureADStateId?: string | null = sessionStorage.azureADStateId;
    @observable returnUrl?: string | null = sessionStorage.returnUrl;

    @computed
    get loggedIn(): boolean {
        return !!this.accessToken;
    }

    @action
    setSession(jwtToken: string, refreshToken: string): void {
        const decoded = jwtDecode<JwtPayload>(jwtToken);
        this.accessToken = sessionStorage.accessToken = jwtToken;
        this.userId = sessionStorage.userId = decoded.sub;
        this.expiresIn = sessionStorage.expiresIn = decoded.exp;
        this.refreshToken = sessionStorage.refreshToken = refreshToken;
    }

    @action
    clearSession(): void {
        this.accessToken = '';
        this.expiresIn = 0;
        this.userId = '';
        sessionStorage.clear();
    }

    @action setAzureADStateId(stateId: string): void {
        this.azureADStateId = sessionStorage.azureADStateId = stateId;
    }

    @action clearAzureADStateId(): void {
        this.azureADStateId = null;
        sessionStorage.removeItem('azureADStateId');
    }

    @action setReturnUrl(returnUrl: string): void {
        this.returnUrl = sessionStorage.returnUrl = returnUrl;
    }

    @action clearReturnUrl(): void {
        this.returnUrl = null;
        sessionStorage.removeItem('returnUrl');
    }
}

export default AuthenticationStore;
