import React, { Children, cloneElement, FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import ForbiddenPage from 'Routes/403-forbidden';
import NotFoundPage from 'Routes/404-not-found';

const ValidatedRoute: FunctionComponent<RouteProps> = observer(({ children, ...routeProps }) => {
    const { authorizationStore } = useStores();

    const updateChildrenWithProps = Children.map(children, (child: any) => {
        return cloneElement(child, {
            routeProps,
        });
    });
    
    return (
        <Route {...routeProps}>
            {authorizationStore.currentRequestForbidden ? (
                <ForbiddenPage />
            ) : authorizationStore.currentRequestNotFound ? (
                <NotFoundPage />
            ) : (
                updateChildrenWithProps
            )}
        </Route>
    );
});

export default ValidatedRoute;
