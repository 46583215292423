import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Star: FunctionComponent<SvgProps> = ({ fill, ...props }) => {
    return (
        <span role="img" className="anticon">
            <svg width="28" height="28" viewBox="1 0 28 28" fill="none" {...props}>
                <path
                    d="M15.0001 4.14282L17.834 10.7294L25.1899 11.2994L19.5855 15.9402L21.2978 22.8791L15.0001 19.1607L8.70235 22.8791L10.4146 15.9402L4.81016 11.2994L12.1661 10.7294L15.0001 4.14282Z"
                    fillRule="evenodd"
                    fill={fill ?? '#F2C552'}
                />
            </svg>
        </span>
    );
};

export default Star;
