import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { CloseIcon } from 'Components/icons';
import WhiteCard from 'Components/white-card';
import React, { useEffect, useRef } from 'react';
import './space-area-card.less';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';

interface SpaceAreaCardProps {
    officeSpace: OfficeSpaceDto;
    selected: boolean;
    onClick: (spaceId: string) => void;
    onDelete: (spaceId: string) => void;
}

const SpaceAreaCard: React.FC<SpaceAreaCardProps> = ({
    officeSpace,
    selected,
    onClick,
    onDelete,
}) => {
    const { t } = useTranslation();
    const cardRef = useRef<any>(null);

    useEffect(() => {
        if (selected && cardRef.current) {
            cardRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [selected]);

    return (
        <div
            className={`SpaceAreaCard ${selected && 'selected'}`}
            onClick={() => onClick(officeSpace.id ?? '')}
            ref={cardRef}
        >
            <WhiteCard padding={10}>
                <div>
                    <div className="text-body">{officeSpace.name}</div>
                    <div className="text-caption-2">
                        {t('capacity')}: {officeSpace.capacity}
                    </div>
                </div>
                <div className="actions">
                    <CloseIcon
                        fill={theme['text-mid-contrast']}
                        onClick={() => onDelete(officeSpace.id ?? '')}
                    />
                </div>
            </WhiteCard>
        </div>
    );
};

export default SpaceAreaCard;
