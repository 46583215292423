import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AppartementIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <mask
                    id="mask0_2853_21893"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                >
                    <rect width="40" height="40" fill={fill != 'currentColor' ? fill : '#D9D9D9'} />
                </mask>
                <g mask="url(#mask0_2853_21893)">
                    <path
                        d="M7.792 35C7.014 35 6.354 34.7293 5.812 34.188C5.27067 33.646 5 32.986 5 32.208V14.583C5 13.8057 5.27067 13.146 5.812 12.604C6.354 12.0627 7.014 11.792 7.792 11.792H11.792V7.792C11.792 7.014 12.0627 6.354 12.604 5.812C13.146 5.27067 13.8057 5 14.583 5H25.417C26.1943 5 26.854 5.27067 27.396 5.812C27.9373 6.354 28.208 7.014 28.208 7.792V18.625H32.208C32.986 18.625 33.646 18.896 34.188 19.438C34.7293 19.9793 35 20.625 35 21.375V32.208C35 32.986 34.7293 33.646 34.188 34.188C33.646 34.7293 32.986 35 32.208 35H22.042V28.208H17.958V35H7.792ZM7.792 32.208H11.792V28.208H7.792V32.208ZM7.792 25.417H11.792V21.375H7.792V25.417ZM7.792 18.625H11.792V14.583H7.792V18.625ZM14.583 25.417H18.625V21.375H14.583V25.417ZM14.583 18.625H18.625V14.583H14.583V18.625ZM14.583 11.792H18.625V7.792H14.583V11.792ZM21.375 25.417H25.417V21.375H21.375V25.417ZM21.375 18.625H25.417V14.583H21.375V18.625ZM21.375 11.792H25.417V7.792H21.375V11.792ZM28.208 32.208H32.208V28.208H28.208V32.208ZM28.208 25.417H32.208V21.375H28.208V25.417Z"
                        fill={fill != 'currentColor' ? fill : '#6B40ED'}
                    />
                </g>
            </svg>
        </span>
    );
};

export default AppartementIcon;
