import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PencilIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M21.3708 8.73324L22.5453 7.56434C23.1272 6.97989 23.1487 6.34132 22.6207 5.80016L22.2005 5.36723C21.6725 4.83689 21.0152 4.89101 20.4441 5.46464L19.2696 6.62272L21.3708 8.73324ZM8.17087 21.97L20.3363 9.75062L18.2459 7.66175L6.08044 19.8595L5.02444 22.4138C4.90591 22.7385 5.23995 23.0956 5.56322 22.9766L8.17087 21.97Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PencilIcon;
