import { CustomDatePicker } from 'Components/date-picker';
import TimePickerDropdown from 'Components/time-picker';
import { ValidatedFormItem } from 'Components/validated-form-item';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';
import './date-and-time-control.less';

interface DateAndtimeControlProps {
    onDateChange: (value: moment.Moment) => void;
    onTimeChange: (value?: string) => void;
    timeValue?: string; //TWENTY_FOUR_HOUR_MINUTE
    fieldLabel: string;
    dateFieldName: string;
    timeFieldName: string;
    errors: Map<string, string[]>;
    required?: boolean;
    maxDate?: Date;
    minDate?: Date;
    minimumAvailableTime?: string;
    maximumAvailableTime?: string;
}

const DateAndtimeControl: React.FunctionComponent<DateAndtimeControlProps> = ({
    onDateChange,
    onTimeChange,
    timeValue,
    fieldLabel,
    dateFieldName,
    timeFieldName,
    errors,
    required,
    maxDate,
    minDate,
    minimumAvailableTime,
    maximumAvailableTime,
}) => {
    useEffect(() => {
        if (minimumAvailableTime && timeValue && minimumAvailableTime > timeValue)
            onTimeChange(undefined);
    }, [minimumAvailableTime]);

    return (
        <div className="DateAndtimeControl">
            <div className="control">
                <ValidatedFormItem
                    label={fieldLabel}
                    name={dateFieldName}
                    required={required}
                    errors={errors}
                >
                    {/* Tried being able to pass default value to component but does not work when in validated form item with field name.. 
                     resort to using form.setFieldValue if you want a starting default value for component */}
                    <CustomDatePicker
                        onChange={(value: Date) => {
                            onDateChange(moment(value));
                        }}
                        maxDate={maxDate}
                        minDate={minDate}
                        value={new Date()}
                        error={errors.has(dateFieldName)}
                    />
                </ValidatedFormItem>

                <ValidatedFormItem label="" errors={errors} name={timeFieldName}>
                    <TimePickerDropdown
                        onChange={(value: string) => onTimeChange(value)}
                        selected={timeValue}
                        minimumAvailableTime={minimumAvailableTime}
                        maximumAvailableTime={maximumAvailableTime}
                    />
                </ValidatedFormItem>
            </div>
        </div>
    );
};

export default DateAndtimeControl;
