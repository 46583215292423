import React from 'react';
import './index.less';

export const WeeklyScheduleCalendarShowAwayPeriodObjectivesSkeletonShapes = (
    <div className="WeeklyScheduleCalendarShowAwayPeriodObjectivesSkeletonShapes">
        <div className="rect" />
    </div>
);

export const WeeklyScheduleCalendarButtonCreateSkeletonShapes = (
    <div className="WeeklyScheduleCalendarButtonCreateSkeletonShapes">
        <div className="rect" />
    </div>
);

export const TimePeriodModalDayReplicatorSkeletonShapes = (
    <div className="TimePeriodModalDayReplicatorSkeletonShapes">
        <div className="rect" />
        <div className="rect" />
        <div className="rect" />
        <div className="rect" />
        <div className="rect" />
        <div className="rect" />
        <div className="rect" />
    </div>
);
