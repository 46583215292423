import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import { timeZoneUtil } from 'appcom-timezones';
import { MOMENT_DEFAULT, FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import { SupportedLanguage } from 'Stores/LanguageStore';

/**
 *
 * @param date string YEAR_MONTH_DAY
 * @param time string TWENTY_FOUR_HOUR_MINUTE format
 * @returns moment object in utc time without converting from browswer offset
 */
export const createUtcWithoutChangingTime = (date: string, time: string): moment.Moment => {
    return moment.utc(
        moment.utc(moment(date).format(FORMAT_YEAR_MONTH_DAY) + 'T' + time + ':00.000+00:00')
    );
};

/**
 *
 * @param date utc format "2022-04-10T05:00:00.000+00:00"
 * @returns moment object with offset without converting from offset
 */
export const createMomentFromUtcWithoutChangingTime = (
    momentString: string,
    timezone?: string
): moment.Moment => {
    const splitDateTime = momentString.split('T');
    const time = splitDateTime[1].split('.');
    return moment.tz(splitDateTime[0] + 'T' + time[0], timezone ?? '');
};

export const createTimezonedMomentFromDateAndTime = (
    date: string,
    time: string,
    timezone: string
): moment.Moment => {
    return moment.tz(`${date} ${time}`, timezone);
};

/**
 * Creates a moment object in a specific timezone and keeps the the original time. 16:00-0500 ==> 16:00-0400
 */
export const createTimeMomentInTimezoneWithoutConverting = (
    momentString: string
): moment.Moment => {
    const splitDateTime = momentString.split('T');
    const timezoneSplit = splitDateTime[1].split(/[+-]/);
    const momentStr = moment(splitDateTime[0] + 'T' + timezoneSplit[0]).format(MOMENT_DEFAULT);
    return moment(momentStr);
};

export const displayTimeZone = (
    lang: SupportedLanguage,
    id?: string | null
): string | undefined => {
    const tz = timeZoneUtil.getTimeZones(lang.toLocaleLowerCase()).find((tz) => {
        return tz.id === id;
    });

    return tz && tz.description;
};

/**
 * Checks whether the moment is considered as midnight of next day
 */
export const momentIsLastPossibleTimePeriod = (momentString: string, isUtc: boolean): boolean => {
    const momentObj = isUtc
        ? moment.utc(momentString)
        : createTimeMomentInTimezoneWithoutConverting(momentString);

    return momentObj.hour() === 23 && momentObj.minute() === 59;
};

export const toStartNextDayIfEndOfDay = (isUtc: boolean, momentString?: string): string | null => {
    if (momentString) {
        const isEndOfDay = momentIsLastPossibleTimePeriod(momentString, isUtc);
        const momentDate = isUtc ? moment.utc(momentString) : moment(momentString);

        return isEndOfDay ? momentDate.add(1, 'days').startOf('day').format() : momentString;
    } else {
        return null;
    }
};

export const decimalTimeToHoursMinutes = (decimalTimeString: string): string => {
    let decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    let hours: any = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    let minutes: any = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;
    let seconds: any = Math.round(decimalTime);
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    let result = hours + ':' + minutes;
    if (result.length >= 5 && result.slice(-2) === '00') {
        result = result.slice(0, 2);
    }
    if (result[0] === '0') return result.slice(1);
    return result;
};

export enum PointInTimeAgainstRangeEnum {
    Before = 'Before',
    Between = 'Between',
    After = 'After',
}

/**
 * Returns where point in time compares agaist a range
 */
export const pointInTimeAgainstRange = (
    pointInTime: moment.Moment,
    startRange: moment.Moment,
    endRange: moment.Moment
): PointInTimeAgainstRangeEnum => {
    if (pointInTime.isBefore(startRange)) return PointInTimeAgainstRangeEnum.Before;
    if (pointInTime.isAfter(endRange)) return PointInTimeAgainstRangeEnum.After;

    return PointInTimeAgainstRangeEnum.Between;
};

export const getWrittendDayIndex = (writtenDay: string, lang: SupportedLanguage): number => {
    if (lang === SupportedLanguage.EN) {
        switch (writtenDay) {
            case 'Sunday':
                return 0;
            case 'Monday':
                return 1;
            case 'Tuesday':
                return 2;
            case 'Wednesday':
                return 3;
            case 'Thursday':
                return 4;
            case 'Friday':
                return 5;
            case 'Saturday':
                return 6;
            default:
                return -1;
        }
    } else {
        switch (writtenDay) {
            case 'dimanche':
                return 0;
            case 'lundi':
                return 1;
            case 'mardi':
                return 2;
            case 'mercredi':
                return 3;
            case 'jeudi':
                return 4;
            case 'vendredi':
                return 5;
            case 'samedi':
                return 6;
            default:
                return -1;
        }
    }
};

export const dateToDayOfWeek = (date: string): DayOfWeekDto => {
    if (!date) return DayOfWeekDto.Monday; // error ?

    //TODO Didnt check how it works where its being used but this seems wrong.
    //https://momentjs.com/docs/#/get-set/day/ moment().day() is 0 indexed on sunday but being used as [0] === monday here
    const daysWeek = [
        DayOfWeekDto.Monday,
        DayOfWeekDto.Tuesday,
        DayOfWeekDto.Wednesday,
        DayOfWeekDto.Thursday,
        DayOfWeekDto.Friday,
        DayOfWeekDto.Saturday,
        DayOfWeekDto.Sunday,
    ];

    const dayIndex = moment(date).day();
    const day = daysWeek[dayIndex];

    return day;
};

/**
 * Conversion for dayOfWeekDto to french values
 * @param day
 * @returns DayOfWeekDto string. French local for moment is lowercase
 */
export const dayOfWeekLangMap = (day: DayOfWeekDto, lang: SupportedLanguage): string => {
    if (lang === SupportedLanguage.EN) return day;
    switch (day) {
        case DayOfWeekDto.Sunday:
            return 'dimanche';
        case DayOfWeekDto.Monday:
            return 'lundi';
        case DayOfWeekDto.Tuesday:
            return 'mardi';
        case DayOfWeekDto.Wednesday:
            return 'mercredi';
        case DayOfWeekDto.Thursday:
            return 'jeudi';
        case DayOfWeekDto.Friday:
            return 'vendredi';
        case DayOfWeekDto.Saturday:
            return 'samedi';
    }
};

/**
 *
 * @param dayIndex 0-6 based array starting at sunday like moment().day()
 */
export const dayIndexToDayOfWeekDto = (dayIndex: number): DayOfWeekDto => {
    switch (dayIndex) {
        case 0:
            return DayOfWeekDto.Sunday;
        case 1:
            return DayOfWeekDto.Monday;
        case 2:
            return DayOfWeekDto.Tuesday;
        case 3:
            return DayOfWeekDto.Wednesday;
        case 4:
            return DayOfWeekDto.Thursday;
        case 5:
            return DayOfWeekDto.Friday;
        case 6:
            return DayOfWeekDto.Saturday;
        default:
            return DayOfWeekDto.Sunday;
    }
};

/**Returns -4 for toronto */
export const getUtcOffsetString = (timezone: string): string => {
    const utc = moment
        .tz(new Date(), timezone ?? '')
        .format('Z')
        .split(':')?.[0];
    if (utc[1] === '0') return utc[0] + utc[2];
    else return utc;
};

/** 2023-05-15T10:30:00.000Z returns 10:30 */
export const getTimeFromMomentString = (momentString: string): string | undefined => {
    const split = momentString.split('T');
    if (split.length < 2) return undefined;

    return split[1].slice(0, 5);
};

export const getGMTString = (timeZone: string): string => {
    return `GMT${moment.tz(new Date(), timeZone).format('Z')}`;
};
