import React from 'react';

export const wavePatternSvg = (
    <svg
        width="785"
        height="58"
        viewBox="0 0 785 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="mask0_2448_14442"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="785"
            height="58"
        >
            <rect width="785" height="58" fill="white" />
        </mask>
        <g mask="url(#mask0_2448_14442)">
            <path
                d="M555.409 58.854L-167.833 189.333C-168.108 168.805 -160.43 149.963 -141.864 141.433C-114.699 128.967 -74.4715 142.511 -47.4734 129.82C-32.1346 122.614 -23.8215 108.178 -13.9502 95.6541C23.2894 48.4026 91.6836 24.0839 163.413 32.5974C200.905 37.0426 238.811 49.8052 275.964 51.4389C324.005 53.5513 366.192 37.0014 410.591 27.5193C453.829 18.2821 523.85 23.8275 555.409 58.854Z"
                fill="#6B40ED"
                fillOpacity="0.2"
            />
            <path
                d="M810.978 79.8515L-26.6452 83.5889C-17.6337 67.8455 -0.825897 54.4493 23.0258 50.3895C57.9167 44.4604 95.0747 60.1684 129.888 54.0458C149.666 50.57 165.16 40.6285 181.463 32.3579C242.972 1.15179 327.615 -8.36795 400.961 7.67688C439.299 16.0596 474.312 30.8614 513.561 37.048C564.312 45.0475 617.224 37.9899 669.312 36.6339C720.04 35.3113 792.893 48.8581 810.978 79.8515Z"
                fill="#2F84EE"
                fillOpacity="0.2"
            />
        </g>
    </svg>
);
