import Content from 'Components/Content';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import WhiteCard from 'Components/white-card';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemService } from 'Services/SystemService';
import { SupportedLanguage } from 'Stores/LanguageStore';
import './index.less';

const GlobalLanguage: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { globalLoadingStore, toastStore, systemStore } = useStores();
    const systemService = useService(SystemService);
    const [selectedLang, setSelectedLang] = useState<SupportedLanguage>(
        systemStore.defaultSystemLanguage
    );

    useEffect(() => {
        setSelectedLang(systemStore.defaultSystemLanguage);
    }, [systemStore.defaultSystemLanguage]);

    const submit = async (lang: SupportedLanguage): Promise<void> => {
        try {
            globalLoadingStore.addLoading();

            await systemService.updateDefaultLanguage({
                defaultLanguageCode: lang,
            });

            await systemStore.setDefaultSystemLang();

            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('SupportedLanguage.default_language'),
                    param2: t('Entity.lowercase_action_edited'),
                }),
            });
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onSelectedLanguageChange = async (option?: SingleSelectCustomOption) => {
        if (option) {
            setSelectedLang(option.value as SupportedLanguage);
            submit(option.value as SupportedLanguage);
        }
    };

    return (
        <Content className="GlobalLanguage">
            <div className="text-headline-bold mb-20">
                {t('SupportedLanguage.language_preferences')}
            </div>
            <WhiteCard padding={40}>
                <div className="mb-20">
                    {t('SupportedLanguage.change_console_pref_for_everyone')}
                </div>

                <StaticSingleSelect
                    onChange={(option?: SingleSelectCustomOption) => {
                        onSelectedLanguageChange(option);
                    }}
                    selected={selectedLang}
                    options={Object.keys(SupportedLanguage).map(
                        (lang) =>
                            ({
                                label: t(`SupportedLanguage.SupportedLanguage_${lang}`),
                                value: lang,
                            } as SingleSelectCustomOption)
                    )}
                />
            </WhiteCard>
        </Content>
    );
});

export default GlobalLanguage;
