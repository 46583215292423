import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { GetOfficeUtilizationStatsRequestDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsRequestDto';
import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';
import { GroupingTypeDto } from 'Api/Features/Stats/Dtos/GroupingTypeDto';
import { TimeUnitTypeDto } from 'Api/Features/Stats/Dtos/TimeUnitTypeDto';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import Content from 'Components/Content';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { useAsyncSingleSelectProps, useFetchQueue, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { EMPTY_GUID, FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsService } from 'Services/StatsService';
import { TeamService } from 'Services/TeamService';
import { PreviousPeriodTimeRangeFilterMap, TimeRangeKey } from 'Utils/ReportsUtils';
import BarChart from './components/bar-chart';
import GraphCard, { TimeRangeFilter } from './components/graph-card';
import HighlightCard, { HighlightData } from './components/highlight-card';
import OfficeOccupation, {
    RealEstateChartOfficeOccupationProps,
} from './components/office-occupation';
import PieChart, { PieChartProps } from './components/pie-chart';
import PopularSpaces, { RealEstateChartBaseLastMonthProps } from './components/popular-spaces';
import './index.less';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';

export enum StatsRequestTypeEnum {
    TimeSpentPieChart = 'TimeSpentPieChart',
    TimeSpentBarChart = 'TimeSpentBarChart',
    OfficeOccupation = 'OfficeOccupation',
    PopularSpaces = 'PopularSpaces',
    TimeSpentHighlight = 'TimeSpentHighlight',
    PopularDayHighlight = 'PopularDayHighlight',
    PopularWorkPeriodHighlight = 'PopularWorkPeriodHighlight',
    PopularOfficeHighlight = 'PopularOfficeHighlight',
}

export interface RealEstateChartBaseProps {
    chartData?: GetOfficeUtilizationStatsResponseDto;
}

const RealEstateStatistics: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const statsService = useService(StatsService);
    const teamService = useService(TeamService);
    const { toastStore, userStore } = useStores();
    const [addToFetchQueue, emptyQueue] = useFetchQueue(2);

    const [timeSpentPieChartData, setTimeSpentPieChartData] = useState<PieChartProps>();

    const [timeSpentBarChartData, setTimeSpentBarChartData] =
        useState<GetOfficeUtilizationStatsResponseDto>();

    const [officeOccupationData, setOfficeOccupationData] =
        useState<RealEstateChartOfficeOccupationProps>();

    const [popularSpacesData, setPopularSpacesData] = useState<RealEstateChartBaseLastMonthProps>(
        {}
    );

    const [timeSpentHighlightData, setTimeSpentHighlightData] = useState<HighlightData>();

    const [popularDayHighlightData, setPopularDayHighlightData] = useState<HighlightData>();

    const [popularWorkPeriodHighlightData, setPopularWorkPeriodHighlightData] =
        useState<HighlightData>();

    const [popularOfficeHighlightData, setPopularOfficeHighlightData] = useState<HighlightData>();

    const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>('all');
    const { asyncSingleSelectProps: teamSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetTeamsRequestDto) =>
                await teamService.getTeams(request),
            fetchFunctionExtraParams: {
                managerIds:
                    userStore.ManagementRole === ManagementRoleDto.Manager
                        ? [userStore.userInfo?.id || '']
                        : undefined,
            },
        },
        entityToSingleSelectCustomOption: (team: TeamDto) =>
            ({ label: team.name, value: team.id } as SingleSelectCustomOption),
        extraMandatoryOptions: {
            unshift: [
                {
                    label: t('all_teams'),
                    value: 'all',
                },
            ].concat(
                userStore.ManagementRole === ManagementRoleDto.Administrator
                    ? [
                          {
                              label: t('teamless'),
                              value: 'no',
                          },
                      ]
                    : []
            ),
        },
        defaultSelectedOption: {
            label: t('all_teams'),
            value: 'all',
        },
    });

    const fetchStats = async (
        timeRangeFilter: TimeRangeFilter,
        requestType: StatsRequestTypeEnum,
        officeFilter: string
    ) => {
        let groupingTypes: GroupingTypeDto[] = [];
        let workTypes: WorkTypeDto[] | null = [];
        let timeUnitType: TimeUnitTypeDto | null = null;
        let dates: string[] = [];

        switch (requestType) {
            case StatsRequestTypeEnum.TimeSpentPieChart:
                {
                    groupingTypes = [GroupingTypeDto.WorkType, GroupingTypeDto.Office];
                    workTypes = null;
                }
                break;
            case StatsRequestTypeEnum.TimeSpentBarChart:
                {
                    groupingTypes = [GroupingTypeDto.TimeUnit, GroupingTypeDto.WorkType];
                    workTypes = null;
                    timeUnitType =
                        timeRangeFilter.key === TimeRangeKey.Last4Weeks ||
                        timeRangeFilter.key === TimeRangeKey.Last3Months
                            ? TimeUnitTypeDto.Week
                            : TimeUnitTypeDto.Month;
                }
                break;
            case StatsRequestTypeEnum.PopularSpaces:
                {
                    groupingTypes = [GroupingTypeDto.OfficeSpace, GroupingTypeDto.TimeUnit];
                    workTypes = [WorkTypeDto.Office];
                    timeUnitType =
                        timeRangeFilter.key === TimeRangeKey.Last4Weeks ||
                        timeRangeFilter.key === TimeRangeKey.Last3Months
                            ? TimeUnitTypeDto.Week
                            : TimeUnitTypeDto.Month;
                }
                break;
            case StatsRequestTypeEnum.OfficeOccupation:
                {
                    groupingTypes = [GroupingTypeDto.TimeUnit];
                    workTypes = [WorkTypeDto.Office];
                    timeUnitType = TimeUnitTypeDto.Hour;
                    dates = [timeRangeFilter.start];
                }
                break;
            case StatsRequestTypeEnum.TimeSpentHighlight:
                {
                    groupingTypes = [GroupingTypeDto.WorkType, GroupingTypeDto.TimeUnit];
                    workTypes = null;
                    timeUnitType = TimeUnitTypeDto.Day;
                }
                break;
            case StatsRequestTypeEnum.PopularDayHighlight:
                {
                    groupingTypes = [GroupingTypeDto.WeekDay];
                    workTypes = [WorkTypeDto.Office];
                    timeUnitType = TimeUnitTypeDto.Day;
                }
                break;
            case StatsRequestTypeEnum.PopularWorkPeriodHighlight:
                {
                    groupingTypes = [GroupingTypeDto.DayFourHoursPeriod];
                    workTypes = [WorkTypeDto.Office];
                    timeUnitType = TimeUnitTypeDto.Day;
                }
                break;
            case StatsRequestTypeEnum.PopularOfficeHighlight:
                {
                    groupingTypes = [GroupingTypeDto.Office, GroupingTypeDto.TimeUnit];
                    workTypes = [WorkTypeDto.Office];
                    timeUnitType = TimeUnitTypeDto.Day;
                }
                break;
        }

        try {
            const request: GetOfficeUtilizationStatsRequestDto = {
                startDate:
                    requestType !== StatsRequestTypeEnum.OfficeOccupation
                        ? timeRangeFilter.start
                        : undefined,
                endDate:
                    requestType !== StatsRequestTypeEnum.OfficeOccupation
                        ? timeRangeFilter.end
                        : undefined,
                groupingTypes,
                workTypes,
                timeUnitType,
                timeZone: userStore.userInfo?.timeZone,
                officeIds:
                    officeFilter === 'all'
                        ? []
                        : officeFilter === 'not defined'
                        ? [EMPTY_GUID]
                        : [officeFilter],
                teamIds:
                    teamSelectProps.selected === 'all'
                        ? undefined
                        : teamSelectProps.selected === 'no'
                        ? [EMPTY_GUID]
                        : [teamSelectProps.selected ?? ''],
                officeIdsIncludeRemote: requestType === StatsRequestTypeEnum.TimeSpentBarChart,
                dates: dates.length > 0 ? dates : undefined,
            };
            const currentPeriodResponse = await statsService.getOfficeUtilizationStats(request);

            let lastPeriodReponse: GetOfficeUtilizationStatsResponseDto | undefined = undefined;
            if (currentPeriodResponse) {
                //graph with trends required to fetch last periods stats
                if (requestType !== StatsRequestTypeEnum.TimeSpentBarChart) {
                    lastPeriodReponse =
                        (await statsService.getOfficeUtilizationStats({
                            ...request,
                            startDate:
                                requestType === StatsRequestTypeEnum.OfficeOccupation
                                    ? undefined
                                    : PreviousPeriodTimeRangeFilterMap.get(timeRangeFilter.key)
                                          ?.start ?? '',
                            endDate:
                                requestType === StatsRequestTypeEnum.OfficeOccupation
                                    ? undefined
                                    : PreviousPeriodTimeRangeFilterMap.get(timeRangeFilter.key)
                                          ?.end ?? '',
                            dates:
                                requestType === StatsRequestTypeEnum.OfficeOccupation
                                    ? [
                                          moment(timeRangeFilter.start)
                                              .add(-7, 'days')
                                              .format(FORMAT_YEAR_MONTH_DAY),
                                          moment(timeRangeFilter.start)
                                              .add(-14, 'days')
                                              .format(FORMAT_YEAR_MONTH_DAY),
                                          moment(timeRangeFilter.start)
                                              .add(-21, 'days')
                                              .format(FORMAT_YEAR_MONTH_DAY),
                                          moment(timeRangeFilter.start)
                                              .add(-28, 'days')
                                              .format(FORMAT_YEAR_MONTH_DAY),
                                      ]
                                    : undefined,
                        })) ?? undefined;
                }

                switch (requestType) {
                    case StatsRequestTypeEnum.TimeSpentPieChart:
                        setTimeSpentPieChartData({
                            chartData: currentPeriodResponse,
                            lastPeriodChartData: lastPeriodReponse,
                        });
                        break;
                    case StatsRequestTypeEnum.TimeSpentBarChart:
                        setTimeSpentBarChartData(currentPeriodResponse);
                        break;
                    case StatsRequestTypeEnum.OfficeOccupation:
                        setOfficeOccupationData({
                            chartData: currentPeriodResponse,
                            lastPeriodChartData: lastPeriodReponse,
                        });
                        break;
                    case StatsRequestTypeEnum.PopularSpaces:
                        setPopularSpacesData({
                            chartData: currentPeriodResponse,
                            lastPeriodChartData: lastPeriodReponse,
                            currentTimeFilter: timeRangeFilter,
                        });
                        break;
                    case StatsRequestTypeEnum.TimeSpentHighlight:
                        setTimeSpentHighlightData({
                            valueData: currentPeriodResponse,
                            lastPeriodData: lastPeriodReponse,
                        });
                        break;
                    case StatsRequestTypeEnum.PopularDayHighlight:
                        setPopularDayHighlightData({
                            valueData: currentPeriodResponse,
                            lastPeriodData: lastPeriodReponse,
                        });
                        break;
                    case StatsRequestTypeEnum.PopularWorkPeriodHighlight:
                        setPopularWorkPeriodHighlightData({
                            valueData: currentPeriodResponse,
                            lastPeriodData: lastPeriodReponse,
                        });
                        break;
                    case StatsRequestTypeEnum.PopularOfficeHighlight:
                        setPopularOfficeHighlightData({
                            valueData: currentPeriodResponse,
                            lastPeriodData: lastPeriodReponse,
                        });
                        break;
                }
            }
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        }
    };

    useEffect(() => {
        //clear the fetch queue on unmount
        return () => {
            emptyQueue();
        };
    }, []);

    return (
        <Content className="RealEstateStatistics">
            <div className="content-wrapper">
                <div className="team-select-wrapper full-row">
                    <div className="text-title-3">{t('stats_for')}</div>
                    <AsyncSingleSelect
                        {...teamSelectProps}
                        onChange={(option?: SingleSelectCustomOption) => {
                            emptyQueue();
                            setSelectedTeamId(option?.value);
                        }}
                        selected={selectedTeamId}
                        placeholder={t('SelectCustom.default_select_placeholder')}
                    />
                </div>

                <div className="text-headline full-row highlights-title">
                    {t('Statistics.last_4_weeks_highlights')}
                </div>

                <HighlightCard
                    iconName="ClockIcon"
                    title={t('time_spent')}
                    fetchStats={fetchStats}
                    selectedTeams={selectedTeamId ?? 'all'}
                    dataProp={timeSpentHighlightData}
                    requestType={StatsRequestTypeEnum.TimeSpentHighlight}
                    addToFetchQueue={addToFetchQueue}
                />

                <HighlightCard
                    iconName="CalendarIcon"
                    title={t('Dashboard.most_popular_day')}
                    fetchStats={fetchStats}
                    dataProp={popularDayHighlightData}
                    selectedTeams={selectedTeamId ?? 'all'}
                    requestType={StatsRequestTypeEnum.PopularDayHighlight}
                    addToFetchQueue={addToFetchQueue}
                />

                <HighlightCard
                    iconName="CalendarIcon"
                    title={t('Dashboard.most_popular_work_period')}
                    fetchStats={fetchStats}
                    requestType={StatsRequestTypeEnum.PopularWorkPeriodHighlight}
                    dataProp={popularWorkPeriodHighlightData}
                    selectedTeams={selectedTeamId ?? 'all'}
                    addToFetchQueue={addToFetchQueue}
                />

                <HighlightCard
                    iconName="DeskIcon"
                    title={t('most_popular_office')}
                    fetchStats={fetchStats}
                    requestType={StatsRequestTypeEnum.PopularOfficeHighlight}
                    dataProp={popularOfficeHighlightData}
                    selectedTeams={selectedTeamId ?? 'all'}
                    addToFetchQueue={addToFetchQueue}
                />

                <div className="text-headline full-row">{t('real_estate_stat_details')}</div>

                <GraphCard
                    title={t('time_spent')}
                    subTitle={t('popular_spaces')}
                    hasOfficeFilter={false}
                    requestType={StatsRequestTypeEnum.TimeSpentPieChart}
                    fetchStats={fetchStats}
                    selectedTeams={selectedTeamId ?? 'all'}
                    className="time-spent-pie"
                    addToFetchQueue={addToFetchQueue}
                >
                    <PieChart
                        chartData={timeSpentPieChartData?.chartData}
                        lastPeriodChartData={timeSpentPieChartData?.lastPeriodChartData}
                    />
                </GraphCard>

                <GraphCard
                    title={t('time_spent')}
                    subTitle={t('office_remote_juxta')}
                    className="time-spent-bar"
                    requestType={StatsRequestTypeEnum.TimeSpentBarChart}
                    fetchStats={fetchStats}
                    selectedTeams={selectedTeamId ?? 'all'}
                    addToFetchQueue={addToFetchQueue}
                >
                    <BarChart chartData={timeSpentBarChartData} />
                </GraphCard>

                <GraphCard
                    title={t('office_occupation')}
                    subTitle={t('popular_hours')}
                    requestType={StatsRequestTypeEnum.OfficeOccupation}
                    fetchStats={fetchStats}
                    selectedTeams={selectedTeamId ?? 'all'}
                    filterByDay
                    className="office-occupation"
                    addToFetchQueue={addToFetchQueue}
                >
                    <OfficeOccupation
                        chartData={officeOccupationData?.chartData}
                        lastPeriodChartData={officeOccupationData?.lastPeriodChartData}
                    />
                </GraphCard>

                <GraphCard
                    title={t('popular_spaces')}
                    subTitle={t('interest_trend')}
                    requestType={StatsRequestTypeEnum.PopularSpaces}
                    fetchStats={fetchStats}
                    selectedTeams={selectedTeamId ?? 'all'}
                    className="popular-spaces"
                    addToFetchQueue={addToFetchQueue}
                >
                    <PopularSpaces
                        chartData={popularSpacesData.chartData}
                        lastPeriodChartData={popularSpacesData.lastPeriodChartData}
                        currentTimeFilter={popularSpacesData.currentTimeFilter}
                    />
                </GraphCard>
            </div>
        </Content>
    );
});

export default RealEstateStatistics;
