import { Upload } from 'antd';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import NoPicture from 'Assets/Images/image-picker-empty.svg';
import Button from 'Components/button';
import { PencilIcon } from 'Components/icons';
import Modal, { ModalHeaderColor } from 'Components/modal';
import { useStores } from 'Hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './generic-image-picker.less';
import ImageCropModal from './image-crop-modal';

interface ImagePickerProps {
    imageUrl?: string;
    emptyImageString?: string;
    width: number;
    height: number;
    modalTitle?: string;
    modalSubtitle?: string;
    onFileChange: (imageUrl?: string, base64?: string, deleted?: boolean) => void;
    enableCropping: boolean;
    croppingAspect?: number;
}

const GenericImagePicker: React.FunctionComponent<ImagePickerProps> = ({
    imageUrl,
    emptyImageString,
    width,
    height,
    modalTitle,
    modalSubtitle,
    onFileChange,
    enableCropping,
    croppingAspect,
}) => {
    const { toastStore } = useStores();
    const [changePictureModalVisible, setChangePictureModalVisible] = useState(false);
    const fileInputRef = React.createRef<HTMLInputElement>();
    const { t } = useTranslation();

    const [croppingImageSrc, setCroppingImageSrc] = useState<string>('');
    const [croppingModalOpen, setCroppingModalOpen] = useState<boolean>(false);

    const handleEditClick = () => {
        fileInputRef.current?.click();
    };

    const handleDeleteClick = () => {
        onFileChange(undefined, undefined, true);
        if (fileInputRef.current?.value) {
            fileInputRef.current.value = '';
        }
    };

    const onChange = (event) => {
        if (event.file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const imageUrl = e.target?.result as string;
                const base64 = imageUrl.substring(imageUrl.lastIndexOf(',') + 1);

                onFileChange(imageUrl, base64, false);
                setChangePictureModalVisible(false);
            };

            reader.readAsDataURL(event.file.originFileObj);
        } else {
            toastStore.toast({
                type: 'error',
                message: t('ImagePicker.error_image_cant_upload'),
            });
        }
    };

    const getFileSrc = async (file: any): Promise<string> => {
        const src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
        return src as string;
    };

    const onChangeWithCropping = async (event): Promise<void> => {
        if (event.file.status === ('uploading' as UploadFileStatus)) {
            const src = await getFileSrc(event.file);
            setCroppingImageSrc(src);
            setCroppingModalOpen(true);
        }
    };

    //The antd control requires to post on file select. We do not want this behavior so we must make a dummy request
    const dummyRequest = (request: any) => {
        setTimeout(() => {
            request.onSuccess('ok');
        }, 0);
    };

    const onImageCroppingComplete = async (
        success: boolean,
        url: string | null,
        base64: string | null
    ) => {
        if (success && url && base64) {
            onFileChange(url, base64, false);
        }
        setCroppingModalOpen(false);
        setChangePictureModalVisible(false);
    };

    return (
        <div className="GenericImagePicker">
            <div
                className={`image-container ${imageUrl ? 'has-img' : ''}`}
                style={{
                    backgroundImage: `url(${imageUrl ?? ''})`,
                    width: `${width}px`,
                    height: `${height}px`,
                }}
                onClick={() => setChangePictureModalVisible(true)}
            >
                {imageUrl ? (
                    <div className="edit-img-btn">
                        <PencilIcon fill={theme.white} />
                    </div>
                ) : (
                    <Button
                        text={emptyImageString ?? 'Add image'}
                        type="link"
                        width="hugged"
                        rightCircleIcon="AddIcon"
                        onClick={() => setChangePictureModalVisible(true)}
                    />
                )}
            </div>

            <Modal
                className="EditImageModal"
                visible={changePictureModalVisible}
                onCancel={() => setChangePictureModalVisible(false)}
                headerColor={ModalHeaderColor.white}
                width={396}
                maxHeight="418"
            >
                <div className="image-picker-container">
                    <div className="ImagePicker">
                        <div
                            className="image-container"
                            style={{ backgroundImage: `url(${imageUrl ?? NoPicture})` }}
                        />
                        <div className="text-container">
                            {modalTitle && <div className="text-callout-bold">{modalTitle}</div>}

                            {modalSubtitle && <div className="text-body">{modalSubtitle}</div>}
                        </div>

                        <div className="edit-button-container">
                            <Button
                                text={t('ImagePicker.delete_picture')}
                                type="link"
                                width="hugged"
                                onClick={() => handleDeleteClick()}
                                disabled={!imageUrl}
                            />

                            <Upload
                                ref={fileInputRef}
                                accept="image/*"
                                onChange={enableCropping ? onChangeWithCropping : onChange}
                                customRequest={dummyRequest}
                            >
                                <Button
                                    text={
                                        imageUrl
                                            ? t('ImagePicker.change_picture')
                                            : t('ImagePicker.add_picture')
                                    }
                                    type="tertiary"
                                    width="hugged"
                                    leftIcon="PencilIcon"
                                    onClick={() => handleEditClick()}
                                />
                            </Upload>
                        </div>
                    </div>
                </div>
            </Modal>

            {enableCropping && croppingImageSrc && croppingModalOpen && (
                <ImageCropModal
                    visible={croppingModalOpen}
                    src={croppingImageSrc}
                    onComplete={onImageCroppingComplete}
                    aspect={croppingAspect}
                />
            )}
        </div>
    );
};

export default React.memo(GenericImagePicker);
