import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { StatsProxy } from 'Api/Features/Stats/StatsProxy';
import { GetOfficeUtilizationStatsRequestDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsRequestDto';
import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';

@inject(StatsProxy)
export class StatsService extends ApiService {
    constructor(private readonly statsProxy: StatsProxy) {
        super();
    }

    public async getOfficeUtilizationStats(
        request: GetOfficeUtilizationStatsRequestDto | null
    ): Promise<GetOfficeUtilizationStatsResponseDto | null> {
        const data: GetOfficeUtilizationStatsResponseDto | null =
            await this.statsProxy.getOfficeUtilizationStats(request);
        return data;
    }
}
