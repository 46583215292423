import Icon from 'Components/icons/Icon';
import WhiteCard from 'Components/white-card';
import React, { FunctionComponent, ReactNode } from 'react';
import * as Icons from 'Components/icons';
import './index.less';

export interface PolicyDetailsWhiteCardData {
    title: string;
    text: string;
    infos: {
        title: string;
        icon: keyof typeof Icons;
        value: number;
        label: string;
        element?: ReactNode;
    };
    element?: ReactNode;
}

interface PolicyDetailsWhiteCardProps {
    data: PolicyDetailsWhiteCardData;
}

const PolicyDetailsWhiteCard: FunctionComponent<PolicyDetailsWhiteCardProps> = ({ data }) => {
    return (
        <WhiteCard className="PolicyDetailsWhiteCard" padding={40}>
            <div className="PolicyDetailsWhiteCard-wrapper">
                <div className="PolicyDetailsWhiteCard-text">
                    <div className="PolicyDetailsWhiteCard-title text-body-bold">{data.title}</div>
                    <div className="text-caption-1 text-mid-contrast">{data.text}</div>
                </div>

                <div className="PolicyDetailsWhiteCard-infos">
                    <div className="text-caption-3 text-mid-contrast">{data.infos.title}</div>
                    <div className="PolicyDetailsWhiteCard-infos-details">
                        <Icon iconName={data.infos.icon} />
                        <span className="number text-title-2-bold">
                            {data.infos.value < 0 ? data.infos.value * -1 : data.infos.value}
                        </span>
                        <span className="text-footnote text-mid-contrast">{data.infos.label}</span>
                    </div>
                    {data.infos.element}
                </div>
            </div>
            {data.element}
        </WhiteCard>
    );
};

export default PolicyDetailsWhiteCard;
