import { Col, Form, Input, Row } from 'antd';
import { ContactInfoDto } from 'Api/Features/General/Dtos/ContactInfoDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import Button from 'Components/button';
import GooglePlacesAutoComplete from 'Components/google-places-autocomplete';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import StaticMultiSelect from 'Components/select-custom/multi-select/static-multi-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FORM_GUTTER } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUserSchema } from 'Schemas/EditProfileSchema';
import { OnboardingBaseProps } from '..';
import './base-styles.less';
import './personal-info.less';
import { languageOptions, timeZonesOptions } from 'Utils/IntlUtils';

export interface OnboardingPersonalInfoFormValues {
    locationLabel?: string;
    locationGooglePlaceId?: string;
    timeZone: string;
    languageCodes?: string[];
    department?: string;
    contactInfo?: ContactInfoDto;
    firstName: string;
    lastName: string;
    jobTitle?: string;
}

interface OnboardingPersonalInfoProps extends OnboardingBaseProps {
    previousFormValues?: OnboardingPersonalInfoFormValues;
}

const OnboardingPersonalInfo: React.FunctionComponent<OnboardingPersonalInfoProps> = observer(
    ({ onNextClick, onPreviousClick, previousFormValues }) => {
        const { t } = useTranslation();
        const [form] = Form.useForm();
        const { userStore, languageStore } = useStores();
        const [errors, validateForm, resetErrors] = useFormValidation(CreateUserSchema, form);

        const [locationGooglePlaceId, setLocationGooglePlaceId] = useState<any>();
        const [locationLabel, setLocationLabel] = useState<string | undefined>();
        const [selectedtimeZone, setSelectedTimeZone] = useState<string>();
        const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

        const setFormValues = (fromPreviousFormValues: boolean) => {
            const source: UserDetailsDto | OnboardingPersonalInfoFormValues | null | undefined =
                fromPreviousFormValues ? previousFormValues : userStore.userInfo;

            form.setFieldsValue({
                firstName: source?.firstName,
                lastName: source?.lastName,
                jobTitle: source?.jobTitle ?? undefined,
                department: source?.department ?? undefined,
                team: userStore.userInfo?.team?.name,
                timeZone: source?.timeZone,
            });
            setSelectedLanguages(
                source?.languageCodes?.filter((lang) => lang !== null).map((lang) => lang!) ?? []
            );
            setSelectedTimeZone(source?.timeZone ?? undefined);
            setLocationGooglePlaceId(
                fromPreviousFormValues
                    ? previousFormValues?.locationGooglePlaceId
                    : userStore.userInfo?.location?.googlePlaceId
            );
        };

        useEffect(() => {
            if (userStore.userInfo && !previousFormValues) {
                setFormValues(false);
            } else {
                setFormValues(true);
            }
        }, [userStore.userInfo, previousFormValues]);

        const handleNextClick = async (): Promise<void> => {
            const formData = form.getFieldsValue();
            const validationObject: OnboardingPersonalInfoFormValues = {
                ...formData,
                contactInfo: { email: userStore.userInfo?.contactInfo?.email },
                timeZone: selectedtimeZone,
                languageCodes: selectedLanguages,
                locationGooglePlaceId:
                    locationGooglePlaceId ?? previousFormValues?.locationGooglePlaceId,
                locationLabel: locationLabel ?? previousFormValues?.locationLabel,
                defaultOfficeEnabled: false,
            };

            if (!(await validateForm(validationObject))) return;

            resetErrors();
            onNextClick(validationObject);
        };

        return (
            <div className="OnboardingPersonalInfo OnboardingBase">
                <Form layout="vertical" form={form}>
                    <div className="text-title-1-bold mb-20 centered pt-40">
                        {t('OnboardingPersonalInfo.review_your_pers_info')}
                    </div>

                    <div className="text-callout text-mid-contrast mb-20 centered subtitle">
                        {t('OnboardingPersonalInfo.help_your_team_know_more')}
                    </div>

                    <Row className="pt-40">
                        <Col offset={6} span={6}>
                            <div className="text-callout-bold mb-20">
                                {t('Onboarding.personal_information')}
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col offset={6} span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                required
                                errors={errors}
                                name="lastName"
                                label={t('last_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col offset={6} span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="jobTitle"
                                label={t('Profile.job_title')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="department"
                                label={t('department')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col offset={6} span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="googlePlaceId"
                                label={t('location')}
                            >
                                <GooglePlacesAutoComplete
                                    onChange={(value?: string, label?: string) => {
                                        setLocationGooglePlaceId(value);
                                        setLocationLabel(label);
                                    }}
                                    defaultValue={
                                        previousFormValues &&
                                        previousFormValues.locationLabel &&
                                        previousFormValues.locationGooglePlaceId
                                            ? {
                                                  label: previousFormValues.locationLabel,
                                                  value: previousFormValues.locationGooglePlaceId,
                                              }
                                            : userStore.userInfo?.location?.googlePlaceId &&
                                              userStore.userInfo.location.description
                                            ? {
                                                  label: userStore.userInfo.location.description!,
                                                  value: userStore.userInfo.location.googlePlaceId!,
                                              }
                                            : undefined
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="timeZone"
                                label={t('time_zone')}
                                required
                            >
                                <StaticSingleSelect
                                    options={timeZonesOptions(languageStore.currentLanguage)}
                                    onChange={(value?: SingleSelectCustomOption): void => {
                                        setSelectedTimeZone(value?.value);
                                    }}
                                    selected={selectedtimeZone}
                                    placeholder={t('SelectCustom.default_select_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col offset={6} span={6}>
                            <ValidatedFormItem
                                errors={errors}
                                name="languageCodes"
                                label={t('languages')}
                            >
                                <StaticMultiSelect
                                    options={languageOptions(languageStore.currentLanguage)}
                                    onChange={(values: MultiSelectCustomOption[]): void => {
                                        setSelectedLanguages(values.map((x: any) => x.value));
                                    }}
                                    selected={selectedLanguages}
                                    placeholder={t('SelectCustom.default_select_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </Form>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button text={t('previous')} type="tertiary" onClick={onPreviousClick} />
                        <Button text={t('next')} type="primary" onClick={() => handleNextClick()} />
                    </div>
                </div>
            </div>
        );
    }
);

export default OnboardingPersonalInfo;
