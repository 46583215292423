import React, { FunctionComponent } from 'react';
import { Vertical3DotsIcon } from 'Components/icons';
import { Dropdown, Menu } from 'antd';
import './index.less';
import { theme } from 'Style/theme';

export interface ThreeDotMenuOption {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

export interface VerticalThreeDotMenuButton {
    menuOptions: ThreeDotMenuOption[];
}

const VerticalThreeDotMenuButton: FunctionComponent<VerticalThreeDotMenuButton> = ({
    menuOptions,
}) => {
    return (
        <div className="VerticalThreeDotMenuButton">
            <Dropdown
                overlayClassName="VerticalThreeDotMenuButtonOverlay"
                overlay={
                    <Menu>
                        {menuOptions.map((option: ThreeDotMenuOption, i) => (
                            <Menu.Item
                                key={i}
                                onClick={option.onClick}
                                disabled={option.disabled}
                                className={option.className}
                            >
                                <div className="title">{option.title}</div>
                            </Menu.Item>
                        ))}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomRight"
            >
                <div className="trigger">
                    <Vertical3DotsIcon fill={theme['text-mid-contrast']} />
                </div>
            </Dropdown>
        </div>
    );
};

export default VerticalThreeDotMenuButton;
