import { GetUserScheduleRequestDto } from 'Api/Features/Schedules/Dtos/GetUserScheduleRequestDto';
import { ScheduleDto } from 'Api/Features/Schedules/Dtos/ScheduleDto';
import { ScheduleUserDto } from 'Api/Features/Schedules/Dtos/ScheduleUserDto';
import Modal from 'Components/modal';
import WeeklyScheduleCalendar, {
    WeeklyScheduleCalendarType,
} from 'Components/weekly-schedule-calendar';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DividedScheduleEventDto } from 'Routes/plan-schedule/plan-schedule';
import { OfficeService } from 'Services/OfficeService';
import { ScheduleService } from 'Services/ScheduleService';
import './coworker-schedule-modal.less';

interface CoworkerHoursPrefModalProps {
    visible: boolean;
    onComplete: () => void;
    coworker: ScheduleUserDto;
}

const CoworkerScheduleModal: React.FunctionComponent<CoworkerHoursPrefModalProps> = observer(
    ({ visible, onComplete, coworker }) => {
        //#region Hooks
        const { toastStore, userStore, languageStore } = useStores();
        const scheduleService = useService(ScheduleService);

        const { t } = useTranslation();

        const [dayOfWeek, setDayOfWeek] = useState<string>(moment().format(FORMAT_YEAR_MONTH_DAY));
        const [events, setEvents] = useState<DividedScheduleEventDto[]>([]);
        const officeService = useService(OfficeService);

        const [isLoaded, setIsLoaded] = useState({
            attendee: false,
        });

        const fetchSchedule = useCallback(async () => {
            const request: GetUserScheduleRequestDto = {
                week: dayOfWeek,
                timeZone: userStore.userInfo?.timeZone,
            };
            try {
                const schedule: ScheduleDto | null = await scheduleService.getUserSchedule(
                    coworker.id!,
                    request
                );

                if (schedule) {
                    //Api might send events that split between two days depending on the timezone we are requesting. We need to create two events with it.
                    const parsedEvents: DividedScheduleEventDto[] = [];
                    schedule.events
                        ?.filter((event) => event !== null)
                        .map((event) => event!)
                        .map((event) => {
                            if (
                                //event start and end are different days
                                moment
                                    .tz(event.startTime, userStore.userInfo?.timeZone ?? '')
                                    .date() !==
                                    moment
                                        .tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                        .date() &&
                                //the end is not midnight
                                !moment
                                    .tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                    .startOf('day')
                                    .isSame(
                                        moment.tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                    )
                            ) {
                                //create first event from starttime to end of day.
                                parsedEvents.push({
                                    ...event,
                                    startTime: event.startTime,
                                    endTime: moment
                                        .tz(event.startTime, userStore.userInfo?.timeZone ?? '')
                                        .endOf('day')
                                        .add(1, 'second')
                                        .format(),
                                    originalEventStartTime: event.startTime,
                                    originalEventEndTime: event.endTime,
                                    eventSpansMultipleDays: true,
                                });
                                //create second event from start of endtime day to endtime
                                parsedEvents.push({
                                    ...event,
                                    startTime: moment
                                        .tz(event.endTime, userStore.userInfo?.timeZone ?? '')
                                        .startOf('day')
                                        .format(),
                                    endTime: event.endTime,
                                    originalEventStartTime: event.startTime,
                                    originalEventEndTime: event.endTime,
                                    eventSpansMultipleDays: true,
                                });
                            } else {
                                parsedEvents.push({
                                    ...event,
                                    eventSpansMultipleDays: false,
                                    originalEventEndTime: event.endTime,
                                    originalEventStartTime: event.startTime,
                                });
                            }
                        });

                    fetchAttendeeSchedule(parsedEvents);
                    setEvents(parsedEvents ?? []);
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            }
        }, [toastStore, scheduleService, dayOfWeek, languageStore.currentLanguage]);

        const getCurrentWeek = useCallback(() => {
            return {
                start: moment(dayOfWeek).startOf('week').format(),
                end: moment(dayOfWeek).endOf('week').format(),
            };
        }, [dayOfWeek]);

        useEffect(() => {
            if (coworker.id && dayOfWeek) fetchSchedule();
        }, [coworker.id, dayOfWeek]);

        //#region Submit / Exit
        const dismiss = (): void => {
            onComplete();
        };
        //#endregion

        const fetchAttendeeSchedule = async (parsedEvents: DividedScheduleEventDto[]) => {
            setIsLoaded((prev) => {
                return {
                    ...prev,
                    attendee: false,
                };
            });

            const periods: any = [];
            const parsedEventWithCapacity: any = [];

            const getCapacity = (reponse: any, index: number) => {
                const capacityIsEqualTo0 = reponse[index].officeSpace.capacity === 0;
                if (capacityIsEqualTo0) return undefined;

                return `${reponse[index].occupancy} / ${reponse[index].officeSpace.capacity}`;
            };

            /*
                Pour optimiser l'appel API:
                    -> Tous les objets periods sont construits en amont, avant d'être envoyé en une seule requêtes API.
                    -> Les resultats restent dans le même ordres dans le tableau. Les valeurs sont donc update avec leurs index.
            */

            // Créer le periods array.
            for (const event of parsedEvents) {
                if (
                    event?.officeSpace?.id &&
                    event?.officeSpace?.id.length > 0 &&
                    event.startTime
                ) {
                    periods.push({
                        officeSpaceId: event?.officeSpace?.id || '',
                        startTime: event.startTime,
                        endTime: moment
                            .tz(event.startTime, userStore.userInfo?.timeZone ?? '')
                            .add(30, 'minutes')
                            .format(),
                    });
                }
            }

            // Appel API.
            try {
                const reponse = await officeService.getOfficeSpaceOccupancy({
                    periods: periods,
                    timeZone: userStore.userInfo?.timeZone,
                });

                // Réutilise l'index du retour API pour savoir le quel correspond au quel.
                let idx = 0;
                for (const event of parsedEvents) {
                    if (
                        event?.officeSpace?.id &&
                        event?.officeSpace?.id.length > 0 &&
                        event.startTime
                    ) {
                        periods.push({
                            officeSpaceId: event?.officeSpace?.id || '',
                            startTime: event.startTime,
                            endTime: moment
                                .tz(event.startTime, userStore.userInfo?.timeZone ?? '')
                                .add(30, 'minutes')
                                .format(),
                        });

                        parsedEventWithCapacity.push({
                            ...event,
                            capacity: getCapacity(reponse, idx),
                        });
                        idx = idx + 1;
                    } else {
                        parsedEventWithCapacity.push(event);
                    }
                }

                setEvents(parsedEventWithCapacity);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
                setEvents(parsedEvents);
            } finally {
                setIsLoaded((prev) => {
                    return {
                        ...prev,
                        attendee: true,
                    };
                });
            }
        };
        //#region Render
        return (
            <Modal
                className="CoworkerScheduleModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={`${t('week_schedule')} - ${coworker.firstName} ${coworker.lastName}`}
                noPadding
            >
                <WeeklyScheduleCalendar
                    events={events}
                    onEventsChange={() => {
                        /**Cannot change events */
                    }}
                    timezone={userStore.userInfo?.timeZone ?? ''}
                    onDateRangeChange={({ start }) =>
                        setDayOfWeek(moment(start).format(FORMAT_YEAR_MONTH_DAY))
                    }
                    currentWeek={getCurrentWeek()}
                    editedDates={[]}
                    onRevertToDefaultClick={() => {
                        /**Cannot revert to default */
                    }}
                    weeklyScheduleCalendarType={WeeklyScheduleCalendarType.Coworker}
                    isLoaded={{
                        attendee: isLoaded.attendee,
                    }}
                />
            </Modal>
        );
        //#endregion
    }
);

export default React.memo(CoworkerScheduleModal);
