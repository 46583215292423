import Content from 'Components/Content';
import TablePageSecondHeader from 'Components/table-page-second-header';
import TeamsTable, { TeamTableContext } from 'Components/teams-table/teams-table';
import { observer } from 'mobx-react-lite';
import { SETTINGS_URL, TEAMS_URL } from 'Models/Constants';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CreateTeamModal from './create-team-modal';

const Teams: FunctionComponent = observer(() => {
    const history = useHistory();
    const { t } = useTranslation();
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className="TeamsList">
            <TablePageSecondHeader
                title={t('teams')}
                createBtnString={t('create_entity', {
                    param1: t('team'),
                    param2: 'une',
                    param3: t('Entity.lowercase_team'),
                })}
                onCreateBtnClick={() => setCreateModalVisible(true)}
                isLoaded={isLoaded}
            />

            <Content>
                <TeamsTable context={TeamTableContext.Settings} onChangeIsLoaded={setIsLoaded} />
            </Content>

            {createModalVisible && (
                <CreateTeamModal
                    visible={createModalVisible}
                    onComplete={(success: boolean, addPeople: boolean, id?: string) => {
                        setCreateModalVisible(false);
                        if (success && id)
                            history.push(
                                SETTINGS_URL +
                                    TEAMS_URL +
                                    `/${id}${addPeople ? '?addPeople=true' : ''}`
                            );
                    }}
                />
            )}
        </div>
    );
});

export default Teams;
