import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Vertical3DotsIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <circle
                    cx="14.445"
                    cy="5.78942"
                    r="2.60533"
                    transform="rotate(90 14.445 5.78942)"
                    fill={fill}
                />
                <circle
                    cx="14.445"
                    cy="14.0001"
                    r="2.60533"
                    transform="rotate(90 14.445 14.0001)"
                    fill={fill}
                />
                <circle
                    cx="14.445"
                    cy="22.2108"
                    r="2.60533"
                    transform="rotate(90 14.445 22.2108)"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Vertical3DotsIcon;
