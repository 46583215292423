import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PeopleIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M18.5879 13.7422C20.7148 13.7422 22.4375 11.8701 22.4375 9.56738C22.4375 7.2998 20.7236 5.48926 18.5879 5.48926C16.4785 5.48926 14.7383 7.32617 14.7383 9.58496C14.7471 11.8789 16.4697 13.7422 18.5879 13.7422ZM8.40137 13.9443C10.2559 13.9443 11.75 12.3008 11.75 10.2705C11.75 8.29297 10.2559 6.69336 8.40137 6.69336C6.56445 6.69336 5.04395 8.31934 5.05273 10.2793C5.05273 12.3096 6.55566 13.9443 8.40137 13.9443ZM18.5879 12.2041C17.375 12.2041 16.3467 11.0615 16.3467 9.57617C16.3467 8.14355 17.3662 7.02734 18.5879 7.02734C19.8184 7.02734 20.8291 8.12598 20.8291 9.56738C20.8291 11.0439 19.8096 12.2041 18.5879 12.2041ZM8.40137 12.4238C7.4082 12.4238 6.56445 11.4746 6.56445 10.2793C6.56445 9.13672 7.39941 8.20508 8.40137 8.20508C9.4209 8.20508 10.2471 9.11914 10.2471 10.2705C10.2471 11.4746 9.40332 12.4238 8.40137 12.4238ZM3.56738 22.0215H10.3965C9.91309 21.7402 9.58789 21.0986 9.64941 20.5186H3.48828C3.32129 20.5186 3.25098 20.4395 3.25098 20.29C3.25098 18.2773 5.60645 16.3613 8.39258 16.3613C9.37695 16.3613 10.3613 16.6074 11.1172 17.0293C11.416 16.5986 11.7852 16.2295 12.2686 15.9131C11.1523 15.2275 9.76367 14.8584 8.39258 14.8584C4.6748 14.8584 1.66895 17.5127 1.66895 20.4307C1.66895 21.4854 2.30176 22.0215 3.56738 22.0215ZM13.1123 22.0215H24.0635C25.584 22.0215 26.3223 21.5381 26.3223 20.501C26.3223 18.084 23.2988 14.8672 18.5879 14.8672C13.877 14.8672 10.8535 18.084 10.8535 20.501C10.8535 21.5381 11.5918 22.0215 13.1123 22.0215ZM12.8311 20.4834C12.6289 20.4834 12.5498 20.4131 12.5498 20.2549C12.5498 18.9102 14.7207 16.4053 18.5879 16.4053C22.4551 16.4053 24.626 18.9102 24.626 20.2549C24.626 20.4131 24.5469 20.4834 24.3359 20.4834H12.8311Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PeopleIcon;
