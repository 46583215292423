import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { RSVPToggle } from 'Components/toggle';
import { ButtonDropdownAttendingStyle } from 'Components/toggle/rsvp-toggle/components/button-dropdown-attending';
import { useStores } from 'Hooks';
import React, { useEffect, useState } from 'react';
import RSVPInfoDetail, { RSVPInfoDetailProps } from './detail';
import './index.less';
import AddToCalendarBtn, {
    AddCalendarIconProps,
} from 'Components/add-to-calendar-btn/add-to-calendar-btn';

interface RSVPInfoProps extends RSVPInfoDetailProps {
    /**detail?: boolean Désactive/Active la partie gauche de la barre, comportant la capacité et les images d'utilisateurs */
    detail?: boolean;

    /**toggle?: boolean Désactive/Active la partie droite de la barre, comportant le toggle permettant d'attending le happening */
    toggle?: boolean;

    happening: HappeningDto;
    hideImages?: boolean;
    dropdownBtnStyle?: ButtonDropdownAttendingStyle;

    isPast?: boolean;
    addToCalendarButtonProps: AddCalendarIconProps;
}

enum STATE {
    NOT_ATTENDING,
    ATTENDING,
    ATTENDING_COMPAGNION,
}

const RSVPInfo: React.FunctionComponent<RSVPInfoProps> = ({
    detail = true,
    toggle = true,
    viewAllButton,
    happening,
    hideImages,
    dropdownBtnStyle,
    isPast = false,
    addToCalendarButtonProps,
}) => {
    const [addAttendeesCount, setAddAttendeesCount] = useState(0);
    const [defaultIsActive, setDefaultIsActive] = useState(false);
    const [defaultCompagnion, setDefaultCompagnion] = useState(0);
    const [attendingUserImgs, setAttendingUserImgs] = useState<string[]>([]);
    const [RSVPInfoDetailNumberCoworkers, setRSVPInfoDetailNumberCoworkers] = useState<number>(0);

    //user changed attendence, set local changes
    const [userAttendingLocal, setUserAttendingLocal] = useState<boolean | undefined>(undefined);

    const { userStore } = useStores();

    const onPressToggleExtra = (isActive: boolean, isAttendingCustom: boolean) => {
        const stateDefault = !defaultIsActive
            ? STATE.NOT_ATTENDING
            : defaultIsActive && defaultCompagnion === 0
            ? STATE.ATTENDING
            : STATE.ATTENDING_COMPAGNION;

        const stateNew = !isActive
            ? STATE.NOT_ATTENDING
            : isActive && isAttendingCustom === false
            ? STATE.ATTENDING
            : STATE.ATTENDING_COMPAGNION;

        setAddAttendeesCount(() => {
            if (stateDefault === stateNew) return 0;
            if (stateDefault === STATE.NOT_ATTENDING) return stateNew === STATE.ATTENDING ? +1 : +2;
            if (stateDefault === STATE.ATTENDING) return stateNew === STATE.NOT_ATTENDING ? -1 : +1;
            if (stateDefault === STATE.ATTENDING_COMPAGNION)
                return stateNew === STATE.ATTENDING ? -1 : -2;
            return 0;
        });
    };

    useEffect(() => {
        setDefaultIsActive(happening.myAttendance !== null);
        setDefaultCompagnion(happening.myAttendance?.numberOfCompanions ?? 0);
    }, [happening.id]);

    useEffect(() => {
        //no attending local changes havec been made, take images from api dto
        if (userAttendingLocal === undefined) {
            setAttendingUserImgs(
                happening.attendees?.map((attendee) => attendee?.imageUrl || '') ?? []
            );
            setRSVPInfoDetailNumberCoworkers(happening.attendeesCount ?? 0);
        }
        // local changes user is attending add his image and remove if needed from api dto
        else if (userAttendingLocal) {
            setAttendingUserImgs(
                happening.attendees
                    ?.filter((att) => att?.id !== userStore.userInfo?.id)
                    .map((attendee) => attendee?.imageUrl || '')
                    .concat(userStore.userInfo?.imageUrl ? [userStore.userInfo.imageUrl] : []) ?? []
            );
            setRSVPInfoDetailNumberCoworkers(
                (happening.attendeesCount ?? 0) +
                    (happening.attendees?.some((att) => att?.id === userStore.userInfo?.id) ? 0 : 1)
            );
        }
        //local changes user not attending remove his image from api dto if needed
        else {
            setAttendingUserImgs(
                happening.attendees
                    ?.filter((att) => att?.id !== userStore.userInfo?.id)
                    .map((attendee) => attendee?.imageUrl || '') ?? []
            );
            setRSVPInfoDetailNumberCoworkers(
                (happening.attendeesCount ?? 0) -
                    (happening.attendees?.some((att) => att?.id === userStore.userInfo?.id) ? 1 : 0)
            );
        }
    }, [userAttendingLocal, happening, userStore.userInfo?.id]);

    return happening.attendeesEnabled ? (
        <div className="RSVPInfo">
            {detail && (
                <RSVPInfoDetail
                    maxCapacity={happening.capacity || 0}
                    currentPeople={
                        (happening.attendeesCount || 0) +
                        addAttendeesCount +
                        (happening.attendeeCompanionsCount || 0)
                    }
                    peopleImageUrls={attendingUserImgs}
                    favCoworkers={happening.favoriteAttendeesCount || 0}
                    numberCoworkers={RSVPInfoDetailNumberCoworkers}
                    happening={happening}
                    viewAllButton={viewAllButton}
                    hideImages={hideImages}
                    isPast={isPast}
                />
            )}

            <AddToCalendarBtn
                happening={happening}
                iconProps={{
                    width: addToCalendarButtonProps.width,
                    color: addToCalendarButtonProps.color,
                }}
            />

            {toggle && happening ? (
                <RSVPToggle
                    happening={happening}
                    onPressToggleExtra={onPressToggleExtra}
                    dropdownBtnStyle={dropdownBtnStyle}
                    onAttendenceChange={(isAttending: boolean) =>
                        setUserAttendingLocal(isAttending)
                    }
                    isPast={isPast}
                />
            ) : null}
        </div>
    ) : null;
};

export default RSVPInfo;
