import { Checkbox, Col, Input, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { CreateHappeningRequestDto } from 'Api/Features/Happenings/Dtos/CreateHappeningRequestDto';
import { HappeningAttendanceTypeDto } from 'Api/Features/Happenings/Dtos/HappeningAttendanceTypeDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import Button from 'Components/button';
import DateAndtimeControl from 'Components/date-and-time-control/date-and-time-control';
import GenericImagePicker from 'Components/generic-image-picker/generic-image-picker';
import Modal from 'Components/modal';
import QuillWrapper from 'Components/quill-wrapper/quill-wrapper';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import ToggleableRow from 'Components/toggleable-row';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useAsyncSingleSelectProps, useFormValidation, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import {
    FORM_GUTTER,
    MINIMUM_TIME_PERIOD_MINUTES,
    TWENTY_FOUR_HOUR_MINUTE,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import moment from 'moment';
import { default as React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateHappeningSchema } from 'Schemas/CreateHappeningSchema';
import { HappeningService } from 'Services/HappeningService';
import { OfficeService } from 'Services/OfficeService';
import { parseLinksToAbsolute } from 'Utils/QuillUtils';
import { createTimezonedMomentFromDateAndTime } from 'Utils/TimeUtils';
import './create-social-event-modal.less';
import { SlackService } from 'Services/SlackService';
import { SlackTextIcon } from 'Components/icons';
import { GetSlackChannelsRequestDto } from 'Api/Features/Slack/Dtos/GetSlackChannelsRequestDto';
import { SlackChannel } from 'Api/Features/Slack/Dtos/SlackChannel';
import SubmitButton from 'Components/submit-button/submit-button';

export enum SocialEventType {
    Happening = 'Happening',
    ClubGathering = 'ClubGathering',
}

interface CreateSocialEventModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    socialEvent?: HappeningDto;
    socialEventType: SocialEventType;
    clubId?: string;
}

const CreateSocialEventModal: React.FunctionComponent<CreateSocialEventModalProps> = observer(
    ({ visible, onComplete, socialEvent, socialEventType, clubId }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const { toastStore, globalLoadingStore, userStore } = useStores();
        const [errors, validateForm, resetErrors] = useFormValidation(CreateHappeningSchema, form);
        const officeService = useService(OfficeService);
        const happeningService = useService(HappeningService);
        const slackService = useService(SlackService);

        const [isSlackWorkspaceConnected, setIsSlackWorkspaceConnected] = useState(false);
        const [sendSlackChecked, setSendSlackChecked] = useState(false);
        const [slackGeneralChannel, setSlackGeneralChannel] = useState<
            SingleSelectCustomOption | undefined
        >();

        const [selectedEventType, setSelectedEventType] = useState<HappeningAttendanceTypeDto>();

        const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficesRequestDto) =>
                    await officeService.getOffices(request),
            },
            entityToSingleSelectCustomOption: (office: OfficeDto) =>
                ({
                    label: office.name,
                    value: office.id,
                    extraData: { timeZone: office.timeZone },
                } as SingleSelectCustomOption),
            defaultSelectedOption: socialEvent
                ? {
                      value: socialEvent.office?.id ?? '',
                      label: socialEvent.office?.name ?? '',
                      extraData: { timeZone: socialEvent.office?.timeZone ?? '' },
                  }
                : undefined,
        });

        const { asyncSingleSelectProps: slackSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetSlackChannelsRequestDto) =>
                    await slackService.getSlackChannels(request),
            },
            entityToSingleSelectCustomOption: (channel: SlackChannel) =>
                ({
                    label: channel.name,
                    value: channel.id,
                } as SingleSelectCustomOption),
            defaultSelectedOption: { value: 'general', label: 'general' },
        });

        const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment>();
        const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment>();
        const [startTime, setStartTime] = useState<string | undefined>();
        const [endTime, setEndTime] = useState<string | undefined>();

        const [imageUrl, setImageUrl] = useState<string | undefined>();
        const [base64, setBase64] = useState<string>();
        const [imageDeleted, setImageDeleted] = useState(false);

        const [attendeesEnabled, setAttendeesEnabled] = useState(false);

        const [noMarkupDescription, setNoMarkupDescription] = useState<string>();
        const [maxCompanions, setMaxCompanions] = useState(false);

        useEffect(() => {
            //when creating new event, set the dates as today
            if (!socialEvent) {
                setSelectedStartDate(moment());
                setSelectedEndDate(moment());
                form.setFieldsValue({
                    startDate: moment().toDate(),
                    endDate: moment().toDate(),
                });
            }
        }, [socialEvent]);

        useEffect(() => {
            //set field values for edit
            if (socialEvent) {
                form.setFieldsValue({
                    name: socialEvent.name,
                    startDate: moment
                        .tz(socialEvent.startTime, socialEvent.office?.timeZone ?? '')
                        .toDate(),
                    endDate: moment
                        .tz(socialEvent.endTime, socialEvent.office?.timeZone ?? '')
                        .toDate(),
                    capacity: socialEvent.capacity ?? undefined,
                    description: socialEvent.description,
                });

                setMaxCompanions(
                    socialEvent?.maxCompanions
                        ? socialEvent?.maxCompanions >= 1 || 0
                            ? true
                            : false
                        : false
                );
                setAttendeesEnabled(socialEvent.attendeesEnabled ?? false);
                setImageUrl(socialEvent.imageUrl ?? undefined);
                setSelectedEventType(socialEvent.attendanceType);
                setSelectedStartDate(
                    moment.tz(socialEvent.startTime, socialEvent.office?.timeZone ?? '')
                );
                setSelectedEndDate(
                    moment.tz(socialEvent.endTime, socialEvent.office?.timeZone ?? '')
                );
                setStartTime(
                    moment
                        .tz(socialEvent.startTime, socialEvent.office?.timeZone ?? '')
                        .format(TWENTY_FOUR_HOUR_MINUTE)
                );
                setEndTime(
                    moment
                        .tz(socialEvent.endTime, socialEvent.office?.timeZone ?? '')
                        .format(TWENTY_FOUR_HOUR_MINUTE)
                );
            }
        }, [socialEvent?.id]);

        //#region Submit / Exit
        const dismiss = (success = false, id?: string): void => {
            form.resetFields();
            resetErrors();
            onComplete(success, id);
        };

        const submit = async (): Promise<void> => {
            const formData = form.getFieldsValue();

            const validationObject = {
                name: formData.name,
                startTime: startTime,
                endTime: endTime,
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                officeId: officeSelectProps.selected,
                description: noMarkupDescription,
                attendanceType: selectedEventType,
                attendeesEnabled: attendeesEnabled,
                capacity: formData.capacity,
                sendSlackChecked,
                slackChannelId: slackSelectProps.selected,
            };

            if (!(await validateForm(validationObject))) return;
            try {
                globalLoadingStore.addLoading();

                if (socialEventType === SocialEventType.ClubGathering && clubId == undefined)
                    return;

                const request: CreateHappeningRequestDto = {
                    ...formData,
                    startTime: createTimezonedMomentFromDateAndTime(
                        moment(selectedStartDate).format(FORMAT_YEAR_MONTH_DAY),
                        startTime!,
                        officeSelectProps.selectedOption?.extraData.timeZone ?? ''
                    ).format(),
                    endTime: createTimezonedMomentFromDateAndTime(
                        moment(selectedEndDate).format(FORMAT_YEAR_MONTH_DAY),
                        endTime!,
                        officeSelectProps.selectedOption?.extraData.timeZone ?? ''
                    ).format(),
                    officeId: officeSelectProps.selected,
                    attendanceType: selectedEventType,
                    attendeesEnabled: attendeesEnabled,
                    image: base64
                        ? ({
                              uploadBase64: base64,
                          } as UpdateFileRequestDto)
                        : imageDeleted
                        ? ({ delete: true } as UpdateFileRequestDto)
                        : null,
                    description: parseLinksToAbsolute(formData.description),
                    maxCompanions: maxCompanions ? 1 : 0,
                    clubId: socialEventType === SocialEventType.ClubGathering ? clubId : undefined,
                    organizerId: userStore.userInfo?.id,
                    slackChannelId: sendSlackChecked ? slackSelectProps.selected : undefined,
                };

                let response;
                if (socialEvent?.id) {
                    await happeningService.updateHappening(socialEvent.id, request);
                } else {
                    response = await happeningService.createHappening(request);
                }

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t(`SocialEventType.SocialEventType_${socialEventType}`),
                    }),
                });

                dismiss(true, response?.id);
            } catch (e: any) {
                if (e.response?.data?.errors.slackChannelId?.[0].description !== undefined) {
                    toastStore.toast({
                        type: 'error',
                        message: e.response?.data?.errors.slackChannelId?.[0].description,
                    });
                    e.treated = true;
                }
                if (e.response?.data?.errors?.endTime) {
                    toastStore.toast({
                        type: 'error',
                        message: e.response.data.errors.endTime[0]?.description,
                    });
                    e.treated = true;
                }
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#region Submit / Exit

        const debounceOnDescriptionChange = useRef(
            debounce((editor: any) => {
                setNoMarkupDescription(editor?.getText());
            }, 200)
        );

        useEffect(() => {
            return () => {
                debounceOnDescriptionChange.current.cancel();
            };
        }, [debounceOnDescriptionChange]);

        const getMinimumEndTimeAvailable = useMemo((): string => {
            if (!selectedStartDate || !selectedEndDate) return '';
            if (selectedStartDate.isBefore(selectedEndDate)) return '';

            return moment()
                .startOf('day')
                .add(startTime)
                .add(MINIMUM_TIME_PERIOD_MINUTES, 'minute')
                .format(TWENTY_FOUR_HOUR_MINUTE);
        }, [startTime, selectedStartDate, selectedEndDate]);

        const onStartDateChange = (value: moment.Moment) => {
            //for when complaints come in https://appcom.atlassian.net/browse/FLEXY-2791
            setSelectedStartDate(value);
            setSelectedEndDate(value);
            form.setFieldsValue({
                endDate: value.toDate(),
            });
        };

        const onStartTimeChange = (value?: string) => {
            //for when complaints come in https://appcom.atlassian.net/browse/FLEXY-2791
            setStartTime(value);
            const endTime = moment(`2020-01-01T${value}`)
                .add(1, 'hour')
                .format(TWENTY_FOUR_HOUR_MINUTE);
            if (value) {
                setEndTime(endTime);
                form.setFieldsValue({
                    endTime: endTime,
                });
            }
        };

        useEffect(() => {
            if (slackGeneralChannel) {
                slackSelectProps.onChange(slackGeneralChannel);
            }
        }, [slackGeneralChannel]);

        const fetchSlackGeneralChannel = useCallback(async () => {
            //general is ALWAYS first result
            const [response] = await slackService.getSlackChannels({ page: 0, pageSize: 1 });
            setSlackGeneralChannel({ value: response[0].id ?? '', label: response[0].name ?? '' });
        }, []);

        const fetchIsSlackWorkspaceConnected = useCallback(async (): Promise<void> => {
            try {
                const isConnected = await slackService.isSlackWorkspaceConnected();
                setIsSlackWorkspaceConnected(isConnected);
                if (isConnected) {
                    await fetchSlackGeneralChannel();
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }, [globalLoadingStore, slackService]);

        useEffect(() => {
            fetchIsSlackWorkspaceConnected();
        }, []);

        return (
            <Modal
                className="CreateSocialEventModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t(`${socialEvent ? 'edit' : 'create'}_entity`, {
                    param1: t(`SocialEventType.SocialEventType_${socialEventType}`),
                    param2: 'un',
                    param3: t(`Entity.lowercase_SocialEventType_${socialEventType}`),
                })}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />

                        <SubmitButton
                            text={
                                socialEvent
                                    ? t('save_changes')
                                    : t('create_entity', {
                                          param1: t(
                                              `SocialEventType.SocialEventType_${socialEventType}`
                                          ),
                                      })
                            }
                            type="primary"
                            onClick={() => submit()}
                        />
                    </>
                }
            >
                <Row gutter={FORM_GUTTER}>
                    <Col span={24}>
                        <GenericImagePicker
                            onFileChange={(imageUrl, base64, deleted) => {
                                setImageUrl(imageUrl);
                                setBase64(base64);
                                setImageDeleted(deleted ?? false);
                            }}
                            imageUrl={imageUrl}
                            width={581}
                            height={327}
                            emptyImageString={t('Happening.add_cover_image')}
                            enableCropping
                        />
                    </Col>
                </Row>

                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <div className="text-callout-bold mb-15 capitalize">{t('details')}</div>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="attendanceType"
                                label={t('attendance_type')}
                                required
                            >
                                <StaticSingleSelect
                                    options={Object.keys(HappeningAttendanceTypeDto).map(
                                        (key) =>
                                            ({
                                                label: t(
                                                    `HappeningAttendanceTypeDto.HappeningAttendanceTypeDto_${key}`
                                                ),
                                                value: key,
                                            } as SingleSelectCustomOption)
                                    )}
                                    onChange={(value?: SingleSelectCustomOption): void => {
                                        setSelectedEventType(
                                            value?.value
                                                ? (value?.value as HappeningAttendanceTypeDto)
                                                : undefined
                                        );
                                    }}
                                    selected={selectedEventType}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="officeId"
                                label={t('office')}
                                required
                            >
                                <AsyncSingleSelect {...officeSelectProps} />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <DateAndtimeControl
                                required
                                dateFieldName="startDate"
                                timeFieldName="startTime"
                                fieldLabel={t('from')}
                                errors={errors}
                                onDateChange={(value: moment.Moment) => onStartDateChange(value)}
                                onTimeChange={(value?: string) => onStartTimeChange(value)}
                                timeValue={startTime}
                            />
                        </Col>
                        <Col span={12}>
                            <DateAndtimeControl
                                required
                                dateFieldName="endDate"
                                timeFieldName="endTime"
                                fieldLabel={t('to')}
                                errors={errors}
                                onDateChange={setSelectedEndDate}
                                onTimeChange={setEndTime}
                                timeValue={endTime}
                                minDate={selectedStartDate?.toDate()}
                                minimumAvailableTime={getMinimumEndTimeAvailable}
                            />
                        </Col>
                    </Row>

                    <ToggleableRow
                        title={t('RSVP')}
                        subTitle={t('rsvp.rsvp_description')}
                        toggle={{
                            checked: attendeesEnabled,
                            onChange: (e) => {
                                setAttendeesEnabled(e);
                                if (!e) {
                                    form.resetFields(['capacity']);
                                }
                            },
                        }}
                        className="rsvp-toggle-row"
                    >
                        <div style={{ display: 'flex', gap: 20 }}>
                            <ValidatedFormItem label={''} name={'maxCompanions'} errors={errors}>
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <Checkbox
                                        checked={maxCompanions}
                                        disabled={!attendeesEnabled}
                                        onChange={() => setMaxCompanions((prev) => !prev)}
                                    />
                                    <div
                                        onClick={() => setMaxCompanions((prev) => !prev)}
                                        className="cursor-pointer"
                                    >
                                        {t('Happening.allow_rsvp_plus_1')}
                                    </div>
                                </div>
                            </ValidatedFormItem>

                            <ValidatedFormItem
                                label={t('capacity')}
                                name={'capacity'}
                                errors={errors}
                            >
                                <Input
                                    value={form.getFieldsValue(['capacity'])}
                                    disabled={!attendeesEnabled}
                                />
                            </ValidatedFormItem>
                        </div>
                    </ToggleableRow>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <div className="text-callout-bold mb-15 capitalize">
                                {t('description')}
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <QuillWrapper
                                onChange={(value: string, editor) => {
                                    form.setFieldsValue({ description: value });
                                    debounceOnDescriptionChange.current(editor);
                                }}
                                value={form.getFieldValue('description') ?? null}
                                errors={errors}
                                fieldName="description"
                            />
                        </Col>
                    </Row>

                    {/* only available on create*/}
                    {!socialEvent && isSlackWorkspaceConnected && (
                        <>
                            <Row gutter={FORM_GUTTER}>
                                <Col span={8}>
                                    <div className="text-callout-bold mb-15 capitalize">
                                        {t('Settings.menu_integration_title')}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={FORM_GUTTER}>
                                <Col span={24}>
                                    <div className="slack-container">
                                        <SlackTextIcon />
                                        <div className="divider" />
                                        <div className="checkbox-container">
                                            <Checkbox
                                                onChange={() =>
                                                    setSendSlackChecked((prev) => !prev)
                                                }
                                            >
                                                <div className="text-body-bold">
                                                    {t('Happening.happening_post_on_slack')}
                                                </div>
                                                <div className="text-body">
                                                    {t(
                                                        'Happening.happening_sends_automatic_message'
                                                    )}
                                                </div>
                                            </Checkbox>
                                        </div>
                                        <ValidatedFormItem
                                            label={''}
                                            name={'slackChannelId'}
                                            errors={errors}
                                        >
                                            <AsyncSingleSelect
                                                {...slackSelectProps}
                                                disabled={!sendSlackChecked}
                                                placeholder={t('SelectCustom.choose_a_entity', {
                                                    param1: t('Entity.lowercase_channel'),
                                                })}
                                            />
                                        </ValidatedFormItem>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            </Modal>
        );
    }
);

export default CreateSocialEventModal;
