import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import Icon from 'Components/icons/Icon';
import React, { ReactElement, ReactFragment, ReactNode } from 'react';
import { theme } from 'Style/theme';
import './index.less';

export enum ModalHeaderColor {
    blue = 'blue',
    white = 'white',
}

interface BaseModalProps extends ModalProps {
    headerText?: string;
    oustideOverflow?: { element: ReactElement; height: number };
    footer?: ReactFragment;
    maxHeight?: string;
    noPadding?: boolean;
    mobileFullScreen?: boolean;
    fullScreenHeaderText?: string;
    headerColor?: ModalHeaderColor;
    centeredHeaderText?: boolean;
    headerActionIcon?: ReactNode;
    /**Most modals take up the height they need and have a max height. If the modal has dynamic content like an infinite load list, set this to true to avoid height flickering */
    fixedHeight?: boolean;
    modalBackgroundColor?: string;
}

const BaseModal: React.FunctionComponent<BaseModalProps> = ({
    headerText,
    oustideOverflow,
    footer,
    width = 1200,
    maxHeight = '85vh',
    noPadding = false,
    mobileFullScreen = false,
    fullScreenHeaderText,
    maskClosable = false,
    children,
    className,
    headerColor = ModalHeaderColor.blue,
    centeredHeaderText,
    closeIcon = (
        <Icon
            iconName="CloseIcon"
            fill={headerColor === ModalHeaderColor.blue ? theme.white : theme['text-mid-contrast']}
        />
    ),
    fixedHeight,
    headerActionIcon,
    modalBackgroundColor,
    ...props
}) => {
    const padding = noPadding ? 0 : mobileFullScreen ? `20px 20px 60px 20px` : '20px';
    const headerHeight = 55;
    const footerHeight = 90;
    let heightToSubtract = 0;

    if (headerText) heightToSubtract = heightToSubtract + headerHeight;
    if (oustideOverflow) heightToSubtract = heightToSubtract + oustideOverflow.height;
    if (footer) heightToSubtract = heightToSubtract + footerHeight;

    const calculatedMaxHeight = `calc(${
        mobileFullScreen ? '100%' : maxHeight
    } - ${heightToSubtract}px)`;

    return (
        <Modal
            className={`${className} fullScreen-${mobileFullScreen} ${
                headerColor === ModalHeaderColor.white ? 'header-white' : ''
            } ${centeredHeaderText ? 'centered-header' : ''}`}
            centered={!mobileFullScreen}
            footer={null}
            maskClosable={maskClosable}
            width={mobileFullScreen ? '100vw' : width}
            closeIcon={closeIcon}
            {...props}
            maskTransitionName="" // <- Impératif pour : import ImgCrop from 'antd-img-crop' pour prévenir de problème sur safari
            transitionName="" // <- Impératif pour : import ImgCrop from 'antd-img-crop' pour prévenir de problème sur safari
        >
            {(headerText || headerActionIcon) && (
                <div className="modal-header" style={{ height: `${headerHeight}px` }}>
                    {mobileFullScreen ? null : headerText}
                    <div className="header-action">{headerActionIcon}</div>
                </div>
            )}

            {oustideOverflow && (
                <div className="oustide-overflow" style={{ height: `${oustideOverflow.height}px` }}>
                    {oustideOverflow.element}
                </div>
            )}

            <div
                className="content"
                style={{
                    height: fixedHeight ? calculatedMaxHeight : undefined,
                    maxHeight: calculatedMaxHeight,
                    padding: padding,
                    backgroundColor: modalBackgroundColor,
                }}
            >
                {mobileFullScreen && fullScreenHeaderText && (
                    <div className="full-screen-modal-header">{fullScreenHeaderText}</div>
                )}

                {children}
            </div>

            {footer && (
                <div className="footer" style={{ height: `${footerHeight}px` }}>
                    {footer}
                </div>
            )}
        </Modal>
    );
};

export default BaseModal;
