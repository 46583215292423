import Icon from 'Components/icons/Icon';
import 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

export interface DashboardObjectivesProps {
    completedObjectives: number;
    totalObjectives: number;
}

export const dashboardObjectivesInit = {
    completedObjectives: 0,
    totalObjectives: 0,
};

const DashboardObjectives: React.FunctionComponent<DashboardObjectivesProps> = ({
    completedObjectives,
    totalObjectives,
}) => {
    const { t } = useTranslation();
    const strokeDasharray = 140;
    const offset = (completedObjectives * strokeDasharray) / totalObjectives;

    return (
        <div className="DashboardObjectives">
            <div className={`objectives-wrapper no-objectives-${totalObjectives <= 0}`}>
                <div className={`circle-wrapper completed-${offset === strokeDasharray}`}>
                    <svg
                        className="back-circle"
                        width="40"
                        height="40"
                        viewBox="0 0 50 50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                    >
                        <ellipse
                            strokeWidth="6"
                            ry="22"
                            rx="22"
                            cy="25"
                            cx="25"
                            stroke="#000"
                            style={{
                                strokeDasharray: `${strokeDasharray}px`,
                                strokeDashoffset: `${offset}px`,
                            }}
                        />
                    </svg>
                    <svg
                        className="front-circle"
                        width="40"
                        height="40"
                        viewBox="0 0 50 50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                    >
                        <ellipse strokeWidth="6" ry="22" rx="22" cy="25" cx="25" stroke="#000" />
                    </svg>

                    {offset === strokeDasharray ? (
                        <Icon iconName="CircleCheckmarkIcon" />
                    ) : (
                        <Icon iconName="TargetArrowIcon" />
                    )}
                </div>

                <div className="text-footnote">
                    {totalObjectives !== 0
                        ? `${completedObjectives}/${totalObjectives}
                        ${t('PlanSchedule.completed_objectives')}`
                        : t('Balance.no_objectives')}
                </div>
            </div>
        </div>
    );
};
export default DashboardObjectives;
