import Button from 'Components/button';
import Modal from 'Components/modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './image-crop-modal.less';
import SubmitButton from 'Components/submit-button/submit-button';

interface ImageCropModalProps {
    src: string;
    aspect?: number;
    title?: string;
    visible: boolean;
    onComplete: (success: boolean, url: string | null, base64: string | null) => void;
    circularCrop?: boolean;
}

const ImageCropModal: React.FunctionComponent<ImageCropModalProps> = ({
    src,
    aspect,
    visible,
    onComplete,
    circularCrop,
}) => {
    const { t } = useTranslation();
    const [imageRef, setImageRef] = useState<HTMLImageElement>();
    const [crop, setCrop] = useState<Crop>({
        unit: '%',
        width: 30,
        x: 40,
        y: 40,
        aspect: circularCrop ? 1 : aspect ? aspect : 16 / 9,
    });

    const dismiss = (success = false, url = null, base64 = null): void => {
        onComplete(success, url, base64);
    };

    const onImageLoaded = (image: HTMLImageElement): void => {
        setImageRef(image);
    };

    const getCroppedImageUrl = async (
        image: HTMLImageElement,
        crop: any
    ): Promise<[string, string]> => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width || 0;
        canvas.height = crop.height || 0;
        const ctx = canvas.getContext('2d');

        ctx?.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        let base64 = canvas.toDataURL();
        // remove image type and base64 from base64 str keep only data.
        base64 = base64.split(',').pop() || '';

        return new Promise((resolve) => {
            canvas.toBlob(async (blob: any) => {
                if (!blob) {
                    return;
                }
                window.URL.revokeObjectURL(src);
                src = window.URL.createObjectURL(blob);
                resolve([src, base64]);
            }, 'image/jpeg');
        });
    };

    const onCropChange = (crop: Crop): void => {
        setCrop(crop);
    };

    const submit = async (): Promise<void> => {
        if (imageRef && crop.width && crop.height) {
            const [croppedImageUrl, croppedImageBase64] = await getCroppedImageUrl(imageRef, crop);
            onComplete(true, croppedImageUrl, croppedImageBase64);
        }
    };

    return (
        <Modal
            className="ImageCropModal"
            visible={visible}
            onCancel={() => dismiss()}
            headerText={'Crop image'}
            footer={
                <>
                    <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                    <SubmitButton
                        text={t('save_changes')}
                        type="primary"
                        onClick={() => submit()}
                    />
                </>
            }
        >
            <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onChange={onCropChange}
                circularCrop={circularCrop}
            />
        </Modal>
    );
};

export default ImageCropModal;
