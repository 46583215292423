import { Switch } from 'antd';
import React, { ReactElement } from 'react';
import './index.less';

interface Toggle {
    checked: boolean;
    onChange: (checked: boolean) => void;
}

interface ToggleableRowProps {
    className?: string;
    title: string | ReactElement;
    subTitle: string | ReactElement;
    toggle?: Toggle;
    bottomChildren?: ReactElement;
}

const ToggleableRow: React.FunctionComponent<ToggleableRowProps> = ({
    className,
    title,
    subTitle,
    toggle,
    bottomChildren,
    children,
}) => {
    return (
        <div className={`ToggleableRow ${className ?? ''}`}>
            <div className="left">
                {toggle && (
                    <Switch
                        checked={toggle.checked}
                        onChange={(checked) => toggle.onChange(checked)}
                    />
                )}

                <div>
                    <div className="title">{title}</div>
                    <div className="subTitle">{subTitle}</div>
                </div>
            </div>

            <div className="right">{children}</div>

            {bottomChildren && <div className="bottomChildren">{bottomChildren}</div>}
        </div>
    );
};

export default ToggleableRow;
