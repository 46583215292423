import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Content from 'Components/Content';
import Skeleton from 'Components/skeleton';
import {
    happeningDetailsSkeletonShape,
    happeningImageSkeletonShape,
} from 'Routes/settings/happenings/id/skeleton-shapes';
import { img, ImgSize } from 'Utils/ImgUtils';
import { getHappeningDefaultImg } from 'Utils/HappeningUtils';
import WhiteCard from 'Components/white-card';
import LabelValueComponent from 'Components/label-value-component';
import moment from 'moment';
import { CLUBS_URL, EVENTS_URL, FORMAT_SHORT_MONTH_DATE_TIME } from 'Models/Constants';
import { RichTextDisplay } from 'Components/rich-text-display/rich-text-display';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { HappeningService } from 'Services/HappeningService';
import './index.less';
import RSVPInfo from 'Components/rsvp-info';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import ClubIconColorLabel from 'Components/club-icon-color-label';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import Button from 'Components/button';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import CreateSocialEventModal, {
    SocialEventType,
} from 'Components/social-event-modal/create-social-event-modal';
import VerticalThreeDotMenuButton, {
    ThreeDotMenuOption,
} from 'Components/vertical-three-dot-menu-button';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';
import { SlackService } from 'Services/SlackService';
import PostOnSlackModal from '../components/post-slack-modal';
import { getGMTString } from 'Utils/TimeUtils';

const EventDetails: React.FunctionComponent = observer(() => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        toastStore,
        userStore,
        authorizationStore,
        confirmationModalStore,
        globalLoadingStore,
        languageStore,
    } = useStores();
    const happeningService = useService(HappeningService);
    const slackService = useService(SlackService);

    const [isSlackWorkspaceConnected, setIsSlackWorkspaceConnected] = useState(false);
    const [happeningDetails, setHappeningDetails] = useState<HappeningDto>();
    const [loading, setLoading] = useState(true);
    const [editClubGatheringModalVisible, setEditClubGatheringModalVisible] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isPast, setIsPast] = useState(false);
    const [menuOptions, setMenuOptions] = useState<ThreeDotMenuOption[]>([
        {
            title: t('edit'),
            onClick: () => setEditClubGatheringModalVisible(true),
        },
        {
            title: t('delete'),
            onClick: () => onDeleteEvent(),
            className: 'secondary',
        },
    ]);

    const [postOnSlackModalVisible, setPostOnSlackModalVisible] = useState(false);

    const fetchIsSlackWorkspaceConnected = useCallback(async (): Promise<void> => {
        try {
            setIsSlackWorkspaceConnected(await slackService.isSlackWorkspaceConnected());
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    }, [globalLoadingStore, slackService]);

    useEffect(() => {
        fetchIsSlackWorkspaceConnected();
    }, []);

    useEffect(() => {
        if (isSlackWorkspaceConnected) {
            setMenuOptions(() => [
                {
                    title: t('edit'),
                    onClick: () => setEditClubGatheringModalVisible(true),
                },
                {
                    title: t('Happening.happening_post_on_slack'),
                    onClick: () => setPostOnSlackModalVisible(true),
                },
                {
                    title: t('delete'),
                    onClick: () => onDeleteEvent(),
                    className: 'secondary',
                },
            ]);
        }
    }, [isSlackWorkspaceConnected]);

    useEffect(() => {
        if (happeningDetails?.club) {
            if (
                userStore.ManagementRole === ManagementRoleDto.Administrator ||
                userStore.userInfo?.id === happeningDetails.organizer?.id ||
                happeningDetails.club.owner === userStore.userInfo?.id
            ) {
                setIsEditable(true);
            }
        }
    }, [happeningDetails, userStore.userInfo]);

    const fetchHappening = useCallback(async () => {
        try {
            setLoading(true);
            const response: HappeningDto | null = await happeningService.getHappening(id, {
                favoritesForUserId: userStore.userInfo?.id,
            });

            if (response) {
                setHappeningDetails(response);

                const momentEventEndTimeEndOfDay = moment(response.endTime).endOf('day');
                setIsPast(moment().isAfter(momentEventEndTimeEndOfDay));
            }
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setLoading(false);
        }
    }, [userStore.userInfo?.id]);

    useEffect(() => {
        if (userStore.userInfo?.id) fetchHappening();
    }, [fetchHappening, userStore.userInfo?.id]);

    useEffect(() => {
        //Club gathering can only view if member of club or admin
        if (
            happeningDetails &&
            happeningDetails.club &&
            userStore.ManagementRole !== ManagementRoleDto.Administrator &&
            !userStore.userInfo?.clubs?.some((x) => x?.id === happeningDetails?.club?.id)
        ) {
            authorizationStore.setCurrentRequestForbidden();
        }
    }, [userStore.userInfo?.clubs, userStore.ManagementRole, happeningDetails]);

    const onDeleteEvent = async (): Promise<void> => {
        try {
            if (
                !(await confirmationModalStore.confirm({
                    icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                    title: t('Cta.delete_confirm_title', {
                        param1: t('Entity.lowercase_event'),
                        param2: 'cet',
                    }),
                    message: t('Cta.delete_confirm_message', {
                        param1: t('Entity.lowercase_event'),
                        param2: "L'",
                    }),
                    positiveText: t('confirm'),
                    negativeText: t('cancel'),
                }))
            )
                return;
            globalLoadingStore.addLoading();

            await happeningService.deleteHappening(id);

            toastStore.toast({
                type: 'success',
                messageKey: t('Toast.entity_action_success_message', {
                    param1: t('event'),
                    param2: t('Entity.lowercase_action_deleted'),
                }),
            });
            history.push(EVENTS_URL);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <div className="EventsUserDetails">
            <Content>
                <div className="page-content">
                    <Skeleton
                        placeholder={happeningImageSkeletonShape}
                        isLoaded={!loading}
                        imgsSrc={[
                            happeningDetails?.imageUrl
                                ? img(happeningDetails?.imageUrl, ImgSize.xl, false)
                                : getHappeningDefaultImg(happeningDetails?.attendanceType),
                        ]}
                    >
                        <div
                            className="d-flex top-section"
                            style={{ justifyContent: isEditable ? 'space-between' : 'center' }}
                        >
                            <div className="space" />
                            <div
                                className={`image-container ${isPast ? 'is-past' : ''}`}
                                style={{
                                    backgroundImage: `url(${
                                        happeningDetails?.imageUrl
                                            ? img(happeningDetails?.imageUrl, ImgSize.xl, false)
                                            : getHappeningDefaultImg(
                                                  happeningDetails?.attendanceType
                                              )
                                    })`,
                                    marginRight: isEditable ? -37 : undefined,
                                }}
                            />
                            {isEditable && <VerticalThreeDotMenuButton menuOptions={menuOptions} />}
                        </div>
                    </Skeleton>

                    <WhiteCard padding={40} className="details-container">
                        <Skeleton placeholder={happeningDetailsSkeletonShape} isLoaded={!loading}>
                            <div>
                                <div className="text-title-2-bold mb-20 d-flex-align">
                                    {happeningDetails?.name}
                                </div>

                                {happeningDetails?.club?.category &&
                                    ClubCategoryIconMap.has(happeningDetails?.club?.category) && (
                                        <div className="club-container">
                                            <ClubIconColorLabel
                                                clubCategory={happeningDetails.club.category}
                                                background
                                            >
                                                <div className="d-flex-align">
                                                    <div className="icon-container Shadow-100">
                                                        <ClubIconColorLabel
                                                            clubCategory={
                                                                happeningDetails.club.category
                                                            }
                                                            type="icon-color"
                                                        />
                                                    </div>

                                                    <div
                                                        className="club-info-container Shadow-700"
                                                        style={{
                                                            backgroundColor:
                                                                ClubCategoryIconMap.get(
                                                                    happeningDetails.club.category
                                                                )?.color,
                                                        }}
                                                    >
                                                        <span className="text-callout-bold">
                                                            {happeningDetails.club.name}
                                                        </span>
                                                        <div className="spacer" />
                                                        <span className="text-caption-2">
                                                            {t('Clubs.club_total_members')}:
                                                            <span className="text-caption-2-bold ml-5">
                                                                {happeningDetails.club.membersCount}
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <Button
                                                        className="undesign-systemed-btn"
                                                        text={t('view')}
                                                        type="light"
                                                        onClick={() =>
                                                            history.push(
                                                                `${CLUBS_URL}/${happeningDetails.club?.id}`
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </ClubIconColorLabel>
                                        </div>
                                    )}

                                <div className="text-headline-bold title">{t('details')}</div>
                                <div className="details">
                                    <LabelValueComponent
                                        label={t('date_time')}
                                        value={`${moment
                                            .tz(
                                                happeningDetails?.startTime,
                                                happeningDetails?.office?.timeZone ?? ''
                                            )
                                            .format(
                                                FORMAT_SHORT_MONTH_DATE_TIME[
                                                    languageStore.currentLanguage
                                                ]
                                            )} - ${moment
                                            .tz(
                                                happeningDetails?.endTime,
                                                happeningDetails?.office?.timeZone ?? ''
                                            )
                                            .format(
                                                FORMAT_SHORT_MONTH_DATE_TIME[
                                                    languageStore.currentLanguage
                                                ]
                                            )} (${getGMTString(
                                            happeningDetails?.office?.timeZone ?? ''
                                        )})`}
                                    />

                                    <LabelValueComponent
                                        label={t('attendance_type')}
                                        value={t(
                                            `HappeningAttendanceTypeDto.HappeningAttendanceTypeDto_${happeningDetails?.attendanceType}`
                                        )}
                                    />
                                    <LabelValueComponent
                                        label={t('office')}
                                        value={happeningDetails?.office?.name}
                                    />
                                    {happeningDetails?.capacity && (
                                        <LabelValueComponent
                                            label={t('capacity')}
                                            value={`${happeningDetails.capacity}`}
                                        />
                                    )}

                                    {happeningDetails?.club && (
                                        <LabelValueComponent
                                            label={t('organizer')}
                                            value={`${happeningDetails.organizer?.firstName} ${happeningDetails.organizer?.lastName}`}
                                        />
                                    )}
                                </div>

                                {(happeningDetails?.description || '')?.length > 0 && (
                                    <>
                                        <div className="text-headline-bold title">
                                            {t('description')}
                                        </div>

                                        <RichTextDisplay
                                            className="text-body"
                                            html={happeningDetails?.description}
                                        />
                                    </>
                                )}

                                {happeningDetails && (
                                    <RSVPInfo
                                        isPast={isPast}
                                        happening={happeningDetails}
                                        viewAllButton
                                        addToCalendarButtonProps={{
                                            color: theme['accent-mid-contrast'],
                                            width: 24,
                                        }}
                                    />
                                )}
                            </div>
                        </Skeleton>
                    </WhiteCard>
                </div>

                {editClubGatheringModalVisible && (
                    <CreateSocialEventModal
                        visible={editClubGatheringModalVisible}
                        clubId={happeningDetails?.club?.id}
                        socialEventType={SocialEventType.ClubGathering}
                        socialEvent={happeningDetails}
                        onComplete={(success?: boolean) => {
                            setEditClubGatheringModalVisible(false);
                            if (success) fetchHappening();
                        }}
                    />
                )}

                {postOnSlackModalVisible && (
                    <PostOnSlackModal
                        visible={postOnSlackModalVisible}
                        onComplete={() => setPostOnSlackModalVisible(false)}
                        happeningId={happeningDetails?.id ?? ''}
                        channel={happeningDetails?.slackChannel}
                    />
                )}
            </Content>
        </div>
    );
});

export default EventDetails;
