import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import Icon from 'Components/icons/Icon';
import BaseModalMobile from 'Components/modal/base-modal-mobile';
import { OfficeOccupancyCardWorkType } from 'Components/office-occupancy-card';
import WorktypeDropdownModalContent from 'Components/office-occupancy-card/modals/worktype-dropdown-modal-content';
import TooltipWrapper from 'Components/tooltip-wrapper';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

interface WorkTypeStatusProps {
    workTypes: WorkTypeDto[];
    type?: 'default' | 'centered-modal' | 'very-small';
    onClick?: () => void;
    onClickChangeWorkType?: (worktype: OfficeOccupancyCardWorkType) => void;
    tooltip?: {
        active: boolean;
        title: string;
    };
}

enum typeDayEnum {
    'hybrid',
    'away',
    'office',
    'remote',
}

const WorkTypeStatus: React.FunctionComponent<WorkTypeStatusProps> = ({
    workTypes,
    onClick,
    onClickChangeWorkType,
    type = 'default',
    tooltip,
}) => {
    const { t } = useTranslation();
    const [centeredModalOpen, setCenteredModalOpen] = useState(false);
    const [currentWorkType] = useState(workTypes);

    const getTypeDay = useMemo(() => {
        const containOfficeWorkTypeDto = currentWorkType.includes(WorkTypeDto.Office);
        const containRemoteWorkTypeDto = currentWorkType.includes(WorkTypeDto.Remote);
        const containAwayWorkTypeDto = currentWorkType.includes(WorkTypeDto.Away);
        const containUnavailableWorkTypeDto = currentWorkType.includes(WorkTypeDto.Unavailable);

        if (containOfficeWorkTypeDto && containRemoteWorkTypeDto) return typeDayEnum.hybrid;
        if (containOfficeWorkTypeDto) return typeDayEnum.office;
        if (containRemoteWorkTypeDto) return typeDayEnum.remote;
        if (containAwayWorkTypeDto) return typeDayEnum.away;
        if (containUnavailableWorkTypeDto) return typeDayEnum.away;

        return typeDayEnum.away;
    }, [currentWorkType]);

    const getLabelWorkTypeStatus = (): string => {
        const typeDay = getTypeDay;

        if (typeDay === typeDayEnum.hybrid) return t('hybrid');
        if (typeDay === typeDayEnum.away) return t('away');
        if (typeDay === typeDayEnum.office) return t('office');
        if (typeDay === typeDayEnum.remote) return t('remote');

        return t('away');
    };

    const IconWorkTypeStatus: React.FunctionComponent = () => {
        const typeDay = getTypeDay;

        if (typeDay === typeDayEnum.hybrid) return <Icon iconName="HouseBuildingIcon" />;
        if (typeDay === typeDayEnum.away) return <Icon iconName="SunIcon" />;
        if (typeDay === typeDayEnum.office) return <Icon iconName="AppartementIcon" />;
        if (typeDay === typeDayEnum.remote) return <Icon iconName="SimpleHouseIcon" />;

        return <Icon iconName="SunIcon" />;
    };

    if (type === 'very-small') {
        return (
            <div className="WorkTypeStatus very-small">
                <TooltipWrapper title={tooltip?.title} placement="top">
                    <IconWorkTypeStatus />
                </TooltipWrapper>
            </div>
        );
    }

    return type === 'default' ? (
        <div className="WorkTypeStatus" onClick={onClick}>
            <IconWorkTypeStatus />
            <span className="label">{getLabelWorkTypeStatus()}</span>
        </div>
    ) : (
        <div className="WorkTypeStatus centered-modal">
            <div
                className={`cta-row cta-worktype cta-worktype-disabled-${
                    currentWorkType.includes(WorkTypeDto.Unavailable) ||
                    currentWorkType.includes(WorkTypeDto.Away)
                }`}
                onClick={
                    currentWorkType.includes(WorkTypeDto.Unavailable) ||
                    currentWorkType.includes(WorkTypeDto.Away)
                        ? undefined
                        : () => setCenteredModalOpen(true)
                }
            >
                <IconWorkTypeStatus />
            </div>

            <BaseModalMobile
                className="WorkTypeStatusCenteredModal"
                visible={centeredModalOpen}
                onCancel={() => setCenteredModalOpen(false)}
                maskClosable
            >
                <WorktypeDropdownModalContent
                    options={[
                        {
                            worktype: OfficeOccupancyCardWorkType.Office,
                            iconName: 'AppartementIcon',
                        },
                        {
                            worktype: OfficeOccupancyCardWorkType.Remote,
                            iconName: 'SimpleHouseIcon',
                        },
                        { worktype: OfficeOccupancyCardWorkType.Away, iconName: 'SunIcon' },
                    ]}
                    onClick={(workType: OfficeOccupancyCardWorkType) => {
                        if (onClickChangeWorkType) {
                            onClickChangeWorkType(workType);
                            setCenteredModalOpen(false);
                        }
                    }}
                />
            </BaseModalMobile>
        </div>
    );
};

export default WorkTypeStatus;
