import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ExclamationMarkCircleIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M13.9942 26C20.5713 26 26 20.5687 26 14C26 7.43133 20.5597 2 13.9826 2C7.41711 2 2 7.43133 2 14C2 20.5687 7.42871 26 13.9942 26ZM13.9942 23.6093C8.66989 23.6093 4.41276 19.3269 4.41276 14C4.41276 8.67311 8.66989 4.40232 13.9826 4.40232C19.3069 4.40232 23.5872 8.67311 23.5988 14C23.6104 19.3269 19.3185 23.6093 13.9942 23.6093ZM13.9826 15.9265C14.6206 15.9265 14.9918 15.5667 15.0034 14.8936L15.1774 9.31141C15.2006 8.62669 14.6902 8.12766 13.971 8.12766C13.2518 8.12766 12.753 8.61509 12.7762 9.29981L12.9386 14.8936C12.9618 15.5551 13.333 15.9265 13.9826 15.9265ZM13.9826 19.7795C14.7366 19.7795 15.3514 19.234 15.3514 18.4913C15.3514 17.7602 14.7482 17.2147 13.9826 17.2147C13.2286 17.2147 12.6138 17.7602 12.6138 18.4913C12.6138 19.2224 13.2402 19.7795 13.9826 19.7795Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ExclamationMarkCircleIcon;
