import PolicyObjectiveCircleIcon from 'Components/policyObjectiveCircleIcon';
import TooltipWrapper from 'Components/tooltip-wrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyBundleObjectivesDetails } from 'Stores/PolicyStore';
import { theme } from 'Style/theme';
import { objectivePopoverContent } from 'Utils/PolicyObjectivesUtils';
import './index.less';

interface ObjectivesSummaryProps {
    policyBundleObjectivesDetails: PolicyBundleObjectivesDetails[];
}

const ObjectivesSummary: React.FunctionComponent<ObjectivesSummaryProps> = ({
    policyBundleObjectivesDetails,
}) => {
    const { t } = useTranslation();

    return (
        <div className="ObjectivesSummary">
            {policyBundleObjectivesDetails.map((policy, key) => {
                const progression = policy.completed;
                const displayProgression = progression >= policy.total ? policy.total : progression;

                return (
                    <TooltipWrapper
                        placement="left"
                        title={() => objectivePopoverContent(policy, t)}
                        key={key}
                        backgroundColor={theme['primary-low-contrast']}
                        textColor={theme['text-high-contrast']}
                        borderRadius={5}
                        padding={[10, 20, 12, 20]}
                        width={453}
                    >
                        <div key={key} className="container-policy">
                            <div className="policy-label">
                                {t(
                                    `PolicyBundleObjectivesDto.PolicyBundleObjectivesDto_${policy.label}`
                                )}
                            </div>
                            <div className="right">
                                <div className="text-caption-2-bold policy-value">
                                    {displayProgression} / {policy.total}
                                </div>
                                <PolicyObjectiveCircleIcon
                                    policyKey={policy.label}
                                    policyCurrentValue={policy.completed}
                                    policyGoal={policy.total}
                                    width={25}
                                    iconSize={15}
                                />
                            </div>
                        </div>
                    </TooltipWrapper>
                );
            })}
        </div>
    );
};

export default ObjectivesSummary;
