import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AppleIcon: FunctionComponent<SvgProps> = ({ onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M19.4958 14.0607C19.4864 12.3458 20.2732 11.0514 21.8657 10.0981C20.9746 8.84112 19.6285 8.14953 17.8511 8.01402C16.1685 7.88318 14.3295 8.98131 13.6565 8.98131C12.9455 8.98131 11.315 8.06075 10.0353 8.06075C7.39051 8.1028 4.57983 10.1402 4.57983 14.285C4.57983 15.5093 4.80734 16.7741 5.26236 18.0794C5.86905 19.7944 8.05881 24 10.3434 23.9299C11.5378 23.9019 12.3815 23.0935 13.9361 23.0935C15.4433 23.0935 16.2254 23.9299 17.5573 23.9299C19.8608 23.8972 21.842 20.0748 22.4203 18.3551C19.3299 16.9206 19.4958 14.1495 19.4958 14.0607ZM16.8131 6.38785C18.1071 4.87383 17.9886 3.49533 17.9507 3C16.8084 3.06542 15.486 3.76636 14.7324 4.63084C13.9029 5.55607 13.4147 6.70093 13.519 7.99065C14.7561 8.08411 15.8841 7.45794 16.8131 6.38785Z"
                    fill="black"
                />
            </svg>
        </span>
    );
};

export default AppleIcon;
