import { Table, TablePaginationConfig } from 'antd';
import { ColumnType } from 'antd/es/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { GetHappeningsRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningsRequestDto';
import { GetHappeningsSortColumnDto } from 'Api/Features/Happenings/Dtos/GetHappeningsSortColumnDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { HappeningStatusDto } from 'Api/Features/Happenings/Dtos/HappeningStatusDto';
import Content from 'Components/Content';
import Skeleton from 'Components/skeleton';
import TableFilters from 'Components/table-filters/table-filters';
import TablePageSecondHeader from 'Components/table-page-second-header';
import { useApiRequestHistory, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    DEBOUNCE_DELAY_400,
    HAPPENINGS_URL,
    SETTINGS_HAPPENINGS_URL_REGEX,
    SETTINGS_URL,
    FORMAT_SHORT_MONTH_DATE_TIME,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { initialPaginationState } from 'Models/InitialPaginationState';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HappeningService } from 'Services/HappeningService';
import { FilterStore } from 'Stores';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import { getHappeningStatusTag } from 'Utils/HappeningUtils';
import { tableSkeletonShape } from '../skeleton-shapes';
import CreateSocialEventModal, {
    SocialEventType,
} from '../../../Components/social-event-modal/create-social-event-modal';
import './index.less';
import { HappeningFilterModeDto } from 'Api/Features/Happenings/Dtos/HappeningFilterModeDto';
import { getGMTString } from 'Utils/TimeUtils';

const getAdvancedFilters = (t: Function): AdvancedFilter[] => [
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: HappeningStatusDto.Ongoing,
                checked: true,
                displayNameKey: t('ongoing'),
            },
            {
                key: HappeningStatusDto.Upcoming,
                checked: true,
                displayNameKey: t('upcoming'),
            },
            {
                key: HappeningStatusDto.Past,
                checked: false,
                displayNameKey: t('ended'),
            },
        ],
    },
];

const SettingsHappenings: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const happeningService = useService(HappeningService);
    const { toastStore, languageStore } = useStores();
    const advancedFilters = getAdvancedFilters(t);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
    const [loading, setLoading] = useState(false);
    const [happeningList, setHappeningList] = useState<HappeningDto[]>();
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const history = useHistory();
    const paginationRef = useRef(initialPaginationState);
    const [selectedOfficeIdsFromCache, setSelectedOfficeIdsFromCache] = useState<
        string[] | undefined
    >();
    const [createModalvisible, setCreateModalVisible] = useState(false);
    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex: SETTINGS_HAPPENINGS_URL_REGEX,
            requestType: RequestType.Happenings,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            const filterStore = filterStoreRef.current;
            const request: GetHappeningsRequestDto = history.request;

            const filtersList = [{ key: request?.statuses || [], parentKey: 'status' }];
            filterStore.tickMultipleAdvancedFilter(filtersList);

            filterStore.searchTerm = request.searchTerm ?? '';

            filterStore.officeIds = request.officeIds ?? [];
            setSelectedOfficeIdsFromCache(request.officeIds ?? undefined);

            paginationRef.current = {
                ...paginationRef.current,
                current: request.page ? request.page + 1 : 1,
                pageSize: request.pageSize ?? initialPaginationState.pageSize,
            };
        },
    });

    const columns: ColumnType<HappeningDto>[] = [
        {
            title: t('Happening.happening_name'),
            render: (happening: HappeningDto) => (
                <div className="TdWithTag">
                    <span className="title text-callout">{happening.name}</span>
                    {getHappeningStatusTag(happening, t)}
                </div>
            ),
            key: GetHappeningsSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('date_time'),
            render: (happening: HappeningDto) =>
                `${moment
                    .tz(happening.startTime, happening.office?.timeZone ?? '')
                    .format(FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage])} - ${moment
                    .tz(happening.endTime, happening.office?.timeZone ?? '')
                    .format(
                        FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage]
                    )} (${getGMTString(happening.office?.timeZone ?? '')})`,
            key: GetHappeningsSortColumnDto.StartTime,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('location'),
            render: (happening: HappeningDto) => happening.office?.name,
            key: GetHappeningsSortColumnDto.OfficeName,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('attendance_type'),
            render: (happening: HappeningDto) =>
                t(
                    `HappeningAttendanceTypeDto.HappeningAttendanceTypeDto_${happening.attendanceType}`
                ),
            key: GetHappeningsSortColumnDto.AttendanceType,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('Happening.happening_attendees_number'),
            render: (happening: HappeningDto) =>
                happening.attendeesEnabled ? happening.attendeesCount : t('n/a'),
            key: GetHappeningsSortColumnDto.NumberOfAttendees,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
    ];

    const onRowClick = (happening: HappeningDto) => {
        history.push(SETTINGS_URL + HAPPENINGS_URL + `/${happening.id}`);
    };

    const getAdvancedFilterStatus = (): {
        checkedStatuses: HappeningStatusDto[];
        statusesCount: number;
    } => {
        const filterStore = filterStoreRef.current;
        const statuses = filterStore.advancedFilters?.find(
            (filter: AdvancedFilter) => filter.key === 'status'
        );
        const checkedStatuses = statuses?.items
            .filter((status) => status.checked)
            .map((status) => {
                return status.key;
            });

        return {
            checkedStatuses:
                checkedStatuses?.map(
                    (status) => HappeningStatusDto[status as keyof typeof HappeningStatusDto]
                ) ?? [],
            statusesCount: statuses?.items.length ?? 0,
        };
    };

    const fetchHappenings = useCallback(
        async (params: {
            officeIds: string[];
            advancedFilters?: AdvancedFilter[];
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: GetHappeningsSortColumnDto | null;
            sortDirection: SortDirection | null;
        }) => {
            setLoading(true);

            try {
                const statuses = getAdvancedFilterStatus();
                if (statuses.checkedStatuses.length === 0) {
                    setHappeningList([]);
                } else {
                    const request = setRequestHistory({
                        request: {
                            pageSize: params.pagination.pageSize || 0,
                            page: (params.pagination.current || 1) - 1,
                            searchTerm: params.searchTerm,
                            sortColumn: params.sortColumn,
                            sortDirection: params.sortDirection,
                            officeIds: params.officeIds,
                            statuses: statuses.checkedStatuses,
                            filterMode: HappeningFilterModeDto.General,
                        } as GetHappeningsRequestDto,
                        requestType: RequestType.Happenings,
                    });

                    const [items, totalItemsCount] = await happeningService.getHappenings(request);

                    setHappeningList(items);
                    setPagination({
                        ...params.pagination,
                        total: totalItemsCount,
                    });
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [happeningService]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }

        const { searchTerm, officeIds } = filterStoreRef.current;

        await fetchHappenings({
            officeIds,
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                officesIds: string[];
                searchTerm?: string;
                advancedFilters?: AdvancedFilter[];
            }) => {
                fetchHappenings({
                    officeIds: params.officesIds,
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    advancedFilters: params.advancedFilters,
                    searchTerm: params.searchTerm,
                    sortColumn: null,
                    sortDirection: null,
                });
            },
            DEBOUNCE_DELAY_400
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                officesIds: filterStore.officeIds,
                advancedFilters: filterStore.advancedFilters,
                searchTerm: filterStore.searchTerm,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    return (
        <div className="Happennings">
            <TablePageSecondHeader
                title={t('happenings')}
                createBtnString={t('create_entity', {
                    param1: t('happening'),
                    param2: 'un',
                    param3: t('Entity.lowercase_happening'),
                })}
                onCreateBtnClick={() => setCreateModalVisible(true)}
                isLoaded={true}
            />
            <Content>
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeAdvancedFilters
                    includeOfficeSelect
                    advancedFiltersDefaultValue={() => getAdvancedFilters(t)}
                    defaultSelectedOfficeIds={selectedOfficeIdsFromCache}
                    isLoaded={(happeningList?.length || 0) > 0 ? true : !loading}
                />

                <Skeleton
                    placeholder={tableSkeletonShape}
                    isLoaded={(happeningList?.length || 0) > 0 ? true : !loading}
                >
                    <Table
                        className="table-action-rows table-striped-rows"
                        dataSource={happeningList}
                        columns={columns}
                        loading={loading}
                        rowKey={(happening): string => happening.id ?? ''}
                        pagination={pagination}
                        onChange={handleTableChange}
                        onRow={(row: HappeningDto) => ({
                            onClick: (): void => {
                                onRowClick(row);
                            },
                        })}
                    />
                </Skeleton>
            </Content>

            {createModalvisible && (
                <CreateSocialEventModal
                    onComplete={(success?: boolean, id?: string) => {
                        setCreateModalVisible(false);
                        if (success && id) history.push(SETTINGS_URL + HAPPENINGS_URL + `/${id}`);
                    }}
                    visible={createModalvisible}
                    socialEventType={SocialEventType.Happening}
                />
            )}
        </div>
    );
});

export default SettingsHappenings;
