import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import {
    AuthenticationStore,
    AuthorizationStore,
    ConfirmationModalStore,
    LanguageStore,
    LoadingStore,
    NavigationStore,
    PolicyStore,
    RequestHistoryStore,
    SlackParametersStore,
    SystemStore,
    ToastStore,
    UserStore,
} from 'Stores';

export interface GlobalStores {
    authenticationStore: AuthenticationStore;
    authorizationStore: AuthorizationStore;
    confirmationModalStore: ConfirmationModalStore;
    languageStore: LanguageStore;
    globalLoadingStore: LoadingStore;
    navigationStore: NavigationStore;
    policyStore: PolicyStore;
    requestHistoryStore: RequestHistoryStore;
    slackParametersStore: SlackParametersStore;
    systemStore: SystemStore;
    toastStore: ToastStore;
    userStore: UserStore;
}

@inject(
    AuthenticationStore,
    AuthorizationStore,
    ConfirmationModalStore,
    LanguageStore,
    LoadingStore,
    NavigationStore,
    PolicyStore,
    RequestHistoryStore,
    SlackParametersStore,
    SystemStore,
    ToastStore,
    UserStore
)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(
        authenticationStore: AuthenticationStore,
        authorizationStore: AuthorizationStore,
        confirmationModalStore: ConfirmationModalStore,
        languageStore: LanguageStore,
        globalLoadingStore: LoadingStore,
        navigationStore: NavigationStore,
        policyStore: PolicyStore,
        requestHistoryStore: RequestHistoryStore,
        slackParametersStore: SlackParametersStore,
        systemStore: SystemStore,
        toastStore: ToastStore,
        userStore: UserStore
    ) {
        this.context = React.createContext({
            authenticationStore,
            authorizationStore,
            confirmationModalStore,
            languageStore,
            globalLoadingStore,
            navigationStore,
            policyStore,
            requestHistoryStore,
            slackParametersStore,
            systemStore,
            toastStore,
            userStore,
        });
    }
}
