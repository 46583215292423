import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const SlackTextIcon: FunctionComponent<SvgProps> = ({ onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="75" height="21" viewBox="0 0 75 21" fill="none" {...props}>
                <path
                    d="M23.86 15.9585L24.7916 13.7491C25.7983 14.5163 27.1355 14.9152 28.4578 14.9152C29.4344 14.9152 30.0504 14.5316 30.0504 13.9486C30.0354 12.3222 24.2056 13.5957 24.1606 9.51442C24.1455 7.44309 25.9485 5.8474 28.5028 5.8474C30.0204 5.8474 31.5379 6.23098 32.6197 7.10554L31.7483 9.36098C30.7566 8.71657 29.5245 8.25628 28.3526 8.25628C27.5562 8.25628 27.0304 8.63986 27.0304 9.13084C27.0454 10.7265 32.9202 9.85196 32.9803 13.7491C32.9803 15.8665 31.2224 17.3548 28.6982 17.3548C26.8501 17.3548 25.1522 16.9098 23.86 15.9585ZM59.605 12.9513C59.1392 13.7798 58.2677 14.3475 57.2611 14.3475C55.7736 14.3475 54.5715 13.1201 54.5715 11.6011C54.5715 10.0821 55.7736 8.85466 57.2611 8.85466C58.2677 8.85466 59.1392 9.42236 59.605 10.2509L62.1743 8.79329C61.2127 7.04417 59.3646 5.8474 57.2611 5.8474C54.1508 5.8474 51.6266 8.42505 51.6266 11.6011C51.6266 14.7771 54.1508 17.3548 57.2611 17.3548C59.3796 17.3548 61.2127 16.1733 62.1743 14.4089L59.605 12.9513ZM34.2725 1.07568H37.4879V17.14H34.2725V1.07568ZM63.4364 1.07568V17.14H66.6518V12.3222L70.4682 17.14H74.5851L69.732 11.417L74.2245 6.07755H70.2879L66.6518 10.5117V1.07568H63.4364ZM47.0439 12.982C46.5781 13.7645 45.6165 14.3475 44.5347 14.3475C43.0472 14.3475 41.8452 13.1201 41.8452 11.6011C41.8452 10.0821 43.0472 8.85466 44.5347 8.85466C45.6165 8.85466 46.5781 9.46839 47.0439 10.2662V12.982ZM47.0439 6.07755V7.38172C46.518 6.47647 45.2108 5.8474 43.8436 5.8474C41.0188 5.8474 38.7951 8.39437 38.7951 11.5857C38.7951 14.7771 41.0188 17.3548 43.8436 17.3548C45.2108 17.3548 46.518 16.7257 47.0439 15.8204V17.1246H50.2593V6.07755H47.0439Z"
                    fill="black"
                />
                <path
                    d="M3.98161 12.9667C3.98161 14.0868 3.09512 14.992 1.99828 14.992C0.90144 14.992 0.0149536 14.0868 0.0149536 12.9667C0.0149536 11.8467 0.90144 10.9414 1.99828 10.9414H3.98161V12.9667ZM4.97327 12.9667C4.97327 11.8467 5.85975 10.9414 6.95659 10.9414C8.05343 10.9414 8.93992 11.8467 8.93992 12.9667V18.0299C8.93992 19.15 8.05343 20.0552 6.95659 20.0552C5.85975 20.0552 4.97327 19.15 4.97327 18.0299V12.9667Z"
                    fill="#E01E5A"
                />
                <path
                    d="M6.95667 4.83477C5.85983 4.83477 4.97334 3.92953 4.97334 2.80948C4.97334 1.68943 5.85983 0.78418 6.95667 0.78418C8.0535 0.78418 8.93999 1.68943 8.93999 2.80948V4.83477H6.95667ZM6.95667 5.86276C8.0535 5.86276 8.93999 6.76801 8.93999 7.88806C8.93999 9.00811 8.0535 9.91335 6.95667 9.91335H1.98333C0.886486 9.91335 0 9.00811 0 7.88806C0 6.76801 0.886486 5.86276 1.98333 5.86276H6.95667Z"
                    fill="#36C5F0"
                />
                <path
                    d="M14.905 7.88806C14.905 6.76801 15.7915 5.86276 16.8884 5.86276C17.9852 5.86276 18.8717 6.76801 18.8717 7.88806C18.8717 9.00811 17.9852 9.91335 16.8884 9.91335H14.905V7.88806ZM13.9134 7.88806C13.9134 9.00811 13.0269 9.91335 11.93 9.91335C10.8332 9.91335 9.94672 9.00811 9.94672 7.88806V2.80948C9.94672 1.68943 10.8332 0.78418 11.93 0.78418C13.0269 0.78418 13.9134 1.68943 13.9134 2.80948V7.88806Z"
                    fill="#2EB67D"
                />
                <path
                    d="M11.93 16.0046C13.0269 16.0046 13.9134 16.9099 13.9134 18.0299C13.9134 19.15 13.0269 20.0552 11.93 20.0552C10.8332 20.0552 9.94672 19.15 9.94672 18.0299V16.0046H11.93ZM11.93 14.992C10.8332 14.992 9.94672 14.0868 9.94672 12.9667C9.94672 11.8467 10.8332 10.9414 11.93 10.9414H16.9034C18.0002 10.9414 18.8867 11.8467 18.8867 12.9667C18.8867 14.0868 18.0002 14.992 16.9034 14.992H11.93Z"
                    fill="#ECB22E"
                />
            </svg>
        </span>
    );
};

export default SlackTextIcon;
