import React, { FunctionComponent } from 'react';
import './view-type-toggle.less';
import { BulletListIcon, FloorPlanIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import TooltipWrapper from 'Components/tooltip-wrapper';
import { useTranslation } from 'react-i18next';

interface ViewTypeToggleProps {
    selected: SpaceViewerViewType;
    onChange: (selected: SpaceViewerViewType) => void;
    floorPlanAvailable: boolean;
}

export enum SpaceViewerViewType {
    SpaceViewer = 'SpaceViewer',
    FloorPlan = 'FloorPlan',
}

const ViewTypeToggle: FunctionComponent<ViewTypeToggleProps> = ({
    selected,
    onChange,
    floorPlanAvailable,
}) => {
    const { t } = useTranslation();
    return (
        <div className="ViewTypeToggle">
            <div
                className={`${selected === SpaceViewerViewType.SpaceViewer && 'selected'}`}
                onClick={() => onChange(SpaceViewerViewType.SpaceViewer)}
            >
                <BulletListIcon width={24} height={24} />
            </div>
            <div
                className={`${selected === SpaceViewerViewType.FloorPlan && 'selected'}`}
                onClick={() =>
                    floorPlanAvailable ? onChange(SpaceViewerViewType.FloorPlan) : undefined
                }
            >
                <TooltipWrapper
                    title={floorPlanAvailable ? undefined : t('FloorPlan.no_floor_plan')}
                    placement={'top'}
                    backgroundColor={theme['layout-high-contrast']}
                    textColor={theme['text-low-contrast']}
                >
                    <FloorPlanIcon
                        width={24}
                        height={24}
                        fill={floorPlanAvailable ? undefined : theme['text-disabled']}
                    />
                </TooltipWrapper>
            </div>
        </div>
    );
};

export default ViewTypeToggle;
