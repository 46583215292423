import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { OfficeOccupancyCardWorkType } from 'Components/office-occupancy-card';

export const WorkTypeDtoToWorkType = (
    worktypeToTransform: WorkTypeDto
): OfficeOccupancyCardWorkType => {
    if (worktypeToTransform === WorkTypeDto.Away) return OfficeOccupancyCardWorkType.Away;
    if (worktypeToTransform === WorkTypeDto.Office) return OfficeOccupancyCardWorkType.Office;
    if (worktypeToTransform === WorkTypeDto.Remote) return OfficeOccupancyCardWorkType.Remote;
    if (worktypeToTransform === WorkTypeDto.Unavailable)
        return OfficeOccupancyCardWorkType.Unavailable;

    return OfficeOccupancyCardWorkType.Unavailable;
};

export const WorkTypeToWorkTypeDto = (
    worktypeToTransform: OfficeOccupancyCardWorkType
): WorkTypeDto => {
    if (worktypeToTransform === OfficeOccupancyCardWorkType.Away) return WorkTypeDto.Away;
    if (worktypeToTransform === OfficeOccupancyCardWorkType.Office) return WorkTypeDto.Office;
    if (worktypeToTransform === OfficeOccupancyCardWorkType.Remote) return WorkTypeDto.Remote;
    if (worktypeToTransform === OfficeOccupancyCardWorkType.Unavailable)
        return WorkTypeDto.Unavailable;

    return WorkTypeDto.Unavailable;
};
