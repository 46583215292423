import { GetHappeningsRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningsRequestDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { HappeningFilterModeDto } from 'Api/Features/Happenings/Dtos/HappeningFilterModeDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { GetOfficesSortColumnDto } from 'Api/Features/Offices/Dtos/GetOfficesSortColumnDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { useApiRequestHistory, useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import {
    DEBOUNCE_DELAY_400,
    EVENTS_URL_REGEX,
    ABORT_CONTROLLER_ABORTED_ERROR,
    PAGE_SIZE_INFINITE_LOADING,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HappeningService } from 'Services/HappeningService';
import { OfficeService } from 'Services/OfficeService';
import './index.less';
import { Content } from 'antd/lib/layout/layout';
import EventUI from './ui';
import { HappeningStatusDto } from 'Api/Features/Happenings/Dtos/HappeningStatusDto';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import HorizontalRadio from 'Components/horizontal-radio';
import { autorun } from 'mobx';
import debounce from 'lodash.debounce';

enum EventTabs {
    NowUpcoming = '1',
    Past = '2',
}

const Events: React.FunctionComponent = observer(() => {
    const abortControllerRef = useRef<any>(null);
    const [currentTab, setCurrentTab] = useState(EventTabs.NowUpcoming);

    const { t } = useTranslation();
    // service
    const happeningService = useService(HappeningService);
    const officesService = useService(OfficeService);

    const { toastStore, userStore } = useStores();

    const [isLoading, setIsLoading] = useState(true);

    const setRequestHistory = useApiRequestHistory({
        useHistoryTrigger: {
            urlRegex: EVENTS_URL_REGEX,
            requestType: RequestType.Happenings,
        },
        useHistoryCallback: (history: HistoryRequest) => {
            onChangeTabs(history.request.currentTab);
            setSelectedEventTypeFilter(history.request.typeDropdown);
            officeSelectProps.onChange(history.request.officeDropdown);
        },
    });

    // happenings
    const [happenings, setHappenings] = useState<HappeningDto[]>([]);
    const [selectedEventTypeFilter, setSelectedEventTypeFilter] = useState<
        SingleSelectCustomOption | undefined
    >({
        label: t(
            `HappeningFilterModeDto.HappeningFilterModeDto_${HappeningFilterModeDto.GeneralAndUserClubs}`
        ),
        value: HappeningFilterModeDto.GeneralAndUserClubs,
    });

    // happenings pagination
    const [happeningsPaginationCurrentPage, setHappeningsPaginationCurrentPage] = useState(0);
    const [happeningsPaginationTotalItemCount, setHappeningsPaginationTotalItemCount] = useState(0);

    // offices
    const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
        fetchProps: {
            fetchFunction: async (request: GetOfficesRequestDto) =>
                await officesService.getOffices(request),
            fetchFunctionExtraParams: {
                sortColumn: GetOfficesSortColumnDto.IsUserDefaultOffice,
                workplacesForUserId: userStore.userInfo?.id,
            },
        },
        entityToSingleSelectCustomOption: (office: OfficeDto) =>
            ({ value: office.id, label: office.name } as SingleSelectCustomOption),
        extraMandatoryOptions: {
            unshift: [
                {
                    value: 'all',
                    label: t('all_offices'),
                },
            ],
        },
        defaultSelectedOption: { value: 'all', label: t('all_offices') },
    });

    // happenings
    const fetchHappenings = useCallback(
        async (
            page: number,
            currentTab: EventTabs,
            eventTypeFilter: SingleSelectCustomOption | undefined,
            office?: SingleSelectCustomOption | undefined
        ) => {
            if (!userStore.userInfo?.id) return;

            setIsLoading(true);
            try {
                const request: GetHappeningsRequestDto = setRequestHistory({
                    request: {
                        officeIds: office && office.value !== 'all' ? [office.value] : undefined,
                        page: page,
                        pageSize: PAGE_SIZE_INFINITE_LOADING,
                        minEndTime:
                            currentTab === EventTabs.NowUpcoming
                                ? moment().startOf('day').format()
                                : undefined,
                        statuses:
                            currentTab === EventTabs.Past ? [HappeningStatusDto.Past] : undefined,
                        favoritesForUserId: userStore?.userInfo?.id || '',
                        filterMode: eventTypeFilter?.value,
                        clubsForUserId: userStore.userInfo?.id,
                        sortDirection:
                            currentTab === EventTabs.Past
                                ? SortDirection.Descending
                                : SortDirection.Ascending,
                        /*api does not use these, this is for us in useHistoryCallback*/
                        currentTab: currentTab,
                        typeDropdown: eventTypeFilter,
                        officeDropdown: office,
                    },
                    requestType: RequestType.Happenings,
                });
                const abortController = new AbortController();
                abortControllerRef.current = abortController;

                const signal = abortController.signal;
                happeningService.addAbortSignal(signal);

                const [items, totalItemCount] = await happeningService.getHappenings(request);
                setHappeningsPaginationTotalItemCount(totalItemCount);
                setHappenings((prev: HappeningDto[]) => {
                    return [...prev, ...items];
                });
                setIsLoading(false);
            } catch (err: any) {
                if (!err.treated) toastStore.genericError();
                if (err?.code !== ABORT_CONTROLLER_ABORTED_ERROR) setIsLoading(false);
            }
        },
        [happeningsPaginationTotalItemCount, userStore?.userInfo?.id]
    );

    useEffect(() => {
        resetHappeningsSearchResults();
    }, [selectedEventTypeFilter]);

    useEffect(() => {
        resetHappeningsSearchResults();
    }, [officeSelectProps.selected]);

    const resetHappeningsSearchResults = () => {
        if (abortControllerRef.current) abortControllerRef.current.abort();
        setHappenings([]);
        setHappeningsPaginationTotalItemCount(0);
        setHappeningsPaginationCurrentPage(0);
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                currentPage: number;
                selectedTab: EventTabs;
                eventTypeFilter: SingleSelectCustomOption | undefined;
                office: SingleSelectCustomOption | undefined;
            }) => {
                fetchHappenings(
                    params.currentPage,
                    params.selectedTab,
                    params.eventTypeFilter,
                    params.office
                );
            },
            DEBOUNCE_DELAY_400
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            debounceSearch.current({
                currentPage: happeningsPaginationCurrentPage,
                selectedTab: currentTab,
                eventTypeFilter: selectedEventTypeFilter,
                office: officeSelectProps.selectedOption,
            });
        });

        return (): void => {
            disposer();
        };
    }, [
        debounceSearch,
        currentTab,
        selectedEventTypeFilter,
        officeSelectProps.selected,
        happeningsPaginationCurrentPage,
    ]);

    const handleScrollToBottom = () => {
        const maxIsAlreadyLoaded =
            happeningsPaginationTotalItemCount > 0
                ? happenings.length >= happeningsPaginationTotalItemCount
                : false;
        if (maxIsAlreadyLoaded) return;

        setHappeningsPaginationCurrentPage((prev) => prev + 1);
    };

    const onChangeTabs = (activeKey: EventTabs) => {
        resetHappeningsSearchResults();
        setCurrentTab(activeKey);
    };

    return (
        <div className="Happenings">
            <div className="tabs-header">
                <HorizontalRadio
                    name={'clubTab'}
                    defaultValue={currentTab}
                    options={[
                        {
                            label: t('Events.Events_now_upcoming'),
                            value: EventTabs.NowUpcoming,
                            countIsLoading: false,
                        },
                        {
                            label: t('Events.Events_past'),
                            value: EventTabs.Past,
                            countIsLoading: false,
                        },
                    ]}
                    onChange={(value) => {
                        onChangeTabs(value as EventTabs);
                    }}
                />
            </div>

            {currentTab === EventTabs.NowUpcoming ? (
                <Content>
                    <EventUI
                        isLoading={isLoading}
                        setSelectedEventTypeFilter={setSelectedEventTypeFilter}
                        selectedEventTypeFilter={selectedEventTypeFilter}
                        asyncSingleSelectProps={officeSelectProps}
                        happenings={happenings}
                        handleScrollToBottom={handleScrollToBottom}
                        isPast={false}
                    />
                </Content>
            ) : (
                <Content>
                    <EventUI
                        isLoading={isLoading}
                        setSelectedEventTypeFilter={setSelectedEventTypeFilter}
                        selectedEventTypeFilter={selectedEventTypeFilter}
                        asyncSingleSelectProps={officeSelectProps}
                        happenings={happenings}
                        handleScrollToBottom={handleScrollToBottom}
                        isPast={true}
                    />
                </Content>
            )}
        </div>
    );
});

export default Events;
