import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const OutlookIcon: FunctionComponent<SvgProps> = ({ onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M21.1134 4.3125H10.296C10.0617 4.3125 9.83702 4.40957 9.67134 4.58236C9.50567 4.75516 9.4126 4.98951 9.4126 5.23388V6.28125L15.5097 8.25L21.9968 6.28125V5.23388C21.9968 4.98951 21.9038 4.75516 21.7381 4.58236C21.5724 4.40957 21.3477 4.3125 21.1134 4.3125Z"
                    fill="#0364B8"
                />
                <path
                    d="M23.0349 14.4219C23.1271 14.1198 23.2007 13.8118 23.2552 13.4999C23.2551 13.4233 23.2357 13.348 23.1989 13.2816C23.1621 13.2153 23.1093 13.1602 23.0456 13.1219L23.0374 13.1166L23.0349 13.1153L16.2155 9.06364C16.1861 9.04373 16.1556 9.02554 16.1243 9.00917C15.9939 8.94163 15.8502 8.90649 15.7046 8.90649C15.559 8.90649 15.4153 8.94163 15.2849 9.00917C15.2536 9.02532 15.2231 9.04328 15.1937 9.06298L8.37428 13.116L8.37176 13.1173L8.36421 13.1219C8.30046 13.1601 8.24748 13.2152 8.21059 13.2815C8.1737 13.3479 8.1542 13.4232 8.15405 13.4999C8.20856 13.8118 8.28212 14.1198 8.37428 14.4219L15.6052 19.9377L23.0349 14.4219Z"
                    fill="#0A2767"
                />
                <path
                    d="M18.2215 6.28125H13.817L12.5454 8.25L13.817 10.2188L18.2215 14.1562H21.9968V10.2188L18.2215 6.28125Z"
                    fill="#28A8EA"
                />
                <path d="M9.4126 6.28125H13.8171V10.2188H9.4126V6.28125Z" fill="#0078D4" />
                <path d="M18.2214 6.28125H21.9967V10.2188H18.2214V6.28125Z" fill="#50D9FF" />
                <path
                    d="M18.2216 14.1562L13.8171 10.2188H9.4126V14.1562L13.8171 18.0938L20.6327 19.254L18.2216 14.1562Z"
                    fill="#0364B8"
                />
                <path d="M13.8169 10.2188H18.2214V14.1562H13.8169V10.2188Z" fill="#0078D4" />
                <path d="M9.4126 14.1562H13.8171V18.0938H9.4126V14.1562Z" fill="#064A8C" />
                <path d="M18.2214 14.1562H21.9967V18.0938H18.2214V14.1562Z" fill="#0078D4" />
                <path
                    opacity="0.5"
                    d="M15.8241 19.5493L8.40381 13.9056L8.71527 13.3346C8.71527 13.3346 15.4762 17.3509 15.5793 17.4113C15.6216 17.4289 15.6668 17.4373 15.7123 17.4359C15.7577 17.4346 15.8024 17.4235 15.8436 17.4034L22.7228 13.3149L23.0349 13.8852L15.8241 19.5493Z"
                    fill="#0A2767"
                />
                <path
                    d="M23.0456 13.8787L23.0368 13.8839L23.0349 13.8852L16.2155 17.9369C16.0801 18.028 15.9245 18.0813 15.7637 18.0918C15.6028 18.1022 15.4421 18.0695 15.2969 17.9966L17.6715 21.3179L22.865 22.4972V22.4998C22.9859 22.4087 23.0842 22.2889 23.152 22.1504C23.2198 22.0119 23.2552 21.8586 23.2552 21.7031V13.5C23.2552 13.5767 23.2358 13.6521 23.199 13.7186C23.1623 13.7851 23.1094 13.8403 23.0456 13.8787Z"
                    fill="#1490DF"
                />
                <path
                    opacity="0.05"
                    d="M23.2552 21.7031V21.2188L16.9737 17.4861L16.2155 17.9369C16.0801 18.028 15.9245 18.0813 15.7637 18.0918C15.6028 18.1022 15.4421 18.0695 15.2969 17.9966L17.6715 21.3179L22.865 22.4972V22.4998C22.9859 22.4087 23.0842 22.2889 23.152 22.1505C23.2198 22.012 23.2552 21.8587 23.2552 21.7031Z"
                    fill="black"
                />
                <path
                    opacity="0.1"
                    d="M23.2237 21.9544L16.3382 17.864L16.2155 17.9362C16.0801 18.0274 15.9246 18.0808 15.7638 18.0914C15.6029 18.102 15.4422 18.0693 15.2969 17.9966L17.6715 21.3179L22.865 22.4971V22.4998C23.0412 22.3667 23.1678 22.1743 23.2237 21.9551V21.9544Z"
                    fill="black"
                />
                <path
                    d="M8.37429 13.8872V13.8806H8.368L8.34913 13.8675C8.28898 13.829 8.2394 13.7749 8.20522 13.7105C8.17104 13.6461 8.15342 13.5736 8.15407 13.5V21.7031C8.1539 21.8324 8.1782 21.9605 8.22558 22.08C8.27295 22.1995 8.34246 22.3081 8.43014 22.3996C8.51781 22.491 8.62193 22.5635 8.73651 22.6129C8.85109 22.6623 8.9739 22.6877 9.09789 22.6875H22.3114C22.39 22.6867 22.4682 22.6757 22.5442 22.6547C22.5836 22.6475 22.6218 22.6343 22.6574 22.6153C22.6708 22.614 22.6837 22.6095 22.6952 22.6022C22.7467 22.5802 22.7953 22.5516 22.8399 22.5169C22.8525 22.5103 22.8588 22.5103 22.8651 22.4972L8.37429 13.8872Z"
                    fill="#28A8EA"
                />
                <path
                    opacity="0.1"
                    d="M14.4462 19.1877V8.46853C14.4455 8.23674 14.3569 8.01464 14.1998 7.85073C14.0426 7.68683 13.8297 7.59444 13.6074 7.59375H9.43135V12.4868L8.37428 13.1154L8.37113 13.1168L8.36358 13.1213C8.29986 13.1597 8.24694 13.2149 8.21016 13.2814C8.17338 13.3479 8.15403 13.4233 8.15405 13.5V20.0625H13.6074C13.8297 20.0618 14.0426 19.9694 14.1998 19.8055C14.3569 19.6416 14.4455 19.4195 14.4462 19.1877Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M13.817 19.844V9.12478C13.8163 8.89299 13.7277 8.67089 13.5706 8.50698C13.4134 8.34308 13.2005 8.25069 12.9782 8.25H9.43135V12.4868L8.37428 13.1154L8.37113 13.1168L8.36358 13.1213C8.29986 13.1597 8.24694 13.2149 8.21016 13.2814C8.17338 13.3479 8.15403 13.4233 8.15405 13.5V20.7188H12.9782C13.2005 20.7181 13.4134 20.6257 13.5706 20.4618C13.7277 20.2979 13.8163 20.0758 13.817 19.844Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M13.817 18.5315V9.12478C13.8163 8.89299 13.7277 8.67089 13.5706 8.50698C13.4134 8.34308 13.2005 8.25069 12.9782 8.25H9.43135V12.4868L8.37428 13.1154L8.37113 13.1168L8.36358 13.1213C8.29986 13.1597 8.24694 13.2149 8.21016 13.2814C8.17338 13.3479 8.15403 13.4233 8.15405 13.5V19.4062H12.9782C13.2005 19.4056 13.4134 19.3132 13.5706 19.1493C13.7277 18.9854 13.8163 18.7633 13.817 18.5315Z"
                    fill="black"
                />
                <path
                    opacity="0.2"
                    d="M13.1878 18.5315V9.12478C13.1871 8.89299 13.0985 8.67089 12.9414 8.50698C12.7842 8.34308 12.5713 8.25069 12.349 8.25H9.43135V12.4868L8.37428 13.1154L8.37113 13.1168L8.36358 13.1213C8.29986 13.1597 8.24694 13.2149 8.21016 13.2814C8.17338 13.3479 8.15403 13.4233 8.15405 13.5V19.4062H12.349C12.5713 19.4056 12.7842 19.3132 12.9414 19.1493C13.0985 18.9854 13.1871 18.7633 13.1878 18.5315Z"
                    fill="black"
                />
                <path
                    d="M3.9591 8.25H12.349C12.5715 8.25 12.7848 8.34216 12.9421 8.50622C13.0994 8.67027 13.1878 8.89277 13.1878 9.12478V17.8752C13.1878 18.1072 13.0994 18.3297 12.9421 18.4938C12.7848 18.6578 12.5715 18.75 12.349 18.75H3.9591C3.73665 18.75 3.52332 18.6578 3.36602 18.4938C3.20873 18.3297 3.12036 18.1072 3.12036 17.8752V9.12478C3.12036 8.89277 3.20873 8.67027 3.36602 8.50622C3.52332 8.34216 3.73665 8.25 3.9591 8.25Z"
                    fill="#0078D4"
                />
                <path
                    d="M5.5535 11.8383C5.77637 11.3429 6.13617 10.9283 6.58667 10.6478C7.08578 10.3498 7.65406 10.2013 8.22892 10.2186C8.7612 10.2066 9.28642 10.3474 9.74721 10.6255C10.181 10.895 10.5303 11.2901 10.7527 11.7628C10.995 12.2838 11.1157 12.8573 11.105 13.4362C11.1167 14.041 10.9924 14.6402 10.742 15.1858C10.5147 15.6753 10.1549 16.0846 9.70694 16.3631C9.22808 16.6501 8.68292 16.7947 8.13076 16.7811C7.58679 16.7947 7.04969 16.6523 6.57786 16.3697C6.14065 16.0998 5.78721 15.7043 5.5598 15.2304C5.31584 14.7171 5.1935 14.1502 5.20303 13.5773C5.19248 12.9775 5.3123 12.3829 5.5535 11.8383ZM6.65463 14.6326C6.77335 14.9457 6.97472 15.2174 7.23539 15.4161C7.50102 15.6101 7.81922 15.7101 8.14334 15.7016C8.48863 15.7156 8.82893 15.6122 9.11296 15.407C9.37073 15.2083 9.56697 14.9352 9.67736 14.6214C9.80115 14.2718 9.86215 13.9014 9.85732 13.5288C9.86116 13.1526 9.80371 12.7785 9.68743 12.4223C9.58483 12.102 9.3956 11.8194 9.14253 11.6086C8.86599 11.393 8.52668 11.2834 8.18173 11.2982C7.85055 11.2894 7.52522 11.3903 7.25238 11.5863C6.98659 11.7853 6.78058 12.0591 6.6584 12.3757C6.38813 13.1015 6.38656 13.9063 6.654 14.6332L6.65463 14.6326Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default OutlookIcon;
