import Divider from 'Components/divider';
import React, { FunctionComponent, ReactNode } from 'react';
import './user-value-display.less';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import { SupportedLanguage } from 'Stores/LanguageStore';

interface UserValueDisplayProps {
    label: string;
    value?: string | null;
    icon?: ReactNode;
}

const UserValueDisplay: FunctionComponent<UserValueDisplayProps> = observer(
    ({ label, value, icon }) => {
        const { languageStore } = useStores();

        return (
            <div className="UserValueDisplay">
                <div className="wrap">
                    {icon && <div className="icon">{icon}</div>}

                    <div
                        className={`text-caption-1 label ${
                            languageStore.currentLanguage === SupportedLanguage.FR
                                ? 'french-label'
                                : ''
                        }`}
                    >
                        {label}:
                    </div>
                    <div className="text-body-bold">{value}</div>
                </div>

                <Divider marginTop={12} />
            </div>
        );
    }
);

export default UserValueDisplay;
