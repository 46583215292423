import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubBookIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M12 21C11.2727 20.4154 10.4848 19.9615 9.63636 19.6385C8.78788 19.3154 7.90909 19.1538 7 19.1538C6.36364 19.1538 5.73879 19.2385 5.12545 19.4077C4.51152 19.5769 3.92424 19.8154 3.36364 20.1231C3.04545 20.2923 2.73879 20.2846 2.44364 20.1C2.14788 19.9154 2 19.6462 2 19.2923V8.16923C2 8 2.04182 7.83846 2.12545 7.68462C2.20848 7.53077 2.33333 7.41538 2.5 7.33846C3.19697 6.96923 3.92424 6.69231 4.68182 6.50769C5.43939 6.32308 6.21212 6.23077 7 6.23077C7.87879 6.23077 8.73879 6.34615 9.58 6.57692C10.4206 6.80769 11.2273 7.15385 12 7.61538V18.7846C12.7727 18.2923 13.5833 17.9231 14.4318 17.6769C15.2803 17.4308 16.1364 17.3077 17 17.3077C17.5455 17.3077 18.0797 17.3538 18.6027 17.4462C19.1252 17.5385 19.6515 17.6769 20.1818 17.8615V6.78462C20.4091 6.86154 20.6327 6.94215 20.8527 7.02646C21.0721 7.11138 21.2879 7.21538 21.5 7.33846C21.6667 7.41538 21.7918 7.53077 21.8755 7.68462C21.9585 7.83846 22 8 22 8.16923V19.2923C22 19.6462 21.8524 19.9154 21.5573 20.1C21.2615 20.2846 20.9545 20.2923 20.6364 20.1231C20.0758 19.8154 19.4885 19.5769 18.8745 19.4077C18.2612 19.2385 17.6364 19.1538 17 19.1538C16.0909 19.1538 15.2121 19.3154 14.3636 19.6385C13.5152 19.9615 12.7273 20.4154 12 21ZM13.8182 16.3846V7.61538L18.3636 3V12.2308L13.8182 16.3846ZM10.1818 17.8846V8.74615C9.68182 8.53077 9.16303 8.36554 8.62545 8.25046C8.08727 8.13477 7.54545 8.07692 7 8.07692C6.43939 8.07692 5.89394 8.13077 5.36364 8.23846C4.83333 8.34615 4.31818 8.50769 3.81818 8.72308V17.8846C4.34848 17.6846 4.87515 17.5385 5.39818 17.4462C5.92061 17.3538 6.45455 17.3077 7 17.3077C7.54545 17.3077 8.07939 17.3538 8.60182 17.4462C9.12485 17.5385 9.65152 17.6846 10.1818 17.8846Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubBookIcon;
