import { FloorPlanFloorDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanFloorDto';
import { FloorPlanOfficeSpaceAreaDto } from 'Api/Features/FloorPlans/Dtos/FloorPlanOfficeSpaceAreaDto';
import { theme } from 'Style/theme';
import SvgPanZoom from 'svg-pan-zoom';

//https://www.npmjs.com/package/svg.js
//https://svgjs.dev/docs/3.0/

/**Logic for using floor plan in component */
export class FloorPlanManager {
    constructor(
        private onPolygonSelected: (
            officeSpace: FloorPlanOfficeSpaceAreaDtoWithAvailability
        ) => void,
        private onPolygonUnSelected: () => void,
        private floorPlanType: FloorPlanType,
        private mouseWheelZoomEnabled: boolean,
        private onCompletePolygonDraw?: (spaceArea: FloorPlanOfficeSpaceAreaDto) => void,
        private onPolygonInteraction?: () => void
    ) {}

    // Root element for the SVG (<svg> element).
    svgRoot: any = null;

    // Root shape inside the SVG that we use to contain all our other shapes (<g> element).
    svgBackgroundShape: any = null;

    // Viewbox coordinates (X, Y) contained in the selected SVG.
    // We need to consider those coordinates to ensure that the points of our polygons are in right client space.
    svgBackgroundViewBoxX = 0;
    svgBackgroundViewBoxY = 0;

    // svg-pan-zoom instance (used to zoom and pan inside an SVG)
    panZoom: SvgPanZoom.Instance | null = null;

    // Shape that is currently being drawn, if any.
    polygonBeingDrawn: any = null;

    // Shape that is currently selected and its associated area view model.
    selectedPolygon: any = null;
    selectedAreaViewModel: FloorPlanOfficeSpaceAreaDtoWithAvailability | null = null;

    // A map of areaViewModel.officeSpaceId and the associated polygon that has been drawn for it
    officeSpaceIdAssociatedPolygons: any = new Map();

    floorViewModelOfficeSpaceAreas: FloorPlanOfficeSpaceAreaDtoWithAvailability[] = [];

    //In floor plan visualiser, clicking between polygons we need a reference of whether the previous poly was available to put the right color
    lastSelectedPolygonWasAvailable = false;

    async init(): Promise<void> {
        // Create the SVG element.
        this.svgRoot = SVG('svg-container-div').attr('id', 'svg');

        if (this.floorPlanType === FloorPlanType.editor) {
            // Listen for double clicks inside the SVG.
            this.svgRoot.dblclick((e: Event) => this.onSvgDoubleClick(e));

            // Listen for right clicks inside the SVG.
            document
                .getElementById('svg-container-div')
                ?.addEventListener('contextmenu', (e: Event) => this.onSvgRightClick(e));

            // Listen for keyboard events.
            document.addEventListener('keydown', (e: KeyboardEvent) => this.onKeyDown(e));
        }
    }

    /*
     * Initializes the floor plan controls from a ViewModel received from the server.
     */
    initFloor(floorViewModel: FloorPlanFloorDtoWithAvailability | null): void {
        // Clear the SVG and reset variables and controls.
        this.svgRoot.clear();
        this.svgBackgroundShape = null;
        this.svgBackgroundViewBoxX = 0;
        this.svgBackgroundViewBoxY = 0;
        this.selectedPolygon = null;
        this.selectedAreaViewModel = null;

        if (!floorViewModel) {
            return;
        }

        if (floorViewModel.svg) {
            // Draw the background (plan SVG).
            this.svgRoot.svg('<g></g>');
            this.svgBackgroundShape = this.svgRoot
                .select('g')
                .first()
                .addClass('svg-pan-zoom_viewport');

            this.svgBackgroundShape.svg(atob(floorViewModel.svg));

            // Init panning and zooming.
            try {
                if (this.panZoom) {
                    this.panZoom.destroy();
                    this.panZoom = null;
                }
                this.panZoom = SvgPanZoom('#svg', {
                    dblClickZoomEnabled: false,
                    fit: true,
                    center: true,
                    mouseWheelZoomEnabled: this.mouseWheelZoomEnabled,
                });
            } catch (error) {
                //
            }

            // Read the viewbox coordinates from the SVG.
            const backgroundSvgRoot = this.svgBackgroundShape.select('svg').first();
            if (backgroundSvgRoot && backgroundSvgRoot.attr('viewBox')) {
                this.svgBackgroundViewBoxX = parseFloat(
                    backgroundSvgRoot.attr('viewBox').split(' ')[0]
                );
                this.svgBackgroundViewBoxY = parseFloat(
                    backgroundSvgRoot.attr('viewBox').split(' ')[1]
                );
            }

            if (this.floorPlanType === FloorPlanType.editor) {
                this.floorViewModelOfficeSpaceAreas =
                    floorViewModel.officeSpaceAreas?.filter((x) => x != null).map((x) => x!) ?? [];
            } else {
                this.floorViewModelOfficeSpaceAreas =
                    floorViewModel.officeSpaceAreasWithAvailability
                        ?.filter((x) => x != null)
                        .map((x) => x!) ?? [];
            }

            // Draw existing areas on the SVG.
            for (let i = 0; i < this.floorViewModelOfficeSpaceAreas.length; i++) {
                const polygon = this.drawPolygonForArea(this.floorViewModelOfficeSpaceAreas[i]);
                this.officeSpaceIdAssociatedPolygons.set(
                    this.floorViewModelOfficeSpaceAreas[i].officeSpaceId,
                    polygon
                );
            }
        }
    }

    onKeyDown(e: KeyboardEvent): void {
        if (this.polygonBeingDrawn != null) {
            if (e.key == 'Escape') {
                // Escape - cancel if we're currently drawing a shape.
                this.cancelPolygonDrawing();
            } else if (e.key == 'Enter') {
                // Enter - Finish the shape if we're currently drawing one.
                this.completePolygonDrawing();
            }
        }
    }

    onSvgDoubleClick(eventData: any): void {
        // We're already drawing a shape, don't do anything.
        if (this.polygonBeingDrawn != null) {
            return;
        }

        if (this.svgBackgroundShape === null) {
            return;
        }

        // Start drawing a shape.
        this.startPolygonDrawing();

        // Place the first point.
        this.polygonBeingDrawn.draw('point', eventData);
    }

    onSvgRightClick(e: Event): boolean {
        e.preventDefault();

        if (!this.polygonBeingDrawn) {
            return false;
        }

        // Cancel the last point.
        const points = this.polygonBeingDrawn.array().valueOf().slice(0, -1);
        if (points.length <= 1) {
            this.cancelPolygonDrawing();
            return false;
        }

        this.polygonBeingDrawn.plot(points);
        this.polygonBeingDrawn.draw('drawCircles');
        this.polygonBeingDrawn.draw('update');

        return false;
    }

    removeSelectedPolygon(): void {
        if (!this.selectedPolygon) {
            return;
        }

        // Remove the shape from the SVG.
        this.selectedPolygon.selectize(false, { deepSelect: true }).resize('stop').remove();
        this.selectedPolygon = null;

        if (this.selectedAreaViewModel)
            this.officeSpaceIdAssociatedPolygons.delete(this.selectedAreaViewModel.officeSpaceId);

        this.selectedAreaViewModel = null;
    }

    removePolygonBySpaceId(spaceId: string): void {
        this.selectedPolygon = this.officeSpaceIdAssociatedPolygons.get(spaceId);
        this.removeSelectedPolygon();
    }

    svgToBase64(content: any): string {
        if (content == undefined) return '';
        const base64 = btoa(content);
        return base64;
    }

    /*
     * Draws an area on the floor plan SVG using the coordinates in a FloorPlanOfficeSpaceAreaDto received from the server.
     */
    drawPolygonForArea(areaViewModel: FloorPlanOfficeSpaceAreaDtoWithAvailability): any {
        if (this.svgBackgroundShape === null) {
            return;
        }

        // Draw the polygon
        // Points format: "x1,y1 x2,y2 x3,y3"
        const polygon = this.svgBackgroundShape.polygon(
            this.translatePoints(
                areaViewModel.points,
                -this.svgBackgroundViewBoxX,
                -this.svgBackgroundViewBoxY
            )
        );

        polygon.attr({
            fill: theme['primary-mid-contrast'],
            'fill-opacity': '0.2',
            'stroke-width': 2,
            stroke: theme['primary-mid-contrast'],
        });

        //visualiser must render colors depending on availability of space
        if (this.floorPlanType === FloorPlanType.visualiser) {
            polygon.attr({
                fill: areaViewModel.isAvailableForUser
                    ? theme['success-mid-contrast']
                    : theme['text-mid-contrast'],
                'fill-opacity': areaViewModel.isAvailableForUser ? '0.2' : '0.5',
                stroke: areaViewModel.isAvailableForUser
                    ? theme['success-mid-contrast']
                    : theme['text-mid-contrast'],
            });
        }

        this.initPolygonFeatures(polygon, areaViewModel);

        return polygon;
    }

    startPolygonDrawing(): void {
        this.polygonBeingDrawn = this.svgBackgroundShape.polygon().draw();
        this.polygonBeingDrawn.attr({
            fill: theme['accent-mid-contrast'],
            'fill-opacity': '0.2',
            'stroke-width': 2,
            stroke: theme['accent-mid-contrast'],
        });
    }

    cancelPolygonDrawing(): void {
        if (this.polygonBeingDrawn) {
            this.polygonBeingDrawn.draw('cancel');
            this.polygonBeingDrawn = null;
        }
    }

    completePolygonDrawing(): void {
        const points = this.polygonBeingDrawn.attr('points');
        //only consider polygons with three points or more. The last uncommited dot is included so 4-1 = 3 = triangle minimum polygon
        if (points.split(' ').length < 4) return;

        if (this.polygonBeingDrawn) {
            this.polygonBeingDrawn.draw('done');

            const officeSpaceArea: FloorPlanOfficeSpaceAreaDto = {
                points: this.translatePoints(
                    this.polygonBeingDrawn.attr('points'),
                    this.svgBackgroundViewBoxX,
                    this.svgBackgroundViewBoxY
                ),
            };

            this.initPolygonFeatures(this.polygonBeingDrawn, officeSpaceArea);
            this.onPolygonClicked(this.polygonBeingDrawn, officeSpaceArea);

            if (this.onCompletePolygonDraw) this.onCompletePolygonDraw(officeSpaceArea);
            this.polygonBeingDrawn = null;
        }
    }

    //after polygon drawn, modal opens to asssign space, Once space is assigned and modal closes, call this function
    assignPolygonSpaceCallback(space: FloorPlanOfficeSpaceAreaDto): void {
        if (this.selectedPolygon == null || this.selectedAreaViewModel == null) return;

        this.selectedAreaViewModel.officeSpaceId = space.officeSpaceId;
        this.selectedAreaViewModel.capacity = space.capacity;
        this.selectedAreaViewModel.officeSpaceName = space.officeSpaceName;

        this.officeSpaceIdAssociatedPolygons.set(space.officeSpaceId, this.selectedPolygon);
    }

    initPolygonFeatures(
        polygon: any,
        areaViewModel: FloorPlanOfficeSpaceAreaDtoWithAvailability
    ): void {
        // Listen for clicks on the polygon
        polygon.click(() => {
            this.onPolygonClicked(polygon, areaViewModel);
        });

        if (this.floorPlanType === FloorPlanType.editor) {
            // Make polygon draggable and resizable
            polygon
                .draggable()
                .on('dragend', () => {
                    areaViewModel.points = polygon.node.getAttribute('points');
                    if (this.onPolygonInteraction) this.onPolygonInteraction();
                })
                .selectize({ deepSelect: true })
                .resize()
                .on('resizedone', () => {
                    areaViewModel.points = polygon.node.getAttribute('points');
                    if (this.onPolygonInteraction) this.onPolygonInteraction();
                });
        }
    }

    onPolygonClicked(
        polygon: any,
        areaViewModel: FloorPlanOfficeSpaceAreaDtoWithAvailability
    ): void {
        if (this.selectedPolygon) {
            //unselected colors
            this.selectedPolygon.attr({
                fill: theme['primary-mid-contrast'],
                'fill-opacity': '0.2',
                stroke: theme['primary-mid-contrast'],
                'stroke-width': 2,
            });
            if (this.floorPlanType === FloorPlanType.visualiser) {
                this.selectedPolygon.attr({
                    fill: this.lastSelectedPolygonWasAvailable
                        ? theme['success-mid-contrast']
                        : theme['text-mid-contrast'],
                    'fill-opacity': this.lastSelectedPolygonWasAvailable ? '0.2' : '0.5',
                    stroke: this.lastSelectedPolygonWasAvailable
                        ? theme['success-mid-contrast']
                        : theme['text-mid-contrast'],
                });
            }
        }

        if (this.selectedPolygon === polygon) {
            this.selectedPolygon = null;
            this.selectedAreaViewModel = null;
            this.onPolygonUnSelected();
            return;
        } else {
            this.selectedPolygon = polygon;
            this.selectedAreaViewModel = areaViewModel;
            //selected colors
            this.selectedPolygon.attr({
                fill: theme['accent-mid-contrast'],
                'fill-opacity': '0.2',
                stroke: theme['accent-mid-contrast'],
                'stroke-width': 2,
            });
            if (this.floorPlanType === FloorPlanType.visualiser) {
                this.selectedPolygon.attr({
                    fill: areaViewModel.isAvailableForUser
                        ? theme['primary-mid-contrast']
                        : theme['text-mid-contrast'],
                    'fill-opacity': areaViewModel.isAvailableForUser ? '0.2' : '0.5',
                    stroke: theme['primary-mid-contrast'],
                });
            }

            //keep a ref of the value when switching polygons for floorplan type visualiser
            this.lastSelectedPolygonWasAvailable = areaViewModel.isAvailableForUser ?? false;
            this.onPolygonSelected(areaViewModel);
        }
    }

    onSideControlSpaceClicked(spaceId: string): void {
        const area = this.floorViewModelOfficeSpaceAreas.find((x) => x.officeSpaceId === spaceId);
        const polygon = this.officeSpaceIdAssociatedPolygons.get(spaceId);
        if (polygon && area) this.onPolygonClicked(polygon, area);
    }
    /**
     * Moves a list of points.
     */
    translatePoints(points: any, x: any, y: any): any {
        if (x === 0 && y === 0) {
            return points;
        }
        const points2 = points.split(' ');

        const translatedPoints: string[] = [];

        for (let i = 0; i < points2.length; i++) {
            const points3 = points2[i].split(',');
            const xx = parseFloat(points3[0]) + x;
            const yy = parseFloat(points3[1]) + y;

            const r = xx.toString() + ',' + yy.toString();
            translatedPoints.push(r);
        }

        return translatedPoints.join(' ');
    }

    isSvg(input: string): boolean {
        // ----------
        // is-svg
        // Code taken from the is-svg JavaScript package.
        // ----------
        const htmlCommentRegex = /<!--([\s\S]*?)-->/g;

        const isBinary = (buffer: any) => {
            for (let i = 0; i < 24; i++) {
                const characterCode = buffer.charCodeAt(i);

                if (characterCode === 65533 || characterCode <= 8) {
                    return true;
                }
            }

            return false;
        };

        const regex =
            /^\s*(?:<\?xml[^>]*>\s*)?(?:<!doctype svg[^>]*\s*(?:\[?(?:\s*<![^>]*>\s*)*\]?)*[^>]*>\s*)?(?:<svg[^>]*>[^]*<\/svg>|<svg[^/>]*\/\s*>)\s*$/i;

        return (
            Boolean(input) &&
            !isBinary(input) &&
            regex.test(input.toString().replace(htmlCommentRegex, ''))
        );
    }
}

export class OfficeSpace {
    id?: string | null;
    name?: string | null;
}

export interface PointDto {
    x?: number;
    y?: number;
}

declare const SVG: any;

export enum FloorPlanType {
    editor = 'editor',
    visualiser = 'visualiser',
}

export interface FloorPlanFloorDtoWithAvailability extends FloorPlanFloorDto {
    officeSpaceAreasWithAvailability?: FloorPlanOfficeSpaceAreaDtoWithAvailability[];
}

export interface FloorPlanOfficeSpaceAreaDtoWithAvailability extends FloorPlanOfficeSpaceAreaDto {
    isAvailableForUser?: boolean;
}
