import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';
import { OfficeUtilizationStatsGroupDto } from 'Api/Features/Stats/Dtos/OfficeUtilizationStatsGroupDto';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RealEstateChartBaseProps } from '../..';
import { TimeRangeFilter } from '../graph-card';
import OneRowOffice from './components/one-row-office';
import './index.less';

export interface RealEstateChartBaseLastMonthProps extends RealEstateChartBaseProps {
    lastPeriodChartData?: GetOfficeUtilizationStatsResponseDto;
    currentTimeFilter?: TimeRangeFilter;
}

interface OfficePopularSpace extends OfficeUtilizationStatsGroupDto {
    percentageOverLastMonth?: number;
}

const PopularSpaces: React.FunctionComponent<RealEstateChartBaseLastMonthProps> = ({
    chartData,
    lastPeriodChartData,
    currentTimeFilter,
}) => {
    const { t } = useTranslation();
    const [popularSpace, setPopularSpace] = useState<OfficePopularSpace[]>([]);

    const parseChartData = useCallback(() => {
        if (!chartData || !chartData?.groups) return;

        const popularSpaceFilter = chartData?.groups.filter(
            (el) => el?.totalHours && el?.totalHours > 0
        );

        const popularSpaceSort = popularSpaceFilter
            .sort((a, b) => (a?.totalHours || 0) - (b?.totalHours || 0))
            .slice(0, 5) // prends les 5 premiers seulement
            .map((item) => {
                const sameSpaceLastMonth = lastPeriodChartData?.groups?.find(
                    (el) => el?.info?.officeSpace?.id === item?.info?.officeSpace?.id
                );
                const lastTotalMonthTotalHours = sameSpaceLastMonth?.totalHours || 1;

                const percentageOverLastMonth =
                    (((item?.totalHours || 0) - lastTotalMonthTotalHours) /
                        lastTotalMonthTotalHours) *
                    100;

                return { ...item, percentageOverLastMonth: Math.round(percentageOverLastMonth) };
            });

        setPopularSpace(popularSpaceSort);
    }, [chartData, lastPeriodChartData]);

    useEffect(() => {
        if (chartData && lastPeriodChartData) parseChartData();
    }, [chartData, lastPeriodChartData]);

    return (
        <div className="PopularSpaces">
            {popularSpace.map((office, index) => {
                return (
                    <OneRowOffice
                        key={index}
                        numero={index + 1}
                        label={office?.info?.officeSpace?.name || t('unspecified_spaces')}
                        data={
                            office.groups &&
                            office.groups.map((item) => {
                                return { pv: item?.totalHours || 0 };
                            })
                        }
                        percentage={office.percentageOverLastMonth || 0}
                        totalOfficeHours={office.totalHours || 0}
                        currentTimeFilter={currentTimeFilter}
                    />
                );
            })}
        </div>
    );
};

export default PopularSpaces;
