import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import Icon from 'Components/icons/Icon';
import BaseModalMobile from 'Components/modal/base-modal-mobile';
import {
    WorkingCoworkersOfficeData,
    WorkingCoworkersTeamData,
    OfficeOccupancyCardWorkType,
} from 'Components/office-occupancy-card';
import OfficeOccupancyCardDate from 'Components/office-occupancy-card/components/office-occupancy-card-date';
import CtaRow from 'Components/office-occupancy-card/components/cta-row';
import OfficeAndTeam from 'Components/office-occupancy-card/components/office-and-team';
import OfficeDropdownModalContent from 'Components/office-occupancy-card/modals/office-dropdown-modal-content';
import Skeleton from 'Components/skeleton';
import WorkTypeStatus from 'Components/worktype-status';
import { useService, useStores } from 'Hooks';
import {
    FORMAT_DAY_WRITTEN,
    FORMAT_MONTH_DATE_COMMA_YEAR,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dashboardMobileOfficeAndTeam } from '../skeleton-shapes';
import './card-day-vertical.less';
import { ScheduleService } from 'Services/ScheduleService';
import { UpdateUserWorkDayRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserWorkDayRequestDto';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';

export interface AsyncOpenData {
    dataOffice: WorkingCoworkersOfficeData;
    dataTeam: WorkingCoworkersTeamData;
}

interface CardDayVerticalProps {
    isToday: boolean;
    date: Moment;
    workTypes: WorkTypeDto[];
    onClickChangeWorkType: (worktype: OfficeOccupancyCardWorkType) => void;
    isHappeningDay?: boolean;
    isSuggestDay?: boolean;
    openOptions?: {
        officeChangerData: {
            showOfficeChanger: boolean;
            isDefaultOffice?: boolean;
            /**Office name*/
            Label: string;
            /**office space */
            subLabel?: string;
            capacity?: string;
            selectedOffice?: SingleSelectCustomOption;
            selectedSpace?: SingleSelectCustomOption;
        };
        workingFrom: string;
        asyncOpenData: AsyncOpenData | null;
        capacityFetchParams: {
            startTime: string;
            endTime: string;
        };
    };
    isOpen: boolean;
    handleOpenClick: (date: string) => void;
    onClickHappeningIcon: () => void;
    changeAllDayOffice: (
        office?: { name?: string; id?: string },
        officeSpace?: { name?: string; id?: string }
    ) => void;
    selectedOfficeId?: string;
}

const CardDayVertical: React.FunctionComponent<CardDayVerticalProps> = ({
    isToday,
    date,
    workTypes,
    onClickChangeWorkType,
    openOptions,
    isHappeningDay = false,
    isSuggestDay = false,
    isOpen,
    handleOpenClick,
    onClickHappeningIcon,
    changeAllDayOffice,
}) => {
    const { userStore, toastStore, globalLoadingStore, languageStore } = useStores();
    const scheduleService = useService(ScheduleService);
    const [isOfficeModalVisible, setIsOfficeModalVisible] = useState(false);
    const { t } = useTranslation();

    const toggleOpen = (event) => {
        if (event.target === event.currentTarget)
            handleOpenClick(date.format(FORMAT_YEAR_MONTH_DAY));
    };

    const updateUserWorkDay = async (request: UpdateUserWorkDayRequestDto) => {
        try {
            globalLoadingStore.addLoading();

            userStore.userInfo?.id &&
                (await scheduleService.updateUserWorkDay(userStore.userInfo?.id, request));
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleOfficeOnClick = (
        selectedOffice?: { name?: string; id?: string },
        selectedSpace?: { name?: string; id?: string }
    ) => {
        setIsOfficeModalVisible(false);

        changeAllDayOffice(selectedOffice, selectedSpace);
        updateUserWorkDay({
            date: date.format(FORMAT_YEAR_MONTH_DAY),
            officeId: selectedOffice?.id,
            officeSpaceId: selectedSpace?.id,
            workType: workTypes[0],
        });
    };

    return (
        <div className={`CardDayVertical isOpen-${isOpen} isToday-${isToday}`}>
            <div className="day-wrapper" onClick={toggleOpen}>
                <OfficeOccupancyCardDate
                    isToday={isToday}
                    day={date.format(FORMAT_DAY_WRITTEN)}
                    date={date.format(FORMAT_MONTH_DATE_COMMA_YEAR[languageStore.currentLanguage])}
                />

                <div className="happening-icon">
                    {isHappeningDay && (
                        <Icon iconName="CalendarStarIcon" onClick={onClickHappeningIcon} />
                    )}
                </div>

                <WorkTypeStatus
                    workTypes={workTypes}
                    type="centered-modal"
                    onClickChangeWorkType={onClickChangeWorkType}
                />
            </div>

            {isOpen && (
                <div className="details-wrapper">
                    {openOptions?.officeChangerData?.showOfficeChanger && (
                        <CtaRow
                            office={{
                                isDefaultOffice: openOptions.officeChangerData.isDefaultOffice,
                                iconName: openOptions.officeChangerData.isDefaultOffice
                                    ? 'MainWorkplaceIcon'
                                    : 'MapPinWithCircleIcon',
                                label: openOptions?.officeChangerData.Label,
                                subLabel: openOptions.officeChangerData.subLabel,
                                capacity: openOptions.officeChangerData.capacity,
                                onClick: () => setIsOfficeModalVisible(true),
                            }}
                        />
                    )}

                    <div className="working-from">
                        <div className="label">{t('working_from')}</div>
                        <div className="value">{openOptions?.workingFrom}</div>
                    </div>

                    <Skeleton
                        isLoaded={openOptions?.asyncOpenData !== null}
                        placeholder={dashboardMobileOfficeAndTeam}
                    >
                        <>
                            {openOptions?.asyncOpenData && (
                                <OfficeAndTeam
                                    dataOffice={openOptions.asyncOpenData.dataOffice}
                                    dataTeam={openOptions.asyncOpenData.dataTeam}
                                />
                            )}
                        </>
                    </Skeleton>
                </div>
            )}

            {isSuggestDay && (
                <div className="suggested-day-container">{t('Policy.suggested_office_day')}</div>
            )}

            {isOfficeModalVisible && (
                <BaseModalMobile
                    className="WorkTypeStatusCenteredModal"
                    visible={isOfficeModalVisible}
                    onCancel={() => setIsOfficeModalVisible(false)}
                    maskClosable
                >
                    <OfficeDropdownModalContent
                        onSubmit={(office, officeSpace) => handleOfficeOnClick(office, officeSpace)}
                        capacityFetchParams={{
                            startTime: openOptions?.capacityFetchParams.startTime ?? '',
                            endTime: openOptions?.capacityFetchParams.endTime ?? '',
                        }}
                        defaultSelectedOffice={openOptions?.officeChangerData.selectedOffice}
                        defaultSelectedSpace={openOptions?.officeChangerData.selectedSpace}
                    />
                </BaseModalMobile>
            )}
        </div>
    );
};

export default CardDayVertical;
