import { HappeningFilterModeDto } from 'Api/Features/Happenings/Dtos/HappeningFilterModeDto';
import { GetDailyWorkStatsRequestDto } from 'Api/Features/Schedules/Dtos/GetDailyWorkStatsRequestDto';
import { ScheduleDayDto } from 'Api/Features/Schedules/Dtos/ScheduleDayDto';
import { ScheduleEventDto } from 'Api/Features/Schedules/Dtos/ScheduleEventDto';
import { UpdateUserWorkDayRequestDto } from 'Api/Features/Schedules/Dtos/UpdateUserWorkDayRequestDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserTeamFilterModeDto } from 'Api/Features/Users/Dtos/UserTeamFilterModeDto';
import { OfficeOccupancyCardWorkType } from 'Components/office-occupancy-card';
import { useService, useStores } from 'Hooks';
import {
    EMPTY_GUID,
    FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE,
    FORMAT_YEAR_MONTH_DAY,
} from 'Models/Constants';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ScheduleService } from 'Services/ScheduleService';
import { UserService } from 'Services/UserService';
import { dateToDayOfWeek } from 'Utils/TimeUtils';
import { WorkTypeToWorkTypeDto } from 'Utils/Worktype';
import CardDayVertical, { AsyncOpenData } from './card-day-vertical';
import { WorkingCoworkersModalMode } from 'Components/coworkers-at-office-modal/working-coworkers-modal';
import { useTranslation } from 'react-i18next';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { OfficeService } from 'Services/OfficeService';
import { truncate } from 'Utils/TextUtils';
import { UpdateUserWorkDayResponseDto } from 'Api/Features/Schedules/Dtos/UpdateUserWorkDayResponseDto';

interface ScheduleDayWithHappeningDto extends ScheduleDayDto {
    hasHappening?: boolean;
    events: ScheduleEventDto[];
}

interface WrapperCardDayVerticalProps {
    day: ScheduleDayWithHappeningDto;
    selectedOfficeFilterName: string;
    selectedOfficeFilterId?: string;
    handleOpenClick: any;
    cardToOpen: string;
    setWorkingCoworkerModalState: Dispatch<
        SetStateAction<{
            visible: boolean;
            startingDate?: string | undefined;
            workType?: WorkTypeDto | undefined;
            mode: WorkingCoworkersModalMode;
        }>
    >;
    changeAllDayOffice: (
        date: string,
        office?: { name?: string; id?: string },
        officeSpace?: { name?: string; id?: string }
    ) => void;
    changeDayWorkType: (
        date: string,
        worktype: WorkTypeDto,
        smartSelectionOffice: UpdateUserWorkDayResponseDto | null
    ) => void;
}

const WrapperCardDayVertical: React.FunctionComponent<WrapperCardDayVerticalProps> = ({
    day,
    selectedOfficeFilterName,
    selectedOfficeFilterId,
    handleOpenClick,
    cardToOpen,
    setWorkingCoworkerModalState,
    changeAllDayOffice,
    changeDayWorkType: changeTypeOfDay,
}) => {
    const { t } = useTranslation();
    const { toastStore, userStore, policyStore, globalLoadingStore, languageStore } = useStores();
    const [asyncOpenData, setAsyncOpenData] = useState<AsyncOpenData | null>(null);
    const scheduleService = useService(ScheduleService);
    const userService = useService(UserService);
    const officeService = useService(OfficeService);

    const [officeChangerData, setOfficeChangerData] = useState<
        | {
              isDefaultOffice?: boolean;
              officeName?: string;
              officeId?: string;
              spaceName?: string;
              spaceId?: string;
              capacity?: string;
          }
        | undefined
    >(undefined);

    const isOpen = cardToOpen === moment.utc(day?.date).format(FORMAT_YEAR_MONTH_DAY);

    const changeWorkType = async (
        worktype: OfficeOccupancyCardWorkType
    ): Promise<UpdateUserWorkDayResponseDto | null> => {
        try {
            if (!userStore.userInfo?.id) return null;
            globalLoadingStore.addLoading();

            const request: UpdateUserWorkDayRequestDto = {
                date: day.date,
                workType: worktype as unknown as WorkTypeDto,
                useSmartOfficeSelection: true,
            };

            return await scheduleService.updateUserWorkDay(userStore.userInfo?.id, request);
        } catch (error: any) {
            if (!error.treated) {
                toastStore.genericError();
            }
            return null;
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const onClickChangeWorkType = async (worktype: OfficeOccupancyCardWorkType) => {
        const office = await changeWorkType(worktype);
        changeTypeOfDay(day.date || '', WorkTypeToWorkTypeDto(worktype), office);
    };

    const fetchOpenOptions = useCallback(async () => {
        const dailyWorkStatsRequestWithTeam: GetDailyWorkStatsRequestDto = {
            favoritesForUserId: userStore?.userInfo?.id,
            startDate: day.date,
            endDate: day.date,
            policyBundleId: policyStore.policyInfo?.policyBundleId,
            timeZone: userStore?.userInfo?.timeZone,
            workType: WorkTypeDto?.Office,
            excludedUserIds: [userStore?.userInfo?.id || ''],
            officeIds:
                selectedOfficeFilterId && selectedOfficeFilterId !== 'all'
                    ? [selectedOfficeFilterId]
                    : undefined,
            happeningFilterMode: HappeningFilterModeDto.GeneralAndUserClubs,
            clubsForUserId: userStore?.userInfo?.id,
        };

        const usersRequest = {
            teamIds: userStore?.userInfo?.team?.id ? [userStore?.userInfo?.team?.id] : undefined,
            teamFilterMode: UserTeamFilterModeDto.Members,
            favoritesForUserId: userStore?.userInfo?.id,
            pageSize: 0,
        };

        const [dailyWorkStatsReponseWithoutTeam, dailyWorkStatsReponseWithTeam, usersResponse] =
            await Promise.all([
                scheduleService.getDailyWorkStats(dailyWorkStatsRequestWithTeam),
                scheduleService.getDailyWorkStats({
                    ...dailyWorkStatsRequestWithTeam,
                    teamIds: userStore?.userInfo?.team?.id
                        ? [userStore?.userInfo?.team?.id]
                        : undefined,
                }),
                userService.getUsers({
                    ...usersRequest,
                }),
            ]);

        setAsyncOpenData({
            dataOffice: {
                officeName: selectedOfficeFilterName,
                totalCoworkers: dailyWorkStatsReponseWithoutTeam.days?.[0]?.usersCount ?? 0,
                myTeam: userStore.userInfo?.team
                    ? {
                          atOffice: dailyWorkStatsReponseWithTeam.days?.[0]?.usersCount ?? 0,
                          total: usersResponse[1] ?? 0,
                      }
                    : undefined,
            },
            dataTeam: {
                imgUrls:
                    dailyWorkStatsReponseWithTeam?.days?.[0]?.users?.map(
                        (user) => user?.imageUrl ?? ''
                    ) ?? [],
                atOffice: dailyWorkStatsReponseWithTeam.days?.[0]?.usersCount ?? 0,
                favCoworkers: dailyWorkStatsReponseWithTeam.days?.[0]?.favoriteUsersCount ?? 0,
                onOpenCoworkersAtOfficeModal: onViewAllClick,
            },
        });

        fetchOfficeSpaceCapacity(day);
    }, [isOpen]);

    const fetchOfficeSpaceCapacity = async (day: ScheduleDayWithHappeningDto) => {
        try {
            const officeEvents = day.events?.filter(
                (event) => event.workType === WorkTypeDto.Office
            );
            const hasMultipleOffices =
                [...new Set(officeEvents?.map((event) => event?.office?.id))].length > 1;

            const hasMultipleOfficeSpaces = hasMultipleOffices
                ? true
                : [...new Set(officeEvents?.map((event) => event?.officeSpace?.id))].length > 1;

            if (hasMultipleOffices || hasMultipleOfficeSpaces) return;

            const eventWithSpace: ScheduleEventDto | undefined = day.events.find(
                (event) => event.officeSpace?.id && event.officeSpace.id !== EMPTY_GUID
            );

            if (eventWithSpace) {
                const occupancyResponse: GetOfficeSpaceOccupancyResponsePeriodDto[] =
                    await officeService.getOfficeSpaceOccupancy({
                        periods: [
                            {
                                officeSpaceId: eventWithSpace?.officeSpace?.id,
                                startTime: day.workStartTime,
                                endTime:
                                    day.workStartTime === day.workEndTime
                                        ? moment(day.workEndTime).add(30, 'minutes').format()
                                        : day.workEndTime,
                            },
                        ],
                        timeZone: userStore?.userInfo?.timeZone,
                    });
                setOfficeChangerData((prev) => ({
                    ...prev,
                    capacity: `${occupancyResponse?.[0].occupancy}/${occupancyResponse?.[0].officeSpace?.capacity}`,
                }));
            }
        } catch (err: any) {
            if (!err.treated) {
                toastStore.genericError();
            }
        }
    };

    useEffect(() => {
        if (isOpen && userStore.userInfo?.id) {
            fetchOpenOptions();
        }
    }, [isOpen, userStore.userInfo?.id]);

    const onViewAllClick = () => {
        setWorkingCoworkerModalState({
            visible: true,
            startingDate: moment.utc(day.date).format(FORMAT_YEAR_MONTH_DAY),
            workType: WorkTypeDto.Office,
            mode: WorkingCoworkersModalMode.CoworkerList,
        });
    };

    const onClickHappeningIcon = () => {
        onViewAllClick();
    };

    useEffect(() => {
        //set the office changer button data
        if (userStore.userInfo && day) {
            const officeEvents = day.events.filter(
                (event) => event.workType === WorkTypeDto.Office
            );
            const hasMultipleOffices =
                //no office is considered like an office. So defined office, not defined office === 2 offices
                [...new Set(officeEvents.map((event) => event?.office?.id))].length > 1;

            //when day has multiple events in same office but different office spaces. Can be set to true if multiple offices
            const hasMultipleOfficeSpaces = hasMultipleOffices
                ? true
                : //no space is considered like a space. So defined space, not defined space === 2 spaces
                  [...new Set(officeEvents?.map((event) => event?.officeSpace?.id))].length > 1;

            setOfficeChangerData({
                officeName: hasMultipleOffices
                    ? t('OfficeOccupancyCard.multi_office')
                    : officeEvents?.[0]?.office?.name ?? t('OfficeOccupancyCard.select_an_office'),
                officeId: hasMultipleOffices ? undefined : officeEvents?.[0]?.office?.id,
                spaceName:
                    hasMultipleOfficeSpaces || hasMultipleOffices
                        ? t('multiple_spaces')
                        : officeEvents?.[0]?.officeSpace?.name ?? undefined,
                spaceId:
                    hasMultipleOfficeSpaces || hasMultipleOffices
                        ? undefined
                        : officeEvents?.[0]?.officeSpace?.id,
                isDefaultOffice: hasMultipleOffices
                    ? false
                    : officeEvents?.[0]?.office !== null &&
                      officeEvents?.[0]?.office?.id === userStore.userInfo?.defaultOffice?.id,
            });
        }
    }, [day, userStore.userInfo]);

    return (
        <div className="WrapperCardDayVertical">
            <CardDayVertical
                selectedOfficeId={selectedOfficeFilterId}
                changeAllDayOffice={(
                    office?: { name?: string; id?: string },
                    officeSpace?: { name?: string; id?: string }
                ) => changeAllDayOffice(day.date || '', office, officeSpace)}
                onClickHappeningIcon={onClickHappeningIcon}
                isOpen={isOpen}
                handleOpenClick={handleOpenClick}
                isToday={moment.utc(day.date).dayOfYear() === moment().dayOfYear()}
                date={moment.utc(day.date)}
                workTypes={day.workTypes || []}
                onClickChangeWorkType={onClickChangeWorkType}
                isHappeningDay={day.hasHappening}
                isSuggestDay={policyStore.policyInfo?.suggestions?.officeDays?.includes(
                    dateToDayOfWeek(day?.date ?? '')
                )}
                openOptions={{
                    officeChangerData: {
                        Label: truncate(officeChangerData?.officeName ?? '', 30),
                        subLabel: truncate(
                            officeChangerData?.officeName !==
                                t('OfficeOccupancyCard.select_an_office')
                                ? officeChangerData?.spaceName ?? t('no_space_chosen')
                                : '',
                            30
                        ),
                        capacity: officeChangerData?.capacity,
                        isDefaultOffice: officeChangerData?.isDefaultOffice,
                        showOfficeChanger:
                            day.workTypes?.includes(WorkTypeDto.Office || WorkTypeDto.Remote) ??
                            false,
                        selectedOffice: officeChangerData?.officeId
                            ? {
                                  label: officeChangerData.officeName ?? '',
                                  value: officeChangerData.officeId ?? '',
                              }
                            : undefined,
                        selectedSpace: officeChangerData?.spaceId
                            ? {
                                  label: officeChangerData.spaceName ?? '',
                                  value: officeChangerData.spaceId ?? '',
                              }
                            : undefined,
                    },
                    capacityFetchParams: {
                        startTime: day.workStartTime ?? '',
                        endTime: day.workEndTime ?? '',
                    },
                    workingFrom:
                        day?.workStartTime && day?.workEndTime
                            ? moment(day?.workStartTime)
                                  .tz(userStore.userInfo?.timeZone || '')
                                  .format(
                                      FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                          languageStore.currentLanguage
                                      ]
                                  ) +
                              ' - ' +
                              moment(day?.workEndTime)
                                  .tz(userStore.userInfo?.timeZone || '')
                                  .format(
                                      FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE[
                                          languageStore.currentLanguage
                                      ]
                                  )
                            : '',
                    asyncOpenData: asyncOpenData,
                }}
            />
        </div>
    );
};

export default WrapperCardDayVertical;
