import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { UpdateTeamRequestDto } from 'Api/Features/Teams/Dtos/UpdateTeamRequestDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersSortColumnDto } from 'Api/Features/Users/Dtos/GetUsersSortColumnDto';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import { UserDto } from 'Api/Features/Users/Dtos/UserDto';
import Icon from 'Components/icons/Icon';
import Skeleton from 'Components/skeleton';
import TableFilters from 'Components/table-filters/table-filters';
import Tag, { TagColor } from 'Components/tag/tag';
import { TdWithImage } from 'Components/td-with-image';
import VerticalThreeDotMenuButton from 'Components/vertical-three-dot-menu-button';
import { useApiRequestHistory, useService, useStores } from 'Hooks';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    DEBOUNCE_DELAY_400,
    SETTINGS_TEAMS_URL_REGEX,
    SETTINGS_URL,
    TEAMS_URL,
    TEAMS_URL_REGEX,
    USERS_URL,
} from 'Models/Constants';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { default as React, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { tableSkeletonShape } from 'Routes/settings/skeleton-shapes';
import { TeamService } from 'Services/TeamService';
import { UserService } from 'Services/UserService';
import { FilterStore } from 'Stores';
import { HistoryRequest, RequestType } from 'Stores/RequestHistoryStore';
import { theme } from 'Style/theme';
import { img, ImgSize } from 'Utils/ImgUtils';
import { getUserStatusTag } from 'Utils/TagUtils';
import { TeamDetailsContext } from '.';
import './managers-tab.less';
import { renderDefaultOfficeColumn } from 'Utils/WorkplaceUtils';

const getAdvancedFilters = (t: Function): AdvancedFilter[] => [
    {
        key: 'status',
        nameKey: 'Status',
        items: [
            {
                key: UserAccountStatusDto.Active,
                checked: true,
                displayNameKey: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Active}`
                ),
            },
            {
                key: UserAccountStatusDto.Pending,
                checked: true,
                displayNameKey: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Pending}`
                ),
            },
            {
                key: UserAccountStatusDto.Deactivated,
                checked: false,
                displayNameKey: t(
                    `UserAccountStatusDto.UserAccountStatusDto_${UserAccountStatusDto.Deactivated}`
                ),
            },
        ],
    },
];

interface InfoTabProps {
    team?: TeamDto;
    interactionCausesFetch: boolean;
    onFetch: () => void;
    context: TeamDetailsContext;
    fetchTeam: () => void;
}

const ManagersTab: React.FunctionComponent<InfoTabProps> = observer(
    ({ team, interactionCausesFetch, onFetch, context, fetchTeam }) => {
        const { id } = useParams<{ id: string }>();
        const userService = useService(UserService);
        const teamService = useService(TeamService);
        const paginationRef = useRef(initialPaginationState);
        const { t } = useTranslation();
        const advancedFilters = getAdvancedFilters(t);
        const filterStoreRef = useRef(new FilterStore({ advancedFilters }));
        const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
        const [loading, setLoading] = useState(false);
        const history = useHistory();
        const [managerList, setManagerList] = useState<UserDto[]>();
        const { toastStore, confirmationModalStore, globalLoadingStore, userStore, policyStore } =
            useStores();
        const [isLoading, setIsLoading] = useState({
            managers: false,
        });
        const setRequestHistory = useApiRequestHistory({
            useHistoryTrigger: {
                urlRegex:
                    context === TeamDetailsContext.Settings
                        ? SETTINGS_TEAMS_URL_REGEX
                        : TEAMS_URL_REGEX,
                requestType: RequestType.TeamManagers,
            },
            useHistoryCallback: (history: HistoryRequest) => {
                const filterStore = filterStoreRef.current;
                const request: GetUsersRequestDto = history.request;
                filterStore.searchTerm = request.searchTerm ?? '';

                paginationRef.current = {
                    ...paginationRef.current,
                    current: request.page ? request.page + 1 : 1,
                    pageSize: request.pageSize ?? initialPaginationState.pageSize,
                };
            },
        });

        const renderNameColumn = (manager: UserDto) => {
            const tagProps = getUserStatusTag(t, manager.accountStatus);
            return (
                <TdWithImage
                    defaultImg={<Icon iconName="PersonIcon" />}
                    imgSrc={manager.imageUrl ? img(manager.imageUrl, ImgSize.s) : undefined}
                >
                    <div className="user-infos">
                        <span className="title text-callout">
                            {manager.firstName} {manager.lastName}
                        </span>
                        <br />
                        <span className="subtitle text-caption">{manager.contactInfo?.email}</span>
                    </div>
                    {manager.accountStatus !== UserAccountStatusDto.Active && (
                        <Tag text={tagProps?.text ?? ''} color={tagProps?.color ?? TagColor.blue} />
                    )}
                </TdWithImage>
            );
        };

        const onRemoveManager = async (managerId: string | null): Promise<void> => {
            if (!managerId || !team) return;

            try {
                if (
                    !(await confirmationModalStore.confirm({
                        icon: <Icon iconName="WarningIcon" fill={theme['warning-mid-contrast']} />,
                        title:
                            managerId === userStore.userInfo?.id
                                ? t('Team.remove_self_from_team_title')
                                : t('Team.remove_coworker_from_team_title'),
                        message:
                            managerId === userStore.userInfo?.id
                                ? t('Team.remove_self_from_team_message')
                                : t('Team.remove_coworker_from_team_message'),
                        positiveText: t('confirm'),
                        negativeText: t('cancel'),
                    }))
                )
                    return;

                globalLoadingStore.addLoading();
                const request: UpdateTeamRequestDto = {
                    name: team.name,
                    managerIds: team.managers
                        ?.filter(
                            (x) =>
                                x !== null &&
                                x.id !== null &&
                                x.id !== undefined &&
                                x.id !== managerId
                        )
                        .map((x) => x!.id!),
                };
                await teamService.updateTeam(id, request);
                await fetchTeam();

                if (managerId === userStore.userInfo?.id)
                    await policyStore.setPolicyRestrictionsAndObjectivesStats();

                const filterStore = filterStoreRef.current;
                fetchManagers({
                    pagination: {
                        ...paginationRef.current,
                    },
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
                toastStore.toast({
                    type: 'success',
                    messageKey: t('Toast.entity_action_success_message', {
                        param1: t('manager'),
                        param2: t('Entity.lowercase_action_removed'),
                    }),
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        const columns: ColumnType<UserDto>[] = [
            {
                title: t('name'),
                render: renderNameColumn,
                key: GetUsersSortColumnDto.Name,
                defaultSortOrder: 'ascend',
                sorter: true,
                onCell: (row: UserDto) => ({
                    onClick: (): void => {
                        onRowClick(row);
                    },
                }),
            },
            {
                title: t('Profile.job_title'),
                render: (manager: UserDto) => manager.jobTitle ?? t('n/a'),
                key: GetUsersSortColumnDto.JobTitle,
                sorter: true,
                onCell: (row: UserDto) => ({
                    onClick: (): void => {
                        onRowClick(row);
                    },
                }),
            },
            {
                title: t('default_office'),
                render: (user: UserDto) => renderDefaultOfficeColumn(user),
                key: GetUsersSortColumnDto.DefaultOffice,
                sorter: true,
                onCell: (row: UserDto) => ({
                    onClick: (): void => {
                        onRowClick(row);
                    },
                }),
            },
            {
                render: (row: UserDto) => (
                    <div className="t-align-center">
                        <VerticalThreeDotMenuButton
                            menuOptions={[
                                {
                                    title: t('remove_from_team'),
                                    className: 'secondary',
                                    onClick: (): Promise<void> => onRemoveManager(row.id ?? null),
                                },
                            ]}
                        />
                    </div>
                ),
                width: 100,
            },
        ];

        const getAdvancedFilterStatus = (): {
            checkedStatuses: UserAccountStatusDto[];
            statusesCount: number;
        } => {
            const filterStore = filterStoreRef.current;
            const statuses = filterStore.advancedFilters?.find(
                (filter: AdvancedFilter) => filter.key === 'status'
            );
            const checkedStatuses = statuses?.items
                .filter((item) => item.checked)
                .map((item) => {
                    return item.key;
                });

            return {
                checkedStatuses:
                    checkedStatuses?.map(
                        (x) => UserAccountStatusDto[x as keyof typeof UserAccountStatusDto]
                    ) ?? [],
                statusesCount: statuses?.items.length ?? 0,
            };
        };

        const fetchManagers = useCallback(
            async (params: {
                pagination: TablePaginationConfig;
                searchTerm?: string;
                advancedFilters?: AdvancedFilter[];
                sortColumn: GetUsersSortColumnDto | null;
                sortDirection: SortDirection | null;
            }) => {
                setLoading(true);

                try {
                    const statuses = getAdvancedFilterStatus();
                    if (statuses.checkedStatuses.length === 0) {
                        setManagerList([]);
                    } else {
                        const request: GetUsersRequestDto = setRequestHistory({
                            request: {
                                pageSize: params.pagination.pageSize || 0,
                                page: (params.pagination.current || 1) - 1,
                                searchTerm: params.searchTerm,
                                managedTeamIds: [id],
                                accountStatuses: statuses.checkedStatuses,
                                sortColumn: params.sortColumn,
                                sortDirection: params.sortDirection,
                            },
                            requestType: RequestType.TeamManagers,
                        });

                        const [items, totalItemsCount] = await userService.getUsers(request);
                        setIsLoading((prev) => {
                            return { ...prev, managers: true };
                        });
                        setManagerList(items);
                        setPagination({
                            ...params.pagination,
                            total: totalItemsCount,
                        });
                        onFetch();
                    }
                } catch (e: any) {
                    if (!e.treated) {
                        toastStore.genericError();
                    }
                } finally {
                    setLoading(false);
                }
            },
            [userService]
        );

        const handleTableChange = async (
            pagination: TablePaginationConfig,
            filter: any,
            sorter: any
        ): Promise<void> => {
            let sortDirection: SortDirection | null;
            switch (sorter.order) {
                case 'ascend':
                    sortDirection = SortDirection.Ascending;
                    break;
                case 'descend':
                    sortDirection = SortDirection.Descending;
                    break;
                default:
                    sortDirection = null;
                    break;
            }
            const { advancedFilters, searchTerm } = filterStoreRef.current;
            await fetchManagers({
                pagination,
                searchTerm,
                advancedFilters,
                sortColumn: sorter.columnKey,
                sortDirection: sortDirection,
            });

            paginationRef.current = pagination;
        };

        const debounceSearch = useRef(
            debounce((params: { searchTerm?: string; advancedFilters?: AdvancedFilter[] }) => {
                fetchManagers({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    advancedFilters: params.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
            }, DEBOUNCE_DELAY_400)
        );

        useEffect(() => {
            const disposer = autorun(() => {
                const filterStore = filterStoreRef.current;
                debounceSearch.current({
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                });
            });

            return (): void => {
                disposer();
            };
        }, [debounceSearch]);

        useEffect(() => {
            if (interactionCausesFetch) {
                const filterStore = filterStoreRef.current;
                fetchManagers({
                    pagination: {
                        ...paginationRef.current,
                    },
                    searchTerm: filterStore.searchTerm,
                    advancedFilters: filterStore.advancedFilters,
                    sortColumn: null,
                    sortDirection: null,
                });
            }
        }, [interactionCausesFetch]);

        const onRowClick = (manager: UserDto): void => {
            if (context === TeamDetailsContext.Settings)
                history.push(SETTINGS_URL + USERS_URL + `/${manager.id}`);
            if (context === TeamDetailsContext.MyTeams)
                history.push(TEAMS_URL + `/${id}` + USERS_URL + `/${manager.id}`);
        };

        return (
            <div className="ManagersTab">
                <TableFilters
                    filterStore={filterStoreRef.current}
                    includeSearch
                    includeUserStatusSelect
                    isLoaded={isLoading.managers}
                />

                <Skeleton isLoaded={isLoading.managers} placeholder={tableSkeletonShape}>
                    <Table
                        className="table-action-rows table-striped-rows"
                        dataSource={managerList}
                        columns={columns}
                        loading={loading}
                        rowKey={(manager): string => manager.id ?? ''}
                        pagination={pagination}
                        onChange={handleTableChange}
                    />
                </Skeleton>
            </div>
        );
    }
);

export default ManagersTab;
