import React from 'react';
import TeamDetails, { TeamDetailsContext } from 'Routes/settings/teams/id';

const TeamManagementDetail: React.FunctionComponent = () => {
    return (
        <div className="TeamManagementDetail">
            <TeamDetails context={TeamDetailsContext.MyTeams} />
        </div>
    );
};

export default TeamManagementDetail;
