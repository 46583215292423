import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const DeskIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 6.66675H25V13.1853H23.4286V21.3334H21.8571V13.1853H6.14286V21.3334H4.57143V13.1853H3V6.66675Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default DeskIcon;
