import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { GetOfficeUtilizationStatsResponseDto } from 'Api/Features/Stats/Dtos/GetOfficeUtilizationStatsResponseDto';
import Icon from 'Components/icons/Icon';
import Skeleton from 'Components/skeleton';
import WhiteCard from 'Components/white-card';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE } from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import { denominatorSafeValue } from 'Utils/NumberUtils';
import { TimeRangeFilterMap, TimeRangeKey } from 'Utils/ReportsUtils';
import { dayOfWeekLangMap } from 'Utils/TimeUtils';
import { StatsRequestTypeEnum } from '..';
import { realEstateHighLightsShape } from '../skeleton-shapes';
import { TimeRangeFilter } from './graph-card';
import './highlight-card.less';
import { QueuedFetch } from 'Hooks/use-fetch-queue';
import { uniqueId } from 'lodash';

export interface HighlightData {
    valueData?: GetOfficeUtilizationStatsResponseDto;
    lastPeriodData?: GetOfficeUtilizationStatsResponseDto;
}

interface HighlightCardProps {
    iconName: string;
    title: string;
    fetchStats: (
        timeRangeFilter: TimeRangeFilter,
        requestType: StatsRequestTypeEnum,
        officeFilter: string
    ) => Promise<void>;
    requestType: StatsRequestTypeEnum;
    selectedTeams: string;
    dataProp?: HighlightData;
    addToFetchQueue: (fetchParams: QueuedFetch) => void;
}

interface Trend {
    value: string;
    trajectory: TrendTrajectory;
    graphTrendColor?: string;
    valueTrendColor?: string;
}

enum TrendTrajectory {
    Upwards = 'Upwards',
    Downwards = 'Downwards',
    Stagnant = 'Stagnant',
}

const HighlightCard: React.FunctionComponent<HighlightCardProps> = observer(
    ({ iconName, title, fetchStats, requestType, selectedTeams, dataProp, addToFetchQueue }) => {
        const { t } = useTranslation();
        const { userStore, languageStore } = useStores();
        const [trend, setTrend] = useState<Trend>({
            trajectory: TrendTrajectory.Stagnant,
            value: '',
        });
        const [loadingData, setLoadingData] = useState(true);
        const [value, setValue] = useState<string>();

        const getIconTrendColor = (trend: TrendTrajectory): string => {
            switch (trend) {
                case TrendTrajectory.Upwards:
                    return theme['success-mid-contrast'];
                case TrendTrajectory.Downwards:
                    return theme['error-mid-contrast'];
                case TrendTrajectory.Stagnant:
                    return theme['primary-mid-contrast'];
            }
        };

        const getIconBackgroundTrendColor = (trend: TrendTrajectory): string => {
            switch (trend) {
                case TrendTrajectory.Upwards:
                    return theme['success-low-contrast'];
                case TrendTrajectory.Downwards:
                    return theme['error-low-contrast'];
                case TrendTrajectory.Stagnant:
                    return theme['primary-low-contrast'];
            }
        };

        const getValueTrendcolor = (trend: TrendTrajectory): string => {
            switch (trend) {
                case TrendTrajectory.Upwards:
                    return theme['success-mid-contrast'];
                case TrendTrajectory.Downwards:
                    return theme['error-mid-contrast'];
                case TrendTrajectory.Stagnant:
                    return theme['layout-mid-contrast'];
            }
        };

        const setTrendObjectState = (trendValue: number) => {
            if (trendValue < 2 && trendValue > -2) {
                setTrend({
                    trajectory: TrendTrajectory.Stagnant,
                    value:
                        trendValue >= 0 ? `+${trendValue.toFixed(0)}` : `${trendValue.toFixed(0)}`,
                    graphTrendColor: getIconTrendColor(TrendTrajectory.Stagnant),
                    valueTrendColor: getValueTrendcolor(TrendTrajectory.Stagnant),
                });
            } else {
                const trajectory =
                    trendValue > 0 ? TrendTrajectory.Upwards : TrendTrajectory.Downwards;
                setTrend({
                    trajectory: trajectory,
                    value: trendValue.toFixed(0),
                    graphTrendColor: getIconTrendColor(trajectory),
                    valueTrendColor: getValueTrendcolor(trajectory),
                });
            }
        };

        const calculateTrendFromPercentages = (currentValue: number, lastPeriodValue: number) => {
            const trendValue = currentValue - lastPeriodValue;
            setTrendObjectState(trendValue);
        };

        const calculateTrendFromValues = (
            currentValue?: number,
            totalCurrent?: number,
            lastPeriodValue?: number,
            totalLastPeriod?: number
        ) => {
            const trendValue =
                ((currentValue ?? 0) / denominatorSafeValue(totalCurrent) -
                    (lastPeriodValue ?? 0) / denominatorSafeValue(totalLastPeriod)) *
                100;
            setTrendObjectState(trendValue);
        };

        const dataForTimeSpent = (
            valueData: GetOfficeUtilizationStatsResponseDto,
            lastPeriodData: GetOfficeUtilizationStatsResponseDto
        ) => {
            const timeSpent =
                ((valueData.groups?.find((group) => group?.info?.workType === WorkTypeDto.Office)
                    ?.totalHours ?? 0) /
                    denominatorSafeValue(valueData.totalHours)) *
                100;

            const lastPeriodTimeSpent =
                ((lastPeriodData.groups?.find(
                    (group) => group?.info?.workType === WorkTypeDto.Office
                )?.totalHours ?? 0) /
                    denominatorSafeValue(lastPeriodData.totalHours)) *
                100;

            calculateTrendFromPercentages(timeSpent, lastPeriodTimeSpent);

            setValue(valueData.totalHours === 0 ? '0%' : `${timeSpent.toFixed(0)}%`);
        };

        const dataForPopularDay = (
            valueData: GetOfficeUtilizationStatsResponseDto,
            lastPeriodData: GetOfficeUtilizationStatsResponseDto
        ) => {
            //sort by number of users, then by hours if there is equality
            const mostPopularDayGroup = valueData?.groups?.sort(
                (a, b) =>
                    (b?.totalUsers ?? 0) - (a?.totalUsers ?? 0) ||
                    (b?.totalHours ?? 0) - (a?.totalHours ?? 0)
            )?.[0];

            const dataForSameDayLastPeriod = lastPeriodData?.groups?.find(
                (group) => group?.info?.dayOfWeek === mostPopularDayGroup?.info?.dayOfWeek
            );

            calculateTrendFromValues(
                mostPopularDayGroup?.totalUsers,
                valueData?.totalUsers,
                dataForSameDayLastPeriod?.totalUsers,
                lastPeriodData.totalUsers
            );

            setValue(
                dayOfWeekLangMap(
                    mostPopularDayGroup?.info?.dayOfWeek ?? DayOfWeekDto.Monday,
                    languageStore.currentLanguage
                )
            );
        };

        const dataForPopularWorkPeriod = (
            valueData: GetOfficeUtilizationStatsResponseDto,
            lastPeriodData: GetOfficeUtilizationStatsResponseDto
        ) => {
            //sort by number of users, then by hours if there is equality
            const mostPopularWorkPeriod = valueData?.groups?.sort(
                (a, b) =>
                    (b?.totalUsers ?? 0) - (a?.totalUsers ?? 0) ||
                    (b?.totalHours ?? 0) - (a?.totalHours ?? 0)
            )?.[0];

            const dataForSamePeriodFromLast = lastPeriodData.groups?.find(
                (group) =>
                    moment.utc(group?.info?.fourHoursPeriodStartTime).hour() ===
                    moment.utc(mostPopularWorkPeriod?.info?.fourHoursPeriodStartTime).hour()
            );

            calculateTrendFromValues(
                mostPopularWorkPeriod?.totalUsers,
                valueData.totalUsers,
                dataForSamePeriodFromLast?.totalUsers,
                lastPeriodData.totalUsers
            );

            setValue(`
                ${moment
                    .utc(mostPopularWorkPeriod?.info?.fourHoursPeriodStartTime)
                    .format(
                        FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE[languageStore.currentLanguage]
                    )} - ${moment
                .utc(mostPopularWorkPeriod?.info?.fourHoursPeriodStartTime)
                .add(4, 'hours')
                .format(FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE[languageStore.currentLanguage])} `);
        };

        const dataForPopularOffice = (
            valueData: GetOfficeUtilizationStatsResponseDto,
            lastPeriodData: GetOfficeUtilizationStatsResponseDto
        ) => {
            const mostPopularOffice = valueData.groups?.sort(
                (a, b) => (b?.totalHours ?? 0) - (a?.totalHours ?? 0)
            )?.[0];

            const lastPeriodSameOfficeData = lastPeriodData.groups?.find(
                (group) => group?.info?.office?.id === mostPopularOffice?.info?.office?.id
            );

            calculateTrendFromValues(
                mostPopularOffice?.totalUsers,
                valueData?.totalUsers,
                lastPeriodSameOfficeData?.totalUsers,
                lastPeriodData.totalUsers
            );

            setValue(mostPopularOffice?.info?.office?.name ?? t('unspecified_office'));
        };

        const parseApiDataForChart = useCallback(
            (
                valueData: GetOfficeUtilizationStatsResponseDto,
                lastPeriodData: GetOfficeUtilizationStatsResponseDto
            ) => {
                switch (requestType) {
                    case StatsRequestTypeEnum.TimeSpentHighlight:
                        dataForTimeSpent(valueData, lastPeriodData);
                        break;
                    case StatsRequestTypeEnum.PopularDayHighlight:
                        dataForPopularDay(valueData, lastPeriodData);
                        break;
                    case StatsRequestTypeEnum.PopularWorkPeriodHighlight:
                        dataForPopularWorkPeriod(valueData, lastPeriodData);
                        break;
                    case StatsRequestTypeEnum.PopularOfficeHighlight:
                        dataForPopularOffice(valueData, lastPeriodData);
                        break;
                }
                setLoadingData(false);
            },
            [dataProp?.valueData, requestType]
        );

        useEffect(() => {
            if (dataProp?.valueData && dataProp?.lastPeriodData && requestType)
                parseApiDataForChart(dataProp.valueData, dataProp.lastPeriodData);
        }, [dataProp?.lastPeriodData, dataProp?.valueData, requestType]);

        const handleFetchStats = async (requestType: StatsRequestTypeEnum) => {
            setLoadingData(true);
            addToFetchQueue({
                fetchFunction: async () =>
                    fetchStats(
                        {
                            start: TimeRangeFilterMap.get(TimeRangeKey.Last4Weeks)?.start ?? '',
                            end: TimeRangeFilterMap.get(TimeRangeKey.Last4Weeks)?.end ?? '',
                            key: TimeRangeKey.Last4Weeks,
                        },
                        requestType,
                        'all'
                    ),
                fetchId: uniqueId(requestType),
                callback: () => {
                    /*nothing to do here*/
                },
            });

            //consider loading to be done only after parsing data in parseApiDataForChart()
        };

        useEffect(() => {
            if (userStore.userInfo?.timeZone) handleFetchStats(requestType);
        }, [userStore.userInfo?.timeZone, selectedTeams, requestType]);

        return (
            <div className="HighlightCard">
                <Skeleton isLoaded={!loadingData} placeholder={realEstateHighLightsShape}>
                    <WhiteCard>
                        <div className="top-container">
                            <div>
                                <div className="text-callout-bold text-mid-contrast">{title}</div>
                                <div className="text-body-bold value">
                                    {value}
                                    <span
                                        style={{
                                            color: trend.valueTrendColor,
                                            fontSize: 15,
                                        }}
                                    >
                                        {trend.trajectory === TrendTrajectory.Upwards && '+'}
                                        {trend.value}%
                                    </span>
                                </div>
                            </div>
                            <div
                                className="icon-container"
                                style={{
                                    backgroundColor: getIconBackgroundTrendColor(trend.trajectory),
                                }}
                            >
                                {
                                    <Icon
                                        iconName={iconName}
                                        fill={getIconTrendColor(trend.trajectory)}
                                        width={24}
                                        height={24}
                                    />
                                }
                            </div>
                        </div>
                    </WhiteCard>
                </Skeleton>
            </div>
        );
    }
);

export default HighlightCard;
