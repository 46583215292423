import React from 'react';
import './index.less';

export const eventSkeletonShape = (
    <div className="eventSkeletonShape">
        <div className="wrapper-one-section">
            <div className="left"></div>
            <div className="right">
                <div className="rect-large" />
                <div className="rect-1" />
                <div className="wrapper-row">
                    <div className="rect-1" />
                    <div className="rect-1" />
                </div>

                <div className="separator" />

                <div className="rect-1 mb-10" />
                <div className="rect-1" />
                <div className="rect-button" />
            </div>
        </div>
    </div>
);
