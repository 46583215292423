import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ClubSocialIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M3 16.5V3.9C3 3.645 3.0864 3.4311 3.2592 3.2583C3.4314 3.0861 3.645 3 3.9 3H15.6C15.855 3 16.0686 3.0861 16.2408 3.2583C16.4136 3.4311 16.5 3.645 16.5 3.9V12C16.5 12.255 16.4136 12.4686 16.2408 12.6408C16.0686 12.8136 15.855 12.9 15.6 12.9H6.6L3 16.5ZM7.5 17.4C7.245 17.4 7.0311 17.3136 6.8583 17.1408C6.6861 16.9686 6.6 16.755 6.6 16.5V14.7H18.3V6.6H20.1C20.355 6.6 20.5686 6.6861 20.7408 6.8583C20.9136 7.0311 21 7.245 21 7.5V21L17.4 17.4H7.5ZM14.7 4.8H4.8V12.1575L5.8575 11.1H14.7V4.8Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ClubSocialIcon;
