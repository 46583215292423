import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect } from 'react';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';

/**
 * SignInCallback
 * Azure AD will redirect here once the "sign-in" flow is finished
 */
const SignInCallback: FunctionComponent = observer(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    const { languageStore } = useStores();

    useEffect(() => {
        const signInCallback = async () => {
            // Check if success or error.
            const error = urlParams.get('error');
            const errorDescription = urlParams.get('error_description');

            if (!error) {
                // Sign-in successful, now we try to obtain a token.
                await azureAdAuthenticationService.handleAuthorizeCallback();
            } else {
                // User cancelled (can happen in embedded the reset password flow).
                if (errorDescription?.startsWith('AADB2C90091')) {
                    await azureAdAuthenticationService.runSignInFlow(languageStore.currentLanguage);
                }
                // User clicked on "forgot password" on the sign-in page
                // Note: This will not happen when the "forgot password" flow is embedded in the sign in flow, but we keep this just in case.
                else if (error === 'access_denied' && errorDescription?.startsWith('AADB2C90118')) {
                    await azureAdAuthenticationService.runResetPasswordFlow(
                        languageStore.currentLanguage
                    );
                }

                // Other error, show error page?
            }
        };

        signInCallback();
    }, []);

    return <div className="SignInCallback"></div>;
});

export default SignInCallback;
