import Icon from 'Components/icons/Icon';
import { RichTextDisplay } from 'Components/rich-text-display/rich-text-display';
import Tag, { TagColor } from 'Components/tag/tag';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ClubDetailsInfosMembers, {
    ClubDetailsInfosMembersProps,
} from '../club-details-infos-members';
import './index.less';

export interface ClubDetailsInfosProps {
    clubInfos: {
        name: string;
        description: string;
        isJoined: boolean;
        isPrivate: boolean;
    };
    clubMembers: ClubDetailsInfosMembersProps;
}

const ClubDetailsInfos: React.FunctionComponent<ClubDetailsInfosProps> = ({
    clubInfos,
    clubMembers,
}) => {
    const { t } = useTranslation();

    return (
        <div className="ClubDetailsInfos">
            <div className="club-name">
                {clubInfos.isPrivate && <Icon iconName="PadlockIcon" />} {clubInfos.name}{' '}
                {clubInfos.isJoined && (
                    <Tag text={t('Clubs.club_tag_joined')} color={TagColor.blue} />
                )}
            </div>

            <ClubDetailsInfosMembers
                total={clubMembers.total}
                favTotal={clubMembers.favTotal}
                imageUrls={clubMembers.imageUrls}
                viewAllOnClick={clubMembers.viewAllOnClick}
            />

            <div className="club-description-title">{t('description')}</div>
            <RichTextDisplay html={clubInfos.description} />
        </div>
    );
};

export default memo(ClubDetailsInfos);
