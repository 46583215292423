import React, { FunctionComponent } from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import './advanced-filters-inline.less'

interface AdvancedFiltersInlineProps {
    filters: AdvancedFilter[];
    onItemChange: (key: string, parentKey: string) => void;
}

const AdvancedFiltersInline: FunctionComponent<AdvancedFiltersInlineProps> = observer(
    ({ filters, onItemChange }) => {
        const { t } = useTranslation();

        return (
            <div className="AdvancedFiltersInline">
                <div className="text-callout-bold mt-20 mb-20">{t('advanced_filters')}</div>
                <div className="section-filters">
                    {filters.map((parentFilter, index) => {
                        return (
                            <div key={parentFilter.key} className="one-section-filter">
                                <div className={`title text-body-bold ${index > 0 ? 'ml-40' : ''}`}>
                                    {parentFilter.nameKey}:
                                </div>
                                <div className="container-items">
                                    {parentFilter.items.map((filter) => {
                                        return (
                                            <div
                                                className="text-callout"
                                                key={filter.key}
                                                onClick={() =>
                                                    onItemChange(filter.key, parentFilter.key)
                                                }
                                            >
                                                <Checkbox checked={filter.checked} />
                                                {filter.displayNameKey}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
);

export default AdvancedFiltersInline;
