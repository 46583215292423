import React, { FunctionComponent, useEffect } from 'react';
import { autorun } from 'mobx';
import { useStores } from '../../Hooks';
import { message } from 'antd';
import './toast-container.less'
import { CheckmarkIcon, CloseIcon } from 'Components/icons';

const ToastContainer: FunctionComponent = () => {
    const { toastStore } = useStores();
    useEffect(
        () =>
            autorun(() => {
                if (toastStore.currentToast) {
                  message[toastStore.currentToast.type]({
                    content:
                      toastStore.currentToast.message ||
                      toastStore.currentToast.messageKey ||
                      "",
                    className: "custom-toast",
                    icon: toastStore.currentToast.type === 'success' ? <CheckmarkIcon /> : <CloseIcon />,
                    duration: 5
                  });
                }
            }),
        [toastStore.currentToast]
    );
    return null;
};

export default ToastContainer;
