//to enable color referencing in components

export const theme = {
    'primary-high-contrast': '#0D51A5',
    'primary-mid-contrast': '#2F84EE',
    'primary-light-contrast': '#5A9DF2',
    'primary-low-contrast': '#D4E6FC',
    'primary-very-low-contrast': '#ECF4FE',

    'accent-high-contrast': '#2D0D8C',
    'accent-mid-contrast': '#6B40ED',
    'accent-low-contrast': '#E9E8FD',
    'accent-light-contrast': '#7953EF',

    'error-high-contrast': '#6E1C11',
    'error-mid-contrast': '#D7382D',
    'error-low-contrast': '#FDE3DF',

    'success-high-contrast': '#0E3F24',
    'success-mid-contrast': '#37BE6E',
    'success-light-contrast': '#59CF89',
    'success-low-contrast': '#E1FAEB',

    'warning-high-contrast': '#725409',
    'warning-mid-contrast': '#F2C552',
    'warning-low-contrast': '#FBEFD0',

    'layout-mid-main': '#2F84EE',
    'layout-mid-accent': '#6B40ED',
    'layout-no-contrast': '#FFFFFF',

    'layout-super-light': '#FAFAFA',
    'layout-low-contrast': '#F3F3F3',
    'layout-mid-contrast': '#CCCCCC',
    'layout-high-contrast': '#434343',

    //font colors
    'text-high-contrast': '#1A1A1A',
    'text-mid-contrast': '#737373',
    'text-low-contrast': '#F3F3F3',
    'text-disabled': '#CCCCCC',

    white: '#ffffff',
    black: '#000000',
};
