import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const CircleCheckmarkIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="-2 -2 28 28" fill="none" {...props}>
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                    fill={fill}
                />
                <path
                    d="M19.4063 6.19869C19.77 6.50174 19.8191 7.04223 19.516 7.40589L10.9446 17.6916C10.7904 17.8766 10.5655 17.9882 10.325 17.9991C10.0844 18.0101 9.85033 17.9193 9.68004 17.749L4.53718 12.6061C4.20245 12.2714 4.20245 11.7287 4.53718 11.3939C4.87192 11.0592 5.41463 11.0592 5.74937 11.3939L10.2285 15.8731L18.1991 6.30843C18.5021 5.94477 19.0426 5.89563 19.4063 6.19869Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default CircleCheckmarkIcon;
