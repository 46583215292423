import { DayOfWeekDto } from 'Api/Features/General/Dtos/DayOfWeekDto';
import Icon from 'Components/icons/Icon';
import Skeleton from 'Components/skeleton';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dayOfWeekLangMap } from 'Utils/TimeUtils';
import DashboardPopularBloc from '../dashboard-popular-bloc';
import './index.less';

export interface Team {
    popularDay: DayOfWeekDto;
    popularWorkPeriod: string;
}

interface DashboardTeamProps {
    className?: string;
    data?: Team;
    isLoaded?: boolean;
}

const DashboardTeam: React.FunctionComponent<DashboardTeamProps> = observer(
    ({ className, data, isLoaded }) => {
        const { languageStore } = useStores();
        const { t } = useTranslation();

        return (
            <Skeleton isLoaded={isLoaded} placeholder={<div />}>
                {data ? (
                    <div className={`DashboardTeam ${className ?? ''}`}>
                        <div className="title">
                            <div className="text-title-2">{t('Dashboard.team_highlights')}</div>
                            <div className="text-callout">{t('PlanSchedule.work_at_office')}</div>
                        </div>

                        <Icon iconName="PeopleFill3Icon" />

                        <DashboardPopularBloc
                            title={t('Dashboard.most_popular_day')}
                            text={dayOfWeekLangMap(data.popularDay, languageStore.currentLanguage)}
                            iconName="CalendarIcon"
                        />

                        <DashboardPopularBloc
                            title={t('Dashboard.most_popular_work_period')}
                            text={data.popularWorkPeriod}
                            iconName="ClockIcon"
                        />
                    </div>
                ) : (
                    <div
                        className={`${className ?? ''}`}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: 15,
                        }}
                    >
                        <Icon iconName="PeopleFill3Icon" fill="white" width={56} height={56} />
                        <div className="text-title-2" style={{ textAlign: 'center' }}>
                            {t('not_linked_to_a_team_yet')}
                        </div>
                    </div>
                )}
            </Skeleton>
        );
    }
);

export default DashboardTeam;
