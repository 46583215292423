import { inject } from 'aurelia-dependency-injection';
import { action, observable } from 'mobx';
import moment from 'moment';
import { SystemService } from 'Services/SystemService';
import { SupportedLanguage } from './LanguageStore';

@inject(SystemService)
class SystemStore {
    constructor(private readonly systemService: SystemService) {}
    private lastDefaultSystemLangCall: string | undefined = undefined;
    private defaultSystemLangPromise: any;

    @observable
    public defaultSystemLanguage: SupportedLanguage =
        localStorage.defaultSystemLang ?? SupportedLanguage.EN;

    @action
    async setDefaultSystemLang(): Promise<void> {
        const response = await this.systemService.getDefaultLanguage();
        if (response?.defaultLanguageCode)
            this.defaultSystemLanguage = response.defaultLanguageCode as SupportedLanguage;
    }

    @action
    async cachedSetDefaultSystemLang(): Promise<void> {
        if (
            this.lastDefaultSystemLangCall === undefined ||
            moment(this.lastDefaultSystemLangCall).add(10, 'minute').isBefore(moment())
        ) {
            if (!this.defaultSystemLangPromise) {
                this.defaultSystemLangPromise = this.setDefaultSystemLang();
            }

            await this.defaultSystemLangPromise;
            this.defaultSystemLangPromise = null;
        }
    }
}

export default SystemStore;
