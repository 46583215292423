import { Input } from 'antd';
import { GetHappeningAttendeesRequestDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesRequestDto';
import { GetHappeningAttendeesResponseItemDto } from 'Api/Features/Happenings/Dtos/GetHappeningAttendeesResponseItemDto';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import { MagnifyingGlassIcon } from 'Components/icons';
import ImageWithPlaceholder from 'Components/image-with-placeholder/image-with-placeholder';
import BaseModal from 'Components/modal';
import ScrollInfinite from 'Components/scroll-infinite';
import Tag, { TagColor } from 'Components/tag/tag';
import { FavToggle } from 'Components/toggle';
import { useService, useStores, useDebounce } from 'Hooks';
import { PAGE_SIZE_INFINITE_LOADING } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    GetHappeningAttendeesResponseDtoNonNull,
    HappeningService,
} from 'Services/HappeningService';
import { theme } from 'Style/theme';
import { img, ImgSize } from 'Utils/ImgUtils';
import './index.less';

interface ModalAttendingCoworkersProps {
    happening: HappeningDto;
    onClose: () => void;
}

const ModalAttendingCoworkers: React.FunctionComponent<ModalAttendingCoworkersProps> = ({
    happening,
    onClose,
}) => {
    const [happeningAttendees, setHappeningAttendees] = useState<any>([]);
    const happeningSerivce = useService(HappeningService);
    const { userStore, toastStore } = useStores();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const debounceSearch = useDebounce(searchTerm);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const fetchUserInHappening = async () => {
        if (!happening.id) return;

        try {
            setIsLoading(true);
            const request: GetHappeningAttendeesRequestDto = {
                favoritesForUserId: userStore.userInfo?.id,
                searchTerm: searchTerm ? searchTerm : undefined,
            };

            const response: GetHappeningAttendeesResponseDtoNonNull =
                await happeningSerivce.getHappeningAttendees(happening.id, request);

            if (response.items.length > 0)
                setHappeningAttendees((prev: GetHappeningAttendeesResponseItemDto[]) => {
                    return [
                        ...prev,
                        ...response.items.map((item) => {
                            if (item.id === userStore.userInfo?.id)
                                return {
                                    ...item,
                                    lastName: item.lastName + ` ${t('parenthesis_me')}`,
                                };
                            else return item;
                        }),
                    ];
                });

            setTotalItemCount(response.totalItemCount);
        } catch (err: any) {
            if (!err.treated) toastStore.genericError();
        } finally {
            setIsLoading(false);
        }
    };

    const onChangeSearchBar = (evt) => {
        const { value } = evt.target;

        setSearchTerm(value);
        setHappeningAttendees([]);
        setCurrentPage(0);
    };

    const handlecurrentPage = (action: 'next' | 'prev') => {
        const maxIsAlreadyLoaded =
            totalItemCount > 0 ? happeningAttendees.length >= totalItemCount : false;

        if (action === 'prev' && currentPage === 0) return;
        if (maxIsAlreadyLoaded) return;

        if (action === 'next') return setCurrentPage((prev) => prev + 1);
        setCurrentPage((prev) => prev - 1);
    };

    useEffect(() => {
        fetchUserInHappening();
    }, [debounceSearch]);

    return (
        <BaseModal
            className="ModalAttendingCoworkers"
            visible
            onCancel={onClose}
            headerText={happening.name || ''}
            oustideOverflow={{
                element: (
                    <div className="sub-header center">
                        <div className="header-btn">
                            <Input
                                className="search search-bar"
                                prefix={
                                    <MagnifyingGlassIcon
                                        width={22}
                                        height={22}
                                        fill={theme['primary-mid-contrast']}
                                    />
                                }
                                onChange={onChangeSearchBar}
                                placeholder="Search"
                                name="name"
                            />
                        </div>
                    </div>
                ),
                height: 100,
            }}
            fixedHeight
        >
            <div className="container-content">
                <ScrollInfinite
                    items={happeningAttendees}
                    RenderItem={({ item }) => (
                        <div className="container-attendees">
                            {item.id !== userStore.userInfo?.id ? (
                                <FavToggle
                                    coworkerId={item?.id}
                                    relationId={item?.favoriteInfo?.id}
                                />
                            ) : (
                                <div className="container-attendees-fav-placeholder" />
                            )}
                            <ImageWithPlaceholder
                                imgSrc={item?.imageUrl ? img(item?.imageUrl, ImgSize.s) : undefined}
                                width={32}
                                height={32}
                            />
                            <span className="text-body">
                                {item?.firstName} {item?.lastName}
                            </span>
                            {item.numberOfCompanions > 0 ? (
                                <Tag text={`+${item.numberOfCompanions}`} color={TagColor.purple} />
                            ) : null}
                        </div>
                    )}
                    RenderItemSkeleton={() => <div />} // TODO https://appcom.atlassian.net/browse/FLEXY-2520
                    handlePagination={handlecurrentPage}
                    paginationOptions={{
                        pageSize: PAGE_SIZE_INFINITE_LOADING,
                        isLoading: isLoading,
                    }}
                />
            </div>
        </BaseModal>
    );
};

export default ModalAttendingCoworkers;
