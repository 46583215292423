import OnboardingCheckmark from 'Assets/Images/onboarding-checkmark.png';
import Button from 'Components/button';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingBaseProps } from '..';
import './base-styles.less';
import './finish-step.less';

const OnboardingFinishStep: React.FunctionComponent<OnboardingBaseProps> = observer(
    ({ onNextClick, onPreviousClick }) => {
        const { t } = useTranslation();

        const handleNextClick = () => {
            onNextClick();
        };

        return (
            <div className="OnboardingFinishStep OnboardingBase">
                <div className="content">
                    <div className="text-container">
                        <div className="title text-x-large-title-bold">
                            {t('Onboarding.youre_set_up')}
                        </div>
                        <div className="text-callout">{t('Onboarding.finish_step')}</div>
                    </div>

                    <img src={OnboardingCheckmark} />
                </div>

                <div className="actions">
                    <div className="actions-wrapper">
                        <Button text={t('previous')} type="tertiary" onClick={onPreviousClick} />
                        <Button
                            text={t('go_to_flexy')}
                            type="primary"
                            onClick={() => handleNextClick()}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default OnboardingFinishStep;
