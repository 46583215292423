import { Tooltip } from 'antd';
import Button from 'Components/button';
import Icon from 'Components/icons/Icon';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';

interface DisplayProps {
    iconName: string;
    label: string;
    value: string;
    newTab: boolean;
}

export interface DisplayContactPreferencesData {
    slack?: string;
    calendly?: string;
    email?: string;
    phoneNumber?: string;
}

interface DisplayContactPreferencesProps {
    data: DisplayContactPreferencesData;
    simpleMode?: boolean;
}

const DisplayContactPreferences: React.FunctionComponent<DisplayContactPreferencesProps> = ({
    data,
    simpleMode,
}) => {
    const { t } = useTranslation();

    const mappedData = [
        {
            iconName: 'SlackIcon',
            label: t('ContactMethodTypeDto.ContactMethodTypeDto_Slack'),
            value: data.slack,
            newTab: true,
        },
        {
            iconName: 'CalendlyIcon',
            label: t('ContactMethodTypeDto.ContactMethodTypeDto_Calendly'),
            value: data.calendly,
            newTab: true,
        },
        {
            iconName: 'EnvelopeIcon',
            label: data.email,
            value: data.email ? `mailto:${data.email}` : undefined,
            newTab: true,
        },
        {
            iconName: 'Phone',
            label: data.phoneNumber,
            value: data.phoneNumber ? `tel:${data.phoneNumber}` : undefined,
        },
    ];

    const cleanedMappedData = mappedData.filter(
        (item): item is DisplayProps => item.value !== undefined
    );

    return (
        <div className={`DisplayContactPreferences simpleMode-${simpleMode}`}>
            <div className="title">{t('contact_preferences')}</div>

            {simpleMode &&
                cleanedMappedData.map((item, i) => (
                    <Tooltip
                        key={item.iconName + i}
                        overlay={item.label}
                        className="link-tooltip"
                        placement="top"
                        arrowPointAtCenter={true}
                    >
                        <div
                            onClick={() =>
                                item.newTab
                                    ? window.open(item.value, '_blank')
                                    : (window.location.href = item.value)
                            }
                        >
                            <Icon iconName={item.iconName} />
                        </div>
                    </Tooltip>
                ))}

            {!simpleMode &&
                cleanedMappedData.map((item, i) => (
                    <Button
                        key={item.iconName + i}
                        type="link"
                        leftCircleIcon={item.iconName}
                        text={simpleMode ? '' : item.label}
                        externalLink={{ url: item.value, newTab: item.newTab }}
                    />
                ))}
        </div>
    );
};

export default memo(DisplayContactPreferences);
