import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { countries, languages } from 'appcom-globalization';
import { timeZoneUtil } from 'appcom-timezones';
import moment from 'moment';

/**
 * Language options for language dropdowns
 */
export const languageOptions = (lang: SupportedLanguage): MultiSelectCustomOption[] => {
    const intl = new Intl.DisplayNames([lang.toLocaleLowerCase()], { type: 'language' });
    const options = languages
        .filter((lang) => lang.twoLetterCode !== 'EN')
        .map(
            (lang) =>
                ({
                    value: lang.twoLetterCode,
                    label:
                        (intl.of(lang.twoLetterCode)?.charAt(0).toUpperCase() ?? '') +
                        intl.of(lang.twoLetterCode)?.slice(1), // capitalise first letter
                    checkBox: true,
                } as MultiSelectCustomOption)
        );

    options.unshift({
        value: 'EN',
        label: (intl.of('EN')?.charAt(0).toUpperCase() ?? '') + intl.of('EN')?.slice(1),
        checkBox: true,
    } as MultiSelectCustomOption);

    return options;
};

/**
 * User languages in current language
 */
export const displayLanguages = (
    languageCodes: (string | null)[] | null | undefined,
    currentLanguage: SupportedLanguage
): string | undefined => {
    const intl = new Intl.DisplayNames([currentLanguage.toLocaleLowerCase()], { type: 'language' });
    return languageCodes
        ? languageCodes
              .filter((lang) => lang !== null && lang !== undefined)
              .map((lang) => lang!)
              .map(
                  (lang) => (intl.of(lang)?.charAt(0).toUpperCase() ?? '') + intl.of(lang)?.slice(1)
              )
              .join(', ')
        : undefined;
};

/**
 * Timezone options for time zone dropdowns
 */
export const timeZonesOptions = (lang: SupportedLanguage): SingleSelectCustomOption[] => {
    return timeZoneUtil.getTimeZones(lang.toLocaleLowerCase()).map((option) => ({
        value: option.id,
        label: `(GMT${moment.tz(new Date(), option.id).format('Z')}) ${option.description}`,
    }));
};

/**
 * Country options for country dropdowns
 */
export const countryOptions = (lang: SupportedLanguage): SingleSelectCustomOption[] => {
    const intl = new Intl.DisplayNames([lang.toLocaleLowerCase()], { type: 'region' });
    const options = countries.map(
        (lang) =>
            ({
                value: lang.twoLetterCode,
                label:
                    (intl.of(lang.twoLetterCode)?.charAt(0).toUpperCase() ?? '') +
                    intl.of(lang.twoLetterCode)?.slice(1), // capitalise first letter
            } as MultiSelectCustomOption)
    );

    return options;
};

/**
 * Country name in current language from country code
 */
export const countryNameIntlFromCountryCode = (
    lang: SupportedLanguage,
    countryTwoLetterCode: string
): string => {
    if (countryTwoLetterCode === '') return '';
    const intl = new Intl.DisplayNames([lang.toLocaleLowerCase()], { type: 'region' });
    return intl.of(countryTwoLetterCode) ?? '';
};
