import { Col, Form, Input, Row } from 'antd';
import { ContactMethodTypeDto } from 'Api/Features/General/Dtos/ContactMethodTypeDto';
import { ContactPreferencesMethodDto } from 'Api/Features/General/Dtos/ContactPreferencesMethodDto';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { GetTeamsRequestDto } from 'Api/Features/Teams/Dtos/GetTeamsRequestDto';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { CreateUserRequestRoleDto } from 'Api/Features/Users/Dtos/CreateUserRequestRoleDto';
import { GetUserSlackInfoResponseDto } from 'Api/Features/Users/Dtos/GetUserSlackInfoResponseDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { UserBadgeDto } from 'Api/Features/Users/Dtos/UserBadgeDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Button from 'Components/button';
import GooglePlacesAutoComplete from 'Components/google-places-autocomplete';
import { EnvelopeIcon, PencilIcon } from 'Components/icons';
import Modal, { ModalHeaderColor } from 'Components/modal';
import ProfileImagePicker from 'Components/profile-image-picker/profile-image-picker';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import StaticMultiSelect from 'Components/select-custom/multi-select/static-multi-select';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useAsyncSingleSelectProps, useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORM_GUTTER } from 'Models/Constants';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateUserSchema } from 'Schemas/EditProfileSchema';
import { TeamService } from 'Services/TeamService';
import { UserService } from 'Services/UserService';
import { theme } from 'Style/theme';
import { moveArrayElement } from 'Utils/ArrayUtils';
import {
    getContactPreferencesMethodArray,
    getEachContactPreferencesMethod,
} from 'Utils/ContactPreferencesUtils';
import './create-user-modal.less';
import PreferredWorkplaceToggle from 'Routes/profile/components/preferred-workplace-toggle/preferred-workplace-toggle';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeService } from 'Services/OfficeService';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import Divider from 'Components/divider';
import { displayAddress } from 'Utils/TextUtils';
import { WorkTypeDto } from 'Api/Features/Schedules/Dtos/WorkTypeDto';
import { UserPreferredWorkTypeDto } from 'Api/Features/Users/Dtos/UserPreferredWorkTypeDto';
import { languageOptions, timeZonesOptions } from 'Utils/IntlUtils';
import SubmitButton from 'Components/submit-button/submit-button';

interface CreateUserModalProps {
    visible: boolean;
    onComplete: (success: boolean, id?: string) => void;
    editUserObject?: UserDetailsDto;
}

const contactPreferencesInit = [
    { type: ContactMethodTypeDto.PhoneNumber, value: undefined, enabled: false },
    { type: ContactMethodTypeDto.Email, value: undefined, enabled: false },
    { type: ContactMethodTypeDto.Slack, value: undefined, enabled: false },
    { type: ContactMethodTypeDto.Calendly, value: undefined, enabled: false },
];

// Two ways of using this modal
// 1- admin is creating a user
// 2- admin is editing a user

const CreateUserModal: React.FunctionComponent<CreateUserModalProps> = observer(
    ({ visible, onComplete, editUserObject }) => {
        const { globalLoadingStore, toastStore, systemStore, languageStore } = useStores();
        const userService = useService(UserService);
        const teamService = useService(TeamService);
        const officeService = useService(OfficeService);
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors] = useFormValidation(CreateUserSchema, form);
        const { t } = useTranslation();
        const [imageUrl, setImageUrl] = useState<string | undefined>(
            editUserObject ? editUserObject?.imageUrl ?? undefined : undefined
        );
        const [base64, setBase64] = useState<string>();
        const [imageDeleted, setImageDeleted] = useState(false);
        const [changePictureModalVisible, setChangePictureModalVisible] = useState(false);
        const [locationGooglePlaceId, setLocationGooglePlaceId] = useState<any>();
        const [selectedtimeZone, setSelectedTimeZone] = useState<string>();
        const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
        const [selectedBadgeIds, setSelectedBadgeIds] = useState<UserBadgeDto[]>([]);
        const [slackInfo, setSlackInfo] = useState<GetUserSlackInfoResponseDto>();
        const [selectedRole, setSelectedRole] = useState<ManagementRoleDto | undefined>(
            editUserObject?.managementRoles?.[0]?.name ?? ManagementRoleDto.None
        );
        const [preferredWorkplace, setPreferredWorkplace] = useState<UserPreferredWorkTypeDto>(
            UserPreferredWorkTypeDto.Office
        );
        const [showDefaultOfficeForm, setShowDefaultOfficeForm] = useState(false);

        const [contactPreferences, setContactPreferences] =
            useState<(ContactPreferencesMethodDto | null)[]>(contactPreferencesInit);

        const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficesRequestDto) =>
                    await officeService.getOffices(request),
            },
            entityToSingleSelectCustomOption: (office: OfficeDto) =>
                ({
                    value: office.id,
                    label: office.name,
                    extraData: office.address,
                } as SingleSelectCustomOption),
            defaultSelectedOption: editUserObject?.defaultOffice
                ? {
                      value: editUserObject.defaultOffice?.id ?? '',
                      label: editUserObject.defaultOffice?.name ?? '',
                      extraData: editUserObject.defaultOffice?.address,
                  }
                : undefined,
        });

        const { asyncSingleSelectProps: spaceSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficeSpacesRequestDto) =>
                    await officeService.getOfficeSpaces(request),
                fetchFunctionExtraParams: {
                    officeIds: officeSelectProps.selected
                        ? [officeSelectProps.selected]
                        : undefined,
                },
            },
            entityToSingleSelectCustomOption: (space: OfficeSpaceDto) =>
                ({
                    value: space.id,
                    label: space.name,
                } as SingleSelectCustomOption),
            defaultSelectedOption: editUserObject?.defaultOfficeSpace
                ? {
                      value: editUserObject.defaultOfficeSpace?.id ?? '',
                      label: editUserObject.defaultOfficeSpace?.name ?? '',
                  }
                : undefined,
        });

        // team dropdownState if creating
        const { asyncSingleSelectProps: teamSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetTeamsRequestDto) =>
                    await teamService.getTeams(request),
            },
            entityToSingleSelectCustomOption: (team: TeamDto) =>
                ({ label: team.name, value: team.id } as SingleSelectCustomOption),
            defaultSelectedOption: editUserObject
                ? {
                      value: editUserObject?.team?.id ?? '',
                      label: editUserObject?.team?.name ?? '',
                  }
                : undefined,
        });

        const badgesListOptions: MultiSelectCustomOption[] = (
            Object.keys(UserBadgeDto) as Array<keyof typeof UserBadgeDto>
        ).map(
            (key) =>
                ({
                    value: key,
                    label: t(`Coworker.badges_${key}`),
                    content: {
                        node: <div>{t(`UserBadgeDto.UserBadgeDto_${key}_explanation`)}</div>,
                    },
                } as MultiSelectCustomOption)
        );

        const roleOptions: MultiSelectCustomOption[] = moveArrayElement(
            Object.keys(ManagementRoleDto),
            Object.keys(ManagementRoleDto).indexOf(ManagementRoleDto.Administrator),
            Object.keys(ManagementRoleDto).length - 1
        ).map(
            (role) =>
                ({
                    value: role,
                    label: t(`ManagementRoleDto.ManagementRoleDto_${role}`),
                    content: { node: t(`ManagementRoleDto.ManagementRoleDto_${role}_description`) },
                } as MultiSelectCustomOption)
        );

        useEffect(() => {
            //set the address in the field when selecting a new office
            form.setFieldsValue({
                address: displayAddress(officeSelectProps.selectedOption?.extraData),
            });
        }, [officeSelectProps.selectedOption]);

        useEffect(() => {
            //set form values from editObject
            if (editUserObject) {
                form.setFieldsValue({
                    firstName: editUserObject?.firstName,
                    lastName: editUserObject?.lastName,
                    jobTitle: editUserObject?.jobTitle,
                    department: editUserObject?.department ?? undefined,
                    contactInfoEmail: editUserObject?.contactInfo?.email,
                    timeZone: editUserObject?.timeZone,
                    teamId: { value: editUserObject?.team?.id },
                    preferredWorkType: editUserObject.preferredWorkType,
                    defaultFloor: editUserObject.defaultFloor,
                    defaultDesk: editUserObject.defaultDesk,
                    defaultOfficeId: editUserObject.defaultOffice
                        ? { value: editUserObject.defaultOffice.id }
                        : undefined,
                    defaultOfficeSpaceId: editUserObject.defaultOfficeSpace
                        ? { value: editUserObject.defaultOfficeSpace.id }
                        : undefined,
                    defaultOfficeEnabled:
                        editUserObject?.defaultOffice !== null &&
                        editUserObject?.defaultOffice !== undefined,
                });
                setShowDefaultOfficeForm(
                    editUserObject.defaultOffice !== null &&
                        editUserObject.defaultOffice !== undefined
                );
                setPreferredWorkplace(
                    editUserObject.preferredWorkType
                        ? editUserObject.preferredWorkType
                        : UserPreferredWorkTypeDto.Office
                );
                setSelectedLanguages(
                    editUserObject?.languageCodes
                        ?.filter((lang) => lang !== null)
                        .map((lang) => lang!) ?? []
                );
                setSelectedBadgeIds(editUserObject?.badges?.map((badge) => badge) ?? []);
                setSelectedTimeZone(editUserObject?.timeZone ?? undefined);
                setLocationGooglePlaceId(editUserObject?.location?.googlePlaceId);
                setSelectedRole(
                    editUserObject?.managementRoles && editUserObject.managementRoles.length > 0
                        ? editUserObject.managementRoles[0]?.name
                        : ManagementRoleDto.None
                );
                const eachContactPreferences = getEachContactPreferencesMethod(
                    editUserObject.contactPreferences?.methods
                );
                const slackId =
                    eachContactPreferences.slack.value ?? slackInfo?.slackProfileUrl ?? undefined;
                const contactPreferencesEmail =
                    eachContactPreferences.email.value ?? editUserObject.contactInfo?.email;
                setContactPreferences(
                    getContactPreferencesMethodArray({
                        ...eachContactPreferences,
                        slack: { ...eachContactPreferences.slack, value: slackId },
                        email: { ...eachContactPreferences.email, value: contactPreferencesEmail },
                    })
                );
            }
        }, [editUserObject]);

        useEffect(() => {
            const fetchSlackInfo = async (
                userId: string
            ): Promise<GetUserSlackInfoResponseDto | null> =>
                await userService.getUserSlackInfo(userId);
            if (editUserObject?.id) {
                const response = fetchSlackInfo(editUserObject.id);

                response.then((value) => {
                    setSlackInfo(value ?? undefined);
                });
            }
        }, [editUserObject]);

        const onRemoveDefaultOffice = () => {
            setShowDefaultOfficeForm(false);
            form.setFieldsValue({
                defaultOfficeEnabled: false,
                defaultFloor: undefined,
                defaultDesk: undefined,
                defaultOfficeId: undefined,
                defaultOfficeSpaceId: undefined,
            });
        };

        const dismiss = (success = false, id?: string): void => {
            form.resetFields();
            resetErrors();
            onComplete(success, id);
        };

        const onSubmit = async (): Promise<void> => {
            const formData = form.getFieldsValue();

            const commonRequest = {
                image: base64
                    ? ({
                          uploadBase64: base64,
                      } as UpdateFileRequestDto)
                    : imageDeleted
                    ? ({ delete: true } as UpdateFileRequestDto)
                    : null,
                firstName: formData.firstName,
                lastName: formData.lastName,
                contactInfo: { email: formData.contactInfoEmail },
                jobTitle: formData.jobTitle ? formData.jobTitle : undefined,
                department: formData.department,
                locationGooglePlaceId: locationGooglePlaceId,
                timeZone: selectedtimeZone,
                languageCodes: selectedLanguages,
                managementRoles:
                    selectedRole === ManagementRoleDto.None
                        ? []
                        : [{ name: selectedRole } as CreateUserRequestRoleDto],
                teamId: formData.teamId?.value,
                badges: selectedBadgeIds,
                defaultOfficeEnabled: formData.defaultOfficeEnabled,
                defaultFloor: formData.defaultFloor,
                defaultDesk: formData.defaultDesk,
                defaultOfficeId: formData.defaultOfficeId?.value,
                defaultOfficeSpaceId: formData.defaultOfficeSpaceId?.value,
                preferredWorkType: formData.preferredWorkType ?? WorkTypeDto.Office,
                skills: editUserObject?.skills,
                certifications: editUserObject?.certifications,
            };

            const createUserRequest: CreateUserRequestDto = {
                ...commonRequest,
                preferredLanguageCode: systemStore.defaultSystemLanguage,
            };

            const updateUserRequest: UpdateUserRequestDto = {
                ...commonRequest,
                preferredLanguageCode: editUserObject?.preferredLanguageCode,
                hasSeenOnboarding: editUserObject?.hasSeenOnboarding,
                contactPreferences: {
                    methods: contactPreferences,
                },
            };

            const isValidFormData = await validateForm(commonRequest);
            if (!isValidFormData) return;

            try {
                globalLoadingStore.addLoading();

                let createdUserId: string | undefined = undefined;

                if (editUserObject?.id) {
                    await userService.updateUser(editUserObject.id, updateUserRequest);
                } else {
                    const response = await userService.createUser(createUserRequest);
                    createdUserId = response?.id;
                }

                toastStore.toast({
                    type: 'success',
                    message: t('Toast.success_message', {
                        param1: t('user'),
                    }),
                });

                dismiss(true, createdUserId);
            } catch (e: any) {
                if (e.response?.data?.errors?.['contactInfo.Email']) {
                    toastStore.toast({
                        type: 'error',
                        message: t('email_already_in_use'),
                    });
                } else if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        return (
            <Modal
                className="CreateUserModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={
                    editUserObject
                        ? t('edit_entity', {
                              param1: t('user'),
                              param2: 'un',
                              param3: t('Entity.lowercase_user'),
                          })
                        : t('create_entity', {
                              param1: t('user'),
                              param2: 'un',
                              param3: t('Entity.lowercase_user'),
                          })
                }
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                        <SubmitButton text={t('save_changes')} type="primary" onClick={onSubmit} />
                    </>
                }
            >
                <Form
                    layout="vertical"
                    className="padding-scroll container-scroll-actions-fixed"
                    onFinish={onSubmit}
                    form={form}
                >
                    <div className="edit-image-container">
                        <div
                            className="img-container"
                            style={{
                                backgroundImage: `url(${imageUrl ?? NoProfilePicture})`,
                            }}
                            onClick={() => setChangePictureModalVisible(true)}
                        >
                            <div className="edit-img-btn">
                                <PencilIcon fill={theme.white} />
                            </div>
                        </div>
                    </div>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24} className="text-callout-bold mb-20">
                            {t('personal')}
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="firstName"
                                label={t('first_name')}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                required
                                errors={errors}
                                name="lastName"
                                label={t('last_name')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="contactInfoEmail"
                                label={t('email')}
                                required={editUserObject === undefined}
                            >
                                <Input
                                    disabled={editUserObject !== undefined}
                                    type={'email'}
                                    prefix={<EnvelopeIcon fill={theme['primary-mid-contrast']} />}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="jobTitle"
                                label={t('Profile.job_title')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem
                                errors={errors}
                                name="department"
                                label={t('department')}
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="googlePlaceId"
                                label={t('location')}
                            >
                                <GooglePlacesAutoComplete
                                    onChange={(value?: string) => setLocationGooglePlaceId(value)}
                                    defaultValue={
                                        editUserObject === undefined
                                            ? undefined
                                            : editUserObject?.location?.googlePlaceId &&
                                              editUserObject.location.description
                                            ? {
                                                  label: editUserObject.location.description,
                                                  value: editUserObject.location.googlePlaceId,
                                              }
                                            : undefined
                                    }
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="timeZone"
                                label={t('time_zone')}
                                required
                            >
                                <StaticSingleSelect
                                    options={timeZonesOptions(languageStore.currentLanguage)}
                                    onChange={(value?: SingleSelectCustomOption): void => {
                                        setSelectedTimeZone(value?.value);
                                    }}
                                    selected={selectedtimeZone}
                                    placeholder={t('SelectCustom.default_select_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="languageCodes"
                                label={t('languages')}
                            >
                                <StaticMultiSelect
                                    options={languageOptions(languageStore.currentLanguage)}
                                    onChange={(values: MultiSelectCustomOption[]): void => {
                                        setSelectedLanguages(values?.map((x: any) => x.value));
                                    }}
                                    selected={selectedLanguages}
                                    placeholder={t('SelectCustom.default_select_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={24} className="text-callout-bold mb-20">
                            {t('Profile.account_detail')}
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem errors={errors} label={t('role')}>
                                <StaticSingleSelect
                                    placeholder={t('SelectCustom.choose_a_entity', {
                                        param1: t('role'),
                                    })}
                                    options={roleOptions}
                                    onChange={(value?: SingleSelectCustomOption): void => {
                                        setSelectedRole(
                                            value?.value
                                                ? (value?.value as ManagementRoleDto)
                                                : undefined
                                        );
                                    }}
                                    selected={selectedRole}
                                />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem errors={errors} name="teamId" label={t('team')}>
                                <AsyncSingleSelect {...teamSelectProps} isClearable />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={12}>
                            <ValidatedFormItem name="badges" label={t('Coworker.badges')}>
                                <StaticMultiSelect
                                    options={badgesListOptions}
                                    onChange={(values: MultiSelectCustomOption[]): void => {
                                        setSelectedBadgeIds(
                                            values.map(
                                                (x: MultiSelectCustomOption) =>
                                                    x.value as UserBadgeDto
                                            )
                                        );
                                    }}
                                    selected={selectedBadgeIds}
                                    placeholder={t('SelectCustom.default_select_placeholder')}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <div className="text-callout-bold">{t('preferred_workplace')}</div>
                    <div className="mb-20">{t('Onboarding.preferred_workplace_explanation')}</div>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24} className="preferred-workplace">
                            <ValidatedFormItem
                                className="d-none"
                                label=""
                                name={'preferredWorkType'}
                            />

                            <PreferredWorkplaceToggle
                                type={UserPreferredWorkTypeDto.Office}
                                isSelected={preferredWorkplace === UserPreferredWorkTypeDto.Office}
                                onClick={(type) => {
                                    setPreferredWorkplace(type);
                                    form.setFieldsValue({
                                        preferredWorkType: WorkTypeDto.Office,
                                    });
                                }}
                            />
                            <PreferredWorkplaceToggle
                                type={UserPreferredWorkTypeDto.Remote}
                                isSelected={preferredWorkplace === UserPreferredWorkTypeDto.Remote}
                                onClick={(type) => {
                                    setPreferredWorkplace(type);
                                    form.setFieldsValue({
                                        preferredWorkType: WorkTypeDto.Remote,
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                    <div className="text-callout-bold">{t('default_office')}</div>
                    <div className="mb-20">{t('Onboarding.default_office_explanation')}</div>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <ValidatedFormItem
                                className="d-none"
                                label=""
                                name={'defaultOfficeEnabled'}
                            />
                            {showDefaultOfficeForm ? (
                                <div className="default-office-form">
                                    <Row gutter={FORM_GUTTER}>
                                        <Col span={12}>
                                            <ValidatedFormItem
                                                errors={errors}
                                                name="defaultOfficeId"
                                                label={t('name')}
                                                required
                                            >
                                                <AsyncSingleSelect
                                                    {...officeSelectProps}
                                                    onChange={(
                                                        option?: SingleSelectCustomOption
                                                    ) => {
                                                        officeSelectProps.onChange(option);
                                                        spaceSelectProps.resetSearchResults();
                                                        spaceSelectProps.onChange(undefined);
                                                    }}
                                                    placeholder={t('choose_an_office')}
                                                />
                                            </ValidatedFormItem>
                                        </Col>

                                        <Col span={12}>
                                            <ValidatedFormItem
                                                name="address"
                                                label={t('building_address')}
                                            >
                                                <Input disabled />
                                            </ValidatedFormItem>
                                        </Col>
                                    </Row>

                                    {officeSelectProps.selected && (
                                        <>
                                            <Row gutter={FORM_GUTTER}>
                                                <Col span={24}>
                                                    <ValidatedFormItem
                                                        errors={errors}
                                                        name="defaultOfficeSpaceId"
                                                        label={t('Office.space')}
                                                    >
                                                        <AsyncSingleSelect
                                                            {...spaceSelectProps}
                                                            isClearable
                                                        />
                                                    </ValidatedFormItem>
                                                </Col>
                                            </Row>

                                            <Row gutter={FORM_GUTTER}>
                                                <Col span={12}>
                                                    <ValidatedFormItem
                                                        errors={errors}
                                                        name="defaultFloor"
                                                        label={t('floor')}
                                                    >
                                                        <Input />
                                                    </ValidatedFormItem>
                                                </Col>
                                                <Col span={12}>
                                                    <ValidatedFormItem
                                                        errors={errors}
                                                        name="defaultDesk"
                                                        label={t('Office.desk_number')}
                                                    >
                                                        <Input />
                                                    </ValidatedFormItem>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                    <Divider />
                                    <div className="remove-default-container">
                                        <Button
                                            className="remove-default-btn"
                                            text={t('remove')}
                                            type="light"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onRemoveDefaultOffice();
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="no-default-office cursor-pointer"
                                    onClick={() => {
                                        form.setFieldsValue({ defaultOfficeEnabled: true });
                                        setShowDefaultOfficeForm(true);
                                    }}
                                >
                                    <Button
                                        text={t('set_default_office')}
                                        type="link"
                                        width="hugged"
                                        leftCircleIcon="AddIcon"
                                        onClick={() => {
                                            form.setFieldsValue({ defaultOfficeEnabled: true });
                                            setShowDefaultOfficeForm(true);
                                        }}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Form>

                {changePictureModalVisible && (
                    <Modal
                        className="EditImageModal"
                        visible={changePictureModalVisible}
                        onCancel={() => setChangePictureModalVisible(false)}
                        width={396}
                        headerColor={ModalHeaderColor.white}
                        maxHeight="418"
                    >
                        <div className="image-picker-container">
                            <ProfileImagePicker
                                onFileChange={(imageUrl, base64, deleted) => {
                                    setImageUrl(imageUrl);
                                    setBase64(base64);
                                    setImageDeleted(deleted ?? false);
                                }}
                                imageUrl={imageUrl}
                                closeModal={() => setChangePictureModalVisible(false)}
                            />
                        </div>
                    </Modal>
                )}
            </Modal>
        );
    }
);

export default React.memo(CreateUserModal);
