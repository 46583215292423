import React, { FunctionComponent, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import ConfirmationModal from 'Components/confirmation-modal/confirmation-modal';
import { WarningIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { useTranslation } from 'react-i18next';

const NavigationBlocker: FunctionComponent = observer(({ children }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { navigationStore } = useStores();
    const [modalVisible, setModalVisible] = useState(false);
    const [nextLocation, setNextLocation] = useState<any | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const handleBlockedNavigation = (nextLocation: any): boolean => {
        if (!confirmedNavigation) {
            setModalVisible(true);
            setNextLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
        navigationStore.setNavigationBlockState(false);
    };

    useEffect(() => {
        if (confirmedNavigation && nextLocation) {
            history.push(nextLocation.pathname);
        }
    }, [confirmedNavigation, nextLocation]);

    return (
        <div>
            <Prompt when={navigationStore.navigationIsBlocked} message={handleBlockedNavigation} />

            {modalVisible && (
                <ConfirmationModal
                    id="navigation-blocker-confirmation"
                    icon={<WarningIcon fill={theme['warning-mid-contrast']} />}
                    title={t('FormNavBlocker.form_nav_title')}
                    message={t('FormNavBlocker.form_nav_message')}
                    positive={{ text: t('continue'), action: handleConfirmNavigationClick }}
                    negative={{
                        text: t('cancel'),
                        action: () => {
                            setModalVisible(false);
                        },
                    }}
                />
            )}

            {children}
        </div>
    );
});

export default NavigationBlocker;
