import { default as React, FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { CloseIcon, ExclamationMarkCircleIcon } from 'Components/icons';
import './selected-teams-component.less';
import { theme } from 'Style/theme';
import { useTranslation } from 'react-i18next';
import { Empty } from 'Components/empty';
import { TeamDto } from 'Api/Features/Teams/Dtos/TeamDto';
import { Tooltip } from 'antd';

interface SelectedTeamsComponentProps {
    teams: TeamDto[];
    handleRemoveClick: (team: TeamDto) => void;
    height?: number;
}

const SelectedTeamsComponent: FunctionComponent<SelectedTeamsComponentProps> = observer(
    ({ teams, handleRemoveClick, height }) => {
        const { t } = useTranslation();
        return (
            <div className="SelectedTeamsComponent" style={{ height }}>
                <div className="header">
                    <div className="text-footnote-bold text-primary-high-contrast">{t('name')}</div>
                    <div className="text-footnote-bold text-primary-high-contrast">
                        {t('Policy.number_of_users')}
                    </div>
                    <div className="text-footnote-bold text-primary-high-contrast">
                        {t('managers')}
                    </div>
                </div>

                <div
                    className="selected-teams-container"
                    style={{
                        maxHeight: height ?? undefined,
                        overflowY: teams.length < 1 ? undefined : 'auto',
                    }}
                >
                    {teams.length < 1 ? (
                        <Empty />
                    ) : (
                        teams.map((team) => (
                            <div key={team.id} className="selected-team">
                                <div className="team-name text-body">
                                    {team.name}{' '}
                                    {team.policyBundle?.id && (
                                        <Tooltip
                                            overlay={t('Policy.team_already_has_policy')}
                                            overlayClassName="add-team-tooltip"
                                            placement='topLeft'
                                            arrowPointAtCenter={true}
                                        >
                                            <ExclamationMarkCircleIcon width={17} height={17} />
                                        </Tooltip>
                                    )}
                                </div>

                                <div className="text-body">{team.numberOfMembers}</div>

                                <div className="text-body managers">
                                    {team.managers
                                        ?.map((x) => `${x?.firstName} ${x?.lastName}`)
                                        .join(', ')}
                                </div>

                                <div className="remove" onClick={() => handleRemoveClick(team)}>
                                    <CloseIcon
                                        width={10}
                                        height={10}
                                        fill={theme['text-mid-contrast']}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        );
    }
);

export default SelectedTeamsComponent;
