import React, { ReactElement, ReactFragment } from 'react';
import { Modal, ModalProps } from 'antd';
import Icon from 'Components/icons/Icon';
import { theme } from 'Style/theme';

interface BaseModalMobileProps extends ModalProps {
    headerText?: string;
    oustideOverflow?: { element: ReactElement; height: number };
    footer?: ReactFragment;
    maxHeight?: string;
    noPadding?: boolean;
}

const BaseModalMobile: React.FunctionComponent<BaseModalMobileProps> = ({
    headerText,
    maskClosable = false,
    closeIcon = (
        <Icon iconName="CloseIcon" fill={headerText ? theme.white : theme['text-high-contrast']} />
    ),
    children,
    ...props
}) => {
    return (
        <Modal
            footer={null}
            centered
            visible
            maskClosable={maskClosable}
            closeIcon={closeIcon}
            {...props}
            width={'90%'}
        >
            {children}
        </Modal>
    );
};

export default BaseModalMobile;
