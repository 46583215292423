import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const BellIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M6.9248 19.5781H10.5459C10.6074 21.248 11.9785 22.7949 14 22.7949C16.0127 22.7949 17.3926 21.2656 17.4629 19.5781H21.0752C21.9893 19.5781 22.5518 19.0771 22.5518 18.3213C22.5518 17.3809 21.6992 16.5811 20.8994 15.8164C20.2754 15.21 20.126 13.9971 20.0117 12.7578C19.8887 9.44434 18.9131 7.22949 16.6279 6.40332C16.2852 5.23438 15.3096 4.34668 14 4.34668C12.6904 4.34668 11.7148 5.23438 11.3809 6.40332C9.08691 7.22949 8.12012 9.44434 7.98828 12.7578C7.87402 13.9971 7.72461 15.21 7.10059 15.8164C6.30078 16.5811 5.44824 17.3809 5.44824 18.3213C5.44824 19.0771 6.01074 19.5781 6.9248 19.5781ZM7.59277 17.9434V17.8379C7.82129 17.583 8.33105 17.1172 8.76172 16.625C9.36816 15.9307 9.64941 14.6475 9.7373 12.9775C9.84277 9.45312 10.9326 8.2666 12.3389 7.88867C12.5586 7.82715 12.6729 7.72168 12.6904 7.48438C12.7344 6.59668 13.2354 5.99902 14 5.99902C14.7734 5.99902 15.2656 6.59668 15.3096 7.48438C15.3271 7.72168 15.4502 7.82715 15.6611 7.88867C17.0762 8.2666 18.1572 9.45312 18.2627 12.9775C18.3594 14.6475 18.6406 15.9307 19.2383 16.625C19.6689 17.1172 20.1611 17.583 20.3896 17.8379V17.9434H7.59277ZM14 21.3799C13.0068 21.3799 12.2949 20.668 12.2334 19.5781H15.7754C15.7227 20.6592 15.002 21.3799 14 21.3799Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default BellIcon;
