import Icon from 'Components/icons/Icon';
import React, { memo } from 'react';
import './index.less';

export interface HappeningIndicatorProps {
    visible: boolean;
    onClick: () => void;
}

const HappeningIndicator: React.FunctionComponent<HappeningIndicatorProps> = ({
    visible,
    onClick,
}) => {
    return visible ? (
        <div className="HappeningIndicator">
            <Icon iconName="CalendarStarIcon" onClick={onClick} />
            <div className="happening-shapes" />
        </div>
    ) : null;
};

export default memo(HappeningIndicator);
