import React from 'react';
import './coworker-details.less';
import UserDetails, { UserDetailsContext } from 'Routes/settings/users/id';

const TeamManagementCoworkerDetail: React.FunctionComponent = () => {
    return (
        <div className="TeamManagementCoworkerDetail">
            <UserDetails context={UserDetailsContext.MyTeams} />
        </div>
    );
};

export default TeamManagementCoworkerDetail;
