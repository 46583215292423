import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';
import { OfficeProxy } from 'Api/Features/Offices/OfficeProxy';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { GetOfficesResponseDto } from 'Api/Features/Offices/Dtos/GetOfficesResponseDto';
import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { GetOfficeSpacesResponseDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesResponseDto';
import { CreateOfficeRequestDto } from 'Api/Features/Offices/Dtos/CreateOfficeRequestDto';
import { CreateOfficeResponseDto } from 'Api/Features/Offices/Dtos/CreateOfficeResponseDto';
import { UpdateOfficeRequestDto } from 'Api/Features/Offices/Dtos/UpdateOfficeRequestDto';
import { UpdateOfficeSpaceRequestDto } from 'Api/Features/Offices/Dtos/UpdateOfficeSpaceRequestDto';
import { CreateOfficeSpaceRequestDto } from 'Api/Features/Offices/Dtos/CreateOfficeSpaceRequestDto';
import { UserStore } from 'Stores';
import { GetOfficeSpaceOccupancyRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestDto';
import { GetOfficeSpaceOccupancyResponseDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponseDto';
import { GetOfficeSpaceOccupancyResponsePeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyResponsePeriodDto';
import { GetOfficeSpaceOccupantsRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupantsRequestDto';
import { GetOfficeSpaceOccupantsResponseDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupantsResponseDto';
import { OfficeSpaceOccupantDto } from 'Api/Features/Offices/Dtos/OfficeSpaceOccupantDto';
import { GetOfficeSpaceOccupancyRequestPeriodDto } from 'Api/Features/Offices/Dtos/GetOfficeSpaceOccupancyRequestPeriodDto';
import moment, { Moment } from 'moment';
import { createTimezonedMomentFromDateAndTime } from 'Utils/TimeUtils';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import { UpdateOfficeSpaceOrderingRequest } from 'Api/Features/Offices/Dtos/UpdateOfficeSpaceOrderingRequest';

@inject(OfficeProxy, UserStore)
export class OfficeService extends ApiService {
    constructor(
        private readonly officeProxy: OfficeProxy,
        private readonly userService: UserStore
    ) {
        super();
    }

    public async getOffice(officeId: string): Promise<OfficeDto | null> {
        const data: OfficeDto | null = await this.officeProxy.getOffice(officeId);
        return data;
    }

    public async getOffices(request: GetOfficesRequestDto | null): Promise<[OfficeDto[], number]> {
        const data: GetOfficesResponseDto | null = await this.officeProxy.getOffices(request);
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async createOffice(
        request: CreateOfficeRequestDto | null
    ): Promise<CreateOfficeResponseDto | null> {
        const data: CreateOfficeResponseDto | null = await this.officeProxy.createOffice(request);
        return data;
    }

    public async updateOffice(
        officeId: string,
        request: UpdateOfficeRequestDto | null
    ): Promise<void> {
        await this.officeProxy.updateOffice(officeId, request);
    }

    public async deleteOffice(officeId: string): Promise<void> {
        await this.officeProxy.deleteOffice(officeId);
    }

    public async getOfficeSpace(officeSpaceId: string): Promise<OfficeSpaceDto | null> {
        const data: OfficeSpaceDto | null = await this.officeProxy.getOfficeSpace(officeSpaceId);
        return data;
    }

    public async getOfficeSpaces(
        request: GetOfficeSpacesRequestDto | null
    ): Promise<[OfficeSpaceDto[], number]> {
        const data: GetOfficeSpacesResponseDto | null = await this.officeProxy.getOfficeSpaces(
            request
        );
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async createOfficeSpace(
        request: CreateOfficeSpaceRequestDto | null
    ): Promise<CreateOfficeResponseDto | null> {
        const data: CreateOfficeResponseDto | null = await this.officeProxy.createOfficeSpace(
            request
        );

        return data;
    }

    public async updateOfficeSpace(
        officeSpaceId: string,
        request: UpdateOfficeSpaceRequestDto | null
    ): Promise<void> {
        await this.officeProxy.updateOfficeSpace(officeSpaceId, request);
    }

    public async deleteOfficeSpace(officeSpaceId: string): Promise<void> {
        await this.officeProxy.deleteOfficeSpace(officeSpaceId);
    }

    public createGetOfficeSpaceOccupancyRequestPeriodDto(
        draftPeriods: {
            spaceId: string;
            startTime: string;
            endTime: string;
            momentSelectedStartDate: Moment;
            momentSelectedEndDate: Moment;
        }[],
        timeZone: string
    ): GetOfficeSpaceOccupancyRequestPeriodDto[] {
        const arr = draftPeriods.map((period) => {
            const { momentSelectedStartDate, momentSelectedEndDate, startTime, endTime, spaceId } =
                period;

            const start = createTimezonedMomentFromDateAndTime(
                moment(momentSelectedStartDate).format(FORMAT_YEAR_MONTH_DAY),
                startTime,
                timeZone
            );

            const end = createTimezonedMomentFromDateAndTime(
                moment(momentSelectedEndDate).format(FORMAT_YEAR_MONTH_DAY),
                endTime,
                timeZone
            );

            if (end.minute() == 59) end.add(1, 'minute');

            const duration = moment.duration(moment(start).diff(end));
            const hours = duration.asHours();
            const isBefore = end.isBefore(startTime);
            const isSame = start.isSame(endTime);

            if (hours > 24 || isBefore || isSame) {
                throw new Error('EndTime need to be after startTime and less than 24h');
            }

            return {
                officeSpaceId: spaceId,
                startTime: start.format(),
                endTime: end.format(),
            };
        });

        return arr;
    }

    public async getOfficeSpaceOccupancy(
        request: GetOfficeSpaceOccupancyRequestDto
    ): Promise<GetOfficeSpaceOccupancyResponsePeriodDto[]> {
        // Securité lié à l'appel API qui retourne une bad request si periods vide.
        if (!request.periods || request.periods.length === 0) return [];

        const data: GetOfficeSpaceOccupancyResponseDto | null =
            await this.officeProxy.getOfficeSpaceOccupancy(request);

        const periods = data?.periods
            ? data.periods.filter(
                  (period): period is GetOfficeSpaceOccupancyResponsePeriodDto => period !== null
              )
            : [];
        return periods;
    }

    public async getOfficeSpaceDefaultOccupancy(
        request: GetOfficeSpaceOccupancyRequestDto
    ): Promise<GetOfficeSpaceOccupancyResponsePeriodDto[]> {
        // Securité lié à l'appel API qui retourne une bad request si periods vide.
        if (!request.periods || request.periods.length === 0) return [];
        const data: GetOfficeSpaceOccupancyResponseDto | null =
            await this.officeProxy.getOfficeSpaceDefaultOccupancy(request);
        const periods = data?.periods
            ? data.periods.filter(
                  (period): period is GetOfficeSpaceOccupancyResponsePeriodDto => period !== null
              )
            : [];
        return periods;
    }

    public async getOfficeSpaceOccupants(
        request: GetOfficeSpaceOccupantsRequestDto | null
    ): Promise<[OfficeSpaceOccupantDto[], number]> {
        const data: GetOfficeSpaceOccupantsResponseDto | null =
            await this.officeProxy.getOfficeSpaceOccupants(request);

        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async getOfficeSpaceDefaultOccupants(
        request: GetOfficeSpaceOccupantsRequestDto | null
    ): Promise<[OfficeSpaceOccupantDto[], number]> {
        const data: GetOfficeSpaceOccupantsResponseDto | null =
            await this.officeProxy.getOfficeSpaceDefaultOccupants(request);
        return [
            data?.items?.filter((dto) => dto !== null).map((dto) => dto!) || [],
            data?.totalItemCount || 0,
        ];
    }

    public async updateOfficeSpaceOrdering(
        officeId: string,
        request: UpdateOfficeSpaceOrderingRequest | null
    ): Promise<void> {
        await this.officeProxy.updateOfficeSpaceOrdering(officeId, request);
    }
}
