import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const Phone: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 25 25" fill="none" {...props}>
                <path
                    d="M9.29073 15.6616C11.5131 17.8915 14.2176 19.6091 16.4249 19.6091C17.4118 19.6091 18.2782 19.2626 18.9713 18.4942C19.3781 18.0421 19.6267 17.5223 19.6267 17.0025C19.6267 16.6033 19.4685 16.2115 19.0843 15.9403L16.7715 14.298C16.4023 14.0419 16.101 13.9213 15.8298 13.9213C15.4757 13.9213 15.1593 14.1172 14.8128 14.4637L14.2704 15.0062C14.18 15.089 14.0745 15.1267 13.9766 15.1267C13.856 15.1267 13.743 15.0815 13.6677 15.0363C13.1931 14.7802 12.3795 14.0871 11.6261 13.3337C10.8728 12.5804 10.1722 11.7743 9.92354 11.2921C9.87834 11.2093 9.84068 11.1038 9.84068 10.9833C9.84068 10.8929 9.87081 10.7874 9.95368 10.697L10.4961 10.1471C10.8351 9.79299 11.0385 9.48411 11.0385 9.13004C11.0385 8.8513 10.918 8.54996 10.6618 8.18835L9.02706 5.89818C8.74832 5.50643 8.35658 5.3407 7.92717 5.3407C7.4149 5.3407 6.90262 5.5667 6.45814 5.99611C5.70479 6.70426 5.37332 7.58568 5.37332 8.5575C5.37332 10.7648 7.06836 13.4392 9.29073 15.6616Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default Phone;
