import WhiteCard from 'Components/white-card';
import React, { createContext, ReactElement, useCallback, useEffect, useState } from 'react';
import { theme } from 'Style/theme';

import { GetOfficesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficesRequestDto';
import { OfficeDto } from 'Api/Features/Offices/Dtos/OfficeDto';
import { TimeUnitTypeDto } from 'Api/Features/Stats/Dtos/TimeUnitTypeDto';
import { CustomDatePicker } from 'Components/date-picker';
import Divider from 'Components/divider';
import { GrowingChartIcon } from 'Components/icons';
import Skeleton from 'Components/skeleton';
import { useAsyncSingleSelectProps, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import moment from 'moment';
import { OfficeService } from 'Services/OfficeService';
import { TimeRangeFilterMap, TimeRangeKey } from 'Utils/ReportsUtils';
import { StatsRequestTypeEnum } from '..';
import {
    realEstateOfficeOccupationShape,
    realEstatePopularSpacesShape,
    realEstateTimeSpentBarChartShape,
    realEstateTimeSpentPieChartShape,
} from '../skeleton-shapes';
import './graph-card.less';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { useTranslation } from 'react-i18next';
import { QueuedFetch } from 'Hooks/use-fetch-queue';

interface GraphCardProps {
    title: string;
    subTitle: string;
    className?: string;
    hasOfficeFilter?: boolean;
    requestType: StatsRequestTypeEnum;
    fetchStats: (
        timeRangeFilter: TimeRangeFilter,
        requestType: StatsRequestTypeEnum,
        officeFilter: string
    ) => Promise<void>;
    selectedTeams: string;
    filterByDay?: boolean;
    addToFetchQueue: (fetchParams: QueuedFetch) => void;
}

export interface TimeRangeFilter {
    start: string;
    end: string;
    key: TimeRangeKey;
}

interface GraphContext {
    timeUnitType: TimeUnitTypeDto;
}

export const GraphContext = createContext<GraphContext | undefined>(undefined);

const GraphCard: React.FunctionComponent<GraphCardProps> = observer(
    ({
        title,
        subTitle,
        className,
        hasOfficeFilter = true,
        requestType,
        fetchStats,
        children,
        selectedTeams,
        filterByDay = false,
        addToFetchQueue,
    }) => {
        const { t } = useTranslation();
        const officeService = useService(OfficeService);
        const [graphContext, setGraphContext] = useState<GraphContext | undefined>(undefined);
        const { userStore } = useStores();
        const [loadingData, setLoadingData] = useState(true);
        const [selectedTimeRangeFilter, setSelectedTimeRangeFilter] = useState<
            TimeRangeKey | undefined
        >(TimeRangeKey.Last4Weeks);
        const [selectedOneDay, setSelectedOneDay] = useState<Date>(new Date());

        const { asyncSingleSelectProps: officeSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOfficesRequestDto) =>
                    await officeService.getOffices(request),
            },
            entityToSingleSelectCustomOption: (office: OfficeDto) =>
                ({ label: office.name, value: office.id } as SingleSelectCustomOption),
            extraMandatoryOptions: {
                unshift: [
                    {
                        label: t('unspecified_office'),
                        value: 'not defined',
                    },
                    {
                        label: t('all_offices'),
                        value: 'all',
                    },
                ],
            },
            defaultSelectedOption: {
                label: t('all_offices'),
                value: 'all',
            },
        });

        const [placeholder] = useState<ReactElement>(() => {
            switch (requestType) {
                case StatsRequestTypeEnum.TimeSpentPieChart:
                    return realEstateTimeSpentPieChartShape;
                case StatsRequestTypeEnum.TimeSpentBarChart:
                    return realEstateTimeSpentBarChartShape;
                case StatsRequestTypeEnum.OfficeOccupation:
                    return realEstateOfficeOccupationShape;
                case StatsRequestTypeEnum.PopularSpaces:
                    return realEstatePopularSpacesShape;
                default:
                    return realEstatePopularSpacesShape;
            }
        });

        const handleFetchStats = async (timeRangeKey: TimeRangeKey, officeFilter: string) => {
            setGraphContext({
                timeUnitType:
                    timeRangeKey === TimeRangeKey.Last4Weeks ||
                    timeRangeKey === TimeRangeKey.Last3Months
                        ? TimeUnitTypeDto.Week
                        : TimeUnitTypeDto.Month,
            });

            setLoadingData(true);

            const timeRangeFilter = {
                start:
                    filterByDay && selectedOneDay
                        ? moment(selectedOneDay).format(FORMAT_YEAR_MONTH_DAY)
                        : TimeRangeFilterMap.get(timeRangeKey)?.start ?? '',
                end:
                    filterByDay && selectedOneDay
                        ? moment(selectedOneDay).format(FORMAT_YEAR_MONTH_DAY)
                        : TimeRangeFilterMap.get(timeRangeKey)?.end ?? '',
                key: timeRangeKey,
            };

            addToFetchQueue({
                fetchFunction: async () =>
                    await fetchStats(timeRangeFilter, requestType, officeFilter),
                fetchId: requestType,
                callback: () => setLoadingData(false),
            });
        };

        useEffect(() => {
            if (
                userStore.userInfo?.timeZone &&
                selectedTimeRangeFilter &&
                officeSelectProps.selected
            )
                handleFetchStats(selectedTimeRangeFilter, officeSelectProps.selected);
        }, [
            userStore.userInfo?.timeZone,
            selectedTimeRangeFilter,
            officeSelectProps.selected,
            selectedTeams,
            selectedOneDay,
        ]);

        const getTimeFilterOptions = useCallback((): SingleSelectCustomOption[] => {
            const options: SingleSelectCustomOption[] = [];
            for (const key of TimeRangeFilterMap.keys()) {
                options.push({
                    label: t(`TimeRangeKey.TimeRangeKey_${key}`),
                    value: key,
                });
            }
            return options;
        }, [TimeRangeFilterMap]);

        const onChangeDatePicker = (date: Date) => {
            setSelectedOneDay(date);
        };

        return (
            <div className={`GraphCard ${className}`}>
                <WhiteCard>
                    <div className="top-container">
                        <GrowingChartIcon fill={theme['layout-mid-accent']} />

                        <div className="title-container">
                            <div className="text-headline-bold title">{title}</div>
                            <div className="text-body-bold text-mid-contrast">{subTitle}</div>
                        </div>

                        <div className="filters-container">
                            {hasOfficeFilter && (
                                <AsyncSingleSelect
                                    {...officeSelectProps}
                                    placeholder={t('SelectCustom.default_select_placeholder')}
                                />
                            )}
                            {!filterByDay ? (
                                <StaticSingleSelect
                                    options={getTimeFilterOptions()}
                                    selected={selectedTimeRangeFilter}
                                    onChange={(option?: SingleSelectCustomOption) => {
                                        setSelectedTimeRangeFilter(
                                            option?.value
                                                ? (option?.value as TimeRangeKey)
                                                : undefined
                                        );
                                    }}
                                    placeholder={t('SelectCustom.default_select_placeholder')}
                                />
                            ) : (
                                <CustomDatePicker
                                    onChange={onChangeDatePicker}
                                    value={selectedOneDay}
                                    maxDate={new Date()}
                                />
                            )}
                        </div>
                    </div>

                    <Divider className="graph-card-divider" />

                    <Skeleton isLoaded={!loadingData} placeholder={placeholder}>
                        <GraphContext.Provider value={graphContext}>
                            {children}
                        </GraphContext.Provider>
                    </Skeleton>
                </WhiteCard>
            </div>
        );
    }
);

export default GraphCard;
