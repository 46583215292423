import { ClubDetailsDto } from 'Api/Features/Clubs/Dtos/ClubDetailsDto';
import Icon from 'Components/icons/Icon';
import TooltipWrapper from 'Components/tooltip-wrapper';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { theme } from 'Style/theme';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import './club-square.less';

interface ClubSqareProps {
    club: ClubDetailsDto;
}
const ClubSquare: FunctionComponent<ClubSqareProps> = ({ club }) => {
    const [clubCategoryIconInfo, SetClubCategoryIconMap] = useState<
        { iconName: string; color: string } | undefined
    >();

    useEffect(() => {
        if (club.category && ClubCategoryIconMap.has(club.category))
            SetClubCategoryIconMap(ClubCategoryIconMap.get(club.category));
    }, [club]);
    return (
        <div className="ClubSquare">
            <TooltipWrapper
                placement="top"
                title={club.name}
                backgroundColor={theme['layout-high-contrast']}
                textColor={theme['text-low-contrast']}
            >
                {clubCategoryIconInfo && (
                    <div
                        className="square Shadow-100"
                        style={{ backgroundColor: clubCategoryIconInfo.color }}
                    >
                        <Icon
                            iconName={clubCategoryIconInfo?.iconName}
                            fill={theme.white}
                            width={20}
                            height={20}
                        />
                    </div>
                )}
            </TooltipWrapper>
        </div>
    );
};

export default ClubSquare;
