import { Divider } from 'antd';
import { GetUserObjectivesStatusWeekValuesDto } from 'Api/Features/Users/Dtos/GetUserObjectivesStatusWeekValuesDto';
import { wavePatternSvg } from 'Assets/StaticSvgs/wave-pattern';
import { PencilIcon, TargetArrowIcon } from 'Components/icons';
import Modal from 'Components/modal';
import PolicyObjectiveCircleIcon from 'Components/policyObjectiveCircleIcon';
import { useStores } from 'Hooks';
import { PLAN_SCHEDULE_URL } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PolicyInfoStoreObjectiveStats } from 'Stores/PolicyStore';
import { theme } from 'Style/theme';
import {
    objectiveLabelToUnit,
    objectiveLabeltoTitle,
    objectiveLabelToDescription,
} from 'Utils/PolicyObjectivesUtils';
import './index.less';

interface ModalWeeklyObjectivesProps {
    isVisible: boolean;
    onCancel: () => void;
    objectiveStats?: Partial<PolicyInfoStoreObjectiveStats>;
    displayEditButton?: boolean;
    isConstantWeek?: boolean;
    mobilefullScreen?: boolean;
}

const ModalWeeklyObjectives: React.FunctionComponent<ModalWeeklyObjectivesProps> = ({
    isVisible,
    onCancel,
    objectiveStats,
    isConstantWeek = false,
    displayEditButton = true,
    mobilefullScreen = false,
}) => {
    const { policyStore } = useStores();
    const [persistedSchedule, setPersistedSchedule] = useState<
        Partial<PolicyInfoStoreObjectiveStats>
    >({});
    const { t } = useTranslation();

    const modalTitle = t('PlanSchedule.weekly_objectives');

    useEffect(() => {
        if (isConstantWeek) {
            setPersistedSchedule({
                ...policyStore.policyInfo?.policyObjectiveStats,
            });
        }

        if (objectiveStats) {
            setPersistedSchedule({
                ...objectiveStats,
            });
        } else if (policyStore.policyInfoForSpecificWeek?.policyObjectiveStats) {
            setPersistedSchedule({
                ...policyStore.policyInfoForSpecificWeek?.policyObjectiveStats,
            });
        }
    }, [
        objectiveStats,
        policyStore.policyInfoForSpecificWeek?.policyObjectiveStats,
        policyStore.policyInfo?.policyObjectiveStats,
    ]);

    const getAdvice = (
        label: keyof GetUserObjectivesStatusWeekValuesDto,
        value: string,
        progression: string | number
    ) => {
        const valueToShow = parseFloat(value) - parseFloat(progression as string);

        if (progression >= value) {
            return t('Policy.completed_this_week');
        }

        if (label == 'availabilityHours' || label == 'workHours' || label === 'coreHours') {
            return t('ModalWeeklyObjectives.this_week_almost_there', {
                // @ts-ignore
                param1: valueToShow.toFixedIfNecessary(2),
            });
        }

        if (label == 'officeDays') {
            return t('ModalWeeklyObjectives.try_working_day_at_office', {
                param1: valueToShow,
                param2: valueToShow > 1 ? t('lowercase_days') : t('lowercase_day'),
            });
        }

        if (label == 'days') {
            return t('ModalWeeklyObjectives.try_working_day', {
                param1: valueToShow,
                param2: valueToShow > 1 ? t('lowercase_days') : t('lowercase_day'),
            });
        }

        return '';
    };

    return (
        <Modal
            className="ModalWeeklyObjectives"
            visible={isVisible}
            onCancel={onCancel}
            headerText={modalTitle}
            fullScreenHeaderText={modalTitle}
            mobileFullScreen={mobilefullScreen}
        >
            <div className="sub-header">
                <div className="left">
                    <TargetArrowIcon fill={theme['primary-high-contrast']} />
                    <div className="text-caption-2-bold weeklyObjective">
                        {mobilefullScreen ? t('this_week') : t('weekly_objective')}
                    </div>
                    <Divider type="vertical" />
                    <div className="text-caption-2-bold">
                        {persistedSchedule?.totalScore?.completed} /{' '}
                        {persistedSchedule?.totalScore?.total}
                    </div>
                    <div className="text-caption-2">{t('PlanSchedule.completed_objectives')}</div>
                </div>

                {displayEditButton && (
                    <Link className="right" to={PLAN_SCHEDULE_URL}>
                        <PencilIcon fill={theme['layout-mid-main']} />
                        <div className="text-footnote-bold">{t('Policy.edit_schedule')}</div>
                    </Link>
                )}

                <div className="wavePatternSvg">{wavePatternSvg}</div>
            </div>

            {persistedSchedule?.policyBundleObjectivesDetails?.map((policy, key) => {
                const progression = policy.completed;
                const displayProgression = progression >= policy.total ? policy.total : progression;
                const backgroundColor =
                    policy.completed >= policy.total
                        ? theme['success-low-contrast']
                        : progression == 0
                        ? theme['text-low-contrast']
                        : theme['primary-low-contrast'];

                return (
                    <div className="template-objective" key={key}>
                        <div className="left">
                            <div className="text-body-bold">
                                {objectiveLabeltoTitle(policy.label, t)}
                            </div>
                            <div className="text-recommended">
                                <TargetArrowIcon fill={theme['layout-mid-main']} />
                                <div className="text-caption-2-bold">
                                    {t('Policy.recommended')}: {policy.total}{' '}
                                    {policy.total <= 1
                                        ? objectiveLabelToUnit(policy.label, t).slice(0, -1)
                                        : objectiveLabelToUnit(policy.label, t)}
                                </div>
                            </div>
                            <div className="text-bold">
                                {objectiveLabelToDescription(policy.label, t)}
                            </div>
                        </div>

                        <div className="right">
                            <PolicyObjectiveCircleIcon
                                width={60}
                                iconSize={36}
                                policyCurrentValue={progression}
                                policyGoal={policy.total}
                                policyKey={policy.label}
                            />
                            <div
                                className="text-caption-1"
                                style={{ backgroundColor, borderRadius: 10 }}
                            >
                                <span className="text-caption-1-bold">
                                    {displayProgression} / {policy.total}
                                </span>{' '}
                                {t(
                                    isConstantWeek
                                        ? `Policy.${policy.label}`
                                        : `Policy.${policy.label}_per_week`
                                )}
                            </div>
                            <div className="text-footnote">
                                {getAdvice(policy.label, policy.total.toString(), progression)}
                            </div>
                        </div>
                    </div>
                );
            })}
        </Modal>
    );
};

export default ModalWeeklyObjectives;
