import { Upload } from 'antd';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import NoProfilePicture from 'Assets/Images/no-profile.svg';
import Button from 'Components/button';
import ImageCropModal from 'Components/generic-image-picker/image-crop-modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './profile-image-picker.less';

interface ImagePickerProps {
    imageUrl?: string;
    onFileChange: (imageUrl?: string, base64?: string, deleted?: boolean) => void;
    closeModal: () => void;
}

const ProfileImagePicker: React.FunctionComponent<ImagePickerProps> = ({
    imageUrl,
    onFileChange,
    closeModal,
}) => {
    const { t } = useTranslation();
    const fileInputRef = React.createRef<HTMLInputElement>();

    const [croppingImageSrc, setCroppingImageSrc] = useState<string>('');
    const [croppingModalOpen, setCroppingModalOpen] = useState<boolean>(false);

    const handleEditClick = () => {
        fileInputRef.current?.click();
    };

    const handleDeleteClick = () => {
        onFileChange(undefined, undefined, true);
        if (fileInputRef.current?.value) {
            fileInputRef.current.value = '';
        }
    };

    const getFileSrc = async (file: any): Promise<string> => {
        const src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
        return src as string;
    };

    const onChangeWithCropping = async (event): Promise<void> => {
        if (event.file.status === ('uploading' as UploadFileStatus)) {
            const src = await getFileSrc(event.file);
            setCroppingImageSrc(src);
            setCroppingModalOpen(true);
        }
    };

    //The antd control requires to post on file select. We do not want this behavior so we must make a dummy request
    const dummyRequest = (request: any) => {
        setTimeout(() => {
            request.onSuccess('ok');
        }, 0);
    };

    const onImageCroppingComplete = async (
        success: boolean,
        url: string | null,
        base64: string | null
    ) => {
        if (success && url && base64) {
            onFileChange(url, base64, false);
        }
        setCroppingModalOpen(false);
        if (closeModal) closeModal();
    };

    return (
        <div className="ProfileImagePicker">
            <div
                className="image-container"
                style={{ backgroundImage: `url(${imageUrl ?? NoProfilePicture})` }}
            />
            <div className="text-container">
                <div className="text-callout-bold">{t('ImagePicker.profile_picture')}</div>
                <div className="text-body">{t('ImagePicker.profile_picture_exp')}</div>
            </div>

            <div className="edit-button-container">
                <Button
                    className="delete-btn"
                    text={t('ImagePicker.delete_picture')}
                    type="link"
                    width="hugged"
                    onClick={() => handleDeleteClick()}
                    disabled={!imageUrl}
                />

                <Upload
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={onChangeWithCropping}
                    customRequest={dummyRequest}
                >
                    <Button
                        text={
                            imageUrl
                                ? t('ImagePicker.change_picture')
                                : t('ImagePicker.add_picture')
                        }
                        type="tertiary"
                        width="hugged"
                        leftIcon="PencilIcon"
                        onClick={() => handleEditClick()}
                    />
                </Upload>
            </div>

            {croppingImageSrc && croppingModalOpen && (
                <ImageCropModal
                    visible={croppingModalOpen}
                    src={croppingImageSrc}
                    onComplete={onImageCroppingComplete}
                    aspect={4 / 3}
                    circularCrop
                />
            )}
        </div>
    );
};

export default React.memo(ProfileImagePicker);
