import React from 'react';
import * as yup from 'yup';
import { scrollToErrors } from 'Utils/ScrollToErrors';
import { FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';

export const useFormValidation = (
    schema: yup.ObjectSchema,
    form: FormInstance
): [
    Map<string, string[]>,
    (values: object) => Promise<boolean>,
    () => void,
    (errors: Map<string, string[]>) => void
] => {
    const [errors, setErrors] = React.useState(new Map<string, string[]>());
    const { t } = useTranslation();

    const resetErrors = (): void => {
        setErrors(new Map<string, string[]>());
    };

    const validateForm = async (values: object): Promise<boolean> => {
        try {
            await schema.validate(values, { abortEarly: false });
            resetErrors();
            return true;
        } catch (validationErrors: any) {
            const errors = new Map<string, string[]>();
            if (validationErrors.inner) {
                for (const error of validationErrors.inner) {
                    //validating an array "yup.array().of(yup.string().max(40, 'Errors.error_max_40'))" gives out a path like field[1].
                    //since our field names are just "field" to be able to see red input remove array. This might need to be changed in future if we have different use case
                    if (error.path.includes('[')) {
                        error.path = error.path.split('[')[0];
                    }

                    errors.set(
                        error.path,
                        (errors.get(error.path) ?? []).concat(t(`${error.errors}`) + '. ')
                    );
                }
            }

            setErrors(errors);
            scrollToErrors(errors, form);
            return false;
        }
    };

    return [errors, validateForm, resetErrors, setErrors];
};
