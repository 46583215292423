import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const BriefcaseIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M6.86328 22.2236H21.1367C23.0264 22.2236 23.9932 21.2744 23.9932 19.4023V10.7891C23.9932 8.92578 23.0264 7.96777 21.1367 7.96777H19.2646V7.03613C19.2646 5.69141 18.4033 4.8916 16.9355 4.8916H11.0645C9.59668 4.8916 8.73535 5.69141 8.73535 7.03613V7.96777H6.86328C4.98242 7.96777 4.00684 8.92578 4.00684 10.7891V19.4023C4.00684 21.2744 4.98242 22.2236 6.86328 22.2236ZM10.3789 7.12402C10.3789 6.66699 10.6953 6.37695 11.1787 6.37695H16.8213C17.3135 6.37695 17.6211 6.66699 17.6211 7.12402V7.96777H10.3789V7.12402ZM5.75586 10.8418C5.75586 10.0508 6.16895 9.65527 6.93359 9.65527H21.0664C21.8311 9.65527 22.2354 10.0508 22.2354 10.8418V11.9932C19.8096 12.8281 17.0234 13.2412 14 13.2412C10.9766 13.2412 8.19043 12.8281 5.75586 11.9932V10.8418ZM6.93359 20.5361C6.16895 20.5361 5.75586 20.1494 5.75586 19.3496V13.6895C7.55762 14.2344 9.51758 14.5859 11.5566 14.7354V15.4297C11.5566 16.0977 11.9434 16.4668 12.6289 16.4668H15.3711C16.0566 16.4668 16.4346 16.0977 16.4346 15.4297V14.7354C18.4824 14.5859 20.4336 14.2344 22.2354 13.6895V19.3496C22.2354 20.1494 21.8311 20.5361 21.0664 20.5361H6.93359Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default BriefcaseIcon;
