import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';
import Button from 'Components/button';
import Divider from 'Components/divider';
import Icon from 'Components/icons/Icon';
import { RichTextDisplay } from 'Components/rich-text-display/rich-text-display';
import RSVPInfo from 'Components/rsvp-info';
import Skeleton from 'Components/skeleton';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { EVENTS_URL, FORMAT_SHORT_MONTH_DATE_TIME } from 'Models/Constants';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { eventSkeletonShape } from 'Routes/events/skeleton-shapes';
import { ClubCategoryIconMap } from 'Utils/ClubsUtils';
import { getHappeningAttendanceTypeIcon, getHappeningDefaultImg } from 'Utils/HappeningUtils';
import { img, ImgSize } from 'Utils/ImgUtils';
import './index.less';
import { theme } from 'Style/theme';

interface EventLineProps {
    happening?: HappeningDto;
    isSkeleton?: boolean;
    isPast?: boolean;
}

const EventLine: React.FunctionComponent<EventLineProps> = observer(
    ({ isPast = false, happening, isSkeleton }) => {
        const { t } = useTranslation();
        const history = useHistory();
        const { languageStore } = useStores();

        const OnPressViewDetail = () => {
            if (!happening) return;
            const { id } = happening;

            history.push(EVENTS_URL + '/' + id);
        };

        const truncateRichText = (text: string, charNb: number) => {
            if (text.length > charNb) {
                const endChar = '…';
                const tagRegex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
                const unRichedText = text.replace(tagRegex, '');
                const truncatedUnRichedText = unRichedText.slice(0, charNb);
                const finalText = truncatedUnRichedText.slice(
                    0,
                    Math.min(truncatedUnRichedText.length, truncatedUnRichedText.lastIndexOf(' '))
                );
                return finalText + endChar;
            } else {
                return text;
            }
        };

        return (
            <div className="EventLine">
                <Skeleton
                    placeholder={eventSkeletonShape}
                    isLoaded={!isSkeleton}
                    defaultPadding={false}
                >
                    <div className={`left ${isPast ? 'is-past' : ''}`}>
                        <img
                            src={
                                happening?.imageUrl
                                    ? img(happening.imageUrl, ImgSize.xl, false)
                                    : getHappeningDefaultImg(happening?.attendanceType)
                            }
                        />
                    </div>
                    <div className="right">
                        <div className="text-title-3-bold">{happening?.name}</div>

                        <div className="text-caption-1">
                            <div className="d-flex-align attendence-type">
                                {getHappeningAttendanceTypeIcon(happening?.attendanceType)}

                                <span className="ml-5">{`${moment(happening?.startTime).format(
                                    FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage]
                                )} - ${moment(happening?.endTime).format(
                                    FORMAT_SHORT_MONTH_DATE_TIME[languageStore.currentLanguage]
                                )}`}</span>
                            </div>

                            <div className="container-pictogramme">
                                <div className="container-one-section-pictogramme">
                                    <div>
                                        {happening?.club?.category &&
                                            ClubCategoryIconMap.has(happening.club.category) && (
                                                <div className="d-flex-align club">
                                                    Club:
                                                    <Icon
                                                        iconName={
                                                            ClubCategoryIconMap.get(
                                                                happening.club.category
                                                            )!.iconName
                                                        }
                                                    />
                                                    {happening.club.name}
                                                </div>
                                            )}
                                    </div>

                                    <Icon iconName="MapPinWithCircleIcon" />
                                    <div className="text-caption-1">{happening?.office?.name}</div>
                                </div>
                            </div>
                        </div>

                        <Divider marginBottom={20} marginTop={10} />

                        <RichTextDisplay
                            className="text-footnote text-description"
                            html={truncateRichText(happening?.description ?? '', 175)}
                        />

                        <div className="wrapper-bottom">
                            {happening && (
                                <RSVPInfo
                                    happening={happening}
                                    isPast={isPast}
                                    addToCalendarButtonProps={{
                                        color: theme['accent-mid-contrast'],
                                        width: 24,
                                    }}
                                />
                            )}

                            <Button
                                text={t('view')}
                                type="secondary"
                                onClick={OnPressViewDetail}
                                width="hugged"
                                className="button-view"
                            />
                        </div>
                    </div>
                </Skeleton>
            </div>
        );
    }
);

export default EventLine;
