import Button from 'Components/button';
import { OfficeOccupancyCardWorkType } from 'Components/office-occupancy-card';
import CtaRow from 'Components/office-occupancy-card/components/cta-row';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from 'Utils/ArrayUtils';
import './index.less';

interface WorktypeDropdownModalOptions {
    worktype: OfficeOccupancyCardWorkType;
    iconName: string;
}

export interface WorktypeDropdownModalContentProps {
    options: WorktypeDropdownModalOptions[];
    buttonLink?: string;
    onClick: (worktype: OfficeOccupancyCardWorkType) => void;
}

const WorktypeDropdownModalContent: React.FunctionComponent<WorktypeDropdownModalContentProps> = ({
    options,
    buttonLink,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <div className="WorktypeDropdownModalContent">
            <div
                className="title"
                dangerouslySetInnerHTML={{
                    __html: t('WorktypeDropdownModal.change_all_day_workplace'),
                }}
            />

            {options.map((option, i) => (
                <CtaRow
                    key={generateKey(`CtaRow-${i}`)}
                    workType={{
                        iconName: option.iconName,
                        label: t('WorkTypeDto.WorkTypeDto_' + option.worktype),
                        onClick: () => onClick(option.worktype),
                    }}
                />
            ))}

            {buttonLink && (
                <>
                    <div className="separator" />
                    <Button
                        text={t('Dashboard.go_to_schedule')}
                        type="secondary"
                        width="full"
                        height="small"
                        link={buttonLink}
                    />
                </>
            )}
        </div>
    );
};

export default WorktypeDropdownModalContent;
