import { Form } from 'antd';
import Button from 'Components/button';
import Modal from 'Components/modal';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfficeService } from 'Services/OfficeService';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { GetOfficeSpacesRequestDto } from 'Api/Features/Offices/Dtos/GetOfficeSpacesRequestDto';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import { theme } from 'Style/theme';
import DragDropContainer from 'Components/drag-drop-container';
import { MenuIcon } from 'Components/icons';
import './index.less';
import Skeleton from 'Components/skeleton';
import SubmitButton from 'Components/submit-button/submit-button';
import { UpdateOfficeSpaceOrderingRequest } from 'Api/Features/Offices/Dtos/UpdateOfficeSpaceOrderingRequest';
import { OrderingMethodDto } from 'Api/Features/General/Dtos/OrderingMethodDto';
import { useFetch } from 'Hooks/use-fetch';

interface ReorderSpaceModalProps {
    officeId: string;
    officeSpaceOrderingMethod: OrderingMethodDto;
    visible: boolean;
    onComplete: (success: boolean, selectedMethod?: OrderingMethodDto) => void;
}

const ReorderSpaceModal: React.FunctionComponent<ReorderSpaceModalProps> = observer(
    ({ officeId, officeSpaceOrderingMethod, visible, onComplete }) => {
        const { t } = useTranslation();
        const officeService = useService(OfficeService);
        const { toastStore } = useStores();
        const [form] = Form.useForm();

        const [spaces, setSpaces] = useState<OfficeSpaceDto[]>([]);
        const [selectedOrderType, setSelectedOrderType] = useState<
            SingleSelectCustomOption | undefined
        >();

        const { apiRequest, loadingStateKeys } = useFetch();

        useEffect(() => {
            setSelectedOrderType({
                label:
                    officeSpaceOrderingMethod == OrderingMethodDto.Manual ? t('custom') : 'A - Z',
                value: officeSpaceOrderingMethod,
            } as SingleSelectCustomOption);
        }, [officeSpaceOrderingMethod]);

        useEffect(() => {
            fetchOfficeSpaces();
        }, []);

        const fetchOfficeSpaces = async () => {
            const [response] = await apiRequest({
                requestFunction: (request) => officeService.getOfficeSpaces(request),
                requestParameters: {
                    officeIds: [officeId],
                    page: 0,
                    pageSize: 1000,
                } as GetOfficeSpacesRequestDto,
                loadingStateKey: 'spacesLoading',
            });

            if (response) {
                setSpaces(response);
            }
        };

        const dismiss = (success = false, method?: OrderingMethodDto): void => {
            form.resetFields();
            onComplete(success, method);
        };

        const submit = async (): Promise<void> => {
            await apiRequest({
                requestFunction: (request) =>
                    officeService.updateOfficeSpaceOrdering(officeId, request),
                requestParameters: {
                    method: selectedOrderType?.value,
                    officeSpaceIds: spaces.map((x) => x.id ?? ''),
                } as UpdateOfficeSpaceOrderingRequest,
                useGlobalLoading: true,
            });

            toastStore.toast({
                type: 'success',
                message: t('SpaceOrdering.spaces_ordered_success'),
            });
            dismiss(true, selectedOrderType?.value as OrderingMethodDto);
        };

        const orderAlphabetically = () => {
            setSpaces((prev) =>
                prev.sort((a, b) => {
                    const nameA = a.name?.toUpperCase() ?? 0;
                    const nameB = b.name?.toUpperCase() ?? 0;
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })
            );
        };

        return (
            <Modal
                className="ReorderSpaceModal"
                visible={visible}
                headerText={t('Office.space_edit_display_order')}
                onCancel={() => dismiss(false)}
                fixedHeight
                modalBackgroundColor={theme['layout-low-contrast']}
                footer={
                    <>
                        <Button text={t('cancel')} type="tertiary" onClick={() => dismiss(false)} />
                        <SubmitButton text={t('save')} type="primary" onClick={submit} />
                    </>
                }
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <div className="top-section text-mid-contrast">
                        <span className="text-caption-1">
                            {t('Office.space_order_explanation')}
                        </span>
                        <span className="order text-caption-1 text-mid-contrast">
                            {t('order')}:
                        </span>
                        <StaticSingleSelect
                            selected={selectedOrderType?.value}
                            onChange={(option?: SingleSelectCustomOption) => {
                                setSelectedOrderType(option);
                                if (option?.value === OrderingMethodDto.Alphabetical) {
                                    orderAlphabetically();
                                }
                            }}
                            options={[
                                { label: t('custom'), value: OrderingMethodDto.Manual },
                                { label: 'A - Z', value: OrderingMethodDto.Alphabetical },
                            ]}
                        />
                    </div>
                    <div className="table">
                        <div className="table-header">
                            <div className="name text-footnote-bold text-primary-high-contrast">
                                {t('Office.space_name')}
                            </div>
                            <div className="capacity text-footnote-bold text-primary-high-contrast">
                                {t('capacity')}
                            </div>
                            <div className="reorder text-footnote-bold text-primary-high-contrast">
                                {t('reorder')}
                            </div>
                        </div>
                        <div className="table-rows">
                            <Skeleton
                                isLoaded={!loadingStateKeys.has('spacesLoading')}
                                defaultPadding={false}
                                placeholder={
                                    <div>
                                        <div className="rect" style={{ height: 500 }}></div>
                                    </div>
                                }
                            >
                                <DragDropContainer
                                    sourceArray={spaces}
                                    onDragEnd={(mutatedList) => {
                                        setSpaces([...mutatedList]);
                                        setSelectedOrderType({
                                            label: t('custom'),
                                            value: OrderingMethodDto.Manual,
                                        });
                                    }}
                                    draggableItems={spaces.map((space, i) => ({
                                        id: space.id!,
                                        element: (
                                            <div className={`row ${i % 2 === 0 ? 'even' : 'odd'}`}>
                                                <div className="name">{space.name}</div>
                                                <div className="capacity">{space.capacity}</div>
                                                <div className="reorder">
                                                    <MenuIcon
                                                        width={24}
                                                        height={24}
                                                        fill={theme['text-mid-contrast']}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    }))}
                                />
                            </Skeleton>
                        </div>
                    </div>
                </Form>
            </Modal>
        );
    }
);

export default ReorderSpaceModal;
