import React from 'react';
import './index.less';

export const clubCardSkeletonShape = (
    <div className="clubCardSkeletonShape">
        <div className="circle" />
        <div className="rect" />
        <div className="rect" />
        <div className="rect" />
        <div className="rect-separator" />
        <div className="rect" />
    </div>
);

export const ClubDetailsHeaderShape = (
    <div className="ClubDetailsHeaderShape ClubDetailsHeader ClubIconColorLabel background">
        <div className="rect-01">
            <div className="club-details-header-content">
                <div className="ClubIconColorLabel icon-color">
                    <div className="circle-02"></div>
                </div>
            </div>
        </div>
    </div>
);

export const ClubDetailsInfosShape = (
    <div className="ClubDetailsInfosShape ClubDetailsInfos">
        <div className="rect-01" />
        <div className="ClubDetailsInfosMembers">
            <div className="rect-02 members-title" />
            <div className="members-images">
                <div className="buttonRect-03" />
                <div className="buttonRect-04" />
            </div>
        </div>
        <div className="rect-05 club-description-title" />
        <div className="rect-06" />
        <div className="rect-06" />
        <div className="rect-06" />
        <div className="rect-06" />
        <div className="rect-06" />
    </div>
);

export const gatheringsSkeletonShapes = (
    <div className="gatheringsSkeletonShapes">
        <div className="wrapper-body">
            {[...Array(5)].map((_, key) => {
                return (
                    <div className="wrapper-one-section" key={key}>
                        <div className="left"></div>
                        <div className="right">
                            <div className="rect-large" />
                            <div className="rect-1" />
                            <div className="wrapper-row">
                                <div className="rect-1" />
                                <div className="rect-1" />
                            </div>

                            <div className="separator" />

                            <div className="rect-1 mb-10" />
                            <div className="rect-1" />
                            <div className="rect-button" />
                        </div>
                    </div>
                );
            })}
        </div>
    </div>
);
