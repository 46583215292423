import { HttpClient } from 'HttpClient/HttpClient';
import { InternalAxiosRequestConfig } from 'axios';
import { inject } from 'aurelia-dependency-injection';
import { AuthenticationStore } from 'Stores';
import { ApiService } from './ApiService';
import { AzureAdAuthenticationService } from './AzureAdAuthenticationService';
import { SupportedLanguage } from 'Stores/LanguageStore';

@inject(HttpClient, AuthenticationStore, AzureAdAuthenticationService)
export class AuthenticationService extends ApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly authenticationStore: AuthenticationStore,
        private readonly azureAdAuthenticationService: AzureAdAuthenticationService
    ) {
        super();
    }

    public refreshTokenPromise: any;
    public runSignInPromise: any;

    public installInterceptors(lang: SupportedLanguage): void {
        this.httpClient.addRequestInterceptor((requestConfig: InternalAxiosRequestConfig) =>
            this.onBeforeRequest(requestConfig, lang)
        );
    }

    public async refreshAccessToken(refreshToken: string): Promise<void> {
        await this.azureAdAuthenticationService.refreshToken(refreshToken);
    }

    public async refreshAccessTokenIfNeeded(): Promise<void> {
        //Retrieve refresh token from store.
        const refreshToken = this.authenticationStore.refreshToken;
        const tokenExpiration = this.authenticationStore.expiresIn;

        // No token currently set.
        if (!refreshToken || !tokenExpiration) {
            return;
        }

        if (Math.round(Date.now() / 1000) > tokenExpiration) {
            if (!this.refreshTokenPromise) {
                this.refreshTokenPromise = this.refreshAccessToken(refreshToken);
            }
            await this.refreshTokenPromise;
            this.refreshTokenPromise = null;
        }
    }

    public async onBeforeRequest(
        requestConfig: InternalAxiosRequestConfig,
        lang: SupportedLanguage
    ): Promise<InternalAxiosRequestConfig> {
        if (requestConfig.url !== '/token' && requestConfig.url !== '/auth-config') {
            await this.refreshAccessTokenIfNeeded();

            //Retrieve access token from store.
            const accessToken = this.authenticationStore.accessToken;

            if (!accessToken && !this.runSignInPromise) {
                this.runSignInPromise = this.azureAdAuthenticationService.runSignInFlow(lang);
            }
            await this.runSignInPromise;

            //Add Authorization header in request
            requestConfig.headers.Authorization = `bearer ${accessToken}`;
        }

        //Add language in header to localise api error responses
        requestConfig.headers['Accept-Language'] = lang;

        return requestConfig;
    }
}
