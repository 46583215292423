import { Col, Form, Input, InputNumber, Row } from 'antd';
import { OfficeSpaceDto } from 'Api/Features/Offices/Dtos/OfficeSpaceDto';
import Button from 'Components/button';
import Modal from 'Components/modal';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { useFormValidation, useService, useStores } from 'Hooks';
import { observer } from 'mobx-react';
import { FORM_GUTTER } from 'Models/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddEditSpaceModalSchema } from 'Schemas/CreateOfficeSchema';
import { OfficeService } from 'Services/OfficeService';
import './index.less';
import { WarningIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import SubmitButton from 'Components/submit-button/submit-button';

interface AddEditSpaceModalProps {
    visible: boolean;
    spaceData?: OfficeSpaceDto;
    onComplete: (arg1: boolean) => void;
    idOffice: string;
}

const AddEditSpaceModal: React.FunctionComponent<AddEditSpaceModalProps> = observer(
    ({ visible, spaceData, onComplete, idOffice }) => {
        const officeService = useService(OfficeService);
        const { globalLoadingStore, toastStore, confirmationModalStore } = useStores();
        const [form] = Form.useForm();
        const [errors, validateForm, resetErrors] = useFormValidation(
            AddEditSpaceModalSchema,
            form
        );
        const { t } = useTranslation();
        const isEdition = !!spaceData;

        const labels = {
            title: t(`Office.${isEdition ? 'space_edit_title' : 'space_add_title'}`),
            positiveButton: t(`${isEdition ? 'save_changes' : 'add'}`),
        };

        const confirmLoweredCapacity = async (): Promise<boolean> => {
            return await confirmationModalStore.confirm({
                icon: <WarningIcon fill={theme['warning-mid-contrast']} />,
                title: t('Office.office_space_lowering_title'),
                message: t('Office.office_space_lowering_message'),
                positiveText: t('confirm'),
                negativeText: t('cancel'),
            });
        };

        const dismiss = (success = false): void => {
            form.resetFields();
            resetErrors();
            onComplete(success);
        };

        const submit = async (): Promise<void> => {
            const formData = form.getFieldsValue();

            if (
                !(await validateForm({
                    ...formData,
                    capacity: formData.capacity === '' ? undefined : formData.capacity,
                }))
            )
                return;

            if (
                isEdition &&
                spaceData.capacity &&
                spaceData.capacity > formData.capacity &&
                !(await confirmLoweredCapacity())
            )
                return;

            try {
                globalLoadingStore.addLoading();

                if (isEdition && spaceData.id) {
                    await officeService.updateOfficeSpace(spaceData.id, formData);
                } else {
                    await officeService.createOfficeSpace({ officeId: idOffice, ...formData });
                }

                toastStore.toast({
                    type: 'success',
                    messageKey: t('Toast.entity_action_success_message', {
                        param1: t('Office.space'),
                        param2: t(
                            `${
                                isEdition
                                    ? 'Entity.lowercase_action_edited'
                                    : 'Entity.lowercase_action_created'
                            }`
                        ),
                    }),
                });
                dismiss(true);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };

        return (
            <Modal
                className="AddEditSpaceModal"
                visible={visible}
                width={650}
                headerText={labels.title}
                onCancel={() => dismiss(false)}
                footer={
                    <>
                        <Button text={t('cancel')} type="tertiary" onClick={() => dismiss(false)} />
                        <SubmitButton
                            text={labels.positiveButton}
                            type="primary"
                            onClick={submit}
                        />
                    </>
                }
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={16}>
                            <ValidatedFormItem
                                errors={errors}
                                name="name"
                                label={t('name')}
                                initialValue={spaceData?.name}
                                required
                            >
                                <Input />
                            </ValidatedFormItem>
                        </Col>

                        <Col span={8}>
                            <ValidatedFormItem
                                errors={errors}
                                name="capacity"
                                label={t('capacity')}
                                initialValue={spaceData?.capacity}
                                required
                            >
                                <InputNumber />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
);

export default AddEditSpaceModal;
