import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const WarningIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <circle cx="14" cy="20.5" r="1" fill={fill} />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 9C14.5523 9 15 9.44772 15 10V17.5C15 18.0523 14.5523 18.5 14 18.5C13.4477 18.5 13 18.0523 13 17.5V10C13 9.44772 13.4477 9 14 9Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.3923 23L14 5L3.60772 23L24.3923 23ZM15.7321 4C14.9623 2.66667 13.0378 2.66667 12.268 4L1.87567 22C1.10587 23.3333 2.06812 25 3.60772 25H24.3923C25.9319 25 26.8942 23.3333 26.1244 22L15.7321 4Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default WarningIcon;
