import { CertificationIcon, DeleteIcon, PencilIcon } from 'Components/icons';
import { useStores } from 'Hooks';
import { FORMAT_MONTH_WRITTEN_DATE_YEAR } from 'Models/Constants';
import { theme } from 'Style/theme';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './index.less';
import { CertificationDtoWithId } from '../profile-tabs-content/profile-details-tab';

interface CertificationItemProps {
    certification: CertificationDtoWithId;
    onEdit?: (certification: CertificationDtoWithId) => void;
    onDelete?: (certification: CertificationDtoWithId) => void;
}

const CertificationItem: FunctionComponent<CertificationItemProps> = observer(
    ({ certification, onEdit, onDelete }) => {
        const { t } = useTranslation();
        const { languageStore } = useStores();

        return (
            <div className="CertificationItem">
                <div className="d-flex-align">
                    <div className="certification-icon">
                        <CertificationIcon width={37} height={35} />
                    </div>
                    <div>
                        <div className="text-callout">{certification.title}</div>

                        <div className="text-body text-mid-contrast">
                            {t('acquired_on')}
                            {': '}
                            {moment(certification.date).format(
                                FORMAT_MONTH_WRITTEN_DATE_YEAR[languageStore.currentLanguage]
                            )}
                        </div>

                        <div className="d-flex">
                            {certification.source && (
                                <>
                                    <div className="text-body text-mid-contrast">
                                        {t('source')}
                                        {': '} {certification.source}
                                    </div>
                                </>
                            )}

                            {certification.source && certification.url && <div className="line" />}

                            {certification.url && (
                                <div>
                                    <a
                                        className="text-body text-primary-mid-contrast"
                                        href={certification.url}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        {t('information_link')}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="action-icons">
                        {onEdit && (
                            <div onClick={() => onEdit(certification)}>
                                <PencilIcon
                                    width={32}
                                    height={32}
                                    fill={theme['primary-mid-contrast']}
                                />
                            </div>
                        )}
                        {onDelete && (
                            <div onClick={() => onDelete(certification)}>
                                <DeleteIcon
                                    width={32}
                                    height={32}
                                    fill={theme['text-mid-contrast']}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

export default CertificationItem;
