import { ReactNode } from 'react';

export interface SingleSelectCustomOption {
    value: string;
    label: string;
    content?: { node: ReactNode; className?: string; showSelected?: boolean };
    // imageUrl?: string;
    leftIcon?: ReactNode;
    showLeftIconSelected?: boolean;
    hideLeftIconDropDown?: boolean;
    isDisabled?: boolean;
    extraData?: any;
}

export const SingleSelectCommonStyles: any = {
    option: (provided: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
    }),
    indicatorSeparator: (styles: any) => ({
        ...styles,
        display: 'none',
    }),
    input: (styles: any) => ({
        ...styles,
        marginLeft: '-10px',
        position: 'relative',
        left: '10px',
    }),
    //permet au menu ouvert de sortir de l'epace d'un modal
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 1000000,
    }),
};
