import { observable, action, computed } from 'mobx';
import { inject } from 'aurelia-dependency-injection';
import UserStore from './UserStore';
import { UserService } from 'Services/UserService';
import moment from 'moment';
import { FORMAT_YEAR_MONTH_DAY } from 'Models/Constants';
import { UserObjectivesStatusDetailsDto } from 'Api/Features/Users/Dtos/UserObjectivesStatusDetailsDto';
import { PolicyBundleRestrictionsDto } from 'Api/Features/Policies/Dtos/PolicyBundleRestrictionsDto';
import { PolicyBundleSuggestionsDto } from 'Api/Features/Policies/Dtos/PolicyBundleSuggestionsDto';
import { PolicyBundleService } from 'Services/PolicyBundleService';
import { PolicyBundleDto } from 'Api/Features/Policies/Dtos/PolicyBundleDto';
import { getObjectivesScore, getPolicyBundleObjectivesDetails } from 'Utils/PolicyObjectivesUtils';
import { PolicyBundleObjectivesDto } from 'Api/Features/Policies/Dtos/PolicyBundleObjectivesDto';
import { GetUserObjectivesStatusRequestDto } from 'Api/Features/Users/Dtos/GetUserObjectivesStatusRequestDto';
import { GetUserObjectivesStatusWeekValuesDto } from 'Api/Features/Users/Dtos/GetUserObjectivesStatusWeekValuesDto';

export interface ObjectivesScore {
    completed: number;
    total: number;
}

export interface PolicyBundleObjectivesDetails extends ObjectivesScore {
    label: keyof GetUserObjectivesStatusWeekValuesDto;
}

export interface PolicyInfoStoreObjectiveStats {
    objectives: UserObjectivesStatusDetailsDto;
    totalScore: ObjectivesScore;
    policyBundleObjectivesDetails: PolicyBundleObjectivesDetails[];
}

export interface PolicyInfoStore {
    policyObjectiveStats?: PolicyInfoStoreObjectiveStats;
    restrictions: PolicyBundleRestrictionsDto | null;
    suggestions: PolicyBundleSuggestionsDto | null;
    policyBundleId: string;
    policyBundleObjectivesDto: PolicyBundleObjectivesDto | null;
}

@inject(UserService, PolicyBundleService, UserStore)
class PolicyStore {
    constructor(
        private readonly userService: UserService,
        private readonly policyBundleService: PolicyBundleService,
        private readonly userStore: UserStore
    ) {}

    private policyObjectivesStatsPromise: any;
    private policyInfoPromise: any;

    private lastObjectivesStatsCallTime: string | undefined = undefined;
    private lastPolicyInfoCallTime: string | undefined = undefined;

    @observable
    public callInProgress = false;

    @observable
    public policyInfo: PolicyInfoStore | null = null;

    @observable
    public policyInfoForSpecificWeek: PolicyInfoStore | null = null;

    private async fetchPolicyObjectivesStats(
        week?: string
    ): Promise<UserObjectivesStatusDetailsDto | null> {
        try {
            const request: GetUserObjectivesStatusRequestDto = {
                week: week
                    ? moment(week).format(FORMAT_YEAR_MONTH_DAY)
                    : moment().format(FORMAT_YEAR_MONTH_DAY),
            };

            const response = await this.userService.getUserObjectivesStatus(
                this.userStore.userInfo?.id ?? '',
                request
            );
            return response;
        } catch (err: any) {
            return null;
        }
    }

    @action async setPolicyObjectivesStats(): Promise<void> {
        try {
            const response = await this.fetchPolicyObjectivesStats();
            if (response) {
                this.policyInfo = {
                    restrictions: this.policyInfo?.restrictions ?? null,
                    suggestions: this.policyInfo?.suggestions ?? null,
                    policyBundleId: this.policyInfo?.policyBundleId ?? '',
                    policyObjectiveStats: {
                        objectives: response,
                        totalScore: getObjectivesScore(response),
                        policyBundleObjectivesDetails: getPolicyBundleObjectivesDetails(response),
                    },
                    policyBundleObjectivesDto: this.policyInfo?.policyBundleObjectivesDto ?? null,
                };
                this.lastObjectivesStatsCallTime = moment().format();
            }
        } catch (err: any) {
            this.policyInfo = null;
        }
    }

    @action
    async setPolicyRestrictions(): Promise<void> {
        this.setCallInProgress(true);
        try {
            let policyInfoBundleResponse: PolicyBundleDto | null = null;
            if (this.userStore.userInfo?.team?.policyBundle?.id) {
                policyInfoBundleResponse = await this.policyBundleService.getPolicyBundle(
                    this.userStore.userInfo.team.policyBundle.id
                );
                this.policyInfo = {
                    ...this.policyInfo,
                    policyBundleId: this.userStore.userInfo.team.policyBundle.id,
                    restrictions: policyInfoBundleResponse?.restrictions || null,
                    policyBundleObjectivesDto: policyInfoBundleResponse?.objectives ?? null,
                    suggestions: policyInfoBundleResponse?.suggestions || null,
                };

                this.lastPolicyInfoCallTime = moment().format();
            } else {
                this.policyInfo = null;
            }
        } catch (err: any) {
            this.policyInfo = null;
        }
        this.setCallInProgress(false);
    }

    @action
    async setPolicyRestrictionsAndObjectivesStats(): Promise<void> {
        const userId = this.userStore.userInfo?.id;
        if (userId) {
            if (!this.policyInfoPromise) {
                this.policyInfoPromise = this.setPolicyRestrictions();
            }

            await this.policyInfoPromise;
            this.policyInfoPromise = null;

            if (!this.policyObjectivesStatsPromise) {
                this.policyObjectivesStatsPromise = this.setPolicyObjectivesStats();
            }

            await this.policyObjectivesStatsPromise;
            this.policyObjectivesStatsPromise = null;
        }
    }

    @action
    async cachedSetPolicyRestrictions(): Promise<void> {
        if (
            this.lastPolicyInfoCallTime === undefined ||
            moment(this.lastPolicyInfoCallTime).add(5, 'minute').isBefore(moment())
        ) {
            if (!this.policyInfoPromise) {
                this.policyInfoPromise = this.setPolicyRestrictions();
            }

            await this.policyInfoPromise;
            this.policyInfoPromise = null;
        }
    }

    @action
    async cachedSetObjectivesStats(): Promise<void> {
        if (
            this.lastObjectivesStatsCallTime === undefined ||
            moment(this.lastObjectivesStatsCallTime).add(5, 'minute').isBefore(moment())
        ) {
            if (!this.policyObjectivesStatsPromise) {
                this.policyObjectivesStatsPromise = this.setPolicyObjectivesStats();
            }

            await this.policyObjectivesStatsPromise;
            this.policyObjectivesStatsPromise = null;
        }
    }

    @action
    async setPolicyObjectivesStatsForSpecificWeek(dayOfWeek: string): Promise<void> {
        try {
            const response = await this.fetchPolicyObjectivesStats(dayOfWeek);
            if (response) {
                this.policyInfoForSpecificWeek = {
                    restrictions: this.policyInfoForSpecificWeek?.restrictions ?? null,
                    suggestions: this.policyInfoForSpecificWeek?.suggestions ?? null,
                    policyBundleId: this.policyInfoForSpecificWeek?.policyBundleId ?? '',
                    policyObjectiveStats: {
                        objectives: response,
                        totalScore: getObjectivesScore(response),
                        policyBundleObjectivesDetails: getPolicyBundleObjectivesDetails(response),
                    },
                    policyBundleObjectivesDto: response.policyBundleObjectives ?? null,
                };
            }
        } catch (e) {
            this.policyInfoForSpecificWeek = null;
        }
    }

    @action
    setCallInProgress(value: boolean): void {
        this.callInProgress = value;
    }

    @action
    clearPolicyInfo(): void {
        this.policyInfo = null;
    }

    @computed
    get policyHasObjectives(): boolean {
        return (
            this.policyInfo !== null &&
            this.policyInfo.policyBundleObjectivesDto !== null &&
            Object.values(this.policyInfo.policyBundleObjectivesDto).some((obj) => obj !== null)
        );
    }
}

export default PolicyStore;
