import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import Select, { components } from 'react-select';
import { theme } from 'Style/theme';
import { CloseIcon } from 'Components/icons';
import {
    MultiSelectCommonStyles,
    MultiSelectCustomOption,
} from 'Components/select-custom/multi-select/multi-select-common';
import '../select-custom/multi-select/multi-select-common';
import './index.less';
import { useTranslation } from 'react-i18next';

export interface StaticMultiSelectProps {
    className?: string;
    options?: MultiSelectCustomOption[];
    onCreateBadge: (value: string) => void;
    onRemoveBadge: (values: MultiSelectCustomOption[]) => void;
    disabled?: boolean;
}

const BadgeCreatorInput: FunctionComponent<StaticMultiSelectProps> = ({
    className,
    options,
    onCreateBadge,
    onRemoveBadge,
    disabled,
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<MultiSelectCustomOption[]>([]);
    const [currentInputValue, setCurrentInputValue] = useState<string | undefined>();

    useEffect(() => {
        if (options === undefined) return;
        setValue(options.map((x) => ({ value: x.value, label: x.label })) ?? []);
    }, [options]);

    /* -- The selected values in input -- */
    const selectedOptionDetails = (data: MultiSelectCustomOption) => {
        return (
            <div className="selected-option-details">
                <div className="text-caption-3-bold">{data.label}</div>
            </div>
        );
    };

    //This is not the input container, it is the rounded badge container
    const MultiValueContainer = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-multivalue-container';
        return <components.MultiValueContainer innerProps={innerProps} {...props} />;
    };

    const MultiValueLabel = ({ data, innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-selected-element';
        data.content = data.content ? data.content : null;
        return (
            <components.MultiValueLabel innerProps={innerProps} {...props}>
                {selectedOptionDetails(data)}
            </components.MultiValueLabel>
        );
    };

    //icone X dans les selected items pour retirer
    const MultiValueRemove = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-value-remove';
        return (
            <components.MultiValueRemove innerProps={innerProps} {...props}>
                <CloseIcon width={13} height={13} fill={theme['primary-high-contrast']} />
            </components.MultiValueRemove>
        );
    };
    /* -- The selected values in dropdown -- */

    const Input = useCallback(({ ...props }: any) => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback((): any => null, []);

    return (
        <div className="BadgeCreatorInput">
            <Select
                components={{
                    MultiValueContainer,
                    MultiValueLabel,
                    MultiValueRemove,
                    DropdownIndicator,
                    Input,
                }}
                theme={(defaultTheme) => ({
                    ...defaultTheme,
                    colors: {
                        ...defaultTheme.colors,
                        primary: 'transparent', //dictates the color of select option
                        primary25: theme['primary-low-contrast'], //dictates the color hovering options
                    },
                })}
                className={`BadgeCreatorInput MultiSelect ${className ?? ''} ${
                    disabled ? 'disabled' : ''
                }`}
                value={value}
                styles={MultiSelectCommonStyles}
                options={options}
                //the on change here is only triggered on clicking the x to remove a badge
                onChange={(values) => onRemoveBadge(values?.map((x) => ({ ...x })) ?? [])}
                isDisabled={disabled}
                isMulti
                isClearable={false}
                placeholder=""
                menuIsOpen={false}
                onInputChange={(newValue: string) => {
                    setCurrentInputValue(newValue);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        if (currentInputValue && currentInputValue !== '') {
                            onCreateBadge(currentInputValue);
                            setCurrentInputValue('');
                        }
                    }
                }}
                backspaceRemovesValue={false}
                inputValue={currentInputValue}
            />
            <span className="text-caption-2 text-mid-contrast">
                {t('BadgeCreatorInput.press_enter_to_create_tags')}
            </span>
        </div>
    );
};

export default BadgeCreatorInput;
