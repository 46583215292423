import { GetUserObjectivesStatusWeekValuesDto } from 'Api/Features/Users/Dtos/GetUserObjectivesStatusWeekValuesDto';
import DonutChart from 'Components/donut-chart/donut-chart';
import Icon from 'Components/icons/Icon';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import { getObjectiveIcon } from 'Utils/PolicyObjectivesUtils';
import './index.less';

interface PolicyObjectiveCircleIconProps {
    policyKey?: keyof GetUserObjectivesStatusWeekValuesDto;
    width?: number;
    policyCurrentValue?: number;
    policyGoal?: number;
    minWidth?: number;
    progressColor?: string;
    emptyProgressColor?: string;
    iconSize?: number;
}

const PolicyObjectiveCircleIcon: FunctionComponent<PolicyObjectiveCircleIconProps> = ({
    policyKey,
    policyCurrentValue,
    policyGoal,
    width = 30,
    minWidth,
    progressColor = theme['primary-mid-contrast'],
    emptyProgressColor = theme['primary-low-contrast'],
    iconSize,
}) => {
    const isSuccess = policyGoal && policyCurrentValue ? policyGoal <= policyCurrentValue : false;
    const SUCCESS_THEME_COLOR = 'success-mid-contrast';
    const SUCCESS_SIZE_ICON = width * 0.8;
    const currentValue =
        (policyCurrentValue ?? 1) >= (policyGoal ?? 0) ? policyGoal : policyCurrentValue;

    const sizeIcon = iconSize ?? width / 2;

    if (!policyKey && (policyGoal || 0) <= (policyCurrentValue || 0))
        return (
            <div className="done-icon">
                <Icon
                    iconName="CircleCheckmarkIcon"
                    width={SUCCESS_SIZE_ICON}
                    height={SUCCESS_SIZE_ICON}
                />
            </div>
        );

    return (
        <div
            className="PolicyObjectiveCircleIcon"
            style={{ maxWidth: width, minWidth: minWidth ?? undefined }}
        >
            <div className="donut-icon">
                {getObjectiveIcon(
                    policyKey,
                    isSuccess ? SUCCESS_SIZE_ICON : sizeIcon,
                    isSuccess ? theme[SUCCESS_THEME_COLOR] : progressColor,
                    isSuccess
                )}
            </div>
            <DonutChart
                lineWidth={18}
                data={[
                    {
                        value: currentValue ?? 0,
                        color: isSuccess ? theme[SUCCESS_THEME_COLOR] : progressColor,
                    },
                    {
                        value: (policyGoal ?? 1) - (currentValue ?? 0),
                        color: emptyProgressColor,
                    },
                ]}
            />
        </div>
    );
};

export default PolicyObjectiveCircleIcon;
