import React from 'react';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { HappeningFilterModeDto } from 'Api/Features/Happenings/Dtos/HappeningFilterModeDto';
import { useTranslation } from 'react-i18next';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import ScrollInfinite from 'Components/scroll-infinite';
import { CalendarStarIcon } from 'Components/icons';
import EventLine from '../components/event-line';
import { PAGE_SIZE_INFINITE_LOADING } from 'Models/Constants';
import { theme } from 'Style/theme';
import { HappeningDto } from 'Api/Features/Happenings/Dtos/HappeningDto';

interface EventUIProps {
    isLoading: boolean;
    setSelectedEventTypeFilter: React.Dispatch<
        React.SetStateAction<SingleSelectCustomOption | undefined>
    >;
    selectedEventTypeFilter: SingleSelectCustomOption | undefined;
    asyncSingleSelectProps: any;
    happenings: HappeningDto[];
    handleScrollToBottom: () => void;
    isPast?: boolean;
}

const EventUI: React.FC<EventUIProps> = ({
    isLoading,
    setSelectedEventTypeFilter,
    selectedEventTypeFilter,
    asyncSingleSelectProps,
    happenings,
    handleScrollToBottom,
    isPast,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="container">
                <div className="header">
                    <div className="container">
                        <StaticSingleSelect
                            onChange={(value?: SingleSelectCustomOption) => {
                                setSelectedEventTypeFilter(value);
                            }}
                            options={[
                                {
                                    label: t(
                                        `HappeningFilterModeDto.HappeningFilterModeDto_${HappeningFilterModeDto.GeneralAndUserClubs}`
                                    ),
                                    value: HappeningFilterModeDto.GeneralAndUserClubs,
                                } as SingleSelectCustomOption,
                            ].concat(
                                Object.values(HappeningFilterModeDto)
                                    .filter(
                                        (x) =>
                                            x !== HappeningFilterModeDto.All &&
                                            x !== HappeningFilterModeDto.GeneralAndUserClubs
                                    )
                                    .map(
                                        (type) =>
                                            ({
                                                label: t(
                                                    `HappeningFilterModeDto.HappeningFilterModeDto_${type}`
                                                ),
                                                value: type,
                                            } as SingleSelectCustomOption)
                                    )
                            )}
                            selected={selectedEventTypeFilter?.value}
                            placeholder={t('SelectCustom.default_select_placeholder')}
                        />

                        <AsyncSingleSelect
                            {...asyncSingleSelectProps}
                            placeholder={t('SelectCustom.default_select_placeholder')}
                        />
                    </div>
                </div>
            </div>

            {happenings.length === 0 && !isLoading && (
                <div className="content all-empty">
                    <div className="circle-happeing">
                        <CalendarStarIcon width={74} height={74} fill={theme['white']} />
                    </div>
                    <div className="text-title-1">{t('no_events_at_this_time')}</div>
                    <div className="text-body">{t('stay_tuned_for_more_soon')}</div>
                </div>
            )}

            <div className="content">
                <div className="body">
                    <ScrollInfinite
                        items={happenings}
                        RenderItem={({ item }) => <EventLine isPast={isPast} happening={item} />}
                        RenderItemSkeleton={() => <EventLine isSkeleton />}
                        handlePagination={handleScrollToBottom}
                        paginationOptions={{
                            pageSize: PAGE_SIZE_INFINITE_LOADING,
                            isLoading: isLoading,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default EventUI;
