import { Col, Row } from 'antd';
import Form, { useForm } from 'antd/es/form/Form';
import Button from 'Components/button';
import Modal from 'Components/modal';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import SubmitButton from 'Components/submit-button/submit-button';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { FORM_GUTTER } from 'Models/Constants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from 'Services/UserService';
import { SupportedLanguage } from 'Stores/LanguageStore';

interface ChangeLangModalProps {
    visible: boolean;
    onComplete: (success: boolean) => void;
}

const ChangeLangModal: React.FunctionComponent<ChangeLangModalProps> = observer(
    ({ visible, onComplete }) => {
        const [form] = useForm();
        const { t } = useTranslation();
        const { globalLoadingStore, toastStore, languageStore, userStore } = useStores();
        const userService = useService(UserService);
        const [selectedLang, setSelectedLang] = useState<SupportedLanguage>(
            languageStore.currentLanguage
        );

        useEffect(() => {
            //must pass whole user object to update. certain fields are not 1:1 map
            if (userStore.userInfo) {
                form.setFieldsValue({});
            }
        }, [userStore.userInfo]);

        //#region Submit / Exit
        const dismiss = (success = false): void => {
            form.resetFields();
            onComplete(success);
        };

        const submit = async (): Promise<void> => {
            try {
                if (userStore.userInfo?.id) {
                    globalLoadingStore.addLoading();
                    await userService.updateUser(userStore.userInfo?.id, {
                        ...userStore.userInfo,
                        preferredLanguageCode: selectedLang,
                        // values that do not map 1:1 from store values to update user request
                        defaultOfficeId: userStore.userInfo.defaultOffice?.id,
                        defaultOfficeSpaceId: userStore.userInfo.defaultOfficeSpace?.id,
                        teamId: userStore.userInfo.team?.id,
                        locationGooglePlaceId: userStore.userInfo?.location?.googlePlaceId,
                    });
                    languageStore.setCurrentLanguage(selectedLang);
                }

                dismiss(true);
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        };
        //#region Submit / Exit

        return (
            <Modal
                className="CreateTeamModal"
                visible={visible}
                onCancel={() => dismiss()}
                headerText={t('SupportedLanguage.language_preferences')}
                footer={
                    <>
                        <Button text={t('close')} type="tertiary" onClick={() => dismiss()} />
                        <SubmitButton
                            text={t('save_changes')}
                            type="primary"
                            onClick={() => submit()}
                        />
                    </>
                }
                width={780}
            >
                <Form layout="vertical" onFinish={submit} form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={24} className="mb-20">
                            <div className="mb-20">
                                {t('SupportedLanguage.change_language_exp')}
                            </div>
                            <StaticSingleSelect
                                onChange={(option?: SingleSelectCustomOption) => {
                                    if (option) setSelectedLang(option.value as SupportedLanguage);
                                }}
                                selected={selectedLang}
                                options={Object.keys(SupportedLanguage).map(
                                    (lang) =>
                                        ({
                                            label: t(`SupportedLanguage.SupportedLanguage_${lang}`),
                                            value: lang,
                                        } as SingleSelectCustomOption)
                                )}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
);

export default ChangeLangModal;
