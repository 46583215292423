import React, { FunctionComponent } from 'react';
import './index.less';

interface TimeDisplayProps {
    start: string;
    end: string;
}

const TimeDisplay: FunctionComponent<TimeDisplayProps> = ({ start, end }) => {
    return (
        <div className="TimeDisplay text-headline">
            {start} <span className="TimeDisplay-separator" /> {end}
        </div>
    );
};

export default TimeDisplay;
