import { EmptyBoxIcon } from 'Components/icons';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './index.less';

interface EmptyProps {
    stringKey?: string;
}

export const Empty: FunctionComponent<EmptyProps> = ({ stringKey }) => {
    const { t } = useTranslation();
    return (
        <div className="Empty">
            <EmptyBoxIcon fill={theme['layout-mid-contrast']} width={50} height={50} />
            <div className="text-title-3 text-disabled">{t(`${stringKey || 'empty'}`)}</div>
        </div>
    );
};
